import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

function SupplyRecentListArea(props:any){
  let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    rowData:{},
    ...props
  };
  let select_mcomp_data= myProps.select_mcomp_data;
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_date_type:'a_date',
      s_start_date:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',-1),"Y-m-01"),
      s_end_date:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',+3),"Y-m-t"),
      s_money_sum:"1",
      s_state:"",
      s_sort:"",
      s_stu_seq:"",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [trade_tot_info,set_trade_tot_info]=useState({
    in_debt:0,
    in_money:0,
    out_debt:0,
    out_money:0,
    remain_money:0
  });

  useEffect(() => {
    list({});
  }, [myProps.rowData,select_mcomp_data.mcomp_seq]);
  

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      //inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      
      setPageData({...tmpPageData});
    }
    let tmp_list_opt=pageData.listOpt;
    if(strFunc.is_empty(myProps.rowData)){
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    if(strFunc.is_empty(myProps.rowData["a_seq"])){
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    tmp_list_opt["s_stu_seq"]=myProps.rowData["a_seq"];
    get_trade_tot_info_by_ajax();

    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        tmp_list_opt["api_key"]=select_mcomp_data.api_key;
        tmp_list_opt["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/class_supply/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_trade_tot_info_by_ajax=()=>{
    if(strFunc.is_empty(myProps.rowData["a_seq"])){
      return false;
    }
    let tmp_list_opt:any={
      "s_pri_arr":[myProps.rowData["a_seq"]],
      "s_addon_trade_tot":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        tmp_list_opt["api_key"]=select_mcomp_data.api_key;
        tmp_list_opt["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length==1){
          set_trade_tot_info(
            response.data["data"]["info_arr"][0]["trade_tot_info"]
          );
        }
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      
    }
  }, []);

  const cellRenderer= (params:any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "origin_val":origin_val,
    }
    */
    let render_str=params.value;
    
    return render_str;
  };

  return (
    <div>
      <p>※최근수납 청구서</p>
      <div>
        청구금: <span style={{color:"#918e4b"}}>{strFunc.comma(trade_tot_info.in_debt)}</span>원, 
        입금: <span style={{color:"blue"}}>{strFunc.comma(trade_tot_info.in_money)}</span>원, 
        미납금: <span style={{color:"red"}}>{strFunc.comma(trade_tot_info.remain_money)}</span>원 
      </div>
      <div>
        <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        gridOpt={{
          is_add_idx_num:false,
          is_add_checkbox:false,
          td_align:"center",
          height:"15vh",
          onGridReady:()=>{
            //list({});
          },
          onCellClicked:cellClickedListener,
          customCellRenderer:cellRenderer
        }}
        ></ListComponent>
      </div>
    </div>
  );
}
export default SupplyRecentListArea;