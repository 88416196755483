import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import VideoPreViewPopup from '@/pages/comp/plan/video/corse/popup/video_preview';

const VideoArea = forwardRef((props:any, ref) => {
  const myProps={
    set_video_arr:(video_arr:any)=>{},
    ...props
  };
  const videoXColumnArr=getXcolumnJson();
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const init_data={
    "section_arr":[],
    "video_arr":[],
  };
  const default_row_data={
    "a_corse_seq":"",
    "a_seq":"1",
    "a_section_seq":"",
    "a_is_url":"1",
    "a_url":"",
    "a_url_sort":"youtube",
    "a_is_essential":"1",
    "a_title":"",
    "a_memo":"",
    "a_total_sec":"",
    "a_complete_sec":"",
    "a_order_num":"",
  };
  const [section_arr,set_section_arr]=useState<any>(init_data.section_arr);
  const [video_arr,set_video_arr]=useState<any>(init_data.video_arr);
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "section_arr":[],
      "video_arr":[],
      "isUpdate":false,
      ...inOptObj
    };
    set_section_arr(optObj.section_arr);
    set_video_arr(optObj.video_arr);
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"view",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  const openPreviewPopup=(row_data:any)=>{
    let pop_title="미리보기";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":row_data,
      "title":pop_title,
      "sort":"view"
    });
  };

  const onChangeRowData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      key:"",
      value:"",
      row_num:0,
      ...inOptObj
    };
    let value=optObj["value"];
    let key=optObj["key"];
    let row_num=optObj["row_num"];
    if(key=="a_is_essential"){
      if(value){
        value="1";
      }else{
        value="";
      }
    }

    let tmp_video_arr:any=[...video_arr];
    tmp_video_arr[row_num][key]=value;

    set_video_arr(tmp_video_arr);
    myProps.set_video_arr(tmp_video_arr);
  };

  const addNewRow=()=>{
    let max_a_seq=0;
    let last_section_seq="";
    if(video_arr.length>0){
      for(let i=0;i<video_arr.length;i++){
        let tmp_a_seq=parseInt(video_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }
      }
      last_section_seq=video_arr[video_arr.length-1]["a_section_seq"];
    }
    let addRow:any={...default_row_data};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    addRow["a_section_seq"]=last_section_seq;
    let tmp_video_arr:any=[...video_arr];
    tmp_video_arr.push(addRow);
    set_video_arr(tmp_video_arr);
    myProps.set_video_arr(tmp_video_arr);
  };

  const removeRowData=(row_num:number)=>{
    let tmp_video_arr=[];
    for(let i=0;i<video_arr.length;i++){
      if(i!=row_num){
        tmp_video_arr.push(video_arr[i]);
      }
    }
    set_video_arr(tmp_video_arr);
    myProps.set_video_arr(tmp_video_arr);
  };

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_video_arr:any=[...video_arr];

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_video_arr.length){
      return false;
    }

    let now_row_data=tmp_video_arr[row_num];
    let change_row_data=tmp_video_arr[next_row_num];

    tmp_video_arr[row_num]=change_row_data;
    tmp_video_arr[next_row_num]=now_row_data;

    tmp_video_arr=getReSetOrderNum(tmp_video_arr);

    set_video_arr(tmp_video_arr);
    myProps.set_video_arr(tmp_video_arr);
  };

  const getReSetOrderNum=(tmp_video_arr:any)=>{
    for(let i=0;i<tmp_video_arr.length;i++){
      tmp_video_arr[i].a_order_num=i+1;
    }
    return tmp_video_arr;
  };

  const getColumnInputTag=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "key":"",
      "data":{},
      "row_num":0,
      ...inOptObj
    };
    let key=optObj["key"];
    let val_str="";
    if(optObj["data"][key]!=undefined){
      val_str=optObj["data"][key];
    }

    let tag_obj=(
      <span>{val_str}</span>
    );

    if(key=="a_section_seq"){
      let select_options=section_arr.map((section_info:any,section_idx:number)=>{
        return (
          <option value={section_info.a_seq} key={section_idx}>{section_info.a_title}</option>
        );
      });
      tag_obj=(
        <select value={val_str} style={{width:"98%"}} 
          onChange={(e:any)=>{onChangeRowData({
            key:key,
            value:e.target.value,
            row_num:optObj["row_num"],
          });}}
          >
          <option value="">선택없음</option>
          {select_options}
        </select>
      );
    }else if(key=="a_is_essential"){
      tag_obj=(
        <input type="checkbox" value="1" style={{width:"98%"}} 
          onChange={(e:any)=>{onChangeRowData({
            key:key,
            value:e.target.checked,
            row_num:optObj["row_num"],
          });}}
          checked={val_str=="1"}
        />
      );
    }else if(key=="a_memo"){
      tag_obj=(
        <textarea style={{width:"98%",height:30,border:"1px solid gray"}}
          value={val_str}
          onChange={(e:any)=>{onChangeRowData({
            key:key,
            value:e.target.value,
            row_num:optObj["row_num"],
          });}}
        ></textarea>
      );
    }else if(key=="a_total_sec"){
      let time_json=strFunc.secondsToTimeJson(strFunc.uncomma(val_str));
      tag_obj=(
        <div>
          <input type="text" value={time_json.h} style={{width:22,textAlign:"center"}}
            onChange={(e:any)=>{
              let tmp_h=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_h>=60){
                tmp_h=59;
              }
              if(tmp_h<0){
                tmp_h=0;
              }
              let tmp_val=strFunc.timeToSeconds(tmp_h,time_json.i,time_json.s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
           />:
          <input type="text" value={time_json.i} style={{width:22,textAlign:"center"}} 
            onChange={(e:any)=>{
              let tmp_i=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_i>=60){
                tmp_i=59;
              }
              if(tmp_i<0){
                tmp_i=0;
              }
              let tmp_val=strFunc.timeToSeconds(time_json.h,tmp_i,time_json.s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
          />:
          <input type="text" value={time_json.s} style={{width:22,textAlign:"center"}} 
            onChange={(e:any)=>{
              let tmp_s=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_s>=60){
                tmp_s=59;
              }
              if(tmp_s<0){
                tmp_s=0;
              }
              let tmp_val=strFunc.timeToSeconds(time_json.h,time_json.i,tmp_s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
          />
        </div>
      );
    }else if(key=="a_complete_sec"){
      let time_json=strFunc.secondsToTimeJson(strFunc.uncomma(val_str));
      tag_obj=(
        <div>
          <input type="text" value={time_json.h} style={{width:22,textAlign:"center"}}
            onChange={(e:any)=>{
              let tmp_h=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_h>=60){
                tmp_h=59;
              }
              if(tmp_h<0){
                tmp_h=0;
              }
              let tmp_val=strFunc.timeToSeconds(tmp_h,time_json.i,time_json.s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
           />:
          <input type="text" value={time_json.i} style={{width:22,textAlign:"center"}} 
            onChange={(e:any)=>{
              let tmp_i=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_i>=60){
                tmp_i=59;
              }
              if(tmp_i<0){
                tmp_i=0;
              }
              let tmp_val=strFunc.timeToSeconds(time_json.h,tmp_i,time_json.s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
          />:
          <input type="text" value={time_json.s} style={{width:22,textAlign:"center"}} 
            onChange={(e:any)=>{
              let tmp_s=parseInt(strFunc.uncomma(e.target.value));
              if(tmp_s>=60){
                tmp_s=59;
              }
              if(tmp_s<0){
                tmp_s=0;
              }
              let tmp_val=strFunc.timeToSeconds(time_json.h,time_json.i,tmp_s);
              onChangeRowData({
                key:key,
                value:tmp_val,
                row_num:optObj["row_num"],
              });
            }}
          />
        </div>
      );
    }else if(key=="row_view_manage"){
      tag_obj=(
        <div>
          <button className="btn-s btn-gray" onClick={()=>{moveOrderNum(optObj["row_num"],"up");}}>▲</button>
          <button className="btn-s btn-gray ml-2" onClick={()=>{moveOrderNum(optObj["row_num"],"down");}}>▼</button>
          <button className="btn-s btn-red ml-2" onClick={()=>{removeRowData(optObj["row_num"]);}}>X</button>
        </div>
      );
    }else if(key=="row_view_preview"){
      tag_obj=(
        <button className="btn-s btn-dark" onClick={()=>{openPreviewPopup(optObj["data"]);}}>미리보기</button>
      );
    }else{
      tag_obj=(
        <input type="text" value={val_str} style={{width:"98%"}} 
          onChange={(e:any)=>{onChangeRowData({
            key:key,
            value:e.target.value,
            row_num:optObj["row_num"],
          });}}
        />
      );
    }
    
    return tag_obj;
  };

  let videoTags=video_arr.map((item:any,idx:number)=>{
    let video_td_arr=videoXColumnArr.x_column_list_arr.map((column:any,column_idx:number)=>{
      let tag_obj=getColumnInputTag({
        "key":column.key,
        "data":item,
        "row_num":idx,
      });
      return (
        <td key={column_idx}>
          <div style={{width:column["width"],textAlign:"center"}}>
           {tag_obj}
          </div>
        </td>
      );
    });

    return (
      <tr key={idx}>
        {video_td_arr}
      </tr>
    );
  });

  return (
    <div>
      <div className="btn-box-left">
        <button className="btn btn-dark" onClick={()=>{addNewRow();}}>추가</button>
      </div>
      <div className="list-table-div mt-1" >
        <table>
          <thead>
            <tr>
              {
                videoXColumnArr.x_column_list_arr.map((column:any,idx:number)=>{
                  return (
                    <th key={idx}>
                      <div style={{width:parseInt(column["width"])}}>
                        {column["name"]}
                      </div>
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {videoTags}
          </tbody>
        </table>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="view"&&
            <VideoPreViewPopup isUpdate={popupData.isUpdate} rowData={popupData.rowData}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             ></VideoPreViewPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default VideoArea;
