import { useState, useRef, useEffect } from 'react';
import DetailItem from "./item";
import strFunc from '@/lib/lyg/string';

function DetailArea(props:any){
  let myProps:any={
    detail_arr:[],
    set_detail_arr:(inData:any)=>{},
    ...props
  };

  let default_detail_row={
    a_form_seq:"",
    a_seq:"",
    a_title:"",
    a_name_sort:"abc",//abc,number
    a_max_score:100,
    a_score_split:20,
    a_name_split_str:"",//이름배열(구분@,@)
  };

  const on_change_detail_data=(inData:any)=>{
    let opt_obj={
      "row_num":0,
      "key":"",
      "value":"",
      ...inData
    };

    let detail_arr=myProps.detail_arr;
    let row_num=opt_obj["row_num"];
    let key=opt_obj["key"];
    let value=opt_obj["value"];

    if(key=="a_max_score"||key=="a_score_split"){
      value=strFunc.comma(value);
    }

    detail_arr[row_num][key]=value;

    myProps.set_detail_arr(detail_arr);
  };

  const add_new_row=()=>{
    let detail_arr=myProps.detail_arr;
    let new_row_data={
      ...default_detail_row,
    };
    detail_arr.push(new_row_data);
    detail_arr=get_seq_detail_arr_by_order(detail_arr);
    myProps.set_detail_arr(detail_arr);
  };
  
  const remove_row_by_row_num=(row_num:number)=>{
    let detail_arr=myProps.detail_arr;
    let new_detail_arr=[];
    for(let i=0;i<detail_arr.length;i++){
      if(i!=row_num){
        new_detail_arr.push(detail_arr[i]);
      }
    }
    new_detail_arr=get_seq_detail_arr_by_order(new_detail_arr);
    myProps.set_detail_arr(new_detail_arr);
  };

  const moveOrderNum=(row_num:number,up_down:"up"|"down")=>{
    let detail_arr=myProps.detail_arr;

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=detail_arr.length){
      return false;
    }

    let now_row_data=detail_arr[row_num];
    let change_row_data=detail_arr[next_row_num];

    detail_arr[row_num]=change_row_data;
    detail_arr[next_row_num]=now_row_data;

    detail_arr=get_seq_detail_arr_by_order(detail_arr);

    myProps.set_detail_arr(detail_arr);
  };

  const get_seq_detail_arr_by_order=(detail_arr:any)=>{
    for(let i=0;i<detail_arr.length;i++){
      detail_arr[i]["a_seq"]=i+1;
    }
    return detail_arr;
  };

  const get_detail_tags=()=>{
    let detail_tags=myProps.detail_arr.map((item:any,idx:number)=>{
      return (
        <DetailItem
          key={idx}
          detail_info={item}
          row_num={idx}
          on_change_detail_data={on_change_detail_data}
          remove_row_by_row_num={remove_row_by_row_num}
          moveOrderNum={moveOrderNum}
        ></DetailItem>
      );
    });

    return detail_tags;
  };
  let detail_tags=get_detail_tags();

  return (
    <div>
      <p className="mt-2">
        상세항목
        <button className="btn-m btn-gray ml-2" onClick={()=>{add_new_row();}}>항목추가</button>
      </p>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width={"100px"}></col>
            <col width={"100px"}></col>
            <col width={"100px"}></col>
            <col width={"100px"}></col>
            <col width={"100px"}></col>
          </colgroup>
          <thead>
            <tr>
              <th className="text-center">평가명</th>
              <th className="text-center">구분</th>
              <th className="text-center">최고점수</th>
              <th className="text-center">분리간격</th>
              <th className="text-center">관리</th>
            </tr>
          </thead>
          <tbody>
            {detail_tags}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailArea;