import TimeNameFunc from "./set_name/name_func";
import TdDataFunc from "./td_data/td_data_func";
import TableOptionFunc from "./table_option/table_option_func";
import TrTdDataFunc from "./tr_td_data/tr_td_data_func";

class LectureTimeFunc {
  static get_table_option_by_lecture_arr = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      teacher_arr: [],
      room_arr: [],
      is_teacher_nickname: false,
      is_show_th_stu_len: false,
      ...inData
    };
    let lecture_arr = TimeNameFunc.get_lecture_info_arr_of_teacher_name({
      lecture_arr: opt_obj["lecture_arr"],
      teacher_arr: opt_obj["teacher_arr"],
      is_teacher_nickname: opt_obj["is_teacher_nickname"],
    });

    let table_option: any = {
      lecture_arr: lecture_arr,
      start_time_m: 60 * 17,//분
      end_time_m: 60 * 18,//분
      split_term: 30,//분리간격 분
      table_x_size: lecture_arr.length,
      table_y_size: 0,
      lecture_col_span_arr: {},//{lecture_seq:number}
      split_time_m_arr: [],//[30,60,..] 분단위
      td_data_arr: [],//default_td_data_row
      table_th_arr: [],//[[{x,y,col_span,row_span,td_data,is_view,title},..]]
      table_tr_td_arr: [],//[[{x,y,col_span,row_span,td_data,is_view},..]]
    };

    for (let i = 0; i < lecture_arr.length; i++) {
      let lecture_info = lecture_arr[i];
      table_option = TdDataFunc.get_table_option_by_lecture_info({
        lecture_info: lecture_info,
        table_option: table_option
      });
    }

    table_option.td_data_arr = TdDataFunc.get_day_of_week_group_of_td_data_arr(table_option.td_data_arr);
    table_option = TableOptionFunc.get_table_opt_of_colspan_rowspan(table_option);
    table_option.td_data_arr = TimeNameFunc.get_teacher_room_name_of_td_data_arr({
      td_data_arr: table_option.td_data_arr,
      teacher_arr: opt_obj.teacher_arr,
      room_arr: opt_obj.room_arr,
      is_teacher_nickname: opt_obj.is_teacher_nickname,
    });
    table_option = TableOptionFunc.get_split_time_arr_of_option({
      lecture_arr: lecture_arr,
      table_option: table_option
    });
    table_option.td_data_arr = TdDataFunc.get_ordered_stu_of_td_data_arr({
      td_data_arr: table_option.td_data_arr,
    });
    table_option = TrTdDataFunc.get_table_tr_td_arr({
      table_option: table_option,
      lecture_arr: opt_obj.lecture_arr,
      teacher_arr: opt_obj.teacher_arr,
      room_arr: opt_obj.room_arr,
      is_show_th_stu_len:opt_obj.is_show_th_stu_len,
    });
    table_option = TableOptionFunc.get_table_opt_xy_by_opt(table_option);

    return table_option;
  };
}
export default LectureTimeFunc;