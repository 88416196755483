import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import getXcolumnJson_stu from "@/pages/comp/edu/eval/card_list/xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";
import ExcelDownComponent from "@/pcomponents/common/excel/down";
import GetEvalExcelData from "./area/excel/eval_excel_down";
import { useSelector, useDispatch } from 'react-redux';

function ListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const xColumnArr_stu=getXcolumnJson_stu();
  const listAggridRef= useRef<any>();
  const ExcelDownComponentRef=useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_form_seq":"",
      "s_subject_like":"",
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq:user.user_grade=="master"?"":strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
      "s_addon_form":"1",
      "s_addon_detail":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("삭제되었습니다.");
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goExcelDown=()=>{
    if(ExcelDownComponentRef.current){
      let tmp_info_arr:any=[...infoArr];
      let excel_down_data_opt=GetEvalExcelData({
        infoArr:tmp_info_arr
      });
      ExcelDownComponentRef.current.setInitData(excel_down_data_opt);
      setTimeout(()=>{
        ExcelDownComponentRef.current.goDownExcel();
      },100);
    }
  };

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    return render_str;
  }, []);


  return (
    <CompLayout>
      <div className="con_wrap">
        <SearchArea
          listOpt={pageData.listOpt}
          xColumnArr={xColumnArr_stu}
          list={list}
        ></SearchArea>
        <div className="btn-box-left relative h-8 mt-2">
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-dark" onClick={goExcelDown}>엑셀다운</button>
            <button className="btn btn-dark" onClick={goDelete}>삭제</button>
          </div>
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:true,
          floatingFilter:true,
          onGridReady:()=>{
            list({});
          },
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
      </div>
      <ExcelDownComponent
        ref={ExcelDownComponentRef}
        xColumnArr={xColumnArr}
      ></ExcelDownComponent>
    </CompLayout>
  );
}

export default ListPage;