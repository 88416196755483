import { useState, useEffect } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";

function SearchArea(props:any){
   let myProps={
    list_opt:{},
    list:(inData:any)=>{},
    ...props
  };

  const [subjectArr,setsubjectArr] = useState<any>([]);
  useEffect(()=>{
    
    list_corse_subject();
  },[]);
  
  const go_search=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;
    myProps.list({
      "now_page":"1",
      [name]:value
    });
  };

  const list_corse_subject = ()=>{
    let subject_list_form_data={
      "order_id":"a_order_num, a_title",
      "s_is_use":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/list',subject_list_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setsubjectArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  return (
  <div>
    <select className="search-input_select" name="s_subject_seq" value={myProps.list_opt.s_subject_seq} 
      onChange={go_search} 
      style={{width:70}}>
      <option value="">구분</option>
      {
        subjectArr.map((item:any,idx:number)=>{
          return (
            <option value={item.a_seq} key={idx}>{item.a_title}</option>
          );
        })
      }
    </select>
    <input type="text" className="search-input" value={myProps.list_opt.s_title_like} name="s_title_like"
      onChange={go_search}
      placeholder="제목"
      style={{width:60}} />
    <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({});}}>검색</button>
  </div>
  );
};
export default SearchArea;