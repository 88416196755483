import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import ContentDivBox from "./content_div";

function TimeTableArea(props: any) {
  let myProps: any = {
    start_time: 16 * 60,
    end_time: 23 * 60,
    time_split_term: 30,//min
    split_height:50,
    lecture_arr: [],
    ...props
  };

  let td_box_style={height:myProps.split_height,lineHeight:myProps.split_height+"px"};

  const getThTrTag = () => {
    let th_tr_tag = (
      <tr>
        <th>
          <div className={Style.time_table_th_box} >
            시간
          </div>
        </th>
        {
          myProps.lecture_arr.map((lecture_info: any, lecture_idx: number) => {
            return (
              <th key={lecture_idx}>
                <div className={Style.time_table_th_box}>
                  {lecture_info.a_name} [{lecture_info.a_room_name}] ({lecture_info.a_main_teacher})
                </div>
              </th>
            );
          })
        }
      </tr>
    );
    return th_tr_tag;
  };

  const getBodyTdTag=(inData:any)=>{
    let opt_obj={
      row_time_min:0,
      ...inData
    };
    let row_time_start_sec=opt_obj.row_time_min*60;
    let row_time_end_sec=(opt_obj.row_time_min+myProps.time_split_term)*60;

    let td_tags=myProps.lecture_arr.map((lecture_info: any, lecture_idx: number) => {
      let content_div:any="";
      if(lecture_info.time_arr){
        let row_time_arr=[];
        for(let i=0;i<lecture_info.time_arr.length;i++){
          let time_info=lecture_info.time_arr[i];
          let tmp_start_time=strFunc.timeToSeconds(time_info["a_start_h"],time_info["a_start_m"],0);
          if(row_time_start_sec<=tmp_start_time&&tmp_start_time<row_time_end_sec){
            row_time_arr.push(time_info);
          }
        }
        if(row_time_arr.length!=0){
          content_div=(
            <ContentDivBox
              lecture_info={lecture_info}
              row_time_arr={row_time_arr}
              time_split_term={myProps.time_split_term}
              split_height={myProps.split_height}
            ></ContentDivBox>
          );
        }
      }

      return (
        <td key={lecture_idx}>
          <div className={Style.time_table_td_box} style={td_box_style} >
            {content_div}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const getBodyTrTag=()=>{
    let split_time_arr=[];
    for(let ing_time=myProps.start_time;ing_time<myProps.end_time;ing_time){
      split_time_arr.push(ing_time);
      ing_time=ing_time+myProps.time_split_term;
    }
    
    let body_tr_tags=split_time_arr.map((item:any,idx:number)=>{
      let rowt_start_json=strFunc.secondsToTimeJson(item*60);
      let rowt_end_json=strFunc.secondsToTimeJson((item+myProps.time_split_term)*60);
      let td_tags=getBodyTdTag({row_time_min:item});
      return  (
        <tr key={idx}>
          <td>
            <div className={Style.time_table_td_time_box} style={td_box_style} >
                {rowt_start_json["h"]}:{rowt_start_json["i"]} ~ {rowt_end_json["h"]}:{rowt_end_json["i"]}
            </div>
          </td>
          {td_tags}
        </tr>
      );
    });

    return body_tr_tags;
  };

  return (
    <div className={Style.time_table_wrap}>
      <table>
        <thead>
          {getThTrTag()}
        </thead>
        <tbody>
          {getBodyTrTag()}
        </tbody>
      </table>
    </div>
  );
}

export default TimeTableArea;