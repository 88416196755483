import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import getXcolumnJson from "../../xcolumn/list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudent from "@/pages/comp/basic/student/popup/find_multy";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";
import LoadingComponent from "@/pcomponents/common/content/loading";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    set_is_open_write:(inData:boolean)=>{},
    isUpdate:false,
    ...props
  };
  const xColumnArr=getXcolumnJson();
  let user=useSelector((state:any) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  let stu_grade_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  const fileWriteOneRef = useRef<any>(null);
  const FileListWriteFrameRef=useRef<any>(null);
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  let is_under_width=MyScreenFunc.get_is_under();
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_stu_seq":"",
    "a_stu_name":"",
    "a_sort1":"",
    "a_sort2":"",
    "a_kind":"학습",

    "a_counsel_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_reserve_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_reserve_time":"00:00:00",
    "a_writer_seq":user.user_seq,
    "a_writer":user.user_name,
    "a_counseler_seq":user.user_seq,
    "a_counseler":user.user_name,
    "a_title":"상담",
    "a_content":"",
    "a_feedback":"",
    "a_eval_score":"",
    "a_reference":"",
    "a_is_success":"1",
    "a_state":"complete",
  };
  let select_arr_a_kind:any=[];
  if(xColumnArr.select_arr.a_kind){
    select_arr_a_kind=xColumnArr.select_arr.a_kind;
  }
  let select_arr_eval_score=[
    {"value":"100","text":"A"},
    {"value":"80","text":"B"},
    {"value":"60","text":"C"},
    {"value":"40","text":"D"},
    {"value":"20","text":"E"},
  ];
  const [rowData,setRowData]=useState<any>({
   ...init_data
  });
  const [loading_data, set_loading_data] = useState({
    is_display:false,
    text:"저장중..",
  });
  const last_info_ref=useRef<any>(rowData);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    isUpdate:false,
    s_except_user_seq:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
    if(FileListWriteFrameRef.current&&optObj.isUpdate){
      FileListWriteFrameRef.current.list({
        s_par_id:xColumnArr.table,
        s_par_seq:xColumnArr.func.getPriValByDataRow({
          xColumnArr:xColumnArr,
          rowData:optObj.row_data
        }),
        s_start_date:optObj.row_data.a_date,
        "now_page":1
      });
    }
    last_info_ref.current=optObj.row_data;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    //a_state
    
    let change_row_data:any={...rowData};
    if(name=="a_state"){
      if(value=="complete"){
        change_row_data["a_is_success"]="1";
        if(strFunc.is_empty(change_row_data["a_counsel_date"])){
          change_row_data["a_counsel_date"]=DateFunc.get_date_format(new Date(),"Y-m-d");
        }
      }else{
        change_row_data["a_is_success"]="";
      }
    }else if(name=="a_reserve_date"){
      change_row_data["a_counsel_date"] = value;
    }

    change_row_data[name]=value;
    setRowData(change_row_data);
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let change_row_data:any={...rowData};
    change_row_data[key]=date_str;
    if(key=="a_reserve_date"){
      change_row_data["a_counsel_date"] = date_str;
    }
    setRowData(change_row_data);
  };

  const setStudentData=(inData:any)=>{
    let optObj={
      info_arr:[],
      row_num:0,
      key:"",
      ...inData
    };
    if(optObj["info_arr"].length==0){
      alert("학생 선택이 없습니다.");
      return false;
    }
    let stu_info=optObj["info_arr"][0];
    setRowData({
      ...rowData,
      "a_stu_seq":stu_info["a_seq"],
      "a_stu_name":stu_info["a_user_name"],
    });
  };

  const openFIndStudentPopup=()=>{
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find",//view
      title:"학생 찾기",
    });
  };

  const goWrite=(e:any) => {
    if(rowData.a_stu_seq==""){
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }

    let wRowData={...rowData};
    if(!isUpdate){
      wRowData["a_ymd"]=strFunc.str_replace("-","",wRowData["a_reserve_date"]);
    }
    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    set_loading_data({
      is_display:true,
      text:"저장중..",
    });
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_loading_data({
          is_display:true,
          text:"저장 하였습니다.",
        });
        setTimeout(() => {
          set_loading_data({
            is_display:false,
            text:"저장중..",
          });
        }, 1000);
        last_info_ref.current=response.data["data"][0];
        let last_pri_val=last_info_ref.current["a_ymd"]+","+last_info_ref.current["a_seq"];
        setInitData({
          row_data:response.data["data"][0],
          isUpdate:true,
          is_under_width:is_under_width
        });
        if(fileWriteOneRef.current){
          let childFileInput=fileWriteOneRef.current.getFileInput();
          if(childFileInput.current.value==""){
            props.list({select_seq:last_pri_val});
            if(is_under_width){
              myProps.set_is_open_write(false);
            }
          }else{
            let priVal=xColumnArr.func.getPriValByDataRow({
              xColumnArr:xColumnArr,
              rowData:response.data["data"][0]
            });
            fileWriteOneRef.current.goWrite({
              "a_par_seq":priVal
            });
          }
        }else{
          props.list({select_seq:last_pri_val});
          if(is_under_width){
            myProps.set_is_open_write(false);
          }
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  let stu_grade_str="";
  let stu_grade_origin="";
  if(rowData.stu_info){
    stu_grade_origin=rowData.stu_info["a_stu_grade"];
    for(let i=0;i<stu_grade_select_option_arr.length;i++){
      if(stu_grade_select_option_arr[i]["value"]==stu_grade_origin){
        stu_grade_str=stu_grade_select_option_arr[i]["text"];
      }
    }
  }

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>
                {rowData.a_stu_name} {stu_grade_str}
                {isUpdate==false&&
                  <button className="btn-s btn-dark ml-2 py-1" onClick={()=>{openFIndStudentPopup();}} >학생찾기</button>
                }
              </td>
              <th>상태</th>
              <td>
                <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange} >
                  <option value="ready">준비</option>
                  <option value="complete">완료</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>상담예정</th>
              <td>
                <DatePicker 
                    selected={rowData.a_reserve_date!=""?new Date(rowData.a_reserve_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_reserve_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
              </td>
              <th>상담일</th>
              <td>
                <DatePicker 
                    selected={rowData.a_counsel_date!=""?new Date(rowData.a_counsel_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_counsel_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
              </td>
            </tr>
            <tr>
              <th>예정시간</th>
              <td>
                <TimeInputComponent
                  name={"a_reserve_time"}
                  value={rowData.a_reserve_time}
                  on_change={(inData:any)=>{
                    handleInputChange({
                      target:{
                        name:inData.name,
                        value:inData.value,
                      }
                    });
                  }}
                ></TimeInputComponent>
              </td>
              <th>구분</th>
              <td>
                <SelectBoxBar
                  valueTextArr={select_arr_a_kind}
                  value={rowData.a_kind}
                  name={"a_kind"}
                  on_change={(inData:any)=>{
                    handleInputChange({
                      target:{
                        name:inData.name,
                        value:inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>상담내용</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_content"
                  value={rowData.a_content}
                  onChange={handleInputChange}
                  placeholder={"내용"}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <th>피드백</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_feedback"
                  value={rowData.a_feedback}
                  onChange={handleInputChange}
                  placeholder={"피드백"}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <th>평가</th>
              <td colSpan={3}>
                <SelectBoxBar
                  valueTextArr={select_arr_eval_score}
                  value={rowData.a_eval_score}
                  name={"a_eval_score"}
                  on_change={(inData:any)=>{
                    handleInputChange({
                      target:{
                        name:inData.name,
                        value:inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>참고내용</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name="a_reference"
                  value={rowData.a_reference}
                  onChange={handleInputChange}
                  placeholder={"참고내용"}
                ></TextAreaComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        rowData.a_seq=="" && 
        <WriteInputFrame
        ref={fileWriteOneRef}
        init_data={{
          a_par_id:xColumnArr.table,
          a_par_seq:rowData.a_ymd+","+rowData.a_seq,
        }}
        callback={()=>{
          props.list({
            select_seq:last_info_ref.current.a_ymd+","+last_info_ref.current.a_seq
          });
          if(is_under_width){
            myProps.set_is_open_write(false);
          }
        }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        {is_under_width&&
          <button className="btn btn-line-gray" onClick={()=>{myProps.set_is_open_write(false);}}>목록</button>
        }
      </div>
      {
        rowData.a_seq!="" && 
        <FileListWriteFrame
          ref={FileListWriteFrameRef}
          listOpt={
            {
              s_par_id:xColumnArr.table,
              s_par_seq:xColumnArr.func.getPriValByDataRow({
                xColumnArr:xColumnArr,
                rowData:rowData
              }),
              s_start_date:rowData.a_reserve_date
            }
          }
          callback={(inData:any)=>{myProps.list({
            select_seq:last_info_ref.current.a_ymd+","+last_info_ref.current.a_seq
          });}}
        ></FileListWriteFrame>
      }
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
        {popupData.sort==="find"&&
            <FindStudent isUpdate={popupData.isUpdate} 
              callBackData={(inData:any)=>{setStudentData(inData);}} 
              listOpt={{
                s_except_user_seq:popupData.s_except_user_seq
              }}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindStudent>
          }

      </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
});

export default WriteFrameContent;
