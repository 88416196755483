import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import Style from "./css/style.module.css";

const ListSearchComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "listOpt":{
      's_start_date':'',
      's_end_date':'',
    },
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "list":(inData:any)=>{},
    "onClickWrite":(inData:any)=>{},
    ...props
  };
  const [listOpt, setListOpt] = useState(myProps.listOpt);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    
  }));

  const goSearch=(inOptObj:any)=>{
    let optObj={
      "key":"",
      "value":"",
      ...inOptObj
    };
    if(optObj["key"]==""){
      return false;
    }
    let key=optObj["key"];
    let value=optObj["value"];

    setListOpt({
      ...listOpt,
      ...{
        [key]:value
      }
    });
    myProps.list({[key]:value});
  };

  const goMoveNexePreDate=(move_sort:"pre"|"next")=>{
    let s_start_date=listOpt.s_start_date;
    let s_end_date=listOpt.s_end_date;
    if(s_start_date==""){
      s_start_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    //이동
    if(move_sort=="next"){
      let next_date=DateFunc.get_change_date(new Date(s_start_date),'day',+1)
      s_start_date=DateFunc.get_date_format(next_date,"Y-m-d");
    }else{
      let next_date=DateFunc.get_change_date(new Date(s_start_date),'day',-1)
      s_start_date=DateFunc.get_date_format(next_date,"Y-m-d");
    }
    s_end_date=s_start_date;
    setListOpt({
      ...listOpt,
      ...{
        "s_start_date":s_start_date,
        "s_end_date":s_end_date
      }
    });
    myProps.list({
      "s_start_date":s_start_date,
      "s_end_date":s_end_date,
      "now_page":1
    });
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{
        [key]:date_str
      }
    });
    myProps.list({[key]: date_str,"now_page":1});
  };

  return (
    <div className={Style.search_box}>
      <div style={{position:"relative"}}>
        <button className="btn-s btn-dark ml-1 px-1" onClick={()=>{goMoveNexePreDate("pre");}} >◀</button>
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_start_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />~
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_end_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
        <button className="btn-s btn-dark ml-1 px-1" onClick={()=>{goMoveNexePreDate("next");}} >▶</button>
        <button className="btn btn-blue ml-3" onClick={()=>{myProps.onClickWrite({});}} style={{background:"#a1ac36"}}>계획추가</button>
        <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({});}} >검색</button>
      </div>
    </div>
  );
});

export default ListSearchComponent;