import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';

function SelectStuAllowDeskPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    "stu_info": null,
    "desk_info": null,
    "now_ymdhis": "",
    "now_lecture_data": null,
    "add_time_sec": 60 * 60,
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        "desk_info": null,
        "now_ymdhis": "",
        select_time:{
          "start_h": "00",
          "start_m": "00",
          "end_date": "0000-00-00",
          "end_h": "00",
          "end_m": "00",
        },
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };

  let stu_info = myProps.stu_info;

  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec = strFunc.timeToSeconds(now_date_json.h, now_date_json.i, now_date_json.s);
  let now_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(myProps.now_ymdhis),'sec',myProps.add_time_sec));
  let default_select_time = {
    "start_h": now_date_json.h,
    "start_m": now_date_json.i,
    "end_date": now_next_date_json.Y+"-"+now_next_date_json.m+"-"+now_next_date_json.d,
    "end_h": now_next_date_json.h,
    "end_m": now_next_date_json.i,
  };
  const [select_time, set_select_time] = useState(default_select_time);
  let select_time_term_minute =
    strFunc.timeToSeconds(select_time.end_h, select_time.end_m, 0)
    - strFunc.timeToSeconds(select_time.start_h, select_time.start_m, 0);
  if (select_time_term_minute != 0) {
    if(select_time_term_minute<0){
      select_time_term_minute=86400+select_time_term_minute;
    }
    select_time_term_minute = select_time_term_minute / 60;
  }

  useEffect(() => {
    if (strFunc.is_empty(stu_info)) {
      alert("학생 정보가 없습니다.");
      myProps.closePopup();
    }
  }, []);

  const onChangeTimeRowInput = (key: string, val: string) => {
    let time_col_arr = [
      "start_h", "start_m",
      "end_h", "end_m"
    ];
    if (strFunc.str_in_array(key, time_col_arr) != -1) {
      val = strFunc.comma(val);
      val = val.substring(0, 2);
      if (key == "start_h" || key == "end_h") {
        if (parseInt(val) >= 24) {
          val = "23";
        }
      } else {
        if (parseInt(val) >= 60) {
          val = "59";
        }
      }
      if (parseInt(val) < 0) {
        val = "0";
      }
    }
    set_select_time({
      ...select_time,
      [key]: val
    });
  };

  const go_change_time_input_by_plus_minus = (inData: any) => {
    let opt_obj = {
      "sort": "static",//static,add
      "plus_min": 0,
      "static_min": 0,
      ...inData
    };
    //now_sec
    //next_sec
    let static_min = parseInt(strFunc.uncomma(opt_obj["static_min"]));
    let plus_min = parseInt(strFunc.uncomma(opt_obj["plus_min"]));
    let change_next_sec = 0;
    let change_next_date_json:any=null;
    if (opt_obj["sort"] == "static") {
      change_next_sec = (static_min * 60);
      change_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(myProps.now_ymdhis),'sec',change_next_sec));
    } else if (opt_obj["sort"] == "add") {
      change_next_sec += (plus_min * 60);
      let tmp_end_ymdhis=select_time["end_date"]+" "+
        strFunc.str_pad({"str":select_time.end_h,"pad_length":2})+
        ":"+strFunc.str_pad({"str":select_time.end_m,"pad_length":2})+
        ":"+strFunc.str_pad({"str":now_date_json.s,"pad_length":2});
      change_next_date_json=DateFunc.get_date_json(DateFunc.get_change_date(new Date(tmp_end_ymdhis),'sec',change_next_sec));
    }
    //하루이상 안되게
    let start_end_diff=change_next_date_json.date_obj.getTime()-now_date_json.date_obj.getTime();
    let start_end_diff_sec=start_end_diff/1000;
    
    if(start_end_diff_sec>=86400){
      return false;
    }
    
    //지금보다 이전이면 안되게
    if(now_date_json.date_obj.getTime()>=change_next_date_json.date_obj.getTime()){
      return false
    }

    let change_select_time:any={...select_time};
    change_select_time["end_h"]=change_next_date_json.h;
    change_select_time["end_m"]=change_next_date_json.i;
    change_select_time["end_date"]=change_next_date_json.Y+"-"+change_next_date_json.m+"-"+change_next_date_json.d;
    set_select_time(change_select_time);
  };

  const goCallBackData = () => {
    let selected_row: any = [stu_info];

    myProps.callBackData({
      info_arr: selected_row,
      "desk_info": myProps.desk_info,
      "now_ymdhis": myProps.now_ymdhis,
      "select_time": select_time,
    });
    myProps.closePopup();
  };

  return (
    <div>
      {!strFunc.is_empty(stu_info) &&
        <div>
          <div className="text-center">
            <span style={{ fontWeight: "bold",fontSize:20 }}>{stu_info.a_user_name}</span>
            <span className="ml-1">학생</span>
          </div>
          <div className="text-center mt-1">
            <span style={{ fontWeight: "bold" }}>{myProps.desk_info.a_name}</span>
            <span className="ml-1">책상</span>
          </div>
        </div>
      }
      <div className="text-center mt-1">
        할당시간
        <span className="mx-2">
          <input type="text" className="text-center" name="start_h" value={strFunc.str_pad({ "str": select_time.start_h, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />:
          <input type="text" className="text-center" name="start_m" value={strFunc.str_pad({ "str": select_time.start_m, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />
        </span>
        ~
        <span className="mx-2">
          <input type="text" className="text-center" name="end_h" value={strFunc.str_pad({ "str": select_time.end_h, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />:
          <input type="text" className="text-center" name="end_m" value={strFunc.str_pad({ "str": select_time.end_m, "pad_length": 2 })}
            onChange={(e: any) => { onChangeTimeRowInput(e.target.name, e.target.value); }} style={{ width: 30 }} />
        </span>
        <span style={{ fontWeight: "bold", color: "blue" }}>
          ({select_time_term_minute}분)
        </span>
      </div>
      <div className="text-center mt-2">
        <button className="btn btn-dark"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 60 })
          }}>1시간</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 90 })
          }}>1시간반</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "static", "static_min": 120 })
          }}>2시간</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": 1 })
          }}>+1분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": 10 })
          }}>+10분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": -1 })
          }}>-1분</button>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_time_input_by_plus_minus({ "sort": "add", "plus_min": -10 })
          }}>-10분</button>
      </div>
      <div className="btn-box-center mt-3">
        <button className="btn-l btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
        <button className="btn-l btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default SelectStuAllowDeskPopup;