import person_ico2 from "@/img/ico/person_ico2.png";
import strFunc from "@/lib/lyg/string";

function TopStuInfoArea(props:any){
   let myProps={
    Style:{},
    info:{},
    xColumnArr:{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let xColumnArr=myProps.xColumnArr;

  let profile_img_src=person_ico2;
  if(info.profile_arr&&info.profile_arr.length>0){
    if(info.profile_arr[0]["is_image"]=="1"){
      profile_img_src=info.profile_arr[0]["thum_img_url"];
    }
  }

  let stu_state_str="";
  let stu_state_color="orange";
  if(xColumnArr.select_arr.a_stu_state){
    for(let i=0;i<xColumnArr.select_arr.a_stu_state.length;i++){
      if(xColumnArr.select_arr.a_stu_state[i]["value"]==info["a_stu_state"]){
        stu_state_str=xColumnArr.select_arr.a_stu_state[i]["text"];
      }
    }
  }

  let stu_grade_str=info["a_stu_grade"];
  if(xColumnArr.select_arr["a_stu_grade"]){
    for(let i=0;i<xColumnArr.select_arr["a_stu_grade"].length;i++){
      let select_opt_info=xColumnArr.select_arr["a_stu_grade"][i];
      if(select_opt_info["value"]==info["a_stu_grade"]){
        stu_grade_str=select_opt_info["text"];
      }
    }
  }

  let app_join_str:any="";
  if(info.comp_link_info){
    app_join_str=(<span className="text-green-500">가입</span>);
  }
  if(info.comp_link_info){
    app_join_str=(<span className="text-gray-500" title={"로그아웃"}>가입</span>);
    if(info.comp_link_info.main_u_info&&info.comp_link_info.main_u_info.a_push_token){
      app_join_str=(<span className="text-green-500">가입</span>);
    }
  }

  let class_name_text="";
  if(info.class_arr){
    let class_name_arr=[];
    for(let i=0;i<info.class_arr.length;i++){
      class_name_arr.push(
        strFunc.cut_str(info.class_arr[i]["class_name"],4,"")
      );
    }
    class_name_text=class_name_arr.join(" / ");;
  }


  
  return (
  <div className={Style.top_stu_info_wrap}>
    <table>
      <colgroup>
        <col width={"50%"}></col>
        <col width={"*"}></col>
      </colgroup>
      <tbody>
        <tr>
          <td>
            <div className={Style.top_stu_info_profile_wrap}>
              <img src={profile_img_src} />
            </div>
          </td>
          <td style={{verticalAlign:"top"}}>
            <div className={Style.top_stu_info_right}>
              <div className={Style.top_stu_info_right_title}>
                {info.a_user_name}
                {info.a_stu_total_code&&
                  <span className="ml-1" style={{fontSize:"12px"}}>{info.a_stu_total_code}</span>
                }
                {stu_state_str!=""&&
                  <span className="ml-1" style={{color:stu_state_color}}>[{stu_state_str}]</span>
                }
              </div>
              <div>
                {info.a_school_name}
              </div>
              <div>
                {stu_grade_str}
              </div>
              <div>
                {class_name_text}
              </div>
              <div>
                {info["a_join_date"]}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
};
export default TopStuInfoArea;