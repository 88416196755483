import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "./css/style.module.css";
import noimg_img from "@/img/ico/noimg.png";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import TodoRowTag from "./area/row_tag";

const ListCardComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "infoArr":[],
    "xColumnArr":{
      "x_column_list_arr":[]
    },
    "gridOpt":{},
    "customCellRenderer":(params:any)=>{},
    "onClickRow":(inOptObj:any)=>{
      let optObj={
        row_num:0,
        row_data:{},
        ...inOptObj
      };
    },
    "onClickMore":(inOptObj:any)=>{},
    "onClickDeleteRow":(inOptObj:any)=>{},
    "onClickModify":(inOptObj:any)=>{},
    "onClickComplete":(inOptObj:any)=>{},
    ...props
  };

  const [infoArr, setInfoArr] = useState(myProps.infoArr);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const setInitData=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
      infoArr:infoArr,
     ...inData
    }

    setInfoArr(optObj.infoArr);
  };

  const getTodoTags=()=>{
    let todo_tags=infoArr.map((item:any,idx:number)=>{
      let click_in_data={
        "row_data":item,
        "row_num":idx
      };
      return (
        <div key={idx}>
          <TodoRowTag
            row_data={item}
            onClickRow={(inData:any)=>{myProps.onClickRow(click_in_data);}}
            onClickMore={(inData:any)=>{myProps.onClickMore(click_in_data);}}
            onClickDeleteRow={(inData:any)=>{myProps.onClickDeleteRow(click_in_data);}}
            onClickModify={(inData:any)=>{myProps.onClickModify(click_in_data);}}
            onClickComplete={(inData:any)=>{myProps.onClickComplete(click_in_data);}}
          ></TodoRowTag>
        </div>
      );
    });
    return todo_tags;
  };

  let todo_list_tags:any=getTodoTags();

  if(todo_list_tags==""){
    todo_list_tags=(
      <div className={Style.empty_box}>
        내용이 없습니다.
      </div>
    );
  }

  return (
    <div className={Style.todo_list_wrap}>
      {todo_list_tags}
    </div>
  );
});

export default ListCardComponent;
