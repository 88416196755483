import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import strFunc from '@/lib/lyg/string';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import ExcelUploadArea from "@/pcomponents/common/excel/excel_upload_popup";
import DateFunc from '@/lib/lyg/date_func';

function WriteMultyPopup(props:any){
  const myProps={
    closePopup:()=>{},
    callback:()=>{},
    isUpdate:false,
    listOpt:{},
    row_num:0,
    key:"",
    callBackData:(data:any)=>{
      let optObj={
        info_arr:[],
        row_num:0,
        key:"",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const ListComponentRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      ...myProps.listOpt
    }
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [isOpenExcel,setIsOpenExcel]=useState(false);

  useEffect(() => {
    
  }, []);

  let default_row_data={
    "a_seq":"",
    "a_corp_seq":"1",
    "a_user_id":"",
    "a_user_pw":"",
    "a_user_grade":"student",
    "a_is_login":"1",
    "a_user_name":"",
    "a_user_phone":"",

    "a_stu_state":"now",
    "a_stu_num":"",
    "a_class_seq":"",
    "a_gender":"",
    "a_school_name":"",
    "a_stu_grade":"",
    "a_join_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_stu_birth_date":"",
    "a_stu_supply_day":"",
    "a_stu_supply_discount":"0",
    "a_memo":"",

    "par_relation":"모",
    "par_phone":"",
    "par_user_id":"",
    "par_user_pw":"",
    "par_is_attend_sms":"",
    "par_is_attend_push":"1",
    "par_is_attend_katalk":"",
  };

  const addRowByData=(inRowData:any)=>{
    let rowData={
      ...default_row_data,
      ...inRowData
    };
    let info_arr=[...infoArr];
    if(ListComponentRef.current){
      info_arr=ListComponentRef.current.getRows();
      info_arr.push(rowData);
      ListComponentRef.current.setInfoArr(info_arr);
      setInfoArr(info_arr);
    }
  };

  const removeRowByData=()=>{
    if(ListComponentRef.current==null){
      return false;
    }
    let all_rows=ListComponentRef.current.getRows();
    let select_rows=ListComponentRef.current.getSelectedRows();
    let now_rows=[];
    for(let i=0;i<all_rows.length;i++){
      let is_select=false;
      for(let j=0;j<select_rows.length;j++){
        if(select_rows[j].idx_num==i){
          is_select=true;
        }
      }
      if(is_select==false){
        now_rows.push(all_rows[i]);
      }
    }
    ListComponentRef.current.setInfoArr(now_rows);
    setInfoArr(now_rows);
  };

  const onExcelCallBack=(rows:any)=>{
    let add_row_arr=[];
    for(let i=0;i<rows.length;i++){
      add_row_arr.push({
        ...default_row_data,
        ...rows[i],
      });
    }
    if(ListComponentRef.current){
      let pre_info_arr=ListComponentRef.current.getRows();
      pre_info_arr=[
        ...pre_info_arr,
        ...add_row_arr
      ];
      ListComponentRef.current.setInfoArr(pre_info_arr);
      setInfoArr(pre_info_arr);
    }
  };

  const onWriteMulty=()=>{
    let select_rows:any=[];
    if(ListComponentRef.current){
      select_rows=ListComponentRef.current.getRows();
    }
    if(select_rows.length==0){
      alert("데이터가 없습니다.");
      return false;
    }
    if(!confirm("등록 하시겠습니까?")){
      return false;
    }

    //학부모 핸드폰 끝자리와 출결번호 세팅
    for(let i=0;i<select_rows.length;i++){
      let info=select_rows[i];
      if(!strFunc.is_empty(info["par_phone"])&&info["par_phone"].length>8){
        if(strFunc.is_empty(info["a_stu_num"])){
          select_rows[i]["a_stu_num"]=strFunc.getNumber2(info["par_phone"].slice(-4));
        }
      }
    }

    let formJsonData={
      "data_arr":select_rows,
      "is_default_val":"1",
      "is_update":"",
    };
    if(myProps.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write_student',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        goWriteParentUser(response.data["data"],select_rows,response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteParentUser=(user_arr:any,select_rows:any,success_msg:string)=>{
    //세팅
    let last_user_arr=[];
    for(let i=0;i<user_arr.length;i++){
      let u_info=user_arr[i];
      for(let j=0;j<select_rows.length;j++){
        let select_row=select_rows[j];
        if(u_info["a_user_name"]==select_row["a_user_name"]){
          last_user_arr.push({
            ...select_row,
            ...u_info
          });
        }
      }
    }

    //얻기
    let parentArr:any=[];
    for(let i=0;i<last_user_arr.length;i++){
      let u_info=last_user_arr[i];
      
      if(u_info["par_phone"]==""||u_info["par_phone"].length<10){
        continue;
      }

      let par_name=u_info["a_user_name"];
      if(u_info["par_relation"]=="모"){
        par_name+=" 어머니";
      }else if(u_info["par_relation"]=="부"){
        par_name+=" 아버지";
      }else if(u_info["par_relation"]=="etc"){
        par_name+=" 부모님";
      }else{
        par_name+=" "+u_info["par_relation"];
      }

      parentArr.push({
        "a_stu_seq":u_info["a_seq"],
        "a_user_seq":"",
        "a_stu_name":u_info["a_user_name"],
        "a_user_name":par_name,
        "a_type":"parent",
        "a_relation":u_info["par_relation"],
        "a_order":0,
        "a_is_attend_sms":u_info["par_is_attend_sms"],
        "a_is_attend_push":u_info["par_is_attend_push"],
        "a_is_attend_katalk":u_info["par_is_attend_katalk"],
        "par_phone":u_info["par_phone"],
        "par_user_id":u_info["par_user_id"],
        "par_user_pw":u_info["par_user_pw"],
      });
    }

    if(parentArr.length==0){
      alert(success_msg);
      myProps.callBackData({});
      myProps.closePopup();
    }
    
    let formJsonData={
      parentArr:parentArr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write_parent_arr',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(success_msg);
        myProps.callBackData({});
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const cellClickedListener = useCallback( (rowData:any,idx:number,e:any) => {
    
  }, []);

  const cellRenderer= useCallback( (inOpt:any) => {
    let opObj={
      "rowData":{},
      "idx":0,
      "key":"",
      "value":"",
      ...inOpt
    };

    return opObj.value;
  }, []);

  const gridCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
   if(params.key=="a_stu_num"){
      params.input_tag=(
      <input type="text" className="row-input" name={params.key} value={strFunc.getNumber2(params.value)}
        onChange={(e:any)=>{
            params.onChangeInput({
              "rowData":params.rowData,
              "row_num":params.idx,
              "key":params.key,
              "value":e.target.value,
              "pre_value":params.value,
              "event":e,
          });
        }} 
        style={{width:"98%"}} />
      );
    }
    return params.input_tag;
  };

  return (
      <div>
        <div className="btn-box-left relative" style={{minHeight:30}}>
          <b>총 <span className="text-blue-600">{infoArr.length}</span> 개</b>
          <span className="px-2">|</span>
          <button className="btn btn-blue" onClick={()=>{onWriteMulty();}}>등록</button>
          <button className="btn btn-line-gray" onClick={()=>{addRowByData({});}}>추가</button>
          <button className="btn btn-line-gray" onClick={()=>{removeRowByData();}}>제거</button>

          <button className="btn btn-line-gray ml-3" onClick={()=>{setIsOpenExcel(true);}}>엑셀업로드</button>
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
          </div>
        </div>
        <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={{...xColumnArr}}
          gridOpt={{
            isGrid:true,
            is_add_idx_num:false,
            is_add_checkbox:true,
            td_align:"left",//center,right
            table_align:"left",//center,right
            onGridReady:()=>{},
            rowOnClick:cellClickedListener,
            customCellRenderer:cellRenderer,
            customGridCellRenderer:gridCellRenderer
          }}
        ></ListComponent>

        {isOpenExcel&&
          <ExcelUploadArea
            onClose={()=>{setIsOpenExcel(false);}}
            onCallback={(inData:any)=>{onExcelCallBack(inData);}}
            xColumnArr={xColumnArr}
          ></ExcelUploadArea>
        }
      </div>
  );
}

export default WriteMultyPopup;