import { createSlice } from '@reduxjs/toolkit';

export const darkSlice = createSlice({
  name: 'darkmode',
  initialState: {
    dark:"",//dark,light
  },
  reducers: {
    setDark: (state, action) => {
      state.dark =action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setDark } = darkSlice.actions;

export default darkSlice.reducer;