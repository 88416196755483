import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteFrameContent from "./area/write";

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "select_cat_seq":"",
      "s_addon_title_img":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const WriteFrameContentRef=useRef<any>(null);
  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        //선택
        if(pageData.listOpt.select_cat_seq!=""){
          for(let i=0;i<response.data["data"]["info_arr"].length;i++){
            let info=response.data["data"]["info_arr"][i];
            if(info["a_seq"]==pageData.listOpt.select_cat_seq){
              WriteFrameContentRef.current.setInitData({
                row_data:info,
                isUpdate:true,
              });
            }
          }
          setTimeout(()=>{
            let listNodes=listAggridRef.current.getNodeRows();
            for(let i=0;i<listNodes.length;i++){
              let node=listNodes[i];
              if(node.data["a_seq"]==pageData.listOpt.select_cat_seq){
                listNodes[i].setSelected(true);
              }
            }
          },100);
          
        }
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        setWriteState();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const setWriteState=()=>{
    listAggridRef.current.setSelectFalseAll();
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData();
    }
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData({
        row_data:params.data,
        isUpdate:true,
      });
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="btn-box-left relative h-8">
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-dark" onClick={()=>{setWriteState();}}>신규</button>
            <button className="btn btn-dark" onClick={goDelete}>삭제</button>
          </div>
        </div>
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"50%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:230}} >
                  <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{...xColumnArr}}
                  list={list}
                  gridOpt={{
                    fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num:true,
                    is_add_checkbox:false,
                    floatingFilter:true,
                    onGridReady:()=>{
                      list({});
                    },
                    onCellClicked:cellClickedListener,
                    cellRenderer:cellRenderer,
                  }}
                  ></ListAggrid>
                  <Paging now_page={pageData.listOpt.now_page}
                    num_per_page={pageData.listOpt.num_per_page}
                    total_rec={countInfo.tot} 
                    onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:300}} >
                  <WriteFrameContent
                    ref={WriteFrameContentRef}
                    list={(listOpt:any)=>{list(listOpt);}}
                  ></WriteFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default ListPage;