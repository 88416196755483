import strFunc from "@/lib/lyg/string";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function StuListArea(props: any) {
  let myProps = {
    Style: {},
    time_info: {},
    is_same_days: false,
    same_days_week_arr: [],
    box_student_arr: [],
    user_time_arr: [],
    set_user_time_arr: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  let able_day_num_arr:any = [];
  if (myProps.is_same_days) {
    able_day_num_arr = myProps.same_days_week_arr;
  } else {
    able_day_num_arr = [myProps.time_info["a_day_num"]];
  }
  const get_able_select_day_option_arr = () => {

    let able_select_day_option_arr_init = [
      { "value": "0", "text": "일" },
      { "value": "1", "text": "월" },
      { "value": "2", "text": "화" },
      { "value": "3", "text": "수" },
      { "value": "4", "text": "목" },
      { "value": "5", "text": "금" },
      { "value": "6", "text": "토" },
    ];
    let able_select_day_option_arr: any = [];
    for (let i = 0; i < able_select_day_option_arr_init.length; i++) {
      let opt_row = able_select_day_option_arr_init[i];
      if (strFunc.str_in_array(opt_row["value"], able_day_num_arr) != -1) {
        able_select_day_option_arr.push(opt_row);
      }
    }

    return able_select_day_option_arr;
  }
  let able_select_day_option_arr = get_able_select_day_option_arr();

  const on_change_select_day_num=(inData: any)=>{
    let opt_obj = {
      student_info: {},
      select_day_num_arr:[],
      ...inData
    };
    let student_info = opt_obj.student_info;
    if (strFunc.is_empty(student_info["a_user_seq"])) {
      return false;
    }

    let del_day_num_arr=[];
    let add_day_num_arr=[];
    for(let i=0;i<able_day_num_arr.length;i++){
      let able_day=able_day_num_arr[i];
      if(strFunc.str_in_array(able_day,opt_obj.select_day_num_arr)==-1){
        del_day_num_arr.push(able_day);
      }else{
        if(strFunc.str_in_array(able_day,student_info.days_week_arr)==-1){
          add_day_num_arr.push(able_day);
        }
      }
    }

    //해당 유저 제거
    let new_user_time_arr = [];
    for (let i = 0; i < myProps.user_time_arr.length; i++) {
      let row_user_time = myProps.user_time_arr[i];
      let is_able_add = true;

      if (row_user_time["a_stu_seq"] == student_info["a_user_seq"]
        &&strFunc.str_in_array(row_user_time["a_day_num"], del_day_num_arr) != -1
        &&row_user_time["a_seq"] == myProps.time_info["a_seq"]) {
        is_able_add = false;
      }

      if (is_able_add) {
        new_user_time_arr.push(row_user_time);
      }
    }
    //추가
    if(add_day_num_arr.length>0&&student_info.user_time_arr&&student_info.user_time_arr.length>0){
      let default_user_time_row=student_info.user_time_arr[0];
      for(let i=0;i<add_day_num_arr.length;i++){
        let add_day_num=add_day_num_arr[i];
        let add_user_time={...default_user_time_row};
        add_user_time.a_day_num=add_day_num;
        new_user_time_arr.push(add_user_time);
      }
    }
    myProps.set_user_time_arr(new_user_time_arr);
  };

  const remove_user_time=(inData: any)=>{
    let opt_obj={
      stu_seq:"",
      ...inData
    };
    
    let new_user_time_arr=[];
    for (let i = 0; i < myProps.user_time_arr.length; i++) {
      let row_user_time = myProps.user_time_arr[i];
      if(row_user_time["a_stu_seq"]==opt_obj["stu_seq"]
        &&strFunc.str_in_array(row_user_time["a_day_num"],able_day_num_arr)!=-1
        &&row_user_time["a_seq"]==myProps.time_info["a_seq"]){
          
      }else{
        new_user_time_arr.push(row_user_time);
      }
    };
    myProps.set_user_time_arr(new_user_time_arr);
  };

  const get_student_row_tags = () => {
    let student_row_tags = myProps.box_student_arr.map((item: any, idx: number) => {

      let stu_grade_str = "";
      if (item.user_info) {
        stu_grade_str = item.user_info.a_stu_grade;
        for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
          if (stu_grade_str == select_stu_grade_option_arr[i]["value"]) {
            stu_grade_str = select_stu_grade_option_arr[i]["text"];
          }
        }
      }

      let stu_days_week_arr_str = "";
      if (item.days_week_arr) {
        stu_days_week_arr_str = item.days_week_arr.join(",");
      }
      return (
        <tr key={idx} className={Style.stu_time_box_stu_list_row}>
          <td>
            {item.a_user_name} {stu_grade_str}
          </td>
          <td>
            <SelectBoxBar
              valueTextArr={able_select_day_option_arr}
              value={stu_days_week_arr_str}
              name={"stu_days_week"}
              on_change={(inData: any) => {
                let select_day_num_arr=inData.value.split(",");
                on_change_select_day_num({ 
                  student_info: item,
                  select_day_num_arr: select_day_num_arr 
                });
              }}
              is_arr_value={true}
              value_split_str={","}
              style_type={"small"}
            ></SelectBoxBar>
          </td>
          <td>
            <button className="btn-s btn-dark" onClick={() => { remove_user_time({ stu_seq: item.a_user_seq }); }}>X</button>
          </td>
        </tr>
      );
    });

    return student_row_tags;
  };

  return (
    <div className={Style.stu_time_box_stu_list_wrap}>
      <table>
        <colgroup>
          <col width={"100px"}></col>
          <col width={"*"}></col>
          <col width={"40px"}></col>
        </colgroup>
        <tbody>
          {get_student_row_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default StuListArea;