function SearchArea(props:any){
   let myProps={
    xColumnArr:{},
    listOpt:{},
    list:(inData:any)=>{},
    ...props
  };
  let xColumnArr=myProps.xColumnArr;
  
  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    myProps.list({now_page:1,[name]: value});
  };

  let stu_state_opt_tags:any="";
  if(xColumnArr.select_arr["a_stu_state"]){
    stu_state_opt_tags=xColumnArr.select_arr["a_stu_state"].map((item:any,idx:number)=>{
      return (
        <option key={idx} value={item["value"]}>{item["text"]}</option>
      );
    });
  }
  return (
  <div>
    <select className="search-input" name="s_stu_state" value={myProps.listOpt.s_stu_state}
      onChange={handleInputChange}>
        <option value="" >전체</option>
        {stu_state_opt_tags}
    </select>
  </div>
  );
};
export default SearchArea;