import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import search_ico from "@/img/ico/search3.png";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from '@/lib/lyg/string';

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props,
  };
  let listOpt = props.listOpt;
  const [form_list, set_form_list] = useState<any>([]);
  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  useEffect(() => {
    
    get_eval_forms_by_ajax();
    get_lecture_select_arr();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.list({ now_page: 1, [name]: value });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ now_page: 1, [key]: date_str });
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_addon_eval_s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_addon_eval_s_start_date: start_date,
      s_addon_eval_s_end_date: end_date,
    });
  };

  const get_eval_forms_by_ajax = () => {
    let s_form_json = {
      "order_id": "a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval_form/list', s_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_form_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        평가서검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={listOpt.s_user_name_like} onChange={handleInputChange} placeholder="이름" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_school_name_like"
            value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_lecture_seq" value={listOpt.s_stu_lecture_seq}
            onChange={(inData: any) => {
              handleInputChange(inData);
              strFunc.set_storage("s_stu_lecture_seq", inData.target.value);
            }}>
            <option value="">수업</option>
            {
              lectrue_select_arr.map((item: any, idx: number) => {
                return (
                  <option value={item.a_seq} key={idx}>{item.a_name}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
            <option value="">학년</option>
            {
              props.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_addon_eval_s_subject_like" value={listOpt.s_addon_eval_s_subject_like} onChange={handleInputChange}>
            <option value="">과목</option>
            {
              subject_option_arr.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-yellowish2" onClick={() => { props.list({ now_page: 1 }); }} style={{ padding: "9px 10px" }} >검색</button>
        </div>
      </div>
      <div className="text-center mt-4">
        기간:
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_addon_eval_s_start_date != "" ? new Date(listOpt.s_addon_eval_s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_eval_s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_addon_eval_s_end_date != "" ? new Date(listOpt.s_addon_eval_s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_eval_s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
        <button className="btn-s2 btn-sky ml-2" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_addon_eval_s_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
            s_addon_eval_s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t")
          });
        }}>이번달</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_addon_eval_s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
            s_addon_eval_s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d")
          });
        }}>오늘</button>

        <span className="ml-2">
          <select className="search_item_input_select" name="s_addon_eval_s_form_seq" value={listOpt.s_addon_eval_s_form_seq}
            onChange={handleInputChange} style={{ border: "1px solid #ddd" }}>
            <option value="">폼선택</option>
            {
              form_list.map((item: any, idx: number) => {
                return (
                  <option value={item["a_seq"]} key={idx}>{item["a_title"]}</option>
                );
              })
            }
          </select>
        </span>
      </div>
    </div>
  );
};

export default SearchArea;