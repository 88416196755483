import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';

function ImgBoxArea(props: any) {
  let myProps = {
    Style: {},
    file_info: {},
    goDelete: (inData: any) => { },
    callback: () => { },
    file_idx_num: 0,
    openFileSlidePopup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let file_info = myProps.file_info;

  let is_image = file_info.is_image;
  let img_url = "";

  const [is_date_modify, set_is_date_modify] = useState(false);

  useEffect(() => {

  }, []);

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    go_update_file_date(date_str);
  };

  const go_update_file_date = (date_str: string) => {
    if (strFunc.is_empty(date_str)) {
      return false;
    }
    if (!confirm("날짜를 " + date_str + "로 바꾸시겠습니까?")) {
      return false;
    }
    let up_file_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
      "a_create_date": date_str + DateFunc.get_date_format(new Date(), " h:i:s"),
    };

    let form_json_data = {
      "data_arr": [up_file_row],
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/update', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_update_file_par_id = (par_id: string) => {
    if (strFunc.is_empty(par_id)) {
      return false;
    }
    let par_id_text = "숙제";
    if (par_id == "plan_study_file") {
      par_id_text = "학습";
    }
    if (!confirm("파일구분을 " + par_id_text + "로 바꾸시겠습니까?")) {
      return false;
    }
    let up_file_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
      "a_par_id": par_id,
    };

    let form_json_data = {
      "data_arr": [up_file_row],
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/update', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  if (is_image == "1") {
    img_url = file_info.img_url;
    if (file_info.a_ext != "png") {
      img_url = file_info.thum_img_url;
    }
  }

  return (
    <div className={Style.sub_img_wrap_item} title={file_info.a_oriname}>
      {is_image == "1" ?
        <a href="javascript:"
          onClick={() => {
            myProps.openFileSlidePopup({ select_file_idx: myProps.file_idx_num });
          }}>
          <img src={img_url} alt={file_info.a_oriname} />
        </a>
        :
        file_info.a_oriname
      }
      <div>
        {is_date_modify &&
          <span>
            <div style={{ display: "inline-block", width: 90 }}>
              <DatePicker
                wrapperClassName="pl-1"
                selected={file_info.a_create_date != "" ? new Date(file_info.a_create_date) : null}
                onChange={(date: Date) => {
                  onChangeDatePicker("s_start_date", date);
                }}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputSearch />}
                renderCustomHeader={customHeaderFunc}
              />
            </div>
            <select className="border ml-1" value={file_info.a_par_id}
              onChange={(e: any) => {
                go_update_file_par_id(e.target.value);
              }} >
              <option value="home_work_file">숙제</option>
              <option value="plan_study_file">학습</option>
            </select>
          </span>
        }
        <button className="btn-s btn-dark ml-1" onClick={() => { set_is_date_modify(!is_date_modify); }}>
          {is_date_modify ? "보기모드" : "수정모드"}
        </button>
      </div>
      <button className="btn-s btn-red px-2" onClick={() => { myProps.goDelete(file_info); }}
        style={{ position: "absolute", right: 0, top: 0 }}>x</button>
    </div>
  );
};
export default ImgBoxArea;