import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import strFunc from '@/lib/lyg/string';
import ControlBarArea from "./area/control_bar";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';

const TodoWriteListArea = forwardRef((props: any, ref) => {
  const myProps = {
    make_todo_by_pan_write: (inOptObj: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>(null);
  let user = useSelector((state: any) => state.user);

  const [todo_write_arr, set_todo_write_arr] = useState<any>([]);

  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      "todo_write_arr": [],
      ...inOptObj
    };
    for (let i = 0; i < optObj.todo_write_arr.length; i++) {
      optObj.todo_write_arr[i]["row_num"] = i;
    }
    set_todo_write_arr(optObj.todo_write_arr);
    ListComponentRef.current.setInfoArr(optObj.todo_write_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, get_todo_write_arr
  }));

  const get_todo_write_arr = () => {
    let all_row = ListComponentRef.current.getRows();
    set_todo_write_arr(all_row);
    return all_row;
  };

  const on_change_row_data = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      row_num: -1,
      ...inData
    };
    
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let all_row = ListComponentRef.current.getRows();
    for (let i = 0; i < all_row.length; i++) {
      let row_data = all_row[i];
      if (row_data["row_num"] == opt_obj["row_num"]) {
        all_row[i][key] = value;
      }
    }
    set_todo_write_arr(all_row);
    ListComponentRef.current.setInfoArr(all_row);
  };

  const onChangeDatePicker=(row_num:number,key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let all_row = ListComponentRef.current.getRows();
    for (let i = 0; i < all_row.length; i++) {
      let row_data = all_row[i];
      if (row_data["row_num"] == row_num) {
        all_row[i][key] = date_str;
        if(key=="a_reserve_start_date"){
          all_row[i]["a_reserve_end_date"] = date_str;
        }
      }
    }
    set_todo_write_arr(all_row);
    ListComponentRef.current.setInfoArr(all_row);
  };

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    let key = params.key;
    let val_str = params.value;

    if (key == "a_sort") {
      if (xColumnArr.select_arr[key]) {
        for (let i = 0; i < xColumnArr.select_arr[key].length; i++) {
          let sel_opt_row = xColumnArr.select_arr[key][i];
          if (sel_opt_row["value"] == val_str) {
            val_str = sel_opt_row["text"];
          }
        }
      }
    } else if (key == "a_success_sort") {
      if (xColumnArr.select_arr[key]) {
        for (let i = 0; i < xColumnArr.select_arr[key].length; i++) {
          let sel_opt_row = xColumnArr.select_arr[key][i];
          if (sel_opt_row["value"] == val_str) {
            val_str = sel_opt_row["text"];
          }
        }
      }
    }

    return val_str;
  };
  const customGridCellRenderer = (params: any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
    let key = params.key;
    if (key == "row_view_success_amount") {
      let tmp_value=params.rowData.a_success_amount;
      let tmp_key="a_success_amount";
      if (params.rowData.a_success_sort == "time") {
        // params.input_tag = parseInt(strFunc.uncomma(params.rowData.a_success_time_sec)) / 60;
        tmp_key="a_success_time_sec";
        tmp_value=params.rowData.a_success_time_sec;
      }
      params.input_tag=(
        <input type="number" className="row-input"
          value={tmp_value}
          name={tmp_key}
          onChange={(e:any)=>{
            on_change_row_data({
              key: e.target.name,
              value: e.target.value,
              row_num: params.rowData.row_num,
            });
          }} 
          onClick={(e:any)=>{
            e.target.select();
          }}
          style={{textAlign:"center"}}
          />
      );
    } else if (key == "a_amount_sort") {
      if (params.rowData.a_success_sort == "time") {
        params.input_tag = "초";
      }
    } else if (key == "row_view_reserve_date") {
      params.input_tag=(
        <div style={{display:"inline-block",width:90}}>
          <DatePicker 
            wrapperClassName="pl-1"
            selected={new Date(params.rowData.a_reserve_start_date.substring(0, 10))} 
            onChange={(date:Date) => {
              onChangeDatePicker(params.rowData.row_num,"a_reserve_start_date",date);
            }}
            locale={ko} 
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
      );
    }
    return params.input_tag;
  };

  return (
    <div>
      <ControlBarArea
        ListComponentRef={ListComponentRef}
        todo_write_arr={todo_write_arr}
        set_todo_write_arr={(inData: any) => {
          set_todo_write_arr(inData);
          ListComponentRef.current.setInfoArr(inData);
        }}
      ></ControlBarArea>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        infoArr={todo_write_arr}
        gridOpt={{
          isGrid: true,
          is_add_idx_num: false,
          is_add_checkbox: true,
          width: "100%",
          height: "45vh",
          is_auto_max_width: false,
          td_align: "left",//center,right
          table_align: "left",//center,right
          onGridReady: () => { },
          rowOnClick: (rowData: any, idx: number, e: any) => {

          },
          customCellRenderer: customCellRenderer,
          customGridCellRenderer: customGridCellRenderer,
          class_name_json: {
            table_div: "basic_list_div",
            td_div: "row-col-div",
            input_check: "chk_row",
            row_input: "row-input",
            data_row_tr: "data_row_tr",
            active_row: "active",
            empty_con_div: "list_empty_content_div",
          },
        }}
      ></ListComponent>
      <div className="text-center mt-5">
        <button className="btn-l btn-yellowish3"
          onClick={() => { myProps.make_todo_by_pan_write(); }} >스케줄 적용하기</button>
      </div>
    </div>
  );
});

export default TodoWriteListArea;
