function TdBoxArea(props: any) {
  let myProps = {
    tr_td_row: {},
    Style: {},
    ...props
  };
  let Style = myProps.Style;
  let tr_td_row=myProps.tr_td_row;
  let td_data=tr_td_row.td_data;

  let td_class_name=Style.time_table_td;
  let td_title_str="";
  let td_sub_title:any="";
  if(tr_td_row.td_data!=null){
    td_class_name+=" "+Style.has_time_td;

    td_title_str=td_data.lecture_name;
    if(td_data.room_name){
      td_title_str+=" ["+td_data.room_name+"]";
    }

    let diff_m_str=td_data.end_time_m-td_data.start_time_m;
    td_sub_title=(
      <div>
        {td_data["name"]+". "+td_data["teacher_name"]}
        <div className="mt-1">
          {td_data["time_str"]}
        </div>
        <div>
          ({diff_m_str}분)
        </div>
      </div>
    );
  }
  
  return (
    <td className={td_class_name} rowSpan={tr_td_row.row_span}  >
      <div className={Style.time_table_td_box}>
        {td_data &&
          <>
            <div className={Style.time_table_lecture_title}>
              {td_title_str}
            </div>
            <div className="text-center" >
              {td_sub_title}
            </div>
          </>
        }
      </div>
    </td>
  );
};
export default TdBoxArea;