import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";

const PushListArea = forwardRef((props:any, ref) => {
  let myPorps:any={
    iam_info:{},
    receive_user_arr:[],
    ...props
  };

  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const ListComponentRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_is_receive":"empty",
      "s_owner_mcomp_seq":"",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [receive_user_arr,set_receive_user_arr]=useState<any>(myPorps.receive_user_arr);
  const [iam_info,set_iam_info]=useState<any>({});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list,set_receive_user_arr,set_iam_info
  }));

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_list_opt={...pageData.listOpt};
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    tmp_list_opt["s_owner_mcomp_seq"]=user.comp_seq;

    let s_sender_receiver_arr=[];
    
    for(let i=0;i<receive_user_arr.length;i++){
      if(!strFunc.is_empty(receive_user_arr[i].comp_link_info)){
        s_sender_receiver_arr.push(receive_user_arr[i].comp_link_info.a_main_user_seq);
      }
    }
    
    if(s_sender_receiver_arr.length==0){
      setInfoArr([]);
      setCountInfo({"tot":0});
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    tmp_list_opt["s_sender_receiver_arr"]=s_sender_receiver_arr;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  return (
    <div>
      <div className="btn-box-left relative h-8">
        푸시이력
        <button className="btn btn-dark" onClick={()=>{list({"now_page":"1"});}}>검색</button>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={goDelete}>삭제</button>
        </div>
      </div>
      <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          isGrid:false,
          is_add_idx_num:true,
          is_add_checkbox:true,
          onGridReady:()=>{
            list({});
          },
        }}
      ></ListComponent>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot} 
        onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
    </div>
  );
});

export default PushListArea;