import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import DatePicker from "react-datepicker";

function SearchArea(props:any){
  let myProps:any={
    listOpt:{
      s_date_type:'a_send_date',
      s_start_date:DateFunc.get_date_format(new Date(),"Y-m-01"),
      s_end_date:DateFunc.get_date_format(new Date(),"Y-m-t"),
      s_is_success:"",
    },
    goDelete:()=>{},
    list:(listOpt:any)=>{},
    ...props
  };

  const [listOpt,setListOpt]=useState(myProps.listOpt);

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{[key]:date_str}
    });
    myProps.list({
      now_page:"1",
      ...{[key]:date_str}
    });
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    setListOpt({
      ...listOpt,
      ...{[name]:value}
    });
    myProps.list({
      now_page:"1",
      ...{[name]:value}
    });
  };

  const goDateMonthChange=(sort:any)=>{
    //next,pre
    let selected_date=listOpt.s_start_date;
    if(selected_date==""){
      selected_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let change_date_obj=new Date(selected_date);
    if(sort=="pre"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'month',-1);
    }else if(sort=="next"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'month',+1);
    }
    
    let start_date=DateFunc.get_date_format(change_date_obj,"Y-m-01");
    let end_date=DateFunc.get_date_format(change_date_obj,"Y-m-t");
    setListOpt({
      ...listOpt,
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
    myProps.list({
      now_page:"1",
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
  };

  return (
    <div>
      <div className="btn-box-left relative">
        <select className="search-input_select ml-1" name="s_date_type" value={listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_send_date">발신일</option>
        </select>
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_start_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />~
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_end_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("pre");}} >◀</button>
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("next");}} >▶</button>
        <select className="search-input_select" name="s_is_success" value={listOpt.s_is_success} onChange={handleInputChange} >
          <option value="">성공여부</option>
          <option value="1">예</option>
          <option value="empty">아니오</option>
        </select>
        <div className="absolute right-0 top-0 btn-box-left">
          <button className="btn btn-red" onClick={()=>{myProps.goDelete();}}>삭제</button>
        </div>
      </div>
    </div>
  );
}
export default SearchArea;