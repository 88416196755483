import strFunc from "@/lib/lyg/string";

function TodoContent(props:any){
   let myProps={
    todo_data:{},
    set_todo_data:(inData:any)=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;
  let todo_data=myProps.todo_data;
  
  const handleInputChange=(e:any)=>{
    let value=e.target.value;
    let name=e.target.name;

    if(name=="a_success_amount"){
      value=strFunc.uncomma(value);
    }

    let tmp_todo_data={...todo_data};
    tmp_todo_data[name]=value;
    myProps.set_todo_data(tmp_todo_data);
  };
  
  return (
  <div>
    <div className="write_table_small">
      <table>
        <colgroup>
          <col width={"100px"}></col>
          <col width={"40%"}></col>
          <col width={"100px"}></col>
          <col width={"40%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>구분</th>
            <td>
              <select className="row-input" onChange={handleInputChange} 
                name="a_sort"
                value={todo_data.a_sort} >
                <option value={"homework"}>숙제</option>
                <option value={"study"}>학습</option>
              </select>
            </td>
            <th>분량</th>
            <td>
              <input type="text" className="row-input" onChange={handleInputChange} 
                name="a_success_amount"
                value={strFunc.comma(todo_data.a_success_amount)}
                style={{width:50,textAlign:"center"}}/>
              <select className="row-input ml-1" onChange={handleInputChange} 
                name="a_amount_sort"
                value={todo_data.a_amount_sort}
                style={{width:60}}>
                <option value="">기준</option>
                <option value="페이지">페이지</option>
                <option value="챕터">챕터</option>
                <option value="개">개</option>
                <option value="문제">문제</option>
                <option value="단원">단원</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>제목</th>
            <td  colSpan={3}>
              <input type="text" className="row-input" onChange={handleInputChange} 
                name="a_title"
                value={todo_data.a_title} />
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td  colSpan={3} style={{paddingBottom:0}}>
              <textarea className="row-input" onChange={handleInputChange} 
                name="a_content"
                value={todo_data.a_content} 
                style={{height:50}}></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
};
export default TodoContent;