let border_1={
  top:{
    style: "thin", color: {rgb: "666666"}
  },
  bottom:{
    style: "thin", color: {rgb: "666666"}
  },
  left:{
    style: "thin", color: {rgb: "666666"}
  },
  right:{
    style: "thin", color: {rgb: "666666"}
  },
};

let style_data={
  border_1:border_1,
};
export default style_data;