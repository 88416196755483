import noimg_ico from "@/img/ico/noimg.png";
import popupFunc from "@/lib/lyg/popup";
import WriteArea from "@/pages/comp/edu/study_diary/list/area/write/area/write_area";

function ListBox(props: any) {
  let myProps = {
    info: {},
    stu_info: {},
    select_subject_option_arr: [],
    on_change_row_input: (inData: any) => { },
    on_change_row_date_picker: (inData: any) => { },
    Style: {},
    is_view_mode: false,
    row_num: 0,
    list_length:0,
    update_by_ajax: (inData: any) => { },
    delete_by_ajax: (inData: any) => { },
    goDownLoad: (inData: any) => { },
    goDeleteFile: (inData: any) => { },
    goWriteFileUpload: (inData: any) => { },
    comment_list: [],
    refresh_comment: (inData: any) => { },
    list: (inData: any) => { },
    set_refresh:(inData: any)=>{},
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let is_view_mode = myProps.is_view_mode;
  let row_num = myProps.row_num;

  const get_file_tags_by_file_arr = (file_arr: []) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let icon_img = noimg_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} style={{display:"inline-block",marginLeft:10}} >
          {file_info.is_image == "1" &&
            <a style={{ cursor: "pointer" }} onClick={() => { popupFunc.openPopup({ url: file_info.img_url }); }} >
              <img src={icon_img} style={{height:250}} />
            </a>
          }
          <p>
            {file_info.a_oriname}
            <button className="btn-s btn-gray ml-1" onClick={() => { myProps.goDownLoad(file_info); }}>다운</button>
            <button className="btn-s btn-red ml-1" onClick={() => { myProps.goDeleteFile([file_info]); }} >X</button>
          </p>
        </div>
      );
    });

    return file_tags;
  };

  let file_tags: any = "";
  if (info.file_arr) {
    file_tags = get_file_tags_by_file_arr(info.file_arr);
  }

  return (
    <div className={Style.counsel_box}>
      <div>
        No. {myProps.list_length-myProps.row_num}
        {info["a_is_success_prescribe"]=="1"&&
          <span className="ml-2" style={{color:"#6faa12",fontWeight:"bold"}}>
            (처방완료)
          </span>
        }
      </div>
      <div>
        <div>
          <WriteArea
            rowData={info}
            isUpdate={true}
            setRowData={(inData: any) => {
              myProps.on_change_row_input({
                row_num: myProps.row_num,
                row_data: inData
              });
            }}
            comment_list={myProps.comment_list}
            refresh_comment={myProps.refresh_comment}
            list={(inData: any) => { }}
            is_find_stu={false}
            is_view_mode={is_view_mode}
            set_refresh={myProps.set_refresh}
          ></WriteArea>
        </div>
        <div style={{ marginTop: 5, textAlign: "center", border: "1px solid #ddd" }}>
          <div>첨부사진</div>
          {is_view_mode == false &&
            <form method="post" >
              <span className="btn-s btn-sky" onClick={(e: any) => {
                e.target.parentElement.querySelector(".input_file")?.click();
              }}  >
                사진선택{info.file_obj ? "(" + info.file_obj.files.length + ")" : ""}
              </span>
              <input type="file" className="input_file" name="input_file[]" hidden
                onChange={(e: any) => {
                  info.file_obj = e.target;
                  let tmp_row_data = info;
                  tmp_row_data.file_obj;
                  if (tmp_row_data.file_obj.value != "") {
                    myProps.goWriteFileUpload(tmp_row_data);
                  }
                }} accept="image/*"
                multiple />
              {(info.file_obj != null && info.file_obj.files.length > 0) &&
                <span className="btn-s btn-red ml-1" onClick={(e: any) => {
                  e.target.parentElement.querySelector(".input_file").value = "";
                }} >X</span>
              }
            </form>
          }
          <div style={{ maxWidth: 550,overflow:"auto",margin:"0 auto",maxHeight:300,whiteSpace:"nowrap" }}>
            {file_tags}
          </div>
        </div>
      </div>

      {myProps.is_view_mode == false &&
        <div className="mt-1 text-center">
          <button className="btn-m2 btn-sky" onClick={() => { myProps.update_by_ajax([info]); }} >
            저장
          </button>
          <button className="btn-m2 btn-red ml-1" onClick={() => { myProps.delete_by_ajax([info]); }} >
            삭제
          </button>
        </div>
      }
    </div>
  );
};
export default ListBox;