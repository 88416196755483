import { useState,useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function SearchSubArea(props:any){
   let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    ...props
  };
  
  const [class_info_arr,set_class_info_arr]=useState([]);

  useEffect(()=>{
    get_class_list_by_ajax();
  },[]);

  const get_class_list_by_ajax = () => {
    let form_json_data={
      "order_id":"a_order_num",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/list', 
    form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_class_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
  <div className="mt-2 text-center">
    클래스:
    <select className="search-input ml-1" value={myProps.listOpt.s_class_seq} name="s_class_seq"
      onChange={(e:any)=>{
        myProps.list({"s_class_seq":e.target.value,"now_page":"1"});
      }}
      style={{width:80}} >
      <option value="">전체</option>
      {class_info_arr.map((item:any,idx:number)=>{
        return (
          <option key={idx} value={item["a_seq"]} >{item["a_name"]}</option>
        );
      })}
    </select>
  </div>
  );
};
export default SearchSubArea;