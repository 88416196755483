import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    set_select_seq_arr:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const pageData={
    "write_btn_text":"신청",
    "confirm_msg":"신청 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const [mcomp_info_list,set_mcomp_info_list]=useState([]);
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_public_sort":"comp",
    "a_owner_mcomp_seq":user.comp_seq,
    "a_sender_num":"",
    "a_is_agree":"",
    "a_request_date":"",
    "a_request_mcomp_seq":user.comp_seq,
    "a_request_user_seq":user.user_seq,
    "a_requester":user.user_name,
    "a_requester_tel":"",
    "a_agree_date":"",
    "a_request_msg":"",
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
    getMcompListByAjax();
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="a_sender_num"){
      value=strFunc.autoHypenPhone(value);
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const getMcompListByAjax=()=>{
    let formJsonData={
      "s_sort":"comp",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/mcomp/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_mcomp_info_list(response.data["data"]["info_arr"]);
      }
    });
  };

  const goWrite=useCallback( (e:any) => {
   
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms_sender/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.set_select_seq_arr([response.data["data"][0]["a_seq"]]);
        myProps.list({"now_page":"1"});
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>발신번호</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_sender_num" value={rowData.a_sender_num} onChange={handleInputChange} placeholder="발신번호" />
              </td>
            </tr>
            <tr>
              <th>승인여부</th>
              <td>
                {rowData.a_is_agree=="1"?"승인":"미승인"}
              </td>
              <th>승인일</th>
              <td>
                {rowData.a_agree_date}
              </td>
            </tr>
            <tr>
              <th>요청메세지</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_request_msg" value={rowData.a_request_msg} onChange={handleInputChange} placeholder="요청메세지" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
    </div>
  );
});

export default WriteFrameContent;
