import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import { useState,useRef,useEffect } from 'react';
import { useLocation,useNavigate } from "react-router";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import TopArea from "./area/top_area";
import VideoBoxArea from "./area/video_box";
import BottomConArea from "./area/bottom_con";
import BottomButtonArea from "./area/bottom_btn";

function TodoViewVideoPage(props:any){
   let myProps={
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [todo_info,set_todo_info]=useState(state.todo_info);
  const [refresh,set_refresh]=useState(false);
  const [is_refreshed,set_is_refreshed]=useState(false);
  const VideoBoxAreaRef=useRef<any>(null);
  let back_press_url=state.back_press_url?state.back_press_url:"/comp_stu/plan/todo_list";
  
  useEffect(()=>{
    
    if(strFunc.is_empty(todo_info)){
      navigate(back_press_url);
    }else{
      goRefresh();
    }
  },[user]);

  const goBackUrl=()=>{
    let send_data:any={};
    if(state.list_opt){
      send_data["list_opt"]=state.list_opt;
    }
    navigate(back_press_url,{
      state:send_data
    });
  };

  const completeOne = ()=>{
    if(!confirm("완료처리 하시겠습니까?")){
      return false;
    }
    if(user.user_seq==""){
      return false;
    }
    if(strFunc.is_empty(todo_info.a_seq)){
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let row_pri_val=todo_info.a_ymd+","+todo_info.a_seq;
    let del_form_data={
      "todo_seq_arr":[row_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/complete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        goBackUrl();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goRefresh=()=>{
    let row_pri_val=todo_info.a_ymd+","+todo_info.a_seq;
    let form_json_data={
      "s_pri_arr":[row_pri_val],
      "s_addon_excute_arr":"1",
      "s_addon_mcomp_title":"1",
      "s_addon_file_arr":"1",
      "s_addon_par_content":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_todo_info=response.data["data"]["info_arr"][0];
          //비디오가 아니던가 데이터가 없다면 뒤로가기
          if(tmp_todo_info["a_par_id"]!="video_list"){
            alert("동영상 강의가 아닙니다.");
            goBackUrl();
            return false;
          }
          if(strFunc.is_empty(tmp_todo_info["video_info"])){
            alert("동영상 강의정보가 없습니다.");
            goBackUrl();
            return false;
          }

          //진행시간 업데이트
          let a_time_sec=parseInt(strFunc.uncomma(tmp_todo_info.a_time_sec));
          tmp_todo_info.a_time_sec=a_time_sec;
          set_todo_info(tmp_todo_info);
          set_is_refreshed(true);
          setTimeout(()=>{
            if(VideoBoxAreaRef.current){
              VideoBoxAreaRef.current.set_init_data({
                todo_info:tmp_todo_info
              });
            }
          },100);
        }else{
          alert("조회 내용이 없습니다.");
          goBackUrl();
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteTodoInfo=(inData:any)=>{
    let opt_obj={
      todo_info:todo_info,
      ...inData
    };
    let form_json_data={
      data_arr:[opt_obj.todo_info],
      is_update:"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/write',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        save_detail_video_by_todo_ajax();
        set_todo_info(opt_obj.todo_info);
        set_refresh(!refresh);
      }else{
        alert(response.data["msg"]);
        goBackUrl();
      }
    });
  };

  const save_detail_video_by_todo_ajax=()=>{
    let form_json_data={
      "todo_seq":todo_info["a_ymd"]+","+todo_info["a_seq"],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/save_detail_video_by_todo',
    form_json_data,get_axios_data())
    .then((response) => {
      
    });
  };

  return (
  <CompLayout isLeftArea={false} isConTitle={false} >
    <div className={Style.video_page}>
      <TopArea
        todo_info={todo_info}
        on_click_back={()=>{goBackUrl();}}
        Style={Style}
      ></TopArea>
      {is_refreshed&&
        <VideoBoxArea
          ref={VideoBoxAreaRef}
          todo_info={todo_info}
          set_todo_info={set_todo_info}
          goWriteTodoInfo={goWriteTodoInfo}
          Style={Style}
        ></VideoBoxArea>
      }
      <BottomConArea
        Style={Style}
        todo_info={todo_info}
      ></BottomConArea>
      <BottomButtonArea
        Style={Style}
        on_click_back={()=>{goBackUrl();}}
        completeOne={completeOne}
        todo_info={todo_info}
      ></BottomButtonArea>
    </div>
  </CompLayout>
  );
};
export default TodoViewVideoPage;