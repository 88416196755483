import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "../../xcolumn/list";

function SearchArea(props:any){
  let myProps:any={
    listOpt:{
      s_grade:"",
    },
    list:(listOpt:any)=>{},
    ...props
  };

  const xColumnArr=getXcolumnJson();
  const [listOpt,setListOpt]=useState(myProps.listOpt);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    setListOpt({
      ...listOpt,
      ...{[name]:value}
    });
    myProps.list({
      now_page:"1",
      ...{[name]:value}
    });
  };

  return (
    <div>
      <div className="btn-box-left relative">
        <select className="search-input_select" name="s_grade" value={listOpt.s_grade} onChange={handleInputChange}>
          <option value="">유저구분</option>
          {xColumnArr.select_arr.a_user_grade.map((item:any,idx:number)=>{
            return (
              <option key={idx} value={item.value}>{item.text}</option>
            );
          })}
        </select>
        <button className="btn btn-dark" onClick={()=>{myProps.list({"now_page":1});}}>검색</button>
      </div>
      <div className="btn-box-left relative mt-1">
       
      </div>
    </div>
  );
}
export default SearchArea;