import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';

function FindMultyStudentPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    listOpt: {},
    row_num: 0,
    key_str: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key_str: "",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    is_show_en_class: false,
    is_show_month_prescribe: false,
    is_show_lecture_time: false,
    is_multi_select_by_click:false,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let select_mcomp_data=myProps.select_mcomp_data;
  
  const xColumnArr = getXcolumnJson();
  if (myProps.is_show_en_class) {
    for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
      if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_en_class") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "1";
      }
    }
  }
  if (myProps.is_show_month_prescribe) {
    for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
      if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_month_prescribe_math") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "1";
      } else if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_month_prescribe_en") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "1";
      }
    }
  }
  if (myProps.is_show_lecture_time) {
    for (let i = 0; i < xColumnArr.x_column_list_arr.length; i++) {
      if (xColumnArr.x_column_list_arr[i]["key"] == "row_view_lecture_name") {
        xColumnArr.x_column_list_arr[i]["is_show"] = "1";
      }
    }
  }
  const listAggridRef = useRef<any>();
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    order_id: strFunc.get_storage("stu_find_multy_order_id", "a_stu_grade, a_user_name"),
    s_grade: ["student"],
    s_search_text_type: "name",
    s_search_text: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_class_user: "1",
    s_addon_link_user: "1",
    s_except_user_seq: "",
    s_addon_counsel_todo_tot: "",
    s_addon_data: "1",
    s_select_date: "",
    s_addon_month_prescribe: "",
    s_addon_month_prescribe_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    s_addon_month_prescribe_end_date: DateFunc.get_date_format(new Date(), "Y-m-t"),
    s_addon_month_prescribe_writer_seq: "",
    s_addon_lecture_time: "",
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
    ...myProps.listOpt
  });
  //xcolumn 세팅

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const en_class_arr_ref = useRef([]);

  useEffect(() => {
    list({});
    get_en_class_by_ajax();
  }, [user,select_mcomp_data.api_user_seq]);

  const setXcolumnArrByListOpt = () => {
    if (myProps.listOpt["s_addon_counsel_todo_tot"] != "1") {
      return false;
    }
    let x_column_list_arr_len = xColumnArr.x_column_list_arr.length;
    for (let i = 0; i < x_column_list_arr_len; i++) {
      let x_column = xColumnArr.x_column_list_arr[i];
      if (x_column["key"] == "row_view_counsel_cnt") {
        if (myProps.listOpt["s_addon_counsel_todo_tot"] == "1") {
          xColumnArr.x_column_list_arr[i]["is_show"] = "1";
        }
      }
    }
  };
  setXcolumnArrByListOpt();

  const list = (inOptObj: any) => {
    let change_list_opt:any = {
      ...listOpt,
      ...inOptObj
    };
    change_list_opt["sc"] = listAggridRef.current.getListSc();
    if (!strFunc.is_empty(change_list_opt["s_select_date"])) {
      let tmp_start_date = change_list_opt["s_select_date"];
      let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
      //일주일 조회
      let week_date_change_num = -tmp_start_date_json.day;
      let week_date_change_end_num = 6 - tmp_start_date_json.day;
      let week_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_num), "Y-m-d");
      let week_date_end_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_end_num), "Y-m-d");
      change_list_opt["s_addon_month_prescribe_start_date"] = week_date_str;
      change_list_opt["s_addon_month_prescribe_end_date"] = week_date_end_str;
    }

    setListOpt(change_list_opt);
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        change_list_opt["api_key"]=select_mcomp_data.api_key;
        change_list_opt["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_en_class_by_ajax = () => {
    let list_json_form_data:any = {
      "order_id": "a_order_num",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        list_json_form_data["api_key"]=select_mcomp_data.api_key;
        list_json_form_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/list', list_json_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          en_class_arr_ref.current = response.data["data"]["info_arr"];
        } else {

        }
      });
  };

  const goCallBackData = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key_str: myProps.key_str
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderOfMonthPrescribe = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    let tot_span_style: any = {
      display: "inline-block",
      textAlign: "center",
      lineHeight: "26px",
      height: "26px",
      padding: "0px 8px",
      background: "gray",
      color: "#fff",
      borderRadius: "5px"
    };
    if (params.data.month_prescribe_arr == undefined) {
      return "";
    }
    if (params.data.month_prescribe_arr.length == 0) {
      return "";
    }
    if (key == "row_view_month_prescribe") {
      if (params.data.month_prescribe_arr.length > 0) {
        let row_mprescribe_arr = params.data.month_prescribe_arr;
        let row_mprescribe_arr_len = row_mprescribe_arr.length;
        let row_success_cnt = 0;
        for (let i = 0; i < row_mprescribe_arr_len; i++) {
          let row_mprescribe = row_mprescribe_arr[i];
          if (row_mprescribe["a_is_success"] == "1") {
            row_success_cnt++;
          }
        }

        if (row_mprescribe_arr_len > 0) {
          tot_span_style["background"] = "#db6c58";
          if (row_success_cnt > 0) {
            tot_span_style["background"] = "#eab358";
            if (row_mprescribe_arr_len == row_success_cnt) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {row_success_cnt + "/" + row_mprescribe_arr_len}
          </div>
        );
      }
    } else {
      let custom_prescribe_data: any = {
        "math": {
          arr: [],
          cnt: 0,
          success: 0,
        },
        "en": {
          arr: [],
          cnt: 0,
          success: 0,
        },
      };
      for (let i = 0; i < params.data.month_prescribe_arr.length; i++) {
        if (params.data.month_prescribe_arr[i]["a_subject_name"] == "수학") {
          custom_prescribe_data["math"]["arr"].push(params.data.month_prescribe_arr[i]);
          custom_prescribe_data["math"]["cnt"]++;
          if (params.data.month_prescribe_arr[i]["a_is_success"] == "1") {
            custom_prescribe_data["math"]["success"]++;
          }
        } else if (params.data.month_prescribe_arr[i]["a_subject_name"] == "영어") {
          custom_prescribe_data["en"]["arr"].push(params.data.month_prescribe_arr[i]);
          custom_prescribe_data["en"]["cnt"]++;
          if (params.data.month_prescribe_arr[i]["a_is_success"] == "1") {
            custom_prescribe_data["en"]["success"]++;
          }
        }
      }
      if (key == "row_view_month_prescribe_math") {
        if (custom_prescribe_data["math"]["cnt"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (custom_prescribe_data["math"]["success"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (custom_prescribe_data["math"]["cnt"] == custom_prescribe_data["math"]["success"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {custom_prescribe_data["math"]["success"] + "/" + custom_prescribe_data["math"]["cnt"]}
          </div>
        );
        if (custom_prescribe_data["math"]["cnt"] == 0) {
          render_str = "";
        }
      } else if (key == "row_view_month_prescribe_en") {
        if (custom_prescribe_data["en"]["cnt"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (custom_prescribe_data["en"]["success"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (custom_prescribe_data["en"]["cnt"] == custom_prescribe_data["en"]["success"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {custom_prescribe_data["en"]["success"] + "/" + custom_prescribe_data["en"]["cnt"]}
          </div>
        );
        if (custom_prescribe_data["en"]["cnt"] == 0) {
          render_str = "";
        }
      }
    }
    return render_str;
  };

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "a_user_name") {
      let stu_grade_str = params.data.a_stu_grade;
      for (let i = 0; i < xColumnArr.select_arr.a_stu_grade2.length; i++) {
        if (xColumnArr.select_arr.a_stu_grade2[i]["value"] == stu_grade_str) {
          stu_grade_str = xColumnArr.select_arr.a_stu_grade2[i]["text"];
        }
      }
      if(!strFunc.is_empty(params.data.a_stu_total_code)){
        render_str+=" "+params.data.a_stu_total_code;
      }
      render_str = render_str + " " + stu_grade_str;
    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_par_phone") {
      let a_user_phone = strFunc.autoHypenPhone(params.data.a_user_phone);
      let par_phone_num = "학부모 미등록";
      if (params.data.link_user_arr && params.data.link_user_arr[0] && params.data.link_user_arr[0]["par_info"]) {
        par_phone_num = params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str = (
        <div>
          <div className="leading-5">{par_phone_num}</div>
          <div className="leading-5">{a_user_phone}</div>
        </div>);
    } else if (key == "a_stu_num") {
      render_str = strFunc.getNumber2(render_str);
    } else if (key == "row_view_counsel_cnt") {
      if (params.data.ctodo_tot_info) {
        let tot_span_style: any = {
          display: "inline-block",
          textAlign: "center",
          lineHeight: "26px",
          height: "26px",
          padding: "0px 8px",
          background: "gray",
          color: "#fff",
          borderRadius: "5px"
        };
        if (params.data.ctodo_tot_info["tot"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (params.data.ctodo_tot_info["success_tot"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (params.data.ctodo_tot_info["tot"] == params.data.ctodo_tot_info["success_tot"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {params.data.ctodo_tot_info["success_tot"] + "/" + params.data.ctodo_tot_info["tot"]}
          </div>
        );
      }
    } else if (key == "row_view_en_class") {
      if (!strFunc.is_empty(params.data.addon_data_info)) {
        render_str = params.data.addon_data_info.a_en_class_seq;
        console.log(en_class_arr_ref.current);
        for (let i = 0; i < en_class_arr_ref.current.length; i++) {
          if (en_class_arr_ref.current[i]["a_seq"] == render_str) {
            render_str = en_class_arr_ref.current[i]["a_name"];
          }
        }
      }
    } else if (key == "row_view_month_prescribe"
      || key == "row_view_month_prescribe_math"
      || key == "row_view_month_prescribe_en") {
      render_str = cellRenderOfMonthPrescribe(params);
    } else if (key == "row_view_lecture_name") {
      if (params.data.lecture_arr) {
        let lecture_name_arr = [];
        for (let i = 0; i < params.data.lecture_arr.length; i++) {
          lecture_name_arr.push(params.data.lecture_arr[i]["a_name"]);
        }
        render_str = lecture_name_arr.join(" / ");;
        render_str=(
          <span title={render_str}>{render_str}</span>
        );
      }
    }
    return render_str;
  };

  return (
    <div>
      <SearchArea
        select_mcomp_data={select_mcomp_data}
        listOpt={listOpt}
        setListOpt={setListOpt}
        xColumnArr={{ ...xColumnArr }}
        list={list}
      ></SearchArea>
      <div className="btn-box-left relative h-8">
        <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
        <span className="px-2">|</span>
        <SearchOrderRadio
          valueTextArr={[
            { "text": "학년", "value": "a_stu_grade, a_user_name" },
            { "text": "등록순", "value": "a_create_date DESC" },
            { "text": "이름순", "value": "a_user_name" },
          ]}
          value={listOpt.order_id}
          handleInputChange={(e: any) => {
            list({
              "now_page": 1,
              "order_id": e.target.value
            });
            strFunc.set_storage("stu_find_multy_order_id", e.target.value);
          }}
        ></SearchOrderRadio>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: true,
          floatingFilter: false,
          rowMultiSelectWithClick:myProps.is_multi_select_by_click,
          onGridReady: () => {
            //list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
}

export default FindMultyStudentPopup;