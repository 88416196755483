import strFunc from "@/lib/lyg/string";

function StuArea(props:any){
   let myProps={
    stu_info_arr:[],
    set_stu_info_arr:(inData:any)=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;

  const remove_stu_by_info=(stu_info:any)=>{
    let tmp_stu_info_arr=[];
    for(let i=0;i<myProps.stu_info_arr.length;i++){
      if(myProps.stu_info_arr[i]["a_seq"]==stu_info["a_seq"]){

      }else{
        tmp_stu_info_arr.push(myProps.stu_info_arr[i]);
      }
    }
    myProps.set_stu_info_arr(tmp_stu_info_arr);
  };

  const get_stu_tags=()=>{
    let stu_tags=myProps.stu_info_arr.map((item:any,idx:number)=>{
      return (
        <span key={idx} className={Style.stu_item}>
          {item.a_user_name}
          <span className="ml-1" style={{color:"#ddd"}}>
          ({strFunc.cut_str(item.a_school_name,2,"..")} {item.stu_grade_name})
          </span>
          <button className="btn-s btn-gray ml-1" onClick={()=>{remove_stu_by_info(item);}}>X</button>
        </span>
      );
    });

    return stu_tags;
  };

  return (
  <div className={Style.stu_wrap}>
    {get_stu_tags()}
  </div>
  );
};
export default StuArea;