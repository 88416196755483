import CoolenMoveBtn from "../move_btn";
import Style from "./css/style.module.css";
import bridge_en_enter2_img from "@/img/coolen/bridge_en_enter2.png";
import grammar_enter2_img from "@/img/coolen/grammar_enter2.png";

function MoveButtonArea(props: any) {
  let myProps = {
    ...props
  };

  let en_text:any=(
    <img src={bridge_en_enter2_img} title="브릿지영어 열기" />
  );
  let grammar_text:any=(
    <img src={grammar_enter2_img} title="문제은행(그래머스퀘어) 열기" />
  );

  return (
    <div>
      <div className={Style.move_btn_wrap}>
        <div className={Style.move_btn_box}>
          <CoolenMoveBtn btn_class_name={Style.move_btn} btn_title={en_text} ></CoolenMoveBtn>
        </div>
        <div className={Style.move_btn_box}>
          <CoolenMoveBtn coolen_sort={"bank"} 
            btn_class_name={Style.move_btn} btn_title={grammar_text}  ></CoolenMoveBtn>
        </div>
      </div>
    </div>
  );
};
export default MoveButtonArea;