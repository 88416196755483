import ColorFunc from "@/pcomponents/common/content/todo/color/func/color_func";
import strFunc from '@/lib/lyg/string';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";

function MidTodoArea(props: any) {
  let myProps = {
    info: {},
    td_sort_data_arr: [],
    base_date_str: "",
    pre_date_str: "",
    Style: {},
    openTodoDaySort: (inData: any) => { },
    openTodoWriteOnePopup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let base_date_str = myProps.base_date_str;
  let pre_date_str = myProps.pre_date_str;

  const get_home_work_file_cnt = (date_sort: "pre" | "now") => {
    let row_file_cnt = 0;
    if (info.home_work_file_arr && info.home_work_file_arr.length > 0) {
      for (let i = 0; i < info.home_work_file_arr.length; i++) {
        if (date_sort == "pre") {
          if (info.home_work_file_arr[i]["a_create_date"].substring(0, 10) >= pre_date_str
            && info.home_work_file_arr[i]["a_create_date"].substring(0, 10) < base_date_str) {
            row_file_cnt++;
          }
        } else if (date_sort == "now") {
          if (info.home_work_file_arr[i]["a_create_date"].substring(0, 10) == base_date_str) {
            row_file_cnt++;
          }
        }
      }
    }
    return row_file_cnt;
  };

  const get_detail_todo_row_tags = (inData: any) => {
    let opt_obj = {
      todo_info_arr: [],
      ...inData,
    };

    let detail_todo_row_tags = opt_obj.todo_info_arr.map((item: any, idx: number) => {
      let row_stat_data = TodoStateFunc.get_stat_data_by_todo_info(item);
      let todo_color_json = TodoStateFunc.get_color_of_percent(row_stat_data.percent);
      let time_str = row_stat_data.time_str;
      if (strFunc.is_empty(item.a_time_sec)) {
        time_str = "";
      }
      let hover_title_str=item.a_title+"\n내용: "+item.a_content+"\n결과메모: "+item.a_result_memo;
      let title_color="#0b0000";
      let title_weight="";
      if(!strFunc.is_empty(item.a_result_memo)){
        title_color="#0440a8";
        title_weight="500";
      }
      return (
        <tr key={idx} className={Style.mid_todo_row_detail_row}>
          <td style={{ paddingLeft: 20 }} title={hover_title_str} onClick={()=>{
            myProps.openTodoWriteOnePopup({
              todo_info:item,
              isUpdate:true,
            });
          }}>
            <input type="text" value={item.a_title} 
              style={{ width: "100%", border: 0,cursor:"pointer",color:title_color,fontWeight:title_weight }} readOnly />
          </td>
          <td style={{ textAlign: "right" }}>
            {time_str}
          </td>
          <td style={{ color: todo_color_json.per, textAlign: "right", paddingRight: 5 }}>
            {row_stat_data.ing_amout}/{row_stat_data.max_amout}
          </td>
        </tr>
      );
    });
    if (strFunc.is_empty(detail_todo_row_tags)) {
      detail_todo_row_tags = (
        <tr>
          <td colSpan={3}>
            <div style={{ color: "#777", textAlign: "center", lineHeight: "20px",fontSize:"13px" }}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }
    return detail_todo_row_tags;
  };

  const get_todo_row_tag = () => {
    let todo_row_tags = myProps.td_sort_data_arr.map((item: any, idx: number) => {
      let color_json: any = ColorFunc.get_color_style_of_percent(item.percent);
      let todo_cnt_str = "";
      if (item.count) {
        todo_cnt_str = "( " + item.success_cnt + "/" + item.count + " )";
      }

      let todo_time_his = strFunc.secondsToTimeJson(parseInt(strFunc.uncomma(item.elapse_time_sec)));
      let time_str=strFunc.timeJsonToTimeStr(todo_time_his);

      let work_file_span: any = "";
      if (item.sort == "homework") {
        let row_file_cnt = get_home_work_file_cnt("now");
        if (row_file_cnt > 0) {
          work_file_span = (
            <span className={Style.mid_todo_row_home_work_file_ico}>
              {row_file_cnt}
            </span>
          );
        }
      } else if (item.sort == "pre_homework") {
        let row_file_cnt = get_home_work_file_cnt("pre");
        if (row_file_cnt > 0) {
          work_file_span = (
            <span className={Style.mid_todo_row_home_work_file_ico}>
              {row_file_cnt}
            </span>
          );
        }
      } else if (item.sort == "study") {
        if (info.plan_study_file_arr && info.plan_study_file_arr.length > 0) {
          work_file_span = (
            <span className={Style.mid_todo_row_home_work_file_ico}>
              {info.plan_study_file_arr.length}
            </span>
          );
        }
      }
      let detail_todo_tags = get_detail_todo_row_tags(item);

      return (
        <div key={idx} >
          <table style={{ width: "100%" }}>
            <colgroup>
              <col width={"70%"}></col>
              <col width={"15%"}></col>
              <col width={"15%"}></col>
            </colgroup>
            <tbody>
              <tr>
                <td colSpan={3}>
                  <div className={Style.mid_todo_row} style={color_json.wrap}
                    onClick={() => {
                      myProps.openTodoDaySort({
                        select_stu_name: info.a_user_name,
                        select_stu_seq: info.a_seq,
                        select_date: (item.sort == "pre_homework" ? pre_date_str : base_date_str)
                      });
                    }}>
                    <div className={Style.mid_todo_row_bar} style={color_json.bar}></div>
                    <span className={Style.mid_todo_row_title}>
                      {item.title}
                      {todo_cnt_str}
                      {!strFunc.is_empty(item.elapse_time_sec) &&
                        <span style={{ fontSize: "12px", marginLeft: 5, color: "#666" }}>
                          {time_str}
                        </span>
                      }
                      {work_file_span}
                    </span>
                    <span className={Style.mid_todo_row_per}>
                      {item.percent}%
                    </span>
                  </div>
                </td>
              </tr>
              {detail_todo_tags}
            </tbody>
          </table>
        </div>
      );
    });
    return todo_row_tags;
  };

  return (
    <div className={Style.todo_box}>
      {get_todo_row_tag()}
    </div>
  );
}
export default MidTodoArea;