import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindMultyStudentPopup from '@/pages/comp/basic/student/popup/find_multy';
import VideoUseDetailPopup from '@/pages/comp/plan/video/corse/popup/video_use_detail';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';

const UseStuList = forwardRef((props:any, ref) => {
  const myProps={
    "corse_info":null,
    "infoArr":[],
    "list":(inOptObj:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const [corse_info,set_corse_info]=useState(myProps.corse_info);
  const [infoArr,setInfoArr]=useState(myProps.infoArr);
  const ListComponentRef=useRef<any>(null);
  
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "corse_info":null,
      "infoArr":[],
      ...inOptObj
    };
    setInfoArr(optObj["infoArr"]);
    set_corse_info(optObj["corse_info"]);
    
    if(ListComponentRef.current){
      ListComponentRef.current.setInfoArr(optObj["infoArr"]);
    }
  };

  useEffect(()=>{
    
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    title:"팝업",
    width:"800px",
    height:"80%",
    stu_seq:"",
    corse_info:null,
    use_info:null,

    listOpt:{
      s_except_user_seq:"",
    },
    row_num:0,
    key:"",
  });

  const openInvitePopup=()=>{
    let pop_title="학생초대";
    let s_except_user_seq:any=[];
    for(let i=0;i<infoArr.length;i++){
      s_except_user_seq.push(infoArr[i].a_stu_seq);
    }

    setPopupData({
      ...popupData,
      ...{
        "isOpen":true,
        "title":pop_title,
        "sort":"find",
        listOpt:{
          s_except_user_seq:s_except_user_seq,
        },
      }
    });
  };
  const openUseDetailPopup=(inData:any)=>{
    let optObj={
      "stu_seq":"",
      "corse_info":corse_info,
      "use_info":null,
      ...inData
    };
    let pop_title="상세보기";
    let s_except_user_seq:any=[];
    for(let i=0;i<infoArr.length;i++){
      s_except_user_seq.push(infoArr[i].a_stu_seq);
    }

    setPopupData({
      ...popupData,
      ...{
        "isOpen":true,
        "title":pop_title,
        "sort":"view_detail",
        "stu_seq":optObj.stu_seq,
        "corse_info":optObj.corse_info,
        "use_info":optObj.use_info,
        listOpt:{
          s_except_user_seq:s_except_user_seq,
        },
      }
    });
  };

  const inviteStuUsers=(user_arr:any)=>{
    if(user_arr.length==0){
      return false;
    }
    if(!confirm(user_arr.length+"명의 학생을 초대하시겠습니까?")){
      return false;
    }
    if(corse_info==null){
      alert("코스데이터가 없습니다.");
      return false;
    }
    let user_seq_arr=[];
    for(let i=0;i<user_arr.length;i++){
      user_seq_arr.push(user_arr[i].a_seq);
    }
    let add_use_user_form_data={
      "corse_seq":corse_info.a_seq,
      "user_seq_arr":user_seq_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use/add_by_user',add_use_user_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.list({select_cat_seq:corse_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDelete =(e:any) => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:corse_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDeleteUse=()=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("선택 초기화 하시겠습니까?")){
      return false;
    }
    let use_seq_arr:any=[];
    for(let i=0;i<selected_row.length;i++){
      let tmp_row_data=selected_row[i];
      let tmp_row_pri_val=tmp_row_data["a_corse_seq"]+","+tmp_row_data["a_stu_seq"];
      use_seq_arr.push(tmp_row_pri_val);
    }
    let init_use_form_data={
      "use_seq_arr":use_seq_arr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use/init_use',init_use_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:corse_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const customCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val
    }
    */
    let val_str=params.value;
    if(params.key=="a_study_state"){
    if(xColumnArr.select_arr["a_study_state"]){
      for(let i=0;i<xColumnArr.select_arr["a_study_state"].length;i++){
        let option_data=xColumnArr.select_arr["a_study_state"][i];
        if(option_data["value"]==params.value){
          val_str=option_data["text"];
        }
      }
    }
    }else if(params.key=="a_study_per"){
      let per_num=parseFloat(strFunc.uncomma(val_str));
      let per_wrap_style:any={
        position:"relative",
        width:"100%",
        height:21,
        background:"#777",
        textAlign:"center",
        lineHeight:"21px",
        display:"inline-block",
      };
      let per_style:any={
        position:"absolute",
        top:0,
        left:0,
        height:"100%",
        width:per_num+"%",
        background:"yellow",
      };
      if(per_num<10){
        per_style.background="#999";
      }
      if(per_num>50){
        per_style.background="blue";
      }
      if(per_num>=100){
        per_style.background="green";
      }
      val_str=(
        <div className="btn-s btn-gray" style={per_wrap_style} onClick={()=>{
          openUseDetailPopup({
            "stu_seq":params.rowData.a_stu_seq,
            "use_info":params.rowData
          });
        }}>
          <div style={per_style}></div>
          <div style={{position:"absolute",left:0,top:0,width:"100%",height:"100%",textAlign:"center"}}>
            {strFunc.comma(val_str)} %
          </div>
        </div>
      );
    }

    return val_str;
  }

  return (
    <div>
      <div className="btn-box-right mb-1">
        <button className="btn btn-dark" onClick={()=>{openInvitePopup();}}>초대</button>
        <button className="btn btn-dark" onClick={goDeleteUse}>진행도초기화</button>
        <button className="btn btn-dark" onClick={goDelete}>삭제</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={xColumnArr}
        gridOpt={
          {
            is_add_idx_num:false,
            is_add_checkbox:true,
            width:"100%",
            max_width:1000,
            td_align:"center",//center,right
            table_align:"left",//center,right
            onGridReady:()=>{},
            rowOnClick:(rowData:any,idx:number,e:any)=>{
              
            },
            customCellRenderer:customCellRenderer
          }
        }
      ></ListComponent>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindMultyStudentPopup 
              listOpt={popupData.listOpt}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             callBackData={(data:any)=>{
                //data = { info_arr:[],row_num:0,key:"" };
                inviteStuUsers(data.info_arr);
             }}
             ></FindMultyStudentPopup>
          }
          {popupData.sort==="view_detail"&&
            <VideoUseDetailPopup
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
              stu_seq={popupData.stu_seq}
              corse_info={corse_info}
              use_info={popupData.use_info}
              refresh={(data:any)=>{
                myProps.list({select_cat_seq:corse_info.a_seq});
             }}
            ></VideoUseDetailPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default UseStuList;
