import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"lecture",
    list_id:"lecture_list",
    x_column_list_arr:[
      {"key":"a_name","name":"수업명","width":"110","is_show":"1"},
      {"key":"a_room_name","name":"강의실","width":"80","is_show":"1"},
      {"key":"a_main_teacher","name":"선생님","width":"100","is_show":"1"},
      {"key":"row_view_stu_cnt","name":"수강생","width":"80","is_show":"1"},
      {"key":"row_view_order","name":"순번","width":"80","is_show":"1"},
      //{"key":"a_state","name":"상태","width":"70","is_show":"1"},
      // {"key":"a_max_stu","name":"정원","width":"60","is_show":"1"},
      // {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      // {"key":"a_update_date","name":"수정일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr.a_state=[
    {text:"수강",value:"ing"},
    {text:"휴강",value:"stop"},
    {text:"종강",value:"end"},
  ];
  xColumnArr.select_arr.a_period_sort=[
    {text:"월별",value:"month"},
    {text:"기간",value:"period"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;