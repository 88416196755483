import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function ContentArea3(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  return (
    <div className={Style.content_wrap}>
      <div className={Style.title_wrap}>
        <span className={Style.title_num}>3</span>
        <span className={Style.title_text}>학습전략 Consulting</span>
      </div>
      <div>
        <table className={Style.goal_table} style={{ marginTop: 5 }}>
          <colgroup>
            <col width="10%"></col>
            <col width="18%"></col>
            <col width="18%"></col>
            <col width="4%"></col>
            <col width="50%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div className={Style.goal_title_box_sky}>
                  교과목
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_plan_subject}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_plan_subject}
                      onChange={(e: any) => { handleInputChange({ name: "a_plan_subject", value: e.target.value }); }}
                      placeholder="교과목"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    단기 중점
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    과목(다음 시
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    험 기준) 1개
                  </div>
                </div>
              </td>
              <td style={{color:"green"}}>
                ▶
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_plan_subject_content}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_plan_subject_content}
                      onChange={(e: any) => { handleInputChange({ name: "a_plan_subject_content", value: e.target.value }); }}
                      placeholder="전략"
                    ></textarea>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={Style.goal_title_box_sky}>
                  학습습관
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_plan_habit}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_plan_habit}
                      onChange={(e: any) => { handleInputChange({ name: "a_plan_habit", value: e.target.value }); }}
                      placeholder="습관"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    단기 개선
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    학습습관
                  </div>
                </div>
              </td>
              <td style={{color:"green"}}>
                ▶
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_plan_habit_content}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_plan_habit_content}
                      onChange={(e: any) => { handleInputChange({ name: "a_plan_habit_content", value: e.target.value }); }}
                      placeholder="전략"
                    ></textarea>
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ContentArea3;