import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"counsel",
    list_id:"counsel_list",
    x_column_list_arr:[
      {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_name","name":"클래스명","width":"120","is_show":"1"},
      {"key":"row_view_teacher","name":"선생님","width":"110","is_show":"1"},
      {"key":"row_view_stu_cnt","name":"인원","width":"90","is_show":"1"},
      {"key":"a_is_supply_money","name":"청구여부","width":"90","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_addon_user":"1",
      "s_stu_seq":"",
      "s_stu_name":"",
      "s_search_text_type":"name",
      "s_search_text":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;