import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
/*
"a_seq":1,
"a_sort":"homework",//homework 숙제, study 학습
"a_title":"",
"a_success_sort":"amount",//amount,time
"a_par_id":"",
"a_par_seq":"",
"a_success_amount":"0",
"a_success_time_sec":"0",
"a_amount_sort":"개",
*/
class MakeTodoWriteFunc{
  static todo_write_default={
    "a_ymd":"",
    "a_seq":"",
    "a_is_mcomp_public":"",
    "a_sort":"homework",
    "a_par_id":"",
    "a_par_seq":"",
    "a_is_reserve":"",
    "a_reserve_start_date":"",
    "a_reserve_end_date":"",
    "a_success_sort":"",
    "a_amount_sort":"",
    "a_success_amount":"",
    "a_success_time_sec":"",
    "a_subject":"",
    "a_title":"",
    "a_content":"",
  }
  static get_make_todo_write(inData:any){
    let opt_obj={
      todo_content_arr:[],
      make_option:{
        "is_mcomp_publiic":"1",
        "is_reserve":"1",//예정여부
        "reserve_start_date":"",
        "reserve_end_date":"",
        "one_day_amount":1,
        "one_day_amount_time":1,
        "apply_day":"1,2,3,4,5",
  
        "start_h":"00",
        "start_m":"00",
        "end_h":"01",
        "end_m":"00",
      },
      ...inData
    };
    let todo_content_arr=opt_obj["todo_content_arr"];
    let make_option=opt_obj["make_option"];
    this.todo_write_default["a_is_mcomp_public"]=make_option["is_mcomp_publiic"];
    this.todo_write_default["a_is_reserve"]=make_option["is_reserve"];
  
    //시작날짜부터 하루분량으로 나누어서 등록
    let start_date=make_option["reserve_start_date"];
    let ing_date=start_date;//진행중인날짜
    let apply_day_arr=make_option["apply_day"].split(",");
    let one_day_amount=parseInt(strFunc.uncomma(make_option["one_day_amount"]));
    let one_day_amount_time=parseInt(strFunc.uncomma(make_option["one_day_amount_time"]));
    one_day_amount_time=parseInt(strFunc.uncomma(one_day_amount_time))*60;
    let next_one_day_remain_amount=0;
    let next_one_day_remain_time=0;
    
    let todo_write_arr:any=[];
    if(one_day_amount==0&&one_day_amount_time==0){
      return todo_write_arr;
    }
    let one_day_remain_amount=0;
    let one_day_remain_time=0;
  
    for(let todo_i=0;todo_i<todo_content_arr.length;todo_i++){
      let todo_content=todo_content_arr[todo_i];

      //데이터세팅
      if(todo_content["a_success_sort"]=="time"){
        todo_content["a_success_time_sec"]=parseInt(strFunc.uncomma(todo_content["row_view_success_amount"]))*60;;
      }else{
        todo_content["a_success_amount"]=todo_content["row_view_success_amount"];
      }

      //분량을 나누어 몇일 더할지 계산
      let todo_amount=todo_content["a_success_amount"];
      let todo_amount_time=todo_content["a_success_time_sec"];
      if(todo_content["a_success_sort"]=="time"){
        if(todo_amount_time==0){
          continue;
        }
      }else{
        if(todo_amount==0){
          continue;
        }
      }

      //나머지
      if(todo_content["a_success_sort"]=="amount"){
        //하루에 남은량
        if(one_day_remain_amount!=0){
          if(todo_amount<=one_day_remain_amount){
            one_day_remain_amount=one_day_remain_amount-todo_amount;
          }else{
            one_day_remain_amount=0;
          }
          one_day_remain_time=0;
        }else{
          if(todo_amount<=one_day_amount){
            one_day_remain_amount=one_day_amount-todo_amount;
          }
        }
      }else{
        //하루에 남은량
        if(one_day_remain_time!=0){
          if(todo_amount_time<=one_day_remain_time){
            one_day_remain_time=one_day_remain_time-todo_amount_time;
          }else{
            one_day_remain_time=0;
          }
          one_day_remain_amount=0;
        }else{
          if(todo_amount_time<=one_day_amount_time){
            one_day_remain_time=one_day_amount_time-todo_amount_time;
          }
        }
      }
      
      let is_go_next_date=true;
      if(todo_content["a_success_sort"]=="amount"){
        if(one_day_remain_amount>0){
          is_go_next_date=false;
        }
      }else{
        if(one_day_remain_time>0){
          is_go_next_date=false;
        }
      }

      let todo_write_arr_rs_by_need_day=this.get_todo_write_arr_by_need_day({
        "ing_date":ing_date,
        "apply_day_arr":apply_day_arr,
        "todo_content":todo_content,
        "make_option":make_option,
        "one_day_amount_time":one_day_amount_time,
        "one_day_amount":one_day_amount,
        "one_day_remain_amount":next_one_day_remain_amount,
        "one_day_remain_time":next_one_day_remain_time,
        "is_go_next_date":is_go_next_date,
      });
      next_one_day_remain_amount=one_day_remain_amount;
      next_one_day_remain_time=one_day_remain_time;
      
      ing_date=todo_write_arr_rs_by_need_day["ing_date"];
      todo_write_arr=[
        ...todo_write_arr,
        ...todo_write_arr_rs_by_need_day["todo_write_arr"],
      ];
    }
  
    return todo_write_arr;
  }
  static get_todo_write_arr_by_need_day(inData:any){
    let this_obj=this;
    let opt_obj={
      "ing_date":"",
      "apply_day_arr":[],
      "todo_content":{},
      "make_option":{},
      "one_day_amount_time":0,
      "one_day_amount":0,
      "one_day_remain_amount":0,
      "one_day_remain_time":0,
      "is_go_next_date":true,
      ...inData
    };
    //종료날짜 구하기
    let ing_date=opt_obj["ing_date"];
    let apply_day_arr=opt_obj["apply_day_arr"];
    let todo_content=opt_obj["todo_content"];
    todo_content["a_success_amount"]=parseInt(strFunc.uncomma(todo_content["a_success_amount"]));
    todo_content["a_success_time_sec"]=parseInt(strFunc.uncomma(todo_content["a_success_time_sec"]));
    let make_option=opt_obj["make_option"];
    let remain_amount=0;
    if(todo_content["a_success_sort"]=="amount"){
      remain_amount=opt_obj["todo_content"]["a_success_amount"];
    }else{
      remain_amount=opt_obj["todo_content"]["a_success_time_sec"];
    }
    
    let tmp_todo_write_arr=[];
    let loop_idx=0;
    while(remain_amount>0){
      loop_idx++;
      //무한반복방지
      if(loop_idx>1001){
        let result_data_arr={
          todo_write_arr:tmp_todo_write_arr,
          ing_date:ing_date,
        };
        return result_data_arr;
      }
      let able_date_str=this.get_able_date_at_week({
        "date_str":ing_date,
        "apply_day_arr":apply_day_arr,
      });
      
      if(able_date_str==false){
        ing_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(ing_date),'day',+1),"Y-m-d");
        continue;
      }
      let todo_write_start_date=able_date_str;
      let todo_write_end_date=able_date_str;
      ing_date=able_date_str;

      todo_write_start_date=todo_write_start_date+" "+make_option["start_h"]+":"+make_option["start_m"]+":00";
      todo_write_end_date=todo_write_end_date+" "+make_option["end_h"]+":"+make_option["end_m"]+":00";

      let success_amount=todo_content["a_success_amount"];
      let success_time_sec=todo_content["a_success_time_sec"];
      if(todo_content["a_success_sort"]=="amount"){
        success_amount=opt_obj["one_day_amount"];
        if(opt_obj["one_day_remain_amount"]>0){
          success_amount=opt_obj["one_day_remain_amount"];
          opt_obj["one_day_remain_amount"]=0;
        }
        if(remain_amount<success_amount){
          success_amount=remain_amount;
        }
        remain_amount-=success_amount;
      }else if(todo_content["a_success_sort"]=="time"){
        success_time_sec=opt_obj["one_day_amount_time"];
        if(opt_obj["one_day_remain_time"]>0){
          success_time_sec=opt_obj["one_day_remain_time"];
          opt_obj["one_day_remain_time"]=0;
        }
        if(remain_amount<success_time_sec){
          success_time_sec=remain_amount;
        }
        remain_amount-=success_time_sec;
      }

      let add_todo_write_row={
        ...this_obj.todo_write_default,
        "a_ymd":strFunc.str_replace("-","",todo_write_start_date),
        "a_is_mcomp_public":make_option["is_mcomp_publiic"],
        "a_sort":todo_content["a_sort"],
        "a_par_id":todo_content["a_par_id"],
        "a_par_seq":todo_content["a_par_seq"],
        "a_is_reserve":make_option["is_reserve"],
        "a_reserve_start_date":todo_write_start_date,
        "a_reserve_end_date":todo_write_end_date,
        "a_success_sort":todo_content["a_success_sort"],
        "a_amount_sort":todo_content["a_amount_sort"],
        "a_success_amount":success_amount,
        "a_success_time_sec":success_time_sec,
        "a_subject":todo_content["a_subject"],
        "a_title":todo_content["a_title"],
        "a_content":todo_content["a_content"],
      };
      tmp_todo_write_arr.push(add_todo_write_row);
      if(opt_obj["is_go_next_date"]){
        ing_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(ing_date),'day',+1),"Y-m-d");
      }
    }
    let result_data_arr={
      todo_write_arr:tmp_todo_write_arr,
      ing_date:ing_date,
    };
    return result_data_arr;
  };
  static get_able_date_at_week(inData:any){
    let opt_obj={
      "date_str":"",
      "apply_day_arr":[],
      ...inData
    };
    let able_date_str=opt_obj["date_str"];

    for(let i=0;i<8;i++){
      let tmp_able=this.is_able_day_of_week({
        "date_str":able_date_str,
        "apply_day_arr":opt_obj["apply_day_arr"],
      });
      
      if(tmp_able){
        return able_date_str;
      }
      able_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(able_date_str),'day',+1),"Y-m-d");
    }
    return false;
  }
  static is_able_day_of_week(inData:any){
    let opt_obj={
      "date_str":"",
      "apply_day_arr":[],
      ...inData
    };
    let next_day_json=DateFunc.get_date_json(new Date(opt_obj["date_str"]));
    if(strFunc.str_in_array(next_day_json.day,opt_obj["apply_day_arr"])!=-1){
      return true;
    }
    return false;
  }
};
export default MakeTodoWriteFunc;