import FolderManageList from "@/pages/comp/edu/lecture/backup_folder/common/manage_list";

function FolderManagePopup(props: any) {
  let myProps = {
    closePopup:(inData:any)=>{},
    list: (inData:any) => { },
    ...props
  };

  return (
    <div>
      <FolderManageList list={myProps.list}></FolderManageList>
    </div>
  );
};
export default FolderManagePopup;