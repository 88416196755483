import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import DayOfWeekPick from "./area/day_of_week_pick";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import ChangeDateFunc from "./func/change_date_func";

function MoveTodoDatePopup(props:any) {
  let myProps={
    info_arr:[],
    callback:()=>{},
    closePopup:()=>{},
    ...props
  };
  let default_make_option={
    "change_day":"1",
    "apply_day":"1,2,3,4,5",
  };
  const [make_option,set_make_option]=useState<any>(default_make_option);
  const xColumnArr=getXcolumnJson();
  const listAggridRef=useRef<any>(null);
  const [infoArr, setInfoArr] = useState([]);

  useEffect(() => {
    
  }, []);

  const list=()=>{
    let pri_val_arr=[];
    for(let i=0;i<myProps.info_arr.length;i++){
      let info=myProps.info_arr[i];
      let row_pri_val=info["a_ymd"]+","+info["a_seq"];
      pri_val_arr.push(row_pri_val);
    }
    if(pri_val_arr.length==0){
      return false;
    }
    let list_form_json={
      "s_pri_arr":pri_val_arr,
      "order_id":"a_reserve_start_date",
      "s_addon_par_content":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const go_write = ()=>{
    let selected_row=listAggridRef.current.getRows();
    if(selected_row.length==0){
      alert("데이터가 없습니다.");
      return false;
    }

    if(!confirm("날짜 이동을 저장 하시겠습니까?")){
      return false;
    }

    let change_data_arr:any=selected_row;

    let form_json_data={
      "data_arr":change_data_arr,
      "is_update":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/write',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("성공");
        myProps.callback();
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const go_change_date_by_opt=()=>{
    let change_info_arr=ChangeDateFunc.change_date_action({
      info_arr:infoArr,
      make_option:make_option
    });
    
    setInfoArr(change_info_arr);
    if(listAggridRef.current){
      listAggridRef.current.setInfoArr(change_info_arr);
    }
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="change_day"){
      value=strFunc.uncomma(value);
    }

    let chage_data_json:any={
      [name]: value
    };
    
    set_make_option({
      ...make_option,
      ...chage_data_json
    });
  };

  
  const cellClickedListener = (params:any) => {
    var key=params.colDef.field;
   
  };

  const cellRenderer= (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="row_view_state"){
      let state_str=params.data.a_state;
      if(xColumnArr.select_arr["a_state"]){
        for(let i=0;i<xColumnArr.select_arr["a_state"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_state"][i];
          if(tmp_option_row["value"]==state_str){
            state_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {state_str}
        </div>
      );
    }else if(key=="row_view_per"){
      let per_str=TodoStateFunc.get_percent_by_todo_info(params.data);
      if(params.data.a_is_success=="1"){
        per_str=100;
      }
      let per_color_json=TodoStateFunc.get_color_of_percent(per_str);
      let per_div_style:any={};
      per_div_style["color"]=per_color_json.per;
      render_str=(
        <div style={per_div_style}>
          {per_str} %
        </div>
      );
    }
    return render_str;
  };

  return (
    <div>
      <h3>플랜 {infoArr.length} 개</h3>
      <div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:false,
          floatingFilter:false,
          height:340,
          onGridReady:()=>{
            list();
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>
      </div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>미루기</th>
              <td>
                <input className="row-input" name="change_day" 
                  value={make_option.change_day} 
                  onChange={handleInputChange} 
                  style={{width:50,textAlign:"center"}}/>
                일 뒤로
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td>
                <DayOfWeekPick
                  value={make_option.apply_day}
                  name={"apply_day"}
                  handleInputChange={handleInputChange}
                ></DayOfWeekPick>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{go_change_date_by_opt();}}>이동</button>
        <button className="btn btn-line-gray" onClick={()=>{list();}}>초기화</button>
        <button className="btn-m btn-sky" onClick={()=>{go_write();}} >저장</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default MoveTodoDatePopup;
