import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import DatePicker from "react-datepicker";

function SearchArea(props:any){
  let myProps:any={
    listOpt:{
      s_date_type:"",
      s_start_date:"",
      s_end_date:"",
    },
    list:(listOpt:any)=>{},
    ...props
  };

  const [listOpt,setListOpt]=useState(myProps.listOpt);

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{[key]:date_str}
    });
    myProps.list({
      now_page:"1",
      ...{[key]:date_str}
    });
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    setListOpt({
      ...listOpt,
      ...{[name]:value}
    });
    myProps.list({
      now_page:"1",
      ...{[name]:value}
    });
  };

  const goDateMonthChange=(sort:any)=>{
    //next,pre
    let selected_date=listOpt.s_start_date;
    if(selected_date==""){
      selected_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let change_date_obj=new Date(selected_date);
    if(sort=="pre"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'day',-1);
    }else if(sort=="next"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'day',+1);
    }
    
    let start_date=DateFunc.get_date_format(change_date_obj,"Y-m-d");
    let end_date=DateFunc.get_date_format(change_date_obj,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
    myProps.list({
      now_page:"1",
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
  };

  return (
    <div>
      <div className="btn-box-left relative">
        <select className="search-input_select" name="s_date_type" value={listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_create_date">등록일</option>
        </select>
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("pre");}} >◀</button>
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("next");}} >▶</button>
        <span style={{width:100}}>
          <DatePicker 
              selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
              onChange={(date:Date) => {
                onChangeDatePicker("s_start_date",date);
              }}
              locale={ko} 
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputSearch />}
              renderCustomHeader={customHeaderFunc}
            />
          </span>~
          <span style={{width:100}}>
            <DatePicker 
              selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
              onChange={(date:Date) => {
                onChangeDatePicker("s_end_date",date);
              }}
              locale={ko} 
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputSearch />}
              renderCustomHeader={customHeaderFunc}
            />
          </span>
          <button className="btn btn-dark" onClick={()=>{myProps.list({"now_page":1});}}>검색</button>
        <div className="absolute right-0 top-0 btn-box-left">
          
        </div>
      </div>
      <div className="btn-box-left relative mt-1">
       
      </div>
    </div>
  );
}
export default SearchArea;