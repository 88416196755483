//import SelectTab from "@/pcomponents/common/crud/write/select_tab";
function SelectTab(props:any){
   let myProps={
    select_arr:[],//[{text:'',value:'',title:''}]
    value:"",
    on_select:(value:string)=>{},
    is_same_value_empty:true,
    wrap_class_name:"write_select_tab",
    item_class_name:"write_select_tab_item",
    active_class_name:"active",
    ...props
  };

  const get_select_tab_tags=()=>{
    let select_tab_tags=myProps.select_arr.map((item:any,idx:number)=>{
      let row_class_name=myProps.item_class_name;
      let title_str=item.text;
      if(item.title!=undefined){
        title_str=item.title;
      }
      if(item.value==myProps.value){
        row_class_name+=" "+myProps.active_class_name;
      }
      return (
        <div key={idx} className={row_class_name} onClick={()=>{
          let change_value=item.value;
          if(myProps.is_same_value_empty){
            if(item.value==myProps.value){
              change_value="";
            }
          }
          myProps.on_select(change_value);
        }} title={title_str}>
          {item.text}
        </div>
      );
    });
    return select_tab_tags;
  };
  
  return (
    <div className={myProps.wrap_class_name}>
      {get_select_tab_tags()}
    </div>
  );
};
export default SelectTab;