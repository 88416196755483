export const default_info={
  "a_ymd":"",
  "a_seq":"",
  "a_corp_seq":"",
  "a_stu_seq":"",
  "a_stu_name":"",

  "a_study_date":"",
  "a_writer_seq":"",
  "a_writer":"",
  "a_par_id":"",
  "a_par_seq":"",

  "a_subject_name":"",
  "a_study_progress":"",
  "a_study_score":"",
  "a_homework_progress":"",
  "a_homework_perform_score":"",
  "a_progress_plan":"",
  "a_attitude_score":"",
  "a_attitude":"",
  "a_homework_score":"",
  "a_homework":"",
  "a_etc_memo":"",
  "a_study_prescribe":"",

  "a_title":"",
  "a_content":"",
};