function SearchArea(props: any) {
  let myProps = {
    xColumnArr: {},
    list_opt: {},
    list: (inData: any) => { },
    change_en_class_seq: "",
    set_change_en_class_seq: (inData: any) => { },
    en_class_arr: [],
    go_move_class: (inData: any) => { },
    open_find_stu: (inData: any) => { },
    go_coolen_link_user_multy: (inData: any) => { },
    ...props
  };

  const handle_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_list_opt: any = {
      "now_page": "1",
    };
    change_list_opt[name] = value;
    myProps.list(change_list_opt);
  };

  return (
    <div style={{ position: "relative" }}>
      <select className="search-input" name="s_grade" value={myProps.list_opt.s_grade}
        onChange={handle_change_input} >
        <option value="" >전체</option>
        <option value="student" >학생</option>
      </select>
      <select className="search-input ml-2" name="s_is_login" value={myProps.list_opt.s_is_login}
        onChange={handle_change_input}>
        <option value="" >로그인</option>
        <option value="1" >로그인 허용</option>
        <option value="empty" >로그인 미허용</option>
      </select>
      <select className="search-input ml-2" name="s_stu_state_and_stu" value={myProps.list_opt.s_stu_state_and_stu}
        onChange={handle_change_input} >
        <option value="">학생상태</option>
        {
          myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <div style={{ position: "absolute", right: 0, top: 0, display: "inline-block" }}>
        <span>
          <button className="btn btn-gray" onClick={() => { myProps.open_find_stu(); }}>초대</button>
        </span>
        <span className="ml-2">
          <select value={myProps.change_en_class_seq} className="search-input" onChange={(e: any) => {
            myProps.set_change_en_class_seq(e.target.value);
          }} >
            <option value="">선택없음</option>
            {myProps.en_class_arr.map((item: any, idx: number) => {
              if (item.a_seq == myProps.list_opt.s_en_class_seq) {
                return "";
              }
              return (
                <option key={idx} value={item.a_seq}>{item.a_name}</option>
              );
            })}
          </select>
          <button className="btn btn-gray ml-1" onClick={myProps.go_move_class} >클래스이동</button>
        </span>
        <span className="ml-2">
          <button className="btn btn-sky" onClick={() => {
            myProps.go_coolen_link_user_multy({
              is_allow_coolen: "1",
              confirm_msg: "연동 승인처리 하시겠습니까?",
            });
          }} >연동승인</button>
          <button className="btn btn-red ml-1" onClick={() => {
            myProps.go_coolen_link_user_multy({
              is_allow_coolen: "",
              confirm_msg: "연동 미승인처리 하시겠습니까?",
            });
          }}>연동미승인</button>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;