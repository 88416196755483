
const CardBoxAttendArea = (props:any) => {
  let myProps:any={
    user_info:{},
    attend_in_info:null,
    attend_out_info:null,
    attend_color:"",
    deleteAttend:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    xColumnArr:{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;

  const getAttendInCellRenderBtns=()=>{
    let attend_in_state_arr=[
      {"value":"attend","text":"등원","class_name":Style.attend_in_btn},
      {"value":"late","text":"지각","class_name":Style.attend_late_btn},
      {"value":"absent","text":"결석","class_name":Style.attend_no_btn},
    ];
    let attend_in_btns=attend_in_state_arr.map((item:any,idx:number)=>{
      let btn_class=Style.attend_btn;
      let is_checked_btn=false;
      let btn_text=item.text;
      let btn_attend_memo="";
      let attend_info:any=myProps.attend_in_info;
      if(attend_info!=null){
        if(attend_info["a_sort_detail"]==item["value"]){
          btn_class+=" "+item.class_name;
          is_checked_btn=true;
          if(attend_info["a_sort_detail"]!="absent"){
            btn_text=attend_info["a_time"].substring(0,5);
          }
          btn_attend_memo=attend_info["a_memo"];
        }
      }
      if(idx!=0){
        btn_class+=" ml-2";
      }

      return (
        <button key={idx}
         className={btn_class}
         onClick={()=>{
          if(is_checked_btn){
            if(attend_info!=null){
              myProps.deleteAttend([attend_info],"등원 취소 하시겠습니까?");
            }
          }else{
            myProps.openAttendPopup([myProps.user_info],"in",item.value);
          }
        }}
        title={btn_attend_memo}
         >
          {btn_text}
        </button>
      );
    });
    return attend_in_btns;
  };

  let is_checked_btn=false;
  let attend_out_info:any=myProps.attend_out_info;
  let attend_out_class_name=Style.attend_btn;
  if(attend_out_info!=null){
    if(attend_out_info["a_sort"]=="out"){
      is_checked_btn=true;
      attend_out_class_name+=" "+Style.attend_out_btn;
    }
  }
  let attend_out_btn:any="";
  if(myProps.attend_in_info!=null){
    if(myProps.attend_in_info["a_sort_detail"]!="absent"){
      let btn_text="하원";
      let attend_out_memo="";
      if(attend_out_info!=null){
        btn_text=attend_out_info["a_time"].substring(0,5);
        attend_out_memo=attend_out_info["a_memo"];
      }
      attend_out_btn=(
        <button className={attend_out_class_name+" ml-2"} 
          onClick={()=>{
            if(is_checked_btn){
              if(attend_out_info!=null){
                myProps.deleteAttend([attend_out_info],"하원 취소 하시겠습니까?");
              }
            }else{
              myProps.openAttendPopup([myProps.user_info],"out","out");
            }
          }} title={attend_out_memo}>
          {btn_text}
        </button>
      );
    }
  }

  let attend_state_str="미등원";
  let attend_time="00:00";
  let attend_out_time="00:00";
  if(myProps.attend_in_info!=null){
    attend_time=myProps.attend_in_info["a_time"].substring(0,5);
    let a_sort_detail=myProps.attend_in_info["a_sort_detail"];

    if(myProps.attend_out_info!=null){
      attend_out_time=myProps.attend_out_info["a_time"].substring(0,5);
      a_sort_detail=myProps.attend_out_info["a_sort_detail"];
    }

    if(myProps.xColumnArr.select_arr.a_sort_detail){
      for(let i=0;i<myProps.xColumnArr.select_arr.a_sort_detail.length;i++){
        let item=myProps.xColumnArr.select_arr.a_sort_detail[i];
        if(item.value==a_sort_detail){
          attend_state_str=item.text;
        }
      }
    }

  }
  if(attend_state_str=="등원"){
    attend_state_str="등원완료";
  }

  return (
    <>
      <div className={Style.card_box_attend_btn_wrap} >
        <div className={Style.card_box_attend_btn_box}>
          {getAttendInCellRenderBtns()}
          {attend_out_btn}
        </div>
      </div>
    </>
  );
};

export default CardBoxAttendArea;