import strFunc from "@/lib/lyg/string";

function SubInfoArea(props: any) {
  let myProps = {
    Style: {},
    info: {},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let issu_text="";
  let counsel_text="";
  if(info.add_memo_info){
    issu_text=info.add_memo_info.a_matter_condition;
    counsel_text=info.add_memo_info.a_counsel_content;
  }

  return (
    <div className={Style.sub_info_wrap}>
      <table className={Style.sub_info_table} style={{width:"100%"}}>
        <colgroup>
          <col width={"10%"}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th className="text-left">Issue</th>
            <td>
              {strFunc.cut_str(issu_text,10,"..")}
            </td>
          </tr>
          <tr>
            <th className="text-left">상담</th>
            <td>
              {strFunc.cut_str(counsel_text,10,"..")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SubInfoArea;