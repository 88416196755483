import CompLayout from "@/pcomponents/comp/layout/layout";
import Style from "./css/style.module.css";
import { useState, useRef, useEffect } from 'react';
import StuSearchList from "./area/stu_list";
import ManageStudyArea from "./area/manage_study";

function StudyStuProgressPage() {
  const left_box_ref = useRef<any>(null);
  const StuSearchListRef = useRef<any>(null);
  const right_box_ref = useRef<any>(null);
  const ManageStudyAreaRef = useRef<any>(null);

  const go_search_progress = (inData: any) => {
    let opt_obj = {
      "s_stu_user_seq": [],
      "s_stu_info_arr": [],
      ...inData
    };

    if (ManageStudyAreaRef.current) {
      let s_stu_seq = "";
      if (opt_obj["s_stu_user_seq"] && opt_obj["s_stu_user_seq"].length > 0) {
        s_stu_seq = opt_obj["s_stu_user_seq"][0];
      }
      ManageStudyAreaRef.current.setInitData({
        stu_seq: s_stu_seq
      });
    }
  };

  const go_search_user_list = (inData: any) => {
    if (StuSearchListRef.current) {
      StuSearchListRef.current.list(inData);
    }
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <div className={Style.wrap}>
          <div className={Style.left_box} ref={left_box_ref}>
            <StuSearchList
              ref={StuSearchListRef}
              list={(inData: any) => { go_search_progress(inData); }}
            ></StuSearchList>
          </div>
          <div className={Style.right_box} ref={right_box_ref}>
            <ManageStudyArea
              ref={ManageStudyAreaRef}
              search_user_list={(inData: any) => { go_search_user_list(inData); }}
            ></ManageStudyArea>
          </div>
        </div>
      </div>
    </CompLayout>
  );
}

export default StudyStuProgressPage;