import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"supply_multy_add",
    x_column_list_arr:[
      {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_user_name","name":"이름","width":"100","is_show":"1"},
      {"key":"row_view_class_name","name":"클래스","width":"90","is_show":"1"},
      {"key":"row_view_class_money","name":"금액","width":"90","is_show":"1"},
      {"key":"a_stu_supply_discount","name":"할인액","width":"100","is_show":"1"},
      //{"key":"a_stu_state","name":"현황","width":"70","is_show":"1"},
      //{"key":"a_school_name","name":"학교","width":"70","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"70","is_show":"1"},
      {"key":"a_stu_supply_day","name":"청구일","width":"80","is_show":"1"},
      {"key":"row_view_supply_cnt","name":"청구건수","width":"100","is_show":"1"},
      {"key":"row_view_supply_money","name":"청구액","width":"100","is_show":"1"},
      // {"key":"row_view_supply_in_money","name":"납부액","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;