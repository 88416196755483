//import EvalFunc from "@/pcomponents/common/content/eval/func/eval_func";
import strFunc from '@/lib/lyg/string';

class EvalFunc
{
  static get_detail_total_data_by_detail_arr(detail_arr:any[]){
    let total_data:any={
      "count":0,
      "average_score":0,
      "sum_score":0,
      "per_arr":[],
    };
    if(strFunc.is_empty(detail_arr)){
      return total_data;
    }

    if(detail_arr&&detail_arr.length>0){
      let total=0;
      let sum_per_num=0;
      let detail_arr_len=detail_arr.length;
      for(let i=0;i<detail_arr_len;i++){
        let detail_info=detail_arr[i];
        let a_score=parseInt(strFunc.uncomma(detail_info["a_score"]));
        let a_max_score=parseInt(strFunc.uncomma(detail_info["a_max_score"]));
        let row_per_num:any=0;
        total_data["sum_score"]+=a_score;
        if(a_score!=0&&a_max_score!=0){
          row_per_num=(a_score/a_max_score)*100;
        }
        total_data["per_arr"].push(row_per_num);
        sum_per_num+=row_per_num;
        total++;
      }
      if(total!=0&&sum_per_num!=0){
        total_data["average_score"]=(sum_per_num/total);
        total_data["average_score"]=parseInt(total_data["average_score"]);
        total_data["count"]=total;
      }
    }

    return total_data;
  }
}
export default EvalFunc;