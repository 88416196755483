import { useSelector, useDispatch } from 'react-redux';
import Style from "./css/top_area.module.css";
import { Link,useNavigate } from 'react-router-dom';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';
import { setUser,removeUser } from '@/store/user/user';
import MiddleArea from "./middle";

function TopArea(){
  let user=useSelector((state:any) => state.user);
  const chat = useSelector((state:any) => state.chat);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    
  },[]);

  const movePage=(url:string)=>{
    navigate(url);
  };

  const goLogOut=()=>{
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/logout',{ 
      token_id: localStorage.intoyou_token,
    },get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("intoyou_token");
      navigate("/login");
    });
  };

  return (
    <>
      <div className="con_wrap" style={{marginTop:28}}>
        <div className={Style.top_area}>
          <span className={Style.top_area_title}>{user.user_name}</span> 
          <span style={{color:"gray",marginLeft:5}}>님</span> 
          {chat.unreadToT!=0&&
            <span className="mx-1 text-red-700">{chat.unreadToT}</span>
          }
          <span>
            <a className="ml-7" onClick={()=>{movePage("/comp/mypage");}}>내정보</a>
            <span className="mx-1">|</span>
            <a onClick={()=>{goLogOut();}}>로그아웃</a>
          </span>
        </div>
        <a className={Style.top_area_comp_manage_btn} onClick={()=>{movePage("/comp/basic/corp/main");}} >정보수정{" >"}</a>
      </div>
      <div className="con_wrap" style={{borderTop:"1px solid #eee",borderBottom:"1px solid #eee",position:"relative"}}>
        <div className={Style.total_cnt_box} style={{marginLeft:0}}>
          <span className={Style.total_cnt_box_title} >플랜톡</span>
          <span className={Style.total_cnt_box_num}>{chat.unreadToT}</span>
        </div>
        <div className={Style.total_cnt_box}>
          <span className={Style.total_cnt_box_title} >어제학습현황</span>
          <span className={Style.total_cnt_box_num}>0%</span>
        </div>
        <div className={Style.total_cnt_box}>
          <span className={Style.total_cnt_box_title} >원생관리</span>
          <span className={Style.total_cnt_box_num}>+0</span>
        </div>
        <div className={Style.total_cnt_box}>
          <span className={Style.total_cnt_box_title} >미납내역</span>
          <span className={Style.total_cnt_box_num}>0</span>
        </div>
        <div className={Style.total_cnt_box}>
          <span className={Style.total_cnt_box_title} >교재샵</span>
          <span className={Style.total_cnt_box_num}>0</span>
        </div>
        <div className={Style.total_cnt_box}>
          <span className={Style.total_cnt_box_title} >게시판</span>
          <span className={Style.total_cnt_box_num}>0</span>
        </div>
        <a className={Style.top_area_comp_manage_btn} onClick={()=>{movePage("/");}} >홈페이지{" >"}</a>
      </div>
      <MiddleArea></MiddleArea>
    </>
  );
}
export default TopArea;