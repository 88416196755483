import Style from "./css/style.module.css";
import { Link,useNavigate } from 'react-router-dom';
import {hmenu,smenu} from "@/pcomponents/comp/layout/menu/data/menu_arr";

const TopSiteMap=(props:any)=>{
  let myProps={
    ...props
  };
  const navigate = useNavigate();

  const movePage=(url:string)=>{
    navigate(url);
  };

  const getSubMenuTagsByHmenu=(hmenu_key:string)=>{
    let sub_menu_tags:any="";
    let smenu_arr_of_head=[];
    let smenu_len=smenu.length;
    for(let i=0;i<smenu_len;i++){
      let smenu_info=smenu[i];
      if(smenu_info["hmenu"]==hmenu_key){
        if(smenu_info["isShow"]){
          smenu_arr_of_head.push(smenu_info);
        }
      }
    }

    sub_menu_tags=smenu_arr_of_head.map((item:any,idx:number)=>{
      let move_url=item.url;
      return (
        <div key={idx} className={Style.item} onClick={()=>{movePage(move_url);}}>
          {item.name}
        </div>
      );
    });

    return sub_menu_tags;
  };

  const menuArrTags=hmenu.map((item,idx)=>{
    if(item.isShow==false){
      return "";
    }
    let sub_menu_tags=getSubMenuTagsByHmenu(item.key);
    return (
      <div key={idx} className={Style.item_box} >
        <div className={Style.item_box_con}>
          {sub_menu_tags}
        </div>
      </div>
    );
  });

  return (
    <div className={Style.site_map_wrap}>
      <div className={Style.site_map_con}>
        <div className={Style.item_wrap}>
          {menuArrTags}
        </div>
      </div>
    </div>
  );
};
export default TopSiteMap;