import strFunc from "@/lib/lyg/string";
import { useState } from 'react';
import SectionArea from "./section";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import VideoPreViewPopup from '@/pages/comp/plan/video/corse/popup/video_preview';

function SectionVideoArea(props:any){
   let myProps={
    "section_arr":[],
    "video_arr":[],
    "set_section_arr":(inData:any)=>{},
    "set_video_arr":(inData:any)=>{},
    ...props
  };

  let section_arr:any=myProps.section_arr;

  let default_section_info={
    a_corse_seq:"",
    a_seq:"",
    a_title:"",
    a_order_num:"",
    video_arr:[],//[{row_num:0},...]
  };

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"view",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  const openPreviewPopup=(row_data:any)=>{
    let pop_title="미리보기";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "rowData":row_data,
      "title":pop_title,
      "sort":"view"
    });
  };

  const addNewRow=()=>{
    let max_a_seq=0;
    if(section_arr.length>0){
      for(let i=0;i<section_arr.length;i++){
        let tmp_a_seq=parseInt(section_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }
      }
    }
    let addRow:any={...default_section_info};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr.push(addRow);
    myProps.set_section_arr(tmp_section_arr);
  };

  const removeRowData=(row_num:number)=>{
    let tmp_section_arr=[];
    for(let i=0;i<section_arr.length;i++){
      if(i!=row_num){
        tmp_section_arr.push(section_arr[i]);
      }
    }
    myProps.set_section_arr(tmp_section_arr);
  };

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_section_arr:any=[...section_arr];

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;

    tmp_section_arr=getReSetOrderNum(tmp_section_arr);

    myProps.set_section_arr(tmp_section_arr);
  };

  const getReSetOrderNum=(tmp_section_arr:any)=>{
    for(let i=0;i<tmp_section_arr.length;i++){
      tmp_section_arr[i].a_order_num=i+1;
    }
    return tmp_section_arr;
  };

  const onChangeRowData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      key:"",
      value:"",
      row_num:0,
      ...inOptObj
    };
    let value=optObj["value"];
    let key=optObj["key"];
    let row_num=optObj["row_num"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr[row_num][key]=value;

    myProps.set_section_arr(tmp_section_arr);
  };

  const get_section_arr_of_addon_video=()=>{
    let addon_section_arr=[];
    let section_len=myProps.section_arr.length;
    let video_row_num_arr=[];
    for(let section_i=0;section_i<section_len;section_i++){
      let section_info={
        ...default_section_info,
        ...myProps.section_arr[section_i]
      };
      section_info.video_arr=[];
      for(let i=0;i<myProps.video_arr.length;i++){
        let video_info=myProps.video_arr[i];
        if(section_info["a_seq"]==video_info["a_section_seq"]){
          video_info["row_num"]=i;
          section_info.video_arr.push(video_info);
          video_row_num_arr.push(i);
        }
      }
      addon_section_arr.push(section_info);
    }

    //빈섹션 등록
    if(video_row_num_arr.length!=myProps.video_arr.length&&myProps.video_arr.length>0){
      let last_section_num=myProps.section_arr.length+1;
      let empty_section_info:any={
        ...default_section_info,
        a_corse_seq:"",
        a_seq:"empty",
        a_title:"소속없음",
        a_order_num:last_section_num,
      };
      empty_section_info.video_arr=[];
      for(let i=0;i<myProps.video_arr.length;i++){
        let video_info=myProps.video_arr[i];
        if(strFunc.str_in_array(i,video_row_num_arr)==-1){
          video_info["row_num"]=i;
          empty_section_info.video_arr.push(video_info);
          video_row_num_arr.push(i);
        }
      }
      addon_section_arr.push(empty_section_info);
    }

    return addon_section_arr;
  };

  const get_section_tag_arr=()=>{
    //section_arr 만들기
    let addon_section_arr=get_section_arr_of_addon_video();

    //tags얻기
    let section_tags:any=addon_section_arr.map((item:any,idx:number)=>{
      return (
        <SectionArea
          key={idx}
          section_info={item}
          section_row_num={idx}
          section_arr={myProps.section_arr}
          video_arr={myProps.video_arr}
          set_section_arr={myProps.set_section_arr}
          set_video_arr={myProps.set_video_arr}

          onChangeRowData={onChangeRowData}
          moveOrderNum={moveOrderNum}
          removeRowData={removeRowData}
          openPreviewPopup={openPreviewPopup}
        ></SectionArea>
      );
    });
    
    return section_tags;
  };

  
  return (
    <div style={{border:"1px solid #ddd",padding:10}}>
      <h4 className="mt-4" style={{color:"#00f"}}>단원</h4>
      <div className="btn-box-left mt-2">
        <button className="btn btn-dark" onClick={()=>{addNewRow();}}>대단원 추가</button>
      </div>
      <div className="mt-1">
        {get_section_tag_arr()}
      </div>
      {popupData.isOpen && 
        <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
          width={popupData.width} height={popupData.height} >
            {popupData.sort==="view"&&
              <VideoPreViewPopup rowData={popupData.rowData}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
              ></VideoPreViewPopup>
            }
        </LayerPopup>
      }
    </div>
  );
};
export default SectionVideoArea;