import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"mcash_receipt",
    list_id:"cash_receipt_list",
    x_column_list_arr:[
      {"key":"a_date","name":"발급일","width":"100","is_show":"1"},
      {"key":"a_money","name":"금액","width":"110","is_show":"1"},
      {"key":"a_number","name":"발급번호","width":"160","is_show":"1"},
      {"key":"a_title","name":"발급명","width":"120","is_show":"1"},
      {"key":"a_sort","name":"구분","width":"120","is_show":"1"},
      {"key":"a_reporter","name":"발급자","width":"120","is_show":"1"},
      {"key":"a_approval_num","name":"승인번호","width":"120","is_show":"1"},
      {"key":"a_is_cancel","name":"취소여부","width":"120","is_show":"1"},
      {"key":"a_cancel_memo","name":"취소사유","width":"120","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':20,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;