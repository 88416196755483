import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "../../css/style.module.css";
import strFunc from '@/lib/lyg/string';
import play_ico_img from "@/img/ico/play_ico.png";
import stop_ico_img from "@/img/ico/stop_ico.png";

const ClockeArea=forwardRef((props:any, ref) => {
  let myProps:any={
    row_data:{},
    set_row_data:(inData:any)=>{},
    onStart:()=>{},
    onStop:()=>{},
    ...props
  };

  const [row_data,set_row_data]= useState<any>(myProps.row_data);
  const [isPlay,setIsPlay]=useState<any>(false);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));
  useEffect(() => {
    if(row_data.a_state=="ing"){
      setIsPlay(true);
    }
  },[row_data]);

  useEffect(() => {
    const timerId=setInterval(goTimeCountInterval,1000);
    return () => clearInterval(timerId);
  },[row_data,isPlay]);

  const setInitData=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
     row_data:{},
     ...inData
    }

    set_row_data(optObj.row_data);
  };
  
  const goTimeCountInterval=()=>{
    if(isPlay&&row_data){
      let a_time_sec=parseInt(strFunc.uncomma(row_data.a_time_sec));
      a_time_sec++;

      set_row_data({
        ...row_data,
        ...{
          "a_time_sec":a_time_sec
        }
      });
      myProps.set_row_data({
        ...row_data,
        ...{
          "a_time_sec":a_time_sec
        }
      });
    }
  };

  const onStartEndToggle=()=>{
    if(isPlay){
      myProps.onStop();
    }else{
      myProps.onStart();
    }
    setIsPlay(!isPlay);
  }

  let time_sec_json:any=strFunc.secondsToTimeJson(strFunc.uncomma(row_data.a_time_sec));
  let play_stop_img=play_ico_img;
  if(isPlay){
    play_stop_img=stop_ico_img;
  }

  return (
    <div className={Style.view_clock_wrap}>
      <div className={Style.view_clock_box}>
        <div className={Style.view_clock_time_wrap}>
          <div className={Style.clock_time_div}>
            {time_sec_json.h}:{time_sec_json.i}:{time_sec_json.s}
          </div>
          <div>
            <a onClick={()=>{onStartEndToggle();}}>
              <img src={play_stop_img} style={{width:40}} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ClockeArea;