import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import { useState } from 'react';

function SearchSubArea(props: any) {
  let myProps = {
    listOpt: {},
    openAttendBySelect: (inData: any) => { },
    list: (inData: any) => { },
    navigate: (inData: any) => { },
    on_check_all: (inData: any) => { },
    onClickPrint: (inData: any) => { },
    sms_phone_check: "",
    set_sms_phone_check: (inData: any) => { },
    openSendSmsPopup: (inData: any) => { },
    save_daily_report_by_stu_arr: (inData: any) => { },
    delete_daily_report_by_stu_arr: (inData: any) => { },
    ...props
  };

  const [attend_btn_sort, set_attend_btn_sort] = useState("attend");

  let attend_search_arr=[
    {value:"attend",text:"등원",background:"#53a500"},
    {value:"late",text:"지각",background:"#eab358"},
    {value:"absent",text:"결석",background:"#F26B6F"},
    // {value:"out",text:"하원"},
  ];

  const get_search_attend_btn_tags=()=>{
    let search_attend_tags=attend_search_arr.map((item:any,idx:number)=>{
      let btn_background=item.background;
      if(myProps.listOpt.s_attend_sort_detail!=""){
        if(myProps.listOpt.s_attend_sort_detail!=item.value){
          btn_background="#a2a2a2";
        }
      }
      return (
        <button key={idx} className="btn-m btn-gray ml-1" 
          style={{background:btn_background}}
          onClick={()=>{
            let change_val=item.value;
            if(change_val==myProps.listOpt.s_attend_sort_detail){
              change_val="";
            }
            myProps.list({
              "now_page": 1,
              s_attend_sort_detail: change_val
            });
          }} >{item.text}</button>
      );
    });

    return search_attend_tags;
  };

  return (
    <div>
      <div className="text-right mt-2">
        <span>
          {get_search_attend_btn_tags()}
        </span>
        <span className="ml-2">
          출석
          <select className="border" value={attend_btn_sort}
            onChange={(e:any)=>{set_attend_btn_sort(e.target.value);}}>
            <option value="attend">등원</option>
            <option value="absent">결석</option>
            <option value="out">하원</option>
          </select>
          <button className="btn-s btn-gray ml-1"
            onClick={() => { 
              let tmp_in_out="in";
              if(attend_btn_sort=="out"){
                tmp_in_out="out";
              }
              myProps.openAttendBySelect(tmp_in_out, attend_btn_sort);
            }} >선택적용</button>
        </span>
      </div>
      <div className="mt-2" style={{ position: "relative" }}>
        <label>
          <input type="checkbox" onChange={myProps.on_check_all} />
          <span className="ml-1">전체</span>
        </label>
        <button className="btn-s btn-yellowish2 ml-1" onClick={myProps.onClickPrint}>프린트</button>
        <span style={{ position: "absolute", right: 0 }}>
          <RadioListArea
            valueTextArr={[
              { "value": "student", "text": "학생" },
              { "value": "parent", "text": "부모님" },
              { "value": "both", "text": "둘다" },
            ]}
            value={myProps.sms_phone_check}
            name={"sms_phone_check"}
            handleInputChange={(e: any) => {
              myProps.set_sms_phone_check(e.target.value);
            }}
          ></RadioListArea>
          <button className="btn-s btn-gray ml-2" onClick={() => { myProps.openSendSmsPopup(); }} >문자전송</button>

          <span className="ml-1">일일보고서</span>
          <button className="btn-s btn-yellowish2 ml-2" onClick={(e: any) => { myProps.save_daily_report_by_stu_arr({}); }}>선택저장</button>
          <button className="btn-s btn-red ml-2" onClick={myProps.delete_daily_report_by_stu_arr} >선택삭제</button>
          <button className="btn-s btn-gray ml-1" onClick={() => { myProps.navigate("/comp/edu/daily_report/history_list"); }}>이력</button>
        </span>
      </div>
    </div>
  );
};
export default SearchSubArea;