import { useState, useRef, useEffect,useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import ListComponentView from '@/pcomponents/common/crud/list/list_only_view';
import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import SelectListArea from "./area/select_list";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import { useSelector, useDispatch } from 'react-redux';

const FindStudentArea=forwardRef((props:any, ref) => {
  let myProps={
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const FindStuWrapRef=useRef<any>(null);
  const SelectStuJustViewRef=useRef<any>(null);
  const ListComponentRef=useRef<any>(null);
  const SelectListAreaRef=useRef<any>(null);
  let origin_order_id="a_create_date DESC";
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    order_id:origin_order_id,
    s_grade:["student"],
    s_addon_class_user:"1",
    s_addon_link_user:"1",
    s_except_user_seq:"",
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq:user.user_grade=="master"?"":strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
  });
  const [infoArr, setInfoArr] = useState([]);
  const [active_row_arr,set_active_row_arr]=useState<any>([]);
  const [select_stu_arr,set_select_stu_arr]=useState<any>([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    get_stu_arr,set_stu_by_user_seq_arr
  }));

  useEffect(() => {
    
    list({});
  }, []);

  const get_stu_arr=()=>{
    let all_row=select_stu_arr;
    return all_row;
  };

  const list = (inOptObj:any)=>{
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        set_active_row_arr(
          ListComponentRef.current.getInitActiveArrByInfoArrLength(response.data["data"]["info_arr"].length)
        );
      }else{
        
      }
    });
  };

  const set_stu_by_user_seq_arr=(stu_seq_arr:[])=>{
    if(strFunc.is_empty(stu_seq_arr)){
      set_select_stu_arr([]);
      return false;
    }
    FindStuWrapRef.current.style.display="none";
    SelectStuJustViewRef.current.style.display="block";
    let tmp_list_opt={
      "s_pri_arr":stu_seq_arr,
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_select_stu_arr(response.data["data"]["info_arr"]);
        if(SelectListAreaRef.current){
          SelectListAreaRef.current.set_init_data({select_stu_arr:response.data["data"]["info_arr"]});
        }
        let s_except_user_seq:any=[];
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          s_except_user_seq.push(response.data["data"]["info_arr"][i]["a_seq"]);
        }
        list({"s_except_user_seq":s_except_user_seq});
      }else{
        
      }
    });
  };


  const select_user_to_right=()=>{
    if(!ListComponentRef.current){
      return false;
    }
    let tmp_select_arr=ListComponentRef.current.getSelectedRows();
    if(tmp_select_arr.length==0){
      alert("선택이 없습니다. ");
      return false;
    }

    let new_select_arr:any=[
      ...select_stu_arr,
      ...tmp_select_arr
    ];

    set_select_stu_arr(new_select_arr);
    if(SelectListAreaRef.current){
      SelectListAreaRef.current.set_init_data({select_stu_arr:new_select_arr});
    }
    let s_except_user_seq:any=[];
    for(let i=0;i<new_select_arr.length;i++){
      s_except_user_seq.push(new_select_arr[i]["a_seq"]);
    }
    list({"s_except_user_seq":s_except_user_seq});
  };

  const customCellRenderer= (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;
    if(key=="a_user_name"){
      render_str +=" "+params.rowData.a_stu_total_code;
    }else if(key=="row_view_class_name"){
      if(params.rowData.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.rowData.class_arr.length;i++){
          class_name_arr.push(
            strFunc.cut_str(params.rowData.class_arr[i]["class_name"],4,"")
          );
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_school_grade"){
      let stu_grade_str="";
      if(xColumnArr.select_arr["a_stu_grade"]){
        for(let i=0;i<xColumnArr.select_arr["a_stu_grade"].length;i++){
          let tmp_row_opt=xColumnArr.select_arr["a_stu_grade"][i];
          if(tmp_row_opt["value"]==params.rowData.a_stu_grade){
            stu_grade_str=tmp_row_opt["text"];
          }
        }
      }
      let a_school_name=params.rowData.a_school_name;
      a_school_name=strFunc.cut_str(a_school_name,2,"");
      render_str=(
        <span title={params.rowData.a_school_name+" "+stu_grade_str}>
          {a_school_name+" "+stu_grade_str}
        </span>
      );
    }else  if(key=="row_view_more"){
      render_str=(
        <div className="text-center">
          <button className="btn btn-line-gray">상세</button>
        </div>
      );
    }else if(key=="row_view_add"){
      render_str=(
        <button className="btn btn-yellowish3" onClick={()=>{
          setTimeout(()=>{select_user_to_right();},100);
        }}>추가</button>
      );
    }
    return render_str;
  };

  return (
    <div className="mt-7">
      <div ref={FindStuWrapRef}>
        <SearchArea 
          listOpt={listOpt}
          setListOpt={setListOpt}
          xColumnArr={{...xColumnArr}}
          list={list}
          ></SearchArea>
        <div className={Style.box_wrap}>
          <div className={Style.box}>
            <div className={Style.left_top_btn_wrap} style={{minWidth:350}}>
              <span style={{fontWeight:500}}>
                [검색 총 {infoArr.length}명]
              </span>

              <button className="btn btn-yellowish3" 
                style={{position:"absolute",right:0}} onClick={()=>{select_user_to_right();}}>선택추가</button>
            </div>
            <ListComponentView
              ref={ListComponentRef}
              infoArr={infoArr}
              active_row_arr={active_row_arr}
              set_active_row_arr={set_active_row_arr}
              xColumnArr={xColumnArr}
              gridOpt={{
                fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                is_add_idx_num:false,
                is_add_checkbox:true,
                td_align:"center",
                height:"35vh",
                is_fix_width:true,
                rowMultiSelectWithClick: true,
                customCellRenderer:customCellRenderer,
                class_name_json:{
                  table_div:"basic_list_div",
                  td_div:"row-col-div",
                  input_check:"chk_row",
                  row_input:"row-input",
                  data_row_tr:"data_row_tr",
                  active_row:"active",
                  empty_con_div:"list_empty_content_div",
                },
              }}
            ></ListComponentView>
            </div>
            <div className={Style.box_center}>
              <span>
                <img src={right_arrow_ico} className={Style.box_center_img} onClick={()=>{select_user_to_right();}}/>
              </span>
            </div>
            <div className={Style.box} style={{minWidth:350}}>
              <SelectListArea
                ref={SelectListAreaRef}
                select_stu_arr={select_stu_arr}
                set_select_stu_arr={set_select_stu_arr}
                list={list}
              ></SelectListArea>
            </div>
        </div>
      </div>
      <div ref={SelectStuJustViewRef} style={{display:"none"}}>
        <table>
          <tbody>
            <tr>
              <th>선택학생</th>
              <td>
                {select_stu_arr.map((item:any,idx:number)=>{
                  let stu_name_div_style={
                    display:"inline-block",
                    padding:"0 15px",
                    lineHeight:"30px",
                    fontSize:"15px",
                    color:"#fff",
                    background:"#379D89",
                    marginLeft:15
                  };
                  return (
                    <div key={idx} style={stu_name_div_style}>
                      {item.a_user_name}
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default FindStudentArea;