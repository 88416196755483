import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import SearchArea from './area/search/search_area';
import StudentList from "./area/stu_list";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const StudentListRef=useRef<any>(null);
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      s_addon_class_user:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  let is_under_width=MyScreenFunc.get_is_under();

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="클래스등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="클래스수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_manage"){
      render_str=(
        <div>
          <button className="btn btn-line-gray" onClick={()=>{setTimeout(()=>{openOnePopup("write",true);},100);}}>수정</button>
          <button className="btn btn-line-gray ml-2" onClick={(e:any)=>{setTimeout(()=>{goDelete(e);},100);}}>삭제</button>
        </div>
      );
    }else if(key=="row_view_teacher"){
      if(params.data.teacher_arr){
        let teacher_name_arr=[];
        for(let i=0;i<params.data.teacher_arr.length;i++){
          teacher_name_arr.push(params.data.teacher_arr[i]["a_user_name"]);
        }
        render_str=teacher_name_arr.join(",");
      }
    }else if(key=="row_view_stu_cnt"){
      if(params.data.student_arr){
        render_str=params.data.student_arr.length+" 명";
      }
    }else if(key=="row_view_period"){
      render_str=params.data.a_start_date+"~";
    }else if(key=="row_view_day"){
      let dayOfWeekArr=NumTodayOfWeekArr(params.data.a_day_of_week.split(","));
      let dayOfWeekSpans=dayOfWeekArr.map((item,idx)=>{
        let active_day_class="text-zinc-300";
        if(item.is_check){
          active_day_class="text-green-500 font-bold";
        }
        return (
          <span key={idx} className={"ml-1 "+active_day_class} >{item.text}</span>
        );
      });
      render_str=dayOfWeekSpans;
    }else if(key=="row_view_memo"){
      render_str=strFunc.cut_str(params.data.a_memo,20,"..");
    }
    return render_str;
  }, []);

  const NumTodayOfWeekArr=(numArr:string[])=>{
    let day_of_week_arr=[
      {"value":"0","text":"일"},
      {"value":"1","text":"월"},
      {"value":"2","text":"화"},
      {"value":"3","text":"수"},
      {"value":"4","text":"목"},
      {"value":"5","text":"금"},
      {"value":"6","text":"토"},
    ];

    let result_str_arr=[];
    for(let d_i=0;d_i<day_of_week_arr.length;d_i++){
      let item=day_of_week_arr[d_i];
      let is_check_day=false;
      for(let i=0;i<numArr.length;i++){
        if(numArr[i]==item["value"]){
          is_check_day=true;
        }
      }
      result_str_arr.push({
        "text":item["text"],
        "is_check":is_check_day
      });
    }

    return result_str_arr;
  }

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    let select_seq_arr=[];
    if(selected_row){
      for(let i=0;i<selected_row.length;i++){
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    if(StudentListRef.current){
      StudentListRef.current.list({
        "s_class_seq":select_seq_arr,
      });
    }
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea 
          listOpt={{...pageData.listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
        ></SearchArea>
        <div className="basic_list_title_bar_wrap">
          {is_under_width==false&&
          <span>
            총 <span className="text-blue-600">{infoArr.length}</span> 개
          </span>
          }
          <span className="ml-3">
            <SearchOrderRadio
              valueTextArr={[
                {"text":"최근등록순","value":"a_create_date DESC"},
                {"text":"이름순","value":"a_name"},
              ]}
              value={pageData.listOpt.order_id}
              handleInputChange={(e:any)=>{
                list({
                  "now_page":1,
                  "order_id":e.target.value
                });
              }}
            ></SearchOrderRadio>
          </span>
          <div className="basic_title_bar_right_box">
            <button className="btn-m btn-sky" onClick={()=>{openOnePopup("write",false);}}>클래스등록</button>
            <button className="btn-m btn-sky ml-1" onClick={()=>{
              if(StudentListRef.current){
                StudentListRef.current.openFIndStudentPopup();
              }
            }}>학생초대</button>
            <button className="btn-m btn-red ml-1" onClick={()=>{
                if(StudentListRef.current){
                StudentListRef.current.removeClassUserData();
              }
            }}>학생제외</button>
          </div>
        </div>
        <div className="list_round_box">
          <div className="flex flex-row gap-x-2">
            <div style={{minWidth:200}} className="grow">
              <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{...xColumnArr}}
              list={list}
              gridOpt={{
                fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num:false,
                is_add_checkbox:true,
                floatingFilter:false,
                onGridReady:()=>{
                  list({});
                },
                onCellClicked:cellClickedListener,
                cellRenderer:cellRenderer,
                onSelectionChanged:onSelectionChanged
              }}
              ></ListAggrid>
            </div>
            <div style={{minWidth:410}}>
              <StudentList
                ref={StudentListRef}
                list={(inOptObj:any)=>{list(inOptObj);}}
              ></StudentList>
            </div>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;