import { useState, useRef, useEffect, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudent from "@/pages/comp/basic/student/popup/find_multy";

const StudentList = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["student"],
      "s_addon_class_user":"1",
      "s_stu_state":"",
      "s_class_seq":"",
      "is_need_count":"",
      "is_no_limit":"1",
    },
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState<any>({"tot":0});

  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    isUpdate:false,
    s_except_user_seq:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
    getClassFrontArr();
  }, []);


  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list,setSelectFalseAll,openFIndStudentPopup,removeClassUserData
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    let tmpPageData={...pageData};
    tmpPageData.listOpt[name]=value;
    setPageData({
      ...tmpPageData
    });
    if(name=="s_search_text"){
      return false;
    }
    list({[name]: value});
  };

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    if(strFunc.is_empty(pageData.listOpt.s_class_seq)){
      setInfoArr([]);
      //setCountInfo({"tot":"0"});
      listAggridRef.current.setInfoArr([]);
      return false;
    }
    let tmp_listOpt=pageData.listOpt;
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          response.data["data"]["info_arr"][i]["idx_num"]=i+1;
        }
        setInfoArr(response.data["data"]["info_arr"]);
        //setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  const setSelectFalseAll=()=>{
    listAggridRef.current.setSelectFalseAll();
    myProps.list({
      "s_stu_seq":"",
      "s_stu_name":"",
    });
  };

  const openFIndStudentPopup=()=>{
    if(strFunc.is_empty(pageData.listOpt.s_class_seq)){
      alert("클래스 선택이 없습니다.");
      return false;
    }
    if(pageData.listOpt.s_class_seq.length==0||pageData.listOpt.s_class_seq.length>=2){
      alert("클래스 하나만 선택해 주세요.");
      return false;
    }
    let s_except_user_seq=[];
    let all_stu_rows=listAggridRef.current.getRows();
    for(let i=0;i<all_stu_rows.length;i++){
      s_except_user_seq.push(all_stu_rows[i]["a_seq"]);
    }
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find",//view
      s_except_user_seq:s_except_user_seq,
      title:"초대 학생 찾기",
    });
  };

  const addClassStudentData=(inData:any)=>{
    let optObj={
      info_arr:[],
      row_num:0,
      key:"",
      ...inData
    };

    if(strFunc.is_empty(pageData.listOpt.s_class_seq)){
      alert("클래스 선택이 없습니다.");
      return false;
    }
    if(pageData.listOpt.s_class_seq.length==0||pageData.listOpt.s_class_seq.length>=2){
      alert("클래스 하나만 선택해 주세요.");
      return false;
    }
    if(optObj["info_arr"].length==0){
      alert("학생 선택이 없습니다.");
      return false;
    }
    if(!confirm(optObj["info_arr"].length+"명을 초대 하시겠습니까?")){
      return false;
    }
    let class_seq=pageData.listOpt.s_class_seq[0];
    let add_classu_arr:any=[];
    for(let i=0;i<optObj["info_arr"].length;i++){
      let u_info=optObj["info_arr"][i];
      add_classu_arr.push({
        "a_class_seq":class_seq,
        "a_user_seq":u_info["a_seq"],
        "a_user_name":u_info["a_user_name"],
        "a_type":"student",
        "a_is_main":"",
      });
    }
    let classu_w_form={
      "data_arr":add_classu_arr,
      "is_default_val":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/classu/write',classu_w_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({});
        list({});
      }else{
        
      }
    });

  };

  const removeClassUserData=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(pageData.listOpt.s_class_seq)){
      alert("클래스 선택이 없습니다.");
      return false;
    }
    if(pageData.listOpt.s_class_seq.length==0||pageData.listOpt.s_class_seq.length>=2){
      alert("클래스 하나만 선택해 주세요.");
      return false;
    }
    if(!confirm("해당 클래스에서 제외 하시겠습니까?")){
      return false;
    }
    let class_seq=pageData.listOpt.s_class_seq[0];
    let remove_classu_arr=[];
    for(let i=0;i<selected_row.length;i++){
      let u_info=selected_row[i];
      if(u_info.class_arr){
        for(let j=0;j<u_info.class_arr.length;j++){
          let classu_info=u_info.class_arr[j];
          if(classu_info["a_class_seq"]==class_seq){
            remove_classu_arr.push(classu_info);
          }
        }
      }
    }
    if(remove_classu_arr.length==0){
      alert("제거 할 클래스연결이 없습니다.");
      return false;
    }
    let classu_d_form={
      "data_arr":remove_classu_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/classu/delete',classu_d_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({});
        list({});
      }else{
        
      }
    });
  };

  const cellClickedListener = (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      
    }

    myProps.list({
      "s_stu_seq":params.data.a_seq,
      "s_stu_name":params.data.a_user_name,
    });
  }

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.data.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.data.class_arr.length;i++){
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_manage"){
      render_str=(
        <div>
          <button className="btn btn-red ml-1" 
            style={{fontSize:20,paddingTop:0}}
            onClick={()=>{setTimeout(()=>{removeClassUserData();},100);}}>-</button>
        </div>
      );
    }
    return render_str;
  }, []);

  return (
    <div>
        <div className="relative" style={{minHeight:25}}>
          <SearchArea
            xColumnArr={xColumnArr}
            listOpt={pageData.listOpt}
            list={list}
          ></SearchArea>
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:true,
          floatingFilter:false,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>

      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
        {popupData.sort==="find"&&
            <FindStudent isUpdate={popupData.isUpdate} 
              callBackData={(inData:any)=>{addClassStudentData(inData);}} 
              listOpt={{
                s_except_user_seq:popupData.s_except_user_seq
              }}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindStudent>
          }

      </LayerPopup>
      }
    </div>
  );
});

export default StudentList;