function ListHistoryArea(props:any){
   let myProps={
    info:{},
    set_info:(inData:any)=>{},
    info_arr:[],
    ...props
  };
  let info=myProps.info;
  
  const get_info_tr_tags=()=>{
    let info_tr_tags=myProps.info_arr.map((item:any,idx:number)=>{
      let tr_style:any={};
      let is_select=false;
      if(item["a_ymd"]==info["a_ymd"]&&item["a_seq"]==info["a_seq"]){
        is_select=true;
        tr_style["background"]="green";
        tr_style["color"]="#fff";
      }
      return (
        <tr key={idx} style={tr_style} onClick={()=>{myProps.set_info(item);}}>
          <td className="text-center">{idx+1}</td>
          <td className="text-center">
            {item["a_counsel_date"]}
          </td>
          <td className="text-center">
            {item["a_writer"]}
          </td>
          <td className="text-center">
            {item["a_create_date"]}
          </td>
          <td className="text-center">
            {item["a_update_date"]}
          </td>
        </tr>
      );
    });

    return info_tr_tags;
  };

  return (
  <div className="write_table_small">
    <table>
      <colgroup>
        <col width={"3%"}></col>
        <col width={"10%"}></col>
        <col width={"10%"}></col>
        <col width={"10%"}></col>
        <col width={"10%"}></col>
      </colgroup>
      <thead>
        <tr>
          <th>No</th>
          <th>상담일</th>
          <th>상담자</th>
          <th>작성일</th>
          <th>수정일</th>
        </tr>
      </thead>
      <tbody>
        {get_info_tr_tags()}
      </tbody>
    </table>
  </div>
  );
};
export default ListHistoryArea;