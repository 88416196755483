import strFunc from "@/lib/lyg/string";

class ListFunc
{
  static get_list_info_arr(inData:any){
    let opt_obj={
      info_arr:[],
      ...inData
    };
    let info_arr=opt_obj.info_arr;
    let info_arr_len=info_arr.length;
    for(let i=0;i<info_arr_len;i++){
      let info=info_arr[i];
      info["row_view_school_grade"]=strFunc.cut_str(info["a_school_name"],"3","")+" "+info["a_stu_grade"];
      let prescribe={
        success:0,
        tot:0,
        math_success:0,
        math_tot:0,
        en_success:0,
        en_tot:0,
      };
      info["row_view_month_prescribe"]="";
      info["row_view_month_prescribe_math"]="";
      info["row_view_month_prescribe_en"]="";
      if(info.month_prescribe_arr&&info.month_prescribe_arr.length>0){
        for(let j=0;j<info.month_prescribe_arr.length;j++){
          let prescribe_info=info.month_prescribe_arr[j];
          prescribe.tot++;
          if(prescribe_info.a_is_success=="1"){
            prescribe.success++;
          }
          if(prescribe_info.a_subject_name=="수학"){
            prescribe.math_tot++;
            if(prescribe_info.a_is_success=="1"){
              prescribe.math_success++;
            }
          }
          if(prescribe_info.a_subject_name=="영어"){
            prescribe.en_tot++;
            if(prescribe_info.a_is_success=="1"){
              prescribe.en_success++;
            }
          }
        }
      }
      info["row_view_month_prescribe"]=prescribe.success+"/"+prescribe.tot;
      info["row_view_month_prescribe_math"]=prescribe.math_success+"/"+prescribe.math_tot;
      info["row_view_month_prescribe_en"]=prescribe.en_success+"/"+prescribe.en_tot;
    }

    return info_arr;
  };
}
export default ListFunc;