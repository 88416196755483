import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useEffect } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector } from 'react-redux';
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewStuLayerPopup from "../popup/view_layer";
import SearchArea from "./area/search";
import ListCardArea from "./area/list_card";
import WritePage from "@/pages/comp/basic/student/common/write";

function StudentNewListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  let origin_order_id = "a_stu_state, a_join_date DESC";
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      order_id: origin_order_id,
      s_grade: ["student"],
      s_stu_state: "now,ready",
      s_date_type: "a_join_date",
      s_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
      s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_class_user: "1",
      s_addon_link_user: "1",
      s_addon_profile: "1",
      s_addon_invite_code: "1",
      //s_addon_comp_link: "1",
      // s_addon_recent_counsel: "1",
      s_addon_add_memo: "1",
      s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [select_idx, set_select_idx] = useState(0);
  const [is_detail_view, set_is_detail_view] = useState(false);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "stu_view",
    has_header: true,
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
    },
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let tmpPageData = pageData;
    if (inOptObj != undefined) {
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const show_detail_view = (stu_info:any) => {
    let info_idx=0;
    if(strFunc.is_empty(stu_info["a_seq"])){
      alert("선택학생이 없습니다.");
      return false;
    }
    for(let i=0;i<infoArr.length;i++){
      if(infoArr[i]["a_seq"]==stu_info["a_seq"]){
        info_idx=i;
      }
    }

    // set_is_detail_view(true);
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: info_idx,
        info_arr: infoArr,
        default_write_info: {},
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });
  };
  const close_detail_view = () => {
    list({});
    set_is_detail_view(false);
  };

  return (
    <CompLayout isConTitle={false}>
      {is_detail_view == false &&
        <div className="con_wrap" >
          <SearchArea
            listOpt={{ ...pageData.listOpt }}
            xColumnArr={{ ...xColumnArr }}
            list={list}
          ></SearchArea>

          <div className="basic_list_title_bar_wrap">
            <span>
              총 <span className="text-blue-600">{countInfo.tot}</span> 명
            </span>
            <span className="ml-1">
              <SearchOrderRadio
                valueTextArr={[
                  { "text": "입학역순", "value": "a_join_date DESC" },
                  { "text": "최근등록", "value": "a_create_date DESC" },
                  { "text": "이름", "value": "a_user_name" },
                  { "text": "아이디", "value": "a_user_id" },
                  { "text": "출결번호", "value": "user_stu_num" },
                ]}
                value={pageData.listOpt.order_id}
                handleInputChange={(e: any) => {
                  list({
                    "now_page": 1,
                    "order_id": e.target.value
                  });
                }}
              ></SearchOrderRadio>
            </span>
            <div className="basic_title_bar_right_box">
              {/* <button className="btn-m btn-sky ml-1" onClick={() => { }}>보기</button> */}
            </div>
          </div>
          <div className="list_round_box">
            <ListCardArea
              info_arr={infoArr}
              xColumnArr={xColumnArr}
              show_detail_view={show_detail_view}
              list={list}
            ></ListCardArea>
            <div className="mt-2">
              <Paging now_page={pageData.listOpt.now_page}
                num_per_page={pageData.listOpt.num_per_page}
                total_rec={countInfo.tot}
                onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
                is_view_num_per_page={true}
                onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
            </div>
          </div>
        </div>
      }
      {is_detail_view &&
        <WritePage
          info_arr={infoArr}
          select_idx={select_idx}
          close_write={close_detail_view}
          list={list}
          is_update={true}
          is_view_stu_info={true}
          is_view_parent={false}
          is_view_supply={true}
        ></WritePage>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
};
export default StudentNewListPage;