import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    goDelete:(inData: any) => { },
    ...props
  };

  const [corse_list_opt, set_corse_list_opt] = useState({
    "s_category_seq":"",
    "s_subject":"",
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
  });
  const [catagroy_info_arr,set_category_info_arr]=useState([]);
  const [corse_info_arr,set_corse_info_arr]=useState([]);
  let select_subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});
  useEffect(() => {
    get_category_by_ajax();
    get_corse_by_ajax({});
  }, []);
  const get_category_by_ajax=()=>{
    let form_json_data={
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_category_info_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  const get_corse_by_ajax=(inData:any)=>{
    let form_json_data={
      ...corse_list_opt,
      ...inData,
    };
    set_corse_list_opt(form_json_data);
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_corse_info_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  return (
    <div className="btn-box-left relative h-8">
      <select className="search-input" name="s_subject" value={corse_list_opt.s_subject} 
        onChange={(e:any)=>{
          get_corse_by_ajax({s_subject:e.target.value});
        }}>
        <option value="">과목</option>
        {select_subject_option_arr.map((item:any,idx:number)=>(
          <option key={idx} value={item.value}>{item.text}</option>
        ))}
      </select>
      <select className="search-input" name="s_corse_seq" value={myProps.listOpt.s_corse_seq}
        onChange={(e:any)=>{
          myProps.list({now_page:1,s_corse_seq:e.target.value});
        }}
        style={{width:100}}>
        <option value="">코스전체</option>
        {corse_info_arr.map((item:any)=>(
          <option key={item.a_seq} value={item.a_seq}>{item.a_title}</option>
        ))}
      </select>
      <select className="search-input" name="s_is_agree" value={myProps.listOpt.s_is_agree}
        onChange={(e:any)=>{
          myProps.list({now_page:1,s_is_agree:e.target.value});
        }}>
        <option value="">승인여부</option>
        <option value="empty" >미승인</option>
        <option value="1" >승인</option>
      </select>
      <input type="text" className="search-input" name="s_writer_like" value={myProps.listOpt.s_writer_like}
        onChange={(e:any)=>{
          myProps.list({now_page:1,s_writer_like:e.target.value});
        }}
        placeholder="학생명" 
        style={{width:70}}/>
      <button className="btn btn-dark" onClick={()=>{myProps.list({});}}>검색</button>
      
      <div className="absolute right-0 top-0 btn-box-right">
        <button className="btn btn-red" onClick={myProps.goDelete}>삭제</button>
      </div>
    </div>
  );
};
export default SearchArea;