import CompLayout from "@/pcomponents/comp/layout/layout";
import Style from "./css/style.module.css";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteRoomComponent from "./area/write";

function RoomList() {
  const xColumnArr = getXcolumnJson();
  const writeRoomComponentRef = useRef<any>(null);
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_is_use: "1",
      s_addon_desk: "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [isShowRight, setIsShowRight] = useState(true);

  useEffect(() => {
    
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/delete', del_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const goWriteFrame = (isUpdate: boolean) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let row_data = {
      "a_seq": "",
      "a_name": "",
      "a_size_x": 5,
      "a_size_y": 5,
      "a_location": "",
      "a_memo": "",
    };
    if (isUpdate == false) {
      listAggridRef.current.setSelectFalseAll();
      selected_row = [];
    } else {
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      } else if (selected_row.length != 1) {
        alert("한개만 선택해 주세요.");
        return false;
      }
      row_data = selected_row[0];
    }
    if (writeRoomComponentRef.current == null) {
      return false;
    }
    writeRoomComponentRef.current.setRowDataChild(row_data, isUpdate);
  };

  const moveOrderNum = (row_data: any, up_down: string) => {
    let row_num = 0;
    let tmp_section_arr: any = listAggridRef.current.getRows();
    let info_arr_len = tmp_section_arr.length;

    for (let i = 0; i < info_arr_len; i++) {
      if (row_data.a_seq == tmp_section_arr[i]["a_seq"]) {
        row_num = i;
      }
    }

    let next_row_num = row_num;
    if (up_down == "up") {
      next_row_num = row_num - 1;
    } else if (up_down == "down") {
      next_row_num = row_num + 1;
    }

    if (next_row_num < 0) {
      return false;
    }
    if (next_row_num >= tmp_section_arr.length) {
      return false;
    }

    let now_row_data = tmp_section_arr[row_num];
    let change_row_data = tmp_section_arr[next_row_num];

    tmp_section_arr[row_num] = change_row_data;
    tmp_section_arr[next_row_num] = now_row_data;
    let up_order_num_arr = [];
    for (let i = 0; i < info_arr_len; i++) {
      up_order_num_arr.push({
        "a_seq": tmp_section_arr[i]["a_seq"],
        "a_order_num": i + 1,
      });
    }
    let up_form_json_data = {
      "is_update": "1",
      "data_arr": up_order_num_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/write', up_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    goWriteFrame(true);
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_xy") {
      render_str = params.data.a_size_x + "," + params.data.a_size_y;
    } else if (key == "row_view_order") {
      render_str = (
        <div>
          <button className="btn-s btn-dark" onClick={() => { moveOrderNum(params.data, "up"); }} >▲</button>
          <button className="btn-s btn-dark ml-1" onClick={() => { moveOrderNum(params.data, "down"); }} >▼</button>
        </div>
      );
    }

    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className={"con_wrap " + Style.room_wrap}>
        <div className={Style.room_left_wrap} style={{ position: "relative" }}>
          <div className="btn-box-left relative h-8" style={{ lineHeight: "22px" }}>
            사용여부:
            <select className="search-input_select"
              value={pageData.listOpt.s_is_use}
              onChange={(e: any) => { list({ "now_page": "1", "s_is_use": e.target.value }) }} >
              <option value="" >전체</option>
              <option value="1" >사용</option>
              <option value="empty" >미사용</option>
            </select>
            <button className="btn btn-dark ml-2" onClick={() => { goWriteFrame(false); }}>신규등록</button>
            <button className="btn btn-dark" onClick={goDelete}>삭제</button>
            <button className="btn btn-dark"
              onClick={() => { setIsShowRight(!isShowRight); }}
            >
              {isShowRight ? "-" : "+"}
            </button>
          </div>
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{ ...xColumnArr }}
            list={list}
            gridOpt={{
              fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num: false,
              is_add_checkbox: false,
              floatingFilter: true,
              onGridReady: () => {
                list({});
              },
              onCellClicked: cellClickedListener,
              cellRenderer: cellRenderer
            }}
          ></ListAggrid>
          <Paging now_page={pageData.listOpt.now_page}
            num_per_page={pageData.listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        </div>
        {isShowRight &&
          <div className={Style.room_center_wrap}>
            <WriteRoomComponent
              ref={writeRoomComponentRef}
              list={list}
            ></WriteRoomComponent>
          </div>
        }
      </div>
    </CompLayout>
  );
}

export default RoomList;