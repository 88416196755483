import TopInfoArea from "./top_info";
import MidTodoArea from  "./mid_todo";
import CardBoxAttendArea from "./attend";
import strFunc from '@/lib/lyg/string';

function CardBox(props:any){
  let myProps={
    info:{},
    Style:{},
    check_row_val_arr:[],
    set_check_row_val_arr:(inData:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    base_date_str:"",
    pre_date_str:"",
    xColumnArr:{},
    deleteAttend:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    openTodoWriteOnePopup:(inData:any)=>{},
    show_detail_user_by_seq:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let base_date_str=myProps.base_date_str;
  let pre_date_str=myProps.pre_date_str;

  let attend_in_info=null;
  if(info.attend_in_arr&&info.attend_in_arr.length>0){
    attend_in_info=info.attend_in_arr[0];
  }
  let attend_out_info=null;
  if(info.attend_out_arr&&info.attend_out_arr.length>0){
    attend_out_info=info.attend_out_arr[0];
  }
  let attend_color="#707070";
  let attend_color_arr=[
    {"value":"attend","text":"등원",color:"#53a500"},
    {"value":"late","text":"등원(지각)",color:"#eabf0b"},
    {"value":"absent","text":"결석",color:"#f26b6f"},
    {"value":"out","text":"하원",color:"#7461ff"},
  ];
  if(attend_in_info!=null){
    for(let i=0;i<attend_color_arr.length;i++){
      if(attend_in_info["a_sort_detail"]==attend_color_arr[i]["value"]){
        attend_color=attend_color_arr[i]["color"];
      }
    }
  }
  if(attend_out_info!=null){
    for(let i=0;i<attend_color_arr.length;i++){
      if(attend_out_info["a_sort_detail"]==attend_color_arr[i]["value"]){
        attend_color=attend_color_arr[i]["color"];
      }
    }
  }

  const on_change_check_box=(e:any)=>{
    if(strFunc.is_empty(info["a_seq"])){
      return false;
    }
    if(e.target.checked){
      //추가
      let tmp_check_row_val_arr=myProps.check_row_val_arr;
      if(strFunc.str_in_array(info["a_seq"],myProps.check_row_val_arr)==-1){
        tmp_check_row_val_arr.push(info["a_seq"]);
        myProps.set_check_row_val_arr(tmp_check_row_val_arr);
      }
    }else{
      //제거
      let tmp_check_row_val_arr=[];
      for(let i=0;i<myProps.check_row_val_arr.length;i++){
        if(myProps.check_row_val_arr[i]==info["a_seq"]){

        }else{
          tmp_check_row_val_arr.push(myProps.check_row_val_arr[i]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
    return false;
  };

  let td_sort_data_json=info.td_sort_data_json;
  let td_sort_data_arr:any=[];
  for(let key in td_sort_data_json){
    if(key!="homework"){
      td_sort_data_arr.push(td_sort_data_json[key]);
    }
  }
  
  let is_checked=false;
  if(strFunc.str_in_array(myProps.info["a_seq"],myProps.check_row_val_arr)!=-1){
    is_checked=true;
  }

  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_back}></div>
      <div className={Style.card_box_back_white}></div>
      <input type="checkbox" className={Style.card_box_check_box} checked={is_checked} value=""
        onChange={on_change_check_box} />
      <div className={Style.card_box_con}>
        <TopInfoArea
          info={myProps.info}
          Style={myProps.Style}
          td_sort_data_arr={td_sort_data_arr}
          openTodoDaySort={myProps.openTodoDaySort}
          show_detail_user_by_seq={myProps.show_detail_user_by_seq}
        ></TopInfoArea>
        <MidTodoArea
          info={myProps.info}
          Style={myProps.Style}
          td_sort_data_arr={td_sort_data_arr}
          base_date_str={base_date_str}
          pre_date_str={pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
        ></MidTodoArea>
        <CardBoxAttendArea 
          user_info={info}
          attend_in_info={attend_in_info}
          attend_out_info={attend_out_info}
          attend_color={attend_color}
          xColumnArr={myProps.xColumnArr}
          deleteAttend={myProps.deleteAttend}
          openAttendPopup={myProps.openAttendPopup}
          Style={myProps.Style}></CardBoxAttendArea>
      </div>
    </div>
  );
}
export default CardBox;