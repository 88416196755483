
const Footer=(props:any)=>{

  return (
    <div id="stu_footer">
      <div className="footer_con">
        
      </div>
    </div>
  );
};

export default Footer;