import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"user_stu_write_multy",
    x_column_list_arr:[
      {"key":"row_view_state","name":"상태","width":"80","is_show":"1"},
      //{"key":"a_user_id","name":"아이디","width":"120","is_show":"1"},
      {"key":"a_user_name","name":"이름","width":"110","is_show":"1"},
      // {"key":"a_user_pw","name":"암호","width":"110","is_show":"1"},
      {"key":"a_gender","name":"성별","width":"110","is_show":"1"},
      {"key":"a_stu_num","name":"출결번호","width":"100","is_show":"1"},
      {"key":"a_school_name","name":"학교","width":"110","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"110","is_show":"1"},
      {"key":"row_view_schoolclass","name":"반","width":"110","is_show":"1"},
      // {"key":"a_user_grade","name":"회원등급","width":"100","is_show":"1"},
      {"key":"a_user_phone","name":"원생연락처","width":"120","is_show":"1"},
      {"key":"a_user_tel","name":"집전화","width":"120","is_show":"1"},

      {"key":"par_phone","name":"보호자연락처","width":"120","is_show":"1"},
      {"key":"par_relation","name":"보호자구분","width":"120","is_show":"1"},
      {"key":"par_name","name":"보호자이름","width":"120","is_show":"1"},
      {"key":"par_is_attend_push","name":"보호자출결알림","width":"120","is_show":"1"},

      {"key":"a_cash_receipt_num","name":"현금영수증발급번호","width":"110","is_show":"1"},
      {"key":"a_cash_receipt_sort","name":"현금영수증발급구분","width":"110","is_show":"1"},
      {"key":"a_user_nickname","name":"닉네임","width":"110","is_show":"1"},
      {"key":"a_stu_birth_date","name":"생일","width":"110","is_show":"1"},
      {"key":"a_join_date","name":"입학일","width":"110","is_show":"1"},
      {"key":"a_post_addr_post","name":"우편번호","width":"110","is_show":"1"},
      {"key":"a_post_addr","name":"주소1","width":"110","is_show":"1"},
      {"key":"a_post_addr_detail","name":"주소2","width":"110","is_show":"1"},
      {"key":"a_stu_motive","name":"입학동기","width":"110","is_show":"1"},
      {"key":"a_stu_state","name":"재원여부","width":"110","is_show":"1"},
      {"key":"a_stu_end_date","name":"퇴원일","width":"110","is_show":"1"},
      {"key":"a_stu_end_memo","name":"퇴원사유","width":"110","is_show":"1"},

      {"key":"row_view_is_rest","name":"휴원여부","width":"110","is_show":"1"},
      {"key":"row_view_rest_memo","name":"휴원사유","width":"110","is_show":"1"},

      {"key":"a_stu_supply_day","name":"수납청구일","width":"110","is_show":"1"},
      {"key":"a_stu_supply_discount","name":"할인액","width":"110","is_show":"1"},
      // {"key":"row_view_discount_sort","name":"할인구분","width":"110","is_show":"1"},
      {"key":"a_school_course","name":"문과/이과","width":"110","is_show":"1"},
      {"key":"a_brother_name","name":"형제","width":"110","is_show":"1"},
      {"key":"a_makeedu_num","name":"원생고유번호","width":"110","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"210","is_show":"1"},
      // {"key":"row_view_end_year","name":"졸업연도","width":"110","is_show":"1"},
      {"key":"a_email","name":"원생이메일","width":"110","is_show":"1"},

      {"key":"a_group_name","name":"수업","width":"110","is_show":"1"},
      {"key":"row_view_main_teacher","name":"담임강사","width":"110","is_show":"1"},
      {"key":"row_view_stu_sort","name":"원생분류","width":"110","is_show":"1"},
      {"key":"a_create_date","name":"등록일","width":"120","is_show":"1"},
      {"key":"a_makeedu_video","name":"비디오동영상","width":"120","is_show":"1"},

      {"key":"par_user_id","name":"부모아이디","width":"120","is_show":"1"},
      {"key":"par_user_pw","name":"부모암호","width":"120","is_show":"1"},
      {"key":"par_is_attend_sms","name":"부모출석sms","width":"120","is_show":"1"},
      {"key":"par_is_attend_katalk","name":"부모출석카톡","width":"120","is_show":"1"},
      {"key":"a_is_login","name":"로그인허용","width":"120","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_user_name DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  initData.date_col_arr=["a_join_date","a_stu_end_date","a_stu_birth_date"];
  initData.password_col_arr=["a_user_pw"];
  initData.number_col_arr=["a_stu_supply_discount"];
  initData.tel_col_arr=["a_user_phone","par_phone"];
  
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr["par_relation"]=[
    {"value":"모","text":"모"},
    {"value":"부","text":"부"},
    {"value":"etc","text":"기타"},
  ];
  xColumnArr.select_arr["par_is_attend_sms"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];
  xColumnArr.select_arr["par_is_attend_push"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];
  xColumnArr.select_arr["par_is_attend_katalk"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;