import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function ContentArea4(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  return (
    <div className={Style.content_wrap}>
      <div className={Style.title_wrap}>
        <span className={Style.title_num}>4</span>
        <span className={Style.title_text}>추천도서</span>
      </div>
      <div>
        <table className={Style.goal_table} style={{ marginTop: 5 }}>
          <colgroup>
            <col width="100%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div className={Style.goal_box_sky} style={{minHeight:90,textAlign:"left"}}>
                  {is_view_mode ?
                    <div className="px-2">
                      <MultyLineTextView text={info.a_recommend_book}></MultyLineTextView>
                    </div>
                    :
                    <textarea
                      value={info.a_recommend_book}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_book", value: e.target.value }); }}
                      placeholder="추천도서"
                      style={{textAlign:"left"}}
                    ></textarea>
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ContentArea4;