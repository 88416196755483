import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
const SearchClassList=forwardRef((props:any, ref) => {
  const [listOpt,setListOpt] = useState(props.listOpt);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);

  useEffect(()=>{
    
    getClassFrontArr();
  },[]);

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr([
          {"text":"클래스 전체","value":""},
          ...response.data["data"]["class_arr"]
        ]);
      }else{
        
      }
    });
  };

  const onClickClassRow=(s_class_seq:string)=>{
    setListOpt({
      ...listOpt,
      ...{"s_class_seq": s_class_seq}
    });
    props.list({"s_class_seq": s_class_seq});
  };

  return (
    <div>
      <div className="list-table-div pt-1">
        <table>
          <colgroup>
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th>클래스별 출석부</th>
            </tr>
          </thead>
          <tbody>
            {
              classSelectArr.map((item:any,idx:number)=>{
                let td_syle:any={
                  paddingLeft:10,
                  fontSize:15
                };
                if(item.value==listOpt.s_class_seq){
                  td_syle["background"] = "gray";
                  td_syle["color"] = "#fff";
                }
                return (
                  <tr key={idx} onClick={()=>{onClickClassRow(item.value);}}>
                    <td style={td_syle}>
                      {item.text}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default SearchClassList;