import CompLayout from "@/pcomponents/comp/layout/layout";
import { useSelector, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import {hmenu,smenu,hmenu_master_arr,smenu_master_arr} from "@/pcomponents/comp/layout/menu/data/menu_arr";
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';

function SiteMap() {
  const navigate = useNavigate();
  let user=useSelector((state:any) => state.user);
  const movePage=(url:string)=>{
    navigate(url);
  };

  const get_sub_menu_tags=(key:string)=>{
    let sub_menu_arr=[];
    for(let i=0;i<smenu.length;i++){
      if(smenu[i].hmenu==key){
        if(smenu[i].isShow){
          sub_menu_arr.push(smenu[i]);
        }
      }
    }
    let sub_menu_tags=sub_menu_arr.map((item:any,idx:number)=>{
      if(user.user_grade!="master"){
        if(strFunc.str_in_array(item.key,smenu_master_arr)!=-1){
          return "";
        }
      }
      return (
        <div key={idx} className={Style.site_map_sub_item} 
          onClick={()=>{movePage(item.url);}}>
            {item.name}
        </div>
      );
    });
    return sub_menu_tags;
  };

  const get_head_menu_tags=()=>{
    let head_menu_tags=hmenu.map((item:any,idx:number)=>{
      if(user.user_grade!="master"){
        if(strFunc.str_in_array(item.key,hmenu_master_arr)!=-1){
          return "";
        }
      }
      let sub_menu_tags=get_sub_menu_tags(item.key);
      return (
        <td key={idx} className={Style.site_map_td}>
          <div className={Style.site_map_head} onClick={()=>{movePage(item.url);}}>
            {item.name}
          </div>
          <div className={Style.site_map_sub_wrap} >
            {sub_menu_tags}
          </div>
        </td>
      );
    });

    return head_menu_tags;
  };

  let split_width=100;
  if(hmenu.length>0){
    split_width=Math.floor(100/hmenu.length);
  }

  return (
    <CompLayout>
      <table className={Style.site_map_table}>
        <colgroup>
          {hmenu.map((item:any,idx:number)=>{
            return (
              <col key={idx} width={split_width+"%"}></col>
            );
          })}
        </colgroup>
        <tbody>
          <tr>
            {get_head_menu_tags()}
          </tr>
        </tbody>
      </table>
    </CompLayout>
  );
}

export default SiteMap;