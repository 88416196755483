import strFunc from "@/lib/lyg/string";

class TableOptionFunc {
  static get_table_opt_of_colspan_rowspan = (table_option: any) => {
    //같은칸에 몇개있는지 찾기
    let td_data_group_arr: any = {};//{"key":cnt}
    for (let td_i = 0; td_i < table_option.td_data_arr.length; td_i++) {
      let td_data = table_option.td_data_arr[td_i];

      //한칸key
      let tmp_td_data_key_arr = [
        td_data["lecture_seq"],
        td_data["range_time_str"],
        //td_data["time_str"],
      ];
      let td_data_key: any = tmp_td_data_key_arr.join(",");
      if (td_data_group_arr[td_data_key] == undefined) {
        td_data_group_arr[td_data_key] = 0;
      }
      td_data_group_arr[td_data_key]++;

      //시간이 1칸 이상이면 높이 합친다.
      //시작,끝 시간차이
      let start_sec = strFunc.timeToSeconds(td_data["start_h"], td_data["start_m"], 0);
      let end_sec = strFunc.timeToSeconds(td_data["end_h"], td_data["end_m"], 0);
      let term_sec = end_sec - start_sec;
      let term_min = 0;
      if (term_sec != 0) {
        term_min = Math.round(term_sec / 60);
      }
      let row_span = 1;
      if (term_min != 0 && table_option.split_term != 0) {
        row_span = Math.ceil(term_min / table_option.split_term);
      }
      table_option.td_data_arr[td_i]["row_span"] = row_span;
    }

    //같은칸에 여러개 있으면 x로 늘어난다.
    for (let td_i = 0; td_i < table_option.td_data_arr.length; td_i++) {
      let td_data = table_option.td_data_arr[td_i];
      //한칸key
      let tmp_td_data_key_arr = [
        td_data["lecture_seq"],
        td_data["range_time_str"],
        //td_data["time_str"],
      ];
      let td_data_key: any = tmp_td_data_key_arr.join(",");;
      if (td_data_group_arr[td_data_key]) {
        table_option.td_data_arr[td_i]["col_tot_cnt"] = td_data_group_arr[td_data_key];
      }
    }

    //강의별로 colspan 구하기
    let lecture_col_span_arr: any = {};
    for (let td_i = 0; td_i < table_option.td_data_arr.length; td_i++) {
      let td_data = table_option.td_data_arr[td_i];
      let td_data_key: any = td_data["lecture_seq"];
      if (lecture_col_span_arr[td_data_key] == undefined) {
        lecture_col_span_arr[td_data_key] = 1;
      }

      if (table_option.td_data_arr[td_i]["col_tot_cnt"]) {
        let col_tot_cnt = table_option.td_data_arr[td_i]["col_tot_cnt"];
        if (col_tot_cnt > lecture_col_span_arr[td_data_key]) {
          lecture_col_span_arr[td_data_key] = col_tot_cnt;
        }
      }
    }
    table_option["lecture_col_span_arr"] = lecture_col_span_arr;

    return table_option;
  };
  static get_split_time_arr_of_option = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      table_option: [],
      ...inData
    };
    let table_option = opt_obj.table_option;

    let split_time_m_arr = [];
    for (let ing_time = table_option.start_time_m; ing_time < table_option.end_time_m; ing_time) {
      split_time_m_arr.push(ing_time);
      ing_time = ing_time + table_option.split_term;
    }
    table_option.split_time_m_arr = split_time_m_arr;

    return table_option;
  };
  static get_table_opt_xy_by_opt = (table_option: any) => {
    //y
    let table_y_min = table_option.end_time_m - table_option.start_time_m;
    if (table_y_min != 0 && table_y_min > 0 && table_option.split_term != 0) {
      table_option.table_y_size = Math.ceil(table_y_min / table_option.split_term);
    }
    //x
    table_option.table_x_size = 0;
    for (let lec_i = 0; lec_i < table_option.lecture_arr.length; lec_i++) {
      let lecture_info = table_option.lecture_arr[lec_i];
      let col_tot_cnt = 1;
      if (table_option.lecture_col_span_arr[lecture_info["a_seq"]]) {
        col_tot_cnt = table_option.lecture_col_span_arr[lecture_info["a_seq"]];
      }
      table_option.table_x_size += col_tot_cnt;
    }

    return table_option;
  };
}
export default TableOptionFunc;