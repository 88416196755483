import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import strFunc from '@/lib/lyg/string';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import ExcelUploadArea from "@/pcomponents/common/excel/excel_upload_popup";
import LoadingComponent from "@/pcomponents/common/content/loading";

function WriteMultyMakeduPopup(props:any){
  const myProps={
    closePopup:()=>{},
    callback:()=>{},
    isUpdate:false,
    listOpt:{},
    row_num:0,
    key:"",
    callBackData:(data:any)=>{
      let optObj={
        info_arr:[],
        row_num:0,
        key:"",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const ListComponentRef= useRef<any>();
  const [is_loading,set_is_loading]=useState(false);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      ...myProps.listOpt
    }
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [isOpenExcel,setIsOpenExcel]=useState(false);
  const [removed_user_arr,set_removed_user_arr]=useState<any>([]);

  useEffect(() => {
    
  }, []);

  let default_row_data={
    "a_seq":"",
    "a_corp_seq":"1",
    "a_user_id":"",
    "a_user_pw":"1111",
    "a_user_grade":"student",
    "a_is_login":"1",
    "a_user_name":"",
    "a_user_phone":"",
    "a_user_tel":"",

    "a_stu_state":"now",
    "a_stu_num":"",
    "a_class_seq":"",
    "a_gender":"",
    "a_school_name":"",
    "a_stu_grade":"",
    "a_join_date":"",
    "a_stu_birth_date":"",
    "a_stu_supply_day":"",
    "a_stu_supply_discount":"0",
    "a_memo":"",

    "a_cash_receipt_num":"",
    "a_cash_receipt_sort":"",
    "a_user_nickname":"",
    "a_post_addr_post":"",
    "a_post_addr":"",
    "a_post_addr_detail":"",
    "a_stu_motive":"",
    "a_stu_end_date":"",
    "a_stu_end_memo":"",
    "a_school_course":"",
    "a_brother_name":"",
    "a_makeedu_num":"",
    "a_email":"",
    "a_group_name":"",
    "a_create_date":"",
    "a_makeedu_video":"",

    "par_relation":"모",
    "par_phone":"",
    "par_name":"",
    "par_user_id":"",
    "par_user_pw":"",
    "par_is_attend_sms":"",
    "par_is_attend_push":"1",
    "par_is_attend_katalk":"",
  };

  const addRowByData=(inRowData:any)=>{
    let rowData={
      ...default_row_data,
      ...inRowData
    };
    let info_arr=[...infoArr];
    if(ListComponentRef.current){
      info_arr=ListComponentRef.current.getRows();
      info_arr.push(rowData);
      info_arr=get_added_idx_num_of_info_arr(info_arr);
      ListComponentRef.current.setInfoArr(info_arr);
      setInfoArr(info_arr);
    }
  };

  const removeRowByData=()=>{
    if(ListComponentRef.current==null){
      return false;
    }
    let all_rows=ListComponentRef.current.getRows();
    let select_rows=ListComponentRef.current.getSelectedRows();
    let now_rows=[];
    for(let i=0;i<all_rows.length;i++){
      let is_select=false;
      for(let j=0;j<select_rows.length;j++){
        if(select_rows[j].idx_num==i){
          is_select=true;
        }
      }
      if(is_select==false){
        now_rows.push(all_rows[i]);
      }
    }
    now_rows=get_added_idx_num_of_info_arr(now_rows);
    ListComponentRef.current.setInfoArr(now_rows);
    setInfoArr(now_rows);
  };

  const get_added_idx_num_of_info_arr=(in_info_arr:any[])=>{
    for(let i=0;i<in_info_arr.length;i++){
      in_info_arr[i]["idx_num"]=i+1;
    }
    return in_info_arr;
  };

  const onExcelCallBack=(rows:any)=>{
    let add_row_arr=[];
    let video_key_arr=[];
    for(let i=0;i<23;i++){
      video_key_arr.push("수강과목"+(i+1));
    }
    
    for(let i=0;i<rows.length;i++){
      let makeedu_video_str_arr=[];
      for(let key in rows[i]){
        //비디오동영상정보얻기
        if(strFunc.str_in_array(key,video_key_arr)!=-1){
          if(rows[i][key]!=""){
            makeedu_video_str_arr.push(rows[i][key]);
          }
        }
        //학년
        if(key=="a_stu_grade"){
          if(xColumnArr.select_arr.a_stu_grade){
            for(let j=0;j<xColumnArr.select_arr.a_stu_grade.length;j++){
              let sel_opt=xColumnArr.select_arr.a_stu_grade[j];
              if(rows[i][key]==sel_opt["text"]){
                rows[i][key]=sel_opt["value"];
              }
            }
          }
        }
        //보호자출결알림
        if(key=="par_is_attend_push"){
          if(rows[i][key]=="O"){
            rows[i][key]="1";
          }else{
            rows[i][key]="";
          }
        }
        //날짜
        if(strFunc.str_in_array(key,["a_stu_birth_date","a_join_date","a_stu_end_date"])!=-1){
          rows[i][key]=strFunc.autoHypenDateStr(rows[i][key]);
        }
        if(key=="a_create_date"){
          rows[i][key]=rows[i][key].substring(0,19);
        }
        //재원여부
        if(key=="a_stu_state"){
          if(rows[i][key]=="O"){
            rows[i][key]="now";
          }else{
            rows[i][key]="ready";
          }
        }
        //남여 
        if(key=="a_gender"){
          if(rows[i][key]=="남"){
            rows[i][key]="m";
          }else if(rows[i][key]=="여"){
            rows[i][key]="f";
          }
        }
        if(key=="a_stu_num"){
          rows[i][key]=parseInt(strFunc.getNumber2(rows[i][key]));
        }
        
      }
      //rows[i]["a_makeedu_video"]=makeedu_video_str_arr.join(",");
      add_row_arr.push({
        ...default_row_data,
        ...rows[i],
      });
    }
    get_pre_user_check_makeedu_by_ajax(add_row_arr);
  };

  const get_pre_user_check_makeedu_by_ajax=(add_row_arr:any[])=>{
    let formJsonData={
      "s_stu_state":"now",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        //pre_info 넣기
        let pre_info_arr=response.data["data"]["info_arr"];
        for(let i=0;i<add_row_arr.length;i++){
          add_row_arr[i]["a_stu_num"]=strFunc.getNumber2(add_row_arr[i]["a_stu_num"]);
          add_row_arr[i]["a_user_name"]=strFunc.trim(add_row_arr[i]["a_user_name"]);
          let add_info=add_row_arr[i];
          for(let j=0;j<pre_info_arr.length;j++){
            pre_info_arr[j]["a_stu_num"]=strFunc.getNumber2(pre_info_arr[j]["a_stu_num"]);
            pre_info_arr[j]["a_user_name"]=strFunc.trim(pre_info_arr[j]["a_user_name"]);
            
            let is_same_phone=false;
            if(!strFunc.is_empty(add_info["a_user_phone"])&&!strFunc.is_empty(pre_info_arr[j]["a_user_phone"])){
              if(add_info["a_user_phone"]==pre_info_arr[j]["a_user_phone"]){
                is_same_phone=true;
              }
            }
            
            if((add_info["a_stu_num"]==pre_info_arr[j]["a_stu_num"]
              &&add_info["a_user_name"]==pre_info_arr[j]["a_user_name"])
              ||is_same_phone){
              add_row_arr[i]["pre_info"]=pre_info_arr[j];
              add_row_arr[i]["a_seq"]=pre_info_arr[j]["a_seq"];
              add_row_arr[i]["a_user_id"]=pre_info_arr[j]["a_user_id"];
              add_row_arr[i]["a_user_pw"]="";
            }
          }
        }
        //기존 없어진유저찾기
        let tmp_removed_user_arr=[];
        for(let j=0;j<pre_info_arr.length;j++){
          let pre_info=pre_info_arr[j];
          let is_exists_user=false;
          for(let i=0;i<add_row_arr.length;i++){
            let add_info=add_row_arr[i];
            if(pre_info["a_seq"]==add_info["a_seq"]){
              is_exists_user=true;
              continue;
            }
          }
          if(is_exists_user==false){
            tmp_removed_user_arr.push(pre_info);
          }
        }
        set_removed_user_arr(tmp_removed_user_arr);
        
        //add rows
        if(ListComponentRef.current){
          let list_rows=ListComponentRef.current.getRows();
          list_rows=[
            ...list_rows,
            ...add_row_arr
          ];
          list_rows=get_added_idx_num_of_info_arr(list_rows);
          ListComponentRef.current.setInfoArr(list_rows);
          setInfoArr(list_rows);
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const update_upload_state_data=(inData:any)=>{
    let opt_obj:any={
      info_arr:infoArr,
      ...inData
    };

    let tmp_upload_state_data={
      "new":0,
      "same":0,
      "update":0,
    };

    for(let i=0;i<opt_obj.info_arr.length;i++){
      let upload_state_data=get_upload_change_state_data(opt_obj.info_arr[i]);
      if(upload_state_data.state=="new"){
        tmp_upload_state_data["new"]++;
      }else if(upload_state_data.state=="same"){
        tmp_upload_state_data["same"]++;
      }else if(upload_state_data.state=="update"){
        tmp_upload_state_data["update"]++;
      }
    }

    return tmp_upload_state_data;
  };

  const onWriteMulty=()=>{
    let select_rows:any=[];
    if(ListComponentRef.current){
      select_rows=ListComponentRef.current.getRows();
    }
    if(select_rows.length==0){
      alert("데이터가 없습니다.");
      return false;
    }
    if(!confirm("저장 하시겠습니까?")){
      return false;
    }

    let formJsonData={
      "data_arr":select_rows,
      "is_default_val":"1",
      "is_update":"",
    };
    if(myProps.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    set_is_loading(true);
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write_student',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        goWriteParentUser(response.data["data"],select_rows,response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteParentUser=(user_arr:any,select_rows:any,success_msg:string)=>{
    //세팅
    let last_user_arr=[];
    for(let i=0;i<user_arr.length;i++){
      let u_info=user_arr[i];
      for(let j=0;j<select_rows.length;j++){
        let select_row=select_rows[j];
        //if(u_info["a_user_id"]==select_row["a_user_id"]){
        if(i==j){
          last_user_arr.push({
            ...select_row,
            ...u_info
          });
        }
      }
    }

    //얻기
    let parentArr:any=[];
    for(let i=0;i<last_user_arr.length;i++){
      let u_info=last_user_arr[i];

      if(u_info["par_phone"]==""||u_info["par_phone"].length<10){
        continue;
      }

      let par_name=u_info["par_name"];
      if(par_name==""){
        par_name=u_info["a_user_name"];
        if(u_info["par_relation"]=="모"){
          par_name+=" 어머니";
        }else if(u_info["par_relation"]=="부"){
          par_name+=" 아버지";
        }else if(u_info["par_relation"]=="etc"){
          par_name+=" 부모님";
        }else{
          par_name+=" "+u_info["par_relation"];
        }
      }

      parentArr.push({
        "a_stu_seq":u_info["a_seq"],
        "a_user_seq":"",
        "a_stu_name":u_info["a_user_name"],
        "a_user_name":par_name,
        "a_type":"parent",
        "a_relation":u_info["par_relation"],
        "a_order":0,
        "a_is_attend_sms":u_info["par_is_attend_sms"],
        "a_is_attend_push":u_info["par_is_attend_push"],
        "a_is_attend_katalk":u_info["par_is_attend_katalk"],
        "par_phone":u_info["par_phone"],
        "par_user_id":u_info["par_user_id"],
        "par_user_pw":u_info["par_user_pw"],
      });
    }

    if(parentArr.length==0){
      set_is_loading(false);
      alert(success_msg);
      myProps.callBackData({});
      myProps.closePopup();
      return false;
    }

    let formJsonData={
      parentArr:parentArr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write_parent_arr',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_is_loading(false);
        alert(success_msg);
        myProps.callBackData({});
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const get_upload_change_state_data=(rowData:any)=>{
    let state="new";
    let add_state_str="신규";
    let add_state_color="gray";
    let row_differ_num=0;
    let change_title_name_arr=[];
    if(rowData.pre_info){
      state="same";
      add_state_str="수정";
      add_state_color="green";
      for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
        let tmp_key=xColumnArr.x_column_list_arr[i]["key"];
        if(tmp_key.indexOf("row_view_")==-1
          &&tmp_key.indexOf("par_")==-1
          &&tmp_key!="a_user_pw"
          &&tmp_key.indexOf("a_")==0){
          if(rowData.pre_info[tmp_key]!=rowData[tmp_key]){
            row_differ_num++;
            change_title_name_arr.push(xColumnArr.x_column_list_arr[i]["name"]);
          }
        }
      }
      if(row_differ_num>0){
        state="update";
        add_state_str+="("+row_differ_num+")";
        add_state_color="#eacc2d";
      }
    }
    return {
      state:state,
      state_str:add_state_str,
      state_color:add_state_color,
      differ_num:row_differ_num,
      change_title_name_arr:change_title_name_arr
    };
  };

  const cellClickedListener = useCallback( (rowData:any,idx:number,e:any) => {
    
  }, []);

  const cellRenderer= useCallback( (inOpt:any) => {
    let opObj={
      "rowData":{},
      "idx":0,
      "key":"",
      "value":"",
      ...inOpt
    };

    return opObj.value;
  }, []);

  const gridCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
   let key=params.key;
   if(key=="row_view_state"){
      let row_upload_state_data=get_upload_change_state_data(params.rowData);
      let add_state_str=row_upload_state_data.state_str
      let add_state_color=row_upload_state_data.state_color;
      let change_title_name=row_upload_state_data.change_title_name_arr.join("\n");
      params.input_tag=(
        <span style={{background:add_state_color,color:"#fff",borderRadius:"5px",padding:"0 5px",lineHeight:"22px"}}
          title={change_title_name}>
          {add_state_str}
        </span>
      );
    }else{
      if(key.indexOf("row_view_")==-1
        &&key.indexOf("par_")==-1){
        if(params.rowData.pre_info){
          if(params.value!=params.rowData.pre_info[key]){
            params.input_tag=(
              <>
                {params.input_tag}
                <p style={{color:"#f55"}}>
                  기존값: {params.rowData.pre_info[key]}
                </p>
              </>
            );
          }
        }
      }
    }
    return params.input_tag;
  };

  let upload_state_data=update_upload_state_data({});

  return (
      <div>
        <div>
          ※메이크에듀에서 받은 원생엑셀파일을 업로드하시면 됩니다.
          (중복검사 기준: <span style={{color:"blue"}}>출결번호, 학생이름 또는 핸드폰번호</span>)
        </div>
        <div className="btn-box-left relative h-8">
          <b>총 <span className="text-blue-600">{infoArr.length}</span> 개</b>
          <span className="px-2">|</span>
          <button className="btn btn-blue" onClick={()=>{onWriteMulty();}}>저장</button>
          <button className="btn btn-line-gray" onClick={()=>{addRowByData({});}}>추가</button>
          <button className="btn btn-line-gray" onClick={()=>{removeRowByData();}}>제외</button>
          <button className="btn btn-line-gray ml-3" onClick={()=>{setIsOpenExcel(true);}}>엑셀업로드</button>
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
          </div>
        </div>
        {removed_user_arr.length>0&&
          <div>
            <p>※기존 학생 중 메이크 에듀 엑셀파일에 없는 학생 리스트</p>
            <div style={{display:"flex",gap:"5px",padding:10,border:"1px solid #aaa",flexWrap:"wrap"}}>
              {removed_user_arr.map((item:any,idx:number)=>{
                return (
                  <span key={idx} className="ml-2" style={{background:"yellow"}}>
                    {idx+1}. 
                    <span style={{color:"blue",fontWeight:"bold"}}>{item.a_user_name}</span>
                    <span style={{color:"#999"}}>{item.a_stu_num}</span> 
                  </span>
                );
              })}
            </div>
          </div>
        }
        <div>
          신규: {upload_state_data.new} 
          <span className="ml-2">동일: {upload_state_data.same}</span>
          <span className="ml-2">수정: {upload_state_data.update}</span>
        </div>
        <div className="mt-1">
          <ListComponent
            ref={ListComponentRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            gridOpt={{
              isGrid:true,
              is_add_idx_num:true,
              is_add_checkbox:true,
              is_auto_max_width:false,
              td_align:"left",//center,right
              table_align:"left",//center,right
              onGridReady:()=>{},
              rowOnClick:cellClickedListener,
              customCellRenderer:cellRenderer,
              customGridCellRenderer:gridCellRenderer,
            }}
          ></ListComponent>
        </div>
        {isOpenExcel&&
          <ExcelUploadArea
            onClose={()=>{setIsOpenExcel(false);}}
            onCallback={(inData:any)=>{onExcelCallBack(inData);}}
            xColumnArr={xColumnArr}
            is_origin_key_include={true}
          ></ExcelUploadArea>
        }
        {is_loading&&
          <LoadingComponent text={"작업중입니다.."} ></LoadingComponent>
        }
      </div>
  );
}

export default WriteMultyMakeduPopup;