import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";

const ClassList = forwardRef((props:any, ref) => {
  const [originClassUsers,setOriginClassUsers]=useState<any>([]);
  const [classUserList,setClassUserList]=useState<any>([]);

  useEffect(()=>{
    
    getClassList();
  },[originClassUsers]);

  const getClassList=()=>{
    let uListFormData={
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/list',uListFormData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassUserByClassList(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const setClassUserByClassList=(class_list:any)=>{
    let tmpClassUsers=[];
    
    for(let i=0;i<class_list.length;i++){
      let class_info=class_list[i];

      //저장데이터 있다면 체크
      let tmp_is_checked=false;
      for(let j=0;j<originClassUsers.length;j++){
        if(class_info["a_seq"]==originClassUsers[j].a_class_seq){
          tmp_is_checked=true;
        }
      }

      tmpClassUsers.push({
        a_class_seq:class_info["a_seq"],
        class_name:class_info["a_name"],
        a_user_seq:"",
        a_user_name:"",
        a_type:"student",
        a_is_main:"",
        is_checked:tmp_is_checked,
      });
    }
    setClassUserList(tmpClassUsers);
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let checked=target.checked;
    let  tmpClassUsers=[...classUserList]
    for(let i=0;i<tmpClassUsers.length;i++){
      let class_user=tmpClassUsers[i];
      if(class_user.a_class_seq==value){
        class_user.is_checked=checked;
      }
    }
    setClassUserList(tmpClassUsers);
  };

  const getClassUserList=()=>{
    return classUserList;
  };
  const getCheckedClassUserList=()=>{
    let tmpClassUsers=[];
    for(let i=0;i<classUserList.length;i++){
      if(classUserList[i].is_checked){
        tmpClassUsers.push(classUserList[i]);
      }
    }
    return tmpClassUsers;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getClassUserList,
    getCheckedClassUserList,
    setOriginClassUsers
  }));

  let  classUserTags=classUserList?.map((item:any,idx:number)=>{
    let is_checked=item.is_checked;
    return (
      <div key={idx} className="my-1">
        <label className="text-base" >
          <input type="checkbox" value={item.a_class_seq} name="a_class_seq" checked={is_checked} 
            onChange={handleInputChange} className="mr-2" />
          {item.class_name}
        </label>
      </div>
    );
  });

  return (
    <div className="p-2">
      {classUserTags}
    </div>
  );
});

export default ClassList;