import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import MyScreenFunc from "@/pcomponents/common/screen/func/screen_func";
import React from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';

const UserBaseTableArea=(props:any)=>{
  let myProps={
    handleInputChange:(inData:any)=>{},
    onChangeDatePicker:(inData:any)=>{},
    rowData:{},
    xColumnArr:{},
    check_id_msg:"",
    ...props,
  };
  let is_under=MyScreenFunc.get_is_under();
  let split_num=2;
  if(is_under){
    split_num=1;
  }
  let col_tag_num_arr=[];
  for(let i=0;i<split_num;i++){
    col_tag_num_arr.push(i);
  }
  let th_td_tags=[
    <>
      <th>
        이름
        <span className="emphasis_star">*</span>
      </th>
      <td>
        <input type="text" className="row-input" name="a_user_name" value={myProps.rowData.a_user_name} onChange={myProps.handleInputChange} placeholder="이름" />
      </td>
    </>,
    <>
      <th>
        아이디
        <span className="emphasis_star">*</span>
      </th>
      <td>
        <input type="text" className="row-input" name="a_user_id" value={myProps.rowData.a_user_id} onChange={myProps.handleInputChange} placeholder="아이디" 
          autoComplete="off"/>
        {myProps.check_id_msg.msg}
      </td>
    </>,
    <>
      <th>핸드폰</th>
      <td>
        <input type="text" className="row-input" name="a_user_phone" value={myProps.rowData.a_user_phone} onChange={myProps.handleInputChange} placeholder="000-0000-0000" />
      </td>
    </>,
    <>
      <th>
        비밀번호
        {props.isUpdate==false&&
        <span className="emphasis_star">*</span>
        }
      </th>
      <td>
        <input type="password" className="row-input" name="a_user_pw" value={myProps.rowData.a_user_pw} onChange={myProps.handleInputChange} placeholder="비밀번호" />
      </td>
    </>,
    <>
    <th>상태</th>
    <td>
      <RadioListArea
        valueTextArr={myProps.xColumnArr.select_arr.a_stu_state}
        value={myProps.rowData.a_stu_state}
        name="a_stu_state"
        handleInputChange={myProps.handleInputChange}
      ></RadioListArea>
    </td>
  </>,
  <>
    <th>로그인허용</th>
    <td>
      <RadioListArea
        valueTextArr={myProps.xColumnArr.select_arr.a_is_login}
        value={myProps.rowData.a_is_login}
        name="a_is_login"
        handleInputChange={myProps.handleInputChange}
      ></RadioListArea>
    </td>
  </>,
  <>
    <th>휴/퇴원일</th>
    <td>
      <DatePicker 
        selected={myProps.rowData.a_stu_end_date!=""?new Date(myProps.rowData.a_stu_end_date):null} 
        onChange={(date:Date) => {
          myProps.onChangeDatePicker("a_stu_end_date",date);
        }}
        locale={ko} 
        dateFormat="yyyy-MM-dd"
        customInput={<CustomInputWrite />}
        renderCustomHeader={customHeaderFunc}
      />
    </td>
  </>,
  <>
    <th>
      휴/퇴원메모
    </th>
    <td>
      <input type="text" className="row-input" name="a_stu_end_memo" value={myProps.rowData.a_stu_end_memo} onChange={myProps.handleInputChange} placeholder="메모" />
    </td>
  </>,
  ];
  const getTrTags=()=>{
    let tr_len=0;
    if(th_td_tags.length>0){
      tr_len=Math.round(th_td_tags.length/split_num);
    }
    let  tr_num_arr=[];
    for(let i=0;i<tr_len;i++){
      tr_num_arr.push(i);
    }
    let tr_tags=tr_num_arr.map((item:any,idx:number)=>{
      let start_th_td_num=idx*split_num;
      let max_th_td_num=start_th_td_num+split_num;
      let row_th_td_tags=th_td_tags.map((td_th:any,td_th_idx:number)=>{
        if(start_th_td_num<=td_th_idx&&td_th_idx<max_th_td_num){

        }else{
          return "";
        }
        return (
          <React.Fragment key={td_th_idx}>
            {td_th}
          </React.Fragment>
        );
      });
      
      return (
        <tr key={idx}>
          {row_th_td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <div className="text-right">
        <span className="emphasis_star mr-2">*</span>
        필수입력사항
      </div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            {col_tag_num_arr.map((item:any,idx:number)=>{
              return (
                <React.Fragment key={idx}>
                  <col width="110px"></col>
                  <col width="*"></col>
                </React.Fragment>
              );
            })}
          </colgroup>
          <tbody>
            {getTrTags()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UserBaseTableArea;