import strFunc from '@/lib/lyg/string';

class StuTimeBoxFunc {
  static get_is_same_days(inData: any) {
    let opt_obj = {
      time_info: {},
      same_days_week_arr: [],
      ...inData
    };
    let time_info = opt_obj.time_info;
    let same_days_week_arr = opt_obj.same_days_week_arr;
    let is_same_days = false;
    if (time_info["a_day_num"] && strFunc.str_in_array(time_info["a_day_num"], same_days_week_arr) != -1) {
      is_same_days = true;
    }
    return is_same_days;
  };
  static get_box_time_arr(inData: any) {
    let opt_obj = {
      time_info: {},
      timeInfoArr: [],
      same_days_week_arr: [],
      ...inData
    };
    let time_info = opt_obj.time_info;
    let same_days_week_arr = opt_obj.same_days_week_arr;
    let timeInfoArr = opt_obj.timeInfoArr;
    let box_time_arr = [];

    let is_same_days = this.get_is_same_days({
      time_info: time_info,
      same_days_week_arr: same_days_week_arr
    });

    if (is_same_days == false) {
      box_time_arr.push(time_info);
    } else {
      for (let i = 0; i < timeInfoArr.length; i++) {
        let row_time_info = timeInfoArr[i];
        if(row_time_info["a_seq"]==time_info["a_seq"]){
          if (row_time_info["a_day_num"] && strFunc.str_in_array(row_time_info["a_day_num"], same_days_week_arr) != -1) {
            box_time_arr.push(row_time_info);
          }
        }
      }
    }
    return box_time_arr;
  }
  static get_box_user_time_arr(inData: any) {
    let opt_obj = {
      box_time_arr: [],
      user_time_arr: [],
      ...inData
    };
    let box_time_arr = opt_obj.box_time_arr;
    let user_time_arr = opt_obj.user_time_arr;
    let box_user_time_arr: any = [];

    for (let time_i = 0; time_i < box_time_arr.length; time_i++) {
      let time_info = box_time_arr[time_i];
      for(let user_i=0;user_i<user_time_arr.length;user_i++){
        let user_time_info=user_time_arr[user_i];
        if(time_info["a_day_num"]==user_time_info["a_day_num"]
          &&time_info["a_seq"]==user_time_info["a_seq"]){
          box_user_time_arr.push(user_time_info);
        }
      }
    }

    return box_user_time_arr;
  };
  static get_box_student_arr(inData: any) {
    let opt_obj = {
      box_user_time_arr: [],
      studentArr: [],
      ...inData
    };
    let box_user_time_arr = opt_obj.box_user_time_arr;
    let studentArr = opt_obj.studentArr;
    let box_student_arr=[];

    let stu_seq_arr=[];
    for(let i=0;i<box_user_time_arr.length;i++){
      let box_user_time=box_user_time_arr[i];
      if(strFunc.str_in_array(box_user_time["a_stu_seq"],stu_seq_arr)==-1){
        stu_seq_arr.push(box_user_time["a_stu_seq"]);
      }
    }
    for(let i=0;i<studentArr.length;i++){
      let student=studentArr[i];
      if(strFunc.str_in_array(student["a_user_seq"],stu_seq_arr)!=-1){
        box_student_arr.push({...student});
      }
    }

    return box_student_arr;
  };
  static get_box_student_arr_of_setting_stu_time_arr(inData: any) {
    let opt_obj = {
      box_student_arr: [],
      box_user_time_arr:[],
      ...inData
    };
    let box_student_arr = opt_obj.box_student_arr;
    let box_user_time_arr = opt_obj.box_user_time_arr;

    for(let stu_i=0;stu_i<box_student_arr.length;stu_i++){
      let student=box_student_arr[stu_i];
      
      let row_user_time_arr=[];
      let row_days_week_arr=[];
      for(let time_i=0;time_i<box_user_time_arr.length;time_i++){
        let user_time_info=box_user_time_arr[time_i];
        if(student["a_user_seq"]==user_time_info["a_stu_seq"]){
          row_user_time_arr.push(user_time_info);
          row_days_week_arr.push(user_time_info["a_day_num"]);
        }
      }
      row_days_week_arr.sort();
      box_student_arr[stu_i].user_time_arr=row_user_time_arr;
      box_student_arr[stu_i].days_week_arr=row_days_week_arr;
    }

    return box_student_arr;
  };
}
export default StuTimeBoxFunc;