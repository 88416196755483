import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import search_ico from "@/img/ico/search3.png";

const SearchArea = forwardRef((props:any, ref) => {
  const [listOpt,setListOpt] = useState(props.listOpt);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);
  useEffect(()=>{
    
    getClassFrontArr();
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({now_page:1,[name]: value});
  };


  const onKeyUpEnterSearch=(e:any)=>{
    if(e.key === 'Enter'){goSearch();}
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr(response.data["data"]["class_arr"]);
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  let oder_id_select_arr=[
    {"text":"등록순","value":"a_create_date"},
    {"text":"등록역순","value":"a_create_date DESC"},
    {"text":"이름순","value":"a_user_name"},
    {"text":"출결번호","value":"user_stu_num"},
    {"text":"출결번호역순","value":"user_stu_num DESC"},
  ];

  return (
    <div className="basic_search_wrap" style={{borderTop:0}}>
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        원생검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={listOpt.s_user_name_like} onChange={handleInputChange} placeholder="이름" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_school_name_like"
            value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_class_name_like"
            value={listOpt.s_class_name_like} onChange={handleInputChange} placeholder="클래스" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
          <option value="">학년</option>
          {
            props.xColumnArr.select_arr.a_stu_grade.map((item:any,idx:number)=>{
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
          <option value="">전체</option>
          {
            props.xColumnArr.select_arr.a_stu_state.map((item:any,idx:number)=>{
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
          </select>
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-yellowish2" onClick={()=>{props.list({now_page:1});}} style={{padding:"9px 10px"}} >검색</button>
        </div>
      </div>
    </div>
  );
});

export default SearchArea;