import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "../../css/style.module.css";
import video_ico_img from "@/img/ico/video_ico.png";
import clock_ico_img from "@/img/ico/clock_ico.png";
import strFunc from '@/lib/lyg/string';

const TitleArea=forwardRef((props:any, ref) => {
  let myProps:any={
    row_data:{},
    ...props
  };
  

  const [row_data,set_row_data]= useState<any>(myProps.row_data);
  let left_ico_img=clock_ico_img;
  if(row_data){
    if(row_data.a_par_id=="video_list"){
      left_ico_img=video_ico_img;
    }
  }

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const setInitData=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
     row_data:{},
     ...inData
    }

    set_row_data(optObj.row_data);
  };

  let per_span_style:any={};
  let per_str:any=0;
  if(row_data.a_success_sort=="time"){
    let a_success_time_sec=parseInt(strFunc.uncomma(row_data.a_success_time_sec));
    let a_time_sec=parseInt(strFunc.uncomma(row_data.a_time_sec));
    if(a_success_time_sec!=0&&a_time_sec!=0){
      per_str=(a_time_sec/a_success_time_sec)*100;
    }
  }else{
    let a_success_amount=parseInt(strFunc.uncomma(row_data.a_success_amount));
    let a_amount_num=parseInt(strFunc.uncomma(row_data.a_amount_num));
    if(a_success_amount!=0&&a_amount_num!=0){
      per_str=(a_amount_num/a_success_amount)*100;
    }
  }
  if(row_data.a_is_success=="1"){
    per_str=100;
  }
  per_str=Math.round(parseFloat(strFunc.uncomma(per_str)));
  if(per_str>0){
    per_span_style["color"]="yellow";
  }
  if(per_str>50){
    per_span_style["color"]="#00aaff";
  }
  if(per_str>80){
    per_span_style["color"]="#ff6100";
  }
  if(per_str>=100){
    per_span_style["color"]="#86ffa1";
  }

  return (
    <div className={Style.view_title_wrap}>
      <div className={Style.view_title_img_wrap}>
        <div className={Style.view_title_img_box} style={{background:"#fff"}} >
          <img src={left_ico_img} style={{height:"80%"}} />
        </div>
      </div>
      <div className={Style.view_title_center_wrap}>
        <h3>{row_data.a_title}</h3>
        <div>{row_data.a_subject}</div>
      </div>
      <div className={Style.view_title_right} style={per_span_style}>
        {per_str} %
      </div>
    </div>
  );
});

export default TitleArea;