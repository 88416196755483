import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";

function PreWriteArea(props: any) {
  let myProps = {
    rowData:{},
    isUpdate:false,
    comment_init_data:{},
    handleInputChange:(inData:any)=>{},
    comment_list:[],
    refresh_comment:(inData:any)=>{},
    ...props
  };
  let rowData=myProps.rowData;
  let isUpdate=myProps.isUpdate;
  let comment_init_data=myProps.comment_init_data;
  const handleInputChange=(inData:any)=>{
    myProps.handleInputChange(inData);
  };

  let score_select_arr = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  return (
    <>
      <tr>
        <th>진도</th>
        <td>
          <TextAreaComponent
            class_name="row-input"
            name="a_study_progress"
            value={rowData.a_study_progress}
            onChange={handleInputChange}
            placeholder="현재진도"
            style={{ width: "90%" }}
          ></TextAreaComponent>
          {isUpdate &&
            <CommentPartArea
              info_arr={myProps.comment_list}
              list={myProps.refresh_comment}
              init_data={{
                ...comment_init_data,
                par_locate: "a_study_progress",
                par_locate_name: "현재진도",
              }}
            ></CommentPartArea>
          }
        </td>
        <td colSpan={2}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <div>
              <SelectBoxBar
                valueTextArr={score_select_arr}
                value={rowData.a_attitude_score}
                name={"a_attitude_score"}
                on_change={(inData: any) => {
                  handleInputChange({
                    target: {
                      name: inData.name,
                      value: inData.value,
                      type: "select",
                    }
                  });
                }}
              ></SelectBoxBar>
            </div>
            <span>
              <input type="number" className="row-input" name="a_study_score"
                value={rowData.a_study_score} onChange={handleInputChange} placeholder="0"
                onClick={(e: any) => { e.target.select(); }}
                style={{ width: 60 }} />점
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <th>숙제</th>
        <td>
          <TextAreaComponent
            class_name="row-input"
            name="a_homework_progress"
            value={rowData.a_homework_progress}
            onChange={handleInputChange}
            placeholder="숙제진도"
            style={{ width: "90%" }}
          ></TextAreaComponent>
          {isUpdate &&
            <CommentPartArea
              info_arr={myProps.comment_list}
              list={myProps.refresh_comment}
              init_data={{
                ...comment_init_data,
                par_locate: "a_homework_progress",
                par_locate_name: "숙제진도",
              }}
            ></CommentPartArea>
          }
        </td>
        <td colSpan={2}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <div>
              <SelectBoxBar
                valueTextArr={score_select_arr}
                value={rowData.a_homework_perform_score}
                name={"a_homework_perform_score"}
                on_change={(inData: any) => {
                  handleInputChange({
                    target: {
                      name: inData.name,
                      value: inData.value,
                      type: "select",
                    }
                  });
                }}
              ></SelectBoxBar>
            </div>
            <span>
              <input type="number" className="row-input" name="a_homework_score"
                value={rowData.a_homework_score} onChange={handleInputChange} placeholder="0"
                onClick={(e: any) => { e.target.select(); }}
                style={{ width: 60 }} />점
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <th>Plan</th>
        <td colSpan={3}>
          <TextAreaComponent
            class_name="row-input"
            name="a_progress_plan"
            value={rowData.a_progress_plan}
            onChange={handleInputChange}
            placeholder="Plan"
            style={{ width: "90%" }}
          ></TextAreaComponent>
          {isUpdate &&
            <CommentPartArea
              info_arr={myProps.comment_list}
              list={myProps.refresh_comment}
              init_data={{
                ...comment_init_data,
                par_locate: "a_progress_plan",
                par_locate_name: "Plan",
              }}
            ></CommentPartArea>
          }
        </td>
      </tr>
      <tr>
        <th>특이사항</th>
        <td colSpan={3}>
          <TextAreaComponent
            class_name="row-input"
            name="a_etc_memo"
            value={rowData.a_etc_memo}
            onChange={handleInputChange}
            placeholder="특이사항"
            style={{ width: "90%" }}
          ></TextAreaComponent>
          {isUpdate &&
            <CommentPartArea
              info_arr={myProps.comment_list}
              list={myProps.refresh_comment}
              init_data={{
                ...comment_init_data,
                par_locate: "a_etc_memo",
                par_locate_name: "특이사항",
              }}
            ></CommentPartArea>
          }
        </td>
      </tr>
    </>
  );
};
export default PreWriteArea;