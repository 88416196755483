import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';

const MultyChangeArea = forwardRef((props:any, ref) => {
  let myProps={
    xColumnArr:null,
    onChangeMulty:(inData:any)=>{},//{"key":"","value":""}
    ...props
  };

  const [key_val,set_key_val]=useState<any>({"key":"","value":""});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const getChangeInputTag=()=>{
    let key=key_val["key"];
    let on_change_val=(e:any)=>{
      set_key_val({...key_val,"value":e.target.value});
    };
    let input_tag=<input type="text" className="search-input ml-1" value={key_val.value} 
      onChange={on_change_val} 
      style={{width:100}} 
      placeholder='입력' />;
    if(myProps.xColumnArr.select_arr[key]){
      input_tag=(
        <select className="search-input_select ml-1" value={key_val.value} onChange={on_change_val} >
          <option value="">선택없음</option>
          {
            myProps.xColumnArr.select_arr[key].map((item:any,idx:number)=>{
              let val_str=item.value;
              if(item.value==""){
                val_str="empty";
              }
              return (
                <option value={val_str} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
      );
    }

    return input_tag;
  };
  let input_tag=getChangeInputTag();

  return (
    <div className="ml-2">
      일괄변경
      <select className="search-input_select ml-1" 
        value={key_val.key} 
        onChange={(e:any)=>{
          set_key_val({...key_val,"key":e.target.value});
        }}
        style={{width:100}}>
        <option value="">선택없음</option>
        {
          myProps.xColumnArr.x_column_list_arr.map((col_data:any,idx:number)=>{
            if(col_data["is_show"]!="1"){
              return "";
            }
            return (
              <option value={col_data["key"]} key={idx}>{col_data["name"]}</option>
            );
          })
        }
      </select>
      {input_tag}
      <button className="btn btn-dark ml-1" onClick={()=>{myProps.onChangeMulty(key_val);}} >변경</button>
    </div>
  );
});
export default MultyChangeArea;