import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useEffect } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import TabArea from '@/pcomponents/common/content/list/user/search/tab_area';
import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import SearchReportArea from "./area/search_area";
import CardListArea from "./area/card_list";

function DailyReportCardListPage() {
  const [tab_id, set_tab_id] = useState("lecture");
  const xColumnArr = getXcolumnJson();
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_lecture_time: "1",
    s_addon_prescribe_last: "1",
    s_addon_prescribe: "1",
    s_addon_prescribe_start_date: now_ymd_str,
    s_addon_prescribe_end_date: now_ymd_str,
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {

    list({});
  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);
    tmp_list_opt["s_attend_start_date"]=tmp_list_opt["s_addon_prescribe_start_date"];
    tmp_list_opt["s_attend_end_date"]=tmp_list_opt["s_addon_prescribe_start_date"];

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          set_refresh(!refresh);
        } else {

        }
      });
  };

  const on_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_pri_val_arr: any = [];
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let row_pri_val = info["a_seq"];
        tmp_pri_val_arr.push(row_pri_val);
      }
      set_check_row_val_arr(tmp_pri_val_arr);
      set_refresh(!refresh);
    } else {
      set_check_row_val_arr([]);
      set_refresh(!refresh);
    }
  };

  const select_save_study_prescribe = () => {
    let select_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    save_study_prescribe_by_stu_arr(select_info_arr);
  };
  const select_delete_study_prescribe = () => {
    let select_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    delete_study_prescribe_by_stu_arr(select_info_arr);
  };

  const save_study_prescribe_by_stu_arr = (select_stu_arr: any[]) => {
    if (select_stu_arr.length == 0) {
      alert("선택이 없습니다..");
      return false;
    }

    //보고서 모으기
    let report_data_arr: any = [];
    for (let i = 0; i < select_stu_arr.length; i++) {
      let stu_info: any = select_stu_arr[i];
      if (stu_info.study_prescribe_arr && stu_info.study_prescribe_arr.length > 0) {
        report_data_arr = [
          ...report_data_arr,
          ...stu_info.study_prescribe_arr
        ];
      }
      if (stu_info.study_prescribe_write_info) {
        report_data_arr = [
          ...report_data_arr,
          ...[stu_info.study_prescribe_write_info]
        ];
      }
    }
    if (report_data_arr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let w_report_form_json = {
      "data_arr": report_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/write', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_study_prescribe_by_stu_arr = (select_stu_arr: any[]) => {
    if (select_stu_arr.length == 0) {
      alert("선택이 없습니다..");
      return false;
    }

    //보고서 모으기
    let report_data_arr: any = [];
    for (let i = 0; i < select_stu_arr.length; i++) {
      let stu_info: any = select_stu_arr[i];
      if (stu_info.study_prescribe_arr && stu_info.study_prescribe_arr.length > 0) {
        report_data_arr = [
          ...report_data_arr,
          ...stu_info.study_prescribe_arr
        ];
      }
    }
    if (report_data_arr.length == 0) {
      alert("삭제 할 데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let w_report_form_json = {
      "data_arr": report_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/delete', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <TabArea
          tab_id={tab_id}
          set_tab_id={set_tab_id}
        ></TabArea>
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          xColumnArr={xColumnArr}
          list={list}
        >
          <SearchReportArea
            listOpt={listOpt}
            list={list}
          ></SearchReportArea>
        </SearchArea>
        <div className="mt-1">
          <label>
            <input type="checkbox" onChange={on_check_all} />
            <span className="ml-1">전체</span>
          </label>
          <button className="btn-s btn-yellowish2 ml-2" onClick={select_save_study_prescribe}>선택저장</button>
          <button className="btn-s btn-yellowish2 ml-2" onClick={(e: any) => { save_study_prescribe_by_stu_arr(infoArr); }}>일괄저장</button>
          <button className="btn-s btn-red ml-2" onClick={select_delete_study_prescribe} >선택삭제</button>
        </div>
        <div className="mt-2">
          <CardListArea
            tab_id={tab_id}
            info_arr={infoArr}
            set_info_arr={(inData: any) => {
              setInfoArr(inData);
              set_refresh(!refresh);
            }}
            select_date={listOpt.s_addon_prescribe_start_date}
            list={list}
            check_row_val_arr={check_row_val_arr}
            set_check_row_val_arr={(inData: any) => {
              set_check_row_val_arr(inData);
              set_refresh(!refresh);
            }}
          ></CardListArea>
        </div>
        <div className="mt-2">
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            is_view_num_per_page={true}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
        </div>
      </div>
    </CompLayout>
  );
};
export default DailyReportCardListPage;