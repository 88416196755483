//import CompLayout from "@/pcomponents/comp/layout/layout";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDark } from '@/store/dark/dark';
import Header from "./header";
import Footer from "./footer";
import SNBArea from "./menu/snb";
import ContentTitle from "./content/con_title";

const CompLayout=(props:any)=>{
  const dispatch = useDispatch();
  let dark=useSelector((state:any) => state.dark);
  let dark_class_name="light";
  if(localStorage.theme=="dark"){
    dark_class_name=localStorage.theme;
  }

  useEffect(()=>{
    setDarkClass();
  },[dark]);

  const setDarkClass=()=>{
    if(dark.dark==""){
      if(localStorage.theme == 'dark' ){
        dispatch(setDark("dark"));
      }else{
        dispatch(setDark("light"));
      }
    }
  };

  return (
    <div id="main_wrap" className={dark_class_name} >
      <div className="main_body">
        <Header></Header>
        <div className="snb_area_wrap">
          <SNBArea></SNBArea>
        </div>
        <div id="container">
          <div className="container_con">
            {props.isConTitle &&
              <ContentTitle></ContentTitle>
            }
            {props.children}
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

CompLayout.defaultProps = {
  "isLeftArea":true,
  "isConTitle":true,
}

export default CompLayout;