import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import SendPushPopup from "@/pages/comp/basic/push/popup/send";
import strFunc from "@/lib/lyg/string";

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["staff","teacher","master"],
      s_addon_invite_code:"1",
      s_addon_comp_link:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view,send_push
    receive_user_seq_arr:[],
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length>=2){
      alert("한개만 선택해주세요.");
      return false;
    }
    let tmp_delete_user_seq=selected_row[0]["a_seq"];
    if(tmp_delete_user_seq=="1"){
      alert("삭제 할 수 없는 회원입니다.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/delete_one',{ 
      user_seq: tmp_delete_user_seq,
    },get_axios_data()).then((response) => {
      if(response.data["result"]==="true"){
        localStorage.user_data_create_date="";
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const open_send_push_popup=()=>{
    let pop_title="푸시전송";
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    let receive_user_seq_arr:any=[];
    for(let i=0;i<selected_row.length;i++){
      receive_user_seq_arr.push(selected_row[i]["a_seq"]);
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "receive_user_seq_arr":receive_user_seq_arr,
      "title":pop_title,
      "sort":"send_push"
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_manage"){
      render_str=(
        <div>
          <button className="btn btn-dark" onClick={()=>{setTimeout(()=>{openOnePopup("write",true);},100);}}>수정</button>
        </div>
      );
    }else if(key=="row_view_is_app"){
      if(params.data.comp_link_info){
        render_str=(<div className="text-gray-500">가입(로그아웃)</div>);
        if(params.data.comp_link_info.main_u_info&&params.data.comp_link_info.main_u_info.a_push_token){
          render_str=(<div className="text-green-500">가입</div>);
        }
      }
    }else if(key=="a_stu_num"){
      render_str=strFunc.getNumber2(render_str);
    }
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="btn-box-left relative h-8">
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-sky" onClick={()=>{open_send_push_popup();}}>푸시</button>
            <button className="btn btn-dark" onClick={()=>{openOnePopup("write",false);}}>등록</button>
            <button className="btn btn-dark" onClick={()=>{openOnePopup("write",true);}}>수정</button>
            <button className="btn btn-dark" onClick={goDelete}>삭제</button>
          </div>
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:true,
          floatingFilter:true,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}
          onChangeNumPerPage={(num_per_page:number)=>{list({num_per_page:num_per_page,now_page:1});}}></Paging>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}}
              openOnePopup={openOnePopup}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ViewPopupContent>
          }
          {popupData.sort==="send_push"&&
            <SendPushPopup isUpdate={popupData.isUpdate} 
              receive_user_seq_arr={popupData.receive_user_seq_arr}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></SendPushPopup>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;