import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

const ViewFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_is_use":"1",
    "a_title":"",
    "a_corse_name":"",
    "a_corse_seq":"",
    "a_is_agree":"",
    "a_writer":"",
    "a_writer_seq":"",
    "a_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const goAgreeUse=()=>{
    if(!confirm("승인 하시겠습니까?")){
      return false;
    }
    let agree_form_data={
      "request_seq_arr":[rowData.a_ymd+","+rowData.a_seq],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/request/agree',agree_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
              {rowData.a_title}
              </td>
            </tr>
            <tr>
              <th>승인여부</th>
              <td colSpan={3}>
                {rowData.a_is_agree=="1"?"승인":"미승인"}
              </td>
            </tr>
            <tr>
              <th>요청자</th>
              <td colSpan={3}>
                {rowData.a_writer}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {(rowData.a_seq!=""&&rowData.a_is_agree!="1")&&
          <button className="btn btn-dark" onClick={()=>{goAgreeUse();}}>승인하기</button>
        }
      </div>
    </div>
  );
});

export default ViewFrameContent;
