import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import YouTubeArea from "./area/youtube";
import VimeoArea from  "@/pcomponents/common/content/video/player/vimeo";

function VideoPreViewPopup(props:any) {
  let myProps={
    rowData:{},
    ...props
  };
  let total_time_json=strFunc.secondsToTimeJson(strFunc.uncomma(myProps.rowData.a_total_sec));
  let complete_time_json=strFunc.secondsToTimeJson(strFunc.uncomma(myProps.rowData.a_complete_sec));

  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>{myProps.rowData.a_title}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                {myProps.rowData.a_url_sort=="youtube"&&
                  <YouTubeArea
                    rowData={myProps.rowData}
                  ></YouTubeArea>
                }
                {myProps.rowData.a_url_sort=="vimeo"&&
                  <VimeoArea
                    video_url={strFunc.get_vimeo_id_by_url(myProps.rowData.a_url)}
                    video_opt={{
                      width:500
                    }}
                  ></VimeoArea>
                }
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                  {
                    myProps.rowData.a_memo && myProps.rowData.a_memo.split('\n').map( (line:string,idx:number) => {
                      return (<span key={idx}>{line}<br/></span>)
                    })
                  }
                </td>
            </tr>
            <tr>
              <th>총시간</th>
              <td>
                {total_time_json.h+":"+total_time_json.i+":"+total_time_json.s}
              </td>
              <th>수료시간</th>
              <td>
                {complete_time_json.h+":"+complete_time_json.i+":"+complete_time_json.s}
              </td>
            </tr>
            <tr>
              <th>필수여부</th>
              <td>{myProps.rowData.a_is_essential=="1"?"예":"아니오"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default VideoPreViewPopup;
