import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    on_select_click: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  const [subjectArr, setsubjectArr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  useEffect(() => {

    list_corse_subject();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    props.list({ [name]: value });
  };

  const goSearch = () => {
    props.list({});
  };

  const list_corse_subject = () => {
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div className="search-box relative">
      <select className="search-input_select ml-1" name="s_subject_sort" value={listOpt.s_subject_sort}
        onChange={handleInputChange}
        style={{ width: 70 }} >
        <option value="">과목</option>
        {
          subject_option_arr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_subject_seq" value={listOpt.s_subject_seq}
        onChange={handleInputChange}
        style={{ width: 70 }}>
        <option value="">구분</option>
        {
          subjectArr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
      <button className="btn btn-darkgreen" onClick={() => { myProps.on_select_click({}); }} >선택</button>
    </div>
  );
};

export default SearchArea;