import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"list_opt",
    list_id:"list_opt_w_popup",
    x_column_list_arr:[
      {"key":"key","name":"키명","width":"100","is_show":""},
      {"key":"name","name":"이름","width":"140","is_show":"1"},
      {"key":"width","name":"크기","width":"80","is_show":"1"},
      {"key":"is_show","name":"보임","width":"80","is_show":"1"},
      {"key":"row_view_order","name":"순번","width":"90","is_show":"1"},
    ],
  };
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr["select_arr"]["is_show"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;