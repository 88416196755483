import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { default_info, default_add_memo_info } from "./data/default_data";
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import WriteArea from "./write_area";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ModifyClassOfStuOnePopup from "@/pages/comp/basic/student/popup/modify_class_of_stu_one";
import getXcolumnJson from "./xcolumn/list";
import DateFunc from '@/lib/lyg/date_func';
import LoadingComponent from "@/pcomponents/common/content/loading";

const WritePage = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    info_arr: [],
    select_idx: -1,
    close_write: () => { },
    close_btn_name: "뒤로",
    is_view_close_btn: true,
    is_view_write_btn: true,
    list: (inData: any) => { },
    refresh_data: (inData: any) => { },
    is_view_mode: true,
    set_is_view_mode: (inData: any) => { },
    is_update: false,
    default_write_info: {},
    counsel_s_select_date: "",
    is_view_stu_info: true,
    is_view_parent: true,
    is_view_supply: true,
    is_view_basic: false,
    tab_id: "monthly_prescribe",
    is_first_tab_scroll: false,
    ...props
  };
  let select_mcomp_data=myProps.select_mcomp_data;
  const [info_arr, set_info_arr] = useState(myProps.info_arr);
  const xColumnArr = getXcolumnJson();
  let init_default_info:any={
    ...default_info,
    ...myProps.default_write_info,
    "a_join_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_join_counsel_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "class_arr":[],
  };
  const [info, set_info] = useState(init_default_info);
  const [add_memo_info, set_add_memo_info] = useState(default_add_memo_info);
  const [parentArr, setParentArr] = useState<any>([{
    "a_seq": "",
    "a_user_phone": "",
    "a_user_id": "",
    "a_user_pw": "",
    "a_relation": "모",
    "a_order": "0",
    "a_is_attend_sms": "1",
    "a_is_attend_push": "1",
    "a_is_attend_katalk": "1",
  }]);
  const [select_idx, set_select_idx] = useState(myProps.select_idx);
  const [is_view_mode, set_is_view_mode] = useState(myProps.is_view_mode);
  const [is_exist_modify, set_is_exist_modify] = useState(false);
  const [refresh, set_refresh] = useState(false);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장중..",
  });
  const WriteAreaRef = useRef<any>(null);

  useEffect(() => {
    if (myProps.is_update) {
      get_user_info_by_ajax({});
    }
  }, [select_idx,select_mcomp_data.mcomp_seq]);

  const setInitData = (inData: any) => {
    let opt_obj = {
      "info_arr": [],
      "select_idx": -1,
      "is_view_mode": true,
      "counsel_s_select_date": "",
      ...inData
    };
    set_info_arr(opt_obj.info_arr);
    set_select_idx(opt_obj.select_idx);
    set_is_view_mode(opt_obj.is_view_mode);
    if (myProps.is_update) {
      get_user_info_by_ajax({
        info_arr: opt_obj.info_arr,
        select_idx: opt_obj.select_idx,
        counsel_s_select_date: opt_obj.counsel_s_select_date
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, get_is_exist_modify, go_write_user_data,
    get_is_view_mode, set_is_view_mode
  }));

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "modify_class",
    stu_seq: "",
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  const get_is_exist_modify = () => { return is_exist_modify; };
  const get_is_view_mode = () => { return is_view_mode; };

  const get_user_info_by_ajax = (inData: any) => {
    let opt_obj = {
      info_arr: info_arr,
      select_idx: select_idx,
      counsel_s_select_date: "",
      ...inData,
    };
    let row_info_arr = opt_obj["info_arr"];
    let row_select_idx = opt_obj["select_idx"];
    if (strFunc.is_empty(row_info_arr[row_select_idx])) {
      alert("선택 회원 정보가 없습니다.");
      myProps.close_write();
      return false;
    }
    let select_user_seq = row_info_arr[row_select_idx]["a_seq"];
    if (strFunc.is_empty(select_user_seq)) {
      alert("회원키가 올바르지 않습니다.");
      myProps.close_write();
      return false;
    }
    let user_info_list_opt:any = {
      "s_pri_arr": [select_user_seq],
      s_addon_class_user: "1",
      s_addon_link_user: "1",
      s_addon_profile: "1",
      s_addon_counsel_file: "1",
      s_addon_invite_code: "1",
      s_addon_comp_link: "1",
      s_addon_recent_counsel: "1",
      s_addon_add_memo: "1",
      s_addon_lecture_time: "1",
      s_addon_daily_report_last: "1",
      s_addon_mprescribe_last: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        user_info_list_opt["api_key"]=select_mcomp_data.api_key;
        user_info_list_opt["api_user_seq"]=select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', user_info_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_is_exist_modify(false);
            let row_user_info = response.data["data"]["info_arr"][0];
            set_info(row_user_info);
            set_add_memo_info({
              ...default_add_memo_info,
              ...(row_user_info.add_memo_info ? row_user_info.add_memo_info : {})
            });
            if (row_user_info.link_user_arr) {
              setParentArrByLinkArr(row_user_info.link_user_arr);
            } else {
              setParentArrByLinkArr([]);
            }
            if (WriteAreaRef.current) {
              WriteAreaRef.current.setInitData({
                info: row_user_info,
                counsel_s_select_date: opt_obj.counsel_s_select_date
              });
            }
          } else {
            alert("회원정보가 없습니다.");
            myProps.close_write();
          }
        } else {

        }
      });
  };

  const setParentArrByLinkArr = (link_user_arr: any) => {
    let tmpParentArr = [];
    for (let i = 0; i < link_user_arr.length; i++) {
      let link_info = link_user_arr[i];
      if (link_info["par_info"] && link_info["stu_info"]) {
        tmpParentArr.push({
          "a_seq": link_info["par_info"]["a_seq"],
          "a_user_phone": strFunc.autoHypenPhone(link_info["par_info"]["a_user_phone"]),
          "a_user_id": link_info["par_info"]["a_user_id"],
          "a_user_pw": "",
          "a_relation": link_info["a_relation"],
          "a_order": link_info["a_order"],
          "a_is_attend_sms": link_info["a_is_attend_sms"],
          "a_is_attend_push": link_info["a_is_attend_push"],
          "a_is_attend_katalk": link_info["a_is_attend_katalk"],
        });
      }
    }
    setParentArr(tmpParentArr);
  };

  const go_write_user_data = () => {
    if (myProps.is_update) {
      if (strFunc.is_empty(info.a_user_id)) {
        alert("아이디 입력이 필요합니다.");
        return false;
      }
    }
    if (!myProps.is_update) {
      if (info.a_user_pw == "") {
        alert("암호 입력이 필요합니다.");
        return false;
      }
    }
    if (info.a_user_name == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    }

    let user_write_form_json: any = {
      "data_arr": [info],
      "is_update": "1",
    };
    if (myProps.is_update == false) {
      user_write_form_json["is_update"] = "";
      user_write_form_json["is_default_val"] = "1";
      user_write_form_json["is_add_class_arr"]="1";
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        user_write_form_json["api_key"]=select_mcomp_data.api_key;
        user_write_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    set_loading_data({
      is_display: true,
      text: "저장중..",
    });
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_student', user_write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let row_user_info = response.data["data"][0];
          set_is_exist_modify(false);
          go_write_add_memo_info({
            a_user_seq: row_user_info["a_seq"],
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_write_add_memo_info = (inData: any) => {
    let row_add_memo_info = {
      ...add_memo_info,
      a_user_seq: info.a_seq,
      a_user_name: info.a_user_name,
      ...inData,
    };
    let user_write_form_json:any = {
      "data_arr": [row_add_memo_info],
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        user_write_form_json["api_key"]=select_mcomp_data.api_key;
        user_write_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user_add_memo/write', user_write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          goWriteParentUser({ a_user_seq: row_add_memo_info.a_user_seq });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteParentUser = (inData: any) => {
    let opt_obj = {
      a_user_seq: info.a_seq,
      ...inData,
    };

    let tmp_par_arr = getExistParentArr();

    if (tmp_par_arr.length == 0) {
      go_finish_write_complete();
      return false;
    }


    let formJsonData:any = {
      stu_seq: opt_obj.a_user_seq,
      parentArr: tmp_par_arr
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        formJsonData["api_key"]=select_mcomp_data.api_key;
        formJsonData["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_parent', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          go_finish_write_complete();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_finish_write_complete = () => {
    set_loading_data({
      is_display: true,
      text: "저장되었습니다.",
    });
    setTimeout(() => {
      set_loading_data({
        is_display: false,
        text: "저장중..",
      });
    }, 1000);

    if (WriteAreaRef.current) {
      WriteAreaRef.current.go_write_tab_area();
    }

    setTimeout(() => {
      set_is_view_mode(true);
      myProps.set_is_view_mode(true);
      myProps.refresh_data({});
      myProps.list({});
      if (myProps.is_update) {
        get_user_info_by_ajax({});
      } else {
        myProps.close_write();
      }
    }, 1000);
  };

  const getExistParentArr = () => {
    let resultParArr = [];
    for (let i = 0; i < parentArr.length; i++) {
      if (parentArr[i]["a_user_phone"] != "") {
        resultParArr.push(parentArr[i]);
      }
    }
    return resultParArr;
  };

  const openModifyClassPopup = () => {
    let pop_title = "클래스관리";

    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "modify_class",
      title: pop_title,
      stu_seq: info.a_seq
    });
  };

  const modifyClassOfStuPopupCallback=(inData:any)=>{
    let opt_obj={
      class_arr:[],
      ...inData,
    };
    
    set_info({
      ...info,
      class_arr:[...opt_obj.class_arr],
    });
  };

  return (
    <div>
      <div className="text-center">
        {myProps.is_view_close_btn &&
          <button className="btn-m btn-gray" onClick={() => { myProps.close_write(); }}>{myProps.close_btn_name}</button>
        }
        {myProps.is_view_write_btn &&
          <span>
            {is_view_mode == false &&
              <button className="btn-m btn-yellowish2 ml-1" onClick={() => { go_write_user_data(); }}>저장</button>
            }
            <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
              {is_view_mode ? "수정" : "보기"}모드
            </button>
          </span>
        }
      </div>
      <WriteArea
        ref={WriteAreaRef}
        select_mcomp_data={{...select_mcomp_data}}
        info={info}
        set_info={(inData: any) => {
          set_info(inData);
          set_refresh(!refresh);
          set_is_exist_modify(true);
        }}
        add_memo_info={add_memo_info}
        set_add_memo_info={(inData: any) => {
          set_add_memo_info(inData);
          set_is_exist_modify(true);
        }}
        parentArr={parentArr}
        setParentArr={(inData: any) => {
          setParentArr(inData);
          set_is_exist_modify(true);
        }}
        refresh_data={() => {
          get_user_info_by_ajax({});
          myProps.refresh_data({});
          myProps.list({});
        }}
        openModifyClassPopup={openModifyClassPopup}
        is_update={myProps.is_update}
        xColumnArr={xColumnArr}
        is_view_stu_info={myProps.is_view_stu_info}
        is_view_parent={myProps.is_view_parent}
        is_view_supply={myProps.is_view_supply}
        is_view_basic={myProps.is_view_basic}
        is_view_mode={is_view_mode}
        set_is_view_mode={set_is_view_mode}
        is_exist_modify={is_exist_modify}
        set_is_exist_modify={set_is_exist_modify}
        counsel_s_select_date={myProps.counsel_s_select_date}
        tab_id={myProps.tab_id}
        is_first_tab_scroll={myProps.is_first_tab_scroll}
      ></WriteArea>
      <div className="text-center mt-2">
        {myProps.is_view_close_btn &&
          <button className="btn-m btn-gray" onClick={() => { myProps.close_write(); }}>{myProps.close_btn_name}</button>
        }
        {myProps.is_view_write_btn &&
          <span>
            {is_view_mode == false &&
              <button className="btn-m btn-yellowish2 ml-1" onClick={() => { go_write_user_data(); }}>저장</button>
            }
            <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
              {is_view_mode ? "수정" : "보기"}모드
            </button>
          </span>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "modify_class" &&
            <ModifyClassOfStuOnePopup
              select_mcomp_data={{...select_mcomp_data}}
              stu_info={info}
              class_arr={info.class_arr}
              callback={modifyClassOfStuPopupCallback}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ModifyClassOfStuOnePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
});
export default WritePage;