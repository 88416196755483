//is_phone_check 체크
class PhoneCheckBoxFunc{
  static onClickPhoneCheckAll(inData:any){
    let opt_obj={
      info_arr:[],
      sort:"",//"student"|"parent"
      is_check:false,
      ...inData
    };
    let info_arr=opt_obj["info_arr"];
    let info_arr_len=info_arr.length;
    for(let info_i=0;info_i<info_arr_len;info_i++){
      let info=info_arr[info_i];

      if(opt_obj["sort"]=="student"){
        info_arr[info_i]["is_phone_check"]=opt_obj["is_check"];
      }
      
      if(info.link_user_arr){
        let link_len=info.link_user_arr.length;
        for(let link_i=0;link_i<link_len;link_i++){
          let link_info=info.link_user_arr[link_i];

          if(opt_obj["sort"]=="parent"){
            info_arr[info_i].link_user_arr[link_i]["is_phone_check"]=opt_obj["is_check"];
          }
        }
      }
    }

    return info_arr;
  }

  static onCheckPhoneNum(inData:any){
    let opt_obj={
      info_arr:[],
      sort:"",//"student"|"parent"
      is_check:false,
      row_num:0,
      link_row_num:0,
      ...inData
    };

    let info_arr=opt_obj["info_arr"];
    let info_arr_len=info_arr.length;
    for(let info_i=0;info_i<info_arr_len;info_i++){
      let info=info_arr[info_i];

      if(opt_obj["sort"]=="student"){
        if(opt_obj.row_num==info_i){
          info_arr[info_i]["is_phone_check"]=opt_obj["is_check"];
        }
      }
      
      if(info.link_user_arr){
        let link_len=info.link_user_arr.length;
        for(let link_i=0;link_i<link_len;link_i++){
          let link_info=info.link_user_arr[link_i];

          if(opt_obj["sort"]=="parent"){
            if(opt_obj.row_num==info_i){
              if(opt_obj.link_row_num==link_i){
                info_arr[info_i].link_user_arr[link_i]["is_phone_check"]=opt_obj["is_check"];
              }
            }
          }
        }
      }
    }

    return info_arr;
  }
}
export default PhoneCheckBoxFunc;