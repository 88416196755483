import CompLayout from "@/pcomponents/comp/layout/layout";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";

function DataMainPage(){
  let user=useSelector((state:any) => state.user);

  let is_able_move_btn=false;
  if(!strFunc.is_empty(user.comp_id)&&!strFunc.is_empty(user.user_seq)){
    is_able_move_btn=true;
  }

  const open_data_page_popup=()=>{
    if(!is_able_move_btn){
      alert("로그인 정보가 없습니다.");
      return false;
    }
    let url_str=process.env.REACT_APP_DATA_URL+"/login";
    let url_data="?comp_id="+user.comp_id+"&user_id="+user.user_id;
    window.open(url_str+url_data,"_blank");
  };

  return (
    <CompLayout>
      <div className="mt-5 text-center">
        <button className="btn btn-sky" onClick={open_data_page_popup}>자료실 열기</button>
      </div>
    </CompLayout>
  );
}

export default DataMainPage;