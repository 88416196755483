function FileListArea(props:any){
   let myProps={
    Style:{},
    infoArr:[],
    openFileSlidePopup:(inData:any)=>{},
    goDownLoad:(inData:any)=>{},
    goDeleteFile:(inData:any)=>{},
    is_view:false,
    is_show_down:false,
    ...props
  };

  let Style = myProps.Style;
  let infoArr=myProps.infoArr;
  let is_view=myProps.is_view;
  
  const get_file_tags_by_file_arr = (file_arr: any[]) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let file_idx_num = idx + 1;

      return (
        <div key={idx} >
          <p>
            <span style={{cursor:"pointer"}}
              onClick={()=>{
                if(file_info.is_image == "1"){
                  myProps.openFileSlidePopup({ "select_file_idx": idx });
                }
              }}>
              {file_idx_num}.
              <span className="ml-1">
                {file_info.a_oriname}
              </span>
            </span>
            {myProps.is_show_down == true &&
              <button className="btn-s btn-gray ml-1" onClick={() => { myProps.goDownLoad(file_info); }}>다운</button>
            }
            {is_view == false &&
              <button className="btn-s btn-red ml-1" onClick={() => { myProps.goDeleteFile([file_info]); }} >X</button>
            }
          </p>
        </div>
      );
    });

    return file_tags;
  };

  let file_tags: any = "";
  if (infoArr) {
    file_tags = get_file_tags_by_file_arr(infoArr);
  }

  return (
  <div>
    {file_tags}
  </div>
  );
};
export default FileListArea;