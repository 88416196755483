import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import TeacherList,{IProps,IClassUser} from "./area/teacher_list";
import DayOfWeekArea from "./area/day_of_week";

function WritePopupContent(props:any) {
  const teacherListRef=useRef(null);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_seq":"",
    "a_name":"",
    "a_type":"monthly",
    "a_day_of_week":"",
    "a_start_time":"",
    "a_end_time":"",

    "start_h":"00",
    "start_m":"00",
    "end_h":"00",
    "end_m":"00",

    "a_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_end_date":"",
    "a_is_supply_money":"1",
    "a_supply_money":"0",
    "a_memo":"",

    "teacher_arr":[],
    "student_arr":[]
  });

  useEffect(()=>{
    
    if(props.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      if(props.rowData["a_start_time"]){
        let time_data=strFunc.timeToHourMinute(props.rowData["a_start_time"]);
        changeRowData["start_h"]=time_data["h"];
        changeRowData["start_m"]=time_data["m"];
      }
      if(props.rowData["a_end_time"]){
        let time_data=strFunc.timeToHourMinute(props.rowData["a_end_time"]);
        changeRowData["end_h"]=time_data["h"];
        changeRowData["end_m"]=time_data["m"];
      }
      
      setRowData({
        ...rowData,
        ...changeRowData
      });
      if(teacherListRef.current&&props.rowData.teacher_arr){
        (teacherListRef.current as  IProps).setOriginClassUsers(props.rowData.teacher_arr);
      }
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="a_supply_money"){
      value=strFunc.comma(value);
    }else if(name=="a_day_of_week"){
      let tmp_day_arr=rowData.a_day_of_week.split(",");
      if(value==""){
        //제거
        let tmp_day_arr2=[];
        for(let i=0;i<tmp_day_arr.length;i++){
          if(tmp_day_arr[i]==target.value){

          }else{
            tmp_day_arr2.push(tmp_day_arr[i]);
          }
        }
        tmp_day_arr=tmp_day_arr2;
      }else{
        //추가
        if(strFunc.str_in_array(value,tmp_day_arr)==-1){
          tmp_day_arr.push(value);
        }
      }
      value=tmp_day_arr.join(",");
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_name==""){
      alert("클래스명 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};
    wRowData.a_start_time=strFunc.HourMinuteToTime(wRowData.start_h,wRowData.start_m);
    wRowData.a_end_time=strFunc.HourMinuteToTime(wRowData.end_h,wRowData.end_m);

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(teacherListRef.current){
          let classUserList:IClassUser[]=(teacherListRef.current as  IProps).getCheckedClassUserList();
          goWriteClassUser(classUserList,response.data["data"][0]);
        }else{
          props.callback(response.data["data"]);
          props.closePopup();
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const goWriteClassUser=(classUserList:IClassUser[],lastInfo:any)=>{
    //클래스키 넣기
    for(let i=0;i<classUserList.length;i++){
      classUserList[i].a_class_seq=lastInfo["a_seq"];
    }
    let formJsonData={
      "class_seq":lastInfo["a_seq"],
      "class_user_arr":classUserList,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/classu/write_of_class',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  
  let a_type_arr=[
    {"value":"monthly","text":"매월수업"},
    {"value":"period","text":"기간수업"}
  ];
  let a_type_checks=a_type_arr.map((item,idx)=>{
    let bChecked=false;
    if(item.value==rowData.a_type){
      bChecked=true;
    }
    return (
      <label key={idx} className="mx-2">
        <input type="radio" name="a_type" value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  //수업요일(0~6:일월화수목금토)
  let day_of_week_arr=[
    {"value":"0","text":"일"},
    {"value":"1","text":"월"},
    {"value":"2","text":"화"},
    {"value":"3","text":"수"},
    {"value":"4","text":"목"},
    {"value":"5","text":"금"},
    {"value":"6","text":"토"},
  ];
  let day_of_week_arr_checks=day_of_week_arr.map((item,idx)=>{
    let bChecked=false;
    let tmp_day_arr=rowData.a_day_of_week.split(",");
    if(strFunc.str_in_array(item.value,tmp_day_arr)!=-1){
      bChecked=true;
    }
    return (
      <label key={idx} className="mx-2">
        <input type="checkbox" name="a_day_of_week" value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  //수업시간 select option
  let time_h_arr=[];
  let time_m_arr=[];
  for(let i=0;i<24;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_h_arr.push(i_str);
  }
  for(let i=0;i<60;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_m_arr.push(i_str);
  }
  let time_h_options=time_h_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });
  let time_m_options=time_m_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  return (
    <div>
      <div className="text-right mt-4">
        <span style={{color:"red",fontWeight:"bold"}}>*</span>
        필수입력사항
      </div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>클래스명<span style={{color:"red"}}>*</span></th>
              <td>
                <input type="text" className="row-input" name="a_name" value={rowData.a_name} onChange={handleInputChange} 
                  placeholder="클래스명" style={{maxWidth:200}} />
              </td>
            </tr>
            <tr>
              <th>수업방식</th>
              <td>
                {a_type_checks}
              </td>
            </tr>
            <tr>
              <th>선생님</th>
              <td>
                <TeacherList ref={teacherListRef}></TeacherList>
              </td>
            </tr>
            <tr>
              <th>수업요일</th>
              <td>
                <DayOfWeekArea
                  value={rowData.a_day_of_week}
                  onChange={(value:string)=>{
                    setRowData({
                      ...rowData,
                      a_day_of_week:value
                    });
                  }}
                ></DayOfWeekArea>
              </td>
            </tr>
            <tr>
              <th>수업시작종료</th>
              <td>
                <div style={{width:100,display:"inline-block"}}>
                  <DatePicker 
                    selected={rowData.a_start_date!=""?new Date(rowData.a_start_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_start_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
                  ~
                <div style={{width:100,display:"inline-block"}}>
                  <DatePicker 
                    selected={rowData.a_end_date!=""?new Date(rowData.a_end_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("a_end_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>청구여부</th>
              <td>
                <label>
                  <input type="checkbox" className="row-input" name="a_is_supply_money" value="1" checked={rowData.a_is_supply_money=="1"}
                    onChange={handleInputChange} style={{width:"auto"}} /> 청구
                </label>
              </td>
            </tr>
            <tr>
              <th>청구금액</th>
              <td>
                <input type="text" className="row-input text-center" name="a_supply_money" value={rowData.a_supply_money} 
                  onChange={handleInputChange} placeholder="청구금액" style={{width:110}} /> 원
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <textarea className="row-input h-20" name="a_memo" value={rowData.a_memo} onChange={handleInputChange} placeholder="메모" ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
