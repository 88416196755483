import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import popupFunc from "@/lib/lyg/popup";
import noimg from "@/img/ico/noimg.png";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_is_use":"1",
    "a_title":"",
    "a_order_num":"",
    "title_img_url":noimg,
    "title_img_arr":[]
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="a_order_num"){
      value=strFunc.comma(value);
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current&&(fileInput.current as HTMLInputElement).value!=""){
          goWriteProfileImg(response.data["data"][0]);
        }else{
          myProps.list({select_cat_seq:response.data["data"][0]["a_seq"]});
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const goWriteProfileImg=(row_data:any)=>{
    if(writeFileForm.current==null){
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data= new FormData(form);
    let file_data_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_comp_seq":user.comp_seq,
      "a_par_id":"video_category",
      "a_par_seq":row_data.a_seq,
      "a_sort1":"title",
      "a_sort2":"",
      "a_sort3":"",
      "a_writer":user.user_name,
      "a_create_seq":user.user_seq,
    };
    form_json_data.append("data_arr",JSON.stringify([file_data_row]));
    form_json_data.append("authorization",localStorage.intoyou_token);

    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current){
          (fileInput.current as HTMLInputElement).value="";
        }
        myProps.list({select_cat_seq:row_data.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDeleteProFile=(fileInfo:any)=>{
    if(!confirm("사진을 삭제 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "data_arr":[fileInfo],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/file/file/delete',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:rowData.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>카테고리</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="카테고리" />
              </td>
            </tr>
            <tr>
              <th>사용여부</th>
              <td colSpan={3}>
                <select className="row-input" name="a_is_use" value={rowData.a_is_use} onChange={handleInputChange}>
                  <option value="">사용안함</option>
                  <option value="1">사용</option>
                </select>
              </td>
            </tr>
            <tr>
            <th>순번</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_order_num" value={rowData.a_order_num} onChange={handleInputChange} placeholder="순번" />
              </td>
            </tr>
            <tr>
              <th>썸네일</th>
              <td>
              <div className="text-center">
                  <a onClick={()=>{
                      popupFunc.openPopup({'url':rowData.title_img_url,'width':700,'height':420,'pop_name':'name','auto_size':false});
                    }}>
                    <img src={rowData["title_img_url"]?rowData["title_img_url"]:noimg} alt="사람" style={{width:100}} />
                  </a>
                  {
                    rowData.title_img_arr.length>0 && 
                    <button className="btn-s btn-gray" onClick={()=>{goDeleteProFile(rowData.title_img_arr[0]);}}>X</button>
                  }
                  <form id="write_form" method="post" ref={writeFileForm} >
                    <input type="file" id="files" className="row-input mt-1" name="input_file[]" ref={fileInput} style={{width:200}} />
                  </form>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
    </div>
  );
});

export default WriteFrameContent;
