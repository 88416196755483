import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import SearchArea from '@/pages/comp/basic/student/list/area/search/search_area';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import SearchSubArea from "./area/search/sub_search";
import LectureStuTimeArea from "./area/lecture_stu_time";

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const LectureStuTimeAreaRef = useRef<any>(null);
  let origin_order_id = "a_stu_grade, a_school_name, a_user_name";
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    order_id: origin_order_id,
    s_grade: ["student"],
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_class_seq: "",
    // s_addon_link_user:"1",
    // s_addon_class_user:"1",
    s_addon_lecture_time: "1",
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
    "select_seq": "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  let is_under_width = MyScreenFunc.get_is_under();

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          if (!strFunc.is_empty(tmp_list_opt.select_seq)) {
            setTimeout(() => {
              let list_row_nodes = listAggridRef.current.getNodeRows();
              for (let i = 0; i < list_row_nodes.length; i++) {
                let tmp_row_node = list_row_nodes[i];
                if (tmp_row_node["data"]["a_seq"] == tmp_list_opt.select_seq) {
                  tmp_row_node.setSelected(true);
                }
              }
            }, 100);
          }
        } else {

        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const customCellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_stu_num") {
      render_str = strFunc.uncomma(render_str);
      render_str = strFunc.str_pad({ "str": render_str, "pad_length": user.stu_num_ciphers });
    } else if (key == "row_view_school_grade") {
      let stu_grade_str = "";
      if (xColumnArr.select_arr["a_stu_grade"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
          let tmp_row_opt = xColumnArr.select_arr["a_stu_grade"][i];
          if (tmp_row_opt["value"] == params.data.a_stu_grade) {
            stu_grade_str = tmp_row_opt["text"];
          }
        }
      }
      render_str = (
        <div className="text-center">
          {strFunc.cut_str(params.data.a_school_name, 2, " ")}
          {stu_grade_str}
        </div>
      );
    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_lecture_name") {
      if (params.data.lecture_arr) {
        let lecture_name_arr = [];
        for (let i = 0; i < params.data.lecture_arr.length; i++) {
          lecture_name_arr.push(params.data.lecture_arr[i]["a_name"]);
        }
        render_str = lecture_name_arr.join(" / ");;
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    LectureStuTimeAreaRef.current.setInitData({
      "stu_info_arr": selected_row,
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap" >
        <SearchArea
          listOpt={{ ...listOpt }}
          setListOpt={setListOpt}
          xColumnArr={{ ...xColumnArr }}
          list={list}
        >
          <SearchSubArea
            listOpt={{ ...listOpt }}
            list={list}
          ></SearchSubArea>
        </SearchArea>
        <div className="basic_list_title_bar_wrap">
          {is_under_width == false &&
            <>
              <span>
                총 <span className="text-blue-600">{countInfo.tot}</span> 명
              </span>
              <SearchOrderRadio
                valueTextArr={[
                  { "text": "학년", "value": "a_stu_grade, a_school_name, a_user_name" },
                  { "text": "학교", "value": "a_school_name, a_stu_grade, a_user_name" },
                  { "text": "이름순", "value": "a_user_name" },
                  { "text": "최근등록순", "value": "a_create_date DESC" },
                ]}
                value={listOpt.order_id}
                handleInputChange={(e: any) => {
                  list({
                    "now_page": 1,
                    "order_id": e.target.value
                  });
                }}
              ></SearchOrderRadio>
            </>
          }
          <div className="basic_title_bar_right_box">

          </div>
        </div>
        <table style={{ width: "100%" }}>
          <colgroup>
            <col width={420} />
            <col width={"*"} />
          </colgroup>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <div className="list_round_box">
                  <ListAggrid
                    ref={listAggridRef}
                    infoArr={infoArr}
                    xColumnArr={{ ...xColumnArr }}
                    list={list}
                    gridOpt={{
                      fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                      is_idx_num: true,
                      is_add_checkbox: true,
                      floatingFilter: false,
                      rowMultiSelectWithClick:true,
                      height:(window.innerHeight/5)*4,
                      onGridReady: () => {
                        list({});
                      },
                      onCellClicked: cellClickedListener,
                      cellRenderer: customCellRenderer,
                      onSelectionChanged:onSelectionChanged
                    }}
                  ></ListAggrid>
                  <Paging now_page={listOpt.now_page}
                    num_per_page={listOpt.num_per_page}
                    total_rec={countInfo.tot}
                    onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
                    is_view_num_per_page={true}
                    onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
                </div>
              </td>
              <td style={{ verticalAlign: "baseline", paddingLeft: 10 }}>
                <LectureStuTimeArea
                  ref={LectureStuTimeAreaRef}
                  list={list}
                ></LectureStuTimeArea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default ListPage;