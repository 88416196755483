import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"class_supply",
    list_id:"supply_main_list",
    x_column_list_arr:[
      {"key":"a_stu_name","name":"이름","width":"80","is_show":"1"},
      {"key":"row_view_stu_grade","name":"학년","width":"50","is_show":"1"},
      {"key":"a_date","name":"청구일","width":"90","is_show":"1"},
      {"key":"a_title","name":"수납명","width":"120","is_show":"1"},
      {"key":"a_money","name":"청구액","width":"90","is_show":"1"},
      {"key":"a_discount_money","name":"할인액","width":"90","is_show":"1"},
      {"key":"a_money_sum","name":"청구합계","width":"90","is_show":"1"},
      {"key":"a_pay_money","name":"납부액","width":"120","is_show":"1"},
      {"key":"row_view_unpaid","name":"미납액","width":"90","is_show":"1"},
      {"key":"a_pay_sort","name":"결제수단","width":"90","is_show":"1"},
      {"key":"row_view_pay_btn","name":"결제","width":"120","is_show":"1"},
      {"key":"a_pay_date","name":"수납일","width":"90","is_show":"1"},
      {"key":"a_sort","name":"구분","width":"80","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;