import TodoArrTable from "./todo_arr";

function TodoArea(props:any){
  let myProps={
    style:{},
    stu_info:{},
    select_date:"",
    callback:(inData:any)=>{},
    td_sort_data_arr:[],
    on_change_todo:(inData:any)=>{},
    on_delete_todo:(inData:any)=>{},
    openTodoWriteOnePopup:(inData:any)=>{},
    ...props
  };

  let get_todo_arr_tags=()=>{
    let todo_arr_tags=myProps.td_sort_data_arr.map((sort_data:any,idx:number)=>{
      return (
        <TodoArrTable
          key={idx}
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          callback={myProps.callback}
          style={myProps.style}
          sum_info={sort_data}
          todo_arr={sort_data.todo_info_arr}
          on_change_todo={myProps.on_change_todo}
          on_delete_todo={myProps.on_delete_todo}
          openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
        ></TodoArrTable>
      );
    });
    if(todo_arr_tags==""){
      todo_arr_tags=(
        <div className="text-center mt-5">
          플랜이 없습니다.
        </div>
      );
    }
    return todo_arr_tags;
  };

  return (
    <div>
      {get_todo_arr_tags()}
    </div>
  );
};
export default TodoArea;