import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import DateFunc from '@/lib/lyg/date_func';
import StuSearchList from "./area/stu_list";
import CalendarArea from "./area/calendar";

function MonthPrescribeCalendarPage() {
  const StuSearchListRef = useRef<any>(null);
  const CalendarAreaRef = useRef<any>(null);
  const left_box_ref = useRef<any>(null);
  const cal_box_ref = useRef<any>(null);
  const [s_lecture_seq,set_s_lecture_seq] = useState([]);

  useEffect(() => {
    const height_count_loop=setInterval(()=>{
      if(left_box_ref.current){
        if(StuSearchListRef.current){
          let tmp_height=left_box_ref.current.offsetHeight-160;
          if(tmp_height<650){
            tmp_height=650;
          }
          StuSearchListRef.current.set_height(tmp_height+"px");
        }
      }
    },200);
    return () => {
        clearInterval(height_count_loop);
    };
}, []);

  const go_search_calendar = (inData: any) => {
    let opt_obj = {
      "s_stu_user_seq": [],
      "s_stu_info_arr": [],
      "s_lecture_seq":[],
      ...inData
    };

    if(CalendarAreaRef.current){
      CalendarAreaRef.current.list({
        s_stu_seq:opt_obj["s_stu_user_seq"],
      });
      set_s_lecture_seq(opt_obj.s_lecture_seq);
    }
  };

  const go_search_user_list=(inData: any)=>{
    if(StuSearchListRef.current){
      StuSearchListRef.current.list(inData);
    }
  };

  const onSearchDateChange=(inData: any)=>{
    go_search_user_list({
      "now_page":"1",
      "s_addon_month_prescribe_start_date":DateFunc.get_date_format(DateFunc.get_change_date(new Date(inData),'month',-1),"Y-m-01"),
      "s_addon_month_prescribe_end_date":DateFunc.get_date_format(DateFunc.get_change_date(new Date(inData),'month',+1),"Y-m-t"),
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <div className={Style.wrap}>
          <div className={Style.left_box} ref={left_box_ref}>
            <StuSearchList
              ref={StuSearchListRef}
              list={(inData: any) => { go_search_calendar(inData); }}
            ></StuSearchList>
          </div>
          <div className={Style.cal_box} ref={cal_box_ref}>
            <CalendarArea
              ref={CalendarAreaRef}
              onSearchDateChange={onSearchDateChange}
              go_search_user_list={go_search_user_list}
              s_lecture_seq={s_lecture_seq}
            ></CalendarArea>
          </div>
        </div>
      </div>
    </CompLayout>
  );
};

export default MonthPrescribeCalendarPage;