import { useRef,useEffect } from 'react';
import popupFunc from "@/lib/lyg/popup";
import { useSelector, useDispatch } from 'react-redux';

function CoolenMoveBtn(props: any) {
  let myProps = {
    btn_title:"",
    btn_class_name:"btn btn-sky",
    btn_style:{},
    coolen_sort:"inside",//inside,bank
    ...props
  };
  let user=useSelector((state:any) => state.user);
  let btn_title=myProps.btn_title;
  if(btn_title==""){
    btn_title="브릿지영어 열기";
    if(myProps.coolen_sort=="bank"){
      btn_title="문제은행(그래머스퀘어) 열기";
    }
  }

  const popup_ref = useRef<any>(null);
  let btn_style={
    ...myProps.btn_style
  };

  const open_coolen_login_page = () => {
    var win_w = (window.innerWidth || document.documentElement.clientWidth) - 20;
    var win_h = (window.innerHeight || document.documentElement.clientHeight) - 20;
    let frame_url='/comp/plan/coolen/iframe/login_iframe';
    let url_get_data_str="?coolen_sort="+myProps.coolen_sort;
    let pop_win: any = popupFunc.openPopup({ 
      'url': frame_url+url_get_data_str, 
      'width': win_w, 
      'height': win_h, 
      'pop_name': 'name', 
      'auto_size': false 
    });
    popup_ref.current = pop_win;
    setTimeout(() => {
      move_coolen_url();
    }, 2500);
  };

  const move_coolen_url=()=>{
    let coolen_url=process.env.REACT_APP_COOLEN_URL;
    let coolen_url_path="";
    if(myProps.coolen_sort=="bank"){
      coolen_url=process.env.REACT_APP_COOLENB_URL;
      if(user.user_grade=="student"){
        coolen_url_path="/grammar/student11_1.php";
      }else{
        coolen_url_path="/grammar/menu1.php";
      }
    }
    popup_ref.current.location.href = coolen_url+coolen_url_path;
  };

  return (
    <button className={myProps.btn_class_name} style={btn_style} 
      onClick={() => { open_coolen_login_page(); }}>
      {btn_title}
    </button>
  );
};
export default CoolenMoveBtn;