function TopSearchStateArea(props:any){
   let myProps={
    listOpt:{
      "s_addon_todo_s_state":"",
    },
    list:(inData:any)=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;

  let s_state_opt_arr=[
    {"value":"complete","text":"완료 x","background":"#A2B854"},
    {"value":"ing","text":"진행중 x","background":"#EAB358"},
    {"value":"not_complete","text":"미완료 x","background":"#DB6C58"},
  ];

  const get_state_btn_tags=()=>{
    let state_btn_tags=s_state_opt_arr.map((item:any,idx:number)=>{
      let span_style:any={
        background:"#777"
      };
      if(myProps.listOpt.s_addon_todo_s_state==""){
        span_style["background"]=item.background;
      }
      if(myProps.listOpt.s_addon_todo_s_state==item.value){
        span_style["background"]=item.background;
      }
      return (
        <span key={idx} className={Style.plan_cal_middle_right_state_span+" ml-2"} 
          style={span_style}
          onClick={()=>{
            let change_state_str=item.value;
            if(myProps.listOpt.s_addon_todo_s_state==item.value){
              change_state_str="";
            }
            myProps.list({
              "s_addon_todo_s_state":change_state_str,
              "now_page":"1"
            });
          }}>
          {item.text}
        </span>
      );
    });
    return state_btn_tags;
  };
  
  return (
  <div className={Style.plan_cal_middle_right}>
    {get_state_btn_tags()}
  </div>
  );
};
export default TopSearchStateArea;