import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import ListArea from "./area/list_area";
import WriteArea from "./area/write";
import SearchArea from "./area/search";

const StudyDiary = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_info: {},
    is_view_mode: true,
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    ...props
  };
  let select_mcomp_data= myProps.select_mcomp_data;
  let pre_month_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -2), "Y-m-01");
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "max_limit_num": "100",
    "s_date_type": "a_study_date",
    "order_id": "a_study_date DESC, a_create_date DESC",
    "s_start_date": pre_month_date_str,
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
    "s_stu_seq": myProps.stu_info["a_seq"],
    "s_writer_seq": "",
    "s_addon_user": "1",
    "s_addon_file": "1",
    ...myProps.list_opt
  });
  const [stu_info, set_stu_info] = useState(myProps.stu_info);
  const [info_arr, set_info_arr] = useState([]);
  const [comment_info_arr, set_comment_info_arr] = useState([]);
  const [is_view_write, set_is_view_write] = useState(false);
  let is_view_mode = myProps.is_view_mode;

  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let select_date_time = new Date(myProps.select_date).getTime();
  useEffect(() => {
    list({});
  }, [select_mcomp_data.mcomp_seq]);

  const setInitData = (inData: any) => {
    let opt_obj = {
      stu_info: {},
      ...inData
    };
    set_stu_info(opt_obj.stu_info);
    let tmp_s_list_opt: any = {
      "s_stu_seq": opt_obj["stu_info"]["a_seq"],
      "now_page": "1"
    };
    list(tmp_s_list_opt);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    let tmp_list_form_json = list_form_json;
    if (strFunc.is_empty(tmp_list_form_json["s_stu_seq"])) {
      return false;
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        tmp_list_form_json["api_key"]=select_mcomp_data.api_key;
        tmp_list_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/list', tmp_list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_info_arr(response.data["data"]["info_arr"]);
          get_comment_list({
            info_arr: response.data["data"]["info_arr"]
          });
        } else {

        }
      });
  };
  const get_comment_list = (inData: any) => {
    let opt_obj = {
      s_start_date: listOpt.s_start_date,
      s_end_date: listOpt.s_end_date,
      info_arr: info_arr,
      ...inData
    };
    let tmp_pri_val_arr: any = [];
    for (let i = 0; i < opt_obj["info_arr"].length; i++) {
      let info = opt_obj["info_arr"][i];
      tmp_pri_val_arr.push(info["a_ymd"] + "," + info["a_seq"]);
    }
    if (tmp_pri_val_arr.length == 0) {
      return false;
    }
    let comment_list_from_json:any = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "max_limit_num": "10000",
      "order_id":"a_date, a_create_date",
      "s_start_date": opt_obj.s_start_date,
      "s_end_date": opt_obj.s_end_date,
      "s_par_id": "study_diary",
      "s_par_seq": tmp_pri_val_arr,
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        comment_list_from_json["api_key"]=select_mcomp_data.api_key;
        comment_list_from_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/list', comment_list_from_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div className={Style.diary_wrap}>
      <div>
        학습일지 ({info_arr.length})
        <button className="btn-m2 btn-sky ml-1" onClick={() => { set_is_view_write(!is_view_write) }} >신규</button>
      </div>
      <div>
        {is_view_write &&
          <WriteArea
            select_mcomp_data={{...select_mcomp_data}}
            stu_info={stu_info}
            Style={Style}
            list={(inData: any) => { list(inData); set_is_view_write(false); }}
            set_is_view_write={set_is_view_write}
          ></WriteArea>
        }
      </div>
      <div className="mt-2">
        <SearchArea
          listOpt={listOpt}
          list={list}
        ></SearchArea>
      </div>
      <ListArea
        info_arr={info_arr}
        set_info_arr={set_info_arr}
        list={list}
        Style={Style}
        is_view_mode={is_view_mode}
        comment_list={comment_info_arr}
        refresh_comment={get_comment_list}
      ></ListArea>
    </div>
  );
});
export default StudyDiary;