import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import VideoSectionList from "./area/video_section_list";

const VideoListArea = forwardRef((props:any, ref) => {
  const myProps={
    ...props
  };
  
  let row_data_default_data={
    
  };
  const VideoSectionListRef=useRef<any>(null);
  const [rowData,setRowData]=useState(row_data_default_data);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "rowData":{},
      ...inOptObj
    };
    setRowData({
      ...row_data_default_data,
      ...optObj.rowData
    });
    VideoSectionListRef.current.setInitData({
      section_arr:optObj.rowData.section_arr?optObj.rowData.section_arr:[],
      video_arr:optObj.rowData.video_arr?optObj.rowData.video_arr:[],
    });
  };

  const get_select_video_list=()=>{
    return VideoSectionListRef.current.get_select_video_list();
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_select_video_list
  }));

  return (
    <div>
      <VideoSectionList
        ref={VideoSectionListRef}
      ></VideoSectionList>
    </div>
  );
});

export default VideoListArea;
