import React,{ forwardRef, useEffect, useImperativeHandle, useRef,useState,useCallback } from "react"
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";

const StudentArrComponent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{

    },
    ...props
  };

  const initStudentRowData={
    "a_lecture_seq":"",
    "a_user_seq":"",
    "a_user_name":"",
    "a_user_grade":"student",
    "a_is_main":"",
    "a_state":"ing",
  };
  const [studentArr,setStudentArr]=useState<any[]>([]);
  const [select_stu_seq_arr,set_select_stu_seq_arr]=useState<any>([]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    listOpt:{
      s_except_user_seq:[],
    },
    title:"원생찾기",
    width:"800px",
    height:"85%",
  });

  useEffect(()=>{
    set_select_stu_seq_arr([]);
  },[studentArr]);
  
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getStudentArr,setStudentArr
  }));

  const getStudentArr=()=>{
    return studentArr;
  };

  const openOnePopup=()=>{
    let popListOpt:any={
      s_except_user_seq:[],
    };
    for(let i=0;i<studentArr.length;i++){
      popListOpt.s_except_user_seq.push(studentArr[i]["a_user_seq"]);
    }

    setPopupData({
      ...popupData,
      listOpt:popListOpt,
      "isOpen":true,
    });
  };

  const callBackData=(data:any)=>{
    let optObj={
      info_arr:[],
      row_num:0,
      key:"",
      ...data
    };
    if(optObj.info_arr.length>0){
      let add_stu_arr=[];
      for(let i=0;i<optObj.info_arr.length;i++){
        let user_info=optObj.info_arr[i];
        add_stu_arr.push({
          ...initStudentRowData,
          "a_user_seq":user_info["a_seq"],
          "a_user_name":user_info["a_user_name"],
          "a_user_grade":user_info["a_user_grade"],
          "a_state":"ing",
        });
      }
      if(add_stu_arr.length>0){
        setStudentArr([
          ...studentArr,
          ...add_stu_arr
        ]);
      }
    }
  };

  const onChageInput=(inData:any)=>{
    let opt_obj={
      row_num:0,
      key:"",
      value:"",
      ...inData
    };
    let row_num=opt_obj.row_num;
    let tmp_studentArr=[...studentArr];
    tmp_studentArr[row_num][opt_obj.key]=opt_obj.value;
    setStudentArr(tmp_studentArr);
  };

  const on_check_all=(e:any)=>{
    let is_checked=e.target.checked;
    if(is_checked==false){
      set_select_stu_seq_arr([]);
    }else{
      let tmp_select_stu_seq_arr=[];
      for(let i=0;i<studentArr.length;i++){
        tmp_select_stu_seq_arr.push(studentArr[i]["a_user_seq"]);
      }
      set_select_stu_seq_arr(tmp_select_stu_seq_arr);
    }
  };
  const on_check_select_stu=(inData:any)=>{
    let opt_obj={
      stu_seq:0,
      is_checked:false,
      ...inData
    };
    let is_checked=opt_obj.is_checked;
    let stu_seq=opt_obj.stu_seq;
    
    let tmp_select_stu_seq_arr=[...select_stu_seq_arr];
    if(is_checked){
      //추가
      if(strFunc.str_in_array(stu_seq,tmp_select_stu_seq_arr)==-1){
        tmp_select_stu_seq_arr.push(stu_seq);
      }
    }else{
      //제거
      tmp_select_stu_seq_arr=[];
      for(let i=0;i<select_stu_seq_arr.length;i++){
        if(select_stu_seq_arr[i]!=stu_seq){
          tmp_select_stu_seq_arr.push(select_stu_seq_arr[i]);
        }
      }
    }
    set_select_stu_seq_arr(tmp_select_stu_seq_arr);
  };

  const removeStuBySeq=(stu_seq:number)=>{
    let tmpStudentArr=[];
    let stu_len=studentArr.length;
    for(let i=0;i<stu_len;i++){
      if(studentArr[i].a_user_seq==stu_seq){

      }else{
        tmpStudentArr.push(studentArr[i]);
      }
    }
    setStudentArr(tmpStudentArr);
  };

  const remove_stu_by_checked=()=>{
    let tmpStudentArr=[];
    let stu_len=studentArr.length;
    for(let i=0;i<stu_len;i++){
      if(strFunc.str_in_array(studentArr[i].a_user_seq,select_stu_seq_arr)==-1){
        tmpStudentArr.push(studentArr[i]);
      }
    }
    setStudentArr(tmpStudentArr);
    set_select_stu_seq_arr([]);
  };

  const getStudentTrTags=()=>{
    let studentTags=studentArr.map((item:any,idx:number)=>{
      let is_checked_stu=false;
      if(strFunc.str_in_array(item["a_user_seq"],select_stu_seq_arr)!=-1){
        is_checked_stu=true;
      }
      return (
        <tr key={idx}>
          <td>
            <input type="checkbox" checked={is_checked_stu} value="" 
              onChange={(e:any)=>{
                on_check_select_stu({
                  stu_seq:item.a_user_seq,
                  is_checked:e.target.checked,
                });
              }} />
          </td>
          <td>{idx+1}</td>
          <td>{item.a_user_name}</td>
          <td>
            {/* <select className="row-input" value={item.a_state} onChange={(e:any)=>{
                onChageInput({
                  row_num:idx,
                  key:"a_state",
                  value:e.target.value,
                });
              }} >
              <option value="ing">수강중</option>
              <option value="stop">휴강</option>
              <option value="end">종강</option>
            </select> */}
          </td>
          <td>
            <button className="btn-s btn-gray" onClick={()=>{removeStuBySeq(item.a_user_seq);}} >X</button>
          </td>
        </tr>
      );
    });
    return studentTags;
  };

  return (
    <div>
      <div style={{position:"relative"}}>
        {/* <button className="btn btn-gray" onClick={()=>{openOnePopup();}}
          style={{position:"absolute",right:0,top:0}} >원생찾기</button> */}
        <button className="btn-s2 btn-red" onClick={()=>{remove_stu_by_checked();}} >선택제거</button>
      </div>
      <div className="list-table-div gray_table mt-2" >
        <table style={{width:"auto"}} >
          <colgroup>
            <col width="50px"></col>
            <col width="100px"></col>
            <col width="100px"></col>
            <col width="100px"></col>
          </colgroup>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={on_check_all} />
              </th>
              <th>No</th>
              <th>이름</th>
              <th>상태</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {getStudentTrTags()}
          </tbody>
        </table>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindMultyStudentPopup callBackData={callBackData} listOpt={popupData.listOpt}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindMultyStudentPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default StudentArrComponent;