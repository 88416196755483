import strFunc from "@/lib/lyg/string";
import ListBox from "./list_box";
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ListArea(props:any){
   let myProps={
    stu_info:{},
    info_arr:[],
    set_info_arr:(inData:any)=>{},
    list:(inData:any)=>{},
    set_refresh:(inData:any)=>{},
    comment_list:[],
    refresh_comment:(inData:any)=>{},
    Style:{},
    is_view_mode:false,
    ...props
  };
  let Style=myProps.Style;
  let is_view_mode=myProps.is_view_mode;
  let info_arr=myProps.info_arr;
  const user = useSelector((state: any) => state.user);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  const downloadFormRef = useRef<HTMLFormElement>(null);
  
  useEffect(() => {
    
  }, []);

  const on_change_row_input = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      row_data: {},
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);

    let change_counsel_todo_arr: any = [...info_arr];
    change_counsel_todo_arr[row_num] = {
      ...change_counsel_todo_arr[row_num],
      ...opt_obj["row_data"]
    };

    myProps.set_info_arr(change_counsel_todo_arr);
  };
  const on_change_row_date_picker = (inData: any) => {
    let opt_obj = {
      row_num: -1,
      key: "",
      value: "",
      ...inData
    };
    if (opt_obj.row_num == -1 || opt_obj.key == "") {
      return false;
    }
    let row_num = parseInt(opt_obj["row_num"]);
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let date_str = DateFunc.get_date_format(value, "Y-m-d");

    let change_counsel_todo_arr: any = [...info_arr];
    change_counsel_todo_arr[row_num][key] = date_str;

    myProps.set_info_arr(change_counsel_todo_arr);
  };

  const update_by_ajax = (select_arr: any[]) => {
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let change_data_arr = [];
    for (let i = 0; i < select_arr.length; i++) {
      let info = select_arr[i];
      change_data_arr.push(info);
    }
    if (change_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": change_data_arr,
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_by_ajax=(select_arr: any[])=>{
    if (select_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let del_data_arr = [];
    for (let i = 0; i < select_arr.length; i++) {
      let info = select_arr[i];
      del_data_arr.push({
        "a_ymd":info["a_ymd"],
        "a_seq":info["a_seq"],
      });
    }
    if (del_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    let form_json_data = {
      "data_arr": del_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDownLoad = (file_info: any) => {
    let pri_val = file_info["a_ymd"] + "," + file_info["a_seq"];
    if (downloadFormRef.current == null) {
      return false;
    }
    downloadFormRef.current.action = file_info["a_serverurl"] + "/api/file/download/" + pri_val;
    downloadFormRef.current.submit();
  };
  const goDeleteFile = (file_info_arr: any) => {
    let selected_row = file_info_arr;
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let del_row_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let file_info = selected_row[i];
      del_row_arr.push({
        "a_ymd": file_info["a_ymd"],
        "a_seq": file_info["a_seq"],
      });
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: del_row_arr,
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제 되었습니다.");
        myProps.list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteFileUpload = (row_data: any) => {
    let is_file_exist = false;
    if (row_data.file_obj && row_data.file_obj.files.length>0) {
      for(let i=0;i<row_data.file_obj.files.length;i++){
        if(!strFunc.is_empty(row_data.file_obj.files[i].name)){
          is_file_exist = true;
        }
      }
    }
    if(!confirm("사진을 저장 하시겠습니까?")){
      return false;
    }
    if (is_file_exist == false) {
      alert("파일 선택이 없습니다.");
      return false;
    }
    let row_pri_val = row_data.a_ymd + "," + row_data.a_seq;
    let file_row_arr:any=[];
    for(let i=0;i<row_data.file_obj.files.length;i++){
      file_row_arr.push({
        "a_ymd": row_data.a_ymd,
        "a_seq": "",
        "a_comp_seq": user.comp_seq,
        "a_par_id": "study_diary",
        "a_par_seq": row_pri_val,
        "a_sort1": "image",
        "a_sort2": "",
        "a_sort3": "",
        "a_writer": user.user_name,
        "a_create_seq": user.user_seq,
      });
    }
    var form = row_data.file_obj.parentElement;
    var form_json_data = new FormData(form);
    
    form_json_data.append("data_arr", JSON.stringify(file_row_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        row_data.file_obj.value="";
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_list_tags=()=>{
    let list_length=myProps.info_arr.length;
    let list_tags=myProps.info_arr.map((item:any,idx:number)=>{
      let row_pri_val=item["a_ymd"]+","+item["a_seq"];
      let row_comment_list=[];
      for(let i=0;i<myProps.comment_list.length;i++){
        let row_comment=myProps.comment_list[i];
        if(row_comment["a_par_seq"]==row_pri_val){
          row_comment_list.push(row_comment);
        }
      }
      return (
        <ListBox
          key={idx}
          stu_info={myProps.stu_info}
          row_num={idx}
          list_length={list_length}
          info={item}
          select_subject_option_arr={select_subject_option_arr}
          on_change_row_input={on_change_row_input}
          on_change_row_date_picker={on_change_row_date_picker}
          Style={Style}
          is_view_mode={is_view_mode}
          update_by_ajax={update_by_ajax}
          delete_by_ajax={delete_by_ajax}
          goDownLoad={goDownLoad}
          goDeleteFile={goDeleteFile}
          goWriteFileUpload={goWriteFileUpload}
          comment_list={row_comment_list}
          refresh_comment={myProps.refresh_comment}
          list={myProps.list}
          set_refresh={myProps.set_refresh}
        ></ListBox>
      );
    });

    if (strFunc.is_empty(list_tags)) {
      list_tags = (
        <div style={{ height: 50, textAlign: "center", lineHeight: "50px", color: "#777" }}>
          내용이 없습니다.
        </div>
      );
    }
    return list_tags;
  };
  
  return (
  <div>
    <div>
      {get_list_tags()}
    </div>
    <form ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
  </div>
  );
};
export default ListArea;