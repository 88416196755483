import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";

function ListPage(){
  const xColumnArr=getXcolumnJson();
  let user=useSelector((state:any) => state.user);
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_mcomp_seq":"",
      "s_subject_seq":"",
      "s_addon_subject_title":"1",
      // "s_addon_title_img":"1",
      // "s_addon_section":"1",
      // "s_addon_study":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [groupArr,setGroupArr] = useState<any>([]);
  const [group_seq,set_group_seq] = useState("");
  const [groupLinkArr,setGroupLinkArr] = useState<any>([]);
  const [subjectArr,setsubjectArr] = useState<any>([]);
  const [is_super_comp,set_is_super_comp]=useState(false);
  const [receiver_mcomp_seq,set_receiver_mcomp_seq]=useState("");
  useEffect(() => {
    
    list_group();
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/share/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const list_group = ()=>{
    if(user.comp_seq==""){
      return false;
    }
    let group_list_form_data={
      "s_mcomp_seq":user.comp_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/group/list',group_list_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setGroupArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  const list_group_link = (in_group_seq:any)=>{
    if(in_group_seq==""){
      setGroupLinkArr([]);
      return false;
    }
    let group_list_form_data={
      "s_group_seq":in_group_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/group_link/list',group_list_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setGroupLinkArr(response.data["data"]["info_arr"]);
        set_receiver_mcomp_seq("");
        let tmp_is_super_comp=false;
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          let group_link=response.data["data"]["info_arr"][i];
          if(group_link["a_mcomp_seq"]==user.comp_seq){
            if(group_link["a_is_super"]=="1"){
              tmp_is_super_comp=true;
            }
          }
          
        }
        set_is_super_comp(tmp_is_super_comp);
      }else{
        
      }
    });
  };

  const list_corse_subject = (in_mcomp_seq:any)=>{
    let subject_list_form_data={
      "s_mcomp_seq":in_mcomp_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/share/list_subject',subject_list_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setsubjectArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_of_other=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(pageData.listOpt.s_mcomp_seq==""){
      alert("학원선택없음.");
      return false;
    }
    if(!confirm(selected_row.length+"개의 교재를 가져오시겠습니까?")){
      return false;
    }
    let title_seq_arr=[];
    for(let i=0;i<selected_row.length;i++){
      title_seq_arr.push(selected_row[i]["a_seq"]);
    }

    let share_get_form_data={
      "s_mcomp_seq":pageData.listOpt.s_mcomp_seq,
      "title_seq_arr":title_seq_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/share/get_of_other',share_get_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const send_to_other=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(pageData.listOpt.s_mcomp_seq==""){
      alert("학원 선택없음.");
      return false;
    }
    if(strFunc.is_empty(receiver_mcomp_seq)){
      alert("받는학원 선택없음.");
      return false;
    }
    if(!confirm(selected_row.length+"개의 교재를 보내시겠습니까?")){
      return false;
    }
    let title_seq_arr=[];
    for(let i=0;i<selected_row.length;i++){
      title_seq_arr.push(selected_row[i]["a_seq"]);
    }

    let share_get_form_data={
      "sender_mcomp_seq":pageData.listOpt.s_mcomp_seq,
      "title_seq_arr":title_seq_arr,
      "receiver_mcomp_seq":receiver_mcomp_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/share/send_to_other',share_get_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="search-box">
          그룹
          <select className="search-input_select" value={group_seq}
            onChange={(e:any)=>{
              set_group_seq(e.target.value);
              list_group_link(e.target.value);
            }} >
            <option value="" >선택없음</option>
            {
              groupArr.map((item:any,idx:number)=>{
                return (
                  <option key={idx} value={item.a_seq}>{item.a_name}</option>
                );
              })
            }
          </select>
          학원
          <select className="search-input_select" name="s_mcomp_seq" value={pageData.listOpt.s_mcomp_seq}
            onChange={(e:any)=>{
              list({
                "now_page":1,
                "s_mcomp_seq":e.target.value
              });
              list_corse_subject(e.target.value);
            }} >
            <option value="" >선택없음</option>
            {
              groupLinkArr.map((item:any,idx:number)=>{
                return (
                  <option key={idx} value={item.a_mcomp_seq}>{item.a_mcomp_name}</option>
                );
              })
            }
          </select>
          과목구분
          <select className="search-input_select" name="s_subject_seq" value={pageData.listOpt.s_subject_seq}
            onChange={(e:any)=>{
              list({
                "now_page":1,
                "s_subject_seq":e.target.value
              });
            }} >
            <option value="" >선택없음</option>
            {
              subjectArr&&subjectArr.map((item:any,idx:number)=>{
                return (
                  <option key={idx} value={item.a_seq}>{item.a_title}</option>
                );
              })
            }
          </select>
        </div>
        <div className="btn-box-left relative h-8">
          <button className="btn btn-dark" onClick={()=>{get_of_other();}} >가져오기</button>
        </div>
        <div>
          {(is_super_comp&&user.user_grade=="master")&&
          <div className="mt-2">
            <span style={{fontWeight:"bold",color:"blue"}}>보내기</span>
            <span className="ml-2">
              받을학원
              <select className="search-input_select ml-1" name="receiver_mcomp_seq" value={receiver_mcomp_seq}
                onChange={(e:any)=>{
                  set_receiver_mcomp_seq(e.target.value);
                }} >
                <option value="" >선택없음</option>
                {
                  groupLinkArr.map((item:any,idx:number)=>{
                    return (
                      <option key={idx} value={item.a_mcomp_seq}>{item.a_mcomp_name}</option>
                    );
                  })
                }
              </select>
            </span>
            <button className="btn btn-dark ml-1" onClick={()=>{send_to_other();}} >보내기</button>
          </div>
          }
        </div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{...xColumnArr}}
          list={list}
          gridOpt={{
            fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num:true,
            is_add_checkbox:true,
            floatingFilter:true,
            onGridReady:()=>{
              list({});
            },
            onCellClicked:cellClickedListener,
            cellRenderer:cellRenderer,
          }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
        <p style={{fontSize:14,color:"#ff7d00"}}>※기존 교재의 제목이 가져오는 제목과 같으면 기존데이터에 덮어쓰기 됩니다.</p>
      </div>
    </CompLayout>
  );
}

export default ListPage;