import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

class RoomMsgFunc {
  static default_room_msg_row = {
    room_seq: "",
    desk_num: "",
    desk_name: "",
    stu_seq: "",
    stu_name: "",
    state: "start",
    time_str: "",
    msg: ""
  };//start,end,remain
  static get_msg_arr_by_room_list = (inData: any) => {
    let opt_obj = {
      room_info_arr: [],
      pre_room_info_arr: [],
      room_msg_arr: [],
      now_ymdhis: "",
      ...inData
    };
    let this_obj = this;
    let room_info_arr = opt_obj.room_info_arr;
    let pre_room_info_arr = opt_obj.pre_room_info_arr;
    let room_msg_arr = opt_obj.room_msg_arr;
    let now_ymdhis = opt_obj.now_ymdhis;
    if (strFunc.is_empty(opt_obj.now_ymdhis)) {
      return room_msg_arr;
    }
    let now_date_json = DateFunc.get_date_json(new Date(now_ymdhis));
    if (strFunc.is_empty(now_date_json.Y)) {
      return room_msg_arr;
    }

    let now_sec = now_date_json.date_obj.getTime() / 1000;
    let pre_sec = now_sec - 1;

    //desku_arr
    let now_desku_arr: any = [];
    let pre_desku_arr: any = [];
    for (let room_i = 0; room_i < room_info_arr.length; room_i++) {
      let room_info = room_info_arr[room_i];
      let desku_arr: any = [];
      if (room_info.desku_arr) {
        desku_arr = room_info.desku_arr;
      }
      for (let i = 0; i < desku_arr.length; i++) {
        let desku_info = desku_arr[i];
        let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
        let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
        let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        if (!strFunc.is_empty(desku_info["a_end_date"])) {
          end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        }
        let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

        let start_sec = start_date_json.date_obj.getTime() / 1000;
        let end_sec = end_date_json.date_obj.getTime() / 1000;
        
        if (start_sec <= now_sec && now_sec < end_sec) {
          now_desku_arr.push(desku_info);
        }
      }
    }
    for (let room_i = 0; room_i < pre_room_info_arr.length; room_i++) {
      let room_info = pre_room_info_arr[room_i];
      let desku_arr: any = [];
      if (room_info.desku_arr) {
        desku_arr = room_info.desku_arr;
      }
      for (let i = 0; i < desku_arr.length; i++) {
        let desku_info = desku_arr[i];
        let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
        let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
        let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        if (!strFunc.is_empty(desku_info["a_end_date"])) {
          end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
        }
        let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

        let start_sec = start_date_json.date_obj.getTime() / 1000;
        let end_sec = end_date_json.date_obj.getTime() / 1000;

        if (start_sec <= pre_sec && pre_sec < end_sec) {
          pre_desku_arr.push(desku_info);
        }
      }
    }

    let added_desku_arr: any = [];
    let removed_desku_arr: any = [];
    for (let i = 0; i < now_desku_arr.length; i++) {
      let desku_info = now_desku_arr[i];
      let is_exist = false;
      for (let j = 0; j < pre_desku_arr.length; j++) {
        let pre_desku_info = pre_desku_arr[j];
        if (desku_info["a_ymd"] == pre_desku_info["a_ymd"]
          && desku_info["a_seq"] == pre_desku_info["a_seq"]
        ) {
          is_exist = true;
        }
      }
      if (!is_exist) {
        added_desku_arr.push(desku_info);
      }
    }
    for (let i = 0; i < pre_desku_arr.length; i++) {
      let pre_desku_info = pre_desku_arr[i];
      let is_exist = false;
      for (let j = 0; j < now_desku_arr.length; j++) {
        let desku_info = now_desku_arr[j];
        if (desku_info["a_ymd"] == pre_desku_info["a_ymd"]
          && desku_info["a_seq"] == pre_desku_info["a_seq"]
        ) {
          is_exist = true;
        }
      }
      if (!is_exist) {
        removed_desku_arr.push(pre_desku_info);
      }
    }

    //메세지추가
    let tmp_time_str = DateFunc.get_date_format(new Date(now_ymdhis), "h:i");
    for (let i = 0; i < added_desku_arr.length; i++) {
      let desku_info = added_desku_arr[i];
      let start_time_str = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
      let desk_name = "";
      if (desku_info["a_name"]) {
        desk_name = desku_info["a_name"];
      }
      let add_room_msg_row: any = {
        ...this_obj.default_room_msg_row,
        room_seq: desku_info["a_room_seq"],
        desk_num: desku_info["a_x"] + "," + desku_info["a_y"],
        desk_name: desk_name,
        stu_seq: desku_info["a_user_seq"],
        stu_name: desku_info["a_user_name"],
        state: "start",
        time_str: start_time_str,
        msg: (
          <span>
            {start_time_str}
            <span className="mx-1" style={{ color: "green", fontWeight: "bold" }}>{desku_info["a_user_name"]}</span>
            학생 시작하였습니다.
            {desk_name && <span>({desk_name})</span>}
          </span>
        )
      };
      let is_exist = this_obj.is_exist_room_msg_row_of_arr({
        room_msg_arr: room_msg_arr,
        add_room_msg_row: add_room_msg_row
      });
      if (!is_exist) {
        room_msg_arr.push(add_room_msg_row);
      }
    }
    for (let i = 0; i < removed_desku_arr.length; i++) {
      let desku_info = removed_desku_arr[i];
      let desk_name = "";
      if (desku_info["a_name"]) {
        desk_name = desku_info["a_name"];
      }
      let add_room_msg_row: any = {
        ...this_obj.default_room_msg_row,
        room_seq: desku_info["a_room_seq"],
        desk_num: desku_info["a_x"] + "," + desku_info["a_y"],
        desk_name: desk_name,
        stu_seq: desku_info["a_user_seq"],
        stu_name: desku_info["a_user_name"],
        state: "end",
        time_str: tmp_time_str,
        msg: (
          <span>
            {tmp_time_str}
            <span className="mx-1" style={{ color: "green", fontWeight: "bold" }}>{desku_info["a_user_name"]}</span>
            학생 종료됐습니다.
            {desk_name && <span>({desk_name})</span>}
          </span>
        )
      };
      let is_exist = this_obj.is_exist_room_msg_row_of_arr({
        room_msg_arr: room_msg_arr,
        add_room_msg_row: add_room_msg_row
      });
      if (!is_exist) {
        room_msg_arr.push(add_room_msg_row);
      }
    }

    return room_msg_arr;
  };
  static is_exist_room_msg_row_of_arr = (inData: any) => {
    let opt_obj = {
      room_msg_arr: [],
      add_room_msg_row: {},
      ...inData
    };
    let is_exist = false;
    let room_msg_arr = opt_obj.room_msg_arr;
    let add_room_msg_row = opt_obj.add_room_msg_row;
    for (let i = 0; i < room_msg_arr.length; i++) {
      let room_msg = room_msg_arr[i];
      if (room_msg["room_seq"] == add_room_msg_row["room_seq"]
        && room_msg["desk_num"] == add_room_msg_row["desk_num"]
        && room_msg["stu_seq"] == add_room_msg_row["stu_seq"]
        && room_msg["state"] == add_room_msg_row["state"]
        && room_msg["time_str"] == add_room_msg_row["time_str"]
      ) {
        is_exist = true;
      }
    }
    return is_exist;
  };
}
export default RoomMsgFunc;