import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"video_category",
    list_id:"video_category_list",
    x_column_list_arr:[
      {"key":"row_view_receive","name":"구분","width":"100","is_show":"1"},
      {"key":"a_title","name":"제목","width":"140","is_show":"1"},
      {"key":"row_view_sender","name":"보낸이","width":"100","is_show":"1"},
      {"key":"row_view_receiver","name":"받는이","width":"100","is_show":"1"},
      {"key":"a_is_read","name":"확인","width":"100","is_show":"1"},
      {"key":"a_send_date","name":"보낸날짜","width":"100","is_show":"1"},
      {"key":"a_read_date","name":"확인날짜","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;