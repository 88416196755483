import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"eval",
    list_id:"eval_his_list",
    x_column_list_arr:[
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_target_name","name":"대상","width":"100","is_show":"1"},
      {"key":"a_target_detail","name":"상세","width":"100","is_show":"1"},
      {"key":"a_stu_name","name":"학생","width":"100","is_show":"1"},
      {"key":"a_date","name":"평가일","width":"100","is_show":"1"},
      {"key":"a_subject_name","name":"과목","width":"100","is_show":"1"},
      {"key":"a_title","name":"제목","width":"100","is_show":"1"},
      {"key":"a_average_score","name":"평균점수","width":"100","is_show":"1"},
      {"key":"a_item_amout","name":"항목수","width":"100","is_show":"1"},
      {"key":"a_writer_name","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"150","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      
      "s_user_name_like":"",
      "s_school_name_like":"",
      "s_class_name_like":"",
      "s_stu_grade":"",

      "s_subject_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr["a_target_id"]=[
    {"value":"lecture","text":"강의"},
    {"value":"study","text":"수업"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;