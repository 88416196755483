import { useState, useRef, useEffect } from 'react';
import Style from "../../../../css/style.module.css";

const CardBoxTodoPerArea = (props:any) => {
  let myProps:any={
    user_info:{},
    todo_stat_data:{},
    todo_color:"green",
    ...props
  };

  let count_div_str:any="";
  if(myProps.todo_stat_data["total"]["count"]){
    count_div_str=(
      <div className="text-center" style={{lineHeight:"8px",position:"absolute",right:10,top:10,color:"12px"}}>
        과제: {myProps.todo_stat_data["total"]["success_cnt"]}
        /
        {myProps.todo_stat_data["total"]["count"]}
      </div>
    );
  }

  return (
    <div className={Style.card_box_todo}>
      {count_div_str}
      <table>
        <colgroup>
          <col width={"30%"}></col>
          <col width={"70%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th style={{whiteSpace:"nowrap"}}>
              학습({myProps.todo_stat_data.study.percent}%)
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar} 
                  style={{width:myProps.todo_stat_data.study.percent+"%",background:myProps.todo_color}}></div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              강의({myProps.todo_stat_data.video.percent}%)
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{width:myProps.todo_stat_data.video.percent+"%",background:myProps.todo_color}}></div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              과제({myProps.todo_stat_data.homework.percent}%)
            </th>
            <td>
              <div className={Style.card_box_todo_td_per_wrap}>
                <div className={Style.card_box_todo_td_per_back}></div>
                <div className={Style.card_box_todo_td_per_bar}
                  style={{width:myProps.todo_stat_data.homework.percent+"%",background:myProps.todo_color}}></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CardBoxTodoPerArea;