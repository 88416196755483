function ControlBarArea(props:any){
   let myProps={
    ListComponentRef:null,
    todo_write_arr:[],
    set_todo_write_arr:(inData:any)=>{},
    ...props
  };
  let ListComponentRef=myProps.ListComponentRef;

  const removeSelect=()=>{
    let all_row=ListComponentRef.current.getRows();
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      return false;
    }
    let remain_row_arr=[];
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_is_exist=false;
      for(let j=0;j<selected_row.length;j++){
        let tmp_sel_row=selected_row[j];
        if(tmp_row.row_num==tmp_sel_row.row_num){
          tmp_is_exist=true;
        }
      }
      if(tmp_is_exist==false){
        remain_row_arr.push(tmp_row);
      }
    }
    ListComponentRef.current.setInfoArr(remain_row_arr);
  };

  
  return (
  <div className="my-1">
    <button className="btn-m btn-red ml-1" onClick={()=>{removeSelect();}} >제외</button>
  </div>
  );
};
export default ControlBarArea;