import React,{ useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindMultyStudentPopup from '@/pages/comp/basic/student/popup/find_multy';
import ListTagArea from "./area/list_tag";

const GradeUserArea = forwardRef((props:any, ref) => {
  const myProps={
    set_grade_arr:(grade_arr:any)=>{},
    set_grade_user_arr:(grade_user_arr:any)=>{},
    ...props
  };
  const ListTagAreaRef=useRef<any>(null);
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);

  const default_row_data={
    "a_routine_seq":"",
    "a_grade_seq":"",
    "a_user_seq":"",
    "a_user_name":"",
    "stu_info":"",
  };
  const [grade_arr,set_grade_arr]=useState<any>([]);
  const [select_grade_seq,set_select_grade_seq]=useState("");
  const [grade_user_arr,set_grade_user_arr]=useState<any>([]);
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "grade_arr":[],
      "grade_user_arr":[],
      "isUpdate":false,
      ...inOptObj
    };
    set_grade_arr(optObj.grade_arr);
    if(optObj.grade_arr.length>0){
      set_select_grade_seq(optObj.grade_arr[0].a_seq);
    }
    set_grade_user_arr(optObj.grade_user_arr);
    setIsUpdate(optObj.isUpdate);
    if(ListTagAreaRef.current){
      ListTagAreaRef.current.setInitData({
        "grade_arr":optObj.grade_arr,
        "grade_user_arr":optObj.grade_user_arr,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    title:"팝업",
    width:"800px",
    height:"80%",
    listOpt:{
      s_except_user_seq:"",
    },
    row_num:0,
    key:"",
  });

  const openInvitePopup=(in_grade_seq:any)=>{
    set_select_grade_seq(in_grade_seq);
    if(in_grade_seq==""){
      alert("등급 선택 없음.");
      return false;
    }
    let pop_title="학생초대";
    let s_except_user_seq:any=[];
    for(let i=0;i<grade_user_arr.length;i++){
      //if(grade_user_arr[i].a_grade_seq==in_grade_seq){
        s_except_user_seq.push(grade_user_arr[i].a_user_seq);
      //}
    }

    setPopupData({
      ...popupData,
      ...{
        "isOpen":true,
        "title":pop_title,
        "sort":"find",
        listOpt:{
          s_except_user_seq:s_except_user_seq,
        },
      }
    });
  };

  const inviteStuUsers=(user_arr:any)=>{
    if(select_grade_seq==""){
      alert("등급 선택 없음.");
      return false;
    }
    let tmp_grade_user_arr:any=[...grade_user_arr];
    for(let i=0;i<user_arr.length;i++){
      let user_info=user_arr[i];
      let is_exist_pre=false;
      for(let j=0;j<grade_user_arr.length;j++){
        if(grade_user_arr[j]["a_grade_seq"]==select_grade_seq
          &&grade_user_arr[j]["a_user_seq"]==user_info["a_seq"]){
            is_exist_pre=true;
        }
      }

      if(is_exist_pre){
        alert("이미 있습니다.");
        continue;
      }

      let addRow:any={...default_row_data};
      addRow["a_grade_seq"]=select_grade_seq;
      addRow["a_user_seq"]=user_info["a_seq"];
      addRow["a_user_name"]=user_info["a_user_name"];
      addRow["stu_info"]=user_info;
      tmp_grade_user_arr.push(addRow);
    }
    set_grade_user_arr(tmp_grade_user_arr);
    myProps.set_grade_user_arr(tmp_grade_user_arr);
    if(ListTagAreaRef.current){
      ListTagAreaRef.current.setInitData({
        "grade_arr":grade_arr,
        "grade_user_arr":tmp_grade_user_arr,
      });
    }
  };

  return (
    <div>
      <ListTagArea
        ref={ListTagAreaRef}
        openInvitePopup={(inData:any)=>{
          openInvitePopup(inData);
        }}
        set_grade_arr={(inData:any)=>{
          set_grade_arr(inData);
          myProps.set_grade_arr(inData);
        }}
        set_grade_user_arr={(inData:any)=>{
          set_grade_user_arr(inData);
          myProps.set_grade_user_arr(inData);
        }}
      ></ListTagArea>

      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindMultyStudentPopup 
              listOpt={popupData.listOpt}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             callBackData={(data:any)=>{
                //data = { info_arr:[],row_num:0,key:"" };
                inviteStuUsers(data.info_arr);
             }}
             ></FindMultyStudentPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default GradeUserArea;
