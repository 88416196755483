import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import Style from "./css/style.module.css";
import StuArea from "./area/stu_area";
import TodoContent from "./area/todo_content";
import SelectDateArea from "./area/select_date";
import TodoArrFunc from "./func/todo_arr_func";

function WriteTodoArrPopup(props: any) {
  let myProps = {
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    stu_seq_arr: [],
    calback: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [stu_info_arr, set_stu_info_arr] = useState([]);
  const [select_date, set_select_date] = useState(myProps.select_date);
  const [select_date_arr, set_select_date_arr] = useState([]);
  const [able_days_of_week, set_able_days_of_week] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [todo_data, set_todo_data] = useState({
    a_sort: "homework",//study
    a_success_sort: "amount",
    a_amount_sort: "개",
    a_success_amount: 10,
    a_subject: "",
    a_title: "할일",
    a_content: "",
  });
  const [refresh, set_refresh] = useState(false);
  let next_month_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps.select_date), 'month', +1), "Y-m-d");

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_stu",//find_stu
    row_num: 0,
    key_str: "",
    s_except_user_seq:[],
    has_header: true,
    title: "학생찾기",
    width: "500px",
    height: "100%",
  });

  useEffect(() => {
    get_stu_info_arr();
  }, []);

  const get_stu_info_arr = () => {
    let search_stu_seq_arr = [];
    if (myProps.stu_seq_arr) {
      for (let i = 0; i < myProps.stu_seq_arr.length; i++) {
        if (!strFunc.is_empty(myProps.stu_seq_arr[i])) {
          search_stu_seq_arr.push(myProps.stu_seq_arr[i]);
        }
      }
    }

    if (strFunc.is_empty(search_stu_seq_arr)) {
      return false;
    }

    let list_form_json = {
      "s_pri_arr": search_stu_seq_arr,
      "s_addon_stu_grade_name": "1",
      "order_id": "a_stu_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_info_arr(response.data["data"]["info_arr"]);
        } else {
          alert("검색 중 오류." + response.data["msg"]);
          props.closePopup();
        }
      });
  };

  const goWriteTodo = () => {
    if (stu_info_arr.length == 0) {
      alert("선택 학생이 없습니다.");
      return false;
    }
    if (select_date_arr.length == 0) {
      alert("날짜 선택이 필요합니다.");
      return false;
    }
    if (strFunc.is_empty(todo_data.a_success_amount)) {
      alert("분량이 없습니다.");
      return false;
    }
    if (strFunc.is_empty(todo_data.a_title)) {
      alert("제목이 없습니다.");
      return false;
    }

    if (!confirm("맞춤플랜을 작성 하시겠습니까?")) {
      return false;
    }

    let todo_arr = TodoArrFunc.get_todo_arr_by_data({
      stu_info_arr: stu_info_arr,
      select_date_arr: select_date_arr,
      todo_data: todo_data,
      user: user
    });

    if (todo_arr.length == 0) {
      alert("등록 할 내용이 없습니다.");
      return false;
    }

    let write_form_json = {
      "data_arr": todo_arr,
      "is_default_val": "1",
      "is_update": "",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          myProps.calback({});
          props.closePopup();
        } else {
          alert("등록 중 오류." + response.data["msg"]);
          props.closePopup();
        }
      });
  };

  const open_find_stu_popup = (inData: any) => {
    let opt_obj = {
      ...inData
    };
    let s_except_user_seq:any=[];
    for(let i=0;i<stu_info_arr.length;i++){
      let stu_info=stu_info_arr[i];
      s_except_user_seq.push(stu_info["a_seq"]);
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "find_stu",
      title: "학생찾기",
      s_except_user_seq:s_except_user_seq,
      ...opt_obj
    });
  };

  const callback_stu_find_popup=(inData: any)=>{
    let optObj = {
      info_arr: [],
      row_num: 0,
      key_str: "",
      ...inData,
    };
    let tmp_stu_info_arr:any=[...stu_info_arr];
    let pre_stu_seq_arr:any=[];
    for(let i=0;i<tmp_stu_info_arr.length;i++){
      pre_stu_seq_arr.push(tmp_stu_info_arr[i]["a_seq"]);
    }
    //추가
    for(let i=0;i<optObj.info_arr.length;i++){
      let stu_info:any=optObj.info_arr[i];
      if(strFunc.str_in_array(stu_info["a_seq"],pre_stu_seq_arr)==-1){
        tmp_stu_info_arr.push(stu_info);
      }
    }
    set_stu_info_arr(tmp_stu_info_arr);
  };

  return (
    <div className={Style.popup_wrap}>
      <div className={Style.sub_title}>
        학생
        <button className="ml-1 btn-m btn-dark" onClick={() => { open_find_stu_popup({}); }}>찾기</button>
      </div>
      <StuArea
        stu_info_arr={stu_info_arr}
        set_stu_info_arr={set_stu_info_arr}
        Style={Style}
      ></StuArea>
      <div className={Style.sub_title}>
        내용
      </div>
      <TodoContent
        todo_data={todo_data}
        set_todo_data={set_todo_data}
        Style={Style}
      ></TodoContent>
      <div className={Style.sub_title}>
        날짜
        <span style={{ color: "#999", marginLeft: 5 }}>
          ({select_date_arr.length}개 선택됨)
        </span>
        <button className="btn-s btn-dark ml-1" onClick={() => { set_select_date_arr([]); }}>선택초기화</button>
        <span className="ml-1">요일</span>
        <span style={{ display: "inline-block", marginLeft: "5px" }}>
          <SelectBoxBar
            valueTextArr={DateFunc.get_select_days_of_week()}
            value={able_days_of_week.join(",")}
            on_change={(inData: any) => {
              set_able_days_of_week(inData.value.split(","));
            }}
            is_arr_value={true}
          ></SelectBoxBar>
        </span>
      </div>
      <SelectDateArea
        select_date={select_date}
        select_date_arr={select_date_arr}
        set_select_date_arr={(inData: any) => {
          set_select_date_arr(inData);
          set_refresh(!refresh);
        }}
        able_days_of_week={able_days_of_week}
        Style={Style}
      ></SelectDateArea>
      <div className="mt-1">
        <SelectDateArea
          select_date={next_month_date_str}
          select_date_arr={select_date_arr}
          set_select_date_arr={(inData: any) => {
            set_select_date_arr(inData);
            set_refresh(!refresh);
          }}
          able_days_of_week={able_days_of_week}
          Style={Style}
        ></SelectDateArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={() => { goWriteTodo(); }}>맞춤플랜작성</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
          {popupData.sort == "find_stu" &&
            <FindMultyStudentPopup
              listOpt={{
                s_except_user_seq:popupData.s_except_user_seq,
              }}
              row_num={popupData.row_num}
              key_str={popupData.key_str}
              is_multi_select_by_click={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={callback_stu_find_popup}
            ></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default WriteTodoArrPopup;