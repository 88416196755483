function TopArea(props:any){
   let myProps={
    Style:{},
    todo_info:{},
    on_click_back:()=>{},
    ...props
  };
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;
  let sort_str="학습";
  if(todo_info["a_sort"]=="homework"){
    sort_str="과제";
  }

  return (
    <div className={Style.top_wrap}>
      <div className={Style.top_wrap_back_btn} onClick={myProps.on_click_back}>뒤로</div>
      {sort_str}
    </div>
  );
};
export default TopArea;