import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import popupFunc from "@/lib/lyg/popup";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../xcolumn/list";
import TabArea from "./area/tab_area";
import WriteArea from "./area/write";
import GradeUserArea from "./area/grade_user";

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  const xColumnArr=getXcolumnJson();
  let user=useSelector((state:any) => state.user);
  const WriteAreaRef=useRef<any>(null);
  const GradeUserAreaRef=useRef<any>(null);

  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const [active_tab,set_active_tab]=useState("");
  if(isUpdate){
    pageData["write_btn_text"]="저장";
    pageData["confirm_msg"]="저장 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_title":"",
    "a_grade_sort":"grade",
    "a_memo":"",
    "a_except_day":"",
    "a_is_reserve_time":"",
    "a_reserve_start_time":"",
    "a_reserve_end_time":"",
    "grade_arr":[],
    "grade_user_arr":[],
    "routine_link_arr":[],
  };
  const [rowData,setRowData]=useState<any>({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
    if(WriteAreaRef.current){
      WriteAreaRef.current.setInitData({
        "row_data":optObj["row_data"],
        "isUpdate":optObj.isUpdate
      });
    }
    if(GradeUserAreaRef.current){
      let tmp_grade_arr=optObj["row_data"]["grade_arr"]?optObj["row_data"]["grade_arr"]:[];
      let tmp_grade_user_arr=optObj["row_data"]["grade_user_arr"]?optObj["row_data"]["grade_user_arr"]:[];
      GradeUserAreaRef.current.setInitData({
        "grade_arr":tmp_grade_arr,
        "grade_user_arr":tmp_grade_user_arr,
        "isUpdate":optObj.isUpdate,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    let chage_data_json:any={
      [name]: value
    };
    
    setRowData({
      ...rowData,
      ...chage_data_json
    });
  };


  const goWrite=() => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};
    let formJsonData={
      "row_data":wRowData,
      "is_update":"",
      "grade_arr":rowData.grade_arr,
      "grade_user_arr":rowData.grade_user_arr,
      "routine_link_arr":rowData.routine_link_arr,
    };
    if(isUpdate){
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/routine/routine/write_one',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("저장 되었습니다.");
        let tmp_routine_info=response.data["data"]["routine_info"];
        myProps.list({select_seq:tmp_routine_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <TabArea
        isUpdate={isUpdate}
        active_tab={active_tab}
        onChangeTab={(tab_id:string)=>{
          set_active_tab(tab_id);
          setInitData({
            "row_data":rowData,
            "isUpdate":isUpdate,
          });
        }}
      ></TabArea>
      <div>
        <div className={((active_tab=="write"||active_tab=="")?"":"hidden")}>
          <WriteArea
            ref={WriteAreaRef}
            setRowData={(inData:any)=>{
              setRowData({
                ...rowData,
                ...inData
              });
            }}
          ></WriteArea>
        </div>
        <div className={((active_tab=="grade_user"||active_tab=="")?"":"hidden")}>
          <GradeUserArea
            ref={GradeUserAreaRef}
            set_grade_arr={(inData:any)=>{
              setRowData({
                ...rowData,
                ...{
                  grade_arr:inData
                }
              });
            }}
            set_grade_user_arr={(inData:any)=>{
              setRowData({
                ...rowData,
                ...{
                  grade_user_arr:inData
                }
              });
            }}
          ></GradeUserArea>
        </div>
      </div>
      <div className="text-center mt-5">
        <button className="btn btn-dark" onClick={()=>{goWrite();}}>{isUpdate?"수정":"등록"}</button>
      </div>
    </div>
  );
});

export default WriteFrameContent;
