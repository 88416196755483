import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"stu_multy_list",
    x_column_list_arr:[
      {"key":"a_stu_name","name":"학생명","width":"80","is_show":"1"},
      {"key":"a_reserve_date","name":"예정일","width":"80","is_show":"1"},
      {"key":"a_title","name":"To do list","width":"150","is_show":"1"},
      {"key":"a_is_success","name":"완료","width":"50","is_show":"1"},
      {"key":"a_owner_mcomp_user_seq","name":"선생님","width":"80","is_show":"1"},
      {"key":"a_public_sort","name":"공개","width":"70","is_show":"1"},
      {"key":"a_subject_name","name":"과목","width":"70","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"150","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_is_success, a_reserve_date DESC, a_create_date DESC',
      'order_type':'',
      's_date_type':'a_reserve_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  initData.date_col_arr=["a_reserve_date"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.select_arr["a_is_success"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];
  xColumnArr.select_arr["a_public_sort"]=[
    {"value":"all","text":"전체"},
    {"value":"manage","text":"나,관리자"},
    {"value":"iam","text":"나만"},
  ];
  xColumnArr.select_arr["a_subject_name"]=[
    {"value":"","text":"선택없음"},
    ...XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'})
  ];

  return xColumnArr;
};

export default getXcolumnJson;