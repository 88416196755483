import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"make_todo_stu_list",
    x_column_list_arr:[
      {"key":"a_user_name","name":"학생명","width":"80","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"50","is_show":"1"},
      {"key":"a_school_name","name":"학교","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;