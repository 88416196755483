class DefaultTableData
{
  static default_td_data_row = {
    lecture_seq: "",
    name:"",
    start_time_m: 0,
    end_time_m: 0,
    time_str: "00:00~00:00",
    start_h: "",
    start_m: "",
    end_h: "",
    end_m: "",
    range_start_time_m:0,
    range_end_time_m:0,
    range_time_str:"00:00~00:00",
    room_seq: "",
    room_name: "",
    teacher_seq: "",
    teacher_name: "",
    day_num: "",
    day_num_arr: [],
    day_num_arr_str: "",
    row_span: 1,
    col_span: 1,
    col_tot_cnt:1,
    stu_union_time_arr: [],
  };
}
export default DefaultTableData;