import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "../../css/style.module.css";
import strFunc from '@/lib/lyg/string';

const ContentArea=forwardRef((props:any, ref) => {
  let myProps:any={
    row_data:{},
    ...props
  };
  
  const [row_data,set_row_data]= useState<any>(myProps.row_data);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const setInitData=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
     row_data:{},
     ...inData
    }

    set_row_data(optObj.row_data);
  };

  let time_sec_json:any=strFunc.secondsToTimeJson(strFunc.uncomma(row_data.a_time_sec));
  let success_time_json:any=strFunc.secondsToTimeJson(strFunc.uncomma(row_data.a_success_time_sec));
  let success_amount_str="";
  if(row_data.a_success_amount){
    success_amount_str=strFunc.comma(row_data.a_success_amount)+" "+row_data.a_amount_sort;
  }

  let amount_num_str=strFunc.comma(row_data.a_amount_num);

  return (
    <div >
      <div className="list-table-div">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            {row_data.a_success_sort=="time"?
            <>
            <tr>
              <th>목표시간</th>
              <td>
                {success_time_json.h}:{success_time_json.i}:{success_time_json.s} <span className="ml-2" style={{color:"#ccc"}}>(시:분:초)</span>
              </td>
            </tr>
            <tr>
              <th>진행시간</th>
              <td>
                {time_sec_json.h}:{time_sec_json.i}:{time_sec_json.s}
              </td>
            </tr>
            <tr>
              <th>분량</th>
              <td>
                {success_amount_str}
              </td>
            </tr>
            </>
            :
            <tr>
              <th>분량</th>
              <td>
                {amount_num_str}/{success_amount_str}
              </td>
            </tr>
            }
            
            <tr>
              <th>내용</th>
              <td>
                {
                  row_data.a_content && row_data.a_content.split('\n').map( (line:string,idx:number) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>시작</th>
              <td>
                {row_data.a_start_date}
              </td>
            </tr>
            <tr>
              <th>종료</th>
              <td>
              {row_data.a_end_date}
              </td>
            </tr>
            {(row_data.a_is_reserve&&row_data.a_reserve_start_date)&&
            <tr>
              <th>예정일</th>
              <td>
                {row_data.a_reserve_start_date.substring(0,10)}~{row_data.a_reserve_end_date.substring(0,10)}
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default ContentArea;