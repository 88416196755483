//import MonthPrescribeListFunc from "@/pcomponents/common/content/edu/month_prescribe/list/list_func";
import LygCalendarFunc from '@/lib/lyg/calendar';
class MonthPrescribeListFunc {
  //몇주차 얻기
  static get_list_week_i_by_date(inData: any) {
    let opt_obj = {
      info_arr: [],
      select_date: "",
      ...inData
    };
    let info_arr = opt_obj["info_arr"];
    let select_date=opt_obj.select_date;
    let cal_data = LygCalendarFunc.get_cal_data_by_select_date(select_date);
    let td_data_arr = LygCalendarFunc.get_td_data_arr({ cal_data: cal_data });
    let td_week_data_arr = LygCalendarFunc.get_td_week_data_arr({
      cal_data: cal_data,
      td_data_arr: td_data_arr,
      standard_day_of_week: 1,
    });
    for (let i = 0; i < info_arr.length; i++) {
      let info = info_arr[i];
      info_arr[i]["week_i"] = -1;
      info_arr[i]["month_num"] = cal_data.select_month_json.m;
      for (let week_i = 0; week_i < td_week_data_arr.length; week_i++) {
        if (td_week_data_arr[week_i].start_date <= info["a_reserve_date"]
          && info["a_reserve_date"] <= td_week_data_arr[week_i].end_date
        ) {
          info_arr[i]["week_i"] = week_i;
        }
      }
    }

    return info_arr;
  }
  //마지막커멘트가 내가쓴게 아니면 위로올리기,완료가아니고.
  static get_list_order_by_comment_arr(inData: any) {
    let opt_obj = {
      info_arr: [],
      iam_user_seq: "",
      ...inData
    };
    let info_arr = opt_obj["info_arr"];
    let iam_user_seq = opt_obj.iam_user_seq;
    let new_info_arr = [];
    let etc_info_arr = [];
    for (let i = 0; i < info_arr.length; i++) {
      let info = info_arr[i];
      let is_iam_comment = false;
      if (info.comment_arr && info.comment_arr.length > 0) {
        let comment_last_idx = info.comment_arr.length - 1;
        let comment_last_info = info.comment_arr[comment_last_idx];
        if (comment_last_info["a_responser_seq"] != iam_user_seq) {
          is_iam_comment = true;
        }
      }
      if (info["a_is_success"] == "1") {
        is_iam_comment = false;
      }
      if (is_iam_comment) {
        new_info_arr.push(info);
      } else {
        etc_info_arr.push(info);
      }
    }
    info_arr = [
      ...new_info_arr,
      ...etc_info_arr
    ];
    return info_arr;
  }
}
export default MonthPrescribeListFunc;