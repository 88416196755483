import React from "react"
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";
import StuTimeBox from "./stu_time_box";

function TimeListArea(props: any) {
  let myProps = {
    rowData: {},
    dayNumArr:[],
    initLectureTimeRowData:{},
    timeInfoArr: [],
    setTimeInfoArr: (inData: any) => { },
    get_copy_of_same_days_week_data:(inTimeInfoArr:any[])=>{},
    is_same_days_week: false,
    same_days_week_arr: [],
    teacher_arr: [],
    room_info_arr: [],

    user_time_arr:[],
    set_user_time_arr:[],
    studentArr:[],
    show_stu_day_time_arr:[],
    set_show_stu_day_time_arr:(inData:any)=>{},
    is_show_stu_day_time_all:false,
    open_find_stu_popup:(inData:any)=>{},
    ...props
  };
  let timeInfoArr = myProps.timeInfoArr;
  let is_same_days_week = myProps.is_same_days_week;
  let same_days_week_arr = myProps.same_days_week_arr;
  let dayNumArr = myProps.dayNumArr;

  const onChangeTimeRowInput = (timeRowData: any, key: string, val: string) => {
    let tmpTimeInfoArr: any[] = [...timeInfoArr];
    let time_col_arr = [
      "a_start_h", "a_start_m",
      "a_end_h", "a_end_m"
    ];
    if (strFunc.str_in_array(key, time_col_arr) != -1) {
      val = strFunc.comma(val);
      val = val.substring(0, 2);
      if (key == "a_start_h" || key == "a_end_h") {
        if (parseInt(val) >= 24) {
          val = "23";
        }
      } else {
        if (parseInt(val) >= 60) {
          val = "59";
        }
      }
      if (parseInt(val) < 0) {
        val = "0";
      }
    }
    let a_teacher_name = "";
    if (key == "a_teacher_seq") {
      for (let i = 0; i < myProps.teacher_arr.length; i++) {
        if (myProps.teacher_arr[i]["a_seq"] == val) {
          a_teacher_name = myProps.teacher_arr[i]["a_user_name"];
        }
      }
    }
    for (let i = 0; i < tmpTimeInfoArr.length; i++) {
      let tmpTimeInfo = tmpTimeInfoArr[i];
      if (tmpTimeInfo.a_day_num == timeRowData.a_day_num && tmpTimeInfo.a_seq == timeRowData.a_seq) {
        tmpTimeInfoArr[i][key] = val;
        if (key == "a_teacher_seq") {
          tmpTimeInfoArr[i]["a_teacher_name"] = a_teacher_name;
        }
      }
    }
    tmpTimeInfoArr=myProps.get_copy_of_same_days_week_data(tmpTimeInfoArr);
    myProps.setTimeInfoArr([...tmpTimeInfoArr]);
  };

  const getTimeRowsByDayNum = (dayNum: number) => {
    let timeRows = [];
    let time_len = timeInfoArr.length;
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum) {
        timeRows.push(timeRowData);
      }
    }
    if (timeRows.length == 0) {

    }

    return timeRows;
  };

  const removeTimeRowOfIdx = (dayNum: number, idx: number) => {
    let time_len = timeInfoArr.length;
    let tmpTimeInfoArr = [];
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum && timeRowData["a_seq"] == idx) {

      } else {
        tmpTimeInfoArr.push(timeRowData);
      }
    }
    tmpTimeInfoArr = myProps.get_copy_of_same_days_week_data(tmpTimeInfoArr);
    myProps.setTimeInfoArr(tmpTimeInfoArr);
  };

  const getDayDataByDayNum = (dayNum: number) => {
    let dayNumLen = dayNumArr.length;
    let dayNumData = null;
    for (let i = 0; i < dayNumLen; i++) {
      if (dayNumArr[i].value == dayNum) {
        dayNumData = dayNumArr[i];
      }
    }
    return dayNumData;
  };

  const addTimeRowOfDayNum = (dayNum: number) => {
    let time_len = timeInfoArr.length;
    let pre_time_info = null;
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum) {
        pre_time_info = timeRowData;
      }
    }
    if (pre_time_info == null) {
      if (strFunc.is_empty(myProps.rowData.a_basic_row_cnt)) {
        myProps.rowData.a_basic_row_cnt = 1;
      }

      addTimeRowByBasicRowCnt(dayNum, myProps.rowData.a_basic_row_cnt);
    } else {
      addTimeRow(dayNum, 1);
    }
  };

  const addTimeRowByBasicRowCnt = (dayNum: number, cnt: number) => {
    addTimeRow(dayNum, cnt);
  };

  const addTimeRow = (dayNum: number, cnt: number) => {
    let tmpTimeInfoArr = [...timeInfoArr];
    for (let row_i = 0; row_i < cnt; row_i++) {
      let time_len = tmpTimeInfoArr.length;
      let time_row_num = 1;
      let pre_time_info = null;
      for (let i = 0; i < time_len; i++) {
        let timeRowData = tmpTimeInfoArr[i];
        if (timeRowData["a_day_num"] == dayNum) {
          pre_time_info = timeRowData;
          time_row_num++;
        }
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_h)) {
        myProps.rowData.a_basic_h = "09";
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_m)) {
        myProps.rowData.a_basic_m = "00";
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_length_sec)) {
        myProps.rowData.a_basic_length_sec = "3600";
      }
      let start_init_sec = strFunc.timeToSeconds(myProps.rowData.a_basic_h, myProps.rowData.a_basic_m, "0");
      let end_init_sec = start_init_sec + parseInt(strFunc.uncomma(myProps.rowData.a_basic_length_sec));
      let end_init_hms_json = strFunc.secondsToTimeJson(end_init_sec);

      let addTimeRowData: any = {
        ...myProps.initLectureTimeRowData,
        ...{
          "a_name": time_row_num,
          "a_start_h": myProps.rowData.a_basic_h,
          "a_start_m": myProps.rowData.a_basic_m,
          "a_end_h": end_init_hms_json.h,
          "a_end_m": end_init_hms_json.i,
          "a_teacher_seq": myProps.rowData.a_main_teacher_seq,
          "a_teacher_name": myProps.rowData.a_main_teacher,
          "a_room_seq": myProps.rowData.a_room_seq,
        },
        ...{
          a_day_num: dayNum,
          a_seq: time_row_num,
        }
      };

      if (pre_time_info != null) {
        //이전타임 + 기준시간
        let tmp_start_h = parseInt(pre_time_info.a_end_h);
        let tmp_start_m = parseInt(pre_time_info.a_end_m);
        let tmp_start_sec = strFunc.timeToSeconds(tmp_start_h, tmp_start_m, "0");
        let tmp_end_sec = tmp_start_sec + parseInt(strFunc.uncomma(myProps.rowData.a_basic_length_sec));
        let tmp_end_hms_json = strFunc.secondsToTimeJson(tmp_end_sec);

        addTimeRowData["a_start_h"] = strFunc.str_pad({ "str": tmp_start_h, "pad_length": 2 });
        addTimeRowData["a_start_m"] = strFunc.str_pad({ "str": tmp_start_m, "pad_length": 2 });
        addTimeRowData["a_end_h"] = tmp_end_hms_json.h;
        addTimeRowData["a_end_m"] = tmp_end_hms_json.i;
      }

      if (parseInt(addTimeRowData["a_end_h"]) >= 24) {
        addTimeRowData["a_end_h"] = "24";
        addTimeRowData["a_end_m"] = "00";
      }
      if (addTimeRowData["a_start_h"] >= "24") {
        continue;
      }

      tmpTimeInfoArr.push(addTimeRowData);
    }
    tmpTimeInfoArr=myProps.get_copy_of_same_days_week_data(tmpTimeInfoArr);
    myProps.setTimeInfoArr([...tmpTimeInfoArr]);
  };

  const getTimeRowTagsByDayNum = (inDayNum: any) => {
    let dayNum = inDayNum;
    if (inDayNum == myProps.rowData["a_same_days_week"]) {
      dayNum = same_days_week_arr[0];
    }
    
    let dayNumData:any = getDayDataByDayNum(inDayNum);
    let timeRows: any = getTimeRowsByDayNum(dayNum);
    let timeRowTags = timeRows.map((item: any, idx: number) => {
      let first_td: any = "";
      if (idx == 0) {
        first_td = (
          <td rowSpan={timeRows.length} style={{ color: dayNumData?.color, verticalAlign: "top" }}>
            <div style={{ height: 30, lineHeight: "30px" }}>
              {dayNumData?.text}
              <button className="btn-s btn-gray ml-2 px-2" onClick={() => { addTimeRowOfDayNum(dayNum); }}>+</button>
            </div>
          </td>
        );
      }
      return (
        <tr key={idx} className={Style.time_row_tr}>
          {first_td}
          <td>
            <input type="text" className="text-center" name="a_name" value={item.a_name}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 50 }}
              autoComplete="off" />
          </td>
          <td>
            <input type="text" className="text-center" name="a_start_h" value={strFunc.str_pad({ "str": item.a_start_h, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />:
            <input type="text" className="text-center" name="a_start_m" value={strFunc.str_pad({ "str": item.a_start_m, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />
          </td>
          <td>
            <input type="text" className="text-center" name="a_end_h" value={strFunc.str_pad({ "str": item.a_end_h, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />:
            <input type="text" className="text-center" name="a_end_m" value={strFunc.str_pad({ "str": item.a_end_m, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />
          </td>
          <td>
            <select className="row-input" name="a_teacher_seq" value={item.a_teacher_seq}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} >
              <option value="">선택없음</option>
              {myProps.teacher_arr.map((teacher_info: any, teacher_idx: number) => {
                return (
                  <option value={teacher_info.a_seq} key={teacher_idx}>{teacher_info.a_user_name}</option>
                );
              })}
            </select>
          </td>
          <td>
            <select className="row-input" name="a_room_seq" value={item.a_room_seq}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} >
              <option value="">선택없음</option>
              {
                myProps.room_info_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item.a_seq}>{item.a_name}</option>
                  );
                })
              }
            </select>
          </td>
          <td>
            <button className="btn-s btn-dark px-2" onClick={() => { removeTimeRowOfIdx(item.a_day_num, item.a_seq); }}>X</button>
          </td>
          <td>
            <StuTimeBox
              Style={Style}
              day_time_num_val={dayNumData?.value+" "+item.a_seq}
              time_info={item}
              timeInfoArr={timeInfoArr}
              same_days_week_arr={same_days_week_arr}
              user_time_arr={myProps.user_time_arr}
              set_user_time_arr={myProps.set_user_time_arr}
              studentArr={myProps.studentArr}
              show_stu_day_time_arr={myProps.show_stu_day_time_arr}
              set_show_stu_day_time_arr={myProps.set_show_stu_day_time_arr}
              is_show_stu_day_time_all={myProps.is_show_stu_day_time_all}
              open_find_stu_popup={myProps.open_find_stu_popup}></StuTimeBox>
          </td>
        </tr>
      );
    });

    if (strFunc.is_empty(timeRowTags)) {
      timeRowTags = (<tr>
        <td style={{ color: dayNumData?.color }}>
          {dayNumData?.text}
          <button className="btn-s btn-gray ml-2 px-2" onClick={() => { addTimeRowOfDayNum(dayNum); }}>+</button>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      );
    }

    return timeRowTags;
  };

  return (
    <div className="list-table-div gray_table mt-1" >
      <table style={{ width: "auto" }} >
        <colgroup>
          <col width="100px"></col>
          <col width="60px"></col>
          <col width="100px"></col>
          <col width="100px"></col>
          <col width="90px"></col>
          <col width="90px"></col>
          <col width="70px"></col>
          <col width="290px"></col>
        </colgroup>
        <thead>
          <tr>
            <th>요일</th>
            <th>교시</th>
            <th>시작</th>
            <th>종료</th>
            <th>담당선생님</th>
            <th>강의실</th>
            <th>관리</th>
            <th>수강생</th>
          </tr>
        </thead>
        <tbody>
          {dayNumArr.map((item: any, idx: number) => {
            return (
              <React.Fragment key={idx} >
                {getTimeRowTagsByDayNum(item.value)}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default TimeListArea;