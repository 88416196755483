import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const SearchArea = (props: any) => {
  let myProps = {
    list: (inData: any) => { },
    listOpt: {
      s_select_date: "",
    },
    now_ymdhis: "",
    set_now_ymdhis: (inData: string) => { },
    is_now_time: true,
    set_is_now_time: (inData: boolean) => { },
    is_show_only_now_lecture: true,
    set_is_show_only_now_lecture: (inData: boolean) => { },
    is_show_msg: true,
    set_room_msg_arr: (inData: any) => { },
    set_is_show_msg: (inData: boolean) => { },
    is_show_stu_select_list: false,
    set_is_show_stu_select_list: (inData: boolean) => { },
    is_stu_filter_lecture: false,
    set_is_stu_filter_lecture: (inData: boolean) => { },
    is_stu_filter_use_desk: false,
    set_is_stu_filter_use_desk: (inData: boolean) => { },
    select_stu_filter: {},
    set_select_stu_filter: (inData: any) => { },
    select_stu_data: {},
    set_select_stu_data: (inData: any) => { },
    is_add_attend_by_desk: false,
    set_is_add_attend_by_desk: (inData: boolean) => { },
    ...props
  };

  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  //const [now_sec, set_now_sec] = useState(strFunc.timeToSeconds(now_date_json.h,now_date_json.i,now_date_json.s)); 
  let now_sec_json = strFunc.secondsToTimeJson(strFunc.timeToSeconds(now_date_json.h, now_date_json.i, now_date_json.s));
  let now_his = now_sec_json.h + ":" + now_sec_json.i + ":" + now_sec_json.s;

  let now_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");
  let pre_date_obj = DateFunc.get_change_date(new Date(myProps.listOpt.s_select_date), 'day', -1);
  let pre_date_json = DateFunc.get_date_json(pre_date_obj);
  let after_date_obj = DateFunc.get_change_date(new Date(myProps.listOpt.s_select_date), 'day', +1);
  let after_date_json = DateFunc.get_date_json(after_date_obj);
  let select_date_json = DateFunc.get_date_json(new Date(myProps.listOpt.s_select_date));

  let select_date_str = select_date_json["m"] + "월 " + select_date_json["d"] + "일";
  if (myProps.listOpt.s_select_date == now_ymd) {
    //select_date_str+="(오늘)";
  }
  let day_of_week_str_arr = ["일", "월", "화", "수", "목", "금", "토"];
  if (day_of_week_str_arr[select_date_json.day] != undefined) {
    select_date_str += "(" + day_of_week_str_arr[select_date_json.day] + ")";
  }
  let pre_date_str = pre_date_json["m"] + "월 " + pre_date_json["d"] + "일";
  let after_date_str = after_date_json["m"] + "월 " + after_date_json["d"] + "일";

  let search_h_arr = [];
  for (let i = 0; i < 24; i++) {
    let h_str = strFunc.str_pad({ "str": i, "pad_length": 2 });
    search_h_arr.push(h_str);
  }
  let search_m_arr = [];
  for (let i = 0; i < 60; i += 1) {
    let m_str = strFunc.str_pad({ "str": i, "pad_length": 2 });
    search_m_arr.push(m_str);
  }

  let select_attend_s_detail_arr = [
    { value: "", text: "전체" },
    { value: "no_absent", text: "결석제외" },
    { value: "attend", text: "등원" },
    { value: "late", text: "지각" },
    { value: "absent", text: "결석" },
    { value: "empty", text: "미등원" },
  ];

  const on_change_date_period = (inData: any) => {
    let opt_obj = {
      s_select_date: myProps.listOpt.s_select_date,
      ...inData
    };
    let change_is_now_time = false;
    myProps.set_is_now_time(change_is_now_time);
    let change_now_ymdhis = opt_obj["s_select_date"] + " " + now_date_json.h + ":" + now_date_json.i + ":" + now_date_json.s;
    myProps.set_now_ymdhis(change_now_ymdhis);
    myProps.list({ "now_page": "1", "s_select_date": opt_obj["s_select_date"], is_now_time: change_is_now_time });
  };

  const on_change_hour = (inData: any) => {
    let opt_obj = {
      hour: "00",
      ...inData
    };
    myProps.set_is_now_time(false);
    let change_now_ymdhis = myProps.listOpt.s_select_date + " " + opt_obj["hour"] + ":00:00";
    myProps.set_now_ymdhis(change_now_ymdhis);
  };
  const on_change_minute = (inData: any) => {
    let opt_obj = {
      m: "00",
      ...inData
    };
    myProps.set_is_now_time(false);
    let change_now_ymdhis = myProps.listOpt.s_select_date + " " + now_sec_json.h + ":" + opt_obj["m"] + ":00";
    myProps.set_now_ymdhis(change_now_ymdhis);
  };
  const on_change_stu_filter = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_select_stu_filter = { ...myProps.select_stu_filter };
    change_select_stu_filter[name] = value;
    myProps.set_select_stu_filter(change_select_stu_filter);
  };

  return (
    <div className={Style.search_box}>
      <div>
        <span className={Style.pre_date_span}
          onClick={() => { on_change_date_period({ "s_select_date": DateFunc.get_date_format(pre_date_obj, "Y-m-d") }); }} >{pre_date_str}</span>
        <span className={Style.select_date_span}
          onClick={() => { on_change_date_period({ "s_select_date": DateFunc.get_date_format(new Date(), "Y-m-d") }); }}>
          {select_date_str}
        </span>
        <span className={Style.after_date_span}
          onClick={() => { on_change_date_period({ "s_select_date": DateFunc.get_date_format(after_date_obj, "Y-m-d") }); }} >
          {after_date_str}
        </span>
        <span className={Style.sub_time_span}>
          {now_his}
        </span>
      </div>
      <div className="text-center mt-2">
        <span className="ml-1">
          시간:
          <button className={"ml-1 btn btn-" + (myProps.is_now_time ? "sky" : "gray")} onClick={() => {
            myProps.set_is_now_time(!myProps.is_now_time);
            if (myProps.is_now_time == false) {
              myProps.list({ "now_page": "1", "s_select_date": now_ymd, is_now_time: true });
            }
          }}>현재</button>
        </span>
        <span className="ml-1">
          <select className="search-input"
            value={now_sec_json.h}
            onChange={(e: any) => { on_change_hour({ hour: e.target.value }); }} >
            {search_h_arr.map((h_str, h_idx) => {
              return (
                <option key={h_idx} value={h_str}>{h_str}</option>
              );
            })}
          </select>:
          <select className="search-input"
            value={now_sec_json.i}
            onChange={(e: any) => { on_change_minute({ m: e.target.value }); }} >
            {search_m_arr.map((m_str, m_idx) => {
              return (
                <option key={m_idx} value={m_str}>{m_str}</option>
              );
            })}
          </select>
        </span>


        <span className="ml-2">
          시간표:
          <button className={"ml-1 btn btn-" + (myProps.is_show_only_now_lecture ? "darkgreen" : "gray")} onClick={() => {
            myProps.set_is_show_only_now_lecture(!myProps.is_show_only_now_lecture);
          }}>
            {myProps.is_show_only_now_lecture ? "현재" : "전체"}
          </button>
        </span>

        <span className="ml-2">
          <label>
            <input type="checkbox" value="1" checked={myProps.is_show_msg}
              onChange={(e: any) => {
                myProps.set_is_show_msg(e.target.checked);
                let desk_main_is_show_msg = "2";
                if (e.target.checked) {
                  desk_main_is_show_msg = "1";
                }
                strFunc.set_storage("desk_main_is_show_msg", desk_main_is_show_msg);
              }} />
            <span className="ml-1">메세지 표시</span>
          </label>
          <button className="btn btn-gray ml-2" onClick={() => { myProps.set_room_msg_arr([]); }}>메세지 초기화</button>
        </span>
      </div>
      <div className="text-center mt-2">
        {myProps.select_stu_data.is_select &&
          <span>
            선택학생:
            <span className="ml-1" style={{ color: "blue" }}>
              {myProps.select_stu_data.stu_info.a_user_name}
            </span>
            <button className="btn-s btn-red ml-1"
              onClick={() => {
                myProps.set_select_stu_data({
                  is_select: false,
                  stu_info: null,
                });
              }}>X</button>
          </span>
        }
        {myProps.is_show_stu_select_list &&
          <span>
            <span className="ml-1">
              이름: <input type="text" className="search-input" value={myProps.select_stu_filter.s_user_name}
                name="s_user_name"
                onChange={on_change_stu_filter} style={{ width: "80px" }}
                onClick={(e: any) => { e.target.select(); }} />
            </span>
            <span className="ml-1">
              학년:
              <select className="search-input" value={myProps.select_stu_filter.s_stu_grade}
                name="s_stu_grade"
                onChange={on_change_stu_filter}>
                <option value="">전체</option>
                <option value="elementary">초</option>
                <option value="middle">중</option>
                <option value="high">고</option>
                {stu_grade_option_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item["value"]}>{item["text"]}</option>
                  );
                })}
              </select>
            </span>
            <span className="ml-1">
              출석:
              <select className="search-input" value={myProps.select_stu_filter.s_attend_sort_detail}
                name="s_attend_sort_detail"
                onChange={on_change_stu_filter}>
                {
                  select_attend_s_detail_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item.value} key={idx}>{item.text}</option>
                    );
                  })
                }
              </select>
            </span>
          </span>
        }
        <label>
          <input type="checkbox" className="ml-1" value="1" checked={myProps.is_show_stu_select_list}
            onChange={(e: any) => {
              myProps.set_is_show_stu_select_list(e.target.checked);
              let desk_main_is_show_stu_select_list = "2";
              if (e.target.checked) {
                desk_main_is_show_stu_select_list = "1";
              }
              strFunc.set_storage("desk_main_is_show_stu_select_list", desk_main_is_show_stu_select_list);
            }} />
          <span className="ml-1">학생찾기 표시</span>
        </label>
        {myProps.is_show_stu_select_list &&
          <span>
            <span className="ml-2">
              <label>
                <input type="checkbox" value="1" checked={myProps.is_stu_filter_lecture}
                  onChange={(e: any) => {
                    myProps.set_is_stu_filter_lecture(e.target.checked);
                    let desk_main_is_stu_filter_lecture = "2";
                    if (e.target.checked) {
                      desk_main_is_stu_filter_lecture = "1";
                    }
                    strFunc.set_storage("desk_main_is_stu_filter_lecture", desk_main_is_stu_filter_lecture);
                  }} />
                <span className="ml-1">수업 소속학생</span>
              </label>
            </span>
            <span className="ml-2">
              <label>
                <input type="checkbox" value="1" checked={myProps.is_stu_filter_use_desk}
                  onChange={(e: any) => {
                    myProps.set_is_stu_filter_use_desk(e.target.checked);
                    let desk_main_is_stu_filter_use_desk = "2";
                    if (e.target.checked) {
                      desk_main_is_stu_filter_use_desk = "1";
                    }
                    strFunc.set_storage("desk_main_is_stu_filter_use_desk", desk_main_is_stu_filter_use_desk);
                  }} />
                <span className="ml-1">좌석사용학생 안보임</span>
              </label>
            </span>
            {/* <span className="ml-2">
              <label>
                <input type="checkbox" value="1" checked={myProps.is_add_attend_by_desk}
                  onChange={(e: any) => {
                    myProps.set_is_add_attend_by_desk(e.target.checked);
                    let desk_main_is_add_attend_by_desk = "2";
                    if (e.target.checked) {
                      desk_main_is_add_attend_by_desk = "1";
                    }
                    strFunc.set_storage("desk_main_is_add_attend_by_desk", desk_main_is_add_attend_by_desk);
                  }} />
                <span className="ml-1">좌석체크시 출석체크</span>
              </label>
            </span> */}
          </span>
        }

      </div>
    </div >
  );
};
export default SearchArea;