import strFunc from "@/lib/lyg/string";
import Style from "../../../list/css/style.module.css";

function DetailItem(props:any){
  let myProps={
    idx:0,
    detail_info:null,
    form_d_info:null,
    onChangeDetail:(inData:any)=>{},
    ...props
  };

  let score_color_arr=[
    "#3FAC96",
    "#00968B",
    "#00776F",
    "#005D5D",
    "#03354B",
  ];
  let detail_info=myProps.detail_info;
  let form_d_info=myProps.form_d_info;
  
  const on_change_detail_input=(inData:any)=>{
    let opt_obj={
      value:"",
      name:"",
      score_letter:"",
      ...inData,
    };
    let value=opt_obj.value;
    let name=opt_obj.name;

    if(name=="a_score"){
      value=strFunc.uncomma(value);
      if(opt_obj["score_letter"]!=""){
        detail_info["a_score_letter"]=opt_obj["score_letter"];
      }
    }

    detail_info[name]=value;

    myProps.onChangeDetail({
      "row_num":myProps.idx,
      "detail_info":detail_info,
    });
  };

  const get_score_area_tag=()=>{
    let score_area_tag:any="";

    score_area_tag=(
      <input type="text" className="row-input" name="a_score" value={detail_info.a_score}
        onChange={(e:any)=>{on_change_detail_input({value:e.target.value,name:e.target.name});}}
        placeholder="점수" />
    );

    if(form_d_info&&form_d_info["a_name_sort"]=="abc"){
      let a_name_split_arr=form_d_info["a_name_split_str"].split("@,@");
      let a_max_score=parseInt(strFunc.uncomma(form_d_info["a_max_score"]));
      let a_score_split=parseInt(strFunc.uncomma(form_d_info["a_score_split"]));
      let a_score=parseInt(strFunc.uncomma(detail_info["a_score"]));
      let abc_len=0;
      if(a_max_score!=0&&a_score_split!=0){
        abc_len=Math.ceil(a_max_score/a_score_split);
      }
      if(strFunc.is_empty(form_d_info["a_name_split_str"])){
        a_name_split_arr=strFunc.get_abc_arr(abc_len);
      }

      let split_item_tags=a_name_split_arr.map((split_str:string,split_idx:number)=>{
        let start_score=a_max_score-(a_score_split*split_idx);
        let end_score=a_max_score-(a_score_split*(split_idx+1));
        let title_str=start_score+"~"+end_score;
        let score_tag_style:any={};
        let score_tag_class=Style.score_tag;
        let is_check_score=false;

        if(end_score<a_score&&a_score<=start_score){
          is_check_score=true;
        }
        if(a_score==a_max_score&&start_score==a_max_score){
          is_check_score=true;
        }
        if(end_score==0&&a_score==0){
          is_check_score=true;
        }
        if(is_check_score){
          if(score_color_arr.length>split_idx){
            score_tag_style["background"]=score_color_arr[split_idx];
          }
          score_tag_class+=" "+Style.active;
        }
        
        return (
          <div key={split_idx} className={Style.score_tag} title={title_str}
            style={score_tag_style} 
            onClick={()=>{
              on_change_detail_input({
                value:start_score,name:"a_score",
                score_letter:split_str,
              });
            }} >
            {split_str}
          </div>
        );
      });

      score_area_tag=(
        <div className={Style.score_tag_box}>
          {split_item_tags}
        </div>
      );
    }

    return score_area_tag;
  };

  return (
    <tr>
      <th>
        <input type="text" className="row-input" name="a_title" value={detail_info.a_title}
          onChange={(e:any)=>{
            on_change_detail_input({
              "name":"a_title",
              "value":e.target.value
            });
          }} placeholder="평가명" />
      </th>
      <td>
        {get_score_area_tag()}
      </td>
    </tr>
  );
}
export default DetailItem;