import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    let change_list_opt = {
      [name]: value
    };
    myProps.list(change_list_opt);
  }

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt: any = {
      now_page: 1,
      [key]: date_str
    };
    if (key == "s_start_date") {
      change_list_opt["s_end_date"] = date_str;
    }
    myProps.list(change_list_opt);
  };
  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };
  const change_start_end_date_by_sort = (sort: "today" | "this_month" | "pre_month") => {
    let now_month_init_date = DateFunc.get_date_format(new Date(), "Y-m-01");
    let s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    let s_end_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    if (sort == "this_month") {
      s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      s_start_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-t");
    }
    myProps.list({
      now_page: 1,
      s_start_date: s_start_date,
      s_end_date: s_end_date,
    });
  };

  return (
    <div className="text-center">
      <div style={{ display: "inline-block", width: 85 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_start_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
      <span>~
        <div style={{ display: "inline-block", width: 85 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
      </span>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
      <button className="btn-s btn-dark ml-1"
        style={{ "background": "#0097BA" }}
        onClick={() => { change_start_end_date_by_sort("today"); }} >오늘</button>
      <button className="btn-s btn-dark ml-1"
        style={{ "background": "#0097BA" }}
        onClick={() => { change_start_end_date_by_sort("this_month"); }} >이번달</button>
      <button className="btn-s btn-dark ml-1"
        style={{ "background": "#0097BA" }}
        onClick={() => { change_start_end_date_by_sort("pre_month"); }} >지난달</button>

      <span>
        수신자:
        <input type="border" name="s_receiver_like" value={myProps.listOpt.s_receiver_like}
          style={{ width: 80 }}
          onChange={handleInputChange} />
      </span>
      <span className="ml-2">
        발신자:
        <input type="border" name="s_sender_like" value={myProps.listOpt.s_sender_like}
          style={{ width: 80 }}
          onChange={handleInputChange} />
      </span>
    </div>
  );
};
export default SearchArea;