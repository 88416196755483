import { useState, useRef, useEffect, forwardRef,useImperativeHandle, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

const SupplyRecentListArea = forwardRef((props:any, ref) => {
  let myProps={
    stu_info_arr:[],
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_date_type:'a_date',
      s_start_date:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',-1),"Y-m-01"),
      s_end_date:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',+3),"Y-m-t"),
      s_money_sum:"1",
      s_state:"",
      s_sort:"",
      s_stu_seq:"",
      s_is_zero_pay_money:"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [trade_tot_info,set_trade_tot_info]=useState({
    in_debt:0,
    in_money:0,
    out_debt:0,
    out_money:0,
    remain_money:0
  });

  useEffect(() => {
    
    list({});
  }, [myProps.stu_info_arr]);
  
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    get_select_rows
  }))

  const get_select_rows=()=>{
    return ListComponentRef.current.getSelectedRows();
  };

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      //inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      
      setPageData({...tmpPageData});
    }
    let tmp_list_opt=pageData.listOpt;
    if(strFunc.is_empty(myProps.stu_info_arr)){
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    if(strFunc.is_empty(myProps.stu_info_arr)){
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    tmp_list_opt["s_stu_seq"]=[];
    for(let i=0;i<myProps.stu_info_arr.length;i++){
      tmp_list_opt["s_stu_seq"].push(myProps.stu_info_arr[i]["a_seq"]);
    }
    get_trade_tot_info_by_ajax();
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/class_supply/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        setTimeout(()=>{
          ListComponentRef.current.checkAllCustom(true);
        },100);
      }else{
        
      }
    });
  };

  const get_trade_tot_info_by_ajax=()=>{
    if(strFunc.is_empty(myProps.stu_info_arr)){
      return false;
    }
    let stu_seq_arr:any=[];
    for(let i=0;i<myProps.stu_info_arr.length;i++){
      stu_seq_arr.push(myProps.stu_info_arr[i]["a_seq"]);
    }
    let tmp_list_opt={
      "s_pri_arr":stu_seq_arr,
      "s_addon_trade_tot":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_trade_tot_info:any={
            in_debt:0,
            in_money:0,
            out_debt:0,
            out_money:0,
            remain_money:0
          };
          for(let i=0;i<response.data["data"]["info_arr"].length;i++){
            let tmp_info=response.data["data"]["info_arr"][i];
            if(tmp_info["trade_tot_info"]){
              for(let key in tmp_info["trade_tot_info"]){
                if(tmp_trade_tot_info[key]==undefined){tmp_trade_tot_info[key]=0;}
                tmp_trade_tot_info[key]+=parseInt(strFunc.uncomma(tmp_info["trade_tot_info"][key]));
              }
            }
          }
          set_trade_tot_info(tmp_trade_tot_info);
        }
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      
    }
  }, []);

  const cellRenderer= (params:any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "origin_val":origin_val,
    }
    */
    let render_str=params.value;
    
    return render_str;
  };

  return (
    <div>
      <div>
        청구금: <span style={{color:"#918e4b"}}>{strFunc.comma(trade_tot_info.in_debt)}</span>원, 
        입금: <span style={{color:"blue"}}>{strFunc.comma(trade_tot_info.in_money)}</span>원, 
        미납금: <span style={{color:"red"}}>{strFunc.comma(trade_tot_info.remain_money)}</span>원 
      </div>
      <div>
        <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        gridOpt={{
          is_add_idx_num:false,
          is_add_checkbox:true,
          td_align:"center",
          height:"15vh",
          onGridReady:()=>{
            //list({});
          },
          onCellClicked:cellClickedListener,
          customCellRenderer:cellRenderer
        }}
        ></ListComponent>
      </div>
    </div>
  );
});
export default SupplyRecentListArea;