import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useState, useEffect } from 'react';
import Style from "./css/style.module.css";
import TopYearMonthArea from "./top_year_month";
import { start } from 'repl';

interface TdData {
  is_this_month: boolean;
  year: string,
  month: string,
  day: number,
  day_of_week: number,
};

function CalendarArea(props: any) {
  let myProps = {
    select_date: "",
    select_date_arr: [],
    set_select_date_arr: () => { },
    onSearchDateChange: (inData: any) => { },
    able_days_of_week: [0, 1, 2, 3, 4, 5, 6],
    ...props
  };
  if (strFunc.is_empty(myProps.select_date)) { myProps.select_date = DateFunc.get_date_format(new Date(), "Y-m-d"); }
  const [select_date, set_select_date] = useState(myProps.select_date);
  const [refresh,set_refresh]=useState(false);

  const now_date_json = DateFunc.get_date_json(new Date());

  let day_num_list = [0, 1, 2, 3, 4, 5, 6];
  let day_name_list = ["일", "월", "화", "수", "목", "금", "토"];
  let select_month_obj = new Date(select_date);
  let pre_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',-1);
  let after_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',+1);
  let pre_month_date = DateFunc.get_date_format(pre_month_obj, "Y-m-01");
  let after_month_date = DateFunc.get_date_format(after_month_obj, "Y-m-01");
  let cal_data: any = {
    select_month_json: DateFunc.get_date_json(select_month_obj),
    pre_month_json: DateFunc.get_date_json(pre_month_obj),
    after_month_json: DateFunc.get_date_json(after_month_obj),
    select_month_week_len: 5,
  };
  cal_data.select_month_week_len = Math.ceil((cal_data.select_month_json.first_day_of_week + cal_data.select_month_json.last_day) / 7);

  const handleDateChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    let change_searchDate = select_date;
    let tmp_year = select_date.substring(0, 4);
    let tmp_month = select_date.substring(5, 7);
    let tmp_day = select_date.substring(8, 10);
    let tmp_date_json: any = DateFunc.get_date_json(new Date(select_date));
    if (name == "s_month") {
      tmp_month = value;
    } else if (name == "s_year") {
      tmp_year = value;
    }
    if (parseInt(tmp_day) > tmp_date_json.last_day) {
      tmp_day = strFunc.str_pad({ "str": tmp_date_json.last_day, "pad_length": 2 });
    }
    change_searchDate = tmp_year + "-" + tmp_month + "-" + tmp_day;

    set_select_date(change_searchDate);
    if (myProps.onSearchDateChange) {
      myProps.onSearchDateChange(change_searchDate);
    }
  };

  const add_rage_date_select_arr=(inData:any)=>{
    let opt_obj={
      "start_date":"",
      "end_date":"",
      ...inData
    };
    let start_date=opt_obj.start_date;
    let end_date=opt_obj.end_date;
    if(start_date>end_date){
      let tmp_date=start_date;
      start_date=end_date;
      end_date=tmp_date;
    }
    let tmp_select_date_arr=[];
    let tmp_date=start_date;
    while(tmp_date<=end_date){
      let tmp_date_json=DateFunc.get_date_json(new Date(tmp_date));
      if(strFunc.str_in_array(tmp_date_json.day,myProps.able_days_of_week)!=-1){
        tmp_select_date_arr.push(tmp_date);
      }
      tmp_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_date),'day',1),"Y-m-d");
    }
    myProps.set_select_date_arr(tmp_select_date_arr);
  };

  const on_td_click=(inData:any)=>{
    if(strFunc.str_in_array(inData,myProps.select_date_arr)==-1){
      //없으면 추가
      let tmp_date_json=DateFunc.get_date_json(new Date(inData));
      if(strFunc.str_in_array(tmp_date_json.day,myProps.able_days_of_week)==-1){
        return false;
      }
      if(myProps.select_date_arr.length==1){
        add_rage_date_select_arr({
          "start_date":myProps.select_date_arr[0],
          "end_date":inData
        });
      }else{
        let tmp_select_date_arr=myProps.select_date_arr;
        tmp_select_date_arr.push(inData);
        myProps.set_select_date_arr(tmp_select_date_arr);
      }
    }else{
      //있으면 제거
      let tmp_select_date_arr=[];
      for(let i=0;i<myProps.select_date_arr.length;i++){
        if(myProps.select_date_arr[i]==inData){

        }else{
          tmp_select_date_arr.push(myProps.select_date_arr[i]);
        }
      }
      myProps.set_select_date_arr(tmp_select_date_arr);
    }
    set_refresh(!refresh);
  };

  const get_col_tags = () => {
    let col_tags = day_num_list.map((item: any, idx: number) => {
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };

  const get_th_tags = () => {
    let th_tags = day_num_list.map((item: number, idx: number) => {
      let th_title = day_name_list[item];
      let th_text_class_name = Style.th_text;
      if (idx == 0) {
        th_text_class_name += " " + Style.th_text_sun;
      } else if (idx == 6) {
        th_text_class_name += " " + Style.th_text_sat;
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  const get_td_data_arr = () => {
    let td_data_arr: TdData[] = [];
    let day_num = 0;
    let pre_day_num = cal_data.pre_month_json.t - cal_data.select_month_json.first_day_of_week + 1;
    let after_day_num = 1;
    for (let week_i = 0; week_i < cal_data.select_month_week_len; week_i++) {
      for (let td_i = 0; td_i < 7; td_i++) {
        if (day_num == 0) {
          if (cal_data.select_month_json.first_day_of_week == td_i) {
            day_num = 1;
          }
        }
        //전달
        if (day_num <= 0) {
          td_data_arr.push({
            "is_this_month": false,
            "year": cal_data.pre_month_json.Y,
            "month": cal_data.pre_month_json.m,
            "day": pre_day_num,
            "day_of_week": td_i,
          });
          pre_day_num++;
        }
        //이번달
        if (day_num > 0 && day_num <= cal_data.select_month_json.t) {
          td_data_arr.push({
            "is_this_month": true,
            "year": cal_data.select_month_json.Y,
            "month": cal_data.select_month_json.m,
            "day": day_num,
            "day_of_week": td_i,
          });
          day_num++;
        }
        //다음달
        if (day_num > cal_data.select_month_json.t) {
          td_data_arr.push({
            "is_this_month": false,
            "year": cal_data.after_month_json.Y,
            "month": cal_data.after_month_json.m,
            "day": after_day_num,
            "day_of_week": td_i,
          });
          after_day_num++;
        }
      }
    }

    return td_data_arr;
  };

  const get_td_tags = (inData: any) => {
    let opt_obj = {
      "td_week_data_arr": [],
      ...inData
    };

    let td_tags = opt_obj["td_week_data_arr"].map((item: any, idx: number) => {
      let td_tag_class_name = Style.td_tag;
      let td_class_name = Style.td_title;
      
      if (item.day_of_week == 0) {
        td_class_name += " " + Style.td_title_sun;
      } else if (item.day_of_week == 6) {
        td_class_name += " " + Style.td_title_sat;
      }
      if (item.is_this_month
        && cal_data.select_month_json.m == now_date_json.m
        && cal_data.select_month_json.Y == now_date_json.Y
        && item.day == parseInt(now_date_json.d)) {

        td_tag_class_name += " "+Style.td_title_now_date;
      }

      let select_date_str = item["year"] + "-" + DateFunc.get_digit_str(item["month"]) + "-" + DateFunc.get_digit_str(item["day"]);

      //선택된 날짜인지 체크
      if(strFunc.str_in_array(select_date_str,myProps.select_date_arr)!=-1){
        td_tag_class_name+=" "+Style.td_select_arr;
      }

      let title_day_str=item.day;
      if (item.is_this_month) {
        td_class_name += " " + Style.td_title_select_month;
      }else{
        td_tag_class_name = Style.td_tag;
        title_day_str="";
      }

      return (
        <td key={idx} className={td_tag_class_name}>
          <div className={td_class_name} onClick={()=>{on_td_click(select_date_str);}} >
            {title_day_str}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags = () => {
    let td_data_arr = get_td_data_arr();
    let tmp_td_week_arr = [];
    let td_week_arr = [];
    for (let i = 0; i < td_data_arr.length; i++) {
      if (i % 7 == 0 && i != 0) {
        td_week_arr.push([
          ...tmp_td_week_arr
        ]);
        tmp_td_week_arr = [];
      }
      tmp_td_week_arr.push(td_data_arr[i]);
    }
    let tr_tags = td_week_arr.map((item: any, idx: number) => {
      let td_tags = get_td_tags({
        "td_week_data_arr": item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <TopYearMonthArea
        Style={Style}
        select_date={select_date}
        set_select_date={set_select_date}
        onSearchDateChange={myProps.onSearchDateChange}
        handleDateChange={handleDateChange}
        pre_month_date={pre_month_date}
        after_month_date={after_month_date}
      ></TopYearMonthArea>
      <div className={Style.cal_wrap}>
        <table>
          <colgroup>
            {get_col_tags()}
          </colgroup>
          <thead>
            <tr>
              {get_th_tags()}
            </tr>
          </thead>
          <tbody>
            {get_tr_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CalendarArea;