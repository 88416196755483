import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import getXcolumnJson from "../../list/xcolumn/list";
import StudentArea from "./area/stu_area";
import ParentArea from "./area/parent/parent_area";
import SupplyRecentListArea from "./area/supply_recent";
import person_img from "@/img/ico/person_ico.png";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import popupFunc from "@/lib/lyg/popup";
import UserBaseTableArea from "./area/base_area";
import ClassList from "./area/class_list";

function WritePopupContent(props: any) {
  let myProps = {
    isUpdate: false,
    rowData: {},
    closePopup: () => { },
    callback: (inData: any) => { },
    openStuEndPopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const parentAreaRef = useRef<any>(null);
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const ClassListRef = useRef<any>(null);
  const StudentAreaRef = useRef<any>(null);

  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const [rowData, setRowData] = useState({
    "a_seq": "",
    "a_corp_seq": "1",
    "a_user_id": "",
    "a_user_pw": "",
    "a_user_grade": "student",
    "a_is_login": "1",
    "a_user_name": "",
    "a_user_phone": "",

    "a_stu_state": "now",
    "a_stu_num": "",
    "a_class_seq": "",
    "a_gender": "",
    "a_school_name": "",
    "a_stu_grade": "",
    "a_join_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_stu_birth_date": "",
    "a_stu_supply_day": "",
    "a_stu_supply_discount": "0",
    "a_memo": "",
    "a_stu_end_date": "",
    "a_stu_end_memo": "",
    "invite_code": "",
    "a_makeedu_num": "",
    "a_makeedu_class": "",
    "a_cash_receipt_sort": "",
    "a_cash_receipt_num": "",

    "class_arr": [],
    "link_user_arr": [],
    "profile_arr": [],

    "person_thum_img": person_img,
    "person_img": person_img,

    "comp_link_info": undefined
  });
  const [check_id_msg, set_check_id_msg] = useState<any>({
    "msg": "",
    "is_able": true,
  });

  useEffect(() => {
    
    if (props.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (props.rowData[key] != undefined) {
          changeRowData[key] = props.rowData[key];
        }
      }
      if (changeRowData["profile_arr"] && changeRowData["profile_arr"].length > 0) {
        changeRowData["person_thum_img"] = changeRowData["profile_arr"][0]["thum_img_url"];
        changeRowData["person_img"] = changeRowData["profile_arr"][0]["img_url"];
      }
      if (changeRowData["a_stu_state"] == "now") {
        changeRowData.a_stu_end_date = "";
        changeRowData.a_stu_end_memo = "";
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
      if (parentAreaRef.current) {
        if (changeRowData["link_user_arr"]) {
          parentAreaRef.current.setParentArrByLinkArr(changeRowData["link_user_arr"]);
        }
      }
      if (ClassListRef.current) {
        if (changeRowData.class_arr) {
          ClassListRef.current.setOriginClassUsers(changeRowData.class_arr);
        }
      }
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "a_user_phone") {
      value = strFunc.autoHypenPhone(value);
      checkPhoneOfChildUser(value);
    } else if (name == "a_stu_num") {
      value = strFunc.getNumber2(value);
    } else if (name == "a_stu_supply_discount") {
      value = strFunc.comma(value);
    } else if (name == "a_user_id") {
      checkUserIdByAjax(value);
    }
    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const checkUserIdByAjax = (user_id: string) => {
    if (strFunc.is_empty(user_id)) {
      set_check_id_msg({
        is_able: false,
        msg: (
          <div style={{ "color": "red" }}>입력필요.</div>
        )
      });
      return false;
    }
    //이미 있는지 체크
    let check_user_id_form_json = {
      "s_id": user_id,
      "s_except_user_seq": rowData.a_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', check_user_id_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            set_check_id_msg({
              is_able: false,
              msg: (
                <div style={{ "color": "red" }}>이미있는 아이디 입니다.</div>
              )
            });
          } else {
            set_check_id_msg({
              is_able: true,
              msg: (
                <div style={{ "color": "green" }}>사용가능.</div>
              )
            });
          }
        }
      });
  };

  const checkPhoneOfChildUser = (phone: string) => {
    if (phone.length != 13) {
      return false;
    }
    //이미 있는지 체크
    let checkPhoneFormData = {
      "s_phone": phone,
      "s_except_user_seq": rowData.a_seq,
      "s_grade": "student",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', checkPhoneFormData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            alert("해당 핸드폰은 이미있는 회원이 있습니다. " + u_info["a_user_name"] + "");
            setRowData({
              ...rowData,
              "a_user_phone": ""
            });
          }
        }
      });
  };

  const goWrite = useCallback((e: any) => {
    if (props.isUpdate) {
      if (rowData.a_user_id == "") {
        alert("아이디 입력이 필요합니다.");
        return false;
      }
    }
    if (!props.isUpdate) {
      if (rowData.a_user_pw == "") {
        alert("암호 입력이 필요합니다.");
        return false;
      }
    }
    if (rowData.a_user_name == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    //학부모 핸드폰 끝자리와 출결번호 세팅
    let w_rowData = rowData;
    let parentArr = parentAreaRef.current.getExistParentArr();
    if (parentArr.length > 0) {
      if (!strFunc.is_empty(parentArr[0].a_user_phone) && parentArr[0].a_user_phone.length > 8) {
        if (strFunc.is_empty(w_rowData.a_stu_num)) {
          w_rowData.a_stu_num = strFunc.getNumber2(parentArr[0].a_user_phone.slice(-4));
        }
      }
    }

    let formJsonData = {
      "data_arr": [w_rowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_student', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (ClassListRef.current) {
            let tmp_checked_class_user_list = ClassListRef.current.getCheckedClassUserList();
            goWriteClassUser(tmp_checked_class_user_list, response.data["data"][0]);
          } else {
            goWriteProfileImg(response.data["data"][0]);
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  const goWriteClassUser = (classUserList: any, lastInfo: any) => {
    if (classUserList.length == 0) {
      if (parentAreaRef.current) {
        goWriteParentUser(lastInfo);
      } else {
        goWriteProfileImg(lastInfo);
      }
      return false;
    }
    //클래스키 넣기
    for (let i = 0; i < classUserList.length; i++) {
      classUserList[i].a_user_seq = lastInfo["a_seq"];
      classUserList[i].a_user_name = lastInfo["a_user_name"];
    }
    let formJsonData = {
      "user_seq": lastInfo["a_seq"],
      "class_user_arr": classUserList,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/classu/write_of_user', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (parentAreaRef.current) {
            goWriteParentUser(lastInfo);
          } else {
            goWriteProfileImg(lastInfo);
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteParentUser = (lastInfo: any) => {
    let parentArr = parentAreaRef.current.getExistParentArr();
    if (parentArr.length == 0) {
      goWriteProfileImg(lastInfo);
      return false;
    }
    let formJsonData = {
      stu_seq: lastInfo["a_seq"],
      parentArr: parentArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_parent', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          goWriteProfileImg(lastInfo);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteProfileImg = (lastInfo: any) => {
    if (writeFileForm.current == null) {
      go_write_end();
      return false;
    }
    if (fileInput.current && (fileInput.current as HTMLInputElement).value == "") {
      go_write_end();
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data = new FormData(form);
    let file_data_row = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.comp_seq,
      "a_par_id": "user",
      "a_par_seq": lastInfo.a_seq,
      "a_sort1": "profile",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": lastInfo.a_user_name,
      "a_create_seq": lastInfo.a_seq,
    };
    form_json_data.append("data_arr", JSON.stringify([file_data_row]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          go_write_end();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_write_end = () => {
    if (myProps.isUpdate) {
      if (myProps.rowData.a_stu_state != rowData.a_stu_state &&
        myProps.rowData.a_stu_state == "now" &&
        (rowData.a_stu_state == "rest" || rowData.a_stu_state == "end")) {

        setTimeout(() => {
          myProps.openStuEndPopup();
        }, 100);
        myProps.closePopup();
        return false;
      }
    }
    alert("저장되었습니다.");
    props.callback({});
    props.closePopup();
  };

  const goDeleteProFile = (fileInfo: any) => {
    if (!confirm("프로필사진을 삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      "data_arr": [fileInfo],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          props.callback({});
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDelete = () => {
    if (!props.isUpdate) {
      alert("삭제할 정보가 없습니다.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let formJsonData = {
      "data_arr": [rowData]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/delete', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          props.callback({});
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <UserBaseTableArea
        rowData={rowData}
        handleInputChange={handleInputChange}
        onChangeDatePicker={onChangeDatePicker}
        xColumnArr={xColumnArr}
        isUpdate={myProps.isUpdate}
        check_id_msg={check_id_msg}
      ></UserBaseTableArea>
      <ParentArea
        ref={parentAreaRef}
        isUpdate={myProps.isUpdate}
        callback={() => { props.callback(); }}
        on_change_parent_hp={(inData: any) => {
          if (myProps.isUpdate == false && !strFunc.is_empty(inData)) {
            let tmp_tel_str = strFunc.str_replace("-", "", inData);
            tmp_tel_str = tmp_tel_str.substring(tmp_tel_str.length - 4);
            setRowData({
              ...rowData,
              "a_stu_num": tmp_tel_str
            });
            if (StudentAreaRef.current) {
              StudentAreaRef.current.checkStuNumOfChildUser(tmp_tel_str);
            }
          }
        }}
      ></ParentArea>
      <p className="mt-2">※ 추가정보</p>
      <StudentArea
        ref={StudentAreaRef}
        rowData={rowData}
        setRowData={setRowData}
        handleInputChange={handleInputChange}
        onChangeDatePicker={onChangeDatePicker}
        xColumnArr={xColumnArr}
      ></StudentArea>
      <h5 className="text-center">소속 클래스</h5>
      <ClassList ref={ClassListRef}></ClassList>
      <p>※ 사진</p>
      <div className="text-center">
        <a onClick={() => {
          popupFunc.openPopup({ 'url': rowData.person_img, 'width': 700, 'height': 420, 'pop_name': 'name', 'auto_size': false });
        }}>
          <img src={rowData.person_thum_img} alt="사람" className="my-2" style={{ width: 120 }} />
        </a>
        {
          rowData.profile_arr.length > 0 &&
          <button className="btn-s btn-gray" onClick={() => { goDeleteProFile(rowData.profile_arr[0]); }}>X</button>
        }
        <form id="write_form" method="post" ref={writeFileForm} >
          <input type="file" id="files" className="row-input" name="input_file[]" ref={fileInput} />
        </form>
      </div>
      {props.isUpdate &&
        <SupplyRecentListArea
          rowData={rowData}
        ></SupplyRecentListArea>
      }
      <div className="btn-box-center"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          textAlign: "center",
          background: "#fff",
          borderTop: "1px solid #ddd",
          padding: 5,
        }}>
        <button className="btn-l2 btn-yellowish2" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        {props.isUpdate &&
          <button className="btn-l2 btn-red" onClick={() => { goDelete(); }}>삭제</button>
        }
        <button className="btn-l2 btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
