import { useRef, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import ExcelDownComponent from "@/pcomponents/common/excel/down";
import getXcolumnJson from "./xcolumn/list";

const MonthPrescribeExcelDownList = forwardRef((props: any, ref) => {
  let myProps: any = {
    ...props
  };
  const xColumnArr = getXcolumnJson();

  const customCellRenderer = (inOpt: any) => {
    let opObj = {
      "rowData": {},
      "idx": 0,
      "key": "",
      "value": "",
      ...inOpt
    };
    let value_str = opObj.value;
    if (opObj["key"] == "a_is_success") {
      if (value_str == "1") {
        value_str = "처방";
      } else {
        value_str = "";
      }
    } else if (opObj["key"] == "a_is_success_check") {
      if (value_str == "1") {
        value_str = "확인";
      } else {
        value_str = "";
      }
    } else if (opObj["key"] == "a_is_request_counsel") {
      if (value_str == "1") {
        value_str = "요청";
      } else {
        value_str = "";
      }
    }

    return value_str;
  };

  const ExcelDownComponentRef = useRef<any>(null);
  const goDownExcel = (inData: any) => {
    let optObj = {
      xColumnArr: xColumnArr,
      is_add_example: false,
      infoArr: [],
      last_daily_report_arr: [],
      daily_report_arr: [],
      excelFileName: "데이터" + DateFunc.get_date_format(new Date(), "Ymd_his"),
      ...inData
    };
    ExcelDownComponentRef.current.setInitData(optObj);
    setTimeout(() => {
      ExcelDownComponentRef.current.goDownExcel();
    }, 100);
  };

  useImperativeHandle(ref, () => ({
    goDownExcel
  }));

  return (
    <>
      <ExcelDownComponent ref={ExcelDownComponentRef}
        customCellRenderer={customCellRenderer}></ExcelDownComponent>
    </>
  );
});

export default MonthPrescribeExcelDownList;