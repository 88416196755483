import strFunc from "@/lib/lyg/string";

function AttendReceiveListArea(props:any){
  let myProps={
    attend_receive_arr:[],
    on_change_data:(inData:any)=>{},
    on_delete_data:(inData:any)=>{},
    ...props
  };
  let attend_receive_arr=myProps.attend_receive_arr;

  const get_attend_receive_tags=()=>{
    let attend_receive_tags=attend_receive_arr.map((attend_receive:any,idx:number)=>{
      return (
        <p key={idx}>
          <span>
            {idx+1}.
          </span>
          <span>
            {attend_receive["a_user_name"]}
          </span>
          <span>
            <button className="btn-s btn-red ml-2" onClick={()=>{myProps.on_delete_data(attend_receive["a_user_seq"]);}} >X</button>
          </span>
        </p>
      );
    });

    if(strFunc.is_empty(attend_receive_tags)){
      attend_receive_tags=(
        <div style={{lineHeight:"50px",textAlign:"center"}}>
          내용이 없습니다.
        </div>
      );
    }

    return attend_receive_tags;
  };

  return (
    <div>
      {get_attend_receive_tags()}
    </div>
  );
}
export default AttendReceiveListArea;