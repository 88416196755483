import ReportListArea from "./list";
import ReportWriteArea from "./write";

function ReportMain(props: any) {
  let myProps = {
    row_num: 0,
    info: {},
    set_info: (info: any) => { },
    select_date:"",
    list: (inData: any) => { },
    is_show_write:false,
    set_is_show_write:(inData: any) => { },
    is_view_mode:false,
    set_is_view_mode:(inData: any) => { },
    Style: {},
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let prescribe_arr = [];
  if (info.prescribe_arr) {
    prescribe_arr = info.prescribe_arr;
  }

  return (
    <div>
      {(myProps.is_show_write||prescribe_arr.length==0) &&
        <ReportWriteArea
          stu_info={myProps.info}
          select_date={myProps.select_date}
          set_is_show_write={myProps.set_is_show_write}
          list={myProps.list}
          set_write_info={(inData:any)=>{
            info.study_prescribe_write_info=inData;
          }}
          Style={Style}
        ></ReportWriteArea>
      }
      <div>
        <ReportListArea
          prescribe_arr={prescribe_arr}
          set_prescribe_arr={(inData: any) => {
            info.prescribe_arr = inData;
            myProps.set_info(info);
          }}
          is_view_mode={myProps.is_view_mode}
          Style={Style}
          list={myProps.list}
        ></ReportListArea>
      </div>
    </div>
  );
};
export default ReportMain;