//import ContentTitle from "@/pcomponents/comp_stu/content/con_title";
import {smenu} from "@/pcomponents/comp_stu/layout/menu/data/menu_arr";
const ContentTitle=(props:any)=>{
  let con_title=props.title;

  if(con_title==""){
    for(let i=0;i<smenu.length;i++){
      let menu=smenu[i];
      if(window.location.pathname==menu.url){
        con_title=menu.name;
      }
    }
  }

  return (
    <div className="stu_con_wrap" style={{borderBottomWidth:1}}>
      <h5>
        <span className="height_bar" style={{top:"20px"}}></span>
        <p className="pl-3">{con_title}</p>
      </h5>
    </div>
  );
};

ContentTitle.defaultProps = {
  title:"",
}

export default ContentTitle;