import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';

const ReceiverListComponent = forwardRef((props:any, ref) => {
  let myProps={
    "receive_arr":[],
    ...props
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getReceiveArr,set_receive_arr
  }));

  let default_receve_row={
    "user_seq":"",
    "phone_num":"",
    "user_name":"",
  };
  const [receive_arr,set_receive_arr]=useState(myProps.receive_arr);//[{phone_num:"",user_name:"",user_seq:""}]

  const getReceiveArr=()=>{
    return receive_arr;
  };

  const onChangeListRowInput=(row_num:number,key:string,value:string)=>{
    let tmp_receve_arr=[...receive_arr];
    let val_str=value;

    if(key=="phone_num"){
      val_str=strFunc.autoHypenPhone(val_str);
    }

    tmp_receve_arr[row_num][key]=val_str;
    set_receive_arr(tmp_receve_arr);
  };

  const addReceiveUser=()=>{
    set_receive_arr([
      ...receive_arr,
      ...[{
        ...default_receve_row
      }]
    ]);
  };

  const removeRecieveUserByIdx=(idx_num:number)=>{
    let tmp_receve_arr=[];
    for(let i=0;i<receive_arr.length;i++){
      if(idx_num!=i){
        tmp_receve_arr.push(receive_arr[i]);
      }
    }
    set_receive_arr(tmp_receve_arr);
  };


  let list_tags=receive_arr.map((item:any,idx:number)=>{
    return (
      <tr key={idx}>
        <td className="text-center">
          {idx+1}
        </td>
        <td className="text-center">
          <input type="text" name="phone_num" value={item.phone_num} 
            onChange={(e:any)=>{onChangeListRowInput(idx,e.target.name,e.target.value);}} 
            style={{"width":"100%"}} />
        </td>
        <td className="text-center">
        <input type="text" name="user_name" value={item.user_name} 
            onChange={(e:any)=>{onChangeListRowInput(idx,e.target.name,e.target.value);}} 
            style={{"width":"100%"}} />
        </td>
        <td className="text-center">
          <button className="btn-s btn-red" onClick={()=>{removeRecieveUserByIdx(idx);}}>X</button>
        </td>
      </tr>
    );
  });
  if(list_tags==""){
    list_tags=(
      <tr>
        <td colSpan={99}>
          <div style={{textAlign:"center"}}>
            내용 없음
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div className="list-table-div mt-1">
      <h5>
        대상자
        <button className="btn-s btn-gray ml-1" onClick={()=>{addReceiveUser();}} >추가</button>
      </h5>
      <table>
        <colgroup>
          <col width="50px" ></col>
          <col width="120px" ></col>
          <col width="100px" ></col>
          <col width="30px" ></col>
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>핸드폰</th>
            <th>이름</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {list_tags}
        </tbody>
      </table>
    </div>
  );
});
export default ReceiverListComponent;