import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_title":"",
    "a_sort":"",
    "a_is_use":"1",
    "a_order_num":"",
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="a_order_num"){
      value=strFunc.comma(value);
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:response.data["data"][0]["a_seq"]});
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
              </td>
            </tr>
            {/* <tr>
              <th>구분</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_sort" value={rowData.a_sort} onChange={handleInputChange} placeholder="구분" />
              </td>
            </tr> */}
            <tr>
              <th>사용여부</th>
              <td colSpan={3}>
                <select className="row-input" name="a_is_use" value={rowData.a_is_use} onChange={handleInputChange}>
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>순번</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_order_num" value={rowData.a_order_num} onChange={handleInputChange} placeholder="순번" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
    </div>
  );
});

export default WriteFrameContent;
