import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../list/xcolumn/list";
import TimeHisInput from "@/pcomponents/common/date/time_his";

function WritePopupContent(props:any) {
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_is_mcomp_public":"1",
    "a_par_mcomp_seq":user.comp_seq,
    "a_par_id":"",
    "a_par_seq":"",
    "a_writer_mcomp_seq":user.comp_seq,
    "a_writer_mcomp_user_seq":user.user_seq,
    "a_writer_seq":"",
    "a_writer_name":user.user_name,
    "a_stu_mcomp_seq":user.comp_seq,
    "a_stu_mcomp_user_seq":user.user_seq,
    "a_stu_seq":"",
    "a_stu_name":user.user_name,
    "a_start_date":"",
    "a_end_date":"",
    "a_is_reserve":"",
    "a_reserve_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_reserve_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_success_sort":"amount",
    "a_amount_sort":"개",
    "a_amount_num":0,
    "a_success_amount":0,
    "a_success_time_sec":3600,
    "a_time_sec":0,
    "a_is_success":"",
    "a_state":"ready",
    "a_subject":"",
    "a_title":"",
    "a_content":"",
    "a_result_memo":"",
    "a_is_need_photo":"",
  });

  useEffect(()=>{
    
    if(props.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>공개</th>
              <td>
                <select className="row-input" name="a_is_mcomp_public" value={rowData.a_is_mcomp_public} onChange={handleInputChange} >
                  <option value="">비공개</option>
                  <option value="1">공개</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>주제</th>
              <td>
                <input type="text" className="row-input" name="a_subject" value={rowData.a_subject} 
                  onChange={handleInputChange} placeholder="주제" style={{width:90}}/>
                <select className="row-input ml-1" style={{width:80}} onChange={(e)=>{
                    setRowData({
                      ...rowData,
                      ...{"a_subject": e.target.value}
                    });
                  }} >
                  <option value="">선택</option>
                  <option value="수학">수학</option>
                  <option value="영어">영어</option>
                  <option value="국어">국어</option>
                  <option value="역사">역사</option>
                  <option value="기타">기타</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <textarea className="row-input h-20" name="a_content" value={rowData.a_content} onChange={handleInputChange} placeholder="내용" ></textarea>
              </td>
            </tr>
            <tr>
              <th>목표구분</th>
              <td>
                <select className="row-input" name="a_success_sort" value={rowData.a_success_sort} onChange={handleInputChange} >
                  <option value="">없음</option>
                  <option value="time">시간</option>
                  <option value="amount">분량</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>분량</th>
              <td>
                <input type="text" className="row-input" name="a_success_amount" 
                  value={rowData.a_success_amount} onChange={handleInputChange} 
                  placeholder="분량" style={{width:70}} />
                <select className="row-input ml-1" name="a_amount_sort" 
                  value={rowData.a_amount_sort} onChange={handleInputChange} style={{width:70}} >
                  <option value="페이지">페이지</option>
                  <option value="챕터">챕터</option>
                  <option value="개">개수</option>
                  <option value="문제">문제</option>
                  <option value="단원">단원</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>목표시간</th>
              <td>
                <TimeHisInput
                is_after_name={true}
                value={rowData.a_success_time_sec}
                onChangeData={(value:any)=>{
                  handleInputChange({
                    target:{
                      name:"a_success_time_sec",
                      value:value,
                      type:"text",
                      checked:false
                    }
                  });
                }}
                ></TimeHisInput>
              </td>
            </tr>
            <tr>
              <th>예정여부</th>
              <td>
                <select className="row-input" name="a_is_reserve" value={rowData.a_is_reserve} onChange={handleInputChange} >
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
              </td>
            </tr>
            {rowData.a_is_reserve=="1"&&
            <>
            <tr>
              <th>예정일</th>
              <td>
                <DatePicker 
                  selected={rowData.a_reserve_start_date!=""?new Date(rowData.a_reserve_start_date):null} 
                  onChange={(date:Date) => {
                    onChangeDatePicker("a_reserve_start_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>마감일</th>
              <td>
                <DatePicker 
                  selected={rowData.a_reserve_end_date!=""?new Date(rowData.a_reserve_end_date):null} 
                  onChange={(date:Date) => {
                    onChangeDatePicker("a_reserve_end_date",date);
                  }}
                  locale={ko} 
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            </>
            }
            <tr>
              <th>사진필요</th>
              <td>
                <select className="row-input" name="a_is_need_photo" value={rowData.a_is_need_photo} onChange={handleInputChange} >
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;