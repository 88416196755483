import noimg_ico from "@/img/ico/noimg.png";

function FileSlideArea(props: any) {
  let myProps = {
    Style:{},
    infoArr:[],
    openFileSlidePopup:(inData:any)=>{},
    goDownLoad:(inData:any)=>{},
    goDeleteFile:(inData:any)=>{},
    is_view:false,
    is_show_down:false,
    ...props
  };
  let Style = myProps.Style;
  let infoArr=myProps.infoArr;
  let is_view=myProps.is_view;

  const get_file_tags_by_file_arr = (file_arr: any[]) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let file_idx_num = idx + 1;
      let icon_img = noimg_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} className={Style.row_content_file_row}>
          {file_info.is_image == "1" &&
            <a style={{ cursor: "pointer" }} onClick={() => {
              myProps.openFileSlidePopup({ "select_file_idx": idx });
            }} >
              <img src={icon_img} className={Style.row_content_file_row_thum} />
            </a>
          }
          <p>
            {file_idx_num}.
            <span className="ml-1">{file_info.a_oriname}</span>
            {myProps.is_show_down == true &&
              <button className="btn-s btn-gray ml-1" onClick={() => { myProps.goDownLoad(file_info); }}>다운</button>
            }
            {is_view == false &&
              <button className="btn-s btn-red ml-1" onClick={() => { myProps.goDeleteFile([file_info]); }} >X</button>
            }
          </p>
        </div>
      );
    });

    return file_tags;
  };

  let file_tags: any = "";
  if (infoArr) {
    file_tags = get_file_tags_by_file_arr(infoArr);
  }

  return (
    <div style={{ height: "170px", position: "relative" }}>
      <div className={Style.row_content_file_row_wrap}>
        <div className={Style.row_content_file_row_wrap2}>
          {file_tags}
        </div>
      </div>
    </div>
  );
};
export default FileSlideArea;