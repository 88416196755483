import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function WriteAreaComponent(props: any) {
  let myProps = {
    rowData:{},
    setRowData:(inData:any)=>{},
    room_info_arr:[],
    teacher_arr:[],
    xColumnArr:{},
    ...props
  };
  let rowData=myProps.rowData;
  let room_info_arr=myProps.room_info_arr;
  let teacher_arr=myProps.teacher_arr;
  let xColumnArr=myProps.xColumnArr;
  let select_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let up_row_data: any = {
      ...rowData,
      ...{ [name]: value }
    };
    if (name == "a_max_stu") {
      up_row_data[name] = strFunc.comma(value);
    } else if (name == "a_room_seq") {
      let room_name_str = "";
      for (let i = 0; i < room_info_arr.length; i++) {
        if (room_info_arr[i].a_seq == value) {
          room_name_str = room_info_arr[i].a_name;
        }
      }
      up_row_data["a_room_name"] = room_name_str;
    } else if (name == "a_main_teacher_seq") {
      let teacher_name = "";
      for (let i = 0; i < teacher_arr.length; i++) {
        if (teacher_arr[i]["a_seq"] == value) {
          teacher_name = teacher_arr[i]["a_user_name"];
        }
      }
      up_row_data["a_main_teacher"] = teacher_name;
    } else if (name == "a_basic_h") {
      value = strFunc.comma(value);
      value = value.substring(0, 2);
      if (parseInt(value) >= 24) {
        value = "23";
      }
      if (parseInt(value) < 0) {
        value = "0";
      }
      up_row_data["a_basic_h"] = value;
    } else if (name == "a_basic_m") {
      value = strFunc.comma(value);
      value = value.substring(0, 2);
      if (parseInt(value) >= 60) {
        value = "59";
      }
      if (parseInt(value) < 0) {
        value = "0";
      }
      up_row_data["a_basic_m"] = value;
    }

    myProps.setRowData(up_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  return (
    <div className="write_table_small mt-1">
      <table>
        <colgroup>
          <col width="100px"></col>
          <col width="30%"></col>
          <col width="100px"></col>
          <col width="30%"></col>
        </colgroup>
        <tbody>
          <tr>
            <th>수업명</th>
            <td>
              <input type="text" className="row-input" name="a_name" value={rowData.a_name} onChange={handleInputChange} placeholder="수업명" />
            </td>
            <th>메모</th>
            <td>
              <textarea className="row-input h-10" name="a_memo" value={rowData.a_memo} onChange={handleInputChange} placeholder="메모" ></textarea>
            </td>
          </tr>
          <tr>
            <td colSpan={4} className="text-center" >
              <span>
                상태:
                <select className="row-input" name="a_state" value={rowData.a_state}
                  onChange={handleInputChange} style={{ width: 60 }}>
                  {xColumnArr.select_arr.a_state.map((item: any, idx: number) => {
                    return (
                      <option value={item.value} key={idx}>{item.text}</option>
                    );
                  })}
                </select>
              </span>
              <span className="ml-1">
                과목:
                <input type="text" className="row-input" name="a_subject" value={rowData.a_subject}
                  onChange={handleInputChange} placeholder="과목" style={{ width: 60 }} />
                <select className="row-input ml-1" name="a_subject" value={rowData.a_subject} 
                  onChange={handleInputChange} style={{ width: 55 }} >
                  <option value="">과목</option>
                  {select_option_arr.map((item:any,idx:number)=>{
                    return (
                      <option key={idx} value={item["value"]}>{item["text"]}</option>
                    );
                  })}
                </select>
              </span>
              <span className="ml-1">
                기간타입:
                <select className="row-input" name="a_period_sort" value={rowData.a_period_sort}
                  onChange={handleInputChange} style={{ width: 60 }} >
                  {xColumnArr.select_arr.a_period_sort.map((item: any, idx: number) => {
                    return (
                      <option value={item.value} key={idx}>{item.text}</option>
                    );
                  })}
                </select>
              </span>
              <span className="ml-1">
                정원:
                <input type="text" className="row-input" name="a_max_stu" value={rowData.a_max_stu}
                  onChange={handleInputChange} placeholder="정원" style={{ width: 60 }} />
              </span>
              <span className="ml-1">
                준비물:
                <input type="text" className="row-input" name="a_material" value={rowData.a_material}
                  onChange={handleInputChange} placeholder="준비물" style={{ width: 80 }} />
              </span>
            </td>
          </tr>
          {rowData.a_period_sort == "period" &&
            <tr>
              <th>기간</th>
              <td colSpan={3}>
                <div style={{ display: "inline-block", width: 100 }}>
                  <DatePicker
                    selected={rowData.a_start_date != "" ? new Date(rowData.a_start_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_start_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>~
                <div style={{ display: "inline-block", width: 100 }}>
                  <DatePicker
                    selected={rowData.a_end_date != "" ? new Date(rowData.a_end_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_end_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
                <button className="btn-s btn-gray" onClick={() => {
                  myProps.setRowData({
                    ...rowData,
                    ...{
                      a_start_date: "",
                      a_end_date: "",
                    }
                  });
                }}>X</button>
              </td>
            </tr>
          }
          <tr>
            <td colSpan={4} className="text-center">
              <span className="ml-1">
                강의실:
                <select className="row-input ml-2" name="a_room_seq" value={rowData.a_room_seq}
                  onChange={handleInputChange} style={{ width: 90 }} >
                  <option value="">선택없음</option>
                  {
                    room_info_arr.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.a_seq} >
                          {item.a_name}
                        </option>
                      );
                    })
                  }
                </select>
              </span>
              <span className="ml-1">
                선생님:
                <select className="row-input ml-2" name="a_main_teacher_seq" value={rowData.a_main_teacher_seq}
                  onChange={handleInputChange} style={{ width: 90 }} >
                  <option value="">선택없음</option>
                  {teacher_arr.map((item: any, idx: number) => {
                    return (
                      <option value={item.a_seq} key={idx} >{item.a_user_name}</option>
                    );
                  })}
                </select>
              </span>
              <span className="ml-2">
                기본수업시간:
                <input type="text" className="text-center" name="a_basic_h" value={strFunc.str_pad({ "str": rowData.a_basic_h, "pad_length": 2 })}
                  onChange={handleInputChange} style={{ width: 30 }} />:
                <input type="text" className="text-center" name="a_basic_m" value={strFunc.str_pad({ "str": rowData.a_basic_m, "pad_length": 2 })}
                  onChange={handleInputChange} style={{ width: 30 }} /> 부터
                <select className="ml-1"
                  name="a_basic_length_sec"
                  value={rowData.a_basic_length_sec}
                  onChange={handleInputChange}
                  style={{ border: "1px solid #ddd", width: 70 }}>
                  <option value="1800">30분</option>
                  <option value="3600">1시간</option>
                  <option value="5400">1시간반</option>
                  <option value={3600 * 2}>2시간</option>
                  <option value={3600 * 2 + 1800}>2시간반</option>
                  <option value={3600 * 3}>3시간</option>
                  <option value={3600 * 4}>4시간</option>
                </select>
                <select className="ml-1"
                  name="a_basic_row_cnt"
                  value={rowData.a_basic_row_cnt}
                  onChange={handleInputChange}
                  style={{ border: "1px solid #ddd", width: 50 }}>
                  <option value="1">1번</option>
                  <option value="2">2번</option>
                  <option value="3">3번</option>
                  <option value="4">4번</option>
                  <option value="5">5번</option>
                  <option value="6">6번</option>
                  <option value="7">7번</option>
                  <option value="8">8번</option>
                  <option value="9">9번</option>
                  <option value="10">10번</option>
                  <option value="11">11번</option>
                  <option value="12">12번</option>
                </select>
              </span>
              <span className="ml-1">
                같은수업요일:
                <div style={{ marginLeft: 3, display: "inline-block" }}>
                  <SelectBoxBar
                    valueTextArr={[
                      { "value": "0", "text": "일" },
                      { "value": "1", "text": "월" },
                      { "value": "2", "text": "화" },
                      { "value": "3", "text": "수" },
                      { "value": "4", "text": "목" },
                      { "value": "5", "text": "금" },
                      { "value": "6", "text": "토" },
                    ]}
                    value={rowData.a_same_days_week}
                    name={"a_same_days_week"}
                    on_change={(inData: any) => {
                      handleInputChange({
                        target: {
                          name: "a_same_days_week",
                          value: inData["value"],
                          text: inData["text"],
                        }
                      });
                    }}
                    is_arr_value={true}
                    value_split_str={","}
                    style_type={"small"}
                  ></SelectBoxBar>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default WriteAreaComponent;