import strFunc from '@/lib/lyg/string';
import { Grid, GridOptions, ModuleRegistry } from "@ag-grid-community/all-modules";

class AggridCustomFunc
{
  static getAggridListByXcolumn(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      "x_column_list_arr":[],
      'gridOpt':{},
      'xColumnArr':{}
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let x_column_list_arr=optObj["x_column_list_arr"];
    let gridOpt=optObj["gridOpt"];
    let xColumnArr=optObj["xColumnArr"];

    let aggridList=[];
    if(gridOpt.is_add_rowDrag){
      let drag_column:any={
        headerName:"",
        field: "row_drag",
        width: 37,
        filter:false,
        editable:false,
        suppressMenu:true,
        rowDrag:true,
      };
      if(gridOpt.fix_left_num>0){
        drag_column["pinned"]="left";
      }
      aggridList.push(drag_column);
    }
    if(gridOpt.is_add_checkbox){
      let check_box_column:any={
        headerName:"",
        field: "chk_box",
        width: 37,
        headerCheckboxSelection:true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly:true,
        filter:false,
        editable:false,
        suppressMenu:true
      };
      if(gridOpt.fix_left_num>0){
        check_box_column["pinned"]="left";
      }
      aggridList.push(check_box_column);
    }
    if(gridOpt.is_idx_num){
        var idx_num_column:any={
          headerName:"No",
          field: "idx_num",
          width: 60,
          filter:false,
          editable:false,
          suppressMenu:true,
          valueFormatter:function(params:any){
              return strFunc.comma(params.value);
          },
          cellRenderer:gridOpt["cellRenderer"]
      }
      if(gridOpt.fix_left_num>0){
        idx_num_column["pinned"]="left";
      }
      aggridList.push(idx_num_column);
    }
    var fix_left_num=parseInt(gridOpt["fix_left_num"]);
    var col_idx=0;
    for(let idx=0;idx<x_column_list_arr.length;idx++){
        var column_val=x_column_list_arr[idx];
        var key=column_val["key"];
        if(column_val["is_show"]==undefined){
            column_val["is_show"]="1";
        }
        var tmp_coldef:any={
            headerName:column_val["name"],
            field:key,
            width:parseInt(column_val["width"])
        };

        if(gridOpt["is_grid"]){
          if(strFunc.str_in_array(key,xColumnArr.view_col_arr)==-1){
            tmp_coldef["editable"]=true;
          }
        }

        //cellClassRulse
        if(column_val["cellClassRules"]!=undefined){
          tmp_coldef["cellClassRules"]=column_val["cellClassRules"];
        }

        if(column_val["is_show"]!="1"){
            tmp_coldef["hide"]=true;
        }else{
            if(col_idx<fix_left_num){
                tmp_coldef["pinned"]='left';
            }
        }
        if(xColumnArr.select_arr[key]!=undefined){
            tmp_coldef['cellRenderer']=function(params:any){
                var tmp_key=params.colDef.field;
                var tmp_sel_arr=xColumnArr.select_arr[tmp_key];
                for(var tmp_i=0;tmp_i<tmp_sel_arr.length;tmp_i++){
                    if(tmp_sel_arr[tmp_i]["value"]==params.value){
                        return tmp_sel_arr[tmp_i]["text"];
                    }
                }
                return params.value;
            };
            //tmp_coldef["cellEditor"]="agRichSelectCellEditor";
            tmp_coldef["cellEditorParams"]=function(params:any){
                var tmp_key=params.colDef.field;
                var tmp_sel_arr=xColumnArr.select_arr[tmp_key];
                var tmp_value_arr=[];
                var tmp_sel_col_val_arr:any=[];
                for(var tmp_i=0;tmp_i<tmp_sel_arr.length;tmp_i++){
                    tmp_value_arr.push(tmp_sel_arr[tmp_i]["value"]);
                    tmp_sel_col_val_arr[tmp_sel_arr[tmp_i]["value"]]=tmp_sel_arr[tmp_i]["text"];
                }
                return {
                    values:tmp_value_arr,
                    cellRenderer:function(render_params:any){
                        if(tmp_sel_col_val_arr[render_params.value]!=undefined){
                            render_params.valueFormatted=tmp_sel_col_val_arr[render_params.value];
                        }
                        return render_params.valueFormatted;
                    }
                }
            };
        }else if(strFunc.str_in_array(key,xColumnArr.number_col_arr)!=-1){
            tmp_coldef["valueFormatter"]=function(params:any){
                if(params.value==""||params.value==undefined){params.value=0;}
                return strFunc.comma(params.value);
            };
            tmp_coldef["comparator"]=function(valueA:number, valueB:number, nodeA:any, nodeB:any, isInverted:any){
                //숫자정렬
                return valueA-valueB;
            };
        }else{
          tmp_coldef['cellRenderer']=gridOpt["cellRenderer"];
        }

        aggridList.push(tmp_coldef);
        if(column_val["is_show"]=="1"){
            col_idx++;
        }
    }

    return aggridList;
  }
  static getXcolumnListByAggrid(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      "aggrid_list":{}
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }

    let x_column_list_arr:any=[];

    return x_column_list_arr;
  }
  static toggleGridEditing(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      "gridRef":{},
      "xColumnArr":{},
      "gridOpt":{},
      "setGridOpt":()=>{},
      "columnDefs":{},
      "setColumnDefs":()=>{},
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let gridRef=optObj["gridRef"];
    let xColumnArr=optObj["xColumnArr"];
    let gridOpt=optObj["gridOpt"];
    let setGridOpt=optObj["setGridOpt"];
    let columnDefs=optObj["columnDefs"];
    let setColumnDefs=optObj["setColumnDefs"];
    
    if(gridOpt.is_able_grid==false){
      return false;
    }
    
    setGridOpt({
      ...gridOpt,
      ...{is_grid:!gridOpt.is_grid}
    });
    let is_grid=!gridOpt.is_grid;

    const {api, columnApi} = gridRef.current as GridOptions;
    if (api==null || columnApi==null) { return false; }

    //복붙
    if(is_grid){
      api.setSuppressClipboardPaste(true);
    }else{
      api.setSuppressClipboardPaste(false);
      api.stopEditing();
    }
    //포커스셀 정보 얻기
    var focus_cell=api.getFocusedCell();
    var focus_cell_rowIndex=0;
    var focus_cell_key:string | undefined="";
    var is_focus_cell_editable=false;
    if(focus_cell!=null&&focus_cell.column){
        focus_cell_rowIndex=focus_cell.rowIndex;
        focus_cell_key=focus_cell.column.getColDef().field;
    }
    //그리드모드
    let tmp_columnDefs=[...columnDefs];
    for(var i=0;i<columnDefs.length;i++){
      var key=columnDefs[i]["field"];
      var edit_val=is_grid; //true,false
      if(strFunc.str_in_array(key,xColumnArr.view_col_arr)!=-1){
        edit_val=false;
      }
      if(strFunc.str_in_array(key,["row_drag","chk_box","idx_num"])!=-1){
        edit_val=false;
      }
      tmp_columnDefs[i].editable=edit_val;

      //포커스셀 수정모드 여부
      if(edit_val&&focus_cell_key==key){
        is_focus_cell_editable=true;
      }
    }
    setColumnDefs(tmp_columnDefs);
    //업데이트 적용
    api.setColumnDefs(tmp_columnDefs);
    //포커스 유지
    if(focus_cell_key!=""){
      if(focus_cell_key){
        api.setFocusedCell(focus_cell_rowIndex,focus_cell_key);
      }
    }
    //포커스셀 수정모드
    if(is_focus_cell_editable){
      if(focus_cell_key){
        api.startEditingCell({
          rowIndex:focus_cell_rowIndex,
          colKey: focus_cell_key
        });
      }
    }
  }
  static addRowData(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      "data":[],
      "focus_key":"",
      "gridRef":{},

    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let gridRef=optObj["gridRef"];

    const {api, columnApi} = gridRef.current as GridOptions;
    if (api==null || columnApi==null) { return false; }

    var row_data_arr=optObj["data"];
    var focus_key="idx_num";
    if(optObj["focus_key"]!=undefined){focus_key=optObj["focus_key"];}
    var max_idx_num=0;
    var max_rowIndex=-1;
    api.forEachNode(function(rowNode:any,index:number){
        var idx_num=parseInt(rowNode.data.idx_num);
        if(idx_num>max_idx_num){
            max_idx_num=idx_num;
        }
        max_rowIndex=index;
    });
    for(var i=0;i<row_data_arr.length;i++){
        max_idx_num++;
        max_rowIndex++;
        row_data_arr[i]['idx_num']=max_idx_num;
    }
    api.applyTransaction({add:row_data_arr});
    api.setFocusedCell(max_rowIndex,focus_key);
    setTimeout(function(){
      var focus_cell=api.getFocusedCell();
      if(focus_cell){
        let row_id=focus_cell.rowIndex+"";
        var focus_node=api.getRowNode(row_id);
        if(focus_node){
          focus_node.setSelected(true,true);
        }
      }
    },50);
  }
}
export default AggridCustomFunc;