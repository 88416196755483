import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";
import WriteArea from "./area/write";

function EvalFromList(){
  const xColumnArr = getXcolumnJson();
  const listAggridRef= useRef<any>();
  const WriteAreaRef=useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_title:"",
      s_subject:"",
      s_addon_detail:"1",
      s_is_use:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [write_data,set_write_data]=useState({
    is_show:false,
    is_update:false,
    row_data:{},
  });

  useEffect(()=>{
    
  },[]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval_form/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( () => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval_form/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("삭제 되었습니다.");
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const go_open_write_page=(is_update:boolean)=>{
    let w_opeon_data_json={
      ...write_data,
      is_show:true,
      row_data:{},
      is_update:false,
    };
    if(is_update){
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }else if(selected_row.length!=1){
        alert("한개만 선택해 주세요.");
        return false;
      }
      w_opeon_data_json.row_data=selected_row[0];
      w_opeon_data_json.is_update=true;
    }

    set_write_data(w_opeon_data_json);
  };

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="row_view_par_phone"){
      let par_phone_num="학부모 미등록";
      if(params.data.link_user_arr&&params.data.link_user_arr[0]&&params.data.link_user_arr[0]["par_info"]){
        par_phone_num=params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str=par_phone_num;
    }
 
    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    //let selected_row=listAggridRef.current.getSelectedRows();
  }

  return (
    <CompLayout >
      <div className="con_wrap">
        <div style={{display:(write_data.is_show?"none":"block")}}>
          <SearchArea
            list_opt={pageData.listOpt}
            list={list}
          ></SearchArea>
          <div className="mt-4">
            <div className="btn-box-right">
              <button className="btn-m btn-sky" onClick={()=>{go_open_write_page(false);}}>신규등록</button>
              <button className="btn-m btn-sky" onClick={()=>{go_open_write_page(true);}} >수정</button>
              <button className="btn-m btn-red" onClick={goDelete}>삭제</button>
            </div>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{...xColumnArr}}
              list={list}
              gridOpt={{
                fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num:true,
                is_add_checkbox:true,
                floatingFilter:true,
                height:(window.innerHeight || document.documentElement.clientHeight)/2,
                onGridReady:()=>{
                  list({});
                },
                cellRenderer:cellRenderer,
                onSelectionChanged:onSelectionChanged,
              }}
            ></ListAggrid>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
          </div>
        </div>
        {write_data.is_show&&
          <WriteArea
            ref={WriteAreaRef}
            list={list}
            is_update={write_data.is_update}
            row_data={write_data.row_data}
            close={()=>{set_write_data({...write_data,is_show:false});}}
          ></WriteArea>
        }
      </div>
    </CompLayout>
  );
}

export default EvalFromList;