import { useState, useEffect, useCallback } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";

function WritePopupContent(props:any) {
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_par_id":"",
    "a_par_col":"",
    "a_sort_name":"",
    "a_type":"",
  });

  useEffect(()=>{
    
    if(props.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_par_id==""){
      alert("부모구분 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_par_col==""){
      alert("구분 입력이 필요합니다.");
      return false;
    }
    if(rowData.a_sort_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/sort/sort/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        location.reload();
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);
  
  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>부모구분</th>
              <td>
                <input type="text" className="row-input" name="a_par_id" value={rowData.a_par_id} onChange={handleInputChange} placeholder="부모구분" />
              </td>
            </tr>
            <tr>
              <th>구분컬럼</th>
              <td>
                <input type="text" className="row-input" name="a_par_col" value={rowData.a_par_col} onChange={handleInputChange} placeholder="구분컬럼" />
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" className="row-input" name="a_sort_name" value={rowData.a_sort_name} onChange={handleInputChange} placeholder="이름" />
              </td>
            </tr>
            <tr>
              <th>고정</th>
              <td>
                <select className="row-input" name="a_type" value={rowData.a_type} onChange={handleInputChange}>
                  <option value="fix">고정</option>
                  <option value="custom">수동</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
