//import SubjectGroupArea from "@/pages/comp/edu/month_prescribe/common/subject_group";
import DateFunc from '@/lib/lyg/date_func';
import { useRef,forwardRef, useImperativeHandle } from 'react';
import WeekListArea from "@/pages/comp/edu/month_prescribe/common/week_list";

const SubjectGroupArea = forwardRef((props: any, ref) => {
   let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_info:null,
    last_daily_report_arr:[],
    last_mprescribe_arr:[],
    info_arr:[],
    set_info_arr:(inData:any)=>{},
    stu_subject_arr:[],
    set_stu_subject_arr:(inData:any)=>{},
    list:(inData:any)=>{},
    set_refresh:()=>{},
    select_date:DateFunc.get_date_format(new Date(), "Y-m-01"),
    is_view_mode:false,
    set_is_view_mode: (inData: any) => { },
    comment_list:[],
    refresh_comment:(inData: any) => { },
    ...props
  };

  const WeekListAreaArrRef=useRef<any>([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    go_auto_save
  }));

  const go_auto_save=()=>{
    for(let i=0;i<WeekListAreaArrRef.current.length;i++){
      if(WeekListAreaArrRef.current[i]){
        WeekListAreaArrRef.current[i].go_auto_save_data();
      }
    }
  };

  const get_subject_tags=()=>{
    let subject_group_tags=myProps.stu_subject_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx}>
          <div className="mt-1">
            <WeekListArea
              ref={(el:any)=>{WeekListAreaArrRef.current[idx]=el;}}
              select_mcomp_data={myProps.select_mcomp_data}
              stu_info={myProps.stu_info}
              last_daily_report_arr={myProps.last_daily_report_arr}
              last_mprescribe_arr={myProps.last_mprescribe_arr}
              info_arr={myProps.info_arr}
              set_info_arr={myProps.set_info_arr}
              list={myProps.list}
              set_refresh={myProps.set_refresh}
              select_date={myProps.select_date}
              is_view_mode={myProps.is_view_mode}
              set_is_view_mode={myProps.set_is_view_mode}
              subject_name={item["a_name"]}
              comment_list={myProps.comment_list}
              refresh_comment={myProps.refresh_comment}
            ></WeekListArea>
          </div>
        </div>
      );
    });

    return subject_group_tags;
  };
  
  return (
  <div>
    {get_subject_tags()}
  </div>
  );
});
export default SubjectGroupArea;