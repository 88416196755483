import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"user_daily_report_card2",
    x_column_list_arr:[
      {"key":"row_view_time","name":"시간","width":"1%","is_show":"1"},
      {"key":"row_view_checkbox","name":"체크","width":"1%","is_show":"1"},
      {"key":"row_view_stu_name","name":"이름","width":"3%","is_show":"1"},
      {"key":"row_view_month_prescribe","name":"처방","width":"2%","is_show":"1"},
      {"key":"row_view_attend","name":"출석","width":"2%","is_show":"1"},
      {"key":"row_view_todo_study","name":"학습","width":"2%","is_show":"1"},
      {"key":"row_view_todo_homework","name":"과제","width":"2%","is_show":"1"},
      {"key":"row_view_homework","name":"숙제","width":"4%","is_show":"1"},
      {"key":"row_view_textbook","name":"본교재","width":"7%","is_show":"1"},
      {"key":"row_view_textbook_study","name":"대단원","width":"8%","is_show":"1"},
      {"key":"row_view_textbook_study_det","name":"소단원","width":"9%","is_show":"1"},
      {"key":"row_view_subtextbook","name":"부교재","width":"7%","is_show":"1"},
      {"key":"row_view_memo","name":"메모","width":"8%","is_show":"1"},
      {"key":"row_view_manage","name":" ","width":"1%","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':200,
      'order_id':'a_stu_grade, a_user_name',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
      "s_user_name_like":""
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr["a_sort_detail"]=[
    {"value":"attend","text":"등원"},
    {"value":"late","text":"등원(지각)"},
    {"value":"absent","text":"결석"},
    {"value":"out","text":"하원"},
  ];
  
  return xColumnArr;
};

export default getXcolumnJson;