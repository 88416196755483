import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../list/xcolumn/list";
import video_ico_img from "@/img/ico/video_ico.png";
import clock_ico_img from "@/img/ico/clock_ico.png";

function ViewPopupContent(props:any) {
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_is_mcomp_public":"1",
    "a_par_mcomp_seq":user.comp_seq,
    "a_par_id":"",
    "a_par_seq":"",
    "a_writer_mcomp_seq":user.comp_seq,
    "a_writer_mcomp_user_seq":user.user_seq,
    "a_writer_seq":"",
    "a_writer_name":user.user_name,
    "a_stu_mcomp_seq":user.comp_seq,
    "a_stu_mcomp_user_seq":user.user_seq,
    "a_stu_seq":"",
    "a_stu_name":user.user_name,
    "a_start_date":"",
    "a_end_date":"",
    "a_is_reserve":"",
    "a_reserve_start_date":"",
    "a_reserve_end_date":"",
    "a_success_sort":"",
    "a_amount_sort":"amount",
    "a_amount_num":0,
    "a_success_amount":0,
    "a_success_time_sec":3600,
    "a_time_sec":0,
    "a_is_success":"",
    "a_state":"ready",
    "a_subject":"",
    "a_title":"",
    "a_content":"",
    "a_result_memo":"",
    "a_is_need_photo":"",
  });

  useEffect(()=>{
    let changeRowData:any={};
    for(let key in rowData){
      if(props.rowData[key]!=undefined){
        changeRowData[key]=props.rowData[key];
      }
    }
    
    setRowData({
      ...rowData,
      ...changeRowData
    });
  },[]);

  let per_str:any=0;
  if(rowData.a_success_sort=="time"){
    let a_success_time_sec=parseInt(strFunc.uncomma(rowData.a_success_time_sec));
    let a_time_sec=parseInt(strFunc.uncomma(rowData.a_time_sec));
    if(a_success_time_sec!=0&&a_time_sec!=0){
      per_str=(a_time_sec/a_success_time_sec)*100;
    }
  }else{
    let a_success_amount=parseInt(strFunc.uncomma(rowData.a_success_amount));
    let a_amount_num=parseInt(strFunc.uncomma(rowData.a_amount_num));
    if(a_success_amount!=0&&a_amount_num!=0){
      per_str=(a_amount_num/a_success_amount)*100;
    }
  }
  if(rowData.a_is_success=="1"){
    per_str=100;
  }
  per_str=strFunc.comma(per_str);

  let left_ico_img=clock_ico_img;
  if(rowData.a_par_id=="video_list"){
    left_ico_img=video_ico_img;
  }

  let state_str="";
  if(xColumnArr.select_arr.a_state){
    for(let i=0;i<xColumnArr.select_arr.a_state.length;i++){
      if(rowData.a_state==xColumnArr.select_arr.a_state[i]["value"]){
        state_str=xColumnArr.select_arr.a_state[i]["text"];
      }
    }
  }

  return (
    <div>
      <table>
        <colgroup>
          <col width="40px"></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            <td>
              <img src={left_ico_img} style={{width:"100%"}} />
            </td>
            <td className="pl-2">
              <h3>{rowData.a_title}</h3>
              <h5>{rowData.a_subject}</h5>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        {
          props.rowData.a_content && props.rowData.a_content.split('\n').map( (line:string,idx:number) => {
            return (<span key={idx}>{line}<br/></span>)
          })
        }
      </div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="150px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>상태</th>
              <td>
                {state_str}
              </td>
            </tr>
            <tr>
              <th>달성률</th>
              <td>
                {per_str} %
              </td>
            </tr>
            <tr>
              <th>시작일</th>
              <td>
                {rowData.a_start_date}
              </td>
            </tr>
            <tr>
              <th>종료일</th>
              <td>
                {rowData.a_end_date}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();props.openOnePopup("write",true,rowData);}}>수정</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
