import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"todo",
    list_id:"make_todo_todo_content_list",
    x_column_list_arr:[
      {"key":"a_sort","name":"내용구분","width":"70","is_show":"1"},
      {"key":"a_title","name":"제목","width":"90","is_show":"1"},
      // {"key":"a_success_sort","name":"목표구분","width":"70","is_show":"1"},
      {"key":"row_view_success_amount","name":"목표량","width":"70","is_show":"1"},
      {"key":"a_amount_sort","name":"단위","width":"50","is_show":"1"},
      {"key":"a_par_id","name":"부모구분","width":"90","is_show":""},
      {"key":"a_par_seq","name":"부모키","width":"90","is_show":""},
      //{"key":"a_success_amount","name":"목표량","width":"100","is_show":"1"},
      {"key":"a_success_time_sec","name":"목표시간","width":"100","is_show":""},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.number_col_arr=["row_view_success_amount"];
  xColumnArr.select_arr["a_sort"]=[
    {"value":"homework","text":"숙제"},
    {"value":"study","text":"학습"},
    {"value":"video","text":"강의"},
  ];
  xColumnArr.select_arr["a_success_sort"]=[
    {"value":"amount","text":"수량"},
    {"value":"time","text":"시간"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;