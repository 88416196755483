import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import MoveTodoDatePopup from "../popup/move_date";
import TodoMultyWritePopup from "@/pages/comp/plan/todo/popup/multy_write";
import ViewFileSlidePopup from "@/pages/comp/basic/file/popup/view_file_slide";
import WriteOptPopupContent from "@/pcomponents/common/xcolumn/popup/write_opt";
import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import SearchArea from "./area/search";

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const findStudentListRef = useRef<any>(null);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_addon_user": "1",
    "s_is_mcomp_public": "1",
    "s_stu_mcomp_seq": user.comp_seq,
    "s_stu_user_seq": [],
    "s_search_text_type": "name",
    "s_search_text": "",
    "s_is_success": "",
    "s_state": "",
    "s_todo_sort": "",
    "s_addon_par_content": "1",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [todo_file_arr, set_todo_file_arr] = useState([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    info_arr: [],
    stu_seq: "",
    stu_name: "",
    file_info_arr: [],
    select_file_idx: 0,
    title: "팝업",
    width: "800px",
    height: "99%",
  });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let list_form_json = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);
    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    list_form_json.s_stu_mcomp_seq = user.comp_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          get_file_list_by_ajax({
            info_arr: response.data["data"]["info_arr"],
            s_start_date: list_form_json.s_start_date,
            s_end_date: list_form_json.s_end_date,
          });
        } else {

        }
      });
  };

  const get_file_list_by_ajax = (inData: any) => {
    let opt_obj = {
      info_arr: infoArr,
      s_start_date: listOpt.s_start_date,
      s_end_date: listOpt.s_end_date,
      ...inData
    };
    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    if (strFunc.is_empty(opt_obj.s_start_date)) {
      return false;
    }
    if (strFunc.is_empty(opt_obj.s_end_date)) {
      return false;
    }
    let tmp_start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(opt_obj.s_start_date), 'day', -1), "Y-m-d");
    let tmp_end_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(opt_obj.s_end_date), 'day', +1), "Y-m-d");
    let list_form_json = {
      "s_date_type": "a_create_date",
      "s_start_date": tmp_start_date,
      "s_end_date": tmp_end_date,
      "s_comp_seq": user.comp_seq,
      "s_par_id": ["home_work_file", "plan_study_file"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_todo_file_arr(response.data["data"]["info_arr"]);
          let tmp_file_arr = response.data["data"]["info_arr"];
          let change_info_arr: any = [...opt_obj["info_arr"]];
          for (let info_i = 0; info_i < change_info_arr.length; info_i++) {
            let info = change_info_arr[info_i];
            let row_file_arr = [];
            for (let i = 0; i < tmp_file_arr.length; i++) {
              let file_info = tmp_file_arr[i];
              if (info["a_reserve_start_date"].substring(0, 10) == file_info["a_create_date"].substring(0, 10)) {
                if (info["a_sort"] == "homework" && file_info["a_par_id"] == "home_work_file" && file_info["a_sort3"] == info["a_stu_mcomp_user_seq"]) {
                  row_file_arr.push(file_info);
                }
                if (info["a_sort"] == "study" && file_info["a_par_id"] == "plan_study_file" && file_info["a_sort3"] == info["a_stu_mcomp_user_seq"]) {
                  row_file_arr.push(file_info);
                }
              }
            }
            change_info_arr[info_i]["row_view_file_arr"] = row_file_arr;
          }
          setInfoArr(change_info_arr);
          listAggridRef.current.setInfoArr(change_info_arr);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const openOnePopup = (sort: string, isUpdate: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      } else if (selected_row.length >= 2) {
        // setPopupData({
        //   ...popupData,
        //   "isOpen":true,
        //   "isUpdate":isUpdate,
        //   "info_arr":selected_row,
        //   "title":"수정",
        //   "sort":"multy_write"
        // });
        // return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    if (sort == "write") {
      if (isUpdate == false) {
        if (listOpt.s_stu_user_seq.length == 0) {
          alert("원생 선택이 필요합니다.");
          return false;
        }
      }
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      stu_seq: listOpt.s_stu_user_seq[0],
      "title": pop_title,
      "sort": sort
    });
  };

  const openMoveDatePopup = () => {
    let pop_title = "일정 미루기";
    let selected_row = listAggridRef.current.getSelectedRows();

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "info_arr": selected_row,
      "sort": "move_date",
    });
  };

  const openListOptPopup = () => {
    let pop_title = "리스트설정";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "list_opt"
    });
  };
  const openViewFileSlidePopup = (inData: any) => {
    let opt_obj = {
      file_info_arr: [],
      select_file_idx: 0,
      ...inData
    };
    let pop_title = "파일보기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      file_info_arr: opt_obj.file_info_arr,
      select_file_idx: opt_obj.select_file_idx,
      "sort": "file_slide",
    });
  };

  const go_change_select_key_val = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      ...inData
    };
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let up_data_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      let row_info = selected_row[i];
      selected_row[i][key] = value;
      let up_data_row: any = {
        "a_ymd": row_info["a_ymd"],
        "a_seq": row_info["a_seq"],
      };
      up_data_row[key] = value;

      up_data_arr.push(up_data_row);
    }
    if (!confirm(up_data_arr.length + "개의 플랜을 수정 하시겠습니까?")) {
      return false;
    }

    let write_form_json = {
      "is_update": "1",
      "data_arr": up_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {

        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_stu_name") {
      openOnePopup("view", true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_success_sort") {
      let sort_str = params.data.a_success_sort;
      if (xColumnArr.select_arr["a_success_sort"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_success_sort"].length; i++) {
          let tmp_option_row = xColumnArr.select_arr["a_success_sort"][i];
          if (tmp_option_row["value"] == sort_str) {
            sort_str = tmp_option_row["text"];
          }
        }
      }
      render_str = (
        <div>
          {sort_str}
        </div>
      );
    } else if (key == "row_view_state") {
      let state_str = params.data.a_state;
      if (xColumnArr.select_arr["a_state"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_state"].length; i++) {
          let tmp_option_row = xColumnArr.select_arr["a_state"][i];
          if (tmp_option_row["value"] == state_str) {
            state_str = tmp_option_row["text"];
          }
        }
      }
      render_str = (
        <div>
          {state_str}
        </div>
      );
    } else if (key == "row_view_per") {
      let per_str = TodoStateFunc.get_percent_by_todo_info(params.data);
      if (params.data.a_is_success == "1") {
        per_str = 100;
      }
      let per_color_json = TodoStateFunc.get_color_of_percent(per_str);
      let per_div_style: any = {};
      per_div_style["color"] = per_color_json.per;
      render_str = (
        <div style={per_div_style}>
          {per_str} %
        </div>
      );
    } else if (key == "row_view_success_amount") {
      if (params.data.a_success_sort == "amount") {
        render_str = params.data.a_amount_num + "/" + params.data.a_success_amount;
      }
    } else if (key == "row_view_time_str") {
      let tmp_time_json: any = strFunc.secondsToTimeJson(params.data.a_time_sec);
      render_str = tmp_time_json.i + ":" + tmp_time_json.s;
      if (tmp_time_json.h != "00") {
        render_str = tmp_time_json.h + ":" + render_str;
      }
    } else if (key == "row_view_reserve_date") {
      render_str = params.data["a_reserve_start_date"].substring(5, 10);
    } else if (key == "row_view_file_cnt") {
      //render_str="첨부";
      let row_file_arr:any = [];
      if (params.data["row_view_file_arr"]) {
        row_file_arr = params.data["row_view_file_arr"];
      }
      let file_btn_class_name = "btn btn-dark";
      if(row_file_arr.length>0){
        file_btn_class_name = "btn btn-blue";
      }
      render_str = (
        <div>
          <button className={file_btn_class_name} onClick={()=>{
            openViewFileSlidePopup({
              file_info_arr:row_file_arr,
              select_file_idx:0
            });
          }}>
            {row_file_arr.length}개
          </button>
        </div>
      );
    }
    return render_str;
  };

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{ minWidth: 220 }}>
            <FindStudentList
              ref={findStudentListRef}
              list={(inOptObj: any) => { list(inOptObj); }}
            ></FindStudentList>
          </div>
          <div className="grow" style={{ minWidth: 400 }}>
            <SearchArea
              xColumnArr={xColumnArr}
              listOpt={listOpt}
              list={list}
              stu_list={(inData: any) => {
                if (findStudentListRef.current) {
                  findStudentListRef.current.list(inData);
                }
              }}
              openOnePopup={openOnePopup}
              openMoveDatePopup={openMoveDatePopup}
              openListOptPopup={openListOptPopup}
              goDelete={goDelete}
              unselect_stu={() => {
                if (findStudentListRef.current) {
                  findStudentListRef.current.setSelectFalseAll();
                }
              }}
              go_change_select_key_val={go_change_select_key_val}
            ></SearchArea>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{ ...xColumnArr }}
              list={list}
              gridOpt={{
                fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num: false,
                is_add_checkbox: true,
                floatingFilter: true,
                onGridReady: () => {
                  list({});
                },
                onCellClicked: cellClickedListener,
                cellRenderer: cellRenderer
              }}
            ></ListAggrid>
            <Paging now_page={listOpt.now_page}
              num_per_page={listOpt.num_per_page}
              total_rec={countInfo.tot}
              onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
              onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
          </div>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              stu_seq={popupData.stu_seq}
              stu_name={popupData.stu_name}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              openOnePopup={openOnePopup}></ViewPopupContent>
          }
          {popupData.sort == "move_date" &&
            <MoveTodoDatePopup
              info_arr={popupData.info_arr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></MoveTodoDatePopup>
          }
          {popupData.sort == "multy_write" &&
            <TodoMultyWritePopup
              info_arr={popupData.info_arr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></TodoMultyWritePopup>
          }
          {
            popupData.sort == "list_opt" &&
            <WriteOptPopupContent
              xColumnArr={xColumnArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteOptPopupContent>
          }
          {
            popupData.sort == "file_slide" &&
            <ViewFileSlidePopup
              file_info_arr={popupData.file_info_arr}
              select_file_idx={popupData.select_file_idx}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewFileSlidePopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;