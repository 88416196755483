import getXcolumnJson from "./xcolumn/list";

const GetEvalExcelData=(inData:any)=>{
  let opt_obj={
    infoArr:[],
    ...inData
  };

  const xEvalColumnArr = getXcolumnJson();
  let eval_form_d_arr=[];
  let info_arr_len=opt_obj.infoArr.length;
  //가장긴 form_d_arr 얻기
  for(let info_i=0;info_i<info_arr_len;info_i++){
    let info=opt_obj.infoArr[info_i];
    let eval_info=info;
    if(eval_info.form_detail_arr){
      let detail_len=eval_info.form_detail_arr.length;
      if(detail_len>eval_form_d_arr.length){
        eval_form_d_arr=[];
        for(let i=0;i<detail_len;i++){
          let detail_f_info=eval_info.form_detail_arr[i];
          eval_form_d_arr.push(detail_f_info);
        }
      }
    }
  }
  //xcolumn에 form_d_arr 추가
  let xcolumn_len=xEvalColumnArr.x_column_list_arr.length;
  for(let col_i=0;col_i<xcolumn_len;col_i++){
    let col_data=xEvalColumnArr.x_column_list_arr[col_i];
    if(col_data["key"]=="a_item_amout"){
      for(let i=0;i<eval_form_d_arr.length;i++){
        let form_col=eval_form_d_arr[i];
        xEvalColumnArr.x_column_list_arr.push({
          "key":"detail_"+i,
          "name":form_col["a_title"],
          "width":"100",
          "is_show":"1"
        });
      }
      for(let i=0;i<eval_form_d_arr.length;i++){
        let form_col=eval_form_d_arr[i];
        xEvalColumnArr.x_column_list_arr.push({
          "key":"detail_score_"+i,
          "name":form_col["a_title"]+" 점수",
          "width":"100",
          "is_show":"1"
        });
      }
      break;
    }
  }
  //데이터
  let excel_info_arr=[];
  for(let info_i=0;info_i<info_arr_len;info_i++){
    let info=opt_obj.infoArr[info_i];
    let eval_info=info;
    
    if(eval_info.detail_arr){
      let detail_len=eval_info.detail_arr.length;
      for(let i=0;i<detail_len;i++){
        let detail_info=eval_info.detail_arr[i];
        info["detail_"+i]=detail_info["a_score_letter"];
        info["detail_score_"+i]=detail_info["a_score"];
      }
    }

    excel_info_arr.push(info);
  }

  let eval_opt_data={
    is_add_example:false,
    xColumnArr:xEvalColumnArr,
    infoArr:excel_info_arr,
  };

  return eval_opt_data;
};
export default GetEvalExcelData;