import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";

function Main(){
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    //goMobilePage();
    navigate("/comp_stu/video/my_list");
  }, []);

  const goMobilePage=()=>{
    if(strFunc.is_empty(user.user_seq)){
      navigate("/comp_stu/video/my_list");
      return false;
    }
    //여기는 로그이아웃
    localStorage.setItem('intoyou_token','');
    let move_url_str=process.env.REACT_APP_MOBILE_URL+"/mobile/login_by_url";
    move_url_str+="?comp_seq="+user.comp_seq;
    move_url_str+="&user_id="+user.user_id;
    location.href=move_url_str;
  }
  return (
    <CompLayout isLeftArea={false} isConTitle={false}>
      
    </CompLayout>
  );
}

export default Main;