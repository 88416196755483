import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import Style from "./css/style.module.css";
import StudySectionList from "./area/study_section_list";

const StudyListArea = forwardRef((props:any, ref) => {
  const myProps={
    ...props
  };
  
  let row_data_default_data={
    
  };
  const StudySectionListRef=useRef<any>(null);
  const [rowData,setRowData]=useState(row_data_default_data);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "rowData":{},
      ...inOptObj
    };
    setRowData({
      ...row_data_default_data,
      ...optObj.rowData
    });
    StudySectionListRef.current.setInitData({
      section_arr:optObj.rowData.section_arr?optObj.rowData.section_arr:[],
      study_arr:optObj.rowData.study_arr?optObj.rowData.study_arr:[],
    });
  };

  const get_select_study_list=()=>{
    return StudySectionListRef.current.get_select_study_list();
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_select_study_list
  }));

  return (
    <div>
      <StudySectionList
        ref={StudySectionListRef}
      ></StudySectionList>
    </div>
  );
});

export default StudyListArea;
