import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import CardGroupArea from "./area/card_group";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

const CardListArea = (props:any) => {
  let myProps={
    tab_id:"",
    infoArr:[],
    lecture_arr:[],
    xColumnArr:{},
    deleteAttend:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    openViewTodoDayPopup:(stu_info:any)=>{},
    ...props
  };

  const get_class_grup_arr=()=>{
    let group_arr:any=[];
    let class_group_arr:any={};//{"class_seq":{"class_info":null,"info_arr":[]}}
    let info_arr_len=myProps.infoArr.length;
    let no_class_seq_arr=[];
    for(let i=0;i<info_arr_len;i++){
      let info=myProps.infoArr[i];
      if(info.class_arr&&info.class_arr.length>0){
        let class_info=info.class_arr[0];
        if(class_info.class_info){
          class_info=class_info.class_info;
          if(class_group_arr[class_info["a_seq"]]==undefined){
            class_group_arr[class_info["a_seq"]]={
              "class_info":class_info,
              "info_arr":[],
            };
          }
          class_group_arr[class_info["a_seq"]]["info_arr"].push(info);
        }else{
          no_class_seq_arr.push(info["a_seq"]);
        }
      }else{
        no_class_seq_arr.push(info["a_seq"]);
      }
    }

    for(let key in class_group_arr){
      let class_group=class_group_arr[key];
      group_arr.push({
        "title":class_group["class_info"]["a_name"],
        "info_arr":class_group["info_arr"],
      });
    }
    if(no_class_seq_arr.length>0){
      let no_class_info_arr=[];
      for(let i=0;i<info_arr_len;i++){
        let info=myProps.infoArr[i];
        if(strFunc.str_in_array(info["a_seq"],no_class_seq_arr)!=-1){
          no_class_info_arr.push(info);
        }
      }
      group_arr.push({
        "title":"소속없음.",
        "info_arr":no_class_info_arr,
      });
    }

    return group_arr;
  };

  const get_time_arr_by_lecture_arr=()=>{
    var now_d_json=DateFunc.get_date_json(new Date());
    let now_day_num=now_d_json["day"];
    let u_time_arr=[];
    let lecture_arr_len=myProps.lecture_arr.length;
    for(let i=0;i<lecture_arr_len;i++){
      let lecture_info=myProps.lecture_arr[i];
      if(lecture_info.stu_union_time_arr){
        for(let j=0;j<lecture_info.stu_union_time_arr.length;j++){
          let u_time_info=lecture_info.stu_union_time_arr[j];
          if(u_time_info["a_day_num"]==now_day_num){
            u_time_info["lecture_name"]=lecture_info["a_name"];
            u_time_arr.push(u_time_info);
          }
        }
      }
    }
    return u_time_arr;
  };

  const get_split_time_by_u_time_arr=(u_time_arr:any)=>{
    let split_time_arr:any={};//split_time_arr[time_h]={"time_h":"","lecture_name_arr":[],u_time_arr:[]};
    let u_time_arr_len=u_time_arr.length;
    for(let i=0;i<u_time_arr_len;i++){
      let u_time_info=u_time_arr[i];
      let start_h=u_time_info["a_start_h"];
      let start_hi=strFunc.HourMinuteToTime(u_time_info["a_start_h"],u_time_info["a_start_m"]);
      let end_hi=strFunc.HourMinuteToTime(u_time_info["a_end_h"],u_time_info["a_end_m"]);
      if(split_time_arr[start_h]==undefined){
        split_time_arr[start_h]={
          "time_h":start_h,
          "start_hi":start_hi,
          "end_hi":end_hi,
          "lecture_name_arr":[],
          u_time_arr:[]
        };
      }
      if(strFunc.str_in_array(u_time_info["lecture_name"],split_time_arr[start_h]["lecture_name_arr"])==-1){
        split_time_arr[start_h]["lecture_name_arr"].push(u_time_info["lecture_name"]);
      }
      split_time_arr[start_h]["u_time_arr"].push(u_time_info);
    }
    return split_time_arr;
  };

  const get_time_group_arr=()=>{
    let group_arr:any=[];

    //유저시간얻기
    let u_time_arr=get_time_arr_by_lecture_arr();
    //시간별로분리(시작시간으로 분리)
    let split_time_arr=get_split_time_by_u_time_arr(u_time_arr);
    //그룹배열얻기
    let added_u_seq_arr=[];
    for(let key in split_time_arr){
      let split_time=split_time_arr[key];
      let title=split_time["start_hi"]+"~"+split_time["end_hi"];
      if(split_time["lecture_name_arr"].length>0){
        title+="("+split_time["lecture_name_arr"].join(",")+")";
      }
      let row_info_arr=[];
      for(let i=0;i<split_time["u_time_arr"].length;i++){
        let u_time_info=split_time["u_time_arr"][i];
        for(let j=0;j<myProps.infoArr.length;j++){
          let u_info=myProps.infoArr[j];
          if(u_time_info["a_stu_seq"]==u_info["a_seq"]){
            added_u_seq_arr.push(u_info["a_seq"]);
            row_info_arr.push(u_info);
          }
        }
      }
      group_arr.push({
        "title":title,
        "info_arr":row_info_arr
      });
    }

    //포함안된 나머지 추가
    let other_info_arr=[];
    for(let i=0;i<myProps.infoArr.length;i++){
      let u_info=myProps.infoArr[i];
      if(strFunc.str_in_array(u_info["a_seq"],added_u_seq_arr)==-1){
        other_info_arr.push(u_info);
      }
    }
    group_arr.push({
      "title":"기타",
      "info_arr":other_info_arr
    });

    return group_arr;
  };

  const get_group_arr=()=>{
    let group_arr:any=[];
    
    if(myProps.tab_id=="class"){
      group_arr=get_class_grup_arr();
    }else if(myProps.tab_id=="time"){
      group_arr=get_time_group_arr();
    }else{
      group_arr=[
        {"title":"전체","info_arr":myProps.infoArr}
      ];
    }
    return group_arr;
  };
  let group_arr=get_group_arr();

  return (
    <div>
      <CardGroupArea
        group_arr={group_arr}
        xColumnArr={myProps.xColumnArr}
        deleteAttend={myProps.deleteAttend}
        openAttendPopup={myProps.openAttendPopup}
        openViewTodoDayPopup={myProps.openViewTodoDayPopup}
      ></CardGroupArea>
    </div>
  );
};

export default CardListArea;