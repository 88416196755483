import CompLayout from "@/pcomponents/comp/layout/layout";
import RoomListWrap from "./area/room_list_wrap";

function DeskMain() {
  
  return (
    <CompLayout isConTitle={false}>
      <RoomListWrap></RoomListWrap>
    </CompLayout>
  );
}

export default DeskMain;