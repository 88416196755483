import { useState, useEffect, useRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const SearchArea = (props: any) => {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    listOpt: {},
    setListOpt: (inData: any) => { },
    xColumnArr: {},
    list: (inData: any) => { },
    ...props
  };
  let select_mcomp_data=myProps.select_mcomp_data;
  let listOpt = myProps.listOpt;
  if (listOpt["s_attend_sort_detail"] === undefined) {
    listOpt["s_attend_sort_detail"] = "";
  }
  let select_attend_s_detail_arr = [
    { value: "", text: "출석" },
    { value: "attend", text: "등원" },
    { value: "late", text: "지각" },
    { value: "absent", text: "결석" },
    { value: "empty", text: "미등원" },
  ];
  const [classSelectArr, setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr, setSchoolNameSelectArr] = useState<any>([]);
  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);
  const search_time_out_ref = useRef<any>(null);

  useEffect(() => {
    getClassFrontArr();
    get_lecture_select_arr();
  }, [select_mcomp_data.mcomp_seq]);

  const go_search_list = (inData: any) => {
    if (search_time_out_ref.current) {
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current = setTimeout(() => {
      myProps.list(inData);
    }, 200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({ ...listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  const getClassFrontArr = () => {
    let class_list_form:any = {
      "s_add_school_arr": "1"
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        class_list_form["api_key"]=select_mcomp_data.api_key;
        class_list_form["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/front_class_arr', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setClassSelectArr(response.data["data"]["class_arr"]);
          setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
        } else {

        }
      });
  };

  const get_lecture_select_arr = () => {
    let class_list_form:any = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        class_list_form["api_key"]=select_mcomp_data.api_key;
        class_list_form["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            strFunc.set_storage("s_stu_lecture_seq", "");
          }
        } else {

        }
      });
  };

  return (
    <div className="search-box relative">
      <SearchLectureSelect
        select_mcomp_data={select_mcomp_data}
        name="s_stu_lecture_seq"
        value={listOpt.s_stu_lecture_seq}
        on_change_input={(inData: any) => {
          let name = inData.name;
          let value = inData.value;
          myProps.list({ now_page: 1, [name]: value });
        }}
        wrap_style={{ width: 90, display: "inline-block" }}
        select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
      ></SearchLectureSelect>
      {/* <select className="search-input_select" name="s_class_seq" value={listOpt.s_class_seq}
        onChange={handleInputChange} style={{ width: 70 }}>
        <option value="">클래스</option>
        {
          classSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select> */}
      <select className="search-input_select" name="s_school_name" value={listOpt.s_school_name}
        onChange={handleInputChange} style={{ width: 60 }} >
        <option value="">학교</option>
        {
          schoolNameSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_grade" value={listOpt.s_stu_grade}
        onChange={handleInputChange} style={{ width: 60 }} >
        <option value="">학년</option>
        <option value="elementary">초</option>
        <option value="middle">중</option>
        <option value="high">고</option>
        {
          myProps.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_attend_sort_detail" value={listOpt.s_attend_sort_detail}
        onChange={handleInputChange} style={{ width: 60 }} >
        {
          select_attend_s_detail_arr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_state" value={listOpt.s_stu_state}
        onChange={handleInputChange} style={{ width: 60 }} >
        <option value="">현황</option>
        <option value="now,ready">대기,재원</option>
        {
          myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      {/* <select className="search-input_select" name="s_is_par_join" value={listOpt.s_is_par_join} onChange={handleInputChange}>
        <option value="">학부모 가입</option>
        <option value="1">가입</option>
        <option value="empty">미가입</option>
      </select> */}
      <span className="basic_search_item_book_mark"
        onClick={() => {
          let change_s_stu_bookmark = listOpt.s_stu_bookmark;
          if (change_s_stu_bookmark == "1") {
            change_s_stu_bookmark = "";
          } else {
            change_s_stu_bookmark = "1";
          }
          strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
          myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
        }}>
        {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
      </span>
      <select className="search-input_select" name="s_search_text_type" value={listOpt.s_search_text_type}
        onChange={handleInputChange} style={{ width: 60 }} >
        <option value="name">이름</option>
        <option value="phone">핸드폰</option>
      </select>
      <input type="text" className="search-input" name="s_search_text"
        style={{ width: 60 }} placeholder="검색어 입력" value={listOpt.s_search_text}
        onChange={handleInputChange} />
      <button className="btn btn-dark" onClick={() => { goSearch(); }} >검색</button>
    </div>
  );
};

export default SearchArea;