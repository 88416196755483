import noimg_ico from "@/img/ico/noimg.png";
import popupFunc from "@/lib/lyg/popup";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import DateFunc from '@/lib/lyg/date_func';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";

function CounselBox(props: any) {
  let myProps = {
    row_num: 0,
    list_length:0,
    counsel_info: {},
    info_arr: [],
    set_info_arr: (inData: any) => { },
    on_change_row_date_picker: (inData: any) => { },
    on_change_row_input: (inData: any) => { },
    select_date_time: "",
    now_date_time: "",
    Style: {},
    goDownLoad: (inData: any) => { },
    goDeleteFile: (inData: any) => { },
    goWriteFileUpload: (inData: any) => { },
    is_view_mode: false,
    update_content_by_ajax: (inData: any) => { },
    delete_coundel_by_ajax: (inData: any) => { },
    change_state_by_ajax: (inData: any) => { },
    list: (inData: any) => { },
    stu_info: {},
    select_arr_a_kind: [],
    select_arr_eval_score: [],
    ...props
  };
  let counsel_info = myProps.counsel_info;
  let select_date_time = myProps.select_date_time;
  let now_date_time = myProps.now_date_time;
  let Style = myProps.Style;
  let is_view_mode = myProps.is_view_mode;
  let info_arr = myProps.info_arr;

  let input_file_names = [];
  if (counsel_info.file_obj) {
    for (let i = 0; i < counsel_info.file_obj.files.length; i++) {
      if (counsel_info.file_obj.files[i].name) {
        input_file_names.push(counsel_info.file_obj.files[i].name);
      }
    }
  }

  let state_text = "준비";
  let state_back_color = "#eab358";
  if (select_date_time < now_date_time) {
    state_text = "미완료";
    state_back_color = "#db6c58";
  }
  if (counsel_info.a_state == "complete") {
    state_text = "완료";
    state_back_color = "#A2B854";
  }

  const get_file_tags_by_file_arr = (file_arr: []) => {
    let file_tags = file_arr.map((file_info: any, idx: number) => {
      let icon_img = noimg_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.thum_img_url;
        if (file_info.a_ext == "png") {
          icon_img = file_info.img_url;
        }
      }

      return (
        <div key={idx} className={Style.row_content_file_row}>
          {file_info.is_image == "1" &&
            <a style={{ cursor: "pointer" }} onClick={() => { popupFunc.openPopup({ url: file_info.img_url }); }} >
              <img src={icon_img} className={Style.row_content_file_row_thum} />
            </a>
          }
          <p>
            {file_info.a_oriname}
            <button className="btn-s btn-gray ml-1" onClick={() => { myProps.goDownLoad(file_info); }}>다운</button>
            <button className="btn-s btn-red ml-1" onClick={() => { myProps.goDeleteFile([file_info]); }} >X</button>
          </p>
        </div>
      );
    });

    return file_tags;
  };

  let file_tags: any = "";
  if (counsel_info.file_arr) {
    file_tags = get_file_tags_by_file_arr(counsel_info.file_arr);
  }

  return (
    <div className={Style.counsel_box}>
      <table className={Style.counsel_box_table} style={{ width: "100%" }}>
        <colgroup>
          <col width={"12%"}></col>
          <col width={"38%"}></col>
          <col width={"12%"}></col>
          <col width={"38%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td colSpan={4}>
              No. {myProps.list_length-myProps.row_num}
            </td>
          </tr>
          <tr>
            <th>예약일</th>
            <td>
              {is_view_mode ?
                counsel_info["a_reserve_date"]
                :
                <div className="ml-1" style={{ display: "inline-block", width: 90, border: "1px solid #ddd" }}>
                  <DatePicker
                    selected={counsel_info.a_reserve_date != "" ? new Date(counsel_info.a_reserve_date) : null}
                    onChange={(date: Date) => {
                      myProps.on_change_row_date_picker({
                        row_num: myProps.row_num,
                        key: "a_reserve_date",
                        value: date
                      });
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              }
              <span className="ml-2">
              {is_view_mode ?
                counsel_info["a_reserve_time"].substring(0, 5)
                :
                  <TimeInputComponent
                    name={"a_reserve_time"}
                    value={counsel_info.a_reserve_time}
                    on_change={(inData: any) => {
                      myProps.on_change_row_input({
                        target: {
                          row_num: myProps.row_num,
                          name: inData.name,
                          value: inData.value,
                        }
                      });
                    }}
                  ></TimeInputComponent>
              }
              </span>
            </td>
            <th>상담일</th>
            <td>
              {is_view_mode ?
                counsel_info["a_counsel_date"]
                :
                <div className="ml-1" style={{ display: "inline-block", width: 90, border: "1px solid #ddd" }}>
                  <DatePicker
                    selected={counsel_info.a_counsel_date != "" ? new Date(counsel_info.a_counsel_date) : null}
                    onChange={(date: Date) => {
                      myProps.on_change_row_date_picker({
                        row_num: myProps.row_num,
                        key: "a_counsel_date",
                        value: date
                      });
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              }
            </td>
          </tr>
          <tr>
            <th>상태</th>
            <td>
              <span className={Style.row_state_span} style={{ background: state_back_color }}
                onClick={() => {
                  myProps.change_state_by_ajax(counsel_info.a_state == "complete" ? "ready" : "complete", [counsel_info]);
                }} >
                {state_text}
              </span>
            </td>
            <th>작성자</th>
            <td>
              {counsel_info["a_writer"]}
            </td>
          </tr>
          <tr>
            <th>구분</th>
            <td colSpan={3}>
              <SelectBoxBar
                valueTextArr={myProps.select_arr_a_kind}
                value={counsel_info.a_kind}
                name={"a_kind"}
                on_change={(inData: any) => {
                  myProps.on_change_row_input({
                    row_num: myProps.row_num,
                    key: inData.name,
                    value: inData.value,
                  });
                }}
              ></SelectBoxBar>
            </td>
          </tr>
          <tr>
            <th style={{fontSize:"11px"}}>상담내용</th>
            <td colSpan={3}>
              <div>
                {is_view_mode ?
                  <MultyLineTextView
                    text={counsel_info["a_content"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_content"}
                    value={counsel_info["a_content"]}
                    class_name={"row-input"}
                    style={{ "width": "100%" }}
                    onChange={(e: any) => {
                      myProps.on_change_row_input({
                        row_num: myProps.row_num,
                        key: "a_content",
                        value: e.target.value,
                      });
                    }}
                  ></TextAreaComponent>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>피드백</th>
            <td colSpan={3}>
              <div>
                {is_view_mode ?
                  <MultyLineTextView
                    text={counsel_info["a_feedback"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_feedback"}
                    value={counsel_info["a_feedback"]}
                    class_name={"row-input"}
                    style={{ "width": "100%" }}
                    onChange={(e: any) => {
                      myProps.on_change_row_input({
                        row_num: myProps.row_num,
                        key: "a_feedback",
                        value: e.target.value,
                      });
                    }}
                    placeholder={"피드백"}
                  ></TextAreaComponent>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>평가</th>
            <td colSpan={3}>
              <SelectBoxBar
                valueTextArr={myProps.select_arr_eval_score}
                value={counsel_info.a_eval_score}
                name={"a_eval_score"}
                on_change={(inData: any) => {
                  myProps.on_change_row_input({
                    row_num: myProps.row_num,
                    key: inData.name,
                    value: inData.value,
                  });
                }}
              ></SelectBoxBar>
            </td>
          </tr>
          <tr>
            <th style={{fontSize:"11px"}}>참고내용</th>
            <td colSpan={3}>
              <div>
                {is_view_mode ?
                  <MultyLineTextView
                    text={counsel_info["a_reference"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_reference"}
                    value={counsel_info["a_reference"]}
                    class_name={"row-input"}
                    style={{ "width": "100%" }}
                    onChange={(e: any) => {
                      myProps.on_change_row_input({
                        row_num: myProps.row_num,
                        key: "a_reference",
                        value: e.target.value,
                      });
                    }}
                    placeholder={"참고내용"}
                  ></TextAreaComponent>
                }
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <div className="text-center">
                <form method="post" >
                  {is_view_mode == false &&
                    <span className={Style.row_manage_btn_span} onClick={(e: any) => {
                      e.target.parentElement.querySelector(".input_file")?.click();
                    }} title={input_file_names.join(",")} >
                      사진선택
                      {input_file_names.length > 0 && <span>({input_file_names.length})</span>}
                    </span>
                  }
                  <input type="file" className="input_file" name="input_file[]" hidden
                    onChange={(e: any) => {
                      myProps.counsel_info["file_obj"] = e.target;
                      myProps.goWriteFileUpload(myProps.counsel_info);
                    }} accept="image/*" />
                </form>
              </div>
              <div>
                {file_tags}
              </div>
            </td>
          </tr>
          {is_view_mode == false &&
            <tr>
              <td colSpan={4}>
                <div className="text-center mt-2">
                  <button className="btn-m2 btn-sky" onClick={() => { myProps.update_content_by_ajax([counsel_info]); }} >저장</button>
                  <button className="btn-m2 btn-red ml-1" onClick={() => { myProps.delete_coundel_by_ajax([counsel_info]); }} >삭제</button>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};
export default CounselBox;