import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

function DayOfWeekPick(props:any){
  let myProps={
    day_of_week_arr:[
      {"value":"0","text":"일"},
      {"value":"1","text":"월"},
      {"value":"2","text":"화"},
      {"value":"3","text":"수"},
      {"value":"4","text":"목"},
      {"value":"5","text":"금"},
      {"value":"6","text":"토"},
    ],//[{"value":"","text":""}]
    value:"",//0,1,2 ...
    name:"",
    handleInputChange:(e:any)=>{},
    ...props
  };
  const handleInputChange=(inData:any)=>{
    let opt_obj={
      value:"",
      ...inData
    };
    let value = opt_obj.value;

    let tmp_day_arr=myProps.value.split(",");
    //공백제거
    let tmp_day_arr2=[];
    for(let i=0;i<tmp_day_arr.length;i++){
      if(tmp_day_arr[i]==""){

      }else{
        tmp_day_arr2.push(tmp_day_arr[i]);
      }
    }
    tmp_day_arr=tmp_day_arr2;

    let is_exist=false;
    for(let i=0;i<tmp_day_arr.length;i++){
      if(tmp_day_arr[i]==value){
        is_exist=true;
      }
    }
    
    if(is_exist){
      //제거
      let tmp_day_arr2=[];
      for(let i=0;i<tmp_day_arr.length;i++){
        if(tmp_day_arr[i]==value){
          
        }else{
          tmp_day_arr2.push(tmp_day_arr[i]);
        }
      }
      tmp_day_arr=tmp_day_arr2;
    }else{
      //추가
      tmp_day_arr.push(value);
    }
    
    value=tmp_day_arr.join(",");
    myProps.handleInputChange({
      target:{
        value:value,
        name:myProps.name,
        type:"text",
      }
    });
  };

  let value_day_arr=myProps.value.split(",");
  let dayTags=myProps.day_of_week_arr.map((item:any,idx:number)=>{
    let td_box_class_name=Style.td_box;

    let td_box_style={
      margin:0,
      padding:0,
      minWidth:0
    };
    
    if(strFunc.str_in_array(item.value,value_day_arr)!=-1){
      td_box_class_name+=" "+Style.active;
    }
    return (
      <td key={idx} 
        className={Style.day_td}
        style={td_box_style}
        onClick={(e:any)=>{
          handleInputChange({
            value:item.value
          });
        }}>
        <div className={td_box_class_name} >
          {item.text}
        </div>
      </td>
    );
  });

  return (
    <table style={{width:"auto"}}>
      <tbody>
        <tr>
          {dayTags}
        </tr>
      </tbody>
    </table>
  );
}
export default DayOfWeekPick;