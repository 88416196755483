import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function ViewDeskPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    callBackData: () => { },
    ...props
  };

  const [add_time_data, set_add_time_data] = useState({
    add_minute: 0
  });

  useEffect(() => {

  }, []);

  const on_change_add_time = (inData: any) => {
    let name = inData.name;
    let value = inData.value;
    let change_add_time_data: any = { ...add_time_data };
    change_add_time_data[name] = value;
    set_add_time_data(change_add_time_data);
  };

  const get_use_time_str = () => {
    if (myProps.desku_info == null) {
      return "";
    }
    let desku_info = myProps.desku_info;
    let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
    let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
    let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    if (!strFunc.is_empty(desku_info["a_end_date"])) {
      end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    }
    let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

    let start_sec = start_date_json.date_obj.getTime() / 1000;
    let end_sec = end_date_json.date_obj.getTime() / 1000;
    let target_time_sec= end_sec - start_sec;
    let target_time_min = Math.floor(target_time_sec / 60);

    let st_time = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
    let end_time = strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]);
    
    let use_time_str = (
      <div>
        <span>{st_time}</span>
        <span className="mx-2">~</span>
        <span>{end_time}</span>
        <span className="ml-1" style={{color:"#00f",fontWeight:"bold"}}>({target_time_min})분</span>
      </div>
    );
    return use_time_str;
  };

  const get_add_time_str = () => {
    if (myProps.desku_info == null) {
      return "";
    }
    let desku_info = myProps.desku_info;
    let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
    let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
    let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    if (!strFunc.is_empty(desku_info["a_end_date"])) {
      end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    }
    let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

    let add_time_sec = add_time_data["add_minute"] * 60;
    let start_sec = start_date_json.date_obj.getTime() / 1000;
    let end_sec = end_date_json.date_obj.getTime() / 1000;

    let start_time_hi = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
    let change_end_time_hi = strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]);

    let add_time_str = (
      <span>
        {start_time_hi}
        <span className="mx-2">~</span>
        {change_end_time_hi}
        <span className="ml-1" style={{color:"#00f",fontWeight:"bold"}}>({add_time_data["add_minute"]}분)</span>
      </span>
    );
    return add_time_str;
  };

  const cancel_desk_use = () => {
    if (myProps.desku_info == null) {
      return "";
    }
    if (!confirm(myProps.desku_info.a_user_name + "님의 사용을 취소 하시겠습니까?")) {
      return false;
    }

    let del_row = {
      "a_ymd": myProps.desku_info["a_ymd"],
      "a_seq": myProps.desku_info["a_seq"],
    };
    let del_desku_form_json = {
      "data_arr": [del_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/delete', del_desku_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callBackData();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_add_desk_use_time = () => {
    if (add_time_data["add_minute"] == 0) {
      alert("시간 선택이 없습니다.");
      return false;
    }
    let add_time_sec = add_time_data["add_minute"] * 60;
    let desku_info = myProps.desku_info;
    let start_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]) + ":00";
    let start_date_json = DateFunc.get_date_json(new Date(start_date_str));
    let end_date_str = desku_info["a_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    if (!strFunc.is_empty(desku_info["a_end_date"])) {
      end_date_str = desku_info["a_end_date"] + " " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]) + ":00";
    }
    let end_date_json = DateFunc.get_date_json(new Date(end_date_str));

    let start_sec = start_date_json.date_obj.getTime() / 1000;
    let end_sec = end_date_json.date_obj.getTime() / 1000;

    let start_time_sec = start_sec;
    let end_time_sec = end_sec;

    let change_end_time_sec = end_time_sec + add_time_sec;
    if (start_time_sec >= change_end_time_sec) {
      alert("시작시간보다 작을 수 없습니다.");
      return false;
    } else if (add_time_sec > 86400) {
      alert("추가시간은 하루를 넘을 수 없습니다.");
      return false;
    }

    if (!confirm("적용 하시겠습니까?")) {
      return false;
    }

    let target_time_sec = change_end_time_sec - start_time_sec;
    let change_date_obj=DateFunc.get_change_date(new Date(end_date_str),'sec',add_time_sec);
    let change_end_date_json = DateFunc.get_date_json(change_date_obj);
    let change_end_date_str=DateFunc.get_date_format(change_date_obj,"Y-m-d");
    let change_use_data_row = {
      a_ymd: myProps.desku_info["a_ymd"],
      a_seq: myProps.desku_info["a_seq"],
      a_end_h: change_end_date_json.h,
      a_end_m: change_end_date_json.i,
      a_end_date:change_end_date_str,
      a_target_time_sec: target_time_sec,
    };
    let form_json_data = {
      "data_arr":[change_use_data_row],
      "is_update":"1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("적용 되었습니다.");
          myProps.callBackData();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="view-table-div">
        <table>
          <colgroup>
            <col width="100px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>강의실</th>
              <td>
                {myProps.room_info ? myProps.room_info.a_name : ""}
              </td>
            </tr>
            <tr>
              <th>책상</th>
              <td>
                {myProps.desk_info ? myProps.desk_info.a_name : ""}
              </td>
            </tr>
            <tr>
              <th>사용자</th>
              <td>
                {myProps.desku_info ? myProps.desku_info.a_user_name : ""}
              </td>
            </tr>
            <tr>
              <th>사용시간</th>
              <td>
                {myProps.desku_info ?
                  <span>
                    {get_use_time_str()}
                  </span>
                  : ""}
              </td>
            </tr>
            <tr>
              <th>시간추가</th>
              <td>
                <div className="text-left">
                  <div>
                    {get_add_time_str()}
                    <button className="btn btn-darkgreen ml-2" onClick={() => { go_add_desk_use_time(); }}>시간추가 적용</button>
                  </div>
                  <div className="mt-1">
                    <button className="btn btn-yellowish3"
                      onClick={() => {
                        on_change_add_time({ name: "add_minute", value: add_time_data["add_minute"] + 1 });
                      }}>+1분</button>
                    <button className="btn btn-yellowish3 ml-1"
                      onClick={() => {
                        on_change_add_time({ name: "add_minute", value: add_time_data["add_minute"] + 10 });
                      }}>+10분</button>
                    <button className="btn btn-dark ml-1"
                      onClick={() => {
                        on_change_add_time({ name: "add_minute", value: add_time_data["add_minute"] - 1 });
                      }}>-1분</button>
                    <button className="btn btn-dark ml-1"
                      onClick={() => {
                        on_change_add_time({ name: "add_minute", value: add_time_data["add_minute"] - 10 });
                      }}>-10분</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-1">
        {myProps.desku_info != null &&
          <button className="btn-m btn-line-gray" onClick={() => { cancel_desk_use(); }}>사용취소</button>
        }
        <button className="btn-m btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default ViewDeskPopup;