class CollisionFunc {
  static is_line_collsion(inData:any){
    let opt_obj={
      a_start:0,
      a_end:0,
      b_start:0,
      b_end:0,
      is_check_line:true,
      ...inData
    };
    let a_start=opt_obj.a_start;
    let a_end=opt_obj.a_end;
    let b_start=opt_obj.b_start;
    let b_end=opt_obj.b_end;
    let is_collision=false;
    if (a_start <= b_start
      && a_end >= b_start) {
        is_collision = true;
    } else if (a_start <= b_end
      && a_end >= b_end) {
        is_collision = true;
    }
    if(is_collision&&opt_obj.is_check_line){
      //끝선만 맞다면 충돌이 아니다.
      let is_contact=this.is_line_contact(opt_obj);
      if(is_contact){
        is_collision=false;
      }
    }
    return is_collision;
  };
  static is_line_contact(inData:any){
    let opt_obj={
      a_start:0,
      a_end:0,
      b_start:0,
      b_end:0,
      ...inData
    };
    let a_start=opt_obj.a_start;
    let a_end=opt_obj.a_end;
    let b_start=opt_obj.b_start;
    let b_end=opt_obj.b_end;
    let is_contact=false;
    if(a_start<b_start){
      if(a_end==b_start){
        is_contact = true;
      }
    }
    if(a_end>b_end){
      if(a_start==b_end){
        is_contact = true;
      }
    }
    return is_contact;
  };
}
export default CollisionFunc;