import strFunc from '@/lib/lyg/string';
import StuTimeBoxFunc from "./func/stu_time_box_func";
import StuListArea from "./stu_list";

function StuTimeBox(props: any) {
  let myProps = {
    Style: {},
    day_time_num_val: "",
    time_info: {},
    timeInfoArr: [],
    same_days_week_arr: [],
    user_time_arr: [],
    set_user_time_arr: (inData: any) => { },
    studentArr: [],
    show_stu_day_time_arr: [],
    set_show_stu_day_time_arr: (inData: any) => { },
    is_show_stu_day_time_all: false,
    open_find_stu_popup: (inData: any) => { },
    ...props
  };
  let time_info = myProps.time_info;
  let same_days_week_arr = myProps.same_days_week_arr;
  let Style = myProps.Style;

  let is_same_days=StuTimeBoxFunc.get_is_same_days({
    time_info:time_info,
    same_days_week_arr:same_days_week_arr
  });

  //타임배열얻기
  let box_time_arr = StuTimeBoxFunc.get_box_time_arr({
    time_info: time_info,
    timeInfoArr: myProps.timeInfoArr,
    same_days_week_arr: same_days_week_arr
  });

  //타임에 맞는 학생시간 얻기
  let box_user_time_arr = StuTimeBoxFunc.get_box_user_time_arr({
    box_time_arr: box_time_arr,
    user_time_arr: myProps.user_time_arr
  });

  //학생정보 얻기
  let box_student_arr = StuTimeBoxFunc.get_box_student_arr({
    box_user_time_arr: box_user_time_arr,
    studentArr: myProps.studentArr
  });

  //학생별 시간정보 세팅
  box_student_arr = StuTimeBoxFunc.get_box_student_arr_of_setting_stu_time_arr({
    box_student_arr: box_student_arr,
    box_user_time_arr: box_user_time_arr
  });

  //상세보기
  let is_show_stu_list = false;
  if (strFunc.str_in_array(myProps.day_time_num_val, myProps.show_stu_day_time_arr) != -1) {
    is_show_stu_list = true;
  }
  if(myProps.is_show_stu_day_time_all){
    is_show_stu_list=true;
  }

  const on_click_show_stu_list_toggle=()=>{
    if(is_show_stu_list){
      myProps.set_show_stu_day_time_arr([]);
    }else{
      myProps.set_show_stu_day_time_arr([myProps.day_time_num_val]);
    }
  };

  return (
    <div className={Style.stu_time_box}>
      <div>
        <span className={Style.stu_time_box_title}>
          {box_student_arr.length}명
        </span>
        <button className="btn-s btn-dark ml-1" 
          onClick={()=>{
            let except_stu_seq_arr=[];
            for(let i=0;i<box_student_arr.length;i++){
              except_stu_seq_arr.push(box_student_arr[i]["a_user_seq"]);
            }
            myProps.open_find_stu_popup({
              except_stu_seq_arr:except_stu_seq_arr,
              time_info:time_info,
              box_time_arr:box_time_arr
            });
          }}>원생추가</button>
        <button className="btn-s btn-dark ml-1" 
          style={{background:(is_show_stu_list&&myProps.is_show_stu_day_time_all==false)?"#00a3ff":""}}
          onClick={on_click_show_stu_list_toggle}>상세</button>
      </div>
      {is_show_stu_list &&
        <div>
          <StuListArea
            Style={Style}
            time_info={time_info}
            is_same_days={is_same_days}
            same_days_week_arr={same_days_week_arr}
            box_student_arr={box_student_arr}
            user_time_arr={myProps.user_time_arr}
            set_user_time_arr={myProps.set_user_time_arr}
          ></StuListArea>
        </div>
      }
    </div>
  );
};
export default StuTimeBox;