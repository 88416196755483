import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import getXcolumnJson from "../../list/xcolumn/list";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import CP_Style from "./css/color_picker_css";

function WritePopupContent(props:any) {
  const xColumnArr=getXcolumnJson();
  let user=useSelector((state:any) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",

    "a_type":"notice",
    "a_public_sort":"1",
    "a_sort":"공지",
    "a_is_top":"",
    "a_title":"",
    "a_board_content":"",
    "a_id":props.s_id,
    "a_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_end_date":"",
    "a_writer":user.user_name,
    "a_color":"#000",
  });
  const [is_show_color_picker,set_is_show_color_picker]=useState(false);

  useEffect(()=>{
    
    if(props.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };
  const onChangeColor=(color:string)=>{
    setRowData({
      ...rowData,
      ...{["a_color"]: color}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_title==""){
      alert("제목 입력 필요.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/board/board/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  let color_style={
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    background: rowData.a_color,
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="35%"></col>
            <col width="110px"></col>
            <col width="35%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>구분</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_sort" value={rowData.a_sort} style={{width:90}} onChange={handleInputChange} />
                <select className="row-input" name="a_sort" value={rowData.a_sort} 
                  style={{width:90}}
                  onChange={handleInputChange} >
                  <option value="">선택없음</option>
                  {
                    xColumnArr.select_arr["a_sort"]&&
                    xColumnArr.select_arr["a_sort"].map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>탑공지</th>
              <td>
                <select className="row-input" name="a_is_top" value={rowData.a_is_top} onChange={handleInputChange} >
                  {
                    xColumnArr.select_arr["a_is_top"]&&
                    xColumnArr.select_arr["a_is_top"].map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })
                  }
                </select>
              </td>
              <th>공개</th>
              <td>
                <select className="row-input" name="a_public_sort" value={rowData.a_public_sort} onChange={handleInputChange} >
                  {
                    xColumnArr.select_arr["a_public_sort"]&&
                    xColumnArr.select_arr["a_public_sort"].map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} 
                  placeholder="제목" style={{color:rowData.a_color}} />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <textarea className="row-input h-20" name="a_board_content" value={rowData.a_board_content} onChange={handleInputChange} placeholder="내용" ></textarea>
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>
                <input type="text" className="row-input" name="a_writer" value={rowData.a_writer} onChange={handleInputChange} placeholder="작성자"/>
              </td>
              <th>제목색</th>
              <td>
                <div style={ CP_Style.swatch } onClick={ ()=>{set_is_show_color_picker(true);} }>
                  <div style={ color_style } />
                </div>
                { is_show_color_picker ? 
                <div style={ CP_Style.popover }>
                  <div style={ CP_Style.cover } onClick={ ()=>{set_is_show_color_picker(false);} }></div>
                  <SketchPicker 
                    color={rowData.a_color}
                    onChange={(color) => {
                      onChangeColor(color.hex);
                    }}
                  />
                </div> : null }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
