import Style from "../css/style.module.css";
import ItemEvalList from "./eval_list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import EvalFunc from "@/pcomponents/common/content/eval/func/eval_func";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function CardItem(props:any){
  let myProps={
    info:{},
    user_info:{},
    row_num:0,
    is_update:false,
    s_form_seq:"",
    form_list:[],
    set_eval_data:(inData:any)=>{},//{eval_info:{},row_num:0}
    go_write_eval:(inData:any)=>{},
    go_delete:()=>{},
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let default_row_data = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_form_seq": myProps.s_form_seq,
    "a_target_id": "study_title",
    "a_target_seq": "",
    "a_target_name": "",
    "a_target_detail": "",
    "a_stu_seq": myProps.user_info.a_seq,
    "a_stu_name": myProps.user_info.a_user_name,
    "a_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_subject_name": "",
    "a_title": "",
    "a_average_score": 0,
    "a_item_amout": 0,
    "a_comment": "",
    "a_writer_seq": user.user_seq,
    "a_writer_name": user.user_name,
    "form_detail_arr": [],
    "detail_arr": [],
  };
  let default_detail_row_data = {
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_detail_seq: "",
    a_title: "",
    a_score: 0,
    a_max_score: 100,
    a_score_letter: "",
    a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_order_num: "",
  };

  let info={
    ...default_row_data,
    ...myProps.info
  };

  const get_row_data_by_form_arr_data=(inData:any)=>{
    let opt_obj:any={
      row_data:{},
      form_list:[],
      ...inData,
    };

    let change_row_data=opt_obj.row_data;
    let form_info = null;
    for (let i = 0; i < opt_obj.form_list.length; i++) {
      if (opt_obj.form_list[i].a_seq == opt_obj.row_data.a_form_seq) {
        form_info = opt_obj.form_list[i];
      }
    }
    let detail_arr = [];
    if (form_info != null) {
      change_row_data["a_title"] = form_info["a_title"];
      change_row_data["a_subject_name"] = form_info["a_subject"];
      change_row_data["a_target_id"] = form_info["a_target_id"];
      change_row_data["a_target_seq"] = form_info["a_target_seq"];
      change_row_data["a_target_name"] = form_info["a_target_name"];
      change_row_data["a_target_detail"] = form_info["a_target_detail"];
    }
    if (form_info != null && !strFunc.is_empty(form_info.detail_arr)) {
      for (let i = 0; i < form_info.detail_arr.length; i++) {
        let form_d_info = form_info.detail_arr[i];
        let detail_idx_num = i + 1;
        detail_arr.push({
          ...default_detail_row_data,
          "a_title": form_d_info["a_title"],
          "a_detail_seq": detail_idx_num,
          "a_order_num": detail_idx_num,
          "a_max_score": form_d_info["a_max_score"],
        });
      }
    }
    change_row_data.detail_arr = detail_arr;
    if(form_info!=null){
      change_row_data.form_detail_arr = form_info.detail_arr;
    }

    return change_row_data;
  };

  if(strFunc.is_empty(info.detail_arr)){
    info=get_row_data_by_form_arr_data({
      row_data:{...info},
      form_list:myProps.form_list,
    });
  }

  const handleInputChange = (inData: any) => {
    let opt_obj={
      key:"",
      value:"",
      ...inData
    };
    let value = opt_obj.value;
    const key = opt_obj.key;
    let change_row_data = { ...info };
    change_row_data[key] = value;

    if (key == "a_form_seq") {
      change_row_data=get_row_data_by_form_arr_data({
        row_data:change_row_data,
        form_list:myProps.form_list,
      });
    }

    myProps.set_eval_data({
      "eval_info":change_row_data,
      "user_row_num":myProps.row_num
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data = { ...info };
    change_row_data[key]=date_str;
    myProps.set_eval_data({
      "eval_info":change_row_data,
      "user_row_num":myProps.row_num
    });
  };

  const onChangeDetail=(inData:any)=>{
    let opt_obj={
      "row_num":0,
      "detail_info":{},
      ...inData
    };
    let change_row_data = { ...info };
    change_row_data.detail_arr[opt_obj["row_num"]]=opt_obj["detail_info"];
    myProps.set_eval_data({
      "eval_info":change_row_data,
      "user_row_num":myProps.row_num
    });
  };

  const go_write=()=>{
    let total_data=EvalFunc.get_detail_total_data_by_detail_arr(info.detail_arr);
    info["a_average_score"]=total_data["average_score"];
    info["a_item_amout"]=total_data["count"];
    myProps.go_write_eval({
      info:info
    });
  };

  const get_eval_tag_list=()=>{
    let eval_tag_list:any="";
    if(info.detail_arr&&info.detail_arr.length>0){
      eval_tag_list=info.detail_arr.map((item:any,idx:number)=>{
        let form_detail_info={};
        if(info.form_detail_arr&&info.form_detail_arr.length>0){
          form_detail_info=info.form_detail_arr[idx];
        }
        return (
          <ItemEvalList
            key={idx}
            idx={idx}
            detail_info={item}
            form_d_info={form_detail_info}
            is_update={myProps.is_update}
            onChangeDetail={onChangeDetail}
          ></ItemEvalList>
        );
      });
    }
    
    return eval_tag_list;
  };

  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_con}>
        <table className={Style.card_box_table}>
          <colgroup>
            <col width={"100px"}></col>
            <col width={"*"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                <div className={Style.card_box_stu_name}>
                  {info.a_stu_name}
                </div>
              </th>
              <td>
                <input type="text" value={info.a_title} 
                  className={Style.card_box_input}
                  onChange={(e:any)=>{
                    handleInputChange({
                      key:"a_title",
                      value:e.target.value,
                    });
                  }}
                  onClick={(e:any)=>{e.target.select();}}
                  style={{width:"98%"}} />
              </td>
            </tr>
            <tr>
              <th>과목</th>
              <td>
                {info.a_subject_name}
              </td>
            </tr>
            <tr>
              <th>평가틀</th>
              <td>
                <select value={info.a_form_seq} style={{border:"1px solid #ddd",width:"98%"}}
                  onChange={(e:any)=>{
                    handleInputChange({
                      key:"a_form_seq",
                      value:e.target.value,
                    });
                  }}>
                  <option value="">선택없음</option>
                  {(myProps.form_list&&myProps.form_list.length>0)&&
                    myProps.form_list.map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.a_seq}>{item.a_title}</option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>수업진도</th>
              <td>
                {info.a_target_name}
                {info.a_target_detail?" "+info.a_target_detail:""}
              </td>
            </tr>
            <tr>
              <th>검사일</th>
              <td>
                <div style={{display:"inline-block",width:80}}>
                  <DatePicker
                    selected={info.a_date != "" ? new Date(info.a_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            {get_eval_tag_list()}
            <tr>
              <th>코멘트</th>
              <td>
                <TextAreaComponent
                  name={"a_comment"}
                  value={info.a_comment}
                  class_name={Style.card_box_input}
                  placeholder={"코멘트"}
                  style={{width:"98%",height:45,border:"1px solid #ccc",
                    resize:"none",padding:4,lineHeight:"21px"}}
                  height={45}
                  onChange={(e:any)=>{
                    handleInputChange({
                      key:"a_comment",
                      value:e.target.value,
                    });
                  }}
                ></TextAreaComponent>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={Style.card_box_btn_div}>
          <button className="btn-m btn-yellowish2" onClick={()=>{go_write();}}>저장</button>
          {myProps.is_update&&
            <button className="btn-m btn-red ml-2" onClick={()=>{myProps.go_delete();}}>제거</button>
          }
        </div>
      </div>
    </div>
  );
}
export default CardItem;