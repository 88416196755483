import search_ico_img from "@/img/ico/search2.png";
import strFunc from '@/lib/lyg/string';
import { useRef } from 'react';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    list: (inData: any) => { },
    listOpt: {},
    setListOpt: (inData: any) => { },
    xColumnArr: {},
    set_content_tab: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let xColumnArr = myProps.xColumnArr;

  const search_time_out_ref=useRef<any>(null);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  return (
    <div className="relative" >
      <div className="relative p-3 text-center" style={{ lineHeight: "20px" }}>
        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={listOpt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 90,display:"inline-table" }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <select name="s_stu_grade" className="search-input ml-1" onChange={(e: any) => {
          myProps.list({
            "now_page": "1",
            "s_stu_grade": e.target.value
          });
        }}
          style={{ height: 30, width: 70 }} >
          <option value="">학년</option>
          <option value="elementary">초</option>
          <option value="middle">중</option>
          <option value="high">고</option>
          {xColumnArr.select_arr.a_stu_grade?.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item.value}>{item.text}</option>
            );
          })}
        </select>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <input type="text" className="search-input ml-1" name="s_search_text"
          style={{ width: 50, padding: 4 }} placeholder="이름" value={listOpt.s_search_text}
          onChange={handleInputChange} 
          autoComplete="off" />
        <button onClick={() => { myProps.list({ "now_page": 1 }); }} style={{ background: "#3FAC96", padding: 4, borderTop: "1px solid #3FAC96" }} >
          <img src={search_ico_img} style={{ height: 20 }} />
        </button>
        {(listOpt.s_seq && listOpt.s_seq.length > 0) &&
          <button className="btn-s btn-red ml-1"
            style={{ height: 30 }}
            onClick={() => {
              myProps.list({ "now_page": "1", "s_seq": [], "select_stu_seq": "" });
              myProps.set_content_tab("calendar");
            }}>X</button>
        }
      </div>
    </div>
  );
};
export default SearchArea;