import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import { useRef, useEffect,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import ExcelDownComponent from "@/pcomponents/common/excel/down";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const DataListArea = forwardRef((props:any, ref) => {
  let myProps = {
    info_arr: [],
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>(null);
  const ExcelDownComponentRef=useRef<any>();
  let infoArr = myProps.info_arr;
  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let stu_grade_select_option_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    change_state_by_ajax,go_del_counsel_row,goExcelDown
  }));

  useEffect(() => {
    
    ListComponentRef.current.setInfoArr(infoArr);
  }, [infoArr]);

  const change_state_by_ajax=(change_state:string)=>{
    let select_arr=ListComponentRef.current.getSelectedRows();
    if(select_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    let confirm_msg="완료 처리 하시겠습니까?";
    if(change_state=="complete"){
      confirm_msg="완료 처리 하시겠습니까?";
    }else{
      confirm_msg="준비 중으로 바꾸시겠습니까?";
    }
    if(!confirm(confirm_msg)){
      return false;
    }

    let change_data_arr=[];
    for(let i=0;i<select_arr.length;i++){
      let info=select_arr[i];
      let a_is_success="";
      if(change_state=="complete"){
        a_is_success="1";
      }
      change_data_arr.push({
        "a_ymd":info["a_ymd"],
        "a_seq":info["a_seq"],
        "a_is_success":a_is_success,
        "a_state":change_state,
      });
    }
    if(change_data_arr.length==0){
      alert("변경할 데이터가 없습니다.");
      return false;
    }

    let form_json_data={
      "data_arr":change_data_arr,
      "is_update":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/write',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("변경 되었습니다.");
        myProps.list({});
      }else{
        
      }
    });
  };

  const go_del_counsel_row=()=>{
    let select_arr=ListComponentRef.current.getSelectedRows();
    if(select_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(!confirm(select_arr.length+"개를 삭제 하시겠습니까?")){
      return false;
    }

    let form_json_data={
      "data_arr":select_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/delete',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("삭제 되었습니다.");
        myProps.list({});
      }else{
        
      }
    });
  };

  const goExcelDown=()=>{
    if(ExcelDownComponentRef.current){
      let tmp_info_arr:any=[...infoArr];
      for(let i=0;i<tmp_info_arr.length;i++){
        let info=tmp_info_arr[i];
        
        //상태
        let state_str = "준비";
        let tmp_select_date_time = new Date(info.a_reserve_date).getTime();
        if (tmp_select_date_time < now_date_time) {
          state_str = "미완료";
        }
        if (info.a_state == "complete") {
          state_str = "완료";
        }
        tmp_info_arr[i]["row_view_state"]=state_str;

        //학년
        let stu_grade_str=info.a_stu_grade;
        if(info.stu_info){
          stu_grade_str=info.stu_info.a_stu_grade;
        }
        let select_stu_grade_opt_arr=strFunc.get_obj_by_key_val_at_obj_arr("value",stu_grade_str,stu_grade_select_option_arr);
        if(select_stu_grade_opt_arr.length>0){
          stu_grade_str=select_stu_grade_opt_arr[0]["text"];
        }
        tmp_info_arr[i]["row_view_stu_grade"]=stu_grade_str;

        //학교
        if(info.stu_info){
          tmp_info_arr[i]["row_view_stu_school"]=info.stu_info.a_school_name;
        }
      }
      let tmp_xColumnArr={...xColumnArr};
      tmp_xColumnArr.x_column_list_arr=[
        {"key":"a_reserve_date","name":"예약일","width":"100","is_show":"1"},
        {"key":"a_counsel_date","name":"상담일","width":"100","is_show":"1"},
        {"key":"a_stu_name","name":"학생","width":"100","is_show":"1"},
        {"key":"row_view_stu_grade","name":"학년","width":"100","is_show":"1"},
        {"key":"row_view_stu_school","name":"학교","width":"100","is_show":"1"},
        {"key":"a_counseler","name":"선생님","width":"100","is_show":"1"},
        {"key":"row_view_state","name":"상태","width":"100","is_show":"1"},
        {"key":"a_title","name":"제목","width":"100","is_show":"1"},
        {"key":"a_content","name":"내용","width":"200","is_show":"1"},
        {"key":"a_sort1","name":"구분1","width":"100","is_show":"1"},
        {"key":"a_sort2","name":"구분2","width":"100","is_show":"1"},
        {"key":"a_writer","name":"작성자","width":"100","is_show":"1"},
        {"key":"a_create_date","name":"등록시간","width":"200","is_show":"1"},
        {"key":"a_update_date","name":"수정시간","width":"200","is_show":"1"},
      ];
      ExcelDownComponentRef.current.setInitData({
        xColumnArr:tmp_xColumnArr,
        is_add_example:false,
        infoArr:tmp_info_arr
      });
      setTimeout(()=>{
        ExcelDownComponentRef.current.goDownExcel();
      },100);
    }
  };

  const setOrderByThKey = (th_key: string) => {
    let pre_order_id = myProps.listOpt.order_id;
    let change_order_id = pre_order_id;

    if (th_key == "checkbox") {
      return false;
    } else if (th_key.indexOf("row_view_") != -1) {
      return false;
    }

    if (pre_order_id.indexOf(th_key) != -1) {
      if (pre_order_id.indexOf(" DESC") != -1) {
        change_order_id = th_key;
      } else {
        change_order_id = th_key + " DESC";
      }
    } else {
      change_order_id = th_key;
    }
    
    myProps.list({
      now_page: 1,
      order_id: change_order_id
    });
  };

  const rowOnClick = (rowData: any, idx: number, e: any) => {
    // if(key=="a_user_name"||key=="a_user_id"){
    //   openOnePopup("write",true);
    // }
  };

  const onClickTh = (key: string, e: any) => {
    setOrderByThKey(key);
  };

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key = params.key;
    var render_str = params.value;

    if (key == "row_view_state") {
      let tmp_select_date_time = new Date(params.rowData.a_reserve_date).getTime();
      let state_back_color = "#eab358";
      let state_str = "준비";
      if (tmp_select_date_time < now_date_time) {
        state_str = "미완료";
        state_back_color = "#db6c58";
      }
      if (params.rowData.a_state == "complete") {
        state_str = "완료";
        state_back_color = "#A2B854";
      }

      let state_span_style = {
        "padding": "0 4px",
        "color": "#fff",
        "lineHeight": "23px",
        "background": state_back_color,
        "borderRadius": "4px",
      };

      render_str = (
        <div style={state_span_style}>
          {state_str}
        </div>
      );
    } else if (key == "row_view_manage") {
      render_str = (
        <div style={{textAlign:"right"}}>
          {params.rowData.a_state != "complete" &&
            <button className="btn-m2 btn-gray" onClick={() => { setTimeout(()=>{change_state_by_ajax("complete");},100); }}>
              완료
            </button>
          }
          {params.rowData.a_state == "complete" &&
            <button className="btn-m2 btn-gray" onClick={() => { setTimeout(()=>{change_state_by_ajax("ready");},100); }}>
              완료취소
            </button>
          }
          <button className="btn-m2 btn-gray" onClick={() => { setTimeout(()=>{go_del_counsel_row();},100);} }
            style={{ background: "red", marginLeft: 5 }}>
            X
          </button>
        </div>
      );
    }else if(key == "row_view_stu_grade"){
      //학년
      let stu_grade_str=params.rowData.a_stu_grade;
      if(params.rowData.stu_info){
        stu_grade_str=params.rowData.stu_info.a_stu_grade;
      }
      let select_stu_grade_opt_arr=strFunc.get_obj_by_key_val_at_obj_arr("value",stu_grade_str,stu_grade_select_option_arr);
      if(select_stu_grade_opt_arr.length>0){
        stu_grade_str=select_stu_grade_opt_arr[0]["text"];
      }
      render_str=stu_grade_str;
    }else if(key=="row_view_stu_school"){
      //학교
      if(params.rowData.stu_info){
        render_str=params.rowData.stu_info.a_school_name;
      }
    }

    return render_str;
  };

  return (
    <div>
      <ExcelDownComponent
        ref={ExcelDownComponentRef}
        xColumnArr={xColumnArr}
      ></ExcelDownComponent>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={{ ...xColumnArr }}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_add_idx_num: false,
          is_add_checkbox: true,
          td_align: "center",
          height: "63vh",
          is_fix_width: true,
          onGridReady: () => {
            myProps.list({});
          },
          rowOnClick: rowOnClick,
          onClickTh: onClickTh,
          customCellRenderer: customCellRenderer,
          class_name_json: {
            table_div: "basic_list_div",
            td_div: "row-col-div",
            input_check: "chk_row",
            row_input: "row-input",
            data_row_tr: "data_row_tr",
            active_row: "active",
            empty_con_div: "list_empty_content_div",
          },
        }}
      ></ListComponent>
    </div>
  );
});
export default DataListArea;