import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DetailArea from "./area/detail";

const WriteArea = forwardRef((props:any, ref) => {
  let myProps={
    list:(inData:any)=>{},
    close:()=>{},
    row_data:{},
    is_update:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  let subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  let default_row_data={
    "a_seq":"",
    "a_is_use":"1",
    "a_title":"",
    "a_subject":"",
    "a_target_id":"study_title",
    "a_target_seq":"",
    "a_target_name":"",
    "a_target_detail":"",
    "a_writer_seq":user.user_seq,
    "a_writer_name":user.user_name,
    "detail_arr":[],
  };
  const [row_data,set_row_data]=useState({
    ...default_row_data,
    ...myProps.row_data,
  });
  const [study_arr,set_study_arr]=useState([]);
  const [lecture_arr,set_lecture_arr]=useState([]);
  const [refresh,set_refresh]=useState(false);

  useEffect(()=>{
    
    get_study_arr_by_ajax();
    get_lecture_arr_by_ajax();
  },[]);

  const setInitData=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData
    };

    set_row_data({
      ...default_row_data,
      ...opt_obj["row_data"]
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  let target_seq_select_arr=[];
  if(row_data.a_target_id=="study_title"){
    for(let i=0;i<study_arr.length;i++){
      let study_info=study_arr[i];
      target_seq_select_arr.push({
        "value":study_info["a_seq"],
        "text":study_info["a_title"],
      });
    }
  }else if(row_data.a_target_id=="lecture"){
    for(let i=0;i<lecture_arr.length;i++){
      let lecture_info=lecture_arr[i];
      target_seq_select_arr.push({
        "value":lecture_info["a_seq"],
        "text":lecture_info["a_name"],
      });
    }
  }

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    let change_row_data={...row_data};
    change_row_data[name]=value;
    if(name=="a_target_seq"){
      if(row_data.a_target_id=="study_title"){
        for(let i=0;i<study_arr.length;i++){
          let study_info=study_arr[i];
          if(study_info["a_seq"]==value){
            change_row_data["a_target_name"]=study_info["a_title"];
          }
        }
      }else if(row_data.a_target_id=="lecture"){
        for(let i=0;i<lecture_arr.length;i++){
          let lecture_info=lecture_arr[i];
          if(lecture_info["a_seq"]==value){
            change_row_data["a_target_name"]=lecture_info["a_name"];
          }
        }
      }
    }

    set_row_data(change_row_data);
  };

  const set_detail_arr=(inData:any)=>{
    let tmp_row_data=row_data;
    tmp_row_data.detail_arr=inData;
    set_row_data(tmp_row_data);
    set_refresh(!refresh);
  };

  const go_write=()=>{
    if(row_data.a_title==""){
      alert("제목을 입력해 주세요.");
      return false;
    }

    let confirm_msg="등록 하시겠습니까?";
    if(myProps.is_update){
      confirm_msg="수정 하시겠습니까?";
    }
    if(!confirm(confirm_msg)){
      return false;
    }

    let w_row_data=row_data;

    let write_data_form:any={
      "data_arr":[w_row_data],
    };
    if(myProps.is_update){
      write_data_form["is_update"]="1";
    }else{
      write_data_form["is_default_val"]="1";
    }

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval_form/write',write_data_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        write_detail_by_ajax(response.data["data"][0]);
        // alert("성공");
        // myProps.list({"now_page":"1"});
        // myProps.close();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const write_detail_by_ajax=(form_info:any)=>{
    let detail_form_json={
      "detail_arr":row_data.detail_arr,
      "eval_form_seq":form_info["a_seq"],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval_formd/add_of_form',detail_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("성공");
        myProps.list({"now_page":"1"});
        myProps.close();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const get_study_arr_by_ajax = ()=>{
    let form_data_json={
      "s_addon_section":"1",
      "s_addon_study":"1",
      "order_id":"a_title",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_title/list',form_data_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_study_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_lecture_arr_by_ajax = ()=>{
    let form_data_json={
      "order_id":"a_name",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/lecture/list',form_data_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_lecture_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_target_detail_option_arr=()=>{
    let target_detail_option_arr=[];

    if(row_data.a_target_id=="study_title"){
      for(let study_i=0;study_i<study_arr.length;study_i++){
        let study_info:any=study_arr[study_i];
        if(study_info["a_seq"]==row_data.a_target_seq){
          let row_study_arr=[];
          if(study_info.study_arr){
            row_study_arr=study_info.study_arr;
          }
          if(study_info.section_arr){
            for(let i=0;i<study_info.section_arr.length;i++){
              let section_info=study_info.section_arr[i];
              target_detail_option_arr.push({
                "text":section_info["a_title"],
                "value":section_info["a_title"],
              });

              for(let j=0;j<row_study_arr.length;j++){
                let row_study_info=row_study_arr[j];
                if(section_info["a_seq"]==row_study_info["a_section_seq"]){
                  target_detail_option_arr.push({
                    "text":"--"+row_study_info["a_title"],
                    "value":section_info["a_title"]+" > "+row_study_info["a_title"],
                  });
                }
              }
            }
          }
        }
      }
    }

    return target_detail_option_arr;
  };
  let target_detail_option_arr=get_target_detail_option_arr();

  return (
    <div>
      <div className="write-table-div mt-1">
      <table>
          <colgroup>
            <col width="10%"></col>
            <col width="40%"></col>
            <col width="10%"></col>
            <col width="40%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={row_data.a_title}
                 onChange={handleInputChange} placeholder="제목" style={{width:200}}/>
              </td>
            </tr>
            <tr>
              <th>과목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_subject" value={row_data.a_subject}
                 onChange={handleInputChange} placeholder="과목" style={{width:100}}/>
                <select className="row-input ml-1" name="a_subject" value={row_data.a_subject} 
                  onChange={handleInputChange} style={{width:120}}>
                  <option value="" >선택</option>
                  {subject_option_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td colSpan={3}>
                <select className="row-input" name="a_target_id" value={row_data.a_target_id} 
                  onChange={handleInputChange} style={{width:100}}>
                  <option value="study_title" >교재</option>
                  <option value="lecture" >수업</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>평가대상</th>
              <td colSpan={3}>
                <select className="row-input" name="a_target_seq" value={row_data.a_target_seq} 
                  onChange={handleInputChange} style={{width:100}}>
                  <option value="" >선택없음</option>
                  {target_seq_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>수업진도</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_target_detail" value={row_data.a_target_detail}
                  onChange={handleInputChange} placeholder="수업진도" style={{width:200}}/>
                {(row_data.a_target_id=="study_title")&&
                <select className="row-input ml-1" name="a_target_detail" value={row_data.a_target_detail} 
                  onChange={handleInputChange} style={{width:100}}>
                  <option value="" >선택없음</option>
                  {
                    target_detail_option_arr.map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.value} >{item.text}</option>
                      );
                    })
                  }
                </select>
                }
              </td>
            </tr>
            <tr>
              <th>사용여부</th>
              <td colSpan={3}>
                <select className="row-input" name="a_is_use" value={row_data.a_is_use}
                  onChange={handleInputChange} style={{width:200}} >
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_writer_name" value={row_data.a_writer_name}
                  onChange={handleInputChange} placeholder="제목" style={{width:200}}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <DetailArea
        detail_arr={row_data.detail_arr}
        set_detail_arr={set_detail_arr}
      ></DetailArea>
      <div className="btn-box-center mt-4">
        <button className="btn-m btn-gray" onClick={()=>{go_write();}}>
          {myProps.is_update?"수정":"등록"}
        </button>
        <button className="btn-m btn-gray" onClick={()=>{myProps.close();}}>뒤로</button>
      </div>
    </div>
  );
});

export default WriteArea;