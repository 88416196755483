//import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
/*
{isPopupOpen && 
  <LayerPopup closePopup={()=>{setIsPopupOpen(false);}} title="팝업" >
    <WritePopupContent userName="ㅋㅋㅋㅋㅋ" closePopup={()=>{setIsPopupOpen(false);}}  ></WritePopupContent>
  </LayerPopup>
}
*/
import { useEffect, useRef, useState } from 'react';
import setPropsFunc from "./func/set_props/set_props";
import TitleBarArea from "./area/title_bar";

function LayerPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callBack: () => { },
    title: '',
    width: '300px',
    height: '300px',
    marginTop: "0px",
    x: "",
    y: "",
    is_win_max_width: true,
    is_win_max_height: true,
    title_bar_style: {},
    content_div_style: {},
    is_border_radius: true,
    has_header: true,
    ...props
  };
  let pop_data = setPropsFunc.geDataByProps(myProps);
  const popupBackDiv = useRef<HTMLInputElement>(null);
  const [offset, setOffset] = useState({ x: pop_data.x, y: pop_data.y });

  if (myProps.is_border_radius == false) {
    myProps.title_bar_style = {
      ...myProps.title_bar_style,
      "borderRadius": "0",
    };
    myProps.content_div_style = {
      ...myProps.content_div_style,
      "borderRadius": "0",
    };
  }

  useEffect(() => {
    popupBackDiv.current?.addEventListener("click", (e: any) => {
      if (e.target.classList.contains('popup-background')) {
        //myProps.closePopup();
      }
      return false;
    }, false);
  }, []);

  let popup_wrap_style = {
    left: offset.x + "px",
    top: offset.y + "px",
    width: pop_data.width + "px",
    height: pop_data.height + "px",
  };

  return (
    <div className="popup-background" ref={popupBackDiv} >
      <div className="popup-con-area">
        <div className="popup-transparent-back"></div>
        <div className="popup-wrap" style={popup_wrap_style}>
          <div className="popup-container">
            {myProps.has_header &&
              <TitleBarArea
                title={myProps.title}
                x={pop_data.x}
                y={pop_data.y}
                setOffset={setOffset}
                title_bar_style={myProps.title_bar_style}
                closePopup={myProps.closePopup}
              ></TitleBarArea>
            }
            <div className="popup-content-div" style={myProps.content_div_style}>
              {myProps.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayerPopup;
