import { useEffect,useState,forwardRef,useImperativeHandle  } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
const FileArea=forwardRef((props:any, ref) => {
  let myProps:any={
    file_arr:[],
    ...props
  };
  
  const [file_arr,set_file_arr]= useState<any>(myProps.file_arr);

  useEffect(() => {
    
  },[file_arr]);

  const goDeleteFile=(fileInfo:any)=>{
    if(!confirm("파일을 삭제 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "data_arr":[fileInfo],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/file/file/delete',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        let tmp_file_arr=[];
        let del_file_pri_val=fileInfo.a_ymd+","+fileInfo.a_seq;
        for(let i=0;i<file_arr.length;i++){
          let file_pri_val=file_arr[i].a_ymd+","+file_arr[i].a_seq;
          if(file_pri_val!=del_file_pri_val){
            tmp_file_arr.push(file_arr[i]);
          }
        }
        set_file_arr(tmp_file_arr);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const setInitData=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
      file_arr:{},
     ...inData
    }

    set_file_arr(optObj.file_arr);
  };

  return (
    <div >
      <div className="list-table-div">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>첨부파일들</th>
              <td>
                {file_arr.length}개
                {file_arr&&
                  file_arr.map((item:any,idx:number)=>{
                    let img_url="";
                    if(item.is_image=="1"){
                      img_url=item.thum_img_url;
                    }
                    return (
                      <div key={idx}>
                        {img_url!=""&&
                          <div><img src={img_url} style={{height:40}} /></div>
                        }
                        {item.a_oriname}
                        <button className="btn-s btn-red px-2" onClick={()=>{goDeleteFile(item);}}>X</button>
                      </div>
                    );
                  })
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default FileArea;