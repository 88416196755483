//import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
//let select_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

class XcolumnFunc
{
  static getDefaultXcolumn(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    /*Save attrs
    x_column_list_arr
    list_opt_arr
    */
   const defaultOptions:any={
      table:"",//authg
      list_id:"",//basic/authg/list
      list_type:"base",//특수한경우만사용
      list_num:"0",
      x_column_list_arr:{},//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
      x_column_list_orig_arr:{},
      list_opt:{},
      list_opt_origin:{
        'now_page':1,
        'num_per_page':10,
        'order_id':'',
        'order_type':'',
        's_date_type':'',
        's_start_date':'',
        's_end_date':'',
        'sc':{},
      },
      list_opt_arr:{
        "fix_left_num":0,
        'num_per_page':10,
        'order_id_arr':[],//["a_seq DESC","a_create_date DESC"]
        'order_id1':'',
        'order_type1':'',
        'order_id2':'',
        'order_type2':'',
        'start_date_num':'',
        'start_date_type':'',
      },
      list_opt_origin_arr:{},
      order_id_col_arr:[
        {"value":"","text":"없음"},
      ],//[{"value":"","text":"기본값"},]
      start_date_num_arr:[
        {"value":"","text":"기본값"},
        {"value":"today","text":"오늘"},
        {"value":"-1day","text":"어제"},
        {"value":"-3day","text":"3일전"},
        {"value":"-7day","text":"7일전"},
        {"value":"-30day","text":"30일전"},
        {"value":"now_month","text":"이번달"},
        {"value":"pre_month","text":"한달전"},
        {"value":"two_month","text":"두달전"},
        {"value":"now_year","text":"일년"},
        {"value":"two_year","text":"2년"},
      ],
      start_date_type_arr:[
        {"value":"","text":"기본값"},
      ],
      pri_col_arr:[],//["a_seq"]
      select_arr:{},//{"a_is_allow":[{"value":"2","text":"미허용"}]}
      view_col_arr:[],// ['key1','key2']
      number_col_arr:[],// ['key1','key2']
      tel_col_arr:[],// ['key1','key2']
      busin_col_arr:[],// ['key1','key2']
      law_num_col_arr:[],// ['key1','key2']
      date_col_arr:[],// ['key1','key2']
      password_col_arr:[],// ['key1','key2']
      checkbox_col_arr:[],// ['key1','key2']
      create_user_col:"",//a_create_user
      func:XcolumnFunc
    };
    for(let key in inOptObj){
      if(defaultOptions[key]!=undefined){
        defaultOptions[key]=inOptObj[key];
      }
    }
    defaultOptions.list_opt_arr["num_per_page"]=defaultOptions.list_opt_origin["num_per_page"];
    defaultOptions.list_opt_origin_arr={...defaultOptions.list_opt_arr};
    defaultOptions.x_column_list_orig_arr=this.getCopyXcolumnListArr(defaultOptions.x_column_list_arr);
    this.getXcolumnByid(defaultOptions);
    defaultOptions.select_arr=this.getSortByTable({"table":defaultOptions["table"]});
    defaultOptions.list_opt={
      ...defaultOptions.list_opt_origin,
      ...this.getListOptByArr({"listOptArr":defaultOptions.list_opt_arr})
    };
    return defaultOptions;
  }
  static getCopyXcolumnListArr(xColumnArr:any){
    let copyArr=[];
    let xColumnLen=xColumnArr.length;
    for(let i=0;i<xColumnLen;i++){
      copyArr.push({...xColumnArr[i]});
    }
    return copyArr;
  }
  static getXcolumnByid(inxColumnArr:any){
    let json_data:any={};

    if(localStorage.xcolumn_data_arr){
      let xcolumn_data_arr=JSON.parse(localStorage.xcolumn_data_arr);
      for(let i=0;i<xcolumn_data_arr.length;i++){
        if(xcolumn_data_arr[i]["a_list_id"]==inxColumnArr["list_id"]
          &&xcolumn_data_arr[i]["a_list_type"]==inxColumnArr["list_type"]){
            json_data=xcolumn_data_arr[i]["x_column_arr"];
        }
      }
    }
    if(json_data.list_opt_arr){
      inxColumnArr.list_opt_arr=json_data.list_opt_arr;
      inxColumnArr.x_column_list_arr=json_data.list_arr[parseInt(json_data.list_num)];
    }

    return json_data;
  }
  static getSortByTable(inOptObj:any){
    let optObj:any={
      "table":"",
      ...inOptObj
    };

    let select_arr={};
    if(localStorage.sort_data_arr){
      let sort_data_arr=JSON.parse(localStorage.sort_data_arr);
      if(sort_data_arr[optObj["table"]]!==undefined){
        select_arr=sort_data_arr[optObj["table"]];
      }
    }

    return select_arr;
  }
  static getSortByTableKey(inOptObj:any){
    let optObj:any={
      "table":"",
      "key":"",
      ...inOptObj
    };

    let option_arr=[];
    if(localStorage.sort_data_arr){
      let sort_data_arr=JSON.parse(localStorage.sort_data_arr);
      if(sort_data_arr[optObj["table"]]!==undefined&&sort_data_arr[optObj["table"]][optObj["key"]]!==undefined){
        option_arr=sort_data_arr[optObj["table"]][optObj["key"]];
      }
    }

    return option_arr;
  }
  static getListOptByArr(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      "listOptArr":{
        // "fix_left_num":0,
        // 'num_per_page':10,
        'order_id_arr':[],//["a_seq DESC","a_create_date DESC"]
        'start_date_num':'',
      }
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }

    let listOpt:any={
      // 'num_per_page':10,
      // 'order_id':'',
      // 'order_type':'',
      // 's_date_type':'',
      // 's_start_date':'',
      // 's_end_date':'',
    };

    if(optObj["listOptArr"]["num_per_page"]){
      listOpt["num_per_page"]=optObj["listOptArr"]["num_per_page"];
    }
    if(optObj["listOptArr"]["order_id_arr"].length>0){
      listOpt["order_id"]=optObj["listOptArr"]["order_id_arr"].join(",");
    }
    if(optObj["listOptArr"]["start_date_num"]){
      let d_num=optObj["listOptArr"]["start_date_num"];
      let now_d=new Date();
      let tmpOptdateDataArr:any={
        today:DateFunc.get_date_format(now_d,"Y-m-d"),
        now_month:DateFunc.get_date_format(now_d,"Y-m-01"),
        pre_month:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',-1),"Y-m-01"),
        two_month:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'month',-2),"Y-m-01"),
        now_year:DateFunc.get_date_format(now_d,"Y-01-01"),
        two_year:DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'year',-1),"Y-01-01"),
      };
      if(tmpOptdateDataArr[d_num]!=undefined){
        listOpt["s_start_date"]=tmpOptdateDataArr[d_num];
      }else if(d_num.indexOf("day")!=-1){
        let d_num_int=parseInt(strFunc.getNumber(d_num));
        listOpt["s_start_date"]=DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'day',d_num_int),"Y-m-d");
      }
    }
    if(optObj["listOptArr"]["start_date_type"]){
      listOpt["s_date_type"]=optObj["listOptArr"]["start_date_type"];
    }

    return listOpt;
  }
  static getPriValByDataRow(inOptObj:any){
    if(inOptObj==undefined){inOptObj= {};}
    let optObj:any={
      xColumnArr:{},
      rowData:{}
    };
    for(let key in inOptObj){
      optObj[key]=inOptObj[key];
    }
    let priVal="";
    let tmpPriValArr=[];
    for(let i=0;i<optObj.xColumnArr.pri_col_arr.length;i++){
      let key=optObj.xColumnArr.pri_col_arr[i];
      tmpPriValArr.push(optObj.rowData[key]);
    }
    priVal=tmpPriValArr.join(",");

    return priVal;
  }
}
export default XcolumnFunc;