import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindMultyStudentPopup from '@/pages/comp/basic/student/popup/find_multy';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import Paging from "@/pcomponents/common/crud/list/paging/paging";

const UseStuList = forwardRef((props:any, ref) => {
  const myProps={
    "title_info":null,
    "infoArr":[],
    "list":(inOptObj:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const [title_info,set_title_info]=useState(myProps.title_info);
  const [infoArr,setInfoArr]=useState(myProps.infoArr);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const ListComponentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_par_id":"study_title",
      "s_par_seq":"",
      's_date_type':'a_create_date',
      's_start_date':'',
      's_end_date':'',
    }
  });
  
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "title_info":null,
      "infoArr":[],
      ...inOptObj
    };
    setInfoArr(optObj["infoArr"]);
    set_title_info(optObj["title_info"]);
    if(ListComponentRef.current){
      ListComponentRef.current.setInfoArr(optObj["infoArr"]);
    }
    list({
      "s_par_id":"study_title",
      "s_par_seq":optObj["title_info"]["a_seq"]
    });
  };

  useEffect(()=>{
    
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",//view
    title:"팝업",
    width:"800px",
    height:"80%",
    stu_seq:"",
    title_info:null,
    use_info:null,

    listOpt:{
      s_except_user_seq:"",
    },
    row_num:0,
    key:"",
  });

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    if(strFunc.is_empty(pageData.listOpt.s_par_seq)){
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let tmpPageData={...pageData};
    tmpPageData.listOpt[key]=date_str;
    setPageData({
      ...tmpPageData
    });
    list({[key]: date_str,"now_page":1});
  };

  const openInvitePopup=()=>{
    let pop_title="학생초대";
    let s_except_user_seq:any=[];
    for(let i=0;i<infoArr.length;i++){
      s_except_user_seq.push(infoArr[i].a_stu_seq);
    }

    setPopupData({
      ...popupData,
      ...{
        "isOpen":true,
        "title":pop_title,
        "sort":"find",
        listOpt:{
          s_except_user_seq:s_except_user_seq,
        },
      }
    });
  };

  const inviteStuUsers=(user_arr:any)=>{
    if(user_arr.length==0){
      return false;
    }
    if(!confirm(user_arr.length+"명의 학생을 초대하시겠습니까?")){
      return false;
    }
    if(title_info==null){
      alert("코스데이터가 없습니다.");
      return false;
    }
    let user_seq_arr=[];
    for(let i=0;i<user_arr.length;i++){
      user_seq_arr.push(user_arr[i].a_seq);
    }
    let add_corse_use_form_json={
      corse_seq:title_info.a_seq,
      user_seq_arr:user_seq_arr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/add_by_user',add_corse_use_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:title_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDelete =(e:any) => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:title_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDeleteUse=()=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("선택 초기화 하시겠습니까?")){
      return false;
    }
  };

  const customCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val
    }
    */
    let val_str=params.value;
    if(params.key=="a_study_state"){
    if(xColumnArr.select_arr["a_study_state"]){
      for(let i=0;i<xColumnArr.select_arr["a_study_state"].length;i++){
        let option_data=xColumnArr.select_arr["a_study_state"][i];
        if(option_data["value"]==params.value){
          val_str=option_data["text"];
        }
      }
    }
    }else if(params.key=="a_study_per"){
      let per_num=parseFloat(strFunc.uncomma(val_str));
      let per_style:any={};
      if(per_num<10){
        per_style.color="#000";
      }
      if(per_num>50){
        per_style.color="blue";
      }
      if(per_num>=100){
        per_style.color="green";
      }
      val_str=strFunc.comma(val_str)+" %";
    }

    return val_str;
  }

  return (
    <div>
      <h4 className="mt-4" style={{color:"#00f"}}>사용이력</h4>
      <div className="search-box py-0 mb-1">
        기간검색
        <DatePicker 
          wrapperClassName="w-28 pl-1"
          selected={pageData.listOpt.s_start_date!=""?new Date(pageData.listOpt.s_start_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_start_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />~
        <DatePicker 
          wrapperClassName="w-28 pl-1"
          selected={pageData.listOpt.s_end_date!=""?new Date(pageData.listOpt.s_end_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_end_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
        
        <button className="btn btn-dark" onClick={()=>{list({"now_page":"1"});}}>검색</button>
        {/* <button className="btn btn-dark" onClick={()=>{openInvitePopup();}}>초대</button>
        <button className="btn btn-dark" onClick={goDeleteUse}>진행도초기화</button> */}
        <button className="btn btn-dark" onClick={goDelete}>삭제</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={xColumnArr}
        gridOpt={
          {
            is_add_idx_num:false,
            is_add_checkbox:true,
            width:"100%",
            height:"200px",
            max_width:1000,
            td_align:"center",//center,right
            table_align:"left",//center,right
            onGridReady:()=>{},
            rowOnClick:(rowData:any,idx:number,e:any)=>{
              
            },
            customCellRenderer:customCellRenderer
          }
        }
      ></ListComponent>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot} 
        is_view_num_per_page={true}
        onChangePage={(now_page:number)=>{list({now_page:now_page});}}
        onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindMultyStudentPopup 
              listOpt={popupData.listOpt}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             callBackData={(data:any)=>{
                //data = { info_arr:[],row_num:0,key:"" };
                inviteStuUsers(data.info_arr);
             }}
             ></FindMultyStudentPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default UseStuList;
