import { useSelector } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudyTitleOFStuPopup from "@/pages/comp/plan/study/study/popup/find_study_title_of_stu";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import ReportLastList from "./area/list";
import LoadingComponent from "@/pcomponents/common/content/loading";

function DailyReportLastArea(props: any) {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    info: {},
    set_info: (inData: any) => { },
    refresh_data: (inData: any) => { },
    ...props
  };
  let select_mcomp_data= myProps.select_mcomp_data;
  let info = myProps.info;
  let user = useSelector((state: any) => state.user);

  const [lecture_arr, set_lecture_arr] = useState([]);
  const [study_title_arr, set_study_title_arr] = useState([]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find_study_title",
    row_num: 0,
    key: "",
    title: "교재찾기",
    width: "800px",
    height: "85%",
  });

  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장중..",
  });

  useEffect(() => {
    get_lecture_arr_by_ajax();
    get_study_title_arr_by_ajax();
  }, [select_mcomp_data.mcomp_seq]);

  const get_lecture_arr_by_ajax = () => {
    let form_json_data:any = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_study_title_arr_by_ajax = () => {
    let form_json_data:any = {
      "order_id": "a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_title_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const save_last_daily_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      data_arr: [],
      is_show_loading: true,
      is_refresh_data: true,
      ...inData
    };

    let form_json_data:any = {
      data_arr: opt_obj["data_arr"],
      is_write_study_use: "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    if (opt_obj["is_show_loading"]) {
      set_loading_data({
        is_display: true,
        text: "저장중..",
      });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report_last/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (opt_obj["is_show_loading"]) {
            set_loading_data({
              is_display: true,
              text: "저장완료.",
            });
            setTimeout(() => {
              set_loading_data({
                is_display: false,
                text: "저장중..",
              });
            }, 1000);
          }
          if (opt_obj["is_refresh_data"]) {
            myProps.refresh_data();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_last_daily_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      data_arr: [],
      ...inData
    };

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let form_json_data:any = {
      data_arr: opt_obj["data_arr"]
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report_last/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "삭제되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              is_display: false,
              text: "저장중..",
            });
          }, 1000);
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_find_study_title_popup = (inData: any) => {
    let opt_obj = {
      row_num: 0,
      key: "",
      ...inData,
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      row_num: opt_obj["row_num"],
      key: opt_obj["key"],
      sort: "find_study_title",
    });
  };

  const get_last_daily_arr_width_lecture = () => {
    let last_daily_report_arr = info.last_daily_report_arr;

    let tmp_last_daily_arr: any = [];
    if (info.lecture_arr) {
      for (let i = 0; i < info.lecture_arr.length; i++) {
        let lecture_info = info.lecture_arr[i];
        let is_exist_row_lecture_last_daily = false;
        if (last_daily_report_arr) {
          for (let j = 0; j < last_daily_report_arr.length; j++) {
            let last_daily_info = last_daily_report_arr[j];
            if (last_daily_info["a_lecture_seq"] == lecture_info["a_seq"]
              //&& last_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
            ) {
              tmp_last_daily_arr.push(last_daily_info);
              is_exist_row_lecture_last_daily = true;
            }
          }
        }
        if (is_exist_row_lecture_last_daily == false) {
          tmp_last_daily_arr.push({
            a_stu_seq: info["a_seq"],
            a_writer_seq: lecture_info["a_main_teacher_seq"],
            a_lecture_seq: lecture_info["a_seq"],
            a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
            a_lecture_name: lecture_info["a_name"],
            a_stu_name: info["a_user_name"],
            a_writer: lecture_info["a_main_teacher"],
            a_subject: lecture_info["a_subject"],
            a_textbook: "",
            a_subtextbook: "",
            a_homework: "",
            a_textbook_study: "",
            a_subtextbook_study: "",
            a_grade_score: 0,
          });
        }
      }
    }
    //더있는지 체크
    let pre_last_pri_val_arr = [];
    for (let i = 0; i < tmp_last_daily_arr.length; i++) {
      let tmp_last = tmp_last_daily_arr[i];
      pre_last_pri_val_arr.push(
        tmp_last["a_stu_seq"] + "," + tmp_last["a_writer_seq"] + "," + tmp_last["a_lecture_seq"]
      );
    }
    if (last_daily_report_arr) {
      for (let i = 0; i < last_daily_report_arr.length; i++) {
        let last_daily_info = last_daily_report_arr[i];
        let tmp_pri_val = last_daily_info["a_stu_seq"] + "," + last_daily_info["a_writer_seq"] + "," + last_daily_info["a_lecture_seq"];
        if (strFunc.str_in_array(tmp_pri_val, pre_last_pri_val_arr) == -1) {
          tmp_last_daily_arr.push(last_daily_info);
        }
      }
    }
    return tmp_last_daily_arr;
  };
  let last_daily_arr = get_last_daily_arr_width_lecture();

  const on_change_daily_input = (inData: any) => {
    let opt_obj = {
      pri_val: "",
      name: "",
      value: "",
      study_detail_str: "",
      ...inData
    };
    let name = opt_obj["name"];
    let value = opt_obj["value"];

    for (let i = 0; i < last_daily_arr.length; i++) {
      let last_daily = last_daily_arr[i];
      let row_pri_val = last_daily["a_stu_seq"] + "," + last_daily["a_writer_seq"] + "," + last_daily["a_lecture_seq"];
      if (row_pri_val == opt_obj["pri_val"]) {
        last_daily[name] = value;
        if (name == "a_grade_score") {
          value = strFunc.uncomma(value);
        }

        if (name == "a_subtextbook_seq") {
          let study_title_str = "";
          for (let j = 0; j < study_title_arr.length; j++) {
            if (study_title_arr[j]["a_seq"] == value) {
              study_title_str = study_title_arr[j]["a_title"];
            }
          }
          last_daily["a_subtextbook"] = study_title_str;
          if (!strFunc.is_empty(opt_obj["study_detail_str"])) {
            last_daily["a_subtextbook_study"] = opt_obj["study_detail_str"];
          }
        }

        if (name == "a_textbook_seq") {
          let study_title_str = "";
          for (let j = 0; j < study_title_arr.length; j++) {
            if (study_title_arr[j]["a_seq"] == value) {
              study_title_str = study_title_arr[j]["a_title"];
            }
          }
          last_daily["a_textbook"] = study_title_str;
          if (!strFunc.is_empty(opt_obj["study_detail_str"])) {
            last_daily["a_textbook_study"] = opt_obj["study_detail_str"];
          }
        }
        last_daily_arr[i] = last_daily;
      }
    }

    info.last_daily_report_arr = last_daily_arr;
    myProps.set_info(info);
    save_last_daily_arr_by_ajax({ 
      data_arr: last_daily_arr,
      is_show_loading:false,
      is_refresh_data:false,
    });
  };

  return (
    <div>
      <ReportLastList
        info={info}
        set_info={myProps.set_info}
        study_title_arr={study_title_arr}
        lecture_arr={lecture_arr}
        last_daily_arr={last_daily_arr}
        on_change_daily_input={on_change_daily_input}
        save_last_daily_arr_by_ajax={save_last_daily_arr_by_ajax}
        delete_last_daily_arr_by_ajax={delete_last_daily_arr_by_ajax}
        open_find_study_title_popup={open_find_study_title_popup}
      ></ReportLastList>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "find_study_title" &&
            <FindStudyTitleOFStuPopup
              select_mcomp_data={{...select_mcomp_data}}
              stu_seq={info.a_seq}
              row_num={popupData.row_num}
              key_str={popupData.key}
              callBackData={(inData: any) => {
                if (inData["info_arr"].length == 0) {
                  alert("선택이 없습니다.");
                  return false;
                } else if (inData["info_arr"].length != 1) {
                  alert("한개만 선택해주세요.");
                  return false;
                }
                let study_title_seq = inData["info_arr"][0]["a_seq"];
                if(inData["section_info"]&&inData["section_row_num"]){
                  inData["study_detail_str"]=inData["section_row_num"]+")"+inData["section_info"]["a_title"];
                }
                on_change_daily_input({
                  pri_val: inData["row_num"],
                  name: inData["key"],
                  value: study_title_seq,
                  study_detail_str: inData["study_detail_str"],
                });
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindStudyTitleOFStuPopup>
          }
        </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </div>
  );
};
export default DailyReportLastArea;