import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';

const TableHeadArea=forwardRef((props:any, ref) => {
  let myProps:any={
    gridOpt:{},
    colDefs:[],
    checkAll:(e:any)=>{},
    customThCellRenderer:(params:any)=>{return params.head_str;},
    updateColumnMaxWidth:(col_max_width_arr:any)=>{},
    ...props
  };

  const gridOpt={
    ...myProps.gridOpt
  };
  const colDefs=myProps.colDefs;
  const listHeadRef=useRef<any>(null);
  const listHeadTrRef=useRef<any>(null);
  const [col_max_width_arr,set_col_max_width_arr]=useState([]);

  useEffect(() => {
    updateColumnMaxWidth();
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setScrollLeft
  }));

  const onClickTh=(key:string,e:any)=>{
    gridOpt.onClickTh(key,e);
  };

  const setScrollLeft=(scroll_num:number)=>{
    if(listHeadRef.current){
      listHeadRef.current.scrollLeft=scroll_num;
    }
  };

  const updateColumnMaxWidth=()=>{
    let tmp_col_max_width_arr:any=[];
    if(listHeadTrRef.current){
      let th_arr=(listHeadTrRef.current as HTMLTableRowElement).getElementsByTagName("th");
      for(let i=0;i<th_arr.length;i++){
        let th_obj=th_arr[i];
        let td_div_obj=th_obj.getElementsByClassName(gridOpt.class_name_json.td_div)[0];
        tmp_col_max_width_arr.push(td_div_obj.clientWidth);
      }
    }
    set_col_max_width_arr(tmp_col_max_width_arr);
    myProps.updateColumnMaxWidth(tmp_col_max_width_arr);
  };

  const tableStyle:any={
    width:gridOpt.width
  };
  if(gridOpt.table_align=="center"){
    tableStyle["margin"]="0 auto";
  }
  let show_idx_num=0;
  const headerCols = colDefs.map((col_data:any,idx:number)=>{
    let key=col_data["key"];
    let tmp_col_width=parseInt(col_data["width"]);
    if(col_data["is_show"]!="1"){
      return "";
    }
    let col_head_str:any=col_data["name"];
    if(key=="checkbox"){
      col_head_str=(<input type="checkbox" value={""} onChange={myProps.checkAll} style={{width:15,height:15}} />);
    }

    col_head_str=myProps.customThCellRenderer({
      "key":key,
      "name":col_data["name"],
      "head_str":col_head_str,
    });

    let col_th_style:any={
      minWidth:tmp_col_width,
      maxWidth:tmp_col_width*2,
    };
    if(gridOpt.is_fix_width){
      col_th_style.width=tmp_col_width;
      col_th_style.maxWidth=tmp_col_width;
    }
    if(col_max_width_arr[show_idx_num]){
      col_th_style.width=col_max_width_arr[show_idx_num];
    }

    show_idx_num++;

    return (
      <th key={key}>
        <div className={gridOpt.class_name_json.td_div} onClick={(e:any)=>{onClickTh(key,e);}} style={col_th_style}>
          {col_head_str}
        </div>
      </th>
    );
  });

  
  return (
    <div ref={listHeadRef} style={{overflow:"hidden"}}>
      <table style={tableStyle}>
        <thead>
          <tr ref={listHeadTrRef}>
            {headerCols}
          </tr>
        </thead>
      </table>
    </div>
  );
});

export default TableHeadArea;