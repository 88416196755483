import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import { useLocation } from "react-router";
import TabArea from "./area/tab";
import FindStudentArea from "./area/find_student";
import TodoContetnArea from "./area/todo_content";
import MakeOptionArea from  "./area/make_option";
import TodoWriteListArea from  "./area/todo_write_list";
import MakeTodoWriteFunc from "./func/make_todo_write";

function PlanMakeTodo(){
  let user=useSelector((state:any) => state.user);
  const { state } = useLocation();
  const StuListAreaRef=useRef<any>(null);
  const StuListAreaWrapRef=useRef<any>(null);
  const StuListAreaWrapTabRef=useRef<any>(null);

  const TodoContetnAreaRef=useRef<any>(null);
  const TodoContetnAreaWrapRef=useRef<any>(null);

  const MakeOptionAreaRef=useRef<any>(null);
  const MakeOptionAreaWrapRef=useRef<any>(null);

  const TodoWriteListAreaRef=useRef<any>(null);
  const TodoWriteListAreaWrapRef=useRef<any>(null);

  const [tab_data_arr,set_tab_data_arr]=useState([
    {"value":"student","text":"학생선택","width":150},
    {"value":"content","text":"내용선택","width":150},
    {"value":"option","text":"맞춤설정","width":150},
    {"value":"write","text":"플랜확인","width":150},
  ]);

  useEffect(()=>{
    
    setInitByLocationState();
  },[]);

  const setInitByLocationState=()=>{
    if(strFunc.is_empty(state)){
      return false;
    }
    if(!strFunc.is_empty(state.stu_seq_arr)){
      StuListAreaRef.current.set_stu_by_user_seq_arr(state.stu_seq_arr);
      StuListAreaWrapTabRef.current.style.display="none";
      set_tab_data_arr([
        {"value":"content","text":"내용선택","width":150},
        {"value":"option","text":"맞춤설정","width":150},
        {"value":"write","text":"플랜확인","width":150},
      ]);
    }
    if(!strFunc.is_empty(state.select_date_str)){
      MakeOptionAreaRef.current.setInitData({
        "make_option":{
          "reserve_start_date":state.select_date_str,
          "reserve_end_date":state.select_date_str,
        },
      });
    }
  };

  const onTabClick=(in_tab_id:string)=>{
    if(in_tab_id=="student"){
      if(document.getElementsByClassName("main_body")){
        (document.getElementsByClassName("main_body")[0] as HTMLDivElement).scrollTop=0;
      }else{
        StuListAreaWrapRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }else if(in_tab_id=="content"){
      TodoContetnAreaWrapRef.current?.scrollIntoView({ behavior: 'smooth' });
    }if(in_tab_id=="option"){
      MakeOptionAreaWrapRef.current?.scrollIntoView({ behavior: 'smooth' });
    }if(in_tab_id=="write"){
      TodoWriteListAreaWrapRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const make_todo_write_plan=()=>{
    let todo_content_arr=TodoContetnAreaRef.current.get_todo_content_arr();
    let make_option=MakeOptionAreaRef.current.get_make_option();

    if(todo_content_arr.length==0){
      alert("내용 선택이 필요합니다.");
      return false;
    }
    if(strFunc.is_empty(make_option.one_day_amount)||strFunc.is_empty(make_option.one_day_amount_time)){
      alert("하루분량이 필요합니다.");
      return false;
    }

    let todo_write_arr=MakeTodoWriteFunc.get_make_todo_write({
      todo_content_arr:todo_content_arr,
      make_option:make_option
    });
    
    TodoWriteListAreaRef.current.setInitData({
      todo_write_arr:todo_write_arr
    });

  };

  const make_todo_by_pan_write=()=>{
    let stu_info_arr=StuListAreaRef.current.get_stu_arr();
    let todo_write_arr=TodoWriteListAreaRef.current.get_todo_write_arr();

    if(strFunc.is_empty(user.comp_seq)){
      alert("로그인이 필요합니다.");
      return false;
    }
    if(stu_info_arr.length==0){
      alert("학생 선택이 필요합니다.");
      return false;
    }
    if(todo_write_arr.length==0){
      alert("스케줄 작성내용이 없습니다.");
      return false;
    }

    if(!confirm("스케줄을 등록 하시겠습니까?")){
      return false;
    }
    let stu_seq_arr=[];
    for(let i=0;i<stu_info_arr.length;i++){
      stu_seq_arr.push(stu_info_arr[i]["a_seq"]);
    }

    let formJsonData={
      "m_comp_seq":user.comp_seq,
      "stu_seq_arr":stu_seq_arr,
      "todo_write_arr":todo_write_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/make_by_custom_and_user',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("등록되었습니다.");
        if(document.getElementsByClassName("main_body")){
          (document.getElementsByClassName("main_body")[0] as HTMLDivElement).scrollTop=0;
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <CompLayout isConTitle={true}>
      <div ref={StuListAreaWrapRef}>
        <div ref={StuListAreaWrapTabRef}>
          <TabArea 
            tab_id={"student"}
            set_tab_id={onTabClick}
            tab_data_arr={tab_data_arr}></TabArea>
        </div>
        <FindStudentArea
          ref={StuListAreaRef}
        ></FindStudentArea>
      </div>
      <div ref={TodoContetnAreaWrapRef}>
        <TabArea 
          tab_id={"content"}
          set_tab_id={onTabClick}
          tab_data_arr={tab_data_arr}></TabArea>
        <TodoContetnArea
          ref={TodoContetnAreaRef}
        ></TodoContetnArea>
      </div>
      <div ref={MakeOptionAreaWrapRef}>
        <TabArea 
          tab_id={"option"}
          set_tab_id={onTabClick}
          tab_data_arr={tab_data_arr}></TabArea>
        <MakeOptionArea
          ref={MakeOptionAreaRef}
          make_todo_write_plan={make_todo_write_plan}
          get_todo_content_arr={()=>{return TodoContetnAreaRef.current.get_todo_content_arr();}}
        ></MakeOptionArea>
      </div>

      <div ref={TodoWriteListAreaWrapRef}>
        <TabArea 
          tab_id={"write"}
          set_tab_id={onTabClick}
          tab_data_arr={tab_data_arr}></TabArea>
        <TodoWriteListArea
          ref={TodoWriteListAreaRef}
          make_todo_by_pan_write={make_todo_by_pan_write}
        ></TodoWriteListArea>
      </div>

    </CompLayout>
  );
}

export default PlanMakeTodo;