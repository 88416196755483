import strFunc from "@/lib/lyg/string";
import { useEffect, useRef } from "react";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function StuSelectBoxArea(props: any) {
  let myProps = {
    Style: {},
    now_ymdhis: "",
    room_info: {},
    student_info_arr: [],
    is_stu_filter_lecture: true,
    is_stu_filter_use_desk: true,
    select_stu_filter: {},
    set_select_stu_filter: (inData: any) => { },
    select_stu_data: {},
    set_select_stu_data: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let now_date_ymd = myProps.now_ymdhis.substring(0,10);
  
  let room_info = myProps.room_info;
  let desku_arr: any = [];
  if (room_info.desku_arr) {
    desku_arr = room_info.desku_arr;
  }
  const room_box_stu_con_ref = useRef<any>();
  const stu_list_wrap_ref = useRef<any>();
  useEffect(() => {
    setTimeout(() => {
      if (room_box_stu_con_ref.current) {
        let max_height = room_box_stu_con_ref.current.parentElement.offsetHeight;
        room_box_stu_con_ref.current.style.maxHeight = max_height + "px";
        room_box_stu_con_ref.current.style.height = max_height + "px";
        if (stu_list_wrap_ref.current) {
          stu_list_wrap_ref.current.style.maxHeight = (max_height - 35) + "px";
          stu_list_wrap_ref.current.style.height = (max_height - 35) + "px";
        }
      }
    }, 200);
  }, []);
  let stu_select_title_style = {
    height: "30px",
    lineHeight: "30px",
  };
  let stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });

  const get_filtered_stu_info_arr = () => {
    let filtered_stu_info_arr = [];
    let after_add_stu_info_arr=[];
    let except_stu_seq_arr: any = [];
    //사용중인 학생 필터링
    if (myProps.is_stu_filter_use_desk) {
      for (let i = 0; i < desku_arr.length; i++) {
        let desku_info = desku_arr[i];
        if(desku_info["a_date"]==now_date_ymd||desku_info["a_end_date"]==now_date_ymd){
          if (desku_info["a_user_seq"]) {
            except_stu_seq_arr.push(desku_info["a_user_seq"]);
          }
        }
      }
    }

    //강의식 강의 필터링
    if (myProps.is_stu_filter_lecture) {
      for (let i = 0; i < myProps.student_info_arr.length; i++) {
        let stu_info = myProps.student_info_arr[i];
        let is_exist_lecture = false;
        if (stu_info.lecture_arr && stu_info.lecture_arr.length > 0) {
          for (let lec_i = 0; lec_i < stu_info.lecture_arr.length; lec_i++) {
            let tmp_lec_info = stu_info.lecture_arr[lec_i];
            if (room_info["a_seq"] == tmp_lec_info["a_room_seq"]) {
              is_exist_lecture = true;
            }
          }
        }
        if (is_exist_lecture == false) {
          except_stu_seq_arr.push(stu_info["a_seq"]);
        }
      }
    }

    for (let i = 0; i < myProps.student_info_arr.length; i++) {
      let stu_info = myProps.student_info_arr[i];
      let is_able_add = true;
      if (strFunc.str_in_array(stu_info["a_seq"], except_stu_seq_arr) != -1) {
        is_able_add = false;
      }

      //이름 필터링
      if (!strFunc.is_empty(myProps.select_stu_filter["s_user_name"])) {
        let tmp_search_rs_num = stu_info["a_user_name"].indexOf(myProps.select_stu_filter["s_user_name"]);
        if (tmp_search_rs_num < 0) {
          is_able_add = false;
        }
      }
      //학년 필터링
      if (!strFunc.is_empty(myProps.select_stu_filter["s_stu_grade"])) {
        let stu_grade_num = parseInt(strFunc.uncomma(stu_info["a_stu_grade"]));
        if (myProps.select_stu_filter["s_stu_grade"] == "elementary") {
          if (stu_grade_num >= 8 && stu_grade_num <= 13) {
          } else {
            is_able_add = false;
          }
        } else if (myProps.select_stu_filter["s_stu_grade"] == "middle") {
          if (stu_grade_num >= 14 && stu_grade_num <= 16) {
          } else {
            is_able_add = false;
          }
        } else if (myProps.select_stu_filter["s_stu_grade"] == "high") {
          if (stu_grade_num >= 17 && stu_grade_num <= 19) {
          } else {
            is_able_add = false;
          }
        } else if (stu_info["a_stu_grade"] != myProps.select_stu_filter["s_stu_grade"]) {
          is_able_add = false;
        }
      }
      //출석 필터링
      if (!strFunc.is_empty(myProps.select_stu_filter["s_attend_sort_detail"])) {
        let attend_in_info=null;
        if(stu_info.attend_in_arr&&stu_info.attend_in_arr.length>0){
          attend_in_info=stu_info.attend_in_arr[0];
        }
        if(myProps.select_stu_filter["s_attend_sort_detail"]=="empty"){
          if(attend_in_info!=null){
            is_able_add = false;
          }
        }else if(myProps.select_stu_filter["s_attend_sort_detail"]=="no_absent"){
          if(attend_in_info!=null){
            if(attend_in_info["a_sort_detail"]=="absent"){
              is_able_add = false;
            }
          }
        }else{
          if(attend_in_info==null){
            is_able_add = false;
          }else{
            if(attend_in_info["a_sort_detail"]!=myProps.select_stu_filter["s_attend_sort_detail"]){
              is_able_add = false;
            }
          }
        }
      }

      stu_info["desk_use_arr"] = [];
      for (let desk_i = 0; desk_i < desku_arr.length; desk_i++) {
        let desku_info = desku_arr[desk_i];
        if(desku_info["a_date"]==now_date_ymd||desku_info["a_end_date"]==now_date_ymd){
          if (desku_info["a_user_seq"] == stu_info["a_seq"]) {
            stu_info["desk_use_arr"].push(desku_info);
          }
        }
      }
      if(stu_info["desk_use_arr"].length>0){
        if (myProps.is_stu_filter_use_desk==false) {
          if(is_able_add){
            after_add_stu_info_arr.push(stu_info);
          }
        }
        is_able_add=false;
      }

      if (is_able_add) {
        filtered_stu_info_arr.push(stu_info);
      }
    }
    //뒤에 추가
    if(after_add_stu_info_arr.length>0){
      for(let i=0;i<after_add_stu_info_arr.length;i++){
        filtered_stu_info_arr.push(after_add_stu_info_arr[i]);
      }
    }

    return filtered_stu_info_arr;
  };
  let filtered_stu_info_arr = get_filtered_stu_info_arr();

  const on_select_stu = (inData: any) => {
    let opt_obj = {
      stu_info: null,
      ...inData
    };
    let is_exist_pre = false;
    if (myProps.select_stu_data.is_select) {
      if (myProps.select_stu_data.stu_info["a_seq"] == opt_obj.stu_info["a_seq"]) {
        is_exist_pre = true;
      }
    }

    if (is_exist_pre) {
      myProps.set_select_stu_data({
        is_select: false,
        stu_info: null
      });
    } else {
      myProps.set_select_stu_data({
        is_select: true,
        stu_info: opt_obj.stu_info
      });
    }
  };

  const get_stu_name_tags = () => {
    let stu_name_tags = filtered_stu_info_arr.map((item: any, idx: number) => {
      let stu_grade_str = "";
      for (let i = 0; i < stu_grade_option_arr.length; i++) {
        if (stu_grade_option_arr[i]["value"] == item["a_stu_grade"]) {
          stu_grade_str = stu_grade_option_arr[i]["text"];
        }
      }
      let stu_name_str = item["a_user_name"];
      if (stu_grade_str) {
        stu_name_str += " " + stu_grade_str;
      }

      let stu_span_style: any = {};
      if (item.desk_use_arr && item.desk_use_arr.length > 0) {
        stu_span_style.color = "yellow";
      }
      
      if(item.attend_in_arr&&item.attend_in_arr.length>0){
        let attend_in_info=item.attend_in_arr[0];
        let border_bottom_color="";
        if(attend_in_info["a_sort_detail"]=="attend"){
          border_bottom_color="#379D89";
        }else if(attend_in_info["a_sort_detail"]=="late"){
          border_bottom_color="#efad2f";
        }else if(attend_in_info["a_sort_detail"]=="absent"){
          border_bottom_color="#df6d51";
        }
        stu_span_style.backgroundColor=border_bottom_color;
      }

      if (myProps.select_stu_data.is_select) {
        if (myProps.select_stu_data.stu_info["a_seq"] == item["a_seq"]) {
          stu_span_style.backgroundColor = "#1526e0";
        }
      }

      return (
        <div key={idx}
          className={Style.stu_select_stu_box}
          style={stu_span_style}
          onClick={() => {
            on_select_stu({ stu_info: item });
          }}
        >{stu_name_str}</div>
      );
    });

    return stu_name_tags;
  };

  return (
    <div className={Style.room_box_stu_con} ref={room_box_stu_con_ref} >
      <div className="text-center" style={stu_select_title_style} >
        학생선택 ({filtered_stu_info_arr.length}명)
      </div>
      <div className={Style.stu_select_list_wrap} ref={stu_list_wrap_ref} >
        {get_stu_name_tags()}
      </div>
    </div>
  );
};
export default StuSelectBoxArea;