import React,{ useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import ExcelFunc from '@/lib/lyg/excel';
import ExcelDownComponent from "@/pcomponents/common/excel/down";
import * as XLSX from "xlsx";

const ExcelUploadPopup = forwardRef((props:any, ref) => {
  let myProps:any={
    onClose:()=>{},
    onCallback:(inData:any)=>{},
    xColumnArr:{},
    start_data_row_num:3,
    is_origin_key_include:false,
    wrap_style:{
      position:"absolute",
      width:"100%",
      top:200,
      left:0,
      zIndex:99,
    },
    wrap_con_style:{
      margin:"0 auto",
      width:300,
      height:300,
      border:"1px solid #ddd",
      padding:10,
      background:"#fff",
    },
    ...props
  };
  useEffect(()=>{
    
  },[]);
  const ExcelDownComponentRef=useRef<any>(null);

  const [start_data_row_num,set_start_data_row_num]=useState(myProps.start_data_row_num);
  const [excel_data,set_excel_data]=useState<any>([]);

  const readExcel=(event:any) =>{
    let input = event.target;
    let reader = new FileReader();
    reader.onload = function () {
      let data = reader.result;
      let workBook = XLSX.read(data, { type: 'binary' });
      let sheet_idx=0;
      workBook.SheetNames.forEach(function (sheetName) {
        if(sheet_idx==0){
          let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
          let start_row=parseInt(start_data_row_num);
          start_row=start_row-2;
          
          let tmp_row_data:any=[];
          let row_len=rows.length;
          for(let i=0;i<row_len;i++){
            if(i>=start_row){
              tmp_row_data.push(
                getRowDataByXcolumn(rows[i])
              );
            }
          }
          set_excel_data(tmp_row_data);
        }
        sheet_idx++;
      });
    };
    reader.readAsBinaryString(input.files[0]);
  }

  const getRowDataByXcolumn=(inRowData:any)=>{
    let rowData:any={};
    if(myProps.is_origin_key_include){
      rowData=inRowData;
    }

    for(let key in inRowData){
      //trim
      let trim_key=strFunc.trim(key);
      inRowData[trim_key]=inRowData[key];
      key=trim_key;

      let key2=key;
      for(let i=0;i<myProps.xColumnArr.x_column_list_arr.length;i++){
        let xcolumn=myProps.xColumnArr.x_column_list_arr[i];
        
        if(xcolumn.name==key){
          key2=xcolumn.key;
        }
      }
      let value=inRowData[key];
      value=value+"";
      if(strFunc.str_in_array(key2,myProps.xColumnArr.date_col_arr)!=-1){
        if(value.length==10||value.length==8){
          value=strFunc.autoHypenDateStr(value);
        }else{
          let value_date_num=strFunc.uncomma(value);
          if(value_date_num.length==5){
            value_date_num=parseInt(value_date_num);
            value_date_num=ExcelFunc.excel_time_to_unix_time(value_date_num);
            value=DateFunc.get_date_format(new Date(value_date_num),"Y-m-d");
          }else{
            value="";
          }
        }
      }

      rowData[key2]=value;
    }

    return rowData;
  };

  useImperativeHandle(ref, () => ({

  }));

  return (
    <div style={myProps.wrap_style}>
      <div style={myProps.wrap_con_style}>
        <h5 className="text-center" style={{borderBottom:"1px solid #ddd"}}>
          엑셀업로드
        </h5>
        <div className="mt-3 btn-box-center">
          <button className="btn btn-dark" onClick={()=>{
            if(ExcelDownComponentRef.current){
              ExcelDownComponentRef.current.goDownExcel();
            }
          }}>양식다운로드</button>
        </div>
        <div className="mt-3">
          데이터 시작줄:
          <input type="text" value={start_data_row_num} onChange={(e:any)=>{
            set_start_data_row_num(e.target.value);
          }} style={{width:30}}/>
        </div>
        <div className="mt-3">
          <input type="file" onChange={readExcel}  style={{width:240}} />
        </div>
        <div className="btn-box-center mt-3">
          <button className="btn btn-dark" onClick={()=>{myProps.onCallback(excel_data);myProps.onClose();}} >업로드</button>
          <button className="btn btn-dark" onClick={()=>{myProps.onClose();}} >닫기</button>
        </div>
        <ExcelDownComponent
          ref={ExcelDownComponentRef}
          xColumnArr={myProps.xColumnArr}
          excelFileName={"양식파일"}
        ></ExcelDownComponent>
      </div>
    </div>
  );
});
export default ExcelUploadPopup;