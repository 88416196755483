//import VimeoArea from  "@/pcomponents/common/content/video/player/vimeo";
import { useState,useRef,useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import VimeoFunc from "./func/index";

function VimeoArea(props:any){
   let myProps={
    video_url:"",
    init_seek_data:{start:0,end:1},
    set_is_play:(inData:any)=>{},
    video_opt:{},
    ...props
  };

  const player_ref=useRef<any>(null);
  const current_sec_ref=useRef(0);
  const [player,set_player]=useState<any>(null);
  const [seek_data,set_seek_data]=useState<any>(myProps.init_seek_data);
  const [is_show_player,set_is_show_player]=useState(true);
  let video_opt={
    width:(window.innerWidth || document.documentElement.clientWidth)*0.95,
    ...myProps.video_opt,
  };

  useEffect(()=>{
    set_seek_data(myProps.init_seek_data);
    if(player_ref.current){
      set_player_event();
    }
  },[is_show_player]);

  useEffect(()=>{
    set_is_show_player(false);
    setTimeout(()=>{
      set_is_show_player(true);
    },100);
  },[myProps.video_url]);

  //반복체크
  // useEffect(() => {
  //     const video_check_loop=setInterval(video_check_by_loop,500);
  //     return () => {
  //         clearInterval(video_check_loop);
  //     };
  // }, [player,seek_data]);

  // const video_check_by_loop=()=>{
  //   if(player){
  //     player.getCurrentTime().then(function(seconds:any) {
  //       if(seconds>seek_data.end-1){
  //         player.pause();
  //         player.setCurrentTime(seek_data.end-3);
  //         myProps.set_is_play(false);
  //       }
  //     });
  //   }
  // };

  const set_player_event=()=>{
    var player:any =VimeoFunc.get_player_by_iframe_obj(player_ref.current);
    if(player==null){
      return false;
    }
    player.on('loaded', function(e:any) {
      if(seek_data.start>0){
        player.getDuration().then(function(duration:number) {
          // `duration` indicates the duration of the video in seconds
          let tmp_current_sec=seek_data.start;
          if(tmp_current_sec>duration-2){
            tmp_current_sec=0;
          }
          player.setCurrentTime(tmp_current_sec);
        });
      }
      //console.log('loaded');
      // console.log(e);
    });
    player.on('play', function(e:any) {
      myProps.set_is_play(true);
      // console.log("onPlay");
      // console.log(e);
      if(e!=undefined){
        set_seek_data({
          start:e.seconds,
          end:e.duration,
        });
      }
    });
    player.on('seeked', function(e:any) {
      // console.log('seeked');
      // console.log(e);
    });
    player.on('pause', function(e:any) {
      // console.log('pause');
      // console.log(e);
      myProps.set_is_play(false);
    });
    player.on('ended', function(e:any) {
      // console.log("ended");
      // console.log(e);
      player.pause();
      myProps.set_is_play(false);
      if(e!=undefined){
        setTimeout(()=>{
          player.setCurrentTime(e.duration-3);
        },200);
      }
      // console.log("onEnd");
    });
    player.on('error', function(e:any) {
      // console.log('error');
      // console.log(e);
    });
    set_player(player);
  };
  const go_last_screen=()=>{
    if(player){
      player.setCurrentTime(seek_data.end-3);
      player.play();
      setTimeout(()=>{
        player.pause();
      },1000);
    }
  };

  let video_id="";
  if(!strFunc.is_empty(myProps.video_url)){
    video_id=strFunc.get_vimeo_id_by_url(myProps.video_url);
  }

  let win_w=video_opt.width;
  let video_h=Math.round(win_w*0.54);
  let hash_parameter="&byline=false";
  hash_parameter+="&width="+win_w;
  hash_parameter+="&responsive=false";
  hash_parameter+="&title=false";
  hash_parameter+="&portrait=false";
  hash_parameter+="&dnt=false";
  hash_parameter+="&pip=false";
  hash_parameter+="&playsinline=false";
  hash_parameter+="&interactive_markers=false";

  let src_str="https://player.vimeo.com/video/"+video_id+"?h="+hash_parameter;
  
  return (
    <div>
      {(video_id!=""&&is_show_player)&&
      <>
        <iframe 
        ref={player_ref}
        src={src_str}  
        width={win_w} height={video_h}
        allow="autoplay; fullscreen; picture-in-picture;"
        style={{border:0}}></iframe>

        <div className="text-center my-1">
          <button className="btn btn-gray" onClick={()=>{
            if(player){
              player.play();
            }
          }}>Play</button>
          <button className="btn btn-gray ml-1" onClick={()=>{
            if(player){
              player.pause();
            }
          }}>Stop</button>
          <button className="btn btn-gray ml-1" onClick={()=>{
            go_last_screen();
          }}>마지막화면</button>
        </div>
      </>
      }
    </div>
  );
};
export default VimeoArea;