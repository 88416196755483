import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import WriteArea from "./area/write";

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    s_is_login: "1",
    s_addon_class_user: "1",
    s_addon_counsel_career_recent: "1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_attend_sort_detail: "",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
    select_seq: "",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [open_write_data, set_open_write_data] = useState({
    is_open: false,
    is_update: false,
    row_data: {},
    stu_info: {},
  });
  const write_area_ref = useRef<any>(null);

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt = {
      ...listOpt,
      ...inOptObj
    };
    if (listAggridRef.current) {
      tmp_list_opt["sc"] = listAggridRef.current.getListSc();
    }
    setListOpt(tmp_list_opt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          //tmp_list_opt.select_seq
          if (!strFunc.is_empty(tmp_list_opt.select_seq)) {
            setTimeout(() => {
              let listNodes = listAggridRef.current.getNodeRows();
              for (let i = 0; i < listNodes.length; i++) {
                let node = listNodes[i];
                if (node.data["a_seq"] == tmp_list_opt.select_seq) {
                  listNodes[i].setSelected(true);
                }
              }
            }, 100);
          }
        } else {

        }
      });
  };

  const go_delete_counsel_one = (ruta_cousel_info: any) => {
    if (strFunc.is_empty(ruta_cousel_info)) {
      return false;
    }
    if (strFunc.is_empty(ruta_cousel_info["a_seq"])) {
      return false;
    }

    if (!confirm("루타 상담1개를 삭제 하시겠습니까?")) {
      return false;
    }
    let del_row_data = {
      a_ymd: ruta_cousel_info["a_ymd"],
      a_seq: ruta_cousel_info["a_seq"]
    };
    let del_form_json_data = {
      "data_arr": [del_row_data]
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_career/delete', del_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_write_page = (is_update: boolean, row_data: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let stu_info = selected_row[0];

    if (write_area_ref.current) {
      let counsel_career_info = null;
      let write_is_update = false;
      let counsel_career_arr = [];
      if (stu_info.counsel_career_arr && stu_info.counsel_career_arr.length > 0) {
        counsel_career_info = stu_info.counsel_career_arr[0];
        counsel_career_arr = stu_info.counsel_career_arr;
        write_is_update = true;
      }
      if (is_update) {
        if (counsel_career_info == null) {
          is_update = false;
        }
      }
      write_area_ref.current.set_init_data({
        stu_info: stu_info,
        row_data: counsel_career_info,
        is_update: is_update,
        info_arr: counsel_career_arr
      });
    }
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key == "a_title") {
      //openOnePopup("view",true);
    }
  };

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "a_user_name") {
      render_str += " " + params.data["a_stu_total_code"];
    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(
            strFunc.cut_str(params.data.class_arr[i]["class_name"], 4, "")
          );
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_ruta_counsel") {
      if (params.data.counsel_career_arr && params.data.counsel_career_arr.length > 0) {
        let last_counsel_career: any = params.data.counsel_career_arr[0];
        render_str = (
          <div>
            {last_counsel_career["a_counsel_date"].substring(0, 10)}
            <button className="btn btn-dark ml-2" onClick={() => {
              setTimeout(() => {
                go_write_page(true, last_counsel_career);
              }, 100);
            }}>수정</button>
            ({params.data.counsel_career_arr.length})
            <button className="btn btn-red ml-1" onClick={() => {
              setTimeout(() => {
                go_delete_counsel_one(last_counsel_career);
              }, 100);
            }}>삭제</button>
          </div>
        );
      } else {
        render_str = (<button className="btn btn-sky ml-2" onClick={() => {
          setTimeout(() => {
            go_write_page(false, {});
          }, 100);
        }}>신규</button>);
      }
    }

    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      if (write_area_ref.current) {
        write_area_ref.current.set_init_data({
          stu_info: {},
          row_data: {},
          is_update: false,
          info_arr: [],
        });
      }
    } else if (selected_row.length == 1) {
      let row_stu_info = selected_row[0];
      if (write_area_ref.current) {
        let counsel_career_info = {};
        let counsel_career_arr = [];
        let write_is_update = false;
        if (row_stu_info.counsel_career_arr && row_stu_info.counsel_career_arr.length > 0) {
          counsel_career_info = row_stu_info.counsel_career_arr[0];
          counsel_career_arr = row_stu_info.counsel_career_arr;
          write_is_update = true;
        }
        write_area_ref.current.set_init_data({
          stu_info: row_stu_info,
          row_data: counsel_career_info,
          is_update: write_is_update,
          info_arr: counsel_career_arr
        });
      }
    }
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea
          listOpt={{ ...listOpt }}
          setListOpt={setListOpt}
          xColumnArr={{ ...xColumnArr }}
          list={list}
        ></SearchArea>
        <div className="mt-2 text-right">
          <button className="btn btn-sky" onClick={() => { go_write_page(false, {}); }}>신규등록</button>
        </div>
        <div style={{ display: "flex", gap: 5,marginTop:10 }}>
          <div style={{ minWidth: "560px" }}>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{ ...xColumnArr }}
              list={list}
              gridOpt={{
                fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num: true,
                is_add_checkbox: true,
                floatingFilter: false,
                onGridReady: () => {
                  list({});
                },
                onCellClicked: cellClickedListener,
                cellRenderer: cellRenderer,
                onSelectionChanged: onSelectionChanged
              }}
            ></ListAggrid>
            <Paging now_page={listOpt.now_page}
              num_per_page={listOpt.num_per_page}
              total_rec={countInfo.tot}
              onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
              onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
          </div>
          <div style={{ minWidth: "600px",border:"1px solid #eee",borderRadius:"10px",paddingTop:10 }}>
            <WriteArea
              ref={write_area_ref}
              is_update={open_write_data.is_update}
              close_write={() => {
                set_open_write_data({
                  ...open_write_data,
                  is_open: false,
                });
              }}
              list={list}
            ></WriteArea>
          </div>
        </div>
      </div>
    </CompLayout>
  );
}

export default ListPage;