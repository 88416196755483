function TopStuInfoArea(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    user:{},
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;
  let user=myProps.user;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  return (
    <div className={Style.top_wrap}>
      <div className={Style.top_title_wrap}>
        진로 및 학생부 설계 컨설팅
        <div className={Style.top_title_under_bar} ></div>
        <div className={Style.top_title_right_logo_box}>
          <img src={process.env.REACT_APP_API_URL+"/img/logo/ruta.png"} style={{height:"100%"}}/>
        </div>
      </div>
      <div className={Style.top_stu_table_wrap}>
        <table style={{ flexGrow: "1",width:"80%" }}>
          <colgroup>
            <col width="25%"></col>
            <col width="13%"></col>
            <col width="13%"></col>
            <col width="13%"></col>
            <col width="13%"></col>
            <col width="23%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>학교명</th>
              <th>학년</th>
              <th>반</th>
              <th>번호</th>
              <th>성별</th>
              <th>성명</th>
            </tr>
            <tr>
              <td>
                {is_view_mode ?
                  info.a_school
                  :
                  <input type="text" value={info.a_school}
                    onChange={(e: any) => { handleInputChange({ name: "a_school", value: e.target.value }); }}
                    placeholder="학교명" />
                }
              </td>
              <td>
                {is_view_mode ?
                  info.a_school_grade
                  :
                  <input type="text" value={info.a_school_grade}
                    onChange={(e: any) => { handleInputChange({ name: "a_school_grade", value: e.target.value }); }}
                    placeholder="학년" />
                }
              </td>
              <td>
                {is_view_mode ?
                  info.a_school_class
                  :
                  <input type="text" value={info.a_school_class}
                    onChange={(e: any) => { handleInputChange({ name: "a_school_class", value: e.target.value }); }}
                    placeholder="반" />
                }
              </td>
              <td>
                {is_view_mode ?
                  info.a_school_num
                  :
                  <input type="text" value={info.a_school_num}
                    onChange={(e: any) => { handleInputChange({ name: "a_school_num", value: e.target.value }); }}
                    placeholder="번호" />
                }
              </td>
              <td>
                {is_view_mode ?
                  info.a_gender
                  :
                  <input type="text" value={info.a_gender}
                    onChange={(e: any) => { handleInputChange({ name: "a_gender", value: e.target.value }); }}
                    placeholder="성별" />
                }
              </td>
              <td>
                {is_view_mode ?
                  info.a_stu_name
                  :
                  <input type="text" value={info.a_stu_name}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_name", value: e.target.value }); }}
                    placeholder="학생명" />
                }
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "160px" }}>
          <colgroup>
            <col width="100%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                기타
                <span style={{ fontSize: 11 }}>(국적 등 특이사항)</span>
              </th>
            </tr>
            <tr>
              <td>
                {is_view_mode ?
                  info.a_stu_etc
                  :
                  <input type="text" value={info.a_stu_etc}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_etc", value: e.target.value }); }}
                    placeholder="기타" />
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TopStuInfoArea;