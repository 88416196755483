import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteFrameContent from "./area/write_area";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';

function SMSSenderListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const WriteFrameContentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_addon_mcomp_name":"1"
    }
  });
  const [select_seq_arr,set_select_seq_arr]=useState<any>([]);
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    let tmp_form_data=pageData.listOpt;
    tmp_form_data["s_mcomp_seq"]=user.comp_seq;
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms_sender/list',tmp_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        let list_nodes=listAggridRef.current.getNodeRows();
        for(let i=0;i<list_nodes.length;i++){
          let row_node=list_nodes[i];
          if(strFunc.str_in_array(row_node.data.a_seq,select_seq_arr)!=-1){
            row_node.setSelected(true);
          }
        }
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_json_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms_sender/delete',del_form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goNewWrite=()=>{
    listAggridRef.current.setSelectFalseAll();
    WriteFrameContentRef.current.setInitData({
      "row_data":{},
      "isUpdate":false,
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
   
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_mcomp_name"){
      if(params.data.mcomp_name){
        render_str=params.data.mcomp_name;
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged=()=>{
    setTimeout(()=>{
      let tmp_select_seq_arr:any=[];
      let selected_row=listAggridRef.current.getSelectedRows();
      for(let i=0;i<selected_row.length;i++){
        tmp_select_seq_arr.push(selected_row[i].a_seq);
      }

      if(tmp_select_seq_arr.length==1){
        WriteFrameContentRef.current.setInitData({
          "row_data":selected_row[0],
          "isUpdate":true,
        });
      }else if(tmp_select_seq_arr.length==0){
        WriteFrameContentRef.current.setInitData({
          "row_data":{},
          "isUpdate":false,
        });
      }
      set_select_seq_arr(tmp_select_seq_arr);
    },100);
  };

  return (
    <CompLayout>
      <div className="con_wrap">
      <table style={{width:"100%"}}>
          <colgroup>
            <col width={"50%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:280}}>
                <div className="btn-box-left relative h-8">
                  <div className="absolute right-0 top-0 btn-box-right">
                  <button className="btn btn-dark" onClick={goNewWrite}>신규</button>
                    <button className="btn btn-red" onClick={goDelete}>삭제</button>
                  </div>
                </div>
                <ListAggrid
                ref={listAggridRef}
                infoArr={infoArr}
                xColumnArr={{...xColumnArr}}
                list={list}
                gridOpt={{
                  fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                  is_idx_num:true,
                  is_add_checkbox:true,
                  floatingFilter:true,
                  onGridReady:()=>{
                    list({});
                  },
                  onCellClicked:cellClickedListener,
                  cellRenderer:cellRenderer,
                  onSelectionChanged:onSelectionChanged,
                }}
                ></ListAggrid>
                <Paging now_page={pageData.listOpt.now_page}
                  num_per_page={pageData.listOpt.num_per_page}
                  total_rec={countInfo.tot} 
                  onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:400}}>
                  <WriteFrameContent
                    ref={WriteFrameContentRef}
                    list={(inOptObj:any)=>{list(inOptObj);}}
                    set_select_seq_arr={(inData:any)=>{set_select_seq_arr(inData);}}
                  ></WriteFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default SMSSenderListPage;