import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJsonUser from "../../xcolumn/user_list";
import strFunc from '@/lib/lyg/string';

const ListTagArea = forwardRef((props:any, ref) => {
  const myProps={
    openInvitePopup:(inData:any)=>{},
    set_grade_arr:(grade_arr:any)=>{},
    set_grade_user_arr:(grade_user_arr:any)=>{},
    ...props
  };
  const xColumnArrUser=getXcolumnJsonUser();

  const default_grade_row_data={
    "a_seq":"1",
    "a_title":"",
    "a_amount_sort":"amount",
    "a_amount":"",
    "a_order_num":"",
  };
  let default_grade_title_arr=["A","B","C","D","E","F","G","H","I"];
  const [grade_arr,set_grade_arr]=useState<any>([]);
  const [grade_user_arr,set_grade_user_arr]=useState<any>([]);
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "grade_arr":[],
      "grade_user_arr":[],
      ...inOptObj
    };
    set_grade_arr(optObj.grade_arr);
    set_grade_user_arr(optObj.grade_user_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const addGradeNewRow=()=>{
    let max_a_seq=0;
    if(grade_arr.length>0){
      for(let i=0;i<grade_arr.length;i++){
        let tmp_a_seq=parseInt(grade_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }
      }
    }
    let addRow:any={...default_grade_row_data};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    if(grade_arr.length<default_grade_title_arr.length){
      addRow["a_title"]=default_grade_title_arr[grade_arr.length];
    }
    let tmp_grade_arr:any=[...grade_arr];
    tmp_grade_arr.push(addRow);
    set_grade_arr(tmp_grade_arr);
    myProps.set_grade_arr(tmp_grade_arr);
  };

  const onChangeRowData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      key:"",
      value:"",
      row_num:0,
      ...inOptObj
    };
    let value=optObj["value"];
    let key=optObj["key"];
    let row_num=optObj["row_num"];

    if(key=="a_amount"){
      value=strFunc.comma(value);
    }

    let tmp_grade_arr:any=[...grade_arr];
    tmp_grade_arr[row_num][key]=value;

    set_grade_arr(tmp_grade_arr);
    myProps.set_grade_arr(tmp_grade_arr);
  };

  const removeGradeRowData=(row_num:number)=>{
    let tmp_grade_arr=[];
    for(let i=0;i<grade_arr.length;i++){
      if(i!=row_num){
        tmp_grade_arr.push(grade_arr[i]);
      }
    }
    set_grade_arr(tmp_grade_arr);
    myProps.set_grade_arr(tmp_grade_arr);
  };

  const removeGradeUserRowData=(row_num:number)=>{
    let tmp_grade_user_arr=[];
    for(let i=0;i<grade_user_arr.length;i++){
      if(i!=row_num){
        tmp_grade_user_arr.push(grade_user_arr[i]);
      }
    }
    set_grade_user_arr(tmp_grade_user_arr);
    myProps.set_grade_user_arr(tmp_grade_user_arr);
  };

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_grade_arr:any=[...grade_arr];

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_grade_arr.length){
      return false;
    }

    let now_row_data=tmp_grade_arr[row_num];
    let change_row_data=tmp_grade_arr[next_row_num];

    tmp_grade_arr[row_num]=change_row_data;
    tmp_grade_arr[next_row_num]=now_row_data;

    tmp_grade_arr=getReSetOrderNum(tmp_grade_arr);

    set_grade_arr(tmp_grade_arr);
    myProps.set_grade_arr(tmp_grade_arr);
  };

  const getReSetOrderNum=(tmp_grade_arr:any)=>{
    for(let i=0;i<tmp_grade_arr.length;i++){
      tmp_grade_arr[i].a_order_num=i+1;
    }
    return tmp_grade_arr;
  };

  const getGradeUserTags=(grade_seq:any)=>{
    let empty_grade_seq_arr:any=[];
    if(grade_seq=="empty"){
      for(let i=0;i<grade_user_arr.length;i++){
        let tmp_row=grade_user_arr[i];
        let tmp_is_exist_row=false;
        for(let j=0;j<grade_arr.length;j++){
          if(grade_arr[j]["a_seq"]==tmp_row["a_grade_seq"]){
            tmp_is_exist_row=true;
          }
        }
        if(tmp_is_exist_row==false){
          if(strFunc.str_in_array(tmp_row["a_grade_seq"],empty_grade_seq_arr)==-1){
            empty_grade_seq_arr.push(tmp_row["a_grade_seq"]);
          }
        }
      }
    }
    let gUserTags=grade_user_arr.map((item:any,idx:number)=>{
      if(grade_seq=="empty"){
        if(strFunc.str_in_array(item["a_grade_seq"],empty_grade_seq_arr)==-1){
          return "";
        }
      }else{
        if(item.a_grade_seq!=grade_seq){
          return "";
        }
      }
      let school_name="";
      let stu_grade_str="";
      if(item.stu_info){
        school_name=item.stu_info["a_school_name"];
        stu_grade_str=item.stu_info["a_stu_grade"];
        if(xColumnArrUser["select_arr"]["a_stu_grade"]){
          for(let i=0;i<xColumnArrUser["select_arr"]["a_stu_grade"].length;i++){
            let tmp_row=xColumnArrUser["select_arr"]["a_stu_grade"][i];
            if(tmp_row["value"]==stu_grade_str){
              stu_grade_str=tmp_row["text"];
            }
          }
        }
      }
      return (
        <tr key={idx}>
          <td className="text-center">
            {item.a_user_name}
          </td>
          <td>
            {stu_grade_str}
          </td>
          <td>
            {school_name}
          </td>
          <td className="text-center">
            <button className="btn-s2 btn-red ml-2" onClick={()=>{removeGradeUserRowData(idx);}}>X</button>
          </td>
        </tr>
      );
    });
    return gUserTags;
  };

  const getListTags=()=>{
    let tmplistTags=grade_arr.map((item:any,idx:number)=>{
      let gUserTags=getGradeUserTags(item.a_seq);
      return (
        <div key={idx} className="mt-2">
          <h5 className="p-1">
            <span className="mx-2" style={{color:"#777"}}>{idx+1}.</span>
            <input type="text" style={{width:"120px"}}
              value={item.a_title} onChange={(e:any)=>{
              onChangeRowData({
                key:"a_title",
                value:e.target.value,
                row_num:idx,
              });
            }} />
            <span className="mx-2">
              <button className="btn-s btn-gray" onClick={()=>{moveOrderNum(idx,"up");}}>▲</button>
              <button className="btn-s btn-gray ml-2" onClick={()=>{moveOrderNum(idx,"down");}}>▼</button>
              <button className="btn-s btn-red ml-2" onClick={()=>{removeGradeRowData(idx);}}>X</button>
            </span>
            <button className="btn-s2 btn-dark ml-2" onClick={()=>{myProps.openInvitePopup(item.a_seq);}}>초대</button>
          </h5>
          <table>
            <colgroup>
              <col width="100px"></col>
              <col width="80px"></col>
              <col width="80px"></col>
              <col width="50px"></col>
            </colgroup>
            <thead>
              <tr>
                <th>이름</th>
                <th>학년</th>
                <th>학교</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {gUserTags}
            </tbody>
          </table>
        </div>
      );
    });

    return tmplistTags;
  };
  const getEmptyListTags=()=>{
    let empty_grade_seq_arr:any=[];
    for(let i=0;i<grade_user_arr.length;i++){
      let tmp_row=grade_user_arr[i];
      let tmp_is_exist_row=false;
      for(let j=0;j<grade_arr.length;j++){
        if(grade_arr[j]["a_seq"]==tmp_row["a_grade_seq"]){
          tmp_is_exist_row=true;
        }
      }
      if(tmp_is_exist_row==false){
        if(strFunc.str_in_array(tmp_row["a_grade_seq"],empty_grade_seq_arr)==-1){
          empty_grade_seq_arr.push(tmp_row["a_grade_seq"]);
        }
      }
    }
    if(empty_grade_seq_arr.length==0){
      return "";
    }

    let tmpEmpyTags=getGradeUserTags("empty");
    
    let tmpListTags:any="";
    if(tmpEmpyTags){
      tmpListTags=(
        <div className="mt-2">
          <h5 className="p-1">
            소속없음.
          </h5>
          <table>
            <colgroup>
              <col width="100px"></col>
              <col width="80px"></col>
              <col width="80px"></col>
              <col width="50px"></col>
            </colgroup>
            <thead>
              <tr>
                <th>이름</th>
                <th>학년</th>
                <th>학교</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {tmpEmpyTags}
            </tbody>
          </table>
        </div>
      );
    }
    return tmpListTags;
  };

  let listTags=getListTags();
  let emptyTags=getEmptyListTags();

  return (
    <div className="list-table-div mt-1">
      <h5 className="m-2">
        등급별 학생
        <button className="btn btn-dark ml-2" onClick={()=>{addGradeNewRow();}}>등급추가</button>
      </h5>
      {listTags}
      {emptyTags}
    </div>
  );
});

export default ListTagArea;
