export const default_info={
  "a_ymd":"",
  "a_seq":"",
  "a_corp_seq":"",
  "a_stu_seq":"",
  "a_stu_name":"",

  "a_study_date":"",
  "a_writer_seq":"",
  "a_writer":"",

  "a_subject_name":"",
  "a_study_progress":"",
  "a_homework_progress":"",
  "a_progress_plan":"",
  "a_study_score":"",
  "a_attitude_score":"",
  "a_attitude":"",
  "a_homework_score":"",
  "a_homework_perform_score":"",
  "a_homework":"",
  "a_etc_memo":"",
  "a_study_prescribe":"",
  "a_study_goal":"",
  "a_observe":"",

  "a_is_success_prescribe":"",
  "a_prescribe_success_date":"",

  "a_title":"",
  "a_content":"",

  "detail_arr":[],
};
export const default_detail_info={
  "a_study_ymd":"",
  "a_study_seq":"",
  "a_seq":"",
  "a_sort":"progress",//homework
  "a_order_num":"0",
  "a_par_id":"",
  "a_par_seq":"",
  "a_study_title":"",
  "a_study_present":"",
  "a_amount":"",
  "a_amount_unit":"장",
  "a_time_sec":"0",
  "a_estimate_num":"0",
  "a_score":"0",
};