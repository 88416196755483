import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import CheckListArea from "./check_list";

function CategoryListArea(props:any){
   let myProps={
    infoArr:[],
    select_cate_seq_arr:[],
    set_select_cate_seq_arr:(inData:any)=>{},
    on_select_check:(inData:any)=>{},
    ...props
  };

  const on_click_cate_title=(inData:any)=>{
    let opt_obj={
      cate_seq:"",
      ...inData
    };
    if(strFunc.is_empty(opt_obj["cate_seq"])){
      return false;
    }
    myProps.set_select_cate_seq_arr([opt_obj["cate_seq"]]);
  };

  const get_cate_tags=()=>{
    let cate_tags=myProps.infoArr.map((item:any,idx:number)=>{
      let is_view_detail=false;
      if(strFunc.str_in_array(item.a_seq,myProps.select_cate_seq_arr)!=-1){
        is_view_detail=true;
      }

      let cate_class_name=Style.cate_title_wrap;
      if(is_view_detail){
        cate_class_name+=" "+Style.active;
      }

      let row_check_arr:any=[];
      if(item.check_arr){
        row_check_arr=item.check_arr;
      }

      return (
        <tr key={idx}>
          <th className={cate_class_name} 
            onClick={()=>{
              on_click_cate_title({cate_seq:item.a_seq});
            }}>
            {idx+1}. {item.a_title}
          </th>
          <td>
            {is_view_detail&&
              <CheckListArea
                check_arr={row_check_arr}
                select_cate_idx={idx}
                cate_info={item}
                on_select_check={myProps.on_select_check}
                Style={Style}
              ></CheckListArea>
            }
          </td>
        </tr>
      );
    });
    return cate_tags;
  };
  
  return (
  <div className="write_table_small">
    <table>
      <colgroup>
        <col width={140}></col>
        <col width={"*"}></col>
      </colgroup>
      <tbody>
        {get_cate_tags()}
      </tbody>
    </table>
  </div>
  );
};
export default CategoryListArea;