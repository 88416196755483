import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { default_info } from "./data/default_data";
import Style from "./css/style.module.css";
import { useReactToPrint } from 'react-to-print';
import EtcInfoArea from "./area/etc_info";
import TopStuInfoArea from "./area/top_stu_info";
import ContentArea1 from "./area/content1";
import ContentArea2 from "./area/content2";
import ContentArea3 from "./area/content3";
import ContentArea4 from "./area/content4";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import ListHistoryArea from "./area/list_history";

const WriteArea = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    close_write: () => { },
    list: (inData: any) => { },
    is_view_mode:true,
    is_view_pre_btn:true,
    ...props
  };

  let select_mcomp_data= myProps.select_mcomp_data;
  let user = useSelector((state: any) => state.user);
  const [is_view_mode, set_is_view_mode] = useState(myProps.is_view_mode);
  const [info, set_info] = useState(default_info);
  const [info_arr, set_info_arr] = useState([]);
  const [is_update, set_is_update] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const [print_page_style, set_print_page_style] = useState<any>({
    "padding": 40,
  });
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  useEffect(() => {
    
  }, []);

  const set_init_data = (inData: any) => {
    let opt_obj = {
      stu_info: {},
      row_data: {},
      is_update: false,
      info_arr: [],
      is_view_mode:is_view_mode,
      ...inData
    };
    set_is_update(opt_obj["is_update"]);
    set_info_arr(opt_obj["info_arr"]);
    set_is_view_mode(opt_obj["is_view_mode"]);
    if (opt_obj["is_update"]) {
      get_info_by_ajax(opt_obj.row_data["a_ymd"] + "," + opt_obj.row_data["a_seq"]);
    } else {
      let stu_info = opt_obj["stu_info"];
      let a_school_grade_str = "";
      for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
        if (select_stu_grade_option_arr[i]["value"] == stu_info["a_stu_grade"]) {
          a_school_grade_str = select_stu_grade_option_arr[i]["text"];
        }
      }
      let a_school_num_str = strFunc.str_pad({ "str": stu_info["a_stu_num"], "pad_length": user.stu_num_ciphers });

      set_info({
        ...default_info,
        "a_counsel_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
        "a_stu_seq": stu_info["a_seq"],
        "a_stu_name": stu_info["a_user_name"],
        "a_writer_seq": user.user_seq,
        "a_writer": user.user_name,
        "a_school": stu_info["a_school_name"],
        "a_school_grade": a_school_grade_str,
        "a_school_class": "",
        "a_school_num": "",
        "a_gender": stu_info["a_gender"] == "f" ? "여" : "남",
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_init_data
  }));

  const get_info_by_ajax = (row_pri_val: string) => {
    if (strFunc.is_empty(row_pri_val)) {
      return false;
    }
    let list_form_json:any = {
      "s_pri_arr": [row_pri_val],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        list_form_json["api_key"]=select_mcomp_data.api_key;
        list_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_career/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_info(response.data["data"]["info_arr"][0]);
          } else {
            alert("정보가 없습니다.");
            myProps.close_write();
          }
        } else {

        }
      });
  };

  const go_write_data = () => {
    if (strFunc.is_empty(info["a_stu_seq"])) {
      alert("학생 선택이 없습니다.");
      return false;
    }

    let confirm_msg = "등록 하시겠습니까?";
    if (is_update) {
      confirm_msg = "수정 하시겠습니까?";
    }
    if (!confirm(confirm_msg)) {
      return false;
    }

    let write_data_row = info;
    if (is_update == false) {
      write_data_row["a_ymd"] = strFunc.str_replace("-", "", write_data_row["a_counsel_date"]);
    }
    let write_form_json: any = {
      "is_update": "",
      "data_arr": [write_data_row],
    };
    if (is_update) {
      write_form_json["is_update"] = "1";
    } else {
      write_form_json["is_default_val"] = "1";
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        write_form_json["api_key"]=select_mcomp_data.api_key;
        write_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_career/write', write_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장되었습니다.");
          let last_info = response.data["data"][0];
          get_info_by_ajax(last_info["a_ymd"] + "," + last_info["a_seq"]);
          myProps.list({ select_seq: last_info["a_stu_seq"] });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_delete_data = () => {
    if (strFunc.is_empty(info["a_stu_seq"])) {
      alert("학생선택 키가 없습니다.");
      return false;
    }
    if (strFunc.is_empty(info["a_ymd"])||strFunc.is_empty(info["a_seq"])) {
      alert("키정보가 없습니다.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let del_form_json:any = {
      data_arr: [info],
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        del_form_json["api_key"]=select_mcomp_data.api_key;
        del_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_career/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제되었습니다.");
          myProps.list({ select_seq: info["a_stu_seq"] });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const onClickPrint = () => {
    if (printRef.current) {
      handlePrint();
    }
  };

  return (
    <div>
      <div className="text-center mb-1">
        {myProps.is_view_pre_btn&&
          <button className="btn-m btn-gray" onClick={() => { myProps.close_write(); }}>뒤로</button>
        }
        {is_view_mode == false &&
          <button className="btn-m btn-yellowish2 ml-1" onClick={() => { go_write_data(); }}>저장</button>
        }
        <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
          {is_view_mode ? "수정" : "보기"}모드
        </button>
        <button className="btn-m btn-gray ml-1" onClick={() => { onClickPrint(); }}>프린트</button>
      </div>
      <EtcInfoArea
        Style={Style}
        info={info}
        set_info={set_info}
        is_view_mode={is_view_mode}
      ></EtcInfoArea>
      <div className="a4page" ref={printRef} style={print_page_style}>
        <div className="a4subpage">
          <div className={Style.write_wrap}>
            <TopStuInfoArea
              Style={Style}
              info={info}
              set_info={set_info}
              is_view_mode={is_view_mode}
              user={user}
            ></TopStuInfoArea>
            <ContentArea1
              Style={Style}
              info={info}
              set_info={set_info}
              is_view_mode={is_view_mode}
            ></ContentArea1>
            <ContentArea2
              Style={Style}
              info={info}
              set_info={set_info}
              is_view_mode={is_view_mode}
            ></ContentArea2>
            <ContentArea3
              Style={Style}
              info={info}
              set_info={set_info}
              is_view_mode={is_view_mode}
            ></ContentArea3>
            <ContentArea4
              Style={Style}
              info={info}
              set_info={set_info}
              is_view_mode={is_view_mode}
            ></ContentArea4>
          </div>
        </div>
      </div>
      <div className="text-center mb-1">
        {is_view_mode == false &&
          <span>
            <button className="btn-m btn-yellowish2 ml-1" onClick={() => { go_write_data(); }}>저장</button>
            {is_update &&
              <button className="btn-m btn-red ml-1" onClick={() => { go_delete_data(); }}>삭제</button>
            }
          </span>
        }
        <button className="btn-m btn-gray ml-1" onClick={() => { onClickPrint(); }}>프린트</button>
        <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
          {is_view_mode ? "수정" : "보기"}모드
        </button>
      </div>
      <div className="mt-2">
        <ListHistoryArea
          info={info}
          set_info={set_info}
          info_arr={info_arr}
        ></ListHistoryArea>
      </div>
    </div>
  );
});
export default WriteArea;