import { useState, useRef, useEffect } from 'react';

const TabArea = (props:any) => {
  let myProps={
    tab_id:"",
    set_tab_id:(inData:any)=>{},
    margin_top:18,
    ...props
  };

  let tab_arr=[
    {id:"",text:"전체",width:120},
    {id:"class",text:"클래스별",width:120},
    {id:"time",text:"시간별",width:120},
  ];

  let active_pre_idx=-1;
  let active_after_idx=-1;
  let active_idx=-1;
  for(let i=0;i<tab_arr.length;i++){
    if(tab_arr[i].id==myProps.tab_id){
      active_idx=i;
      active_pre_idx=active_idx-1;
      active_after_idx=active_idx+1;
    }
  }

  let tab_tags=tab_arr.map((item:any,idx:number)=>{
    let item_class_name="tab_item";
    if(idx==0){
      item_class_name+=" first_tab_item";
    }
    if(myProps.tab_id==item.id){
      item_class_name+=" active";
    }
    let item_style:any={};
    if(idx==active_pre_idx){
      item_style["borderRight"]=0;
    }
    if(idx==active_after_idx){
      item_style["borderLeft"]=0;
    }
    return (
      <td key={idx} >
        <div className={item_class_name} onClick={()=>{myProps.set_tab_id(item.id);}} style={item_style}>
          {item.text}
        </div>
      </td>
    );
  });

  return (
    <div className="basic_tab_wrap2" style={{marginTop:myProps.margin_top}}>
      <table>
        <colgroup>
          {tab_arr.map((item:any,idx:number)=>{
            return (
              <col key={idx} width={item.width}></col>
            );
          })}
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            {tab_tags}
            <td>
              <div className="tab_item_empty">
                
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TabArea;