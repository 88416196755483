import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"char_gold_history",
    list_id:"stu_gold_char_gold_history_pop",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"100","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_date","name":"작성일","width":"110","is_show":"1"},
      {"key":"a_amount","name":"양분","width":"110","is_show":"1"},
      {"key":"a_msg","name":"메세지","width":"200","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"200","is_show":"1"},
      {"key":"a_create_date","name":"작성시간","width":"160","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;