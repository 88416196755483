import { useState,forwardRef,useImperativeHandle } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';

const SearchArea = forwardRef((props:any, ref) => {
  let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    goDelete:(inData:any)=>{},
    openSendPushPopup:(inData:any)=>{},
    ...props,
  };
  const [listOpt,setListOpt] = useState(props.listOpt);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({[name]: value});
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{[key]: date_str}
    });
    props.list({[key]: date_str});
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  return (
    <div className="search-box relative">
      <div className="btn-box-left relative h-8">
        <select className="search-input_select" value={listOpt.s_is_receive} 
          onChange={(e:any)=>{
            handleInputChange(e);
          }} >
          <option value="">전체</option>
          <option value="empty">보냄</option>
          <option value="1">받음</option>
        </select>
        <DatePicker 
          wrapperClassName="w-28 pl-1"
          selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_start_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
        ~
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_end_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
        
        <button className="btn btn-dark" onClick={()=>{myProps.openSendPushPopup();}}>보내기</button>
        <button className="btn btn-dark" onClick={myProps.goDelete}>삭제</button>
      </div>
    </div>
  );
});

export default SearchArea;