import Style from "../../css/style.module.css";
import video_ico_img from "@/img/ico/video_ico.png";
import clock_ico_img from "@/img/ico/clock_ico.png";
import more_ico_img from "@/img/ico/more_ico.png";
import noimg_img from "@/img/ico/noimg.png";
import strFunc from '@/lib/lyg/string';
import { useEffect,useState,useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function TodoRowTag(props:any){
  let myProps:any={
    row_data:{
      "a_ymd":"",
      "a_seq":"",
      "a_is_mcomp_public":"1",
      "a_par_mcomp_seq":"",
      "a_par_id":"",
      "a_par_seq":"",
      "a_writer_mcomp_seq":"",
      "a_writer_mcomp_user_seq":"",
      "a_writer_seq":"",
      "a_writer_name":"",
      "a_stu_mcomp_seq":"",
      "a_stu_mcomp_user_seq":"",
      "a_stu_seq":"",
      "a_stu_name":"",
      "a_start_date":"",
      "a_end_date":"",
      "a_is_reserve":"",
      "a_reserve_start_date":"",
      "a_reserve_end_date":"",
      "a_success_sort":"",
      "a_amount_sort":"amount",
      "a_amount_num":0,
      "a_success_amount":0,
      "a_success_time_sec":3600,
      "a_time_sec":0,
      "a_is_success":"",
      "a_state":"ready",
      "a_subject":"",
      "a_title":"",
      "a_content":"",
      "a_result_memo":"",
      "a_is_need_photo":"",
      "file_arr":[],
    },
    onClickRow:()=>{},
    onClickMore:()=>{},
    onClickDeleteRow:()=>{},
    onClickModify:()=>{},
    onClickComplete:()=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);

  const [isBottomeShow,setIsBottomeShow]=useState(false);
  let row_data=myProps.row_data;

  let a_time_sec=parseInt(strFunc.uncomma(row_data.a_time_sec));
  if(row_data.a_state=="ing"&&row_data.a_start_date_last){
    //진행시간 업데이트
    let last_date_sec=(new Date(row_data.a_start_date_last).getTime())/1000;
    let now_date_sec=(new Date().getTime())/1000;
    let plus_sec=now_date_sec-last_date_sec;
    if(localStorage.server_time_gap){
      plus_sec-=parseInt(localStorage.server_time_gap);
    }
    a_time_sec=a_time_sec+plus_sec;
    a_time_sec=Math.round(a_time_sec);
  }

  let per_span_style:any={};
  let per_str:any=0;
  if(row_data.a_success_sort=="time"){
    row_data.a_success_time_sec=parseInt(strFunc.uncomma(row_data.a_success_time_sec));
    a_time_sec=parseInt(strFunc.uncomma(a_time_sec));
    if(row_data.a_success_time_sec!=0&&a_time_sec!=0){
      per_str=(a_time_sec/row_data.a_success_time_sec)*100;
    }
  }else{
    let a_success_amount=parseInt(strFunc.uncomma(row_data.a_success_amount));
    let a_amount_num=parseInt(strFunc.uncomma(row_data.a_amount_num));
    if(a_success_amount!=0&&a_amount_num!=0){
      per_str=(a_amount_num/a_success_amount)*100;
    }
  }
  if(row_data.a_is_success=="1"){
    per_str=100;
  }
  per_str=Math.round(parseFloat(strFunc.uncomma(per_str)));
  if(per_str>0){
    per_span_style["color"]="yellow";
  }
  if(per_str>50){
    per_span_style["color"]="#00aaff";
  }
  if(per_str>80){
    per_span_style["color"]="#ff6100";
  }
  if(per_str>=100){
    per_span_style["color"]="#00ff39";
  }

  let left_ico_img=clock_ico_img;
  if(row_data.a_par_id=="video_list"){
    left_ico_img=video_ico_img;
  }

  let time_sec_json:any=strFunc.secondsToTimeJson(strFunc.uncomma(a_time_sec));

  const getFileItemTags=()=>{
    if(myProps.row_data.file_arr){

    }else{
      return "";
    }
    let file_item_tags=myProps.row_data.file_arr.map((item:any,idx:number)=>{
      let file_img_src=noimg_img;
      if(item.is_image){
        file_img_src=item.thum_img_url;
      }
      return (
        <div className={Style.todo_row_file_item} key={idx}>
          <img src={file_img_src} style={{width:"100%"}} />
        </div>
      );
    });
    return file_item_tags;
  };

  return (
    <div className={Style.todo_row}>
      <div className={Style.todo_row_top} >
        <div className={Style.todo_row_top_left_box} onClick={()=>{myProps.onClickRow(row_data);}} >
          <div className={Style.todo_row_top_left_img_wrap}>
            <img src={left_ico_img} style={{height:"80%"}}></img>
          </div>
        </div>
        <div className={Style.todo_row_top_center_box} onClick={()=>{myProps.onClickRow(row_data);}} >
          <div className={Style.todo_row_top_center_box_title}>
            {myProps.row_data.a_title}
          </div>
          <div>
            {myProps.row_data.a_subject}
          </div>
        </div>
        <div className={Style.todo_row_top_right_time_wrap} onClick={()=>{myProps.onClickRow(row_data);}} >
          <div style={per_span_style}>
            {per_str} %
          </div>
          <div>
            {time_sec_json.h!="00"&&
            <span>
              {parseInt(strFunc.uncomma(time_sec_json.h))}:
            </span>
            }
            {time_sec_json.i}:{time_sec_json.s}
          </div>
        </div>
        <div className={Style.todo_row_top_right_more_wrap} onClick={()=>{myProps.onClickMore(row_data);}}>
          <img src={more_ico_img} style={{height:"30%",marginTop:15}}/>
        </div>
      </div>
      <div className={Style.todo_row_slide_btn} onClick={()=>{setIsBottomeShow(!isBottomeShow);}}>
        {isBottomeShow?"▲":"▼"}
      </div>
      {isBottomeShow&&
      <div className={Style.todo_row_slid_box}>
        {myProps.row_data.file_arr&&
          <div className="p-2">
            <div>결과사진</div>
            {getFileItemTags()}
          </div>
        }
        <div className="p-2 text-center">
          <button className="btn btn-dark" onClick={()=>{myProps.onClickModify(row_data);}}>수정</button>
          <button className="btn btn-dark ml-1" onClick={()=>{myProps.onClickComplete(row_data);}} >완료</button>
          <button className="btn btn-red ml-1" onClick={()=>{myProps.onClickDeleteRow(row_data);}} >삭제</button>
        </div>
      </div>
      }
    </div>
  );
};

export default TodoRowTag;