import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
/*
"a_seq":1,
"a_sort":"homework",//homework 숙제, study 학습
"a_title":"",
"a_success_sort":"amount",//amount,time
"a_par_id":"",
"a_par_seq":"",
"a_success_amount":"0",
"a_success_time_sec":"0",
"a_amount_sort":"개",
*/
class MakeTodoWriteFunc{
  static todo_write_default={
    "a_ymd":"",
    "a_seq":"",
    "a_is_mcomp_public":"",
    "a_sort":"homework",
    "a_par_id":"",
    "a_par_seq":"",
    "a_is_reserve":"",
    "a_reserve_start_date":"",
    "a_reserve_end_date":"",
    "a_success_sort":"",
    "a_amount_sort":"",
    "a_success_amount":"",
    "a_success_time_sec":"",
    "a_subject":"",
    "a_title":"",
    "a_content":"",
    "a_is_need_photo":"",
  }
  static get_make_todo_write(inData:any){
    let opt_obj={
      todo_content_arr:[],
      make_option:{
        "is_mcomp_publiic":"1",
        "is_reserve":"1",//예정여부
        "reserve_start_date":"",
        "reserve_end_date":"",
        "one_day_amount":1,
        "one_day_amount_time":1,
        "one_day_amount_over_per":100,
        "apply_day":"1,2,3,4,5",
        "is_need_photo":"",
  
        "start_h":"00",
        "start_m":"00",
        "end_h":"01",
        "end_m":"00",
      },
      ...inData
    };
    let todo_content_arr=opt_obj["todo_content_arr"];
    let make_option=opt_obj["make_option"];
    this.todo_write_default["a_is_mcomp_public"]=make_option["is_mcomp_publiic"];
    this.todo_write_default["a_is_reserve"]=make_option["is_reserve"];
    
    //시작날짜부터 하루분량으로 나누어서 등록
    let start_date=make_option["reserve_start_date"];
    let ing_date=start_date;//진행중인날짜
    let apply_day_arr=make_option["apply_day"].split(",");
    let one_day_amount=parseInt(strFunc.uncomma(make_option["one_day_amount"]));
    let one_day_amount_time=parseInt(strFunc.uncomma(make_option["one_day_amount_time"]));
    one_day_amount_time=parseInt(strFunc.uncomma(one_day_amount_time));

    //날짜 잘못된것 제거
    apply_day_arr=this.check_data_apply_day_arr(apply_day_arr);
    
    let todo_write_arr:any=[];
    if(one_day_amount==0&&one_day_amount_time==0){
      return todo_write_arr;
    }
    let day_use_sum_num=0;
    for(let todo_i=0;todo_i<todo_content_arr.length;todo_i++){
      let todo_content=todo_content_arr[todo_i];
      //데이터세팅
      if(todo_content["a_success_sort"]=="time"){
        todo_content["a_success_time_sec"]=parseInt(strFunc.uncomma(todo_content["row_view_success_amount"]));
      }else{
        todo_content["a_success_amount"]=todo_content["row_view_success_amount"];
      }

      //분량을 나누어 몇일 더할지 계산
      let todo_amount=todo_content["a_success_amount"];
      let todo_amount_time=todo_content["a_success_time_sec"];
      if(todo_content["a_success_sort"]=="time"){
        if(todo_amount_time==0){
          continue;
        }
      }else{
        if(todo_amount==0){
          continue;
        }
      }

      let todo_write_arr_rs=this.get_todo_write_arr_by_todo({
        "ing_date":ing_date,
        "apply_day_arr":apply_day_arr,
        "todo_content":todo_content,
        "make_option":make_option,
        "one_day_amount":one_day_amount,
        "one_day_amount_time":one_day_amount_time,
        "day_use_sum_num":day_use_sum_num,
      });
      day_use_sum_num=todo_write_arr_rs["day_use_sum_num"];
      
      ing_date=todo_write_arr_rs["ing_date"];
      todo_write_arr=[
        ...todo_write_arr,
        ...todo_write_arr_rs["todo_write_arr"],
      ];
    }
  
    return todo_write_arr;
  }
  static get_todo_write_arr_by_todo(inData:any){
    let this_obj=this;
    let opt_obj={
      "ing_date":"",
      "apply_day_arr":[],
      "todo_content":{},
      "make_option":{},
      "one_day_amount":0,
      "one_day_amount_time":0,
      "day_use_sum_num":0,
      ...inData
    };

    let todo_write_arr:any=[];
    let ing_date=opt_obj["ing_date"];
    let apply_day_arr=opt_obj["apply_day_arr"];
    let todo_content=opt_obj["todo_content"];
    todo_content["a_success_amount"]=parseInt(strFunc.uncomma(todo_content["a_success_amount"]));
    todo_content["a_success_time_sec"]=parseInt(strFunc.uncomma(todo_content["a_success_time_sec"]));
    let make_option=opt_obj["make_option"];
    let one_day_amount_over_per=parseInt(strFunc.uncomma(make_option["one_day_amount_over_per"]));
    let content_max_num=0;
    let one_day_max_num=0;
    let content_num=0;
    if(todo_content["a_success_sort"]=="amount"){
      content_max_num=opt_obj["todo_content"]["a_success_amount"];
      one_day_max_num=opt_obj["one_day_amount"];
    }else{
      content_max_num=opt_obj["todo_content"]["a_success_time_sec"];
      one_day_max_num=opt_obj["one_day_amount_time"];
    }
    let day_use_sum_num=opt_obj["day_use_sum_num"];

    let result_data_arr:any={
      todo_write_arr:todo_write_arr,
      ing_date:ing_date,
      day_use_sum_num:day_use_sum_num
    };
    console.log(apply_day_arr);

    //요일 없으면 끝
    if(apply_day_arr.length==0){
      return result_data_arr;
    }
    //데이터 없으면 끝
    if(content_max_num==0){
      return result_data_arr;
    }
    //분량 없으면 끝
    if(one_day_max_num==0){
      return result_data_arr;
    }

    let loop_idx=0;
    //필요일 만큼 반복
    while(content_num<content_max_num){
      //무한반복 방지
      loop_idx++;
      if(loop_idx>10000){
        return result_data_arr;
      }
      let ing_date_json=DateFunc.get_date_json(new Date(ing_date));
      if(strFunc.str_in_array(ing_date_json.day,apply_day_arr)==-1){
        //유효날이 아니면 날짜 넘기기
        ing_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(ing_date),'day',+1),"Y-m-d");
        continue;
      }

      //등록
      let todo_write_start_date=ing_date;
      let todo_write_end_date=ing_date;

      todo_write_start_date=todo_write_start_date+" "+make_option["start_h"]+":"+make_option["start_m"]+":00";
      todo_write_end_date=todo_write_end_date+" "+make_option["end_h"]+":"+make_option["end_m"]+":00";

      let success_amount=todo_content["a_success_amount"];
      let success_time_sec=todo_content["a_success_time_sec"];
      let use_amount=0;
      //할일남은량
      let content_remain_num=content_max_num-content_num;
      //하루최대량
      let row_one_day_max_num=one_day_max_num-day_use_sum_num;

      //남은량>하루한계치
      if(content_remain_num>=row_one_day_max_num){
        use_amount=row_one_day_max_num;
      }else{
        //남은량<하루한계치
        use_amount=content_remain_num;
      }

      if(todo_content["a_success_sort"]=="amount"){
        success_amount=use_amount;
      }else if(todo_content["a_success_sort"]=="time"){
        success_time_sec=use_amount;
      }

      let add_todo_write_row={
        ...this_obj.todo_write_default,
        "a_ymd":strFunc.str_replace("-","",todo_write_start_date),
        "a_is_mcomp_public":make_option["is_mcomp_publiic"],
        "a_sort":todo_content["a_sort"],
        "a_par_id":todo_content["a_par_id"],
        "a_par_seq":todo_content["a_par_seq"],
        "a_is_reserve":make_option["is_reserve"],
        "a_reserve_start_date":todo_write_start_date,
        "a_reserve_end_date":todo_write_end_date,
        "a_success_sort":todo_content["a_success_sort"],
        "a_amount_sort":todo_content["a_amount_sort"],
        "a_success_amount":success_amount,
        "a_success_time_sec":success_time_sec,
        "a_subject":todo_content["a_subject"],
        "a_title":todo_content["a_title"],
        "a_content":todo_content["a_content"],
        "a_is_need_photo":make_option["is_need_photo"],
      };
      result_data_arr.todo_write_arr.push(add_todo_write_row);

      content_num+=use_amount;
      day_use_sum_num+=use_amount;
      
      let is_go_next_day=false;
      if(day_use_sum_num>=one_day_max_num){
        is_go_next_day=true;
      }else{
        //어느정도차면 다음날로 넘기자
        if(content_max_num>=10&&one_day_amount_over_per<100){
          if(day_use_sum_num!=0&&one_day_max_num!=0){
            let row_one_day_percent=(day_use_sum_num/one_day_max_num)*100;
            if(row_one_day_percent>one_day_amount_over_per){
              is_go_next_day=true;
            }
          }
        }
      }
      if(is_go_next_day){
        day_use_sum_num=0;
        ing_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(ing_date),'day',+1),"Y-m-d");
      }
    }

    result_data_arr["ing_date"]=ing_date;
    result_data_arr["day_use_sum_num"]=day_use_sum_num;
    return result_data_arr;
  }
  static get_one_day_amount_by_period=(inData:any)=>{
    let opt_obj={
      start_date:"",
      end_date:"",
      todo_content_arr:[],
      apply_day_arr:[],//[0,1,2]
      one_day_amount:0,
      ...inData
    };
    
    let start_date=opt_obj.start_date;
    let end_date=opt_obj.end_date;
    if(strFunc.is_empty(start_date)){
      start_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    if(strFunc.is_empty(end_date)){
      end_date=start_date;
    }
    if(strFunc.is_empty(opt_obj.todo_content_arr)){
      return opt_obj.one_day_amount;
    }
    if(strFunc.is_empty(opt_obj.todo_content_arr)){
      return opt_obj.one_day_amount;
    }

    let todo_content_arr:any=opt_obj.todo_content_arr;
    let diff_days=DateFunc.get_diff_days_of_date(new Date(start_date),new Date(end_date));
    diff_days=diff_days+1;
    
    let useable_days=0;
    let start_date_json=DateFunc.get_date_json(new Date(start_date));
    let start_day_of_week=start_date_json.day;
    let ing_day_of_week=start_day_of_week;
    let apply_day_arr=opt_obj.apply_day_arr;
    for(let i=0;i<diff_days;i++){
      if(strFunc.str_in_array(ing_day_of_week,apply_day_arr)!=-1){
        useable_days++;
      }
      ing_day_of_week++;
      if(ing_day_of_week>=7){
        ing_day_of_week=0;
      }
    }

    //분량얻기
    let todo_amount=0;
    let todo_amount_time=0;
    for(let i=0;i<todo_content_arr.length;i++){
      let todo_content=todo_content_arr[i];
      if(todo_content.a_success_sort=="amount"){
        todo_amount+=parseInt(strFunc.uncomma(todo_content.row_view_success_amount));
      }else{
        todo_amount_time+=parseInt(strFunc.uncomma(todo_content.row_view_success_amount));
      }
    }
    
    let one_day_amount=opt_obj.one_day_amount;
    if(useable_days!=0&&todo_amount!=0){
      one_day_amount=Math.ceil(todo_amount/useable_days);
    }

    return one_day_amount;
  };
  static check_data_apply_day_arr=(apply_day_arr:[])=>{
    let tmp_apply_day_arr:any=[];
    let tmp_day_num_sort_arr=["0","1","2","3","4","5","6"];
    for(let i=0;i<apply_day_arr.length;i++){
      if(apply_day_arr[i]==""){

      }else{
        if(strFunc.str_in_array(apply_day_arr[i],tmp_day_num_sort_arr)!=-1){
          tmp_apply_day_arr.push(apply_day_arr[i]);
        }
      }
    }
    apply_day_arr=tmp_apply_day_arr;

    return apply_day_arr;
  };
};
export default MakeTodoWriteFunc;