import strFunc from "@/lib/lyg/string";

function DailyInputText(props: any) {
  let myProps = {
    daily_info: {},
    name: "",
    onChange: (inData: any) => { },
    onClick: (inData: any) => { },
    info: {},
    is_print_mode: false,
    ...props
  };
  let name = myProps.name;
  let value = myProps.daily_info[name] ? myProps.daily_info[name] : "";

  //선택
  let select_input_render: any = "";
  if (name == "a_textbook_study" || name == "a_textbook_study_det") {
    let study_title_info: any = null;
    if (myProps.info["daily_study_title_arr"] && myProps.info["daily_study_title_arr"].length > 0) {
      for (let i = 0; i < myProps.info["daily_study_title_arr"].length; i++) {
        if (myProps.info["daily_study_title_arr"][i]["a_seq"] == myProps.daily_info["a_textbook_seq"]) {
          study_title_info = myProps.info["daily_study_title_arr"][i];
        }
      }
    }
    let select_tag_of_num_style:any={
      width: "16px", position: "absolute", left: -1, top: -1, textAlign: "center",paddingLeft:3,
      border:"1px solid #555"
    };
    if(myProps.is_print_mode){
      select_tag_of_num_style["border"]="1px solid #ddd";
    }
    if (study_title_info != null) {
      if (name == "a_textbook_study") {
        let select_title_str = "";
        for (let i = 0; i < study_title_info.section_arr.length; i++) {
          let idx_num = i + 1;
          select_title_str += idx_num + ". " + study_title_info.section_arr[i]["a_title"] + "\n";
        }

        select_input_render = (
          <select
            className="select_none_arrow"
            value={myProps.daily_info["a_textbook_study_num"]}
            onChange={(e: any) => {
              let change_row_data: any = {};
              let change_val = parseInt(strFunc.uncomma(e.target.value));
              change_val--;
              if (change_val >= 0 && change_val < study_title_info.section_arr.length) {
                change_row_data["a_textbook_study"] = study_title_info.section_arr[change_val]["a_title"];
              }

              myProps.onChange({
                name: "a_textbook_study_num",
                value: e.target.value,
                change_row_data: change_row_data
              });
            }}
            title={select_title_str}
            style={select_tag_of_num_style}
          >
            <option value=""></option>
            {study_title_info.section_arr &&
              study_title_info.section_arr.map((item: any, idx: number) => {
                let tmp_option_idx=idx+1;
                let tmp_option_text=" "+tmp_option_idx+" "+item["a_title"];
                return (<option key={idx} value={tmp_option_idx}>{tmp_option_text}</option>)
              })
            }
          </select>
        );
      } else if (name == "a_textbook_study_det") {
        let row_study_arr: any = [];
        let row_select_section = null;
        let a_textbook_study_num = parseInt(strFunc.uncomma(myProps.daily_info["a_textbook_study_num"]));
        a_textbook_study_num--;
        if (a_textbook_study_num >= 0 && a_textbook_study_num < study_title_info.section_arr.length) {
          row_select_section = study_title_info.section_arr[a_textbook_study_num];
          for (let i = 0; i < study_title_info.study_arr.length; i++) {
            if (study_title_info.study_arr[i]["a_section_seq"] == row_select_section["a_seq"]) {
              row_study_arr.push(study_title_info.study_arr[i]);
            }
          }
        }

        let select_title_str = "";
        for (let i = 0; i < row_study_arr.length; i++) {
          let idx_num = i + 1;
          select_title_str += idx_num + ") " + row_study_arr[i]["a_title"] + "\n";
        }

        select_input_render = (
          <select
            className="select_none_arrow"
            value={myProps.daily_info["a_textbook_study_det_num"]}
            onChange={(e: any) => {
              let change_row_data: any = {};
              let change_val = parseInt(strFunc.uncomma(e.target.value));
              change_val--;
              if (change_val >= 0 && change_val < row_study_arr.length) {
                change_row_data["a_textbook_study_det"] = row_study_arr[change_val]["a_title"];
              }

              myProps.onChange({
                name: "a_textbook_study_det_num",
                value: e.target.value,
                change_row_data: change_row_data
              });
            }}
            title={select_title_str}
            style={select_tag_of_num_style} >
            <option value=""></option>
            {row_study_arr &&
              row_study_arr.map((item: any, idx: number) => {
                let tmp_option_idx=idx+1;
                let tmp_option_text=" "+tmp_option_idx+" "+item["a_title"];
                return (<option key={idx} value={tmp_option_idx}>{tmp_option_text}</option>)
              })
            }
          </select>
        );
      }
    }
  }

  let text_input_wrap_style: any = { position: "relative" };
  let text_input_style = { width: "100%", border: "0px", paddingLeft: "1px" };
  if (!strFunc.is_empty(select_input_render)) {
    text_input_wrap_style["paddingLeft"] = "16px";
  }

  let remove_btn_render:any = "";

  return (
    <td>
      <div style={text_input_wrap_style}>
        <input type="text"
          value={value}
          name={name}
          onChange={(e: any) => {
            myProps.onChange({
              name: e.target.name,
              value: e.target.value
            });
          }}
          onClick={(e: any) => {
            myProps.onClick(e);
          }}
          title={value}
          style={text_input_style}
          autoComplete="off"
        />
        {select_input_render}
        {remove_btn_render}
      </div>
    </td>
  );
};
export default DailyInputText;