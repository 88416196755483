import { Link } from 'react-router-dom';
import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';
import stu_getXcolumnJson from "@/pages/comp/basic/student/list/xcolumn/list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import SupplyCardPosPopup from "./popup/supply_card_pos";
import SendCashReceiptPopup from "./popup/send_cash_receipt";

function SuppyMain(){
  const xColumnArr=getXcolumnJson();
  const stuxColumnArr=stu_getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_date_type:'a_date',
      s_start_date:DateFunc.get_date_format(new Date(),"Y-m-01"),
      s_end_date:DateFunc.get_date_format(new Date(),"Y-m-t"),
      s_money_sum:"1",
      s_state:"",
      s_sort:"",
      s_addon_user:"1",
      s_addon_pos_card:"1",
      s_addon_pos_receipt:"1",
      s_addon_trade_simple:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState<any>({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"supply_card",//cash_receipt
    title:"팝업",
    row_data:null,
    is_cancel:false,
    width:"600px",
    height:"60%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      //inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/class_supply/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/class_supply/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const saveSelectedRow=()=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("저장하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row,
      "is_update":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/trade/class_supply/write',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const openSupplyCardPopup=(is_cancel:boolean)=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length!=1){
      alert("한개만 선택해주세요.");
      return false;
    }
    let row_data=selected_row[0];
    let pop_title="카드결제";
    if(is_cancel){
      pop_title="카드결제(취소)";
    }

    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "row_data":row_data,
      "is_cancel":is_cancel,
      "sort":"supply_card"
    });
  };
  const openCashReceiptPopup=(is_cancel:boolean)=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length!=1){
      alert("한개만 선택해주세요.");
      return false;
    }
    let row_data=selected_row[0];
    let pop_title="현금영수증";
    if(is_cancel){
      pop_title="현금영수증(취소)";
    }

    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "row_data":row_data,
      "is_cancel":is_cancel,
      "sort":"cash_receipt"
    });
  };

  const onChangeRowInput=(params:any)=>{
    if(params==undefined){params={};}
    let optObj:any={
      idx:0,
      key:"",
      val:"",
    };
    for(let key in params){
      optObj[key]=params[key];
    }

    let money_key_arr=["a_money","a_discount_money","a_pay_money"];
    let sum_calculate_key_arr=["a_money","a_discount_money"];
    let tmp_info_arr:any=ListComponentRef.current.getRows();
    if(tmp_info_arr[optObj.idx]!=undefined){
      //값 변경하기
      let val_str=optObj.val;
      if(strFunc.str_in_array(optObj.key,money_key_arr)!=-1){
        val_str=strFunc.comma(val_str);
      }
      tmp_info_arr[optObj.idx][optObj.key]=val_str;

      //합계관련이면 합계 다시 계산
      if(strFunc.str_in_array(optObj.key,sum_calculate_key_arr)!=-1){
        let supply_money=parseInt(strFunc.uncomma(tmp_info_arr[optObj.idx]["a_money"]));
        let discount_money=parseInt(strFunc.uncomma(tmp_info_arr[optObj.idx]["a_discount_money"]));
        let sum_money=strFunc.comma(supply_money-discount_money);
        tmp_info_arr[optObj.idx]["a_money_sum"]=sum_money;
      }
    }
    setInfoArr(tmp_info_arr);
    ListComponentRef.current.setInfoArr([...tmp_info_arr]);
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      
    }
  }, []);

  const cellRenderer= (params:any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "origin_val":origin_val,
    }
    */
    let render_str=params.value;
    if(params.key=="row_view_stu_grade"){
      if(params.rowData.stu_info!=undefined){
        render_str=params.rowData.stu_info["a_stu_grade"];
        if(stuxColumnArr.select_arr["a_stu_grade"]!=undefined){
          for(let i=0;i<stuxColumnArr.select_arr["a_stu_grade"].length;i++){
            if(stuxColumnArr.select_arr["a_stu_grade"][i]["value"]==render_str){
              render_str=stuxColumnArr.select_arr["a_stu_grade"][i]["text"];
            }
          }
        }
      }
    }else if(params.key=="a_money"||params.key=="a_discount_money"
      ||params.key=="a_pay_money"||params.key=="a_memo"){
      
      let tmp_input_style:any={width:"98%",textAlign:"right"};
      if(params.key=="a_memo"){
        tmp_input_style["textAlign"]="left";
      }
      if(params.key=="a_pay_money"){
        tmp_input_style["width"]=80;
      }

      let tmp_val_str=params.origin_val;
      if(params.key=="a_money"||params.key=="a_discount_money"||params.key=="a_pay_money"){
        tmp_val_str=strFunc.comma(tmp_val_str);
      }

      render_str=(
        <input type="text" className="row-input" style={tmp_input_style} value={tmp_val_str}
          onChange={(e:any)=>{
            onChangeRowInput({
              idx:params.idx,
              key:params.key,
              val:e.target.value,
            });
          }} />
      );
      if(params.key=="a_pay_money"){
        render_str=<>
          {render_str}
          <button className="btn-s btn-gray ml-1" onClick={()=>{
            onChangeRowInput({
              idx:params.idx,
              key:params.key,
              val:params.rowData.a_money_sum,
            });
          }}>완납</button>
        </>
      }
    }else if(params.key=="row_view_unpaid"){
      let sum_money=parseInt(strFunc.uncomma(params.rowData.a_money_sum));
      let pay_money=parseInt(strFunc.uncomma(params.rowData.a_pay_money));
      render_str=strFunc.comma(sum_money-pay_money);
    }else if(params.key=="a_pay_sort"){
      let tmp_input_style:any={width:80,textAlign:"left"};
      render_str=(
        <select style={tmp_input_style} value={params.origin_val}
          onChange={(e:any)=>{
            onChangeRowInput({
              idx:params.idx,
              key:params.key,
              val:e.target.value,
            });
          }} >
            <option value="">선택없음</option>
            <option value="money">현금</option>
            <option value="card">카드</option>
          </select>
      );
    }else if(params.key=="a_sort"){
      let tmp_input_style:any={width:80,textAlign:"left"};
      render_str=(
        <select style={tmp_input_style} value={params.origin_val}
          onChange={(e:any)=>{
            onChangeRowInput({
              idx:params.idx,
              key:params.key,
              val:e.target.value,
            });
          }} >
            <option value="">선택없음</option>
            <option value="class">수업</option>
            <option value="etc">기타</option>
          </select>
      );
    }else if(params.key=="a_money_sum"){
      render_str=strFunc.comma(render_str);
    }else if(params.key=="row_view_pay_btn"){
      if(params.rowData.a_pay_sort=="card"){
        if(strFunc.is_empty(params.rowData.a_pay_money)){
          render_str=(
            <div className="text-center">
              <button className="btn-s btn-dark" onClick={()=>{setTimeout(()=>{openSupplyCardPopup(false);},100);}} >결제하기(pos)</button>
            </div>
          );
        }else{
          if(params.rowData.pos_card_arr&&params.rowData.pos_card_arr.length>0){
            if(params.rowData.pos_card_arr[0]["a_state"]=="complete"){
              render_str=(
                <div className="text-center">
                  <button className="btn-s btn-red" onClick={()=>{setTimeout(()=>{openSupplyCardPopup(true);},100);}} >결제취소(pos)</button>
                </div>
              );
            }
          }
        }
      }else if(params.rowData.a_pay_sort=="money"){
        if(!strFunc.is_empty(params.rowData.a_pay_money)){
          let is_exist_receipt=false;
          if(params.rowData.pos_cash_receipt_arr&&params.rowData.pos_cash_receipt_arr.length>0){
            if(params.rowData.pos_cash_receipt_arr[0]["a_state"]=="complete"){
              is_exist_receipt=true;
              render_str=(
                <div className="text-center">
                  <button className="btn-s btn-red" onClick={()=>{setTimeout(()=>{openCashReceiptPopup(true);},100);}} >현금영수증취소(pos)</button>
                </div>
              );
            }
          }
          if(is_exist_receipt==false){
            render_str=(
              <div className="text-center">
                <button className="btn-s btn-dark" onClick={()=>{setTimeout(()=>{openCashReceiptPopup(false);},100);}} >현금영수증발급(pos)</button>
              </div>
            );
          }
        }
      }
    }
    
    return render_str;
  };
  
  return (
    <CompLayout>
      <div className="con_wrap">
        <SearchArea
          listOpt={pageData.listOpt}
          saveSelectedRow={saveSelectedRow}
          goDelete={goDelete}
          list={list}
        ></SearchArea>
        <div className="text-right">
          <span>
            청구: <span style={{color:"#8f8a14"}}>{strFunc.comma(countInfo.a_money_sum)}</span>원
          </span>
          <span className="ml-4">
            입금: <span style={{color:"#00f"}}>{strFunc.comma(countInfo.a_pay_money)}</span>원
          </span>
          <span className="ml-4">
            미납금: <span style={{color:"#f00"}}>{strFunc.comma(countInfo.a_money_sum-countInfo.a_pay_money)}</span>원
          </span>
        </div>
        <div>
          <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={{...xColumnArr}}
          gridOpt={{
            is_idx_num:true,
            is_add_checkbox:true,
            td_align:"center",
            onGridReady:()=>{
              list({});
            },
            onCellClicked:cellClickedListener,
            customCellRenderer:cellRenderer
          }}
          ></ListComponent>
        </div>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="supply_card"&&
            <SupplyCardPosPopup 
              row_data={popupData.row_data}
              is_cancel={popupData.is_cancel}
              list={list}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></SupplyCardPosPopup>
          }
          {popupData.sort==="cash_receipt"&&
            <SendCashReceiptPopup 
              row_data={popupData.row_data}
              is_cancel={popupData.is_cancel}
              list={list}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></SendCashReceiptPopup>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default SuppyMain;