import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {
      "s_start_date": "",
      "s_end_date": "",
      "s_is_success":"",
    },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let select_subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  const on_change_search_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_list_opt: any = {};
    change_list_opt[name] = value;
    myProps.list({
      now_page: "1",
      ...change_list_opt
    });
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'month', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'month', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-01");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-t");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ now_page: 1, [key]: date_str });
  };

  return (
    <div className="text-center">
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
      <span className="ml-1" style={{fontSize:"16px",fontWeight:"bold"}}>
        {listOpt.s_start_date.substring(0,4)}년{" "}
        {listOpt.s_start_date.substring(5,7)}월
      </span>
      <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
      <button className="btn-s2 btn-dark ml-1" onClick={() => {
        myProps.list({
          "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
          "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
        });
      }}>
        이번달
      </button>
      {/* <span className="ml-1">
        처방:
        <select className="search-input" name="s_is_success" value={listOpt.s_is_success}
          onChange={on_change_search_input}>
          <option value="">전체</option>
          <option value="1">완료</option>
          <option value="empty">미완료</option>
        </select>
      </span>
      <span className="ml-1">
        과목:
        <select className="search-input" name="s_subject" value={listOpt.s_subject}
          onChange={on_change_search_input}>
          <option value="">전체</option>
          {select_subject_option_arr.map((item:any,idx:number)=>{
            return (
              <option key={idx} value={item.value}>{item.text}</option>
            );
          })}
        </select>
      </span> */}
    </div>
  );
};
export default SearchArea;