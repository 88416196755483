import search_ico from "@/img/ico/search3.png";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    xColumnArr: {},
    ...props,
  };
  let listOpt = props.listOpt;

  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  useEffect(() => {

    get_lecture_select_arr();
  }, []);

  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value: any = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    myProps.list({ now_page: 1, [name]: value });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ now_page: 1, [key]: date_str });
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };

  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        학생검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={listOpt.s_user_name_like} onChange={handleInputChange} placeholder="이름" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_school_name_like"
            value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={listOpt.s_stu_lecture_seq}
            on_change_input={(inData: any) => {
              let name = inData.name;
              let value = inData.value;
              myProps.list({ now_page: 1, [name]: value });
            }}
          ></SearchLectureSelect>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
            <option value="">학년</option>
            {
              props.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
            <option value="">전체</option>
            <option value="now,ready">대기,재원</option>
            {
              myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-yellowish2" onClick={() => { props.list({ now_page: 1 }); }} style={{ padding: "9px 10px" }} >검색</button>
        </div>
      </div>
      <div className="text-center mt-4">
        입학일
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
        <button className="btn-s2 btn-sky ml-2" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
            s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t")
          });
        }}>지난달</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
            s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t")
          });
        }}>이번달</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
            s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d")
          });
        }}>오늘</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            now_page: "1",
            s_start_date: "",
            s_end_date: "",
          });
        }}>전체</button>
      </div>
    </div>
  );
};

export default SearchArea;