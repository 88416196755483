//import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import { useRef, useEffect } from 'react';
function TextAreaComponent(props:any){
   let myProps={
    name:"",
    value:"",
    onChange:(e:any)=>{},
    class_name:"row-input",
    placeholder:"",
    style:{},
    height:26,
    autoComplete:"off",
    is_auto_height:true,
    ...props
  };
  let text_area_style:any={
    "width":"100%",
    "resize":"none",
    "lineHeight":"18px",
    padding:"1px",
    ...myProps.style,
    height:myProps.height,
  };

  const textarea_ref=useRef<any>(null);

  useEffect(()=>{
    set_auto_height_textarea();
  },[myProps.value]);

  const set_auto_height_textarea=()=>{
    if(myProps.is_auto_height!=true){
      return false;
    }
    if(textarea_ref.current){
      textarea_ref.current.style.height=myProps.height+"px";
      if(textarea_ref.current.scrollHeight>myProps.height){
        textarea_ref.current.style.height = (textarea_ref.current.scrollHeight + 2) + "px";
      }
    }
  };

  return (
  <textarea
    className={myProps.class_name}
    name={myProps.name}
    value={myProps.value}
    placeholder={myProps.placeholder}
    style={text_area_style}
    autoComplete={myProps.autoComplete}
    ref={textarea_ref}
    onChange={(e: any) => {
      myProps.onChange(e);
      set_auto_height_textarea();
    }}
  ></textarea>
  );
};
export default TextAreaComponent;