function TabBottomComponent(props:any){
  const myProps={
    tabList:[],//["a","b"]
    activeIdx:0,
    onChangeActive:(idx:number)=>{},
    ...props
  };

  const onChangeActive=(idx:number)=>{
    myProps.activeIdx=idx;
    myProps.onChangeActive(idx);
  };

  return (
    <ul className="tab_list_wrap">
      {
        myProps.tabList.map((item:any,idx:number)=>{
          let tab_class_name="tab_list_item_a";
          if(idx==myProps.activeIdx){
            tab_class_name+=" active";
          }
          return (
            <li className="tab_list_item" key={idx} >
              <a className={tab_class_name} 
              onClick={()=>{onChangeActive(idx);}}>{item}</a>
            </li>
          );
        })
      }
    </ul>
  );
};
export default TabBottomComponent;