import * as XLSX from "xlsx-js-style";
import style_data from "./style/index";
import strFunc from '@/lib/lyg/string';

const go_down_excel = (inData:any) => {
  let opt_obj={
    start_time: 16 * 60,
    end_time: 23 * 60,
    time_split_term: 30,//min
    split_height:70,
    lecture_arr: [],
    ...inData,
  };
  const wb = XLSX.utils.book_new();

  let data:any=[];

  //넓이
  var wscols = [
    { wpx: 100},
  ];
  //높이
  var wsrows=[{hpx: 30}];

  //헤더
  let header_row_data:any=["시간"];
  let lecture_arr_len=opt_obj.lecture_arr.length;
  let abc_arr=strFunc.get_abc_arr(lecture_arr_len+1);
  for(let i=0;i<lecture_arr_len;i++){
    let lecture_info=opt_obj.lecture_arr[i];
    wscols.push({ wpx: 200});
    header_row_data.push(
      lecture_info.a_name+"["+lecture_info.a_room_name+"]"+"("+lecture_info.a_main_teacher+")"
    );
  }
  data.push(header_row_data);

  //시간 + 데이터
  let split_time_arr=[];
  for(let ing_time=opt_obj.start_time;ing_time<opt_obj.end_time;ing_time){
    split_time_arr.push(ing_time);
    ing_time=ing_time+opt_obj.time_split_term;
  }
  let split_time_arr_len=split_time_arr.length;
  let merge_cell_arr:any=[];//{x:0,y:0,merge_num:2}
  for(let i=0;i<split_time_arr_len;i++){
    let split_time=split_time_arr[i];
    let rowt_start_json=strFunc.secondsToTimeJson(split_time*60);
    let rowt_end_json=strFunc.secondsToTimeJson((split_time+opt_obj.time_split_term)*60);
    let row_data=[
      {
        v:rowt_start_json["h"]+":"+rowt_start_json["i"]+"~"+rowt_end_json["h"]+":"+rowt_end_json["i"],
        t:"s",
        s:{ alignment: { vertical: "center",horizontal:"center" }},
      }
    ];
    let td_data_json_rs=get_td_data_arr({
      ...opt_obj,
      row_time_min:split_time,
    });
    let td_data_arr=td_data_json_rs.td_data_arr;

    data.push([
      ...row_data,
      ...td_data_arr
    ]);
    wsrows.push({hpx: opt_obj["split_height"]});

    let merge_num_arr=td_data_json_rs.merge_num_arr;
    for(let j=0;j<merge_num_arr.length;j++){
      if(merge_num_arr[j]>=2){
        merge_cell_arr.push({
          x:j+1,
          y:data.length-1,
          merge_num:merge_num_arr[j],
        });
      }
    }
  }


  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(data);//[row,row2]
  XLSX.utils.book_append_sheet(wb, ws, "time_table");

  //스타일
  let all_row_i=data.length;
  for(let row_i=0;row_i<all_row_i;row_i++){
    for(let i=0;i<abc_arr.length;i++){
      let cell_style:any={
        border:style_data.border_1,
        alignment: { 
          vertical: "center",
          horizontal:"center",
          wrapText: true 
        },
      };
      if(row_i==0||i==0){
        cell_style["font"]={
          "bold":true
        };
      }else{
        cell_style["fill"]={ fgColor: { rgb: "EFEFEF" } };
        for(let j=0;j<merge_cell_arr.length;j++){
          let merge_cell=merge_cell_arr[j];
          if(merge_cell.x==i&&merge_cell.y==row_i){
            cell_style["fill"]={ fgColor: { rgb: "FFFFFF" } };
          }
        }
      }
      ws[abc_arr[i]+(row_i+1)].s=cell_style;
    }
  }

  //넓이
  ws['!cols'] = wscols;
  //높이
  ws['!rows'] = wsrows;

  //합치기
  let merge_cell_data_arr=[];
  for(let i=0;i<merge_cell_arr.length;i++){
    let merge_cell=merge_cell_arr[i];
    if(merge_cell["merge_num"]>=2){
      let plus_row_num=merge_cell.merge_num-1;
      merge_cell_data_arr.push({
        s:{r:merge_cell.y,c:merge_cell.x},
        e:{r:merge_cell.y+plus_row_num,c:merge_cell.x},
      });
    }
  }
  if(merge_cell_data_arr.length>0){
    ws["!merges"] = merge_cell_data_arr;
  }

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, "time_table_excel.xlsx");
};

const get_td_data_arr=(inData:any)=>{
  let opt_obj={
    row_time_min:0,
    time_split_term: 30,//min
    lecture_arr: [],
    ...inData
  };

  let row_time_start_sec=opt_obj.row_time_min*60;
  let row_time_end_sec=(opt_obj.row_time_min+opt_obj.time_split_term)*60;
  let lecture_arr_len=opt_obj.lecture_arr.length;
  let td_data_arr:any=[];
  let merge_num_arr=[];

  for(let lecture_i=0;lecture_i<lecture_arr_len;lecture_i++){
    let lecture_info=opt_obj.lecture_arr[lecture_i];
    let content_str:any="";
    let merge_num=0;
    if(lecture_info.time_arr){
      let row_time_arr=[];
      for(let i=0;i<lecture_info.time_arr.length;i++){
        let time_info=lecture_info.time_arr[i];
        let tmp_start_time=strFunc.timeToSeconds(time_info["a_start_h"],time_info["a_start_m"],0);
        if(row_time_start_sec<=tmp_start_time&&tmp_start_time<row_time_end_sec){
          row_time_arr.push(time_info);
        }
      }
      if(row_time_arr.length!=0){
        let tmp_content_data=get_td_content_str({
          ...opt_obj,
          lecture_info:lecture_info,
          row_time_arr:row_time_arr,
        });
        content_str=tmp_content_data["content_str"];
        merge_num=tmp_content_data["merge_num"];
      }
    }

    td_data_arr.push(content_str);
    merge_num_arr.push(merge_num);
  }

  return {
    td_data_arr:td_data_arr,
    merge_num_arr:merge_num_arr
  };
};

const get_td_content_str=(inData:any)=>{
  let opt_obj={
    lecture_info:{},
    row_time_arr:[],
    time_split_term:30,
    split_height:70,
    ...inData
  };

  let content_str="";

  const get_merge_student_arr=(origin_arr:any[],add_arr:any[],a_day_num:any)=>{
    let tmp_student_arr:any=origin_arr;
    let add_etc_arr=[];
    let add_arr_len=add_arr.length;
    for(let i=0;i<add_arr_len;i++){
      let is_exist_data=false;
      let add_info:any=add_arr[i];
      for(let j=0;j<origin_arr.length;j++){
        let origin_info:any=origin_arr[j];
        
        if(add_info.a_user_seq==origin_info.a_user_seq){
          is_exist_data=true;
          if(strFunc.str_in_array(a_day_num,tmp_student_arr[j]["day_num_arr"])==-1){
            tmp_student_arr[j]["day_num_arr"].push(a_day_num);
          }
        }
      }
      if(is_exist_data==false){
        add_info.day_num_arr=[a_day_num];
        add_etc_arr.push(add_info);
      }
    }

    tmp_student_arr=[
      ...tmp_student_arr,
      ...add_etc_arr
    ];
  
    return tmp_student_arr;
  };

  let row_start_sec=strFunc.timeToSeconds(opt_obj.row_time_arr[0]["a_start_h"],opt_obj.row_time_arr[0]["a_start_m"],0);
  let row_end_sec=strFunc.timeToSeconds(opt_obj.row_time_arr[0]["a_end_h"],opt_obj.row_time_arr[0]["a_end_m"],0);
  let day_num_arr:any=[];
  let student_arr=[];
  for(let i=0;i<opt_obj.row_time_arr.length;i++){
    let time_info=opt_obj.row_time_arr[i];
    let tmp_start_time_sec=strFunc.timeToSeconds(time_info["a_start_h"],time_info["a_start_m"],0);
    let tmp_end_time_sec=strFunc.timeToSeconds(time_info["a_end_h"],time_info["a_end_m"],0);
    if(tmp_start_time_sec<row_start_sec){
      row_start_sec=tmp_start_time_sec;
    }
    if(tmp_end_time_sec>row_end_sec){
      row_end_sec=tmp_end_time_sec;
    }

    //요일등록
    if(strFunc.str_in_array(time_info["a_day_num"],day_num_arr)==-1){
      day_num_arr.push(time_info["a_day_num"]);
    }

    //유저정보
    if(time_info.a_stu_seq==undefined){
      student_arr=get_merge_student_arr(student_arr,opt_obj.lecture_info.student_arr_base,time_info["a_day_num"]);
    }else{
      student_arr=get_merge_student_arr(student_arr,[time_info.student_info],time_info["a_day_num"]);
    }
  }
  let row_diff_sec=row_end_sec-row_start_sec;
  let row_diff_min=row_diff_sec/60;
  let height_multi_num=Math.ceil(row_diff_min/opt_obj.time_split_term);
  let row_height=opt_obj.split_height*(height_multi_num-1);

  //요일정보
  let week_name_arr=["일","월","화","수","목","금","토"];
  let row_week_name_arr=[];
  day_num_arr.sort();
  for(let i=0;i<day_num_arr.length;i++){
    row_week_name_arr.push(
      week_name_arr[day_num_arr[i]]
    );
  }
  let row_week_name_str=row_week_name_arr.join(",");

  let title_str="  수업";
  if(row_week_name_arr.length>0){
    title_str+="("+row_week_name_str+")";
  }
  title_str+="\n";
  let student_str_arr=[];
  let student_group_arr=[];
  for(let i=0;i<student_arr.length;i++){
    let stu_info=student_arr[i];
    let stu_name_str=stu_info.a_user_name;

    //요일 다르면 표시
    let stu_row_week_name_str="";
    let tmp_row_week_name_arr=[];
    stu_info["day_num_arr"].sort();
    for(let i=0;i<stu_info["day_num_arr"].length;i++){
      tmp_row_week_name_arr.push(
        week_name_arr[stu_info["day_num_arr"][i]]
      );
    }
    let tmp_row_week_name_str=tmp_row_week_name_arr.join(",");
    if(row_week_name_str!=tmp_row_week_name_str){
      stu_name_str+=stu_row_week_name_str="("+tmp_row_week_name_str+")";
    }

    if(i!=0&&i%3==0){
      student_group_arr.push(student_str_arr.join(" "));
      student_str_arr=[];
    }
    student_str_arr.push(stu_name_str);
  }
  if(student_str_arr.length>0){
    student_group_arr.push(student_str_arr.join(" "));
  }

  content_str=title_str;
  content_str+=student_group_arr.join("\n");

  return {
    content_str:{v:content_str,t:"s",s: { alignment: { wrapText: true } } },
    merge_num:height_multi_num,
  };
};

export default go_down_excel;