import CompLayout from "@/pcomponents/comp/layout/layout";

function InitTestMain(){

  return (
    <CompLayout isLeftArea={true}>

    </CompLayout>
  );
}

export default InitTestMain;