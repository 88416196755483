import React, { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import down_arrow from "@/img/ico/arrow/down_arrow.png";
import up_arrow from "@/img/ico/arrow/up_arrow.png";
import LectureTimeTableArea from "./lecture_time_table";

const LectureStuTimeArea = forwardRef((props: any, ref) => {

  const [stu_info_arr, set_stu_info_arr] = useState<any>([]);
  const [stu_info, set_stu_info] = useState<any>(null);
  const [lecture_arr, set_lecture_arr] = useState([]);
  const [lecture_user_arr, set_lecture_user_arr] = useState([]);
  const [lecture_user_time_arr, set_lecture_user_time_arr] = useState([]);
  const [show_user_time_row, set_show_user_time_row] = useState(-1);
  const [show_user_time_all, set_show_user_time_all] = useState(true);
  const [teacherInfoArr, setTeacherInfoArr] = useState([]);
  const [room_info_arr, set_room_info_arr] = useState<any>([]);
  const [is_del_pre,set_is_del_pre]=useState("");//1,
  const [refresh, set_refresh] = useState(false);

  const initStudentRowData = {
    "a_lecture_seq": "",
    "a_user_seq": "",
    "a_user_name": "",
    "a_user_grade": "student",
    "a_is_main": "",
    "a_state": "ing",
  };

  useEffect(() => {
    get_lecture_by_ajax();
    getTeacherInfoArr();
    getSelectRoomList();
  }, []);

  const setInitData = (inData: any) => {
    let opt_obj = {
      "stu_info_arr": null,
      ...inData
    };
    set_stu_info_arr(opt_obj["stu_info_arr"]);
    if (opt_obj["stu_info_arr"] && opt_obj["stu_info_arr"].length > 0) {
      let tmp_stu_info = opt_obj["stu_info_arr"][0];
      set_stu_info(tmp_stu_info);
      get_user_and_time_by_ajax({ "in_stu_info": tmp_stu_info });
    } else {
      set_stu_info(null);
      set_lecture_user_arr([]);
      set_lecture_user_time_arr([]);
      set_show_user_time_row(-1);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const get_lecture_by_ajax = () => {
    let lecture_arr_form_json_data = {
      "order_id": "a_order_num",
      "s_state": "ing",
      "s_addon_time": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', lecture_arr_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const getTeacherInfoArr = () => {
    let teacher_s_form_data = {
      "s_grade": ["teacher", "master"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', teacher_s_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setTeacherInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const getSelectRoomList = () => {
    let formJsonData = {
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });

  };

  const get_user_and_time_by_ajax = (inData: any) => {
    let opt_obj = {
      in_stu_info: null,
      ...inData
    };
    let in_stu_info = stu_info;
    if (opt_obj["in_stu_info"] != null) {
      in_stu_info = opt_obj["in_stu_info"];
    }
    if (in_stu_info == null || strFunc.is_empty(in_stu_info.a_seq)) {
      set_lecture_user_arr([]);
      set_lecture_user_time_arr([]);
      set_show_user_time_row(-1);
      return false;
    }
    let lecture_form_json_data = {
      "s_stu_seq": in_stu_info["a_seq"],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/get_user_and_time', 
    lecture_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_user_arr(response.data["data"]["lecture_user_arr"]);
          set_lecture_user_time_arr(response.data["data"]["user_time_arr"]);
          //해당순번강의가 없다면 시간표닫기
          let tmp_is_show_time_table = false;
          for (let lecture_i = 0; lecture_i < lecture_arr.length; lecture_i++) {
            if (lecture_i == show_user_time_row) {
              for (let i = 0; i < response.data["data"]["lecture_user_arr"].length; i++) {
                if (response.data["data"]["lecture_user_arr"][i]["a_lecture_seq"] == lecture_arr[lecture_i]["a_seq"]) {
                  tmp_is_show_time_table = true;
                }
              }
            }
          }
          if (tmp_is_show_time_table == false) {
            set_show_user_time_row(-1);
          }
        } else {
          set_lecture_user_arr([]);
          set_lecture_user_time_arr([]);
          set_show_user_time_row(-1);
        }
      });
  };

  const goSaveLectureStuTime = () => {
    if (stu_info_arr.length == 0) {
      alert("학생 선택이 없습니다.");
      return false;
    }

    let stu_seq_arr = [];
    for (let i = 0; i < stu_info_arr.length; i++) {
      let tmp_stu_info = stu_info_arr[i];
      if (!strFunc.is_empty(tmp_stu_info["a_seq"])) {
        stu_seq_arr.push(tmp_stu_info["a_seq"]);
      }
    }

    //체크안된 time 제거
    let lecture_seq_arr: any = [];
    for (let i = 0; i < lecture_user_arr.length; i++) {
      let lecture_user = lecture_user_arr[i];
      lecture_seq_arr.push(lecture_user["a_lecture_seq"]);
    }
    let tmp_lecture_user_time_arr: any = [];
    for (let i = 0; i < lecture_user_time_arr.length; i++) {
      let lecture_user_time = lecture_user_time_arr[i];
      if (strFunc.str_in_array(lecture_user_time["a_lecture_seq"], lecture_seq_arr) != -1) {
        tmp_lecture_user_time_arr.push(lecture_user_time);
      }
    }
    set_lecture_user_time_arr(tmp_lecture_user_time_arr);

    if (stu_seq_arr.length == 0) {
      alert("학생 선택이 없습니다2.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let w_form_json_data = {
      "stu_seq_arr": stu_seq_arr,
      "lecture_user_arr": lecture_user_arr,
      "lecture_user_time_arr": tmp_lecture_user_time_arr,
      "is_del_pre":is_del_pre,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/save_stu_lecture_and_time_multy',
       w_form_json_data,get_axios_data())
      .then((response) => {
        alert(response.data["msg"]);
        if (response.data["result"] === "true") {
          props.list({ "now_page": "1", "select_seq": stu_info.a_seq });
        }
      });
  };

  const onChangeLectureUser = (inData: any) => {
    let opt_obj = {
      lecture_info: null,
      is_checked: false,
      ...inData
    };
    if (stu_info == null || strFunc.is_empty(stu_info.a_seq)) {
      return false;
    }

    let tmp_lecture_user_arr: any = lecture_user_arr;
    if (opt_obj["is_checked"] == true) {
      //추가
      tmp_lecture_user_arr.push({
        ...initStudentRowData,
        "a_lecture_seq": opt_obj.lecture_info.a_seq,
        "a_user_seq": stu_info.a_seq,
        "a_user_name": stu_info.a_user_name,
      });
    } else {
      //제거
      tmp_lecture_user_arr = [];
      for (let i = 0; i < lecture_user_arr.length; i++) {
        if (lecture_user_arr[i]["a_lecture_seq"] != opt_obj.lecture_info["a_seq"]) {
          tmp_lecture_user_arr.push(lecture_user_arr[i]);
        }
      }
    }
    set_lecture_user_arr(tmp_lecture_user_arr);
  };

  const onChageInput = (inData: any) => {
    let opt_obj = {
      row_num: 0,
      key: "",
      value: "",
      ...inData
    };
    let row_num = opt_obj.row_num;
    let tmp_studentArr: any = [...lecture_user_arr];
    tmp_studentArr[row_num][opt_obj.key] = opt_obj.value;
    set_lecture_user_arr(tmp_studentArr);
  };

  const get_lecture_box_tag = () => {
    let lecture_tr_tags = lecture_arr.map((lecture_info: any, lecture_idx: number) => {
      let is_has_lecture = false;
      let lecture_user_info: any = null;
      let lecture_user_idx = 0;
      for (let i = 0; i < lecture_user_arr.length; i++) {
        if (lecture_user_arr[i]["a_lecture_seq"] == lecture_info["a_seq"]) {
          is_has_lecture = true;
          lecture_user_info = lecture_user_arr[i];
          lecture_user_idx = i;
        }
      }

      let is_show_time_table = false;
      if (show_user_time_all) {
        is_show_time_table = true;
      } else if (show_user_time_row == lecture_idx) {
        is_show_time_table = true;
      }
      if (stu_info == null || is_has_lecture == false) {
        is_show_time_table = false;
      }

      return (
        <div key={lecture_idx} style={{ maxWidth: 300, display: "inline-block",border:"1px solid #ddd",padding:2 }}>
          <div>
            {lecture_info["a_name"]}
            <span className="ml-1">
              <input type="checkbox" value="1" checked={is_has_lecture} style={{ width: 15, height: 15 }} onChange={(e: any) => {
                onChangeLectureUser({
                  lecture_info: lecture_info,
                  is_checked: e.target.checked,
                });
                set_refresh(!refresh);
              }} />
            </span>
          </div>
          {is_show_time_table &&
            <LectureTimeTableArea
              lecture_info={lecture_info}
              stu_info={stu_info}
              lecture_user_time_arr={lecture_user_time_arr}
              teacherInfoArr={teacherInfoArr}
              room_info_arr={room_info_arr}
              set_lecture_user_time_arr={(inData: any) => {
                set_lecture_user_time_arr(inData);
                set_refresh(!refresh);
              }}
            ></LectureTimeTableArea>
          }
        </div>
      );
    });

    return lecture_tr_tags;
  };

  return (
    <div>
      <div style={{ width: "auto", minWidth: 300,display:"flex",gap:5 }}>
        {get_lecture_box_tag()}
      </div>
      <div className="btn-box-center mt-2">
        <select value={is_del_pre} onChange={(e:any)=>{set_is_del_pre(e.target.value);}} 
          style={{width:115,border:"1px solid #ddd"}} >
          <option value="">추가및덮어쓰기</option>
          <option value="1">초기화후덮어쓰기</option>
        </select>
        <button className="btn btn-sky" onClick={() => { goSaveLectureStuTime(); }}>저장</button>
        <button className="btn btn-dark" onClick={() => { set_show_user_time_all(!show_user_time_all); }}>
          시간표모두
          {show_user_time_all ? "열기취소" : "열기"}
        </button>
        {stu_info_arr.length > 0 &&
          <span>
            ( {stu_info_arr.length} 명 )
          </span>
        }
      </div>
      <div style={{ display: "flex", marginTop: 10, gap: 10, justifyContent: "center", flexWrap: "wrap" }}>
        {
          stu_info_arr.map((item: any, idx: number) => {
            let stu_name_color = "#000";
            if (idx == 0) {
              stu_name_color = "#00f";
            }
            return (
              <span key={idx} style={{ color: stu_name_color }}>
                {item.a_user_name}
              </span>
            )
          })
        }
      </div>
      <div style={{ marginTop: 10, color: "#ff872b" }}>
        <p style={{ fontSize: 12 }}>※ 선택된 원생들이 일괄 저장됩니다.</p>
        <p style={{ fontSize: 12 }}>※ 기존 시간표 정보가 삭제되고, 저장된 시간표로 대체됩니다.</p>
      </div>
    </div>
  );
});

export default LectureStuTimeArea;