
function ListTabArea(props: any) {
  let myProps = {
    list_tab: "day",//week,day
    set_list_tab: (inData: any) => { },
    ...props
  };
  let list_tab_arr = [
    { id: "week", name: "주별" },
    { id: "day", name: "일별" },
  ];

  const get_list_tab_tags = () => {
    let list_tab_tags = list_tab_arr.map((item: any, idx: number) => {
      let tab_box_style:any = {
        display:"inline-block",
        minWith:"100px",
        textAlign:"center",
        padding:"5px 10px",
        cursor:"pointer",
        fontSize:"16px",
        border:"1px solid lightgray"
      };
      if(item.id==myProps.list_tab){
        tab_box_style["backgroundColor"] = "#3FAC96";
        tab_box_style["color"] = "white";
      }
      return (
        <div key={idx}
          style={tab_box_style}
          onClick={() => { myProps.set_list_tab(item.id) }}>
          {item.name}
        </div>
      );
    });

    return list_tab_tags;
  };

  return (
    <div style={{display:"flex",gap:"2px",justifyContent:"center"}}>
      {get_list_tab_tags()}
    </div>
  );
};
export default ListTabArea;