import Player from '@vimeo/player';

class VimeoFunc{
  static get_player_by_iframe_obj(iframe_obj){
    let player=null;
    if(iframe_obj){
      player = new Player(iframe_obj);
    }
    return player;
  }
}
export default VimeoFunc;