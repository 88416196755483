import strFunc from '@/lib/lyg/string';

function TimeHisInput(props:any){
  let myProps:any={
    is_after_name:false,
    div_class:"",
    input_class:"",
    input_width:22,
    value:"",//sec 숫자
    onChangeData:(change_val:any)=>{
      
    },
    ...props
  };


  let time_json=strFunc.secondsToTimeJson(strFunc.uncomma(myProps.value));

  return (
    <div className={myProps.div_class}>
      <input type="text" className={myProps.input_class} value={time_json.h} 
        style={{width:myProps.input_width,textAlign:"center"}}
        onChange={(e:any)=>{
          let tmp_h=parseInt(strFunc.uncomma(e.target.value));
          if(tmp_h>=60){
            tmp_h=59;
          }
          if(tmp_h<0){
            tmp_h=0;
          }
          let tmp_val=strFunc.timeToSeconds(tmp_h,time_json.i,time_json.s);
          myProps.onChangeData(tmp_val);
        }}
      />
      {myProps.is_after_name?"시간 ":":"}
      <input type="text" className={myProps.input_class} value={time_json.i} 
        style={{width:myProps.input_width,textAlign:"center"}}
        onChange={(e:any)=>{
          let tmp_i=parseInt(strFunc.uncomma(e.target.value));
          if(tmp_i>=60){
            tmp_i=59;
          }
          if(tmp_i<0){
            tmp_i=0;
          }
          let tmp_val=strFunc.timeToSeconds(time_json.h,tmp_i,time_json.s);
          myProps.onChangeData(tmp_val);
        }}
      />
      {myProps.is_after_name?"분 ":":"}
      <input type="text" className={myProps.input_class} value={time_json.s} 
        style={{width:myProps.input_width,textAlign:"center"}}
        onChange={(e:any)=>{
          let tmp_s=parseInt(strFunc.uncomma(e.target.value));
          if(tmp_s>=60){
            tmp_s=59;
          }
          if(tmp_s<0){
            tmp_s=0;
          }
          let tmp_val=strFunc.timeToSeconds(time_json.h,time_json.i,tmp_s);
          myProps.onChangeData(tmp_val);
        }}
      />
      {myProps.is_after_name?"초":""}
    </div>
  );
};
export default TimeHisInput;