//import {CustomInputWrite} from 'components/common/date_picker/custom_input';
//customInput={<CustomInputWrite />}
import { forwardRef } from 'react';

export const CustomInputWrite = forwardRef((props, ref) => {
  const myProps={
    onClick:(e)=>{},
    value:"",
    with:"100%",
    height:"auto",
    ...props
  };
  return (
    <button className="row-input" style={{width:myProps.with,height:myProps.height}}  readOnly onClick={myProps.onClick} ref={ref}>
      &nbsp;{myProps.value}
    </button>
  )
});

export const CustomInputSearch = forwardRef((props, ref) => {
  const myProps={
    onClick:(e)=>{},
    value:"",
    with:"100%",
    height:"auto",
    ...props
  };
  return (
    <button className="search-input h-7" style={{width:myProps.with,height:myProps.height}} readOnly onClick={myProps.onClick} ref={ref}>
      &nbsp;{myProps.value}
    </button>
  );
});
/*
<div className="search_date_picker_wrap">
  <DatePicker 
    wrapperClassName="pl-1"
    selected={pageData.listOpt.s_start_date!=""?new Date(pageData.listOpt.s_start_date):null} 
    onChange={(date:Date) => {
      onChangeDatePicker("s_start_date",date);
    }}
    locale={ko} 
    dateFormat="yyyy-MM-dd"
    customInput={<CustomInputSearch />}
    renderCustomHeader={customHeaderFunc}
  />
</div>
  ~
<div className="search_date_picker_wrap">
  <DatePicker 
    wrapperClassName="pl-1"
    selected={pageData.listOpt.s_end_date!=""?new Date(pageData.listOpt.s_end_date):null} 
    onChange={(date:Date) => {
      onChangeDatePicker("s_end_date",date);
    }}
    locale={ko} 
    dateFormat="yyyy-MM-dd"
    customInput={<CustomInputSearch />}
    renderCustomHeader={customHeaderFunc}
  />
</div>
*/