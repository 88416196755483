import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';

function SupplyCardPosPopup(props:any) {
  let myProps={
    "closePopup":()=>{},
    "row_data":{},
    "is_cancel":false,
    "list":(inData:any)=>{},
    ...props,
  };
  let user=useSelector((state:any) => state.user);
  let init_pos_row_data={
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_mcomp_seq":user.comp_seq,
    "a_user_seq":myProps.row_data.a_stu_seq,
    "a_writer_seq":user.user_seq,
    "a_state":"ready",
    "a_par_id":"supply",
    "a_par_seq":myProps.row_data.a_ymd+","+myProps.row_data.a_seq,
    "a_trade_date":DateFunc.get_date_format(new Date(),"Y-m-d h:i:s"),
    "a_amount":"1",
    "a_tax_money":"0",
    "a_serve_money":"0",
    "a_pay_money":myProps.row_data.a_money_sum,
    "a_title":myProps.row_data.a_title,
    "a_approval_num":"",
    "a_cat_num":"",
    "a_origin_trade_date":"",
    "a_sort":"supply",
    "a_card_num":"",
    "a_card_company":"",
    "a_monthly_pay":0,
    "a_memo":"",
    "a_receipt_content":"",

    "a_company_name":"",
    "a_ceo":"",
    "a_busin_num":"",
    "a_comp_tel":"",
    "a_stu_name":myProps.row_data.a_stu_name,
    "a_buy_company_name":"",
    "a_buy_company_code":"",
    "a_pos_union_num":"",
    "a_trade_serial_num":"",
    "a_cat_response_code":"",
    "a_cat_memo1":"",
    "a_cat_memo2":"",
    "a_cat_memo3":"",
    "a_cat_memo4":"",

    "a_is_cancel":"",
    "a_cancel_date":"",
  };
  if(myProps.row_data.pos_card_arr&&myProps.row_data.pos_card_arr.length>0){
    let last_card_row=myProps.row_data.pos_card_arr[0];
    //마지막이 취소면 새로등록 시작
    if(last_card_row["a_is_cancel"]!="1"){
      init_pos_row_data={
        ...init_pos_row_data,
        ...myProps.row_data.pos_card_arr[0],
        "a_pay_money":myProps.row_data.a_money_sum,
        "a_tax_money":"0",
      }
    }
  }
  const [pos_row,set_pos_row]=useState(init_pos_row_data);
  const [pos_req,set_pos_req]=useState({
    "sort":myProps.is_cancel?"supply_cancel":"supply",//supply, supply_cancel, receipt, receipt_cancel
    "is_print_receipt":"1",
    "is_print_receipt_user":"1",
    "is_print_base":"1",
    "api_key":"",
    "api_user_seq":user.user_seq,
  });
  const [is_check_pos_result,set_is_check_pos_result]=useState(false);

  useEffect(() => {
    
    getCorpInfo();
    getMcompInfo();
  }, []);

  //포스기결과 체크 루프
  useEffect(() => {
    let posResultLoop=setInterval(()=>{
      checkPosResultState();
    },2000)

    return () => {
      if(posResultLoop!=null){
        clearInterval(posResultLoop);
      }
    };
  }, [is_check_pos_result,pos_row,pos_req]);
  
  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="is_print_receipt"||name=="is_print_base"||name=="is_print_receipt_user"){
      set_pos_req({
        ...pos_req,
        ...{[name]: value}
      });
    }else if(name=="a_pay_money"){
      value=strFunc.comma(value);
    }
    set_pos_row({
      ...pos_row,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    set_pos_row({
      ...pos_row,
      ...{[key]: date_str}
    });
  };

  const getCorpInfo=()=>{
    let corp_info_form_data={
      "s_pri_arr":[user.corp_seq],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/corp/corp/list',corp_info_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_corp_info=response.data["data"]["info_arr"][0];
          set_pos_row({
            ...pos_row,
            "a_company_name":tmp_corp_info["a_company_name"],
            "a_ceo":tmp_corp_info["a_represent_name"],
            "a_busin_num":strFunc.str_replace("-","",tmp_corp_info["a_company_busin_num"]),
            "a_comp_tel":strFunc.str_replace("-","",tmp_corp_info["a_company_tel"]),
          });
        }
      }else{
        
      }
    });
  };
  const getMcompInfo=()=>{
    let mcomp_info_form_data={
      "s_pri_arr":[user.comp_seq],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/mcomp/list',mcomp_info_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_mcomp_info=response.data["data"]["info_arr"][0];
          set_pos_req({
            ...pos_req,
            "api_key":tmp_mcomp_info["a_api_key"],
          });
        }
      }else{
        
      }
    });
  };

  const goRequestPos=()=>{
    if(!confirm("pos기로 전송 하시겠습니까?")){
      return false;
    }
    //poscard 준비등록
    let write_pos_form_data:any={
      "data_arr":[pos_row],
    };
    if(pos_row.a_seq==""){
      write_pos_form_data["is_default_val"]="1";
    }
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/trade/mpos_card/write',write_pos_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_pos_row({
          ...pos_row,
          ...response.data["data"][0]
        });
        goRequestPosAction({
          row_data:response.data["data"][0]
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goRequestPosAction=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData
    };
    myProps.list({});
    let pos_req_json={
      "pos_card_seq":opt_obj["row_data"]["a_ymd"]+","+opt_obj["row_data"]["a_seq"],
      ...pos_req,
      "sort":pos_req.sort
    };
    
    let pos_req_json_str=JSON.stringify(pos_req_json);
    pos_req_json_str=strFunc.base64Encode(pos_req_json_str);
    location.href="intoyoupos://"+pos_req_json_str;
    setTimeout(()=>{
      if (document.hasFocus()) {
        alert("Pos연동 설치 필요.");
      } else {
        //응답기다리기
        set_is_check_pos_result(true);
      }
    },1000);
  };

  const checkPosResultState=()=>{
    if(is_check_pos_result&&pos_row.a_seq!=""){
      let tmp_pos_card_pri_val=pos_row.a_ymd+","+pos_row.a_seq;
      let pos_card_list_form={
        "s_pri_arr":[tmp_pos_card_pri_val],
        "is_need_count":"",
        "is_need_info_arr":"1",
        "is_no_limit":"1",
      };
      my_axios.post( process.env.REACT_APP_API_URL+'/api/main/trade/mpos_card/list',pos_card_list_form,get_axios_data())
      .then((response) => {
        if(response.data["result"]==="true"){
          if(response.data["data"]["info_arr"].length>0){
            let tmp_card_row=response.data["data"]["info_arr"][0];
            //다른상태면 완료
            if(tmp_card_row["a_state"]!=pos_row.a_state){
              let result_msg="결제가 완료.";
              if(pos_req.sort=="supply_cancel"){
                result_msg="결제취소 완료.";
              }
              myProps.list({});
              alert(result_msg);
              myProps.closePopup();
            }
          }
        }else{
          
        }
      });
    }
  };

  return (
    <div>
      결제요청 ({pos_row.a_stu_name})
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width="20%" ></col>
            <col width="30%" ></col>
            <col width="20%" ></col>
            <col width="30%" ></col>
          </colgroup>
          <tbody>
            <tr>
              <th>수납명</th>
              <td>
                <input type="text" className="row-input" name="a_title" value={pos_row.a_title} onChange={handleInputChange} placeholder="수납명" />
              </td>
              <td colSpan={2} rowSpan={4}>
                <h5>영수증</h5>
                <textarea className="row-input" name="a_receipt_content" value={pos_row.a_receipt_content} 
                  onChange={()=>{}} placeholder="영수증내용" style={{height:150}}
                  readOnly></textarea>
              </td>
            </tr>
            <tr>
              <th>청구금</th>
              <td>
                <input type="text" className="row-input" name="a_pay_money" value={pos_row.a_pay_money} onChange={handleInputChange} placeholder="청구금" />
              </td>
            </tr>
            <tr>
              <th>학원명</th>
              <td>
                {pos_row.a_company_name}
              </td>
            </tr>
            <tr>
              <th>출력</th>
              <td>
                <p>
                  <label>
                    영수증(학원용):<input type="checkbox" className="row-input ml-1" name="is_print_receipt" value="1" onChange={handleInputChange}
                    checked={pos_req.is_print_receipt=="1"?true:false} style={{width:"auto"}} />
                  </label>
                </p>
                <p>
                  <label>
                    영수증(소비자):<input type="checkbox" className="row-input ml-1" name="is_print_receipt_user" value="1" onChange={handleInputChange}
                    checked={pos_req.is_print_receipt_user=="1"?true:false} style={{width:"auto"}} />
                  </label>
                </p>
                <p>
                  <label>
                  기본인쇄:<input type="checkbox" className="row-input ml-1" name="is_print_base" value="1" onChange={handleInputChange}
                    checked={pos_req.is_print_base=="1"?true:false} style={{width:"auto"}} />
                  </label>
                </p>
              </td>
            </tr>
            <tr>
              <th>거래일</th>
              <td>
                  {pos_row.a_trade_date}
              </td>
              <th>할부</th>
              <td>
                <select className="row-input" name="a_monthly_pay" value={pos_row.a_monthly_pay} onChange={handleInputChange}>
                  <option value="0">일시불</option>
                  {
                    [2,3,4,5,6,7,8,9,10,11,12].map((item:any,idx:number)=>{
                      return (
                        <option value={item} key={idx}>{item} 개월</option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>원거래일</th>
              <td>
                {pos_row.a_origin_trade_date}
              </td>
              <th>메모</th>
              <td>
                <input type="text" className="row-input" name="a_memo" value={pos_row.a_memo} onChange={handleInputChange} placeholder="메모" />
              </td>
            </tr>
            <tr>
              <th>승인번호</th>
              <td>
                {pos_row.a_approval_num}
              </td>
              <th>일련번호</th>
              <td>
                  {pos_row.a_trade_serial_num}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-dark" onClick={()=>{goRequestPos();}}>{myProps.is_cancel?"결제취소":"결제"}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default SupplyCardPosPopup;
