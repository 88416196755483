import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import strFunc from '@/lib/lyg/string';

const TodoWriteListArea = forwardRef((props:any, ref) => {
  const myProps={
    make_todo_by_pan_write:(inOptObj:any)=>{},
    ...props
  };
  
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  let user=useSelector((state:any) => state.user);

  const [todo_write_arr,set_todo_write_arr]=useState<any>([]);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "todo_write_arr":[],
      ...inOptObj
    };
    for(let i=0;i<optObj.todo_write_arr.length;i++){
      optObj.todo_write_arr[i]["row_num"]=i;
    }
    set_todo_write_arr(optObj.todo_write_arr);
    ListComponentRef.current.setInfoArr(optObj.todo_write_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_todo_write_arr
  }));

  useEffect(()=>{
    
  },[]);

  const get_todo_write_arr=()=>{
    let all_row=ListComponentRef.current.getRows();
    set_todo_write_arr(all_row);
    return all_row;
  };


  const removeSelect=()=>{
    let all_row=ListComponentRef.current.getRows();
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      return false;
    }
    let remain_row_arr=[];
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_is_exist=false;
      for(let j=0;j<selected_row.length;j++){
        let tmp_sel_row=selected_row[j];
        if(tmp_row.row_num==tmp_sel_row.row_num){
          tmp_is_exist=true;
        }
      }
      if(tmp_is_exist==false){
        remain_row_arr.push(tmp_row);
      }
    }
    ListComponentRef.current.setInfoArr(remain_row_arr);
  };

  const customCellRenderer=(params:any)=>{
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    let key=params.key;
    let val_str=params.value;

    if(key=="a_sort"){
      if(xColumnArr.select_arr[key]){
        for(let i=0;i<xColumnArr.select_arr[key].length;i++){
          let sel_opt_row=xColumnArr.select_arr[key][i];
          if(sel_opt_row["value"]==val_str){
            val_str=sel_opt_row["text"];
          }
        }
      }
    }else if(key=="a_success_sort"){
      if(xColumnArr.select_arr[key]){
        for(let i=0;i<xColumnArr.select_arr[key].length;i++){
          let sel_opt_row=xColumnArr.select_arr[key][i];
          if(sel_opt_row["value"]==val_str){
            val_str=sel_opt_row["text"];
          }
        }
      }
    }

    return val_str;
  };
  const customGridCellRenderer=(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "input_tag":input_tag,
        "onChangeInput":onChangeInput,
      }
      */
     let key=params.key;
     if(key=="row_view_success_amount"){
      if(params.rowData.a_success_sort=="time"){
        params.input_tag=parseInt(strFunc.uncomma(params.rowData.a_success_time_sec))/60;
      }else{
        params.input_tag=params.rowData.a_success_amount;
      }
     }else if(key=="a_amount_sort"){
      if(params.rowData.a_success_sort=="time"){
        params.input_tag="초";
      }
     }else if(key=="row_view_reserve_date"){
      params.input_tag=params.rowData.a_reserve_start_date.substring(0,10);
     }
      return params.input_tag;
  };

  return (
    <div>
      <h5>작성내용</h5>
      <div className="my-1 text-right">
        <button className="btn-m btn-yellowish2" onClick={()=>{myProps.make_todo_by_pan_write();}} style={{width:"50%"}}>스케줄 적용하기</button>
        <button className="btn-m btn-dark ml-1" onClick={()=>{removeSelect();}} >제외</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        infoArr={todo_write_arr}
        gridOpt={{
          isGrid:true,
          is_add_idx_num:false,
          is_add_checkbox:true,
          width:"100%",
          height:"40vh",
          is_auto_max_width:false,
          td_align:"left",//center,right
          table_align:"left",//center,right
          onGridReady:()=>{},
          rowOnClick:(rowData:any,idx:number,e:any)=>{

          },
          customCellRenderer:customCellRenderer,
          customGridCellRenderer:customGridCellRenderer
        }}
      ></ListComponent>
    </div>
  );
});

export default TodoWriteListArea;
