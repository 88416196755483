import { useRef,useCallback,forwardRef,useImperativeHandle, useState } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import Style from "../../css/style.module.css";
import strFunc from '@/lib/lyg/string';

const SelectListArea=forwardRef((props:any, ref) => {
  let myProps={
    select_stu_arr:[],
    set_select_stu_arr:(inData:any)=>{},
    list:(inData:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_init_data
  }));

  const set_init_data=(inData:any)=>{
    let opt_obj={
      select_stu_arr:[],
      ...inData
    };
    ListComponentRef.current.setInfoArr(opt_obj.select_stu_arr);
  };

  const remove_select_stu_arr=()=>{
    let all_arr=ListComponentRef.current.getRows();
    let tmp_select_arr=ListComponentRef.current.getSelectedRows();
    
    if(tmp_select_arr.length==0){
      alert("선택이 없습니다. ");
      return false;
    }
    
    let new_select_arr=[];
    for(let i=0;i<all_arr.length;i++){
      let pre_info=all_arr[i];
      let is_exist=false;
      for(let j=0;j<tmp_select_arr.length;j++){
        let sel_info=tmp_select_arr[j];
        if(pre_info["a_seq"]==sel_info["a_seq"]){
          is_exist=true;
        }
      }
      if(is_exist==false){
        new_select_arr.push(pre_info);
      }
    }

    myProps.set_select_stu_arr(new_select_arr);
    ListComponentRef.current.setInfoArr(new_select_arr);
    let s_except_user_seq:any=[];
    for(let i=0;i<new_select_arr.length;i++){
      s_except_user_seq.push(new_select_arr[i]["a_seq"]);
    }
    myProps.list({"s_except_user_seq":s_except_user_seq});
  };

  const customCellRenderer= useCallback( (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.rowData.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.rowData.class_arr.length;i++){
          class_name_arr.push(
            strFunc.cut_str(params.rowData.class_arr[i]["class_name"],4,"")
          );
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_school_grade"){
      let stu_grade_str="";
      if(xColumnArr.select_arr["a_stu_grade"]){
        for(let i=0;i<xColumnArr.select_arr["a_stu_grade"].length;i++){
          let tmp_row_opt=xColumnArr.select_arr["a_stu_grade"][i];
          if(tmp_row_opt["value"]==params.rowData.a_stu_grade){
            stu_grade_str=tmp_row_opt["text"];
          }
        }
      }
      let a_school_name=params.rowData.a_school_name;
      a_school_name=strFunc.cut_str(a_school_name,3,"..");
      render_str=a_school_name+" "+stu_grade_str;
    }else if(key=="row_view_remove"){
      render_str=(
        <button className="btn btn-red" onClick={()=>{
          setTimeout(()=>{
            remove_select_stu_arr();
          },100);
        }} >━</button>
      );
    }
    return render_str;
  }, []);

  return (
    <div>
      <div className={Style.left_top_btn_wrap} style={{minWidth:350}}>
        <span style={{fontWeight:500}}>
          [선택 총 {myProps.select_stu_arr.length}명]
        </span>

        <button className="btn btn-red" 
          style={{position:"absolute",right:0}} 
          onClick={()=>{
            setTimeout(()=>{
              remove_select_stu_arr();
            },100);
          }}>선택제외</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={{...xColumnArr}}
        infoArr={myProps.select_stu_arr}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_add_idx_num:false,
          is_add_checkbox:true,
          td_align:"center",
          height:"35vh",
          is_fix_width:true,
          onGridReady:()=>{
            ListComponentRef.current.setInfoArr(myProps.select_stu_arr);
          },
          customCellRenderer:customCellRenderer,
          class_name_json:{
            table_div:"basic_list_div",
            td_div:"row-col-div",
            input_check:"chk_row",
            row_input:"row-input",
            data_row_tr:"data_row_tr",
            active_row:"active",
            empty_con_div:"list_empty_content_div",
          },
        }}
        ></ListComponent>
    </div>
  );
});
export default SelectListArea;