import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "./css/style.module.css";

const SiteMapBtn=forwardRef((props:any, ref) => {
  let myProps:any={
    onIsActive:(is_active:boolean)=>{},
    ...props
  };

  const [is_active,set_is_active]=useState(false);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getActive,set_is_active
  }));

  const getActive=()=>{
    return is_active;
  };

  const onActiveToggle=()=>{
    myProps.onIsActive(!is_active);
    set_is_active(!is_active);
  };

  let btn_class_name=Style.menu_trigger;
  if(is_active){
    btn_class_name+=" "+Style.active;
  }

  let wrap_style:any={};
  if(is_active){
    wrap_style.background="#3FAC96";
  }

  return (
    <div className={Style.btn_wrap} onClick={()=>{onActiveToggle();}} style={wrap_style}>
      <a className={btn_class_name} >
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>
  );
});

export default SiteMapBtn;