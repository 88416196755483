import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";

function UserListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    's_start_date': "",
    's_end_date': "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {
    list({});
  }, [user.comp_seq]);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(form_json_data);

    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    form_json_data["s_mcomp_seq"] = user.comp_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/katalk/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_json_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/katalk/delete', del_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.user_data_create_date = "";
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="btn-box-left relative h-8">
          <div className="absolute right-0 top-0 btn-box-right">
            {/* <button className="btn btn-dark" onClick={goDelete}>삭제</button> */}
          </div>
        </div>
        <div>
          <SearchArea
            listOpt={listOpt}
            list={list}
          ></SearchArea>
        </div>
        <div className="mt-2">
          <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{ ...xColumnArr }}
            list={list}
            gridOpt={{
              fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num: false,
              is_add_checkbox: false,
              floatingFilter: false,
              onGridReady: () => {
                //list({});
              },
              onCellClicked: cellClickedListener,
              cellRenderer: cellRenderer
            }}
          ></ListAggrid>
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
        </div>
      </div>
    </CompLayout>
  );
}

export default UserListPage;