import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from '@/pages/comp/basic/student/list/area/search/search_area';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteOptPopupContent from "@/pcomponents/common/xcolumn/popup/write_opt";
import MultyChangeArea from "./area/multy_change";
import { useSelector, useDispatch } from 'react-redux';

function ListPage() {
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>();
  let now_ymd_str=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    // s_addon_class_user:"1",
    s_addon_link_user: "1",
    // s_addon_profile:"1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_attend_sort_detail:"",
    s_attend_start_date:now_ymd_str,
    s_attend_end_date:now_ymd_str,
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"] = get_par_one_row_apply_info_arr(response.data["data"]["info_arr"]);
          setInfoArr(response.data["data"]["info_arr"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_par_one_row_apply_info_arr = (in_info_arr: any[]) => {
    let rs_info_arr: any = [];

    for (let i = 0; i < in_info_arr.length; i++) {
      let info = in_info_arr[i];
      if (info.link_user_arr && info.link_user_arr.length > 0) {
        let par_first_info = null;
        for (let j = 0; j < info.link_user_arr.length; j++) {
          let link_info = info.link_user_arr[j];
          if (link_info["a_type"] == "parent" && link_info["par_info"]) {
            if (par_first_info == null) {
              par_first_info = link_info;
            }
          }
        }
        if (par_first_info != null) {
          info["par_relation"] = par_first_info["a_relation"];
          info["par_phone"] = par_first_info["par_info"]["a_user_phone"];
          info["par_user_id"] = par_first_info["par_info"]["a_user_id"];
          info["par_user_pw"] = "";
          info["par_is_attend_sms"] = par_first_info["a_is_attend_sms"];
          info["par_is_attend_push"] = par_first_info["a_is_attend_push"];
          info["par_is_attend_katalk"] = par_first_info["a_is_attend_katalk"];
        }
      }
      rs_info_arr.push(info);
    }

    return rs_info_arr;
  };

  const goWriteArr = (e: any) => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("선택 수정 하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_student', {
      data_arr: selected_row,
      //is_update:"1",
    }, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        //alert(response.data["msg"]);
        //list({});
        goWriteParentUser(selected_row, response.data["msg"]);
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteParentUser = (select_rows: any[], success_msg: string) => {
    //세팅
    let last_user_arr = select_rows;

    //얻기
    let parentArr: any = [];
    for (let i = 0; i < last_user_arr.length; i++) {
      let u_info = last_user_arr[i];

      if (u_info["par_phone"] == "" || (u_info["par_phone"] && u_info["par_phone"].length < 7)) {
        continue;
      }

      let par_name = u_info["a_user_name"];
      if (u_info["par_relation"] == "모") {
        par_name += " 어머니";
      } else if (u_info["par_relation"] == "부") {
        par_name += " 아버지";
      } else if (u_info["par_relation"] == "etc") {
        par_name += " 부모님";
      } else {
        par_name += " " + u_info["par_relation"];
      }

      let par_link_row = {
        "a_stu_seq": u_info["a_seq"],
        "a_user_seq": "",
        "a_stu_name": u_info["a_user_name"],
        "a_user_name": par_name,
        "a_type": "parent",
        "a_relation": u_info["par_relation"],
        "a_order": 0,
        "a_is_attend_sms": u_info["par_is_attend_sms"],
        "a_is_attend_push": u_info["par_is_attend_push"],
        "a_is_attend_katalk": u_info["par_is_attend_katalk"],
        "par_phone": u_info["par_phone"],
        "par_user_id": u_info["par_user_id"],
        "par_user_pw": u_info["par_user_pw"],
      };

      let pre_par_link_info = null;
      if (u_info["link_user_arr"] && u_info["link_user_arr"].length > 0) {
        for (let j = 0; j < u_info["link_user_arr"].length; j++) {
          let link_info = u_info["link_user_arr"][j];
          if (link_info["a_type"] == "parent" && link_info["par_info"]) {
            if (pre_par_link_info == null) {
              pre_par_link_info = link_info;
            }
          }
        }
      }
      if (pre_par_link_info != null) {
        par_link_row["a_user_seq"] = pre_par_link_info["a_user_seq"];
      }

      parentArr.push(par_link_row);
    }

    if (parentArr.length == 0) {
      alert(success_msg);
      list({});
      return false;
    }

    let formJsonData = {
      parentArr: parentArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write_parent_arr', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(success_msg);
          list({});
        } else {
          alert(response.data["msg"]);
          list({});
        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let is_able_del = true;
    for (let i = 0; i < selected_row.length; i++) {
      if (selected_row[i]["a_seq"] == "1") {
        is_able_del = false;
      }
    }
    if (is_able_del == false) {
      alert("삭제 할 수 없는 회원입니다.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/delete', {
      data_arr: selected_row
    }, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        localStorage.user_data_create_date = "";
        list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  }, []);

  const goSelectChnageData = (inData: any) => {
    let opt_obj = {
      "key": "",
      "value": "",
      ...inData
    };
    if (opt_obj["value"] == "empty") {
      opt_obj["value"] = "";
    }

    let selected_row = ListComponentRef.current.getSelectedRows();
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i][opt_obj["key"]] = opt_obj["value"];
    }
    let tmp_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info: any = infoArr[i];
      for (let j = 0; j < selected_row.length; j++) {
        if (selected_row[j].a_seq == info.a_seq) {
          info = selected_row[j];
        }
      }
      tmp_info_arr.push(info);
    }
    let pre_active_row_arr = ListComponentRef.current.get_active_row_arr();
    setInfoArr(tmp_info_arr);
    ListComponentRef.current.setInfoArr(tmp_info_arr);
    ListComponentRef.current.set_active_row_arr(pre_active_row_arr);
  };

  const openListOptPopup = () => {
    let pop_title = "리스트설정";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": false,
      "title": pop_title,
      "sort": "list_opt"
    });
  };

  const setOrderByThKey = (th_key: string) => {
    let pre_order_id = listOpt.order_id;
    let change_order_id = pre_order_id;

    if (th_key == "checkbox") {
      return false;
    } else if (th_key.indexOf("row_view_") != -1) {
      return false;
    }

    if (pre_order_id.indexOf(th_key) != -1) {
      if (pre_order_id.indexOf(" DESC") != -1) {
        change_order_id = th_key;
      } else {
        change_order_id = th_key + " DESC";
      }
    } else {
      change_order_id = th_key;
    }
    list({
      now_page: 1,
      order_id: change_order_id
    });
  };

  const cellClickedListener = useCallback((rowData: any, idx: number, e: any) => {

  }, []);

  const onClickTh = (key: string, e: any) => {
    setOrderByThKey(key);
  };

  const cellRenderer = useCallback((inOpt: any) => {
    let opObj = {
      "rowData": {},
      "idx": 0,
      "key": "",
      "value": "",
      ...inOpt
    };

    return opObj.value;
  }, []);

  const gridCellRenderer = (params: any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
    if (params.key == "a_stu_num") {
      params.input_tag = (
        <input type="text" className="row-input" name={params.key}
          value={strFunc.str_pad({ "str": strFunc.getNumber2(params.value), "pad_length": user.stu_num_ciphers })}
          onChange={(e: any) => {
            params.onChangeInput({
              "rowData": params.rowData,
              "row_num": params.idx,
              "key": params.key,
              "value": parseInt(strFunc.getNumber2(e.target.value)),
              "pre_value": params.value,
              "event": e,
            });
          }}
          style={{ width: "98%" }} />
      );
    }
    return params.input_tag;
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea
          listOpt={{ ...listOpt }}
          setListOpt={setListOpt}
          xColumnArr={{ ...xColumnArr }}
          list={list}
        ></SearchArea>
        <div className="list_round_box_wrap mt-7">
          <div className="list_box_title_bar">
            <span className="list_box_title_bar_title">원생목록</span>
            <span className="ml-3">
              [총 <span className="text-blue-600">{infoArr.length}</span> 명]
            </span>
            <span>
              <button className="btn-s btn-line-gray ml-1" onClick={() => { openListOptPopup(); }}>화면설정</button>
            </span>
            <div className="list_box_title_right_box">
              <button className="btn btn-dark ml-1" onClick={goWriteArr}>선택수정</button>
              <button className="btn btn-red ml-1" onClick={goDelete}>삭제</button>
            </div>
          </div>
          <div className="list_round_box">
            <div className="mb-1">
              <MultyChangeArea
                xColumnArr={xColumnArr}
                onChangeMulty={(inData: any) => { goSelectChnageData(inData); }}
              ></MultyChangeArea>
            </div>
            <ListComponent
              ref={ListComponentRef}
              infoArr={infoArr}
              xColumnArr={{ ...xColumnArr }}
              gridOpt={{
                isGrid: true,
                is_add_idx_num: false,
                is_add_checkbox: true,
                is_auto_max_width: true,
                height: "45vh",
                td_align: "left",//center,right
                table_align: "left",//center,right
                onGridReady: () => { list({}); },
                rowOnClick: cellClickedListener,
                onClickTh: onClickTh,
                customCellRenderer: cellRenderer,
                customGridCellRenderer: gridCellRenderer,
              }}
            ></ListComponent>
          </div>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {
            popupData.sort == "list_opt" &&
            <WriteOptPopupContent
              xColumnArr={xColumnArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteOptPopupContent>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;