export const default_daily_report_last_info={
  a_stu_seq:"",
  a_writer_seq:"",
  a_lecture_seq:"",
  a_date:"",
  a_lecture_name:"",
  a_lecture_time:"",
  a_stu_name:"",
  a_writer:"",
  a_subject:"",
  a_grade_score:"",
  a_attend_sort_detail:"",
  a_attend_time:"",
  a_homework_per_num:"",
  a_homework_file_cnt:"",
  a_textbook:"",
  a_textbook_seq:"",
  a_subtextbook:"",
  a_subtextbook_seq:"",
  a_homework:"",
  a_textbook_study:"",
  a_subtextbook_study:"",
  a_textbook_study_det:"",
  a_subtextbook_study_det:"",
  a_textbook_study_num:"",
  a_textbook_study_det_num:"",
  a_todo_time_sec:"",
  a_todo_success_amount:"",
  a_todo_amount:"",
  a_todo_sub_success_amount:"",
  a_todo_sub_amount:"",
  a_memo:"",
};