import { lazy } from 'react';

const HomeDefault = lazy(() => import('@/pages/home/index'));
const NoiceList = lazy(() => import('@/pages/home/board/notice/list'));
const PersonalInfoManage = lazy(() => import('@/pages/home/guide/personal_info_manage'));
const JoinRequestWriteOne = lazy(() => import('@/pages/home/guide/join_request/write'));
const JoinRequestFintMy = lazy(() => import('@/pages/home/guide/join_request/find_my'));
const PlanMathViewOfUrl = lazy(() => import('@/pages/home/plan_math/view_of_url'));

// import HomeDefault from "@/pages/home/index";
// import NoiceList from "@/pages/home/board/notice/list";
// import PersonalInfoManage from "@/pages/home/guide/personal_info_manage";
// import JoinRequestWriteOne from "@/pages/home/guide/join_request/write";
// import JoinRequestFintMy from "@/pages/home/guide/join_request/find_my";

const routerArr:object[] = [
  {
    path: "/",
    element: <HomeDefault></HomeDefault>,
  },
  {
    path: "/home/board/notice/list",
    element: <NoiceList></NoiceList>,
  },
  {
    path: "/home/guide/personal_info_manage",
    element: <PersonalInfoManage></PersonalInfoManage>,
  },
  {
    path: "/home/guide/join_request/write",
    element: <JoinRequestWriteOne></JoinRequestWriteOne>,
  },
  {
    path: "/home/guide/join_request/find_my",
    element: <JoinRequestFintMy></JoinRequestFintMy>,
  },
  {
    path: "/home/plan_math/view_of_url",
    element: <PlanMathViewOfUrl></PlanMathViewOfUrl>,
  },
];

export default routerArr;