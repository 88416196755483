import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteFrameContent from "./area/write";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "select_cat_seq":"",
      "s_addon_title_img":"1",
      "s_addon_category":"1",
      "s_addon_section":"1",
      "s_addon_video":"1",
      "s_addon_use_stu":"1",
      "s_addon_request":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [categoryInfoArr, setCategoryInfoArr] = useState([]);
  const WriteFrameContentRef=useRef<any>(null);
  let is_under_width=MyScreenFunc.get_is_under();
  useEffect(() => {
    
    list_category();
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      if(listAggridRef.current){
        inOptObj["sc"]=listAggridRef.current.getListSc();
      }
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        //선택
        if(pageData.listOpt.select_cat_seq!=""){
          for(let i=0;i<response.data["data"]["info_arr"].length;i++){
            let info=response.data["data"]["info_arr"][i];
            if(info["a_seq"]==pageData.listOpt.select_cat_seq){
              WriteFrameContentRef.current.setInitData({
                row_data:info,
                isUpdate:true,
              });
            }
          }
          setTimeout(()=>{
            let listNodes=listAggridRef.current.getNodeRows();
            for(let i=0;i<listNodes.length;i++){
              let node=listNodes[i];
              if(node.data["a_seq"]==pageData.listOpt.select_cat_seq){
                listNodes[i].setSelected(true);
              }
            }
          },100);
          
        }
        
      }else{
        
      }
    });
  };

  const list_category = ()=>{
    let category_form_json={
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',category_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setCategoryInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        setWriteState();
      }else{
        alert(response.data["msg"]);
        list({});
      }
    });
  }, []);

  const setWriteState=()=>{
    listAggridRef.current.setSelectFalseAll();
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData();
    }
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData({
        row_data:params.data,
        isUpdate:true,
      });
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="row_view_section"){
      let section_num=0;
      let video_num=0;
      if(params.data.section_arr){
        section_num=params.data.section_arr.length;
      }
      if(params.data.video_arr){
        video_num=params.data.video_arr.length;
      }
      render_str=section_num+"/"+video_num;
    }else if(key=="row_view_stu_cnt"){
      if(params.data.use_stu_arr){
        render_str=params.data.use_stu_arr.length;
      }
    }else if(key=="row_view_request"){
      if(params.data.request_arr){
        render_str=params.data.request_arr.length;
      }
    }

    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"20%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:(is_under_width?230:460)}} >
                  <div className="search-box btn-box-left relative h-8">
                    카테고리
                    <select className="search-input_select" value={pageData.listOpt.s_category_seq} 
                      onChange={(e:any)=>{
                        list({
                          "now_page":1,
                          "s_category_seq":e.target.value
                        });
                      }}>
                      <option value="">선택없음</option>
                      {
                        categoryInfoArr.map((item:any,idx:number)=>{
                          return (
                            <option key={idx} value={item.a_seq}>{item.a_title}</option>
                          );
                        })
                      }
                    </select>
                    <div className="absolute right-0 top-0 btn-box-right">
                      <button className="btn btn-dark" onClick={()=>{setWriteState();}}>신규</button>
                      <button className="btn btn-dark" onClick={goDelete}>삭제</button>
                    </div>
                  </div>
                  <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{...xColumnArr}}
                  list={list}
                  gridOpt={{
                    fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num:true,
                    is_add_checkbox:false,
                    floatingFilter:true,
                    onGridReady:()=>{
                      list({});
                    },
                    onCellClicked:cellClickedListener,
                    cellRenderer:cellRenderer,
                  }}
                  ></ListAggrid>
                  <Paging now_page={pageData.listOpt.now_page}
                    num_per_page={pageData.listOpt.num_per_page}
                    total_rec={countInfo.tot} 
                    is_view_num_per_page={true}
                    onChangePage={(now_page:number)=>{list({now_page:now_page});}}
                    onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:400}} >
                  <WriteFrameContent
                    ref={WriteFrameContentRef}
                    list={(listOpt:any)=>{list(listOpt);}}
                  ></WriteFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default ListPage;