import strFunc from "@/lib/lyg/string";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function TextAreaViewWrite(props: any) {
  let myProps = {
    is_view_mode: true,
    value: "",
    name: "",
    textarea_style: { width: "100%", height: 32 },
    handleInputChange: (inData: any) => { },
    ...props
  };
  let is_view_mode = myProps.is_view_mode;
  let value = myProps.value;
  if(strFunc.is_empty(myProps.value)){
    value="";
  }
  let name = myProps.name;

  return (
    <div>
      {is_view_mode ?
        <MultyLineTextView text={value}></MultyLineTextView>
        :
        <textarea className="row-input" value={value}
          onChange={(e: any) => { myProps.handleInputChange({ name: name, value: e.target.value }); }}
          style={myProps.textarea_style}></textarea>
      }
    </div>
  );
};
export default TextAreaViewWrite;