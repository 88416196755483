//import WriteOptPopupContent from 'components/common/xcolumn/popup/write_opt/index';
import { useSelector, useDispatch } from 'react-redux';
import { setUser,removeUser } from '@/store/user/user';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import getXcolumnJson from './xcolumn/list';
import getXcolumnFunc from '@/pcomponents/common/xcolumn/get_data/get_xcolumn';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";

function WriteOptPopupContent(props:any) {
  let myProps={
    xColumnArr:{},
    callback:(inData:any)=>{},
    closePopup:()=>{},
    ...props
  };
  const optxColumnArr=getXcolumnJson();
  const dispatch = useDispatch();
  const user = useSelector((state:any) => state.user);

  const listAggridRef= useRef<any>(null);
  const ListComponentRef=useRef<any>(null);
  const pageData={
    "confirm_msg":"저장 하시겠습니까?",
  };
  const [rowData,setRowData]=useState({
    "a_user_seq":user["user_seq"],
    "a_list_id":myProps.xColumnArr.list_id,
    "a_list_type":myProps.xColumnArr.list_type,
    "a_json_data":"",
  });
  
  const [x_column_list_arr,set_x_column_list_arr]=useState(
    myProps.xColumnArr.func.getCopyXcolumnListArr(
      myProps.xColumnArr.x_column_list_arr
    )
  );
  const [list_opt_arr,set_list_opt_arr]=useState({
    "fix_left_num":0,
    'num_per_page':10,
    'order_id_arr':[],//["a_seq DESC","a_create_date DESC"]
    'order_id1':'',
    'order_type1':'',
    'order_id2':'',
    'order_type2':'',
    'start_date_num':'',
    'start_date_type':'',
  });

  useEffect(()=>{
    
    if(rowData["a_user_seq"]==""||rowData["a_list_id"]==""){
      alert("고유값필요.");
      myProps.closePopup();
    }else if(myProps.xColumnArr.list_opt_arr==undefined){
      alert("내용없음.");
      myProps.closePopup();
    }else{
      set_list_opt_arr({...myProps.xColumnArr.list_opt_arr});
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    set_list_opt_arr({
      ...list_opt_arr,
      ...{[name]: value}
    });
  };

  const getJsonData=()=>{
    let xcolumn_info_arr=ListComponentRef.current.getRows();
    let a_json_data:any={
      list_arr:[xcolumn_info_arr],
      list_opt_arr:{...list_opt_arr},
      list_num:myProps.xColumnArr.list_num
    };
    //order_id_arr 설정
    a_json_data["list_opt_arr"]["order_id_arr"]=[];
    if(a_json_data["list_opt_arr"]["order_id1"]!=""){
      let tmp_order_str:any=a_json_data["list_opt_arr"]["order_id1"];
      if(a_json_data["list_opt_arr"]["order_type1"]!=""){
        tmp_order_str+=" "+a_json_data["list_opt_arr"]["order_type1"];
      }
      a_json_data["list_opt_arr"]["order_id_arr"].push(tmp_order_str);
    }
    if(a_json_data["list_opt_arr"]["order_id2"]!=""){
      let tmp_order_str=a_json_data["list_opt_arr"]["order_id2"];
      if(a_json_data["list_opt_arr"]["order_type2"]!=""){
        tmp_order_str+=" "+a_json_data["list_opt_arr"]["order_type2"];
      }
      a_json_data["list_opt_arr"]["order_id_arr"].push(tmp_order_str);
    }
    return JSON.stringify(a_json_data);
  };

  const goWrite= (e:any) => {

    if(!confirm(pageData.confirm_msg)){
      return false;
    }

    let tmpRowData={...rowData};
    tmpRowData["a_json_data"]=getJsonData();
    setRowData(tmpRowData);
    let formJsonData={
      "data_arr":[tmpRowData],
      "is_default_val":"1",
      "is_update":"",
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/list_opt/list_opt/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.xcolumn_create_date="";
        getXcolumnFunc({"user_seq":user.user_seq});
        myProps.callback(response.data["data"]);
        myProps.closePopup();
        setTimeout(()=>{window.location.reload();},500);
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  const go_delete=(e:any)=>{
    if(!confirm("데이터를 초기화 하시겠습니까?")){
      return false;
    }
    let tmpRowData={...rowData};
    tmpRowData["a_json_data"]=getJsonData();
    setRowData(tmpRowData);
    let formJsonData={
      "data_arr":[tmpRowData],
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/list_opt/list_opt/delete',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.xcolumn_create_date="";
        getXcolumnFunc({"user_seq":user.user_seq});
        myProps.callback(response.data["data"]);
        myProps.closePopup();
        setTimeout(()=>{window.location.reload();},500);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goReSetInit=useCallback((e:any)=>{
    ListComponentRef.current.setInfoArr(
      myProps.xColumnArr.func.getCopyXcolumnListArr(
        myProps.xColumnArr.x_column_list_orig_arr
      )
    );
    set_list_opt_arr({...myProps.xColumnArr.list_opt_origin_arr});
  },[]);

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_section_arr:any=[...ListComponentRef.current.getRows()];
    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;
    setTimeout(()=>{
      ListComponentRef.current.setInfoArr(tmp_section_arr);
    },100);
  };

  const customGridCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "origin_val":origin_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
    let render_str=params.input_tag;
    if(params.key=="row_view_order"){
      render_str=(
        <div className="text-center">
          <button className="btn-s btn-dark" onClick={()=>{moveOrderNum(params.idx,"up");}} >▲</button>
          <button className="btn-s btn-dark ml-1" onClick={()=>{moveOrderNum(params.idx,"down");}} >▼</button>
        </div>
      );
    }
    return render_str;
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="90px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>왼쪽고정</th>
              <td>
                <select className="row-input" name="fix_left_num" value={list_opt_arr.fix_left_num} onChange={handleInputChange}>
                  {[0,1,2,3,4,5,6,7,8,9,10].map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th><span className="text-xs" >페이지당갯수</span></th>
              <td>
                <select className="row-input" name="num_per_page" value={list_opt_arr.num_per_page} onChange={handleInputChange}>
                  {[10,20,50,100,200,300,1000].map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>정렬1</th>
              <td>
                <select className="row-input" name="order_id1" value={list_opt_arr.order_id1} onChange={handleInputChange} 
                  style={{width:100}}>
                  {props.xColumnArr["order_id_col_arr"].map((item:any) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <select className="row-input" name="order_type1" value={list_opt_arr.order_type1} onChange={handleInputChange} 
                  style={{width:100}}>
                  <option value="">기본</option>
                  <option value="DESC">역순</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>정렬2</th>
              <td>
                <select className="row-input" name="order_id2" value={list_opt_arr.order_id2} onChange={handleInputChange} 
                  style={{width:100}}>
                  {myProps.xColumnArr["order_id_col_arr"].map((item:any) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <select className="row-input" name="order_type2" value={list_opt_arr.order_type2} onChange={handleInputChange} 
                  style={{width:100}}>
                  <option value="">기본</option>
                  <option value="DESC">역순</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>기간기준</th>
              <td>
                <select className="row-input" name="start_date_type" value={list_opt_arr.start_date_type} onChange={handleInputChange}>
                  {myProps.xColumnArr["start_date_type_arr"].map((item:any) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>기간시작</th>
              <td>
                <select className="row-input" name="start_date_num" value={list_opt_arr.start_date_num} onChange={handleInputChange}>
                  {myProps.xColumnArr["start_date_num_arr"].map((item:any) => (
                    <option value={item.value} key={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <ListComponent
          ref={ListComponentRef}
          infoArr={x_column_list_arr}
          xColumnArr={optxColumnArr}
          gridOpt={{
            isGrid:true,
            is_add_idx_num:false,
            is_add_checkbox:false,
            height:(window.innerHeight/3),
            width:"auto",
            customGridCellRenderer:customGridCellRenderer
          }}
        ></ListComponent>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>저장</button>
        <button className="btn btn-line-gray" onClick={goReSetInit}>초기화</button>
        <button className="btn btn-red" onClick={go_delete}>저장초기화</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WriteOptPopupContent;
