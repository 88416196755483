import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import StuSearchList from "./area/stu_list";
import ClassSearchList from "./area/class_list";
import TopSearchTabArea from "@/pages/comp/plan/calendar/area/left_tab";
import CalendarArea from "./area/calendar";
import StuInfoArea from "./area/stu_info";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteCounselTodoPopup from "./area/popup/write_counsel_todo";
import ViewCounselTodoOfDay from "./area/popup/view_ctodo_day";
import DateFunc from '@/lib/lyg/date_func';

function CounselCalendarPage() {
  const [select_tab, set_select_tab] = useState("stu");//stu,class
  const StuSearchListRef = useRef<any>(null);
  const ClassSearchListRef = useRef<any>(null);
  const CalendarAreaRef=useRef<any>(null);
  const StuInfoAreaRef=useRef<any>(null);
  const left_box_ref=useRef<any>(null);
  const cal_box_ref=useRef<any>(null);

  const [select_stu_arr,set_select_stu_arr]=useState<any[]>([]);
  const [content_tab,set_content_tab]=useState("calendar");//calendar,stu_info
  const [popupData,setPopupData] = useState<any>({
    isOpen:false,
    sort:"write_ctodo",//view_ctodo
    stu_info_arr:[],
    start_date:"",
    end_date:"",
    select_date:"",
    list_opt:{},
    title:"보기",
    width:"700px",
    height:"65%",
  });

  useEffect(() => {
    const height_count_loop=setInterval(()=>{
      if(cal_box_ref.current){
        if(StuSearchListRef.current){
          let tmp_height=cal_box_ref.current.offsetHeight-165;
          StuSearchListRef.current.set_height(tmp_height+"px");
        }
      }
    },100);
    return () => {
        clearInterval(height_count_loop);
    };
}, []);

  const go_search_calendar = (inData: any) => {
    set_select_stu_arr(inData.s_stu_info_arr);

    if(StuInfoAreaRef.current){
      if(inData.s_stu_info_arr.length>0){
        StuInfoAreaRef.current.setInitData({
          select_stu_seq:inData.s_stu_info_arr[0]["a_seq"],
        });
      }
    }
    // CalendarAreaRef.current.list({
    //   "s_stu_seq":inData.s_stu_user_seq
    // });
  };

  const go_search_user_list=(inData: any)=>{
    if(StuSearchListRef.current){
      StuSearchListRef.current.list(inData);
    }
    if(ClassSearchListRef.current){
      ClassSearchListRef.current.list(inData);
    }
  };

  const onSearchDateChange=(inData: any)=>{
    go_search_user_list({
      "now_page":"1",
      "s_addon_counsel_start_date":DateFunc.get_date_format(DateFunc.get_change_date(new Date(inData),'month',-1),"Y-m-01"),
      "s_addon_counsel_end_date":DateFunc.get_date_format(DateFunc.get_change_date(new Date(inData),'month',+1),"Y-m-t"),
    });
  };

  const open_write_counsel_todo=(inData: any)=>{
    //inData에 start_date:"",end_date:"" 들어온다.
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"write_ctodo",
      title:"상담일정 등록",
      stu_info_arr:select_stu_arr,
      ...inData
    });
  };
  const open_view_counsel_todo=(inData: any)=>{
    //inData에 select_date:"" 들어온다.
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"view_ctodo",
      title:"상담일정 보기",
      stu_info_arr:[],
      ...inData
    });
  };
  const show_write_diary_page=(inData:any)=>{
    let opt_obj={
      stu_seq_arr:[],
      select_stu_seq:"",
      select_cousel_seq:"",
      select_date:"",
      ...inData
    };
    
    //학생리스트 검색 변경
    if(StuSearchListRef.current){
      StuSearchListRef.current.list({
        "now_page":"1",
        "s_seq":opt_obj["stu_seq_arr"],
        "select_stu_seq":opt_obj["select_stu_seq"],
      });
    }

    //센터내용 학생상세탭으로 변경
    set_content_tab("stu_info");
    setTimeout(()=>{
      if(StuInfoAreaRef.current){
        StuInfoAreaRef.current.setInitData({
          "select_stu_seq":opt_obj.select_stu_seq,
          "select_cousel_seq":opt_obj.select_cousel_seq,
          "select_date":opt_obj.select_date,
        });
      }
    },100);
  };
  const close_write_diary_page=()=>{
    set_content_tab("calendar");
    StuSearchListRef.current.list({
      now_page:1,
      s_seq:[],
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <div className={Style.wrap}>
          <div className={Style.left_box} ref={left_box_ref}>
            <TopSearchTabArea
              select_tab={select_tab}
              set_select_tab={set_select_tab}
            ></TopSearchTabArea>
            {select_tab == "stu" &&
              <StuSearchList
                ref={StuSearchListRef}
                list={(inData: any) => { go_search_calendar(inData); }}
                set_content_tab={set_content_tab}
              ></StuSearchList>
            }
            {select_tab == "class" &&
              <ClassSearchList
                ref={ClassSearchListRef}
                list={(inData: any) => { go_search_calendar(inData); }}
              ></ClassSearchList>
            }
          </div>
          <div className={Style.cal_box} ref={cal_box_ref}>
            {content_tab=="calendar"&&
            <CalendarArea
              ref={CalendarAreaRef}
              onSearchDateChange={onSearchDateChange}
              go_search_user_list={go_search_user_list}
              show_write_counsel_todo={open_write_counsel_todo}
              open_view_counsel_todo={open_view_counsel_todo}
              show_write_diary_page={show_write_diary_page}
            ></CalendarArea>
            }
            {content_tab=="stu_info"&&
              <StuInfoArea
                ref={StuInfoAreaRef}
                close_write_diary_page={close_write_diary_page}
              ></StuInfoArea>
            }
          </div>
        </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} title_bar_style={{borderBottom:0}}
        >
        {popupData.sort=="write_ctodo"&&
          <WriteCounselTodoPopup
            stu_info_arr={popupData.stu_info_arr}
            start_date={popupData.start_date}
            end_date={popupData.end_date}
            callback={()=>{CalendarAreaRef.current.list({});go_search_user_list({});}}
            closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
          ></WriteCounselTodoPopup>
        }
        {popupData.sort=="view_ctodo"&&
          <ViewCounselTodoOfDay
            select_date={popupData.select_date}
            stu_info_arr={popupData.stu_info_arr}
            list_opt={popupData.list_opt}
            show_write_diary_page={show_write_diary_page}
            callback={()=>{CalendarAreaRef.current.list({});go_search_user_list({});}}
            closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
          ></ViewCounselTodoOfDay>
        }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default CounselCalendarPage;