import * as XLSX from "xlsx-js-style";
import style_data from "./style/index";
import strFunc from "@/lib/lyg/string";

class ExcelDownStudyOne
{
  static action(inData: any){
    let opt_obj = {
      study_title_info:null,
      student_info:null,
      study_use:null,
      ...inData,
    };
    let study_title_info = opt_obj["study_title_info"];
    let student_info = opt_obj["student_info"];
    if(strFunc.is_empty(study_title_info)){
      return false;
    }
    if(strFunc.is_empty(student_info)){
      return false;
    }
    let study_use = opt_obj["study_use"];
    let section_arr=[];
    if (study_title_info["section_arr"] && study_title_info["section_arr"].length > 0) {
      section_arr=study_title_info["section_arr"];
    }
    let study_title_str=study_title_info["a_title"];
    let detail_arr: any = [];
    if (study_use && study_use.detail_arr) {
      detail_arr = study_use.detail_arr;
    }

    let column_arr=[
      {key:"num",name:"번호",width:30},
      {key:"title",name:"제목",width:200},
      {key:"cnt",name:"수량",width:50},
      {key:"play",name:"수행",width:50},
      {key:"repeat",name:"반복",width:50},
      {key:"score",name:"점수기록",width:70},
      {key:"momo",name:"메모",width:100},
      {key:"complete",name:"완료",width:30},
      {key:"complete_date",name:"완료일",width:60},
    ];

    const wb = XLSX.utils.book_new();
  
    let data: any = [];
  
    //넓이
    var wscols = [];//{ wpx: 100 },
    for(let i=0;i<column_arr.length;i++){
      wscols.push({ wpx: column_arr[i]["width"] });
    }
    //높이
    var wsrows = [{ hpx: 20 }];
  
    //헤더
    let header_row_data: any = ["교재: "+study_title_str];
    let merge_cell_arr: any = [];//{x:0,y:0,col_span:1,row_span:1}
    merge_cell_arr.push({ x: 0, y: 0, col_span: 9, row_span: 1 });

    let abc_arr = strFunc.get_abc_arr(column_arr.length);
    data.push(header_row_data);
  
    //데이터
    let study_excel_data=this.get_excel_data_of_study({
      study_title_info:study_title_info,
      student_info:student_info,
      study_use:study_use,
      section_arr:section_arr,
      detail_arr:detail_arr,
      column_arr:column_arr,
      start_row_num:1,
    });
    data=[
      ...data,
      ...study_excel_data.data_arr
    ];
    merge_cell_arr=[
      ...merge_cell_arr,
      ...study_excel_data.merge_cell_arr
    ];
  
    // STEP 3: Create worksheet with rows; Add worksheet to workbook
    const ws = XLSX.utils.aoa_to_sheet(data);//[row,row2]
    XLSX.utils.book_append_sheet(wb, ws, "study");
  
    //넓이
    ws['!cols'] = wscols;
    //높이
    ws['!rows'] = wsrows;
  
    //합치기
    let merge_cell_data_arr = [];
    for (let i = 0; i < merge_cell_arr.length; i++) {
      let merge_cell = merge_cell_arr[i];
      if (merge_cell["row_span"] >= 2||merge_cell["col_span"] >= 2) {
        let plus_row_num = merge_cell.row_span - 1;
        let plus_x_num = merge_cell.col_span - 1;
        merge_cell_data_arr.push({
          s: { r: merge_cell.y, c: merge_cell.x },
          e: { r: merge_cell.y + plus_row_num, c: merge_cell.x+plus_x_num },
        });
      }
    }
    if (merge_cell_data_arr.length > 0) {
      ws["!merges"] = merge_cell_data_arr;
    }
  
    //스타일
    let all_row_i = data.length;
    for (let row_i = 0; row_i < all_row_i; row_i++) {
      for (let i = 0; i < abc_arr.length; i++) {
        let cell_style: any = {
          fill:{ fgColor: { rgb: "FFFFFF" } },
          border: style_data.border_1,
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true
          },
        };
        if (row_i == 0 || i == 0) {
          cell_style["fill"] = { fgColor: { rgb: "FFFFFF" } };
          for (let j = 0; j < merge_cell_arr.length; j++) {
            let merge_cell = merge_cell_arr[j];
            if (merge_cell.x == i && merge_cell.y == row_i) {
              if(merge_cell.x==0&&merge_cell.y==0){

              }else{
                cell_style["fill"] = { fgColor: { rgb: "EEEEEE" } };
              }
            }
          }
        }
        if (ws[abc_arr[i] + (row_i + 1)]) {
          ws[abc_arr[i] + (row_i + 1)].s = cell_style;
        }
      }
    }
  
    // STEP 4: Write Excel file to browser
    XLSX.writeFile(wb, "study_"+study_title_str+".xlsx");
  }
  static get_excel_data_of_study(inData:any){
    let opt_obj = {
      study_title_info:null,
      student_info:null,
      study_use:null,
      section_arr:[],
      detail_arr:[],
      column_arr:[],
      start_row_num:1,
      ...inData,
    };
    let result_data:any={
      data_arr:[],
      merge_cell_arr:[],
      section_head_row_num_arr:[],
    };
    let row_num=opt_obj["start_row_num"];
    let study_title_info=opt_obj["study_title_info"];
    let student_info=opt_obj["student_info"];
    let study_use=opt_obj["study_use"];
    let section_arr=opt_obj["section_arr"];
    let detail_arr=opt_obj["detail_arr"];
    let column_arr=opt_obj["column_arr"];
    let column_arr_len=column_arr.length;

    let default_detail_info = {
      a_stu_seq: student_info["a_seq"],
      a_study_title_seq: study_title_info["a_seq"],
      a_seq: 0,
      a_reserve_date: "",
      a_amount: "",
      a_success_amount: "",
      a_is_success: "",
      a_success_date: "",
      a_text_score: "",
      a_repeat_cnt: "",
      a_success_record: "",
      a_memo: "",
    };

    for(let sec_i=0;sec_i<section_arr.length;sec_i++){
      let section_info=section_arr[sec_i];
      let section_title=section_info["a_title"];
      let section_idx_num=sec_i+1;
      //섹션 제목
      result_data.data_arr.push([section_idx_num+". "+section_title]);
      result_data.merge_cell_arr.push({x:0,y:row_num,col_span:column_arr_len,row_span:1});
      row_num++;
      //디테일 제목
      let row_data=[];
      for(let col_i=0;col_i<column_arr_len;col_i++){
        let row_col=column_arr[col_i];
        row_data.push(row_col["name"]);
      }
      result_data.data_arr.push(row_data);
      row_num++;
      //디테일 내용
      let study_arr = [];
      if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
        for (let i = 0; i < study_title_info["study_arr"].length; i++) {
          let study_info = study_title_info["study_arr"][i];
          if (study_info["a_section_seq"] == section_info["a_seq"]) {
            study_arr.push(study_info);
          }
        }
      }
      for(let study_i=0;study_i<study_arr.length;study_i++){
        let study_info=study_arr[study_i];
        let detail_info = this.get_use_detail_by_study_info(study_info,detail_arr);
        let is_exist_detail = false;
        if (detail_info == null) {
          detail_info = {
            ...default_detail_info
          };
        } else {
          is_exist_detail = true;
        }
        let row_data=[];
        for(let col_i=0;col_i<column_arr_len;col_i++){
          let row_col=column_arr[col_i];
          let key=row_col["key"];
          let value:any="";
          if(key=="num"){
            value=study_i+1;
          }else if(key=="title"){
            value=study_info["a_title"];
          }else if(key=="cnt"){
            value=study_info["a_amount_num"]+" "+study_info["a_amount_sort"];
          }else if(key=="play"){
            value=detail_info["a_amount"];
          }else if(key=="repeat"){
            value=detail_info["a_repeat_cnt"];
          }else if(key=="score"){
            value=detail_info["a_success_record"];
          }else if(key=="momo"){
            value=detail_info["a_memo"];
          }else if(key=="complete"){
            value="";
            if(detail_info["a_is_success"]=="1"){
              value="완";
            }
          }else if(key=="complete_date"){
            if(detail_info["a_is_success"]=="1"){
              value=detail_info["a_complete_date"];
            }
          }
          row_data.push(value);
        }
        result_data.data_arr.push(row_data);
        row_num++;
      }
    }
    
    return result_data;
  }

  static get_use_detail_by_study_info(study_info: any,detail_arr:any) {
    let detail_info = null;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == study_info["a_seq"]) {
        detail_info = detail_arr[i];
      }
    }
    return detail_info;
  };
}

export default ExcelDownStudyOne;