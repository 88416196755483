import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import DateFunc from '@/lib/lyg/date_func';
import getXcolumnJson from "./xcolumn/list";

function ManageCategoryPopup(props:any) {
  let myProps:any={
    closePopup:()=>{},
    callBack:()=>{},
    ...props
  };

  const xColumnArr=getXcolumnJson();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "is_need_count":"",
      "is_no_limit":"1",
    }
  });
  const listAggridRef= useRef<any>();
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [refresh,set_refresh]=useState(false);

  useEffect(()=>{
    
  },[]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        setTimeout(()=>{
          setOrderNumAuto();
        },100);
      }else{
        
      }
    });
  };

  const goDelete = (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }

    let remove_rows=[];
    let del_rows=[];
    for(let i=0;i<selected_row.length;i++){
      if(strFunc.is_empty(selected_row[i]["a_seq"])){
        remove_rows.push(selected_row[i]);
      }else{
        del_rows.push(selected_row[i]);
      }
    }

    if(remove_rows.length>0){
      removeRowByData(remove_rows);
      return false;
    }
    if(del_rows.length==0){
      return false;
    }

    if(!confirm("삭제 하시겠습니까?")){
      return false;
    }

    let del_form_data={
      "data_arr":del_rows
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("삭제 되었습니다.");
        list({});
        myProps.callBack();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const removeRowByData=(remove_rows:any[])=>{
    const all_rows=listAggridRef.current.getRows();
    let select_rows=remove_rows;
    let now_rows:any=[];
    for(let i=0;i<all_rows.length;i++){
      let is_select=false;
      for(let j=0;j<select_rows.length;j++){
        if(select_rows[j].a_order_num==all_rows[i].a_order_num){
          is_select=true;
        }
      }
      if(is_select==false){
        now_rows.push(all_rows[i]);
      }
    }
    setInfoArr(now_rows);
    listAggridRef.current.setInfoArr(now_rows);
    setTimeout(()=>{
      setOrderNumAuto();
    },100);
  };

  const goWrite=()=>{
    let selected_row=listAggridRef.current.getRows();//listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("수정 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/write',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("저장 되었습니다.");
        list({});
        myProps.callBack();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goAddRow=()=>{
    const all_rows=listAggridRef.current.getRows();
    let add_row={
      a_seq:"",
      a_title:"",
      a_is_use:"1",
      a_order_num:all_rows.length+1,
    };
    listAggridRef.current.addRowData({
      "data":[add_row],
    });
  };

  const setOrderNumAuto=()=>{
    let allRows=listAggridRef.current.getRows();
    for(let i=0;i<allRows.length;i++){
      allRows[i]["a_order_num"]=i+1;
    }
    listAggridRef.current.setInfoArr(allRows);
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    return render_str;
  }, []);

  return (
    <div>
      <div className="btn-box-left relative h-8">
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={()=>{goAddRow();}}>추가</button>
          <button className="btn btn-dark" onClick={()=>{goWrite();}}>저장</button>
          <button className="btn btn-red" onClick={goDelete}>삭제</button>
          <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:true,
          floatingFilter:false,
          is_add_rowDrag:true,
          is_grid:true,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer,
          onRowDragEnd:setOrderNumAuto
        }}
        ></ListAggrid>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ManageCategoryPopup;
