import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";

export interface IClassUser{
  a_class_seq:string,
  a_user_seq:string,
  a_user_name:string,
  a_type:"student"|"teacher",
  a_is_main:""|"1",
  is_checked:boolean,
}

export interface IProps {
  getClassUserList: () => IClassUser[];
  getCheckedClassUserList:() => IClassUser[];
  setOriginClassUsers:(classUsers:IClassUser[])=>void;
}

const TeacherList = forwardRef<IProps>((props, ref) => {
  const [originClassUsers,setOriginClassUsers]=useState<IClassUser[]>([]);
  const [classUserList,setClassUserList]=useState<IClassUser[]>([]);

  useEffect(()=>{
    
    getUserList();
  },[originClassUsers]);

  const getUserList=()=>{
    let uListFormData={
      "s_grade":["teacher","master","staff"],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',uListFormData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassUserByUserList(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const setClassUserByUserList=(user_list:any)=>{
    let tmpClassUsers:IClassUser[]=[];
    
    for(let i=0;i<user_list.length;i++){
      let user=user_list[i];

      //저장데이터 있다면 체크
      let tmp_is_checked=false;
      for(let j=0;j<originClassUsers.length;j++){
        if(user["a_seq"]==originClassUsers[j].a_user_seq){
          tmp_is_checked=true;
        }
      }

      tmpClassUsers.push({
        a_class_seq:"",
        a_user_seq:user["a_seq"],
        a_user_name:user["a_user_name"],
        a_type:"teacher",
        a_is_main:"",
        is_checked:tmp_is_checked,
      });
    }
    setClassUserList(tmpClassUsers);
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let checked=target.checked;
    let  tmpClassUsers=[...classUserList]
    for(let i=0;i<tmpClassUsers.length;i++){
      let class_user=tmpClassUsers[i];
      if(class_user.a_user_seq==value){
        class_user.is_checked=checked;
      }
    }
    setClassUserList(tmpClassUsers);
  };

  const getClassUserList=()=>{
    return classUserList;
  };
  const getCheckedClassUserList=()=>{
    let tmpClassUsers:IClassUser[]=[];
    for(let i=0;i<classUserList.length;i++){
      if(classUserList[i].is_checked){
        tmpClassUsers.push(classUserList[i]);
      }
    }
    return tmpClassUsers;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getClassUserList,
    getCheckedClassUserList,
    setOriginClassUsers
  }));

  let  classUserTags=classUserList?.map((item,idx)=>{
    let is_checked=item.is_checked;
    return (
      <div key={idx} className="m-2">
        <label className="text-base" >
          <input type="checkbox" value={item.a_user_seq} name="a_user_seq" checked={is_checked} 
            onChange={handleInputChange} className="mr-2" />
          {item.a_user_name}
        </label>
      </div>
    );
  });

  return (
    <div className="flex flex-row">
      {classUserTags}
    </div>
  );
});

export default TeacherList;