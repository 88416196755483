import Style from "./css/style.module.css";
import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import ImgArea from "./area/img";
import AudioArea from "./area/audio";
import VideoArea from "./area/video";

function HomeWorkFileArea(props: any) {
  let myProps = {
    stu_info: {},
    select_date: "",
    s_par_id:"home_work_file",//plan_study_file
    file_arr: [],
    callback: () => { },
    is_show_img: true,
    is_show_audio: true,
    is_show_video: true,
    ...props
  };
  let file_arr = myProps.file_arr;

  useEffect(() => {
    
  }, []);

  const goDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("파일을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        myProps.callback();
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  return (
    <div className={Style.home_work_wrap}>
      <div className={Style.title_wrap}>
        제출 ({file_arr.length})
      </div>
      {myProps.is_show_img &&
        <ImgArea
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          s_par_id={myProps.s_par_id}
          file_arr={file_arr}
          goDelete={goDelete}
          callback={() => { myProps.callback(); }}
          Style={Style}
        ></ImgArea>
      }
      {myProps.is_show_audio &&
        <AudioArea
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          file_arr={file_arr}
          goDelete={goDelete}
          callback={() => { myProps.callback(); }}
          Style={Style}
        ></AudioArea>
      }
      {myProps.is_show_video &&
        <VideoArea
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          file_arr={file_arr}
          goDelete={goDelete}
          callback={() => { myProps.callback(); }}
          Style={Style}
        ></VideoArea>
      }
    </div>
  );
};
export default HomeWorkFileArea;