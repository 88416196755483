import { Link } from 'react-router-dom';
import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';

function UpStuGrade(){
  let user=useSelector((state:any) => state.user);

  useEffect(() => {
    
  }, []);

  const goUpGradeStudent=(inData:any)=>{
    if(inData==undefined){inData={};};
    let optObj={
     "s_stu_state":"",
     "grade_up_down":"up",
     ...inData
    }
    let up_down_msg="올리";
    if(optObj["grade_up_down"]=="down"){
      up_down_msg="내리";
    }
    if(!confirm("학생들의 학년을 "+up_down_msg+"시겠습니까?")){
      return false;
    }

    let form_json_data={
      "s_stu_state":optObj["s_stu_state"],
      "grade_up_down":optObj["grade_up_down"],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/stu_up_grade',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="view-table-div">
          <table>
            <colgroup>
              <col width="150px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>재원생만</th>
                <td>
                  <div>
                    <div className="p-2">
                      <button className="btn btn-red" 
                        onClick={()=>{goUpGradeStudent({"s_stu_state":"now","grade_up_down":"up"});}}
                      >전체 1학년 올리기</button>
                    </div>
                    <div className="p-2">
                      <button className="btn btn-dark" 
                        onClick={()=>{goUpGradeStudent({"s_stu_state":"now","grade_up_down":"down"});}} style={{background:"green"}}
                      >전체 1학년 내리기</button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>전체원생</th>
                <td>
                  <div>
                    <div className="p-2">
                      <button className="btn btn-red" 
                        onClick={()=>{goUpGradeStudent({"s_stu_state":"","grade_up_down":"up"});}}
                      >전체 1학년 올리기</button>
                    </div>
                    <div className="p-2">
                      <button className="btn btn-dark" 
                        onClick={()=>{goUpGradeStudent({"s_stu_state":"","grade_up_down":"down"});}} style={{background:"green"}}
                      >전체 1학년 내리기</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CompLayout>
  );
}

export default UpStuGrade;