import strFunc from "@/lib/lyg/string";

class TrTdDataFunc {
  static get_ordered_day_num_arr_of_td_data_arr = (td_data_arr: any) => {
    let day_num_str_arr: any = [];
    for (let i = 0; i < td_data_arr.length; i++) {
      let td_data = td_data_arr[i];
      if (strFunc.str_in_array(td_data["day_num_arr_str"], day_num_str_arr) == -1) {
        day_num_str_arr.push(td_data["day_num_arr_str"]);
      }
    }
    day_num_str_arr.sort();
    let new_td_data_arr = [];
    for (let day_i = 0; day_i < day_num_str_arr.length; day_i++) {
      let day_num_str = day_num_str_arr[day_i];
      for (let i = 0; i < td_data_arr.length; i++) {
        let td_data = td_data_arr[i];
        if (td_data["day_num_arr_str"] == day_num_str) {
          new_td_data_arr.push(td_data);
        }
      }
    }
    return new_td_data_arr;
  };
  static get_table_tr_td_arr = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      teacher_arr: [],
      room_arr: [],
      table_option: {},
      is_show_th_stu_len:false,
      ...inData,
    };
    let table_option = opt_obj.table_option;
    let table_th_arr: any = [];//[[{x,y,col_span,row_span,td_data,is_view,title,lecture_info},..]]
    let table_tr_td_arr: any = [];//[[{x,y,col_span,row_span,td_data,is_view},..]]
    //th_arr 얻기
    let th_x = 0;
    for (let lec_i = 0; lec_i < table_option.lecture_arr.length; lec_i++) {
      let lecture_info = table_option.lecture_arr[lec_i];
      let col_tot_cnt = 1;
      if (table_option.lecture_col_span_arr[lecture_info["a_seq"]]) {
        col_tot_cnt = table_option.lecture_col_span_arr[lecture_info["a_seq"]];
      }
      for (let i = 0; i < col_tot_cnt; i++) {
        let th_row: any = {
          x: th_x,
          y: 0,
          col_span: 1,
          row_span: 1,
          td_data: null,
          is_view: true,
          title: "",
          lecture_info: lecture_info,
        };
        if (i == 0) {
          th_row.title = lecture_info["a_name"];
          if(!strFunc.is_empty(lecture_info["a_room_name"])){
            th_row.title += " [" + lecture_info["a_room_name"] + "]";
          }
          if(opt_obj.is_show_th_stu_len&&lecture_info["student_arr"]){
            th_row.title += " [" + lecture_info["student_arr"].length + "명]";
          }
          th_row.title += " (" + lecture_info["a_main_teacher"] + ")";
          th_row.col_span = col_tot_cnt;
        } else {
          th_row.is_view = false;
        }
        table_th_arr.push(th_row);
        th_x++;
      }
    }
    table_option.table_th_arr = table_th_arr;

    //tr_td_arr 얻기
    for (let row_num = 0; row_num < table_option.split_time_m_arr.length; row_num++) {
      let split_m = table_option.split_time_m_arr[row_num];
      table_tr_td_arr.push([]);
      let row_x = 0;
      for (let lec_i = 0; lec_i < table_option.lecture_arr.length; lec_i++) {
        let lecture_info = table_option.lecture_arr[lec_i];
        let col_tot_cnt = 1;

        if (table_option.lecture_col_span_arr[lecture_info["a_seq"]]) {
          col_tot_cnt = table_option.lecture_col_span_arr[lecture_info["a_seq"]];
        }
        let row_range_data_arr=[];
        for (let td_i = 0; td_i < table_option.td_data_arr.length; td_i++) {
          let td_data = table_option.td_data_arr[td_i];
          if (td_data["lecture_seq"] == lecture_info["a_seq"]) {
            if (td_data["range_start_time_m"] <= split_m&&split_m<td_data["range_end_time_m"]) {
              row_range_data_arr.push(td_data);
            }
          }
        }
        //정렬 요일별
        if (row_range_data_arr.length > 0) {
          row_range_data_arr = this.get_ordered_day_num_arr_of_td_data_arr(row_range_data_arr);
        }

        let td_diff_col_num = col_tot_cnt - row_range_data_arr.length;
        let td_diff_col_span = td_diff_col_num + 1;
        for (let i = 0; i < col_tot_cnt; i++) {
          let tr_td_row: any = {
            x: row_x,
            y: row_num,
            col_span: 1,
            row_span: 1,
            td_data: null,
            is_view: true,
          };

          //td_data 얻기
          if (row_range_data_arr[i] != undefined) {
            if(row_range_data_arr[i]["start_time_m"]==split_m){
              tr_td_row["td_data"] = row_range_data_arr[i];
              tr_td_row["col_span"] = row_range_data_arr[i]["col_span"];
              tr_td_row["row_span"] = row_range_data_arr[i]["row_span"];
            }
          }
          //is_view 얻기 (이전 row들 중 row_span이 있다면 false)
          for (let pre_row = 0; pre_row < row_num; pre_row++) {
            let remain_row_num = table_tr_td_arr[pre_row][row_x]["row_span"];
            remain_row_num = remain_row_num - (row_num - pre_row);
            if (remain_row_num > 0) {
              tr_td_row["is_view"] = false;
            }
          }
          //col_span얻기
          tr_td_row["col_span"] = 1;
          if (row_range_data_arr.length == 0) {
            if (i == 0) {
              tr_td_row["col_span"] = col_tot_cnt;
            } else {
              tr_td_row["is_view"] = false;
            }
          } else {
            if (col_tot_cnt - i == td_diff_col_span) {
              //합칠컬럼
              tr_td_row["col_span"] = td_diff_col_span;
            } else if (col_tot_cnt - i < td_diff_col_span) {
              //합친 이후는 안보이게
              tr_td_row["is_view"] = false;
            }
          }
          table_tr_td_arr[row_num].push(tr_td_row);
          row_x++;
        }
      }
    }

    table_option.table_tr_td_arr = table_tr_td_arr;

    return table_option;
  };
}
export default TrTdDataFunc;