import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import style from "./css/style.module.css";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

const WeekSearch=forwardRef((props:any, ref) => {
  const [searchDate,setSearchDate] = useState<string>(
    props.search_date?props.search_date:DateFunc.get_date_format(new Date(),"Y-m-d")
  );

  let pre_month_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(searchDate),'month',-1),"Y-m-d");
  let after_month_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(searchDate),'month',+1),"Y-m-d");

  let pre_week_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(searchDate),'day',-7),"Y-m-d");
  let after_week_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(searchDate),'day',+7),"Y-m-d");

  const handleDateChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    let change_searchDate=searchDate;
    let tmp_year=searchDate.substring(0,4);
    let tmp_month=searchDate.substring(5,7);
    let tmp_day=searchDate.substring(8,10);
    let tmp_date_json:any=DateFunc.get_date_json(new Date(searchDate));
    if(name=="s_month"){
      tmp_month=value;
    }else if(name=="s_year"){
      tmp_year=value;
    }
    if(parseInt(tmp_day)>tmp_date_json.last_day){
      tmp_day=strFunc.str_pad({"str":tmp_date_json.last_day,"pad_length":2});
    }
    change_searchDate=tmp_year+"-"+tmp_month+"-"+tmp_day;

    setSearchDate(change_searchDate);
    if(props.onSearchDateChange){
      props.onSearchDateChange(change_searchDate);
    }
  };
  const onChangeDateClick=(day:string,sort:string)=>{
    day=strFunc.str_pad({"str":day,"pad_length":2});
    let tmp_year=searchDate.substring(0,4);
    let tmp_month=searchDate.substring(5,7);
    let tmp_day=searchDate.substring(8,10);
    let change_searchDate=searchDate;
    
    if(sort=="pre"){
      let pre_month_json:any=DateFunc.get_date_json(
        DateFunc.get_change_date(new Date(tmp_year+"-"+tmp_month+"-01"),'month',-1)
      );
      tmp_year=pre_month_json.Y;
      tmp_month=strFunc.str_pad({"str":pre_month_json.m,"pad_length":2});
    }else if(sort=="after"){
      let after_month_json:any=DateFunc.get_date_json(
        DateFunc.get_change_date(new Date(tmp_year+"-"+tmp_month+"-01"),'month',+1)
      );
      
      tmp_year=after_month_json.Y;
      tmp_month=strFunc.str_pad({"str":after_month_json.m,"pad_length":2});
    }

    change_searchDate=tmp_year+"-"+tmp_month+"-"+day;

    setSearchDate(change_searchDate);
    if(props.onSearchDateChange){
      props.onSearchDateChange(change_searchDate);
    }
  };

  let year_num_arr=[];
  let select_year_num=parseInt(searchDate.substring(0,4));
  for(let i=select_year_num-50;i<=select_year_num+10;i++){
    year_num_arr.push(i);
  }
  const year_options_tag=year_num_arr.map((item,idx)=>{
    return (
      <option value={item} key={idx}>{item}</option>
    );
  });

  let month_num_arr=[];
  for(let i=1;i<=12;i++){
    month_num_arr.push(i);
  }
  const month_options_tag=month_num_arr.map((item,idx)=>{
    let pad_item=strFunc.str_pad({"str":item,"pad_length":2});
    return (
      <option value={pad_item} key={idx}>{pad_item}</option>
    );
  });

  const getDayOfWeekTags=()=>{
    let tmp_year=searchDate.substring(0,4);
    let tmp_month=searchDate.substring(5,7);
    let search_date_json:any=DateFunc.get_date_json(new Date(searchDate));
    let first_day=search_date_json.first_day_of_week;
    let last_day=search_date_json.last_day;
    let tr_cnt=Math.ceil((search_date_json.last_day+search_date_json.first_day_of_week)/7);
    let tmp_select_day=parseInt(searchDate.substring(8,10));

    let pre_month_json:any=DateFunc.get_date_json(
      DateFunc.get_change_date(new Date(tmp_year+"-"+tmp_month+"-01"),'month',-1)
    );

    let day_arr_arr:any=[];
    let select_week=0;
    let day_idx=1;
    for(let tr_i=0;tr_i<tr_cnt;tr_i++){
      day_arr_arr.push([]);
      for(let i=0;i<7;i++){
        if(i>=first_day&&day_idx==1){
          day_arr_arr[tr_i].push(day_idx);
          day_idx++;
        }else if(day_idx<=last_day&&day_idx!=1){
          day_arr_arr[tr_i].push(day_idx);
          day_idx++;
          
        }
        if((day_idx-1)==tmp_select_day){
          select_week=tr_i;
        }
      }
    }
    let select_week_days=day_arr_arr[select_week];
    
    let row_date_arr=[];//{"text":"","value":"","sort":""} sort: pre,select,after
    let day_tag_i=0;
    let other_day_i=0;
    let start_day=0;
    if(select_week==0){
      start_day=first_day;
    }

    for(let i=0;i<7;i++){
      if(i>=start_day&&day_tag_i<select_week_days.length){
        row_date_arr.push({
          "text":select_week_days[day_tag_i],
          "value":select_week_days[day_tag_i],
          "sort":"select"
        });
        day_tag_i++;
      }else{
        //이번주 날짜가 아닌경우
        other_day_i++;
        if(select_week==0){
          //첫째주
          row_date_arr.push({
            "text":pre_month_json.last_day-(first_day-i-1),
            "value":other_day_i,
            "sort":"pre"
          });
        }else{
          //마지막주
          row_date_arr.push({
            "text":other_day_i,
            "value":other_day_i,
            "sort":"after"
          });
        }
      }
    }

    let day_of_week_text_arr=["일","월","화","수","목","금","토","일"];

    let dayTags=row_date_arr.map((item,idx)=>{
      let day_box_class=style.day_box;
      if(item.sort!="select"){
        day_box_class+=" "+style.day_box_other;
      }else if(item.value==parseInt(searchDate.substring(8,10))){
        day_box_class+=" "+style.day_box_active;
      }

      let box_num_style:any={};

      let day_week_style:any={
        
      };
      if(idx==0){
        box_num_style["color"]="#ff6c22";
        day_week_style["color"]="#ff6c22";
      }else if(idx==6){
        box_num_style["color"]="#2196f3";
        day_week_style["color"]="#2196f3";
      }

      return (
        <div key={idx} className={day_box_class} onClick={()=>{
          onChangeDateClick(item.text+"",item.sort);
        }}>
          <div className={style.day_box_num} style={box_num_style}>{item.text}</div>
          <div className={style.day_box_day_week} style={day_week_style}>{day_of_week_text_arr[idx]}</div>
        </div>
      );
    });
    return dayTags;
  };
  let dayTags=getDayOfWeekTags();

  return (
    <div className={style.week_search_wrap} >
      <div className={style.week_search_box}>
        <span className={style.year_span}>
          <select name="s_year" value={searchDate.substring(0,4)} onChange={handleDateChange}>
            {year_options_tag}
          </select>년
        </span>
        {/* <a className="btn-s btn-dark p-0" onClick={()=>{setSearchDate(pre_month_date);}}>◀</a> */}
        <a className="btn-s btn-dark" onClick={()=>{setSearchDate(pre_week_date);}}>◁</a>
        <span className={style.month_span+" "+style.now_month}>
          <select name="s_month" value={searchDate.substring(5,7)} onChange={handleDateChange}>
            {month_options_tag}
          </select> 월
        </span>
        <a className="btn-s btn-dark" onClick={()=>{setSearchDate(after_week_date);}}>▷</a>
        {/* <a className="btn-s btn-dark p-0" onClick={()=>{setSearchDate(after_month_date);}}>▶</a> */}
      </div>
      <div className={style.week_list_box}>
        {dayTags}
      </div>
    </div>
  );
});

export default WeekSearch;