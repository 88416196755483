import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import getXcolumnJson from "../../list/xcolumn/list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudent from "@/pages/comp/basic/student/popup/find_multy";
import { default_info,default_detail_info } from "@/pages/comp/edu/study_diary/list/area/write/data/default_data";
import WriteArea from "@/pages/comp/edu/study_diary/list/area/write/area/write_area";

function WritePopupContent(props: any) {
  let myProps = {
    isUpdate:false,
    stu_seq:"",
    stu_name:"",
    rowData:{},
    ...props,
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const fileWriteOneRef = useRef<any>(null);
  
  const init_data = {
    ...default_info,
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_seq,
    "a_stu_name": myProps.stu_name,
    "a_study_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_writer_seq": user.user_seq,
    "a_writer": user.user_name,
    "detail_arr":[
      {
        ...default_detail_info,
        a_seq:"1",
        a_order_num:"1",
      },
      {
        ...default_detail_info,
        a_seq:"2",
        a_order_num:"2",
        a_sort:"homework",
      }
    ],
  };
  const [rowData, setRowData] = useState({
    ...init_data,
    file_obj: null,
  });
  const [comment_list, set_comment_list] = useState([]);
  const last_row_info_ref=useRef(rowData);
  const [refresh, set_refresh] = useState(false);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    isUpdate: false,
    s_except_user_seq: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (props.isUpdate) {
      let changeRowData: any = {
        ...rowData,
        ...props.rowData
      };
      setRowData(changeRowData);
      list({row_data:changeRowData});
      get_comment_list({
        row_data: changeRowData,
      });
    }else{
      get_writer_info_by_ajax();
    }
  }, []);

  const list = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData = {
      "s_pri_arr": [row_pri_val],
      "s_addon_detail": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_row_data={
              ...init_data,
              ...response.data["data"]["info_arr"][0]
            };
            if(tmp_row_data.detail_arr.length==0){
              tmp_row_data.detail_arr.push({
                ...default_detail_info,
                a_seq:1,
                a_order_num:1,
              });
              tmp_row_data.detail_arr.push({
                ...default_detail_info,
                a_seq:2,
                a_order_num:2,
                a_sort:"homework",
              });
            }
            setRowData(tmp_row_data);
            last_row_info_ref.current=tmp_row_data;
          }
        } else {

        }
      });
  };
  
  const get_comment_list = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData = {
      "s_par_id": "study_diary",
      "s_par_seq": row_pri_val,
      "order_id": "a_create_date",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_writer_info_by_ajax=()=>{
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }
    if (props.isUpdate) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr":[user.user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            let writer_info=response.data["data"]["info_arr"][0];
            if (props.isUpdate==false) {
              setRowData({
                ...rowData,
                a_subject_name:writer_info["a_main_subject"],
              });
            }
          }
        } else {

        }
      });
  };

  const setStudentData = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData
    };
    if (optObj["info_arr"].length == 0) {
      alert("학생 선택이 없습니다.");
      return false;
    }
    let stu_info = optObj["info_arr"][0];
    setRowData({
      ...rowData,
      "a_stu_seq": stu_info["a_seq"],
      "a_stu_name": stu_info["a_user_name"],
    });
  };

  const openFIndStudentPopup = () => {
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "find",//view
      title: "학생 찾기",
    });
  };

  const goWrite = useCallback((e: any) => {
    if (rowData.a_stu_seq == "") {
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };

    let formJsonData = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          last_row_info_ref.current=response.data["data"][0];
          let childFileInput:any = null;
          if(fileWriteOneRef.current){
            childFileInput=fileWriteOneRef.current.getFileInput();
          }
          let priVal = xColumnArr.func.getPriValByDataRow({
            xColumnArr: xColumnArr,
            rowData: response.data["data"][0]
          });
          if (childFileInput!=null&&childFileInput.current.value != "") {
            fileWriteOneRef.current.goWrite({
              "a_par_seq": priVal
            });
          } else {
            props.callback({select_seq:priVal});
            props.closePopup();
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  return (
    <div>
      <div>
        <WriteArea
          rowData={rowData}
          setRowData={setRowData}
          isUpdate={myProps.isUpdate}
          openFIndStudentPopup={openFIndStudentPopup}
          is_find_stu={true}
          comment_list={comment_list}
          refresh_comment={get_comment_list}
          set_refresh={()=>{set_refresh(!refresh);}}
        ></WriteArea>
      </div>
      {
        rowData.a_seq == "" &&
        <WriteInputFrame
          ref={fileWriteOneRef}
          init_data={{
            a_par_id: xColumnArr.table,
            a_par_seq: rowData.a_ymd + "," + rowData.a_seq,
          }}
          callback={() => { 
            props.callback({select_seq:last_row_info_ref.current.a_ymd + "," + last_row_info_ref.current.a_seq});
            myProps.closePopup(); 
          }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      {
        rowData.a_seq != "" &&
        <FileListWriteFrame
          listOpt={
            {
              s_par_id: xColumnArr.table,
              s_par_seq: xColumnArr.func.getPriValByDataRow({
                xColumnArr: xColumnArr,
                rowData: rowData
              }),
              s_start_date: rowData.a_study_date
            }
          }
        ></FileListWriteFrame>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "find" &&
            <FindStudent isUpdate={popupData.isUpdate}
              callBackData={(inData: any) => { setStudentData(inData); }}
              listOpt={{
                s_except_user_seq: popupData.s_except_user_seq
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindStudent>
          }

        </LayerPopup>
      }
    </div>
  );
}

export default WritePopupContent;
