import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";
import ViewFrameContent from "./area/view";

function ListPage() {
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_corse_seq: "",
    s_is_agree: "",
    s_writer_like: "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const ViewFrameContentRef = useRef<any>(null);
  useEffect(() => {

  }, []);

  const list = (inData: any) => {
    let form_json_data={
      ...listOpt,
      ...inData,
    };
    form_json_data["sc"]=listAggridRef.current.getListSc();
    setListOpt(form_json_data);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/request/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/request/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
    if (ViewFrameContentRef.current) {
      ViewFrameContentRef.current.setInitData({
        row_data: params.data,
        isUpdate: true,
      });
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <table style={{ width: "100%" }}>
          <colgroup>
            <col width={"50%"} />
            <col width={"*"} />
          </colgroup>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <div style={{ minWidth: 230 }} >
                  <SearchArea
                    listOpt={listOpt}
                    list={list}
                    goDelete={goDelete}
                  ></SearchArea>
                  <ListAggrid
                    ref={listAggridRef}
                    infoArr={infoArr}
                    xColumnArr={{ ...xColumnArr }}
                    list={list}
                    gridOpt={{
                      fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                      is_idx_num: true,
                      is_add_checkbox: true,
                      floatingFilter: false,
                      onGridReady: () => {
                        list({});
                      },
                      onCellClicked: cellClickedListener,
                      cellRenderer: cellRenderer,
                    }}
                  ></ListAggrid>
                  <Paging now_page={listOpt.now_page}
                    num_per_page={listOpt.num_per_page}
                    total_rec={countInfo.tot}
                    onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
                </div>
              </td>
              <td style={{ verticalAlign: "top", paddingLeft: 10 }}>
                <div style={{ minWidth: 300, paddingTop: 27 }} >
                  <ViewFrameContent
                    ref={ViewFrameContentRef}
                    list={(listOpt: any) => { list(listOpt); }}
                  ></ViewFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default ListPage;