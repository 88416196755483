import Style from "./css/style.module.css";
import LectureTimeFunc from "./func/lecture_time_func";
import strFunc from "@/lib/lyg/string";
import TdBoxArea from "./content/td_box";

function LectureTimeTableArea(props: any) {
  let myProps = {
    lecture_arr: [],
    teacher_arr:[],
    room_arr:[],
    is_teacher_nickname:false,
    is_show_th_stu_len:false,
    stu_grade_sort:"",
    is_show_attend:false,
    ...props
  };

  let table_option = LectureTimeFunc.get_table_option_by_lecture_arr({
    lecture_arr: myProps.lecture_arr,
    teacher_arr:myProps.teacher_arr,
    room_arr:myProps.room_arr,
    is_teacher_nickname:myProps.is_teacher_nickname,
    is_show_th_stu_len:myProps.is_show_th_stu_len,
  });
  let td_box_style = { height: myProps.split_height, lineHeight: myProps.split_height + "px" };

  const getThTrTag = () => {
    let th_tr_tag = (
      <tr>
        <th>
          <div className={Style.time_table_th_box} >
            시간
          </div>
        </th>
        {
          table_option.table_th_arr.map((th_info: any, th_idx: number) => {
            if(th_info.is_view==false){
              return "";
            }
            return (
              <th key={th_idx} colSpan={th_info.col_span}>
                <div className={Style.time_table_th_box}>
                  {th_info.title}
                </div>
              </th>
            );
          })
        }
      </tr>
    );
    return th_tr_tag;
  };

  const getBodyTdTag = (inData: any) => {
    let opt_obj = {
      row_time_min: 0,
      row_num:0,
      ...inData
    };

    if(table_option.table_tr_td_arr[opt_obj.row_num]==undefined){
      return "";
    }

    let td_tags = table_option.table_tr_td_arr[opt_obj.row_num].map((tr_td_row: any, tr_td_idx: number) => {
      if(tr_td_row["is_view"]==false){
        return "";
      }
      let lecture_info=null;
      if(tr_td_row["td_data"]){
        for(let i=0;i<myProps.lecture_arr.length;i++){
          if(tr_td_row["td_data"]["lecture_seq"]==myProps.lecture_arr[i]["a_seq"]){
            lecture_info=myProps.lecture_arr[i];
          }
        }
      }
      return (
        <TdBoxArea
          key={tr_td_idx}
          lecture_info={lecture_info}
          tr_td_row={tr_td_row}
          stu_grade_sort={myProps.stu_grade_sort}
          is_show_attend={myProps.is_show_attend}
          Style={Style}
        ></TdBoxArea>
      );
    });
    return td_tags;
  };

  const getBodyTrTag = () => {
    let split_time_m_arr=table_option.split_time_m_arr;
    let body_tr_tags = split_time_m_arr.map((item: any, idx: number) => {
      let rowt_start_json = strFunc.secondsToTimeJson(item * 60);
      let rowt_end_json = strFunc.secondsToTimeJson((item + table_option.split_term) * 60);
      let td_tags = getBodyTdTag({ 
        row_time_min: item,
        row_num:idx,
      });
      return (
        <tr key={idx}>
          <td>
            <div className={Style.time_table_td_time_box} style={td_box_style} >
              {rowt_start_json["h"]}:{rowt_start_json["i"]} ~ {rowt_end_json["h"]}:{rowt_end_json["i"]}
            </div>
          </td>
          {td_tags}
        </tr>
      );
    });

    return body_tr_tags;
  };

  return (
    <div className={Style.time_table_wrap}>
      <table>
        <thead>
          {getThTrTag()}
        </thead>
        <tbody>
          {getBodyTrTag()}
        </tbody>
      </table>
    </div>
  );
};
export default LectureTimeTableArea;