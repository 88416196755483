import { useState, useRef, useEffect, useCallback,useImperativeHandle,forwardRef } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search/search_area";

const GoldHistoryList = forwardRef((props:any, ref) => {
  let myProps = {
    main_user_seq: "",
    refresh_data: (inData: any) => { },
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  let default_list_opt = {
    's_date_type': "a_date",
    's_start_date': DateFunc.get_date_format(new Date(), "Y-m-01"),
    's_end_date': DateFunc.get_date_format(new Date(), "Y-m-t"),
    "s_main_user_seq": myProps.main_user_seq,
  };
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "select_seq": "",
    ...default_list_opt
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {
    list({});
  }, [myProps.main_user_seq]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }))

  const list = (inOptObj: any) => {
    let tmp_list_opt: any = {
      ...listOpt,
      ...inOptObj
    };
    if (listAggridRef.current) {
      tmp_list_opt["sc"] = listAggridRef.current.getListSc();
    }
    setListOpt(tmp_list_opt);

    if(strFunc.is_empty(tmp_list_opt["s_main_user_seq"])){
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/gold_history/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          if (!strFunc.is_empty(tmp_list_opt.select_seq) && listAggridRef.current) {
            setTimeout(() => {
              let all_nodes = listAggridRef.current.getNodeRows();
              for (let i = 0; i < all_nodes.length; i++) {
                let row_node = all_nodes[i];
                let row_pri_val = row_node.data["a_ymd"] + "," + row_node.data["a_seq"];
                if (row_pri_val == tmp_list_opt.select_seq) {
                  row_node.setSelected(true);
                }
              }
            }, 100);
          }
        } else {

        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if(key=="a_amount"){
      let tmp_is_out=false;
      if(params.data.a_in_out=="out"){
        tmp_is_out=true;
      }
      let amount_span_style:any={
        color:"blue",
        fontWeight:"bold",
      };
      let plus_minus_str="";
      if(tmp_is_out){
        plus_minus_str="-";
        amount_span_style["color"]="red";
      }
      render_str=(
        <span style={amount_span_style}>
          {plus_minus_str}{strFunc.comma(render_str)}
        </span>
      );
    }
    return render_str;
  };

  const onSelectionChanged = (event: any) => {

  };

  return (
    <div>
      골드히스토리
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
        ></SearchArea>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: false,
            is_add_checkbox: true,
            floatingFilter: false,
            onGridReady: () => {
              
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer,
            onSelectionChanged: onSelectionChanged
          }}
        ></ListAggrid>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
    </div>
  );
});
export default GoldHistoryList;