import strFunc from '@/lib/lyg/string';
import StudyBox from "./study_box";

function StudyTitleListArea(props: any) {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    study_title_arr: [],
    study_use_arr: [],
    set_study_use_arr:(inData: any) => { },
    show_detail_title_seq_arr: [],
    set_show_detail_title_seq_arr: (inData: any) => { },
    select_study_title_seq_arr:[],
    set_select_study_title_seq_arr:(inData: any) => { },
    is_show_check_title:true,
    student_info:{},
    select_daily_study_title:(inData: any) => { },
    go_write_study_use_by_ajax:(inData: any) => { },
    refresh_data: (inData: any) => { },
    is_show_only_use:false,
    set_refresh: () => { },
    on_select_detail:(inData: any) => { },
    ...props
  };
  let select_mcomp_data= myProps.select_mcomp_data;
  let study_title_arr = myProps.study_title_arr;
  let study_use_arr = myProps.study_use_arr;
  let show_detail_title_seq_arr = myProps.show_detail_title_seq_arr;

  const get_order_list_by_main = () => {
    let main_title_seq_arr = [];
    let main_title_arr = [];
    let etc_title_arr = [];
    for (let i = 0; i < study_use_arr.length; i++) {
      let use_info = study_use_arr[i];
      for (let j = 0; j < study_title_arr.length; j++) {
        let study_info = study_title_arr[j];
        if (use_info["a_study_title_seq"] == study_info["a_seq"]) {
          if (strFunc.str_in_array(study_info["a_seq"], main_title_seq_arr) == -1) {
            main_title_seq_arr.push(study_info["a_seq"]);
            main_title_arr.push(study_info);
          }
        }
      }
    }

    if(myProps.is_show_only_use==false){
      for (let i = 0; i < study_title_arr.length; i++) {
        let study_info = study_title_arr[i];
        if (strFunc.str_in_array(study_info["a_seq"], main_title_seq_arr) == -1) {
          etc_title_arr.push(study_info);
        }
      }
    }

    let ordered_list_arr = [
      ...main_title_arr,
      ...etc_title_arr
    ];

    return ordered_list_arr;
  };

  const on_check_select_by_study_box=(inData:any)=>{
    let opt_obj={
      study_title_info:{},
      is_check:false,
      ...inData
    };
    
    let study_title_info=opt_obj["study_title_info"];
    if(opt_obj["is_check"]){
      //추가
      let new_select_study_title_seq_arr=[...myProps.select_study_title_seq_arr];
      new_select_study_title_seq_arr.push(study_title_info["a_seq"]);
      myProps.set_select_study_title_seq_arr(new_select_study_title_seq_arr);
    }else{
      //빼기
      let new_select_study_title_seq_arr=[];
      for(let i=0;i<myProps.select_study_title_seq_arr.length;i++){
        if(myProps.select_study_title_seq_arr[i]==study_title_info["a_seq"]){

        }else{
          new_select_study_title_seq_arr.push(myProps.select_study_title_seq_arr[i]);
        }
      }
      myProps.set_select_study_title_seq_arr(new_select_study_title_seq_arr);
    }
  };

  const get_study_group_tags = () => {
    let ordered_list_arr = get_order_list_by_main();
    let study_group_tags = ordered_list_arr.map((study_title_info: any, idx: number) => {
      let is_select_title=false;
      if(strFunc.str_in_array(study_title_info["a_seq"],myProps.select_study_title_seq_arr)!=-1){
        is_select_title=true;
      }
      return (
        <StudyBox 
          key={idx}
          select_mcomp_data={{...select_mcomp_data}}
          study_title_info={study_title_info}
          is_show_check_title={myProps.is_show_check_title}
          study_use_arr={study_use_arr}
          set_study_use_arr={myProps.set_study_use_arr}
          show_detail_title_seq_arr={show_detail_title_seq_arr}
          set_show_detail_title_seq_arr={myProps.set_show_detail_title_seq_arr}
          student_info={myProps.student_info}
          select_daily_study_title={myProps.select_daily_study_title}
          go_write_study_use_by_ajax={myProps.go_write_study_use_by_ajax}
          is_select_title={is_select_title}
          set_is_select={(inData:any)=>{
            on_check_select_by_study_box({
              study_title_info:study_title_info,
              is_check:inData,
            });
          }}
          refresh_data={myProps.refresh_data}
          set_refresh={myProps.set_refresh}
          on_select_detail={myProps.on_select_detail}
        ></StudyBox>
      );
    });
    return study_group_tags;
  };

  return (
    <div>
      <div className="mt-1">
        {get_study_group_tags()}
      </div>
    </div>
  );
};
export default StudyTitleListArea;