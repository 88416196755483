function BottomConArea(props:any){
   let myProps={
    Style:{},
    todo_info:{},
    ...props
  };
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;
  
  return (
  <div className={Style.bottom_con_wrap}>
    <span className={Style.bottom_con_date}>
      {todo_info.a_reserve_start_date.substring(0,10)}
    </span>
  </div>
  );
};
export default BottomConArea;