import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../list/xcolumn/list";

function ProgressAmountPopup(props:any) {
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const [rowData,setRowData]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_is_mcomp_public":"1",
    "a_par_mcomp_seq":user.comp_seq,
    "a_par_id":"",
    "a_par_seq":"",
    "a_writer_mcomp_seq":user.comp_seq,
    "a_writer_mcomp_user_seq":user.user_seq,
    "a_writer_seq":"",
    "a_writer_name":user.user_name,
    "a_stu_mcomp_seq":user.comp_seq,
    "a_stu_mcomp_user_seq":user.user_seq,
    "a_stu_seq":"",
    "a_stu_name":user.user_name,
    "a_start_date":"",
    "a_end_date":"",
    "a_is_reserve":"",
    "a_reserve_start_date":"",
    "a_reserve_end_date":"",
    "a_success_sort":"",
    "a_amount_sort":"amount",
    "a_amount_num":0,
    "a_success_amount":0,
    "a_success_time_sec":3600,
    "a_time_sec":0,
    "a_is_success":"",
    "a_state":"ready",
    "a_subject":"",
    "a_title":"",
    "a_content":"",
    "a_result_memo":"",
    "a_is_need_photo":"",
  });

  useEffect(()=>{
    
    let changeRowData:any={};
    for(let key in rowData){
      if(props.rowData[key]!=undefined){
        changeRowData[key]=props.rowData[key];
      }
    }
    
    setRowData({
      ...rowData,
      ...changeRowData
    });
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="a_amount_num"){
      value=strFunc.comma(value);
    }
    
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const plus_minus_amout=(sort:"plus"|"minus")=>{
    let a_amount_num=parseInt(strFunc.uncomma(rowData.a_amount_num));
    if(sort=="plus"){
      a_amount_num++;
    }else{
      a_amount_num--;
    }
    if(a_amount_num<0){
      a_amount_num=0;
    }
    setRowData({
      ...rowData,
      "a_amount_num":strFunc.comma(a_amount_num)
    });
  };

  const updateTodoAmountNumByAjax=()=>{
    if(!confirm("진행도를 업데이트 하시겠습니까?")){
      return false;
    }
    let formJsonData={
      "todo_seq":rowData.a_ymd+","+rowData.a_seq,
      "amount_num":rowData.a_amount_num,
    };
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/update_amount',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  let per_str:any=0;
  if(rowData.a_success_sort=="time"){
    let a_success_time_sec=parseInt(strFunc.uncomma(rowData.a_success_time_sec));
    let a_time_sec=parseInt(strFunc.uncomma(rowData.a_time_sec));
    if(a_success_time_sec!=0&&a_time_sec!=0){
      per_str=(a_time_sec/a_success_time_sec)*100;
    }
  }else{
    let a_success_amount=parseInt(strFunc.uncomma(rowData.a_success_amount));
    let a_amount_num=parseInt(strFunc.uncomma(rowData.a_amount_num));
    if(a_success_amount!=0&&a_amount_num!=0){
      per_str=(a_amount_num/a_success_amount)*100;
    }
  }
  if(rowData.a_is_success=="1"){
    per_str=100;
  }
  per_str=strFunc.comma(per_str);

  let state_str="";
  if(xColumnArr.select_arr.a_state){
    for(let i=0;i<xColumnArr.select_arr.a_state.length;i++){
      if(rowData.a_state==xColumnArr.select_arr.a_state[i]["value"]){
        state_str=xColumnArr.select_arr.a_state[i]["text"];
      }
    }
  }

  return (
    <div>
      <div>
        {
          props.rowData.a_content && props.rowData.a_content.split('\n').map( (line:string,idx:number) => {
            return (<span key={idx}>{line}<br/></span>)
          })
        }
      </div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="150px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>상태</th>
              <td>
                {state_str}
              </td>
            </tr>
            <tr>
              <th>달성률</th>
              <td>
                {per_str} %
              </td>
            </tr>
            {rowData.a_success_sort=="amount"&&
            <>
            <tr>
              <th>목표</th>
              <td>
                {strFunc.comma(rowData.a_success_amount)} {rowData.a_amount_sort}
              </td>
            </tr>
            <tr>
              <th colSpan={2}>진행도 업데이트</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="text-center">
                  <button className="btn btn-dark mr-1" onClick={()=>{plus_minus_amout("minus");}} >-</button>
                  <input type="text" name="a_amount_num" value={rowData.a_amount_num} onChange={handleInputChange} 
                    style={{width:90,fontSize:16,textAlign:"right",color:"blue",fontWeight:"bold",padding:5}}
                    onClick={(e:any)=>{e.target.select();}}/>
                  <button className="btn btn-dark ml-1" onClick={()=>{plus_minus_amout("plus");}} >+</button>
                </div>
                <div className="text-center mt-2">
                  <button className="btn btn-dark" onClick={()=>{updateTodoAmountNumByAjax();}}>진행도 업데이트</button>
                </div>
              </td>
            </tr>
            </>
            }
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ProgressAmountPopup;
