import { Link } from 'react-router-dom';
import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';

export interface ISortDRight {
  list: (inOptObj: any) => void;
}

const SortDRight = forwardRef((props, ref) => {
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      'order_id': 'a_order_num',
      's_par_id': "empty",
      's_par_col': "empty",
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let form_list_json=pageData.listOpt;
    form_list_json["is_comp_sort"]="1";
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/sortd/sortd/list', form_list_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const list_origin = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let origin_form_list_json=pageData.listOpt;
    origin_form_list_json["is_comp_sort"]="";
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/sortd/sortd/list',origin_form_list_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }));

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_data = {
      "data_arr": selected_row,
      "is_comp_sort": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/sortd/sortd/delete', del_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const goWriteArr = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("선택 수정 하시겠습니까?")) {
      return false;
    }
    let sortd_w_form_json = {
      "is_comp_sort": "1",
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/sortd/sortd/write', sortd_w_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.sort_data_create_date = "";
          alert("저장되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const goAddRowData = (e: any) => {
    if (pageData.listOpt.s_par_id != "empty") {
      let allRows = listAggridRef.current.getRows();
      listAggridRef.current.addRowData({
        data: [{
          "a_par_id": pageData.listOpt.s_par_id,
          "a_par_col": pageData.listOpt.s_par_col,
          "a_seq": "",
          "a_value": "",
          "a_text": "",
          "a_order_num": allRows.length + 1,
        }]
      });
    }
  };

  const setOrderNumAuto = (e: any) => {
    let allRows = listAggridRef.current.getRows();
    for (let i = 0; i < allRows.length; i++) {
      allRows[i]["a_order_num"] = i + 1;
    }
    listAggridRef.current.setInfoArr(allRows);
  };

  const openOnePopup = (sort: string, isUpdate: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      openOnePopup("view", true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    return render_str;
  }, []);

  return (
    <div>
      <div className="btn-box-left relative h-8">
        <div className="absolute right-0 top-0 btn-box-right">
          {/* <button className="btn btn-dark" onClick={()=>{openOnePopup("write",false);}}>등록</button>
          <button className="btn btn-dark" onClick={()=>{openOnePopup("write",true);}}>수정</button> */}
          {/* <button className="btn btn-round-green" onClick={()=>{listAggridRef.current.toggleGridEditing();}}>수정모드</button> */}
          <button className="btn btn-gray" onClick={()=>{list_origin({});}}>초기데이터얻기</button>
          <button className="btn btn-gray" onClick={goAddRowData}>추가</button>
          <button className="btn btn-gray" onClick={goWriteArr}>선택저장</button>
          <button className="btn btn-dark" onClick={goDelete}>삭제</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: false,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          is_grid: true,
          is_add_rowDrag: true,
          onRowDragEnd: setOrderNumAuto
        }}
      ></ListAggrid>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
});

export default SortDRight;