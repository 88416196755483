import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import strFunc from '@/lib/lyg/string';
import Style from "./css/use_style.module.css";

const VideoUseList = forwardRef((props:any, ref) => {
  const myProps={
    "corse_info":null,
    "detail_arr":[],
    ...props
  };

  const [corse_info,set_corse_info]=useState<any>(myProps.corse_info);
  const [detail_arr,set_detail_arr]=useState<any>(myProps.detail_arr);
  const [section_arr,set_section_arr]=useState<any>([]);
  const [video_arr,set_video_arr]=useState<any>([]);
  const [select_video_seq_arr,set_select_video_seq_arr]=useState<any>([]);

  const setInitData=(inData:any)=>{
    let optObj:any={
      "corse_info":null,
      "detail_arr":[],
      ...inData,
    };
    set_corse_info(optObj["corse_info"]);
    set_detail_arr(optObj["detail_arr"]);
    if(optObj["corse_info"].section_arr){
      set_section_arr(optObj["corse_info"].section_arr);
    }
    if(optObj["corse_info"].video_arr){
      set_video_arr(optObj["corse_info"].video_arr);
    }
  };
  
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_select_video_seq_arr
  }));

  const get_select_video_seq_arr=()=>{
    return select_video_seq_arr;
  };

  const checkTogleOfVideo=(is_checked:boolean,video_seq:string)=>{
    let pre_seq_arr=[...select_video_seq_arr];
    let after_seq_arr=[];
    if(is_checked){
      //없다면 등록
      after_seq_arr=pre_seq_arr;
      if(strFunc.str_in_array(video_seq,pre_seq_arr)==-1){
        pre_seq_arr.push(video_seq);
        after_seq_arr=pre_seq_arr;
      }
    }else{
      //있다면 삭제
      for(let i=0;i<pre_seq_arr.length;i++){
        if(pre_seq_arr[i]!=video_seq){
          after_seq_arr.push(pre_seq_arr[i]);
        }
      }
    }

    set_select_video_seq_arr(after_seq_arr);
  };

  //섹션
  const getSectionTags=()=>{
    let sectionTags=section_arr.map((section_info:any,idx:number)=>{
      let section_video_arr=[];
      for(let i=0;i<video_arr.length;i++){
        if(video_arr[i]["a_section_seq"]==section_info.a_seq){
          section_video_arr.push(video_arr[i]);
        }
      }
      let video_tags=getVideoRowTagsBySection(section_video_arr);
      return (
        <div className={Style.section_div} key={idx}>
          <div className={Style.section_title}>
            {section_info.a_title}
          </div>
          {video_tags}
        </div>
      );
    });
    return sectionTags;
  };
  //기타
  const getSectionEtcTags=()=>{
    let section_seq_arr=[];
    for(let i=0;i<section_arr.length;i++){
      section_seq_arr.push(section_arr[i].a_seq);
    }
    let etc_section_video_arr=[];
    for(let i=0;i<video_arr.length;i++){
      if(strFunc.str_in_array(video_arr[i]["a_section_seq"],section_seq_arr)==-1){
        etc_section_video_arr.push(video_arr[i]);
      }
    }

    if(etc_section_video_arr.length==0){
      return "";
    }

    let video_tags=getVideoRowTagsBySection(etc_section_video_arr);

    return (
      <div className={Style.section_div} >
        <div className={Style.section_title}>
          기타
        </div>
        {video_tags}
      </div>
    );
  }
  //비디오
  const getVideoRowTagsBySection=(in_video_arr:any)=>{
    let video_tags:any=in_video_arr.map((video_info:any,idx:number)=>{
      let per_bar_tag=getVideoUseBar(video_info);
      let video_checked=false;
      if(strFunc.str_in_array(video_info.a_seq,select_video_seq_arr)!=-1){
        video_checked=true;
      }
      return (
        <div className={Style.video_div} key={idx}>
          <label>
            <input type="checkbox" className="mr-1" 
              onChange={(e:any)=>{checkTogleOfVideo(e.target.checked,video_info.a_seq);}}
              checked={video_checked} />
            {video_info.a_title}
          </label>
          {per_bar_tag}
        </div>
      );
    });

    return video_tags;
  };

  //비디오의 사용 디테일
  const getVideoUseBar=(video_info:any)=>{
    let video_use_info=null;
    for(let i=0;i<detail_arr.length;i++){
      if(video_info["a_seq"]==detail_arr[i]["a_video_seq"]){
        video_use_info=detail_arr[i];
      }
    }
    let per_num=0;
    if(video_use_info!=null){
      let total_sec=parseInt(video_info.a_total_sec);
      let watch_sec=parseInt(video_use_info.a_watch_sec);
      if(total_sec!=0&&watch_sec!=0){
        per_num=strFunc.comma(watch_sec/total_sec);
      }
      if(video_use_info.a_video_is_complete=="1"){
        per_num=100;
      }
    }

    let bar_per_style:any={
      width:per_num+"%"
    };
    
    return (
      <div className={Style.video_user_bar} >
        <div className={Style.video_user_bar_text}>{per_num} %</div>
        <div className={Style.video_user_bar_per} style={bar_per_style}></div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <label>
          <input type="checkbox" onChange={(e:any)=>{
            let check_seq_arr=[];
            let is_check=e.target.checked;
            if(is_check){
              for(let i=0;i<video_arr.length;i++){
                check_seq_arr.push(video_arr[i]["a_seq"]);
              }
            }
            set_select_video_seq_arr(check_seq_arr);
          }} /> 전체체크
        </label>
      </div>
      {getSectionTags()}
      {getSectionEtcTags()}
    </div>
  );
});

export default VideoUseList;
