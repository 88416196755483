import strFunc from '@/lib/lyg/string';

function ViewPopupContent(props:any) {
  let myProps={
    closePopup:()=>{},
    openOnePopup:(inData:any)=>{},
    ...props
  };
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>아이디</th>
              <td>{props.rowData.a_user_id}</td>
            </tr>
            <tr>
              <th>이름</th>
              <td>{props.rowData.a_user_name}</td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td>{props.rowData.a_user_nickname}</td>
            </tr>
            <tr>
              <th>핸드폰</th>
              <td>{props.rowData.a_user_phone}</td>
            </tr>
            <tr>
              <th>출결번호</th>
              <td>{strFunc.getNumber2(props.rowData.a_stu_num)}</td>
            </tr>
            <tr>
              <th>과목</th>
              <td>{props.rowData.a_main_subject}</td>
            </tr>
            <tr>
              <th>생성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
            <tr>
              <th>수정일</th>
              <td>{props.rowData.a_update_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();myProps.openOnePopup("write",true);}}>수정</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
