import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState,useEffect } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import AddAttendPopup from "../popup/add_attend";
import ViewTodoDayPopup from "@/pages/comp/plan/main/area/popup/view_todo_day";
import TabArea from "./area/tab_area";
import SearchArea from "./area/search_area";
import CardListArea from "./area/card_list";

function AttendCardListPage() {
  const xColumnArr = getXcolumnJson();

  const [tab_id,set_tab_id]=useState("");
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      s_addon_class_user: "1",
      s_addon_link_user: "1",
      s_addon_profile: "1",
      s_addon_attend: "1",
      s_addon_attend_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_attend_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_todo:"1",
      s_addon_todo_start_date:DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_todo_end_date:DateFunc.get_date_format(new Date(), "Y-m-d"),
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [lecture_arr,set_lecture_arr]=useState([]);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",//view_todo_day
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    stu_info:{},
    title: "팝업",
    width: "500px",
    height: "70%",
  });
  let is_under_width = MyScreenFunc.get_is_under();

  useEffect(() => {
    
    list({});
    get_lecture_list_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_lecture_list_by_ajax = () => {
    let edu_list_form_json={
      "s_state":"ing",
      "s_addon_time":"1",
      "s_addon_user":"1",
      "s_addon_user_s_state":"ing",
      "s_addon_user_time":"1",
      "s_addon_time_union":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list',edu_list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (!confirm(confirm_msg)) {
      return false;
    }
    let del_form_data = {
      "data_arr": dataArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', del_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const openAttendPopup = (selected_row: [], attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    if (xColumnArr.select_arr.a_sort_detail) {
      for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
        let item = xColumnArr.select_arr.a_sort_detail[i];
        if (item.value == attend_sort_detail) {
          pop_title = item.text;
        }
      }
    }

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = pageData.listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_attend",
      "title": pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      attend_date: attend_date,
      userInfoArr: selected_row,
    });
  };

  const openViewTodoDayPopup = (stu_info:any) => {
    if(stu_info==null){
      alert("학생 선택 필요.");
      return false;
    }
    let pop_title="맞춤플랜 보기";
    let attend_date = pageData.listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_todo_day",
      "title": pop_title,
      attend_date: attend_date,
      stu_info:stu_info,
    });
  };

  return (
    <CompLayout isConTitle={true} >
      <TabArea
        tab_id={tab_id}
        set_tab_id={set_tab_id}
      ></TabArea>
      <SearchArea 
        listOpt={pageData.listOpt}
        list={list}
        xColumnArr={xColumnArr}></SearchArea>
      <CardListArea
        tab_id={tab_id}
        infoArr={infoArr}
        lecture_arr={lecture_arr}
        xColumnArr={xColumnArr}
        deleteAttend={deleteAttend}
        openAttendPopup={openAttendPopup}
        openViewTodoDayPopup={openViewTodoDayPopup}
      ></CardListArea>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "add_attend" &&
            <AddAttendPopup attend_sort={popupData.attend_sort} attend_sort_detail={popupData.attend_sort_detail}
              attend_date={popupData.attend_date}
              userInfoArr={popupData.userInfoArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
          }
          {popupData.sort === "view_todo_day" &&
            <ViewTodoDayPopup 
              list_opt={{
                "s_stu_user_name":popupData.stu_info.a_user_name,
                "s_stu_user_seq":popupData.stu_info.a_seq,
                "s_start_date":popupData.attend_date,
                "s_end_date":popupData.attend_date,
              }}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewTodoDayPopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default AttendCardListPage;