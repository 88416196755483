import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteFrameContent from "./area/write";
import SearchArea from "./area/search_area";

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "select_seq":"",
      "s_subject_seq":"",
      "s_subject_sort":"",
      "s_is_progress":"",
      "s_addon_title_img":"1",
      "s_addon_subject_title":"1",
      "s_addon_section":"1",
      "s_addon_study":"1",
      "s_addon_use_stu":"1",

      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const WriteFrameContentRef=useRef<any>(null);
  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      if(listAggridRef.current){
        inOptObj["sc"]=listAggridRef.current.getListSc();
      }
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_title/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        for(let i=0;i<response.data["data"]["info_arr"].length;i++){
          response.data["data"]["info_arr"][i]["idx_num"]=i+1;
        }
        setInfoArr(response.data["data"]["info_arr"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        //선택
        setSelectbySelectedSeq();
      }else{
        
      }
    });
  };

  const setSelectbySelectedSeq=()=>{
    if(pageData.listOpt.select_seq!=""){
      setTimeout(()=>{
        let listNodes=listAggridRef.current.getNodeRows();
        for(let i=0;i<listNodes.length;i++){
          let node=listNodes[i];
          if(node.data["a_seq"]==pageData.listOpt.select_seq){
            listNodes[i].setSelected(true);
          }
        }
      },100);
    }
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_title/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        setWriteState();
      }else{
        alert(response.data["msg"]);
        list({});
      }
    });
  }, []);

  const setWriteState=()=>{
    listAggridRef.current.setSelectFalseAll();
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData();
    }
  };

  const moveOrderNum=(row_data:any,up_down:string)=>{
    let tmp_section_arr:any=[...listAggridRef.current.getRows()];
    let row_num=0;
    for(let i=0;i<tmp_section_arr.length;i++){
      if(tmp_section_arr[i]["a_seq"]==row_data["a_seq"]){
        row_num=i;
      }
    }
    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;
    save_list_order_num_by_arr(tmp_section_arr);
    setTimeout(()=>{
      setInfoArr(tmp_section_arr);
      listAggridRef.current.setInfoArr(tmp_section_arr);
    },100);
  };

  const save_list_order_num_by_arr=(tmp_section_arr:[])=>{
    let w_order_info_arr=[];
    for(let i=0;i<tmp_section_arr.length;i++){
      w_order_info_arr.push({
        "a_seq":tmp_section_arr[i]["a_seq"],
        "a_order_num":i,
      });
    }
    let write_form_data={
      "is_update":"1",
      "data_arr":w_order_info_arr
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_title/write',write_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="row_view_section"){
      let section_num=0;
      let study_num=0;
      if(params.data.section_arr){
        section_num=params.data.section_arr.length;
      }
      if(params.data.study_arr){
        study_num=params.data.study_arr.length;
      }
      render_str=section_num+"/"+study_num;
    }else if(key=="row_view_stu_cnt"){
      if(params.data.use_stu_arr){
        render_str=params.data.use_stu_arr.length;
      }
    }else if(key=="row_view_request"){
      if(params.data.request_arr){
        render_str=params.data.request_arr.length;
      }
    }else if(key=="row_view_order"){
      render_str=(
        <div className="text-center">
          <button className="btn-s btn-dark" onClick={()=>{moveOrderNum(params.data,"up");}} >▲</button>
          <button className="btn-s btn-dark ml-1" onClick={()=>{moveOrderNum(params.data,"down");}} >▼</button>
        </div>
      );
    }

    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==1){
      if(WriteFrameContentRef.current){
        WriteFrameContentRef.current.setInitData({
          row_data:selected_row[0],
          isUpdate:true,
        });
      }
    }
  };

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"25%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:380}} >
                  <SearchArea
                    listOpt={pageData.listOpt}
                    list={list}
                    setWriteState={setWriteState}
                    goDelete={goDelete}
                  ></SearchArea>
                  <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{...xColumnArr}}
                  list={list}
                  gridOpt={{
                    fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num:true,
                    is_add_checkbox:false,
                    floatingFilter:true,
                    onGridReady:()=>{
                      list({});
                    },
                    onCellClicked:cellClickedListener,
                    cellRenderer:cellRenderer,
                    onSelectionChanged:onSelectionChanged,
                  }}
                  ></ListAggrid>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:400}}>
                  <WriteFrameContent
                    ref={WriteFrameContentRef}
                    list={(listOpt:any)=>{list(listOpt);}}
                  ></WriteFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default ListPage;