import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react"
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import TopManageBarArea from "./top_manage_bar";
import TimeListArea from "./area/time_list";

const LectureTimeComponent = forwardRef((props: any, ref) => {
  const myProps = {
    rowData: {
      "a_basic_h": "13",
      "a_basic_m": "00",
      "a_basic_length_sec": 60 * 60,
      "a_same_days_week": "",
    },
    add_student: (inData: any) => { },
    room_info_arr: [],
    teacher_arr: [],
    ...props
  };
  let dark = useSelector((state: any) => state.dark);

  let same_days_week_arr: any = [];
  let is_same_days_week = !strFunc.is_empty(myProps.rowData["a_same_days_week"]);
  if (is_same_days_week == false) {
    if (myProps.rowData["a_same_days_week"] == "0") {
      is_same_days_week = true;
    }
  }
  if (is_same_days_week) {
    same_days_week_arr = myProps.rowData["a_same_days_week"].split(",");
  }
  let dayNumArr = [
    { value: 0, text: "일", color: "red" },
    { value: 1, text: "월", color: "black" },
    { value: 2, text: "화", color: "black" },
    { value: 3, text: "수", color: "black" },
    { value: 4, text: "목", color: "black" },
    { value: 5, text: "금", color: "black" },
    { value: 6, text: "토", color: "blue" },
  ];
  if (is_same_days_week) {
    let new_dayNumArr = [];
    for (let i = 0; i < dayNumArr.length; i++) {
      if (strFunc.str_in_array(dayNumArr[i]["value"], same_days_week_arr) == -1) {
        new_dayNumArr.push(dayNumArr[i]);
      }
    }
    new_dayNumArr.push({
      value: same_days_week_arr.join(","),
      text: DateFunc.get_day_str_by_day_num_arr(same_days_week_arr, ""),
      color: "black"
    });
    dayNumArr = new_dayNumArr;
  }

  const initLectureTimeRowData = {
    "a_lecture_seq": "",
    "a_day_num": "",
    "a_seq": "",
    "a_name": "",
    "a_start_h": "09",
    "a_start_m": "00",
    "a_end_h": "10",
    "a_end_m": "00",
    "a_room_seq": "",
    "a_teacher_seq": "",
    "a_teacher_name": "",
    "a_date": "",
  };
  const [timeInfoArr, setTimeInfoArr] = useState<any[]>([]);
  const [user_time_arr, set_user_time_arr] = useState<any[]>([]);
  const [studentArr, setStudentArr] = useState<any[]>([]);
  const [show_stu_day_time_arr, set_show_stu_day_time_arr] = useState<any[]>([]);//[1,2,3]
  const [is_show_stu_day_time_all, set_is_show_stu_day_time_all] = useState(false);
  const [refresh, set_refresh] = useState(false);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find_stu",
    isUpdate: false,
    rowData: {},
    list_opt:{},
    time_info: {},
    box_time_arr:[],
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    setTimeInfoArr(get_copy_of_same_days_week_data(timeInfoArr));
    set_show_stu_day_time_arr([]);
    set_refresh(!refresh);
  }, [myProps.rowData["a_same_days_week"]]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowDataChild, getTimeInfoArr, setTimeInfoArr, get_user_time_arr_by_ajax,
    get_user_time_arr,
    setStudentArr
  }));

  const setRowDataChild = (inTimeDataArr: []) => {
    setTimeInfoArr([...inTimeDataArr]);
  };

  const getTimeInfoArr = () => {
    return timeInfoArr;
  };

  const get_user_time_arr = () => {
    return user_time_arr;
  };

  const get_copy_of_same_days_week_data = (inTimeInfoArr: any[]) => {
    let newTimeInfoArr: any = [];
    if (is_same_days_week == false) {
      return inTimeInfoArr;
    }
    if (same_days_week_arr.length == 0) {
      return inTimeInfoArr;
    }

    //첫번째 데이터 리스트 얻기
    let first_day_num = same_days_week_arr[0];
    let first_time_info_arr: any = [];
    for (let i = 0; i < inTimeInfoArr.length; i++) {
      let tmp_time_info = inTimeInfoArr[i];
      if (tmp_time_info["a_day_num"] == first_day_num) {
        first_time_info_arr.push(tmp_time_info);
      }
    }
    //기존데이터 없애기
    newTimeInfoArr = [];
    for (let i = 0; i < inTimeInfoArr.length; i++) {
      let tmp_time_info = inTimeInfoArr[i];
      if (strFunc.str_in_array(tmp_time_info["a_day_num"], same_days_week_arr) == -1) {
        newTimeInfoArr.push(tmp_time_info);
      }
    }
    if (first_time_info_arr.length == 0) {
      return newTimeInfoArr;
    }
    //첫번째 데이터 리스트 추가
    for (let day_i in same_days_week_arr) {
      let day_i_str = same_days_week_arr[day_i];
      for (let i = 0; i < first_time_info_arr.length; i++) {
        let first_time_info = first_time_info_arr[i];
        let add_time_info_row = {
          ...first_time_info,
          a_day_num: day_i_str,
        };
        newTimeInfoArr.push(add_time_info_row);
      }
    }
    return newTimeInfoArr;
  };

  const get_user_time_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      lecture_seq: "",
      ...inData
    };
    if (strFunc.is_empty(opt_obj["lecture_seq"])) {
      set_user_time_arr([]);
      return false;
    }
    let formJsonData = {
      "s_lecture_seq": opt_obj["lecture_seq"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_user_time/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_user_time_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const add_student = (inData: any) => {
    let opt_obj = {
      add_student_arr:[],
      ...inData
    };
    setStudentArr([
      ...studentArr,
      ...opt_obj["add_student_arr"],
    ]);
    myProps.add_student(inData);
  };

  const open_find_stu_popup = (inData:any) => {
    let opt_obj={
      except_stu_seq_arr:[],
      time_info:{},
      box_time_arr:[],
      ...inData
    };
    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find_stu",
      list_opt:{
        s_except_user_seq:opt_obj["except_stu_seq_arr"],
      },
      time_info:opt_obj["time_info"],
      box_time_arr:opt_obj["box_time_arr"],
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataStuPopup = (inData: any) => {
    let opt_obj={
      info_arr: [],
      ...inData
    };
    if(opt_obj["info_arr"].length==0){
      alert("선택이 없습니다.");
      return false;
    }

    //학생 추가
    let pre_stu_seq_arr=[];
    for(let i=0;i<studentArr.length;i++){
      pre_stu_seq_arr.push(studentArr[i]["a_user_seq"]);
    }
    let add_student_arr=[];
    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let stu_info=opt_obj["info_arr"][i];
      if(strFunc.str_in_array(stu_info["a_seq"],pre_stu_seq_arr)==-1){
        add_student_arr.push({
          "a_lecture_seq":"",
          "a_user_seq":stu_info["a_seq"],
          "a_user_name":stu_info["a_user_name"],
          "a_user_grade":stu_info["a_user_grade"],
          "a_is_main":"",
          "a_state":"ing",
          "user_info":stu_info,
        });
      }
    }
    add_student({
      add_student_arr:add_student_arr,
    });
    //학생 시간 추가
    let add_user_time_arr=[];
    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let stu_info=opt_obj["info_arr"][i];
      
      for(let j=0;j<popupData.box_time_arr.length;j++){
        let box_time:any=popupData.box_time_arr[j];
        let add_user_time={
          ...box_time,
          a_stu_seq:stu_info["a_seq"],
          a_stu_name:stu_info["a_user_name"],
        };
        add_user_time.a_day_num=parseInt(add_user_time.a_day_num+"");
        add_user_time_arr.push(add_user_time);
      }
    }
    set_user_time_arr([
      ...user_time_arr,
      ...add_user_time_arr,
    ]);
  };

  return (
    <div>
      <TopManageBarArea
        teacher_arr={myProps.teacher_arr}
        room_info_arr={myProps.room_info_arr}
        timeInfoArr={timeInfoArr}
        setTimeInfoArr={(inData: any) => {
          setTimeInfoArr(inData);
          set_refresh(!refresh);
        }}
        dayNumArr={dayNumArr}
        is_show_stu_day_time_all={is_show_stu_day_time_all}
        set_is_show_stu_day_time_all={set_is_show_stu_day_time_all}
        set_show_stu_day_time_arr={set_show_stu_day_time_arr}
      ></TopManageBarArea>
      <TimeListArea
        rowData={myProps.rowData}
        dayNumArr={dayNumArr}
        initLectureTimeRowData={initLectureTimeRowData}
        timeInfoArr={timeInfoArr}
        setTimeInfoArr={setTimeInfoArr}
        get_copy_of_same_days_week_data={get_copy_of_same_days_week_data}
        is_same_days_week={is_same_days_week}
        same_days_week_arr={same_days_week_arr}
        teacher_arr={myProps.teacher_arr}
        room_info_arr={myProps.room_info_arr}

        user_time_arr={user_time_arr}
        set_user_time_arr={set_user_time_arr}
        studentArr={studentArr}
        show_stu_day_time_arr={show_stu_day_time_arr}
        set_show_stu_day_time_arr={set_show_stu_day_time_arr}
        is_show_stu_day_time_all={is_show_stu_day_time_all}
        open_find_stu_popup={open_find_stu_popup}
      ></TimeListArea>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "find_stu" &&
            <FindMultyStudentPopup
              callBackData={callBackDataStuPopup}
              listOpt={{
                s_addon_lecture_time:"1",
                ...popupData.list_opt
              }}
              is_show_lecture_time={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
});

export default LectureTimeComponent;