import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";
import WriteFrameContent from "./area/write";

function RoutinList(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "select_seq":"",
      "s_addon_grade":"1",
      "s_addon_grade_user":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  const WriteFrameContentRef=useRef<any>(null);

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      if(listAggridRef.current){
        inOptObj["sc"]=listAggridRef.current.getListSc();
      }
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/routine/routine/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        //선택
        setSelectbySelectedSeq();
      }else{
        
      }
    });
  };

  const setSelectbySelectedSeq=()=>{
    if(pageData.listOpt.select_seq!=""){
      setTimeout(()=>{
        let listNodes=listAggridRef.current.getNodeRows();
        for(let i=0;i<listNodes.length;i++){
          let node=listNodes[i];
          if(node.data["a_seq"]==pageData.listOpt.select_seq){
            listNodes[i].setSelected(true);
          }
        }
      },100);
    }
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/routine/routine/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
        list({});
      }
    });
  }, []);

  const setWriteState=()=>{
    listAggridRef.current.setSelectFalseAll();
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData();
    }
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==1){
      if(WriteFrameContentRef.current){
        WriteFrameContentRef.current.setInitData({
          row_data:selected_row[0],
          isUpdate:true,
        });
      }
    }
  };

  return (
    <CompLayout isLeftArea={true}>
      <div className="con_wrap">
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"30%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:280}} >
                  <SearchArea
                    listOpt={pageData.listOpt}
                    list={list}
                    goDelete={goDelete}
                    setWriteState={setWriteState}
                  ></SearchArea>
                  <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{...xColumnArr}}
                  list={list}
                  gridOpt={{
                    fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num:true,
                    is_add_checkbox:false,
                    floatingFilter:false,
                    onGridReady:()=>{
                      list({});
                    },
                    onCellClicked:cellClickedListener,
                    cellRenderer:cellRenderer,
                    onSelectionChanged:onSelectionChanged,
                  }}
                  ></ListAggrid>
                  <Paging now_page={pageData.listOpt.now_page}
                    num_per_page={pageData.listOpt.num_per_page}
                    total_rec={countInfo.tot} 
                    is_view_num_per_page={true}
                    onChangePage={(now_page:number)=>{list({now_page:now_page});}}
                    onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:400}}>
                  <WriteFrameContent
                    ref={WriteFrameContentRef}
                    list={list}
                  ></WriteFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CompLayout>
  );
}

export default RoutinList;