//import DarkMode from "@/pcomponents/common/dark";
import { useSelector, useDispatch } from 'react-redux';
import { setDark } from '@/store/dark/dark';
import {  useEffect } from 'react';

const DarkMode=(props:any)=>{
  const dispatch = useDispatch();
  let dark=useSelector((state:any) => state.dark);

  useEffect(()=>{
    //setDarkPage(dark.dark);
  },[]);

  const goDarkToggle=()=>{
    if(dark.dark=="dark"){
      setDarkPage("light");
    }else{
      setDarkPage("dark");
    }
  };
  const setDarkPage=(sort:string)=>{
    if(sort!="dark"){
      localStorage.theme="light";
      (document.getElementById("modal") as HTMLElement).classList.remove('dark');
      dispatch(setDark("light"));
    }else{
      localStorage.theme="dark";
      (document.getElementById("modal") as HTMLElement).classList.add('dark');
      dispatch(setDark("dark"));
    }
  };

  return (
    <span onClick={()=>{goDarkToggle();}} className={props.class_name} >
      {dark.dark=="dark"?props.dark_text:props.light_text}
    </span>
  );
};

DarkMode.defaultProps = {
  dark_text:"다크",
  light_text:"다크",
  class_name:"text-white",
}

export default DarkMode;