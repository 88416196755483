import strFunc from "@/lib/lyg/string";

function SelectChangeArea(props: any) {
  let myProps = {
    check_study_seq_arr: [],
    set_check_study_seq_arr: (inData: any) => { },
    study_arr: [],
    set_study_arr: (inData: any) => { },
    section_arr: [],
    ...props
  };

  const on_check_all=(e:any)=>{
    if(e.target.checked){
      let tmp_check_study_seq_arr=[];
      for(let i=0;i<myProps.study_arr.length;i++){
        tmp_check_study_seq_arr.push(myProps.study_arr[i]["a_seq"]);
      }
      myProps.set_check_study_seq_arr(tmp_check_study_seq_arr);
    }else{
      myProps.set_check_study_seq_arr([]);
    }
  };

  const on_change_select = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let tmp_study_arr = [...myProps.study_arr];
    for (let i = 0; i < myProps.check_study_seq_arr.length; i++) {
      let tmp_seq = myProps.check_study_seq_arr[i];
      for (let j = 0; j < tmp_study_arr.length; j++) {
        if (tmp_study_arr[j]["a_seq"] == tmp_seq) {
          tmp_study_arr[j][opt_obj.name] = opt_obj.value;
        }
      }
    }
    myProps.set_study_arr(tmp_study_arr);
  };

  const remove_select_study=()=>{
    let new_study_arr=[];
    for(let i=0;i<myProps.study_arr.length;i++){
      let tmp_study_info=myProps.study_arr[i];
      if(strFunc.str_in_array(tmp_study_info["a_seq"],myProps.check_study_seq_arr)==-1){
        new_study_arr.push(tmp_study_info);
      }
    }
    myProps.set_study_arr(new_study_arr);
    myProps.set_check_study_seq_arr([]);
  };

  return (
    <div className="mt-1 text-right">
      <span style={{color:"#666"}}>
        선택일괄수정:
      </span>
      <span className="ml-2">
        <label>
          <input type="checkbox" onChange={on_check_all} />
          <span className="ml-1">전체</span>
        </label>
      </span>
      <span className="ml-1">
        <select style={{ width: 80, border: "1px solid gray" }}
          onChange={(e: any) => {
            on_change_select({
              name: "a_amount_sort",
              value: e.target.value,
            });
          }}>
          <option value="">구분</option>
          <option value="개">개</option>
          <option value="쪽">쪽</option>
          <option value="문제">문제</option>
        </select>
      </span>
      <span className="ml-1">
        <select style={{ width: 80, border: "1px solid gray" }}
          onChange={(e: any) => {
            on_change_select({
              name: "a_section_seq",
              value: e.target.value,
            });
          }}
        >
          <option value="">다단원</option>
          {myProps.section_arr.map((section_info: any, section_idx: number) => {
            return (
              <option value={section_info.a_seq} key={section_idx}>{section_info.a_title}</option>
            );
          })}
        </select>
      </span>
      <span className="ml-1">
        <input type="number" style={{ width: 50, border: "1px solid gray" }}
          placeholder="수량"
          onChange={(e: any) => {
            on_change_select({
              name: "a_amount_num",
              value: e.target.value,
            });
          }}
        />
      </span>
      <span>
        <button className="btn-s btn-red ml-1" onClick={() => { remove_select_study(); }}>제외</button>
      </span>
    </div>
  );
};
export default SelectChangeArea;