class TimeNameFunc {
  static get_lecture_info_arr_of_teacher_name = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      teacher_arr: [],
      is_teacher_nickname: false,
      ...inData
    };
    let lecture_arr = opt_obj["lecture_arr"];
    for (let lec_i = 0; lec_i < lecture_arr.length; lec_i++) {
      let lecture_info = lecture_arr[lec_i];
      //선생님이름세팅
      for (let i = 0; i < opt_obj["teacher_arr"].length; i++) {
        let teacher_info = opt_obj["teacher_arr"][i];
        if (lecture_info["a_main_teacher_seq"] == teacher_info["a_seq"]) {
          lecture_info["a_main_teacher"] = teacher_info["a_user_name"];
          if (opt_obj["is_teacher_nickname"]&&teacher_info["a_user_nickname"]) {
            lecture_info["a_main_teacher"] = teacher_info["a_user_nickname"];
          }
        }
      }
      lecture_arr[lec_i] = lecture_info;
    }

    return lecture_arr;
  };
  static get_teacher_room_name_of_td_data_arr = (inData: any) => {
    let opt_obj = {
      td_data_arr: [],
      teacher_arr: [],
      room_arr: [],
      is_teacher_nickname: false,
      ...inData
    };
    let td_data_arr = opt_obj.td_data_arr;
    let teacher_arr = opt_obj.teacher_arr;
    let room_arr = opt_obj.room_arr;

    for (let td_i = 0; td_i < td_data_arr.length; td_i++) {
      let td_data = td_data_arr[td_i];
      for (let i = 0; i < teacher_arr.length; i++) {
        let teacher_info = teacher_arr[i];
        if (td_data["teacher_seq"] == teacher_info["a_seq"]) {
          td_data_arr[td_i]["teacher_name"] = teacher_info["a_user_name"];
          if (opt_obj["is_teacher_nickname"]&&teacher_info["a_user_nickname"]) {
            td_data_arr[td_i]["teacher_name"] = teacher_info["a_user_nickname"];
          }
        }
      }
      for (let i = 0; i < room_arr.length; i++) {
        let room_info = room_arr[i];
        if (td_data["room_seq"] == room_info["a_seq"]) {
          td_data_arr[td_i]["room_name"] = room_info["a_name"];
        }
      }
    }

    return td_data_arr;
  };

}
export default TimeNameFunc;