import DateFunc from '@/lib/lyg/date_func';
import DetailItem from "./item";

function DetailListArea(props:any){
  let myProps:any={
    form_detail_arr:[],
    detail_arr:[],
    onChangeDetail:(inData:any)=>{},
    ...props
  };

  let default_detail_row_data={
    a_ymd:DateFunc.get_date_format(new Date(),"Ymd"),
    a_seq:"",
    a_detail_seq:"",
    a_title:"",
    a_score:0,
    a_max_score:100,
    a_score_letter:"",
    a_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    a_order_num:"",
  };

  let form_detail_arr=myProps.form_detail_arr;
  let detail_arr=myProps.detail_arr;

  const get_detail_arr_by_form=()=>{
    let form_d_arr_len=form_detail_arr.length;
    for(let i=0;i<form_d_arr_len;i++){
      let form_d_info=form_detail_arr[i];
      if(detail_arr.length<i){
        detail_arr.push({...default_detail_row_data});
      }
    }
    return detail_arr;
  };
  detail_arr=get_detail_arr_by_form();

  const get_detail_tags=()=>{
    let detail_tags=detail_arr.map((item:any,idx:number)=>{
      return (
        <DetailItem
          key={idx}
          idx={idx}
          detail_info={item}
          form_d_info={form_detail_arr[idx]}
          onChangeDetail={myProps.onChangeDetail}
        ></DetailItem>
      );
    });
    return detail_tags;
  };

  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width="10%"></col>
            <col width="90%"></col>
          </colgroup>
          <tbody>
            {get_detail_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default DetailListArea;