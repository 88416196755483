import { useState, useRef, useEffect } from 'react';
import Style from "../../css/style.module.css";
import CardBoxTopTitleArea from "./area/title";
import CardBoxTodoPerArea from "./area/todo_per";
import CardBoxAttendArea from "./area/attend";
import strFunc from '@/lib/lyg/string';

const CardBoxArea = (props:any) => {
  let myProps:any={
    user_info:{},
    xColumnArr:{},
    deleteAttend:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    openViewTodoDayPopup:(stu_info:any)=>{},
    ...props
  };

  const get_num_arr_percent=(num_arr:number[])=>{
    let num_arr_len=num_arr.length;
    let num_sum=0;
    for(let i=0;i<num_arr_len;i++){
      num_sum+=num_arr[i];
    }
    let percent=0;
    if(num_sum!=0&&num_arr_len!=0){
      percent=Math.round(num_sum/num_arr_len);
    }
    return percent;
  };

  let attend_in_info=null;
  if(myProps.user_info.attend_in_arr&&myProps.user_info.attend_in_arr.length>0){
    attend_in_info=myProps.user_info.attend_in_arr[0];
  }
  let attend_out_info=null;
  if(myProps.user_info.attend_out_arr&&myProps.user_info.attend_out_arr.length>0){
    attend_out_info=myProps.user_info.attend_out_arr[0];
  }
  let attend_color="#707070";
  let attend_color_arr=[
    {"value":"attend","text":"등원",color:"#53a500"},
    {"value":"late","text":"등원(지각)",color:"#eabf0b"},
    {"value":"absent","text":"결석",color:"#f26b6f"},
    {"value":"out","text":"하원",color:"#7461ff"},
  ];
  if(attend_in_info!=null){
    for(let i=0;i<attend_color_arr.length;i++){
      if(attend_in_info["a_sort_detail"]==attend_color_arr[i]["value"]){
        attend_color=attend_color_arr[i]["color"];
      }
    }
  }
  if(attend_out_info!=null){
    for(let i=0;i<attend_color_arr.length;i++){
      if(attend_out_info["a_sort_detail"]==attend_color_arr[i]["value"]){
        attend_color=attend_color_arr[i]["color"];
      }
    }
  }

  //todo정보
  let todo_color="#707070";
  let todo_stat_data:any={
    "homework":{percent:0,percent_arr:[]},
    "study":{percent:0,percent_arr:[]},
    "video":{percent:0,percent_arr:[]},
    "total":{percent:0,percent_arr:[],count:0,success_cnt:0},
  };
  if(myProps.user_info.todo_arr&&myProps.user_info.todo_arr.length>0){
    let todo_len=myProps.user_info.todo_arr.length;
    for(let i=0;i<todo_len;i++){
      let todo_info=myProps.user_info.todo_arr[i];
      let row_per_num=0;
      if(todo_info["a_success_sort"]=="amount"){
        let a_amount_num=parseInt(strFunc.uncomma(todo_info["a_amount_num"]));
        let a_success_amount=parseInt(strFunc.uncomma(todo_info["a_success_amount"]));
        if(a_amount_num!=0&&a_success_amount!=0){
          row_per_num=Math.round((a_amount_num/a_success_amount)*100);
        }
      }else{
        let a_time_sec=parseInt(strFunc.uncomma(todo_info["a_time_sec"]));
        let a_success_time_sec=parseInt(strFunc.uncomma(todo_info["a_success_time_sec"]));
        if(a_time_sec!=0&&a_success_time_sec!=0){
          row_per_num=Math.round((a_time_sec/a_success_time_sec)*100);
        }
      }
      todo_stat_data["total"].percent_arr.push(row_per_num);
      todo_stat_data["total"]["count"]++;
      if(todo_info["a_is_success"]=="1"){
        todo_stat_data["total"]["success_cnt"]++;
      }
      let a_sort=todo_info["a_sort"];
      if(todo_stat_data[a_sort]){
        todo_stat_data[a_sort].percent_arr.push(row_per_num);
      }
    }
    //total합계퍼센트
    todo_stat_data["homework"]["percent"]=get_num_arr_percent(todo_stat_data["homework"]["percent_arr"]);
    todo_stat_data["study"]["percent"]=get_num_arr_percent(todo_stat_data["study"]["percent_arr"]);
    todo_stat_data["video"]["percent"]=get_num_arr_percent(todo_stat_data["video"]["percent_arr"]);
    todo_stat_data["total"]["percent"]=get_num_arr_percent(todo_stat_data["total"]["percent_arr"]);
    todo_color="#f26b6f";
    if(todo_stat_data["homework"]["percent"]>0){
      todo_color="#eabf0b";
    }
    if(todo_stat_data["homework"]["percent"]==100){
      todo_color="#53a500";
    }
  }
  
  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_back} style={{background:todo_color}}></div>
      <div className={Style.card_box_con}>
        <CardBoxTopTitleArea 
          user_info={myProps.user_info}
          todo_stat_data={todo_stat_data}
          todo_color={todo_color} 
          openViewTodoDayPopup={myProps.openViewTodoDayPopup}></CardBoxTopTitleArea>
        <CardBoxTodoPerArea 
          user_info={myProps.user_info}
          todo_stat_data={todo_stat_data}
          todo_color={todo_color} ></CardBoxTodoPerArea>
        <CardBoxAttendArea 
          user_info={myProps.user_info}
          attend_in_info={attend_in_info}
          attend_out_info={attend_out_info}
          attend_color={attend_color}
          xColumnArr={myProps.xColumnArr}
          deleteAttend={myProps.deleteAttend}
          openAttendPopup={myProps.openAttendPopup}></CardBoxAttendArea>
      </div>
    </div>
  );
};

export default CardBoxArea;