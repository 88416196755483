import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import TradeListArea from "./area/trade_list";
import strFunc from '@/lib/lyg/string';

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["student"],
      s_addon_class_user:"1",
      s_addon_trade_tot:"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [select_seq_arr,set_select_seq_arr]=useState([]);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);
  const TradeListAreaRef=useRef<any>(null);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    stu_seq:"",
    stu_name:"",
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
    getClassFrontArr();
  }, []);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    let tmpPageData={...pageData};
    tmpPageData.listOpt[name]=value;
    setPageData({
      ...tmpPageData
    });
    if(name=="s_search_text"){
      return false;
    }
    list({[name]: value,"now_page":1});
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let tmpPageData={...pageData};
    tmpPageData.listOpt[key]=date_str;
    setPageData({
      ...tmpPageData
    });
    list({[key]: date_str,"now_page":1});
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr(response.data["data"]["class_arr"]);
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.data.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.data.class_arr.length;i++){
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_trade_tot"){
      if(params.data.trade_tot_info){
        render_str=strFunc.comma(params.data.trade_tot_info.remain_money);
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged=()=>{
    setTimeout(()=>{
      let tmp_select_seq_arr:any=[];
      let stu_name_arr:any[]=[];
      let trade_tot_info:any={};
      let selected_row=listAggridRef.current.getSelectedRows();
      for(let i=0;i<selected_row.length;i++){
        tmp_select_seq_arr.push(selected_row[i].a_seq);
        stu_name_arr.push(selected_row[i].a_user_name);
        if(i==0){
          if(selected_row[i]["trade_tot_info"]){
            trade_tot_info=selected_row[i]["trade_tot_info"];
          }
        }
      }
      TradeListAreaRef.current.setInitData({
        "stu_seq_arr":tmp_select_seq_arr,
        "stu_name_arr":stu_name_arr,
        "trade_tot_info":trade_tot_info,
      });
      set_select_seq_arr(tmp_select_seq_arr);
    },100);
  };

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{minWidth:230}}>
            <div className="relative" style={{minHeight:55}}>
              <div className="search-box relative py-0">
                <select className="search-input_select" name="s_class_seq" value={pageData.listOpt.s_class_seq} onChange={handleInputChange} >
                  <option value="">클래스 전체</option>
                  {
                    classSelectArr.map((item:any,idx:number)=>{
                      return (
                        <option value={item.value} key={idx}>{item.text}</option>
                      );
                    })
                  }
                </select>
                <select className="search-input_select" name="s_stu_state" value={pageData.listOpt.s_stu_state} onChange={handleInputChange} >
                  <option value="">현황 전체</option>
                  {
                    xColumnArr.select_arr.a_stu_state.map((item:any,idx:number)=>{
                      return (
                        <option value={item.value} key={idx}>{item.text}</option>
                      );
                    })
                  }
                </select>
              </div>
              <div className="search-box relative py-0 ml-0 mt-1">
                <select className="search-input_select" name="s_search_text_type" value={pageData.listOpt.s_search_text_type} onChange={handleInputChange} >
                  <option value="name">이름</option>
                  <option value="phone">핸드폰</option>
                </select>
                <input type="text" className="search-input" name="s_search_text" 
                  style={{width:120}} placeholder="검색어 입력" value={pageData.listOpt.s_search_text}
                  onChange={handleInputChange} onKeyUp={(e:any)=>{if(e.key === 'Enter'){list({"now_page":1});}}} />
                <button className="btn btn-gray" onClick={()=>{list({"now_page":1});}} >검색</button>
              </div>
            </div>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{...xColumnArr}}
              list={list}
              gridOpt={{
                fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num:false,
                is_add_checkbox:false,
                floatingFilter:false,
                onGridReady:()=>{
                  list({});
                },
                onCellClicked:cellClickedListener,
                cellRenderer:cellRenderer,
                onSelectionChanged:onSelectionChanged
              }}
              ></ListAggrid>
              <Paging now_page={pageData.listOpt.now_page}
                num_per_page={pageData.listOpt.num_per_page}
                total_rec={countInfo.tot} 
                onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
          </div>
          <div className="grow" style={{minWidth:450}} >
            <TradeListArea
              ref={TradeListAreaRef}
              list={list}
            ></TradeListArea>
          </div>
        </div>
      </div>
    </CompLayout>
  );
}

export default ListPage;