import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";

function UserListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_listOpt=pageData.listOpt;
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    tmp_listOpt["s_mcomp_seq"]=user.comp_seq;
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/trade/mpos_card/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    alert("삭제불가");
    return false;
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_json_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/trade/mpos_card/delete',del_form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.user_data_create_date="";
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
   
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;

    if(key=="a_pay_money"){
      render_str=strFunc.comma(render_str);
    }

    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="btn-box-left relative h-8">
          <SearchArea
            listOpt={pageData.listOpt}
            list={list}
          ></SearchArea>
          <div className="absolute right-0 top-0 btn-box-right">
            {/* <button className="btn btn-dark" onClick={goDelete}>삭제</button> */}
          </div>
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:false,
          floatingFilter:true,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
      </div>
    </CompLayout>
  );
}

export default UserListPage;