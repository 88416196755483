import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function WritePopupContent(props: any) {
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  let user = useSelector((state: any) => state.user);
  const [rowData, setRowData] = useState({
    "a_seq": "",
    "a_corp_seq": "1",
    "a_user_id": "",
    "a_user_pw": "",
    "a_user_grade": "teacher",
    "a_is_login": "1",
    "a_user_name": "",
    "a_user_nickname": "",
    "a_user_phone": "",
    "a_stu_num": "",
    "a_main_subject": "",
    "invite_code": "",
  });
  const [stu_num_msg_data, set_stu_num_msg_data] = useState({
    is_show: false,
    msg: "사용가능",
    color: "green",
  });
  let select_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  useEffect(() => {
    if (props.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (props.rowData[key] != undefined) {
          changeRowData[key] = props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name == "a_user_phone") {
      value = strFunc.autoHypenPhone(value);
    } else if (name == "a_stu_num") {
      checkStuNumOfUser(value);
      value = strFunc.getNumber2(value);
    }

    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const checkStuNumOfUser = (stu_num: string) => {
    if (stu_num == "" || stu_num == "0") {
      return false;
    }
    stu_num = strFunc.getNumber2(stu_num);

    //이미 있는지 체크
    let checkFormData = {
      "s_stu_num_now": stu_num,
      "s_except_user_seq": rowData.a_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', checkFormData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            set_stu_num_msg_data({
              is_show: true,
              msg: "해당 출결번호는 이미있는 있습니다. " + u_info["a_user_name"] + "",
              color: "#ff5757",
            });
            // myProps.setRowData({
            //   ...myProps.rowData,
            //   "a_stu_num":""
            // });
          } else {
            set_stu_num_msg_data({
              is_show: true,
              msg: "사용가능",
              color: "green",
            });
          }
        }
      });
  };

  const goWrite = useCallback((e: any) => {
    if (rowData.a_user_id == "") {
      alert("아이디 입력이 필요합니다.");
      return false;
    }
    if (!props.isUpdate) {
      if (rowData.a_user_pw == "") {
        alert("암호 입력이 필요합니다.");
        return false;
      }
    }
    if (rowData.a_user_name == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let formJsonData = {
      "data_arr": [rowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          props.callback(response.data["data"]);
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  const makeInviteCode = () => {
    if (rowData.a_seq == "") {
      alert("유저키 없음.");
      return false;
    }
    if (!confirm("초대코드를 생성 하시겠습니까?")) {
      return false;
    }
    let inivte_make_code_from_data = {
      "mcomp_seq": "",
      "user_seq": rowData.a_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/invite_code/make_code',
      inivte_make_code_from_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setRowData({
            ...rowData,
            ...{ "invite_code": response.data["data"]["invite_code"] }
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>아이디</th>
              <td>
                <input type="text" className="row-input" name="a_user_id" value={rowData.a_user_id} onChange={handleInputChange} placeholder="아이디" />
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <input type="password" className="row-input" name="a_user_pw" value={rowData.a_user_pw} onChange={handleInputChange} placeholder="비밀번호" />
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" className="row-input" name="a_user_name" value={rowData.a_user_name} onChange={handleInputChange} placeholder="이름" />
              </td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td>
                <input type="text" className="row-input" name="a_user_nickname" value={rowData.a_user_nickname} onChange={handleInputChange} placeholder="닉네임" />
              </td>
            </tr>
            <tr>
              <th>핸드폰</th>
              <td>
                <input type="text" className="row-input" name="a_user_phone" value={rowData.a_user_phone} onChange={handleInputChange} placeholder="000-0000-0000" />
              </td>
            </tr>
            <tr>
              <th>등급</th>
              <td>
                <select className="row-input" name="a_user_grade" value={rowData.a_user_grade} onChange={handleInputChange} >
                  <option value="teacher">선생님</option>
                  <option value="staff">직원</option>
                  <option value="master">관리자</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>로그인허용</th>
              <td>
                <select className="row-input" name="a_is_login" value={rowData.a_is_login} onChange={handleInputChange} >
                  <option value="1">허용</option>
                  <option value="">미허용</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>출결번호</th>
              <td>
                <input type="text" className="row-input" name="a_stu_num"
                  value={strFunc.getNumber2(rowData.a_stu_num)}
                  onChange={handleInputChange}
                  placeholder="00000" />

                {stu_num_msg_data.is_show &&
                  <div>
                    <span style={{ color: stu_num_msg_data.color }}>{stu_num_msg_data.msg}</span>
                  </div>
                }
              </td>
            </tr>
            <tr>
              <th>과목</th>
              <td>
                <input type="text" className="row-input" name="a_main_subject"
                  value={rowData.a_main_subject}
                  onChange={handleInputChange}
                  placeholder="과목" style={{width:80}} />
                <select className="row-input" name="a_main_subject"
                  value={rowData.a_main_subject}
                  onChange={handleInputChange}
                  style={{width:80}} >
                    <option value="">과목선택</option>
                    {select_option_arr.map((item:any,idx:number)=>{
                      return (
                        <option key={idx} value={item.value}>{item.text}</option>
                      );
                    })}
                </select>
              </td>
            </tr>
            {rowData.a_seq != "" &&
              <tr>
                <th>초대코드</th>
                <td>
                  {rowData["invite_code"]}
                  <button className="btn btn-dark ml-2" onClick={() => { makeInviteCode(); }}>생성</button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
