import strFunc from "@/lib/lyg/string";

function VideoArea(props:any){
   let myProps={
    file_arr:[],
    goDelete:(inData:any)=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;
  const get_tag_data_arr=()=>{
    let select_file_arr=[];
    for(let i=0;i<myProps.file_arr.length;i++){
      let file_info=myProps.file_arr[i];
      if(file_info["a_sort1"]=="video"){
        select_file_arr.push(file_info);
      }
    }
    return select_file_arr;
  };
  const get_tag_arr=()=>{
    let select_file_arr=get_tag_data_arr();
    let div_tags:any = select_file_arr.map((item: any, idx: number) => {

      let audio_url=item.a_serverurl+"/"+item.a_path+item.a_savename;
      let audio_type=item.a_type;

      return (
        <div key={idx} className={Style.sub_video_wrap_item} title={item.a_oriname}>
          <div>{item.a_oriname}</div>
          <video  controls>
            <source src={audio_url} type={audio_type}></source>
            Your browser does not support the audio element.
          </video>
          <button className="btn-s btn-red px-2" onClick={() => { myProps.goDelete(item); }}
            style={{ position: "absolute", right: 0, top: 0 }}>x</button>
        </div>
      );
    });

    if(strFunc.is_empty(div_tags)){
      div_tags=(
        <div style={{width:"100%",textAlign:"center",lineHeight:"30px",color:"#999"}}>
          내용이 없습니다.
        </div>
      );
    }

    return div_tags;
  }

  return (
  <div>
    <div className={Style.sub_title}>동영상</div>
    <div className={Style.sub_video_wrap}>
      {get_tag_arr()}
    </div>
  </div>
  );
};
export default VideoArea;