import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import { useState,useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import LoadingComponent from "@/pcomponents/common/content/loading";

function WriteArea(props:any){
   let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    user:{},
    writer_seq:"",
    writer_name:"",
    par_id:"",
    par_seq:"",
    par_locate:"",
    par_locate_name:"",
    is_send_write_push:false,
    infoArr:[],
    close_write:()=>{},
    list:()=>{},
    ...props
  };
  let select_mcomp_data=myProps.select_mcomp_data;
  let user=myProps.user;
  let a_responser_seq=user.user_seq;
  if(strFunc.is_empty(select_mcomp_data.api_user_seq)){
    a_responser_seq=select_mcomp_data.api_user_seq;
  }
  let qna_sort_init="q";
  if(myProps.writer_seq==a_responser_seq){
    qna_sort_init="a";
  }
  const [info,set_info]=useState({
    "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
    "a_seq":"",
    "a_par_id":myProps.par_id,
    "a_par_seq":myProps.par_seq,
    "a_par_locate":myProps.par_locate,
    "a_par_locate_name":myProps.par_locate_name,
    "a_qna_sort":qna_sort_init,//질문답변(q,a)
    "a_writer":myProps.writer_name,
    "a_writer_seq":myProps.writer_seq,
    "a_responser":user.user_name,
    "a_responser_seq":a_responser_seq,
    "a_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "a_message":"",
    "a_is_view_writer":"",
    "a_view_date":"",
  });
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });

  useEffect(()=>{
    
  },[]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  const write = ()=>{
    if(strFunc.is_empty(info["a_message"])){
      alert("내용이 없습니다.");
      return false;
    }
    let w_info=info;
    let receiver_seq_arr=[];
    if (myProps.writer_seq!=user.user_seq) {
      receiver_seq_arr.push(myProps.writer_seq);
    }
    for(let i=0;i<myProps.infoArr.length;i++){
      let pre_info=myProps.infoArr[i];
      if(pre_info["a_responser_seq"]!=user.user_seq){
        if(strFunc.str_in_array(pre_info["a_responser_seq"],receiver_seq_arr)==-1){
          receiver_seq_arr.push(pre_info["a_responser_seq"]);
        }
      }
    }

    let w_form_json:any={
      "data_arr":[w_info],
      "is_default_val":"1",
      "is_update":"",
      "is_send_write_push":myProps.is_send_write_push?"1":"",
      "receiver_seq_arr":receiver_seq_arr,
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        w_form_json["api_key"]=select_mcomp_data.api_key;
        w_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/comment/comment/write',w_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setLoadingData({
          is_display: true,
          text: "저장되었습니다."
        });
        setTimeout(() => {
          setLoadingData({
            is_display: false,
            text: ""
          });
        }, 1200);
        myProps.list({});
        myProps.close_write();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  
  return (
  <div className="write_table_small">
    <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}></LoadingComponent>
    <table>
      <colgroup>
        <col width={65}></col>
        <col width={"*"}></col>
        <col width={50}></col>
      </colgroup>
      <tbody>
        <tr>
          <td>
            <select className="row-input" name="a_qna_sort" 
              value={info["a_qna_sort"]} onChange={handleInputChange} 
              style={{fontSize:"12px"}}>
              <option value="q">질문</option>
              <option value="a">답변</option>
            </select>
          </td>
          <td>
            <TextAreaComponent
              name={"a_message"}
              value={info["a_message"]}
              height={28}
              onChange={handleInputChange}
            ></TextAreaComponent>
          </td>
          <td className="text-center">
            <div style={{position:"relative"}}>
              <button className="btn btn-dark" onClick={()=>{write();}} >작성</button>
              <button className="btn-s btn-gray" 
                onClick={()=>{myProps.close_write();}}
                style={{position:"absolute",top:-8,right:-5}}>-</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
};
export default WriteArea;