import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import { default_info } from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";
import MonthPrescribeWriteArea from "@/pages/comp/edu/month_prescribe/common/write";
import strFunc from "@/lib/lyg/string";
import LoadingComponent from "@/pcomponents/common/content/loading";
import { default_daily_report_last_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";

const WriteAreaComponent = forwardRef((props: any, ref) => {
  let myProps = {
    list: (inData: any) => { },
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let init_page_data = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  const [isUpdate, setIsUpdate] = useState(false);
  const [writer_info, set_writer_info] = useState<any>({});
  const [rowData, setRowData] = useState({
    ...default_info,
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_reserve_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
  });
  const [select_mcomp_data,set_select_mcomp_data] = useState<any>({
    mcomp_seq: "",
    api_key: "",
    api_user_seq: "",
    ...myProps.select_mcomp_data
  });
  const [comment_list, set_comment_list] = useState([]);
  const [last_daily_report, set_last_daily_report] = useState<any>({...default_daily_report_last_info});
  const [pageData, setPageData] = useState(init_page_data);
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });
  const update_cool_time_ref = useRef(0);
  const is_change_data_ref = useRef(false);

  useEffect(() => {
    get_writer_info_by_ajax();
  }, [user,select_mcomp_data.mcomp_seq]);

  useEffect(() => {
    const save_loop = setInterval(save_interval, 1000);
    return () => {
      clearInterval(save_loop);
    };
  }, [rowData, isUpdate]);
  const save_interval = () => {
    update_cool_time_ref.current += 1;
    if (isUpdate && is_change_data_ref.current) {
      if (update_cool_time_ref.current > 5) {
        update_cool_time_ref.current = 0;
        is_change_data_ref.current = false;
        go_update_auto_by_ajax();
      }
    }
  };

  const initData = (inData: any) => {
    let opt_obj = {
      rowData: default_info,
      isUpdate: false,
      select_mcomp_data:{
        mcomp_seq: "",
        api_key: "",
        api_user_seq: "",
      },
      ...inData
    };
    if (opt_obj["isUpdate"]) {
      setPageData({
        "write_btn_text": "저장 수정",
        "confirm_msg": "저장 하시겠습니까?",
      });
      get_comment_list_by_ajax({
        row_data: opt_obj["rowData"],
        select_mcomp_data:{
          ...opt_obj["select_mcomp_data"]
        },
      });
      get_last_daily_report_by_ajax({
        row_data: opt_obj["rowData"],
        select_mcomp_data:{
          ...opt_obj["select_mcomp_data"]
        },
      });
    } else {
      set_comment_list([]);
      setPageData(init_page_data);
      opt_obj["rowData"]["a_ymd"] = DateFunc.get_date_format(new Date(), "Ymd");
      opt_obj["rowData"]["a_reserve_date"] = DateFunc.get_date_format(new Date(), "Y-m-d");
      if (!strFunc.is_empty(writer_info["a_seq"])) {
        opt_obj["rowData"]["a_writer_seq"] = writer_info["a_seq"];
        opt_obj["rowData"]["a_writer_name"] = writer_info["a_user_name"];
        opt_obj["rowData"]["a_subject_name"] = writer_info["a_main_subject"];
      }
    }
    setRowData(opt_obj["rowData"]);
    setIsUpdate(opt_obj["isUpdate"]);
    set_select_mcomp_data(opt_obj["select_mcomp_data"]);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    initData
  }));

  const get_writer_info_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let form_json_data:any = {
      "s_pri_arr": [user.user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
        form_json_data["s_pri_arr"]=[select_mcomp_data.api_user_seq];
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let writer_user_info = response.data["data"]["info_arr"][0];
            set_writer_info(response.data["data"]["info_arr"][0]);
            setRowData({
              ...rowData,
              a_writer_seq: writer_user_info["a_seq"],
              a_writer_name: writer_user_info["a_user_name"],
              a_subject_name: writer_user_info["a_main_subject"],
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_comment_list_by_ajax = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      set_comment_list([]);
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData:any = {
      "s_par_id": "month_prescribe",
      "s_par_seq": row_pri_val,
      "order_id": "a_create_date",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        formJsonData["api_key"]=select_mcomp_data.api_key;
        formJsonData["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_last_daily_report_by_ajax = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      set_last_daily_report({...default_daily_report_last_info});
      return false;
    }
    if (strFunc.is_empty(opt_obj["row_data"]["a_stu_seq"])
      || strFunc.is_empty(opt_obj["row_data"]["a_subject_name"])) {
      set_last_daily_report({...default_daily_report_last_info});
      return false;
    }
    
    if(opt_obj.row_data.daily_info_arr&&opt_obj.row_data.daily_info_arr.length>0){
      set_last_daily_report({...default_daily_report_last_info,...opt_obj.row_data.daily_info_arr[0]});
      return false;
    }
    if(opt_obj.row_data.daily_last_info_arr&&opt_obj.row_data.daily_last_info_arr.length>0){
      set_last_daily_report({...default_daily_report_last_info,...opt_obj.row_data.daily_last_info_arr[0]});
      return false;
    }
  };

  const go_write = () => {
    if (strFunc.is_empty(rowData.a_stu_seq)) {
      alert("학생 선택 필요.");
      return false;
    }
    if (strFunc.is_empty(rowData.a_writer_seq)) {
      alert("작성자 정보 없음.");
      return false;
    }
    if (strFunc.is_empty(rowData.a_subject_name)) {
      alert("과목선택 필요.");
      return false;
    }

    // if (!confirm(pageData.confirm_msg)) {
    //   return false;
    // }

    let w_row_data = { ...rowData };
    if (isUpdate == false) {
      w_row_data["a_ymd"] = strFunc.getNumber2(w_row_data["a_reserve_date"]);
    }

    let form_json_data: any = {
      "data_arr": [w_row_data],
      "is_update": "",
    };
    if (isUpdate) {
      form_json_data["is_update"] = "1";
    } else {
      form_json_data["is_default_val"] = "1";
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "저장되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          let last_info = response.data["data"][0];
          myProps.list({ select_seq: last_info["a_ymd"] + "," + last_info["a_seq"] });
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const go_update_auto_by_ajax = () => {
    return false;
    if (strFunc.is_empty(rowData.a_stu_seq)) {
      return false;
    }
    if (strFunc.is_empty(rowData.a_writer_seq)) {
      return false;
    }
    if (strFunc.is_empty(rowData.a_subject_name)) {
      return false;
    }
    let form_json_data: any = {
      "data_arr": [rowData],
      "is_update": "1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        form_json_data["api_key"]=select_mcomp_data.api_key;
        form_json_data["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.list({ select_seq: rowData.a_ymd + "," + rowData.a_seq });
        } else {
          //alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
      <MonthPrescribeWriteArea
        select_mcomp_data={select_mcomp_data}
        rowData={rowData}
        setRowData={(inData: any) => {
          is_change_data_ref.current = true;
          setRowData(inData);
          update_cool_time_ref.current = 0;
        }}
        isUpdate={isUpdate}
        list={myProps.list}
        comment_list={comment_list}
        is_view_mode={false}
        is_view_stu_info={true}
        last_daily_report={last_daily_report}
        set_last_daily_report={set_last_daily_report}
        set_refresh={(inData: any) => { }}
      ></MonthPrescribeWriteArea>
      <div className="text-center mt-1">
        <button className="btn btn-line-gray" onClick={() => { go_write(); }} >{pageData.write_btn_text}</button>
      </div>
    </div>
  );
});
export default WriteAreaComponent;