import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import search_ico from "@/img/ico/search.png";
import getXcolumnJson from "@/pages/comp/basic/student/list/xcolumn/list";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';

const SearchArea = forwardRef((props:any, ref) => {
  let myProps = {
    listOpt:{},
    list:(inData:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  let listOpt=myProps.listOpt;
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);
  useEffect(()=>{
    getClassFrontArr();
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="s_search_text"){
      return false;
    }
    props.list({now_page:1,[name]: value});
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    props.list({now_page:1,[key]: date_str});
  };

  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr(response.data["data"]["class_arr"]);
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  let oder_id_select_arr=[
    {"text":"등록순","value":"a_create_date"},
    {"text":"등록역순","value":"a_create_date DESC"},
    {"text":"이름순","value":"a_user_name"},
    {"text":"출결번호","value":"user_stu_num"},
    {"text":"출결번호역순","value":"user_stu_num DESC"},
  ];

  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        원생검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={listOpt.s_user_name_like} onChange={handleInputChange} placeholder="이름" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_school_name_like"
            value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_class_name_like"
            value={listOpt.s_class_name_like} onChange={handleInputChange} placeholder="클래스" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
          <option value="">학년</option>
          {
            xColumnArr.select_arr.a_stu_grade.map((item:any,idx:number)=>{
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
          <option value="">전체</option>
          {
            xColumnArr.select_arr.a_stu_state.map((item:any,idx:number)=>{
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
          </select>
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-dark" onClick={()=>{props.list({now_page:1});}} style={{padding:"9px 10px"}} >검색</button>
        </div>
      </div>
      <div style={{"textAlign":"center",marginTop:15}}>
        기간:
        <div style={{display:"inline-block",width:90}}>
          <DatePicker 
            wrapperClassName="pl-1"
            selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
            onChange={(date:Date) => {
              onChangeDatePicker("s_start_date",date);
            }}
            locale={ko} 
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{display:"inline-block",width:90}}>
          <DatePicker 
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
            onChange={(date:Date) => {
              onChangeDatePicker("s_end_date",date);
            }}
            locale={ko} 
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
            s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d")
          });
        }}>오늘</button>
        <button className="btn-s2 btn-sky ml-1" onClick={() => {
          myProps.list({
            "now_page": 1,
            s_start_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
            s_end_date: DateFunc.get_date_format(new Date(), "Y-m-t")
          });
        }}>이번달</button>
      </div>
    </div>
  );
});

export default SearchArea;