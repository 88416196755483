import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"video_corse",
    list_id:"video_corse_list",
    x_column_list_arr:[
      {"key":"a_date","name":"상담일","width":"120","is_show":"1"},
      {"key":"a_stu_name","name":"원생명","width":"100","is_show":"1"},
      {"key":"a_title","name":"제목","width":"120","is_show":"1"},
      {"key":"a_writer","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"120","is_show":"1"},
      {"key":"a_sort1","name":"구분1","width":"100","is_show":"1"},
      {"key":"a_sort2","name":"구분2","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_title',
      'order_type':'',
      's_date_type':'a_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;