import { useState, useRef, useEffect } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import StuViewArea from "./area/stu_area";
import GoldHistoryList from "./area/gold_history";

function CharOptGoldOfStuOnePopup(props:any){
   let myProps={
    stu_seq:"",
    closePopup: () => {},
    callback:(inData:any)=>{},
    ...props
  };

  const [stu_info,set_stu_info]=useState<any>({});
  const GoldHistoryListRef=useRef<any>(null);

  useEffect(()=>{
    get_stu_info_by_ajax();
  },[]);

  const get_stu_info_by_ajax=()=>{
    if(strFunc.is_empty(myProps.stu_seq)){
      alert("학생키가 없습니다.");
      myProps.closePopup();
      return false;
    }

    let user_list_opt={
      "s_pri_arr":[myProps.stu_seq],
      s_addon_comp_link: "1",
      s_addon_comp_chat_opt_by_comp_link:"1",
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',user_list_opt,get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        if(response.data["data"]["info_arr"].length>0){
          let tmp_stu_info=response.data["data"]["info_arr"][0];
          set_stu_info(tmp_stu_info);
        }else{
          alert("학생 정보가 없습니다.");
          myProps.closePopup();
        }
      } else {
        alert(response.data["msg"]);
        myProps.closePopup();
      }
    });
  };

  let is_exist_char_opt=false;
  if(stu_info.char_opt){
    is_exist_char_opt=true;
  }
  
  return (
  <div>
    {stu_info.a_seq&&
      <StuViewArea
        stu_info={stu_info}
        refresh_data={()=>{
          get_stu_info_by_ajax();
          if(GoldHistoryListRef.current){
            GoldHistoryListRef.current.list({});
          }
        }}
      ></StuViewArea>
    }
    {is_exist_char_opt&&
    <div>
      <GoldHistoryList
        ref={GoldHistoryListRef}
        main_user_seq={stu_info.char_opt["a_main_user_seq"]}
        refresh_data={()=>{get_stu_info_by_ajax();}}
      ></GoldHistoryList>
    </div>
    }
    <div className="mt-1 text-center">
      <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
    </div>
  </div>
  );
};
export default CharOptGoldOfStuOnePopup;