import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";

const SearchArea = forwardRef((props:any, ref) => {
  const [listOpt,setListOpt] = useState(props.listOpt);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);
  useEffect(()=>{
    
    getClassFrontArr();
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({[name]: value});
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr(response.data["data"]["class_arr"]);
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  return (
    <div className="search-box relative">
      <select className="search-input_select" name="s_class_seq" value={listOpt.s_class_seq} onChange={handleInputChange} >
        <option value="">클래스 전체</option>
        {
          classSelectArr.map((item:any,idx:number)=>{
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_school_name" value={listOpt.s_school_name} onChange={handleInputChange}>
        <option value="">학교 전체</option>
        {
          schoolNameSelectArr.map((item:any,idx:number)=>{
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
        <option value="">학년 전체</option>
        {
          props.xColumnArr.select_arr.a_stu_grade.map((item:any,idx:number)=>{
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search-input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
        <option value="">현황 전체</option>
        {
          props.xColumnArr.select_arr.a_stu_state.map((item:any,idx:number)=>{
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <button className="btn btn-dark" onClick={()=>{goSearch();}} >검색</button>
    </div>
  );
});

export default SearchArea;