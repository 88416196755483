import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import { useSelector, useDispatch } from 'react-redux';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import ListCardComponent from "./area/list";
import ListSearchComponent from "./area/search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from "../popup/write";
import ViewPopupContent from "../popup/view";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function TodoList(){
  const navigate = useNavigate();
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      's_start_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
      's_end_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
      "s_stu_mcomp_seq":user.comp_seq,
      "s_stu_user_seq":user.user_seq,
      "s_addon_file_arr":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const ListCardComponentRef=useRef<any>(null);
  const ListSearchComponentRef=useRef<any>(null);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"100%",
    height:"80%",
  });

  useEffect(() => {
    
    list({});
  }, [user.user_seq]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_listOpt={...pageData.listOpt};
    if(user.user_seq==""){
      return false;
    }
    tmp_listOpt.s_stu_mcomp_seq=user.comp_seq;
    tmp_listOpt.s_stu_user_seq=user.user_seq;
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        if(ListCardComponentRef.current){
          ListCardComponentRef.current.setInitData({
            "infoArr":response.data["data"]["info_arr"]
          });
        }
      }else{
        
      }
    });
  };

  const deleteOne = (row_data:any)=>{
    if(!confirm("삭제 하시겠습니까?")){
      return false;
    }
    if(user.user_seq==""){
      return false;
    }
    if(strFunc.is_empty(row_data.a_seq)){
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let del_form_data={
      "data_arr":[row_data]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const completeOne = (row_data:any)=>{
    if(!confirm("완료처리 하시겠습니까?")){
      return false;
    }
    if(user.user_seq==""){
      return false;
    }
    if(strFunc.is_empty(row_data.a_seq)){
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let del_form_data={
      "todo_seq_arr":[row_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/complete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const openOnePopup=(sort:string,isUpdate:boolean,rowData:any)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(rowData){
      tmp_rowData=rowData;
    }
    if(isUpdate){
      pop_title="수정";
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const go_view_page=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData,
    };

    let send_data={
      "row_data":opt_obj.row_data,
      "todo_info":opt_obj.row_data,
      list_opt:pageData.listOpt,
    };
    let view_url="/comp_stu/plan/todo_list_view";
    if(opt_obj.row_data["a_par_id"]=="video_list"){
      view_url="/comp_stu/plan/todo_list_view_video";
    }
    navigate(view_url,
      { state: send_data });

  };

  return (
    <CompLayout isConTitle={false} >
      <div className="stu_con_wrap">
        <ListSearchComponent
          ref={ListSearchComponentRef}
          listOpt={pageData.listOpt}
          xColumnArr={xColumnArr}
          list={(inData:any)=>{list(inData);}}
          onClickWrite={()=>{openOnePopup("write",false,{});}}
        ></ListSearchComponent>
        <ListCardComponent
          ref={ListCardComponentRef}
          xColumnArr={xColumnArr}
          onClickRow={go_view_page}
          onClickModify={(inData:any)=>{
            openOnePopup("write",true,inData.row_data);
          }}
          onClickDeleteRow={(inData:any)=>{
            deleteOne(inData.row_data);
          }}
          onClickComplete={(inData:any)=>{
            completeOne(inData.row_data);
          }}
          onClickMore={(inData:any)=>{
            openOnePopup("view",false,inData.row_data);
          }}
        ></ListCardComponent>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent 
              isUpdate={popupData.isUpdate} 
              rowData={popupData.rowData} callback={()=>{list({});}} 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
            ></WritePopupContent>
          }
          {popupData.sort=="view"&&
            <ViewPopupContent
              rowData={popupData.rowData} callback={()=>{list({});}} 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
              openOnePopup={openOnePopup}
            ></ViewPopupContent>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default TodoList;