import strFunc from '@/lib/lyg/string';

function ViewPopupContent(props: any) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>구분</th>
              <td>{props.rowData.a_sort}</td>
              <th>공개</th>
              <td>{(props.rowData.a_public_sort == "1") ? "공개" : "미공개"}</td>
            </tr>
            <tr>
              <th>날짜</th>
              <td colSpan={3}>
                {props.rowData.a_date}
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3} style={{ color: props.rowData.a_color }}>
                {props.rowData.a_title}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div style={{minHeight:40}}>
                  {
                    props.rowData.a_board_content && props.rowData.a_board_content.split('\n').map((line: string, idx: number) => {
                      return (<span key={idx}>{line}<br /></span>)
                    })
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{props.rowData.a_writer}</td>
              <th>조회수</th>
              <td>{props.rowData.a_read_cnt}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={() => { props.closePopup(); props.openOnePopup("write", true); }}>수정</button>
        <button className="btn btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
