//import LygCalendarFunc from '@/lib/lyg/calendar';
import DateFunc from '@/lib/lyg/date_func';

class LygCalendarFunc
{
  static get_cal_data_by_select_date(select_date){
    let select_month_obj = new Date(select_date);
    let now_date_json = DateFunc.get_date_json(new Date());
    let pre_month_obj = DateFunc.get_change_date(new Date(select_date.substring(0, 7) + "-01"), 'month', -1);
    let after_month_obj = DateFunc.get_change_date(new Date(select_date.substring(0, 7) + "-01"), 'month', +1);
    let pre_month_date = DateFunc.get_date_format(pre_month_obj, "Y-m-01");
    let after_month_date = DateFunc.get_date_format(after_month_obj, "Y-m-01");
    let cal_data = {
      select_month_json: DateFunc.get_date_json(select_month_obj),
      now_date_json:now_date_json,
      pre_month_json: DateFunc.get_date_json(pre_month_obj),
      after_month_json: DateFunc.get_date_json(after_month_obj),
      select_month_week_len: 5,
      pre_month_week_len:5,
      after_month_week_len:5,
      pre_month_date:pre_month_date,
      after_month_date:after_month_date,
      now_date_str:DateFunc.get_date_format(new Date(),"Y-m-d"),
    };
    cal_data.select_month_week_len = cal_data.select_month_json.week_length;
    cal_data.pre_month_week_len = cal_data.pre_month_json.week_length;
    cal_data.after_month_week_len = cal_data.after_month_json.week_length;
    return cal_data;
  }
  static get_td_data_arr(inData){
    let opt_obj={
      cal_data:{
        select_month_json: {},
        pre_month_json: {},
        after_month_json: {},
        select_month_week_len: 5,
      },
      ...inData,
    };
    let cal_data=opt_obj.cal_data;

    let td_data_arr= [];
    let day_num = 0;
    let pre_day_num = cal_data.pre_month_json.t - cal_data.select_month_json.first_day_of_week + 1;
    let after_day_num = 1;
    for (let week_i = 0; week_i < cal_data.select_month_week_len; week_i++) {
      for (let td_i = 0; td_i < 7; td_i++) {
        if (day_num == 0) {
          if (cal_data.select_month_json.first_day_of_week == td_i) {
            day_num = 1;
          }
        }
        //전달
        if (day_num <= 0) {
          td_data_arr.push({
            "is_this_month": false,
            "year": cal_data.pre_month_json.Y,
            "month": cal_data.pre_month_json.m,
            "day": pre_day_num,
            "day_of_week": td_i,
            "week_idx":week_i,
            "date_str":DateFunc.get_date_format_by_date_json(cal_data.pre_month_json,"Y-m-"+(pre_day_num<10?"0"+pre_day_num:pre_day_num)),
          });
          pre_day_num++;
        }
        //다음달
        if (day_num > cal_data.select_month_json.t) {
          td_data_arr.push({
            "is_this_month": false,
            "year": cal_data.after_month_json.Y,
            "month": cal_data.after_month_json.m,
            "day": after_day_num,
            "day_of_week": td_i,
            "week_idx":week_i,
            "date_str":DateFunc.get_date_format_by_date_json(cal_data.after_month_json,"Y-m-"+(after_day_num<10?"0"+after_day_num:after_day_num)),
          });
          after_day_num++;
        }
        //이번달
        if (day_num > 0 && day_num <= cal_data.select_month_json.t) {
          td_data_arr.push({
            "is_this_month": true,
            "year": cal_data.select_month_json.Y,
            "month": cal_data.select_month_json.m,
            "day": day_num,
            "day_of_week": td_i,
            "week_idx":week_i,
            "date_str":DateFunc.get_date_format_by_date_json(cal_data.select_month_json,"Y-m-"+(day_num<10?"0"+day_num:day_num)),
          });
          day_num++;
        }
      }
    }
    return td_data_arr;
  };
  static get_td_week_arr_by_td_data_arr(inData){
    let opt_obj={
      cal_data:{},
      td_data_arr:[],
      ...inData
    };
    let cal_data=opt_obj.cal_data;
    let td_data_arr=opt_obj.td_data_arr;
    let td_week_arr=[];

    for(let week_i=0;week_i<cal_data.select_month_week_len;week_i++){
      let row_td_data_arr = [];
      for (let i = 0; i < td_data_arr.length; i++) {
        let td_data=td_data_arr[i];
        if(td_data["week_idx"]==week_i){
          row_td_data_arr.push(td_data);
        }
      }
      td_week_arr.push(row_td_data_arr);
    }

    return td_week_arr;
  }
  static get_td_week_data_arr(inData){
    let opt_obj={
      cal_data:{},
      td_data_arr:[],
      standard_day_of_week:-1,//0,1,2,3,4,5,6
      ...inData
    };
    let cal_data=opt_obj.cal_data;
    let td_data_arr=opt_obj.td_data_arr;
    let td_data_arr_len=td_data_arr.length;
    let standard_day_of_week=opt_obj.standard_day_of_week;
    let td_week_data_arr = [];
    for (let week_i = 0; week_i < cal_data.select_month_week_len; week_i++) {
      let week_start_date = "";
      let week_end_date = "";
      let start_day_of_week = -1;
      let is_exist_monday = false;
      let row_td_data_arr = [];
      for (let td_i = 0; td_i < td_data_arr_len; td_i++) {
        let td_data = td_data_arr[td_i];
        if (td_data.week_idx == week_i) {
          row_td_data_arr.push(td_data);
          week_end_date = td_data.date_str;
          if (td_data.is_this_month) {
            if (week_start_date == "") {
              week_start_date = td_data.date_str;
            }
            if (start_day_of_week == -1) {
              start_day_of_week = td_data.day_of_week;
            }
            if(standard_day_of_week==-1){
              is_exist_monday = true;
            }else{
              if (td_data.day_of_week == standard_day_of_week) {
                is_exist_monday = true;
              }
            }
          }
        }
      }

      if (is_exist_monday) {
        td_week_data_arr.push({
          idx: td_week_data_arr.length,
          start_day_of_week: start_day_of_week,
          start_date: week_start_date,
          end_date: week_end_date,
          row_td_data_arr: row_td_data_arr,
        });
      }
    }
    return td_week_data_arr;
  };
}
export default LygCalendarFunc;