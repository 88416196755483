//import ColorFunc from "@/pcomponents/common/content/todo/color/func/color_func";
class ColorFunc {
  static get_color_style_of_percent(percent: number) {
    let con_wrap_style: any = {};
    let con_bar_style: any = {};
    let per_span_style: any = {};
    if (percent == 0) {
      con_wrap_style["background"] = "#FAD5CC";
      con_bar_style["background"] = "#F48282";
      per_span_style["color"] = "#E18685";
    } else if (percent > 0) {
      con_wrap_style["background"] = "#FAF1E0";
      con_bar_style["background"] = "#EBBD70";
      per_span_style["color"] = "#E0BA7B";
    }
    if (percent >= 100) {
      con_wrap_style["background"] = "#EFF3D0";
      con_bar_style["background"] = "#B7C528";
      per_span_style["color"] = "#B0BA3F";
    }
    return {
      wrap: con_wrap_style,
      bar: con_bar_style,
      per: per_span_style
    };
  }
}
export default ColorFunc;