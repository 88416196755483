import CompLayout from "@/pcomponents/comp/layout/layout";
import TStuListMultyArea from "@/pages/comp/tea/tstu_todo/common/list_multy";

function TeaStuTodoListPage(){
  
  return (
  <CompLayout isConTitle={false}>
    <div className="con_wrap">
      <TStuListMultyArea></TStuListMultyArea>
    </div>
  </CompLayout>
  );
};
export default TeaStuTodoListPage;