import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user_removed",
    list_id:"user_removed_list",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"110","is_show":"1"},
      {"key":"a_user_phone","name":"핸드폰","width":"110","is_show":"1"},
      {"key":"a_user_id","name":"아이디","width":"110","is_show":"1"},
      {"key":"a_user_grade","name":"회원구분","width":"110","is_show":"1"},
      {"key":"a_stu_num","name":"출결번호","width":"110","is_show":"1"},
      {"key":"a_school_name","name":"학교","width":"110","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"110","is_show":"1"},
      {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      {"key":"a_remove_date","name":"삭제일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_remove_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_remove_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr["a_user_grade"]=[
    {"value":"master","text":"관리자"},
    {"value":"staff","text":"직원"},
    {"value":"teacher","text":"선생님"},
    {"value":"student","text":"원생"},
    {"value":"parent","text":"부모"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;