import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import getXcolumnJson from "../../../../xcolumn/list";
import strFunc from '@/lib/lyg/string';

const WriteArea = forwardRef((props:any, ref) => {
  const myProps={
    setRowData:(inOptObj:any)=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const init_data={
    "a_seq":"",
    "a_title":"",
    "a_grade_sort":"grade",
    "a_memo":"",
    "a_except_day":"",
    "a_is_reserve_time":"",
    "a_reserve_start_time":"",
    "a_reserve_end_time":"",

    "start_h":"00",
    "start_m":"00",
    "end_h":"00",
    "end_m":"00",
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };

    if(optObj.row_data["a_reserve_start_time"]){
      let time_data=strFunc.timeToHourMinute(optObj.row_data["a_reserve_start_time"]);
      optObj.row_data["start_h"]=time_data["h"];
      optObj.row_data["start_m"]=time_data["m"];
    }
    if(optObj.row_data["a_reserve_end_time"]){
      let time_data=strFunc.timeToHourMinute(optObj.row_data["a_reserve_end_time"]);
      optObj.row_data["end_h"]=time_data["h"];
      optObj.row_data["end_m"]=time_data["m"];
    }

    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="a_except_day"){
      let tmp_day_arr=rowData.a_except_day.split(",");
      if(value==""){
        //제거
        let tmp_day_arr2=[];
        for(let i=0;i<tmp_day_arr.length;i++){
          if(tmp_day_arr[i]==target.value){

          }else{
            tmp_day_arr2.push(tmp_day_arr[i]);
          }
        }
        tmp_day_arr=tmp_day_arr2;
      }else{
        //추가
        if(strFunc.str_in_array(value,tmp_day_arr)==-1){
          tmp_day_arr.push(value);
        }
      }
      value=tmp_day_arr.join(",");
    }
    
    let chage_data_json:any={
      [name]: value
    };
    if(name=="start_h"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(value,rowData.start_m);
    }else if(name=="start_m"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(rowData.start_h,value);
    }else if(name=="end_h"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(value,rowData.end_m);
    }else if(name=="end_m"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(rowData.end_h,value);
    }

    //유효성체크
    if(strFunc.str_in_array(name,["start_h","start_m","end_h","end_m"])!=-1){
      chage_data_json={
        ...rowData,
        ...chage_data_json
      };
      let start_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_start_time));
      let end_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_end_time));
      if(start_time_num>end_time_num){
        chage_data_json.a_reserve_end_time=chage_data_json.a_reserve_start_time;
        chage_data_json.end_h=chage_data_json.start_h;
        chage_data_json.end_m=chage_data_json.start_m;
      }
    }
    
    setRowData({
      ...rowData,
      ...chage_data_json
    });
    myProps.setRowData({
      ...rowData,
      ...chage_data_json
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
    myProps.setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  //수업요일(0~6:일월화수목금토)
  let day_of_week_arr=[
    {"value":"0","text":"일"},
    {"value":"1","text":"월"},
    {"value":"2","text":"화"},
    {"value":"3","text":"수"},
    {"value":"4","text":"목"},
    {"value":"5","text":"금"},
    {"value":"6","text":"토"},
  ];
  let day_of_week_arr_checks=day_of_week_arr.map((item,idx)=>{
    let bChecked=false;
    let tmp_day_arr=rowData.a_except_day.split(",");
    if(strFunc.str_in_array(item.value,tmp_day_arr)!=-1){
      bChecked=true;
    }
    return (
      <label key={idx} className="mx-2">
        <input type="checkbox" name="a_except_day" value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  let time_h_arr=[];
  let time_m_arr=[];
  for(let i=0;i<24;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_h_arr.push(i_str);
  }
  for(let i=0;i<60;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_m_arr.push(i_str);
  }
  let time_h_options=time_h_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });
  let time_m_options=time_m_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  return (
    <div className="write-table-div">
      <h5 className="m-2">정보</h5>
      <table>
        <colgroup>
          <col width="20%"></col>
          <col width="80%"></col>
        </colgroup>
        <tbody>
          <tr>
            <th>제목</th>
            <td>
              <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
            </td>
          </tr>
          <tr>
            <th>등급구분</th>
            <td>
              <select className="row-input" name="a_grade_sort" value={rowData.a_grade_sort} onChange={handleInputChange} >
                <option value="grade" >등급</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>제외요일</th>
            <td>
              {day_of_week_arr_checks}
            </td>
          </tr>
          <tr>
            <th>예정시간여부</th>
            <td>
              <select className="row-input" name="a_is_reserve_time" value={rowData.a_is_reserve_time} onChange={handleInputChange} >
                <option value="" >아니오</option>
                <option value="1" >예</option>
              </select>
            </td>
          </tr>
          {rowData.a_is_reserve_time=="1"&&
          <tr>
            <th>예정시간</th>
            <td>
              <select className="row-input" name="start_h" value={rowData.start_h} onChange={handleInputChange} style={{width:50}} >
                {time_h_options}
              </select>:
              <select className="row-input" name="start_m" value={rowData.start_m} onChange={handleInputChange} style={{width:50}} >
                {time_m_options}
              </select>
              ~
              <select className="row-input" name="end_h" value={rowData.end_h} onChange={handleInputChange} style={{width:50}} >
                {time_h_options}
              </select>:
              <select className="row-input" name="end_m" value={rowData.end_m} onChange={handleInputChange} style={{width:50}} >
                {time_m_options}
              </select>
            </td>
          </tr>
          }
          <tr>
            <th>메모</th>
            <td>
              <textarea className="row-input" name="a_memo" value={rowData.a_memo} onChange={handleInputChange} 
                placeholder="메모" style={{height:100}}></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default WriteArea;
