import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

const DayOfWeekArea=(props:any)=>{
  let myProps:any={
    value:"",//0,1,2,3,4,5,6
    onChange:(value:string)=>{},
    ...props,
  };

  const onClickDay=(day_num:string,is_checked:boolean)=>{
    let tmp_day_arr=myProps.value.split(",");
    if(is_checked){
      //추가
      if(strFunc.str_in_array(day_num,tmp_day_arr)==-1){
        tmp_day_arr.push(day_num);
      }
    }else{
      //빼기
      let tmp_day_arr2=[];
      for(let i=0;i<tmp_day_arr.length;i++){
        if(tmp_day_arr[i]==day_num){

        }else{
          tmp_day_arr2.push(tmp_day_arr[i]);
        }
      }
      tmp_day_arr=tmp_day_arr2;
    }
    myProps.onChange(tmp_day_arr.join(","));
  };

  let day_of_week_arr=[
    {"value":"0","text":"일"},
    {"value":"1","text":"월"},
    {"value":"2","text":"화"},
    {"value":"3","text":"수"},
    {"value":"4","text":"목"},
    {"value":"5","text":"금"},
    {"value":"6","text":"토"},
  ];

  let day_of_week_arr_checks=day_of_week_arr.map((item,idx)=>{
    let bChecked=false;
    let tmp_day_arr=myProps.value.split(",");
    if(strFunc.str_in_array(item.value,tmp_day_arr)!=-1){
      bChecked=true;
    }
    let item_class_name=Style.item;
    if(bChecked){
      item_class_name+=" "+Style.active;
    }
    return (
      <div key={idx} className={item_class_name} onClick={()=>{onClickDay(item.value,!bChecked);}} >
        {item.text}
      </div>
    );
  });

  return (
    <div className={Style.day_of_week_wrap}>
      {day_of_week_arr_checks}
    </div>
  );
};
export default DayOfWeekArea;