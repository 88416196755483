import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import popupFunc from "@/lib/lyg/popup";
import noimg from "@/img/ico/noimg.png";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import ManageCategoryPopup from '@/pages/comp/plan/video/category/popup/manage';
import TabArea from "./area/tab_area";
import WriteArea from "./area/writer_area";
import SectionVideoArea from "./area/section_video";
import SectionArea from "./area/section_area";
import VideoArea from "./area/video_area";
import UseStuList from "./area/use_stu_list";
import RequestList from "./area/request_list";

const WriteFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const WriteAreaRef=useRef<any>(null);
  const SectionAreaRef=useRef<any>(null);
  const VideoAreaRef=useRef<any>(null);
  const UseStuListRef=useRef<any>(null);
  const RequestListRef=useRef<any>(null);

  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const [active_tab,set_active_tab]=useState("write");
  if(isUpdate){
    pageData["write_btn_text"]="저장";
    pageData["confirm_msg"]="저장 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_subject":"",
    "a_category_seq":"",
    "a_category":"",
    "a_open_group":"",
    "a_title":"",
    "a_content":"",
    "a_state":"ing",
    "a_agree_sort":"manual",
    "a_period_sort":"continue",
    "a_start_date":"",
    "a_end_date":"",
    "a_level":"",
    "a_teacher_name":"",
    "title_img_url":noimg,
    "title_img_arr":[],
    "section_arr":[],
    "video_arr":[],
    "use_stu_arr":[],
    "request_arr":[],

  };
  const [rowData,setRowData]=useState({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
    if(WriteAreaRef.current){
      WriteAreaRef.current.setInitData({
        "row_data":optObj["row_data"],
        "isUpdate":optObj.isUpdate
      });
    }
    if(SectionAreaRef.current){
      let tmp_section_arr=optObj["row_data"]["section_arr"]?optObj["row_data"]["section_arr"]:[];
      let tmp_video_arr=optObj["row_data"]["video_arr"]?optObj["row_data"]["video_arr"]:[];
      SectionAreaRef.current.setInitData({
        "section_arr":tmp_section_arr,
        "video_arr":tmp_video_arr,
        "isUpdate":optObj.isUpdate,
      });
    }
    if(VideoAreaRef.current){
      let tmp_section_arr=optObj["row_data"]["section_arr"]?optObj["row_data"]["section_arr"]:[];
      let tmp_video_arr=optObj["row_data"]["video_arr"]?optObj["row_data"]["video_arr"]:[];
      VideoAreaRef.current.setInitData({
        "section_arr":tmp_section_arr,
        "video_arr":tmp_video_arr,
        "isUpdate":optObj.isUpdate,
      });
    }
    if(UseStuListRef.current){
      let use_stu_arr=optObj["row_data"]["use_stu_arr"]?optObj["row_data"]["use_stu_arr"]:[];
      UseStuListRef.current.setInitData({
        "corse_info":optObj["row_data"],
        "infoArr":use_stu_arr,
      });
    }
    if(RequestListRef.current){
      let request_arr=optObj["row_data"]["request_arr"]?optObj["row_data"]["request_arr"]:[];
      RequestListRef.current.setInitData({
        "corse_info":optObj["row_data"],
        "infoArr":request_arr,
      });
    }
    
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"manage_category",//manage_category
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"500px",
    height:"80%",
  });

  const open_manage_category_popup=()=>{
    let pop_title="카테고리 관리";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "sort":"manage_category"
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let wRowData={...rowData};

    let formJsonData={
      "data_arr":[wRowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current&&(fileInput.current as HTMLInputElement).value!=""){
          goWriteProfileImg(response.data["data"][0]);
        }else{
          goWriteSectionVideo(response.data["data"][0]);
        }
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const goWriteProfileImg=(row_data:any)=>{
    if(writeFileForm.current==null){
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data= new FormData(form);
    let file_data_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_comp_seq":user.comp_seq,
      "a_par_id":"video_corse",
      "a_par_seq":row_data.a_seq,
      "a_sort1":"title",
      "a_sort2":"",
      "a_sort3":"",
      "a_writer":user.user_name,
      "a_create_seq":user.user_seq,
    };
    form_json_data.append("data_arr",JSON.stringify([file_data_row]));
    form_json_data.append("authorization",localStorage.intoyou_token);

    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current){
          (fileInput.current as HTMLInputElement).value="";
        }
        goWriteSectionVideo(row_data);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDeleteProFile=(fileInfo:any)=>{
    if(!confirm("사진을 삭제 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "data_arr":[fileInfo],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/file/file/delete',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list({select_cat_seq:rowData.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteSectionVideo=(row_data:any)=>{
    let section_video_form_json={
      "corse_info":row_data,
      "section_arr":rowData.section_arr,
      "video_arr":rowData.video_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/add_section_video',
    section_video_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("저장 되었습니다.");
        myProps.list({select_cat_seq:row_data["a_seq"]});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <TabArea
        isUpdate={isUpdate}
        active_tab={active_tab}
        onChangeTab={(tab_id:string)=>{set_active_tab(tab_id);}}
      ></TabArea>
      <div>
        <div className={(active_tab=="write"?"":"hidden")}>
          <WriteArea
            ref={WriteAreaRef}
            setRowData={(inData:any)=>{
              setRowData({
                ...rowData,
                ...inData
              });
            }}
            open_manage_category_popup={open_manage_category_popup}
          ></WriteArea>
          <div className="write-table-div">
            <table>
              <colgroup>
                <col width="20%"></col>
                <col width="30%"></col>
                <col width="20%"></col>
                <col width="30%"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>썸네일</th>
                  <td>
                  <div className="text-center">
                      <a onClick={()=>{
                          popupFunc.openPopup({'url':rowData.title_img_url,'width':700,'height':420,'pop_name':'name','auto_size':false});
                        }}>
                        <img src={rowData["title_img_url"]?rowData["title_img_url"]:noimg} alt="사람" style={{width:100}} />
                      </a>
                      {
                        rowData.title_img_arr.length>0 && 
                        <button className="btn-s btn-gray" onClick={()=>{goDeleteProFile(rowData.title_img_arr[0]);}}>X</button>
                      }
                      <form id="write_form" method="post" ref={writeFileForm} >
                        <input type="file" id="files" className="row-input mt-1" name="input_file[]" ref={fileInput} style={{width:200}} />
                      </form>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={(active_tab=="section_video"?"":"hidden")}>
          <SectionVideoArea
            section_arr={rowData.section_arr?rowData.section_arr:[]}
            video_arr={rowData.video_arr?rowData.video_arr:[]}
            set_section_arr={(inData:any)=>{
              setRowData({
                ...rowData,
                "section_arr":inData
              });
            }}
            set_video_arr={(inData:any)=>{
              setRowData({
                ...rowData,
                "video_arr":inData
              });
            }}
          ></SectionVideoArea>
        </div>
        <div className={(active_tab=="section"?"":"hidden")}>
          <SectionArea
            ref={SectionAreaRef}
            set_section_arr={(section_arr:any)=>{
              setRowData({
                ...rowData,
                ...{
                  section_arr:section_arr
                }
              });
            }}
          ></SectionArea>
        </div>
        <div className={(active_tab=="video"?"":"hidden")}>
          <VideoArea
            ref={VideoAreaRef}
            set_video_arr={(video_arr:any)=>{
              setRowData({
                ...rowData,
                ...{
                  video_arr:video_arr
                }
              });
            }}
          ></VideoArea>
        </div>
        <div className={(active_tab=="student"?"":"hidden")}>
          <UseStuList
            ref={UseStuListRef}
            list={(inOptObj:any)=>{myProps.list(inOptObj);}}
          ></UseStuList>
        </div>
        <div className={(active_tab=="request"?"":"hidden")}>
          <RequestList
            ref={RequestListRef}
            list={(inOptObj:any)=>{myProps.list(inOptObj);}}
          ></RequestList>
        </div>
      </div>
      {(active_tab!="student"&&active_tab!="request")&&
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
      }

      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="manage_category"&&
            <ManageCategoryPopup 
              callBack={()=>{
                WriteAreaRef.current?.getCategoryList();
              }}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             ></ManageCategoryPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default WriteFrameContent;
