import Style from "../css/style.module.css";
import { Link,useNavigate } from 'react-router-dom';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';

const NoticeListArea=()=>{
  const navigate = useNavigate();

  const [infoArr, setInfoArr] = useState([]);

  useEffect(()=>{
    
    list();
  },[]);

  const list = ()=>{
    let list_form_json:any={
      'order_id':'a_create_date DESC',
      "max_limit_num":"10",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/board/board/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const getNoticeTags=()=>{
    let notice_tags=infoArr.map((item:any,idx:number)=>{
      let pre_title="";
      if(item.a_sort){
        pre_title="["+item.a_sort+"]";
      }
      let title_style:any={};
      if(item.a_color){
        title_style.color=item.a_color;
      }
      return (
        <div key={idx} className={Style.notice_row}>
          <div className={Style.notice_row_title} style={title_style}  >
            {pre_title} {item.a_title}
          </div>
          <div className={Style.notice_row_right_text}>
            {item.a_create_date.substring(0,10)}
          </div>
        </div>
      );
    });
    return notice_tags;
  };

  return (
    <div>
      <div className={Style.box_title_wrap}>
        공지사항
        <a className={Style.box_title_right_link} onClick={()=>{navigate("/comp/board/notice/list");}} >
          바로가기{">"}
        </a>
      </div>
      <div className={Style.notice_list_wrap}>
        {infoArr.length==0&&
        <div className={Style.notice_empty_box}>
          내용이 없습니다.
        </div>
        }
        {
          getNoticeTags()
        }
      </div>
    </div>
  );
};
export default NoticeListArea;