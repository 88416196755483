import DateFunc from '@/lib/lyg/date_func';

function TopDateArea(props:any){
   let myProps={
    Style:{},
    list_opt:{
      "s_start_date":"",
      "s_end_date":"",
    },
    list:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  var start_date_obj=DateFunc.get_date_json(new Date(myProps.list_opt.s_start_date));
  
  const goDateChange=(sort:any)=>{
    //next,pre
    let selected_date=myProps.list_opt.s_start_date;
    if(selected_date==""){
      selected_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let change_date_obj=new Date(selected_date);
    if(sort=="pre"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'day',-1);
    }else if(sort=="next"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'day',+1);
    }
    
    let start_date=DateFunc.get_date_format(change_date_obj,"Y-m-d");
    let end_date=start_date;
    myProps.list({
      now_page:"1",
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
  };

  return (
  <div>
    <div className={Style.top_date_wrap}>
      <button className="btn-s btn-gray mr-2" onClick={()=>{goDateChange("pre");}} >◀</button>
      {start_date_obj.m+"월 "+start_date_obj.d+"일 "+DateFunc.get_day_str_by_day_num(start_date_obj.day)}
      <button className="btn-s btn-gray ml-2" onClick={()=>{goDateChange("next");}} >▶</button>
    </div>
  </div>
  );
};
export default TopDateArea;