import { useState } from 'react';

function TitleBarArea(props: any) {
  let myProps = {
    title:"팝업",
    x: 0,
    y: 0,
    setOffset:(inData:any)=>{},
    title_bar_style:{},
    closePopup:(inData:any)=>{},
    ...props
  };
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: myProps.x, y: myProps.y });

  const handleMouseDown = (e: any) => {
    const startX = e.clientX - offset.x;
    const startY = e.clientY - offset.y;
    setDragging(true);
    setPosition({ x: startX, y: startY });
  };

  const handleMouseMove = (e: any) => {
    if (!dragging) return;
    const newX = e.clientX - position.x;
    const newY = e.clientY - position.y;
    setOffset({ x: newX, y: newY });
    myProps.setOffset({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  let title_bar_style={
    cursor: "pointer",
    ...myProps.title_bar_style
  };

  return (
    <div className="popup-title-bar" style={title_bar_style}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="con-title">{myProps.title}</div>
      <button className="popup-title-close-btn" onClick={() => { myProps.closePopup(); }}></button>
    </div>
  );
}
export default TitleBarArea;
