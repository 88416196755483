import { useState } from 'react';
import strFunc from '@/lib/lyg/string';
import YouTube from 'react-youtube';

function YouTubeArea(props:any){
   let myProps={
    ...props
  };

  const [player,setPlayer]=useState<any>(null);
  //props.rowData.a_user_name;
  const onReadyVideo=(event:any)=>{
    console.log("Ready");
    setPlayer(event.target);
    //event.target.pauseVideo();
    //event.target.playVideo();
  };
  const onPlay=(event:any)=>{
    console.log("onPlay");
    //다른동영상 재상 방지
    if(player){
      let play_video_code=player.getVideoUrl();
      let now_video_id:any=strFunc.get_youtube_id_by_url(play_video_code);
      let this_video_id:any=strFunc.get_youtube_id_by_url(props.rowData.a_url);
      if(now_video_id!=this_video_id){
        player.loadVideoById(this_video_id,0, "large");
        player.pauseVideo();
      }
    }
  };
  const onPause=(event:any)=>{
    //console.log("onPause");
    if(player){
      let current_time=player.getCurrentTime();
    }
  };
  const onEnd=(event:any)=>{
    console.log("onEnd");
    if(player){
      let current_time=player.getCurrentTime();
      player.seekTo(current_time-2);
      player.pauseVideo();
    }
  };
  const video_opts = {
    height: '400',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };
  //props.rowData.a_url
  let video_id:any=strFunc.get_youtube_id_by_url(props.rowData.a_url);
  
  return (
  <div>
    {video_id!=""&&
      <YouTube videoId={video_id} opts={video_opts} 
        onReady={onReadyVideo}
        onPlay={onPlay} 
        onPause={onPause} 
        onEnd={onEnd} 
      />
    }
  </div>
  );
};
export default YouTubeArea;