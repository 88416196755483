import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';

const ListSearchComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "categoryInfoArr":[],
    "listOpt":{
      "s_category_seq":""
    },
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "list":()=>{},
    ...props
  };
  const [categoryInfoArr, setCategoryInfoArr] = useState(myProps.categoryInfoArr);
  const [listOpt, setListOpt] = useState(myProps.listOpt);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setCategoryInfoArr
  }));

  let goSearch=(inOptObj:any)=>{
    let optObj={
      "key":"",
      "value":"",
      ...inOptObj
    };
    if(optObj["key"]==""){
      return false;
    }
    let key=optObj["key"];
    let value=optObj["value"];

    setListOpt({
      ...listOpt,
      ...{
        [key]:value
      }
    });
    myProps.list({[key]:value});
  };

  return (
    <div>
      <div style={{position:"relative"}}>
        <h4>강의리스트</h4>
        <div style={{"position":"absolute",right:0,top:0}}>
          <select className="search-input_select ml-2" value={listOpt.s_category_seq}
            onChange={(e:any)=>{
              goSearch({
                "key":"s_category_seq",
                "value":e.target.value
              });
            }}
              style={{maxWidth:120}}
            >
            <option value="">카테고리</option>
            {
              categoryInfoArr.map((item:any,idx:number)=>{
                return (
                  <option value={item.a_seq} key={idx}>{item.a_title}</option>
                );
              })
            }
          </select>
        </div>
      </div>
    </div>
  );
});

export default ListSearchComponent;