import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"counsel_todo",
    list_id:"counsel_todo_list",
    x_column_list_arr:[
      {"key":"a_counsel_date","name":"상담일","width":"110","is_show":"1"},
      {"key":"a_content","name":"내용","width":"110","is_show":"1"},
      {"key":"row_view_files","name":"첨부파일","width":"110","is_show":"1"},
      {"key":"a_stu_name","name":"원생명","width":"110","is_show":"1"},
      {"key":"a_counseler","name":"상담자","width":"100","is_show":"1"},
      {"key":"row_view_state","name":"상태","width":"90","is_show":"1"},
      // {"key":"a_create_date","name":"작성일","width":"120","is_show":"1"},
      // {"key":"a_sort1","name":"구분1","width":"100","is_show":"1"},
      // {"key":"a_sort2","name":"구분2","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_create_date DESC, a_is_success',
      'order_type':'',
      's_date_type':'a_counsel_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_addon_user":"1",
      "s_stu_seq":"",
      "s_stu_name_like":"",
      "s_stu_phone_like":"",
      "s_stu_school_name_like":"",
      "s_title_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;