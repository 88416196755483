import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from './area/search/search_area';
import WriteFrameContent from "./area/write";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';
import strFunc from "@/lib/lyg/string";
import clip_ico from "@/img/ico/clip.png";
import popupFunc from "@/lib/lyg/popup";
import { useLocation,useNavigate } from "react-router";
import LoadingComponent from "@/pcomponents/common/content/loading";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ListPage(){
  const { state } = useLocation();
  let state_obj={
    counsel_write_row_data:{},
    counsel_is_update:false,
    ...state
  };
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const WriteFrameContentRef=useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
      select_seq:"",
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      "s_addon_user": "1",
      "s_addon_file":"1",
  });
  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let stu_grade2_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [is_open_write,set_is_open_write]=useState(true);
  const [loading_data, set_loading_data] = useState({
    is_display:false,
    text:"저장중..",
  });

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    stu_seq:"",
    stu_name:"",
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    var win_w=(window.innerWidth || document.documentElement.clientWidth);
    if(win_w<800){
      set_is_open_write(false);
    }
    if(!strFunc.is_empty(state_obj.counsel_write_row_data.a_stu_seq)){
      if(WriteFrameContentRef.current){
        WriteFrameContentRef.current.setInitData({
          "row_data":state_obj.counsel_write_row_data,
          "isUpdate":state_obj.counsel_is_update
        });
      }
    }
  }, []);

  const list = (inOptObj:any)=>{
    let tmp_list_opt={
      ...listOpt,
      ...inOptObj
    };
    tmp_list_opt["sc"]=listAggridRef.current.getListSc();
    setListOpt(tmp_list_opt);
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        if (!strFunc.is_empty(tmp_list_opt.select_seq) && listAggridRef.current) {
          setTimeout(() => {
            let all_nodes = listAggridRef.current.getNodeRows();
            for (let i = 0; i < all_nodes.length; i++) {
              let row_node = all_nodes[i];
              let row_pri_val = row_node.data["a_ymd"] + "," + row_node.data["a_seq"];
              if (row_pri_val == tmp_list_opt.select_seq) {
                row_node.setSelected(true);
              }
            }
          }, 100);
        }
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/counsel_todo/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_loading_data({
          is_display:true,
          text:"삭제 되었습니다.",
        });
        setTimeout(() => {
          set_loading_data({
            is_display:false,
            text:"저장중..",
          });
        }, 1000);
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  let is_under_width=MyScreenFunc.get_is_under();

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    if(sort=="write"){
      if(isUpdate==false){
        if(listOpt.s_stu_seq==""){
          alert("원생 선택이 필요합니다.");
          return false;
        }
      }
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      stu_seq:listOpt.s_stu_seq,
      stu_name:listOpt.s_stu_name,
      "title":pop_title,
      "sort":sort
    });
  };

  const setWriteState=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length>0){
      listAggridRef.current.setSelectFalseAll();
    }
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData();
    }
    if(is_under_width){
      set_is_open_write(true);
    }
  };
  const setWriteStateUpdate=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(selected_row.length!=1){
      alert("한개만 선택해 주세요.");
      return false;
    }
    if(WriteFrameContentRef.current){
      WriteFrameContentRef.current.setInitData({
        "row_data":selected_row[0],
        "isUpdate":"1"
      });
    }
    if(is_under_width){
      set_is_open_write(true);
    }
  };

  const get_file_tags_by_file_arr=(file_arr:[])=>{
    let file_tags=file_arr.map((file_info:any,idx:number)=>{
      let icon_img=clip_ico;
      if(file_info.is_image=="1"){
        icon_img=file_info.thum_img_url;
        if(file_info.a_ext=="png"){
          icon_img=file_info.img_url;
        }
      }

      return (
        <span key={idx} className="ml-1">
          {file_info.is_image=="1"&&
            <a style={{cursor:"pointer"}} onClick={()=>{popupFunc.openPopup({url:file_info.img_url});}} >
              <img src={icon_img} style={{height:40}} />
            </a>
          }
        </span>
      );
    });

    return file_tags;
  };

  const cellClickedListener =(params:any) => {
    var key=params.colDef.field;
    if(key=="a_title"){
      //openOnePopup("view",true);
    }
  };

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_manage"){
      render_str=(
        <div>
          <button className="btn btn-dark" onClick={()=>{setTimeout(()=>{openOnePopup("write",true);},100);}}>수정</button>
        </div>
      );
    }else if(key=="row_view_state"){
      let tmp_select_date_time = new Date(params.data.a_reserve_date).getTime();
      let state_back_color = "#eab358";
      let state_str = "준비";
      if (tmp_select_date_time < now_date_time) {
        state_str = "미완료";
        state_back_color = "#db6c58";
      }
      if (params.data.a_state == "complete") {
        state_str = "완료";
        state_back_color = "#A2B854";
      }

      let state_span_style:any = {
        "padding": "0 3px",
        "color": "#fff",
        "lineHeight": "23px",
        "background": state_back_color,
        "borderRadius": "4px",
        "textAlign":"center",
        "display":"inline-block",
      };

      render_str = (
        <div style={state_span_style}>
          {state_str}
        </div>
      );
    }else if(key=="a_counsel_date"){
      let tmp_select_date_time = new Date(params.data.a_reserve_date).getTime();
      let state_back_color = "#eab358";
      let state_str = "준비";
      if (tmp_select_date_time < now_date_time) {
        state_str = "미완료";
        state_back_color = "#db6c58";
      }
      if (params.data.a_state == "complete") {
        state_str = "완료";
        state_back_color = "#A2B854";
      }
      let state_span_style:any = {
        "padding": "0 3px",
        "color": "#fff",
        "lineHeight": "23px",
        "background": state_back_color,
        "borderRadius": "4px",
        "textAlign":"center",
        "display":"inline-block",
      };

      render_str=(
        <div style={state_span_style} title={state_str}>
          {render_str}
        </div>
      );
    }else if(key=="a_content"){
      render_str=strFunc.cut_str(render_str,7,"..");
    }else if(key=="row_view_files"){
      //file_arr
      if(params.data.file_arr){
        render_str=get_file_tags_by_file_arr(params.data.file_arr);
      }
    }else if(key=="a_stu_name"){
      let stu_grade_origin="";
      let stu_grade_str="";
      if(params.data.stu_info){
        stu_grade_origin=params.data.stu_info["a_stu_grade"];
        for(let i=0;i<stu_grade2_select_option_arr.length;i++){
          if(stu_grade2_select_option_arr[i]["value"]==stu_grade_origin){
            stu_grade_str=stu_grade2_select_option_arr[i]["text"];
          }
        }
      }
      render_str=render_str+" "+stu_grade_str;
    }
    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let tmp_is_under=MyScreenFunc.get_is_under();
    if(tmp_is_under){
      
    }else{
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length>0){
        if(selected_row.length==1){
          if(WriteFrameContentRef.current){
            WriteFrameContentRef.current.setInitData({
              "row_data":selected_row[0],
              "isUpdate":"1"
            });
          }
        }else{
          WriteFrameContentRef.current.setInitData();
        }
      }else{
        setWriteState();
      }
    }
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea 
          listOpt={{...listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
        ></SearchArea>
        <div className="list_round_box_wrap mt-7">
          <div className="list_box_title_bar">
            <span className="list_box_title_bar_title">
              상담관리
            </span>
            <div className="absolute right-3 top-4 btn-box-right">
              {/* <button className="btn btn-dark" onClick={()=>{openOnePopup("write",false);}}>등록</button>
              <button className="btn btn-dark" onClick={()=>{openOnePopup("write",true);}}>수정</button> */}
              <button className="btn-m btn-sky" onClick={()=>{setWriteState();}}>신규</button>
              {is_under_width&&
              <button className="btn-m btn-sky" onClick={()=>{setWriteStateUpdate();}}>수정</button>
              }
              <button className="btn-m btn-dark" onClick={goDelete}>삭제</button>
            </div>
          </div>
          <div className="list_round_box">
            <div className="flex flex-row gap-x-2">
              <div style={{width:(is_under_width)?"100%":"100%",display:(is_open_write)?"":"none",minWidth:500}}  >
                <WriteFrameContent
                  ref={WriteFrameContentRef}
                  list={(listOpt:any)=>{list(listOpt);}}
                  set_is_open_write={(inData:boolean)=>{set_is_open_write(inData)}}
                ></WriteFrameContent>
              </div>
              <div className="grow" style={{minWidth:560,display:(is_under_width&&is_open_write)?"none":""}} >
                <ListAggrid
                ref={listAggridRef}
                infoArr={infoArr}
                xColumnArr={{...xColumnArr}}
                list={list}
                gridOpt={{
                  fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                  is_idx_num:true,
                  is_add_checkbox:true,
                  floatingFilter:false,
                  onGridReady:()=>{
                    list({});
                  },
                  onCellClicked:cellClickedListener,
                  cellRenderer:cellRenderer,
                  onSelectionChanged:onSelectionChanged,
                }}
                ></ListAggrid>
                <Paging now_page={listOpt.now_page}
                  num_per_page={listOpt.num_per_page}
                  total_rec={countInfo.tot} 
                  onChangePage={(now_page:number)=>{list({now_page:now_page});}}
                  onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>

                <div className="text-right">
                  <span className="ml-2" style={{color:"#eab358"}}>■</span>:준비,
                  <span className="ml-1" style={{color:"#db6c58"}}>■</span>:미완료,
                  <span className="ml-1" style={{color:"#A2B854"}}>■</span>:완료
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             stu_seq={popupData.stu_seq}
             stu_name={popupData.stu_name}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             openOnePopup={openOnePopup}></ViewPopupContent>
          }
      </LayerPopup>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
    </CompLayout>
  );
}

export default ListPage;