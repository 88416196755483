function SearchArea(props:any){
   let myProps={
    list_opt:{},
    list:(inData:any)=>{},
    setWriteState:(inData:any)=>{},
    goDelete:(inData:any)=>{},
    ...props
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    myProps.list({now_page:1,[name]: value});
  };
  
  return (
  <div>
    <select className="search-input" name="s_is_use" value={myProps.list_opt.s_is_use} 
      onChange={handleInputChange}>
        <option value="">모두</option>
      <option value="1">사용</option>
      <option value="empty">미사용</option>
    </select>
    {/* <button className="btn btn-red" onClick={goDelete}>삭제</button> */}
    <button className="btn btn-sky ml-1" onClick={myProps.setWriteState}>신규</button>
  </div>
  );
};
export default SearchArea;