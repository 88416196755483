//import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from '@/lib/lyg/string';
import {month_prescribe_select_arr} from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";

class MonthPrescribeViewFunc {
  //몇주차 얻기
  static get_view_text_str(inData: any) {
    let opt_obj = {
      info: {},
      ...inData
    };
    let info = opt_obj["info"];
    let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
    let stu_grade_str = "";
    if (info.stu_info) {
      info["a_stu_name"] = info.stu_info["a_user_name"];
      stu_grade_str = info.stu_info.a_stu_grade;
      for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
        if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
          stu_grade_str = select_stu_grade_option_arr[i]["text"];
        }
      }
    }
    let week_i_str: any = parseInt(strFunc.uncomma(info.week_i));
    let month_num = parseInt(strFunc.uncomma(info.month_num));
    week_i_str++;
    if (week_i_str == 0) {
      week_i_str = "전달 마지막";
    } else {
      week_i_str = month_num + "월 " + week_i_str;
    }

    let lack_detail_str="";
    lack_detail_str+="  숙제수행도: ";
    for(let i=0;i<month_prescribe_select_arr.a_homework_degree.length;i++){
      if(info.a_homework_degree==month_prescribe_select_arr.a_homework_degree[i].value){
        lack_detail_str+=month_prescribe_select_arr.a_homework_degree[i].text;
      }
    }
    lack_detail_str+="\n";
    lack_detail_str+="  이해점수: ";
    for(let i=0;i<month_prescribe_select_arr.a_understand_degree.length;i++){
      if(info.a_understand_degree==month_prescribe_select_arr.a_understand_degree[i].value){
        lack_detail_str+=month_prescribe_select_arr.a_understand_degree[i].text;
      }
    }
    lack_detail_str+="\n";
    lack_detail_str+="  하루학습량: "+info.a_study_amount;
    lack_detail_str+="\n";
    lack_detail_str+="  수업태도: ";
    for(let i=0;i<month_prescribe_select_arr.a_lesson_attitude.length;i++){
      if(info.a_lesson_attitude==month_prescribe_select_arr.a_lesson_attitude[i].value){
        lack_detail_str+=month_prescribe_select_arr.a_lesson_attitude[i].text;
      }
    }
    lack_detail_str+="\n";
    lack_detail_str+="  학습정서: "+info.a_study_emotion;

    let text_str = "학생명: " + info["a_stu_name"];
    if (info.stu_info) {
      text_str += " (" + strFunc.cut_str(info.stu_info["a_school_name"], 2, "") + " " + stu_grade_str + ")";
    }
    text_str += "\n";
    if(info.week_i!=undefined){
      text_str += week_i_str + " 주차 학습처방 내용 \n";
    }
    text_str+="\n";
    text_str += "1.진도전략:\n";
    text_str += "  " + info.a_progress_reason + "\n";
    text_str += "2.현재진도:\n";
    text_str += "  " + info.a_progress + "\n";
    text_str += "3.성장한부분:\n";
    text_str += "  " + info.a_grow + "\n";
    text_str += "4.발견:\n";
    text_str += lack_detail_str + "\n";
    text_str += "  " + info.a_lack + "\n";
    text_str += "5.학습처방:\n";
    text_str += "  " + info.a_content + "\n";
    if (info.comment_arr&&info.comment_arr.length > 0) {
      text_str += "\n";
      text_str += "질문답변\n";
      for (let i = 0; i < info.comment_arr.length; i++) {
        let comment_info = info.comment_arr[i];
        let tmp_idx = i + 1;
        let qa_str = "Q";
        if (comment_info.a_qna_sort == "a") {
          qa_str = "A";
        }
        text_str += " " + tmp_idx + ". " + qa_str + " " + comment_info["a_message"] + "\n";
        text_str += " " + comment_info["a_create_date"] + " " + comment_info["a_responser"] + "\n";
      }
    }

    return text_str;
  }
}
export default MonthPrescribeViewFunc;