import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';

function WritePopupContent(props:any) {
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  if(props.isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const [rowData,setRowData]=useState({
    "a_seq":"",
    "a_corp_seq":"1",
    "a_user_id":"",
    "a_user_pw":"",
    "a_user_grade":"teacher",
    "a_is_login":"1",
    "a_user_name":"",
    "a_user_phone":"",
    "invite_code":"",
  });

  useEffect(()=>{
    
    if(props.isUpdate){
      let changeRowData:any={};
      for(let key in rowData){
        if(props.rowData[key]!=undefined){
          changeRowData[key]=props.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name=="a_user_phone"){
      value=strFunc.autoHypenPhone(value);
    }
    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const goWrite=useCallback( (e:any) => {
    if(rowData.a_user_id==""){
      alert("아이디 입력이 필요합니다.");
      return false;
    }
    if(!props.isUpdate){
      if(rowData.a_user_pw==""){
        alert("암호 입력이 필요합니다.");
        return false;
      }
    }
    if(rowData.a_user_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_default_val":"1",
      "is_update":"",
    };
    if(props.isUpdate){
      formJsonData["is_default_val"]="";
      formJsonData["is_update"]="1";
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });

  }, [rowData]);

  const makeInviteCode=()=>{
    if(rowData.a_seq==""){
      alert("유저키 없음.");
      return false;
    }
    if(!confirm("초대코드를 생성 하시겠습니까?")){
      return false;
    }
    let inivte_make_code_from_data={
      "mcomp_seq":"",
      "user_seq":rowData.a_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/invite_code/make_code',
    inivte_make_code_from_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setRowData({
          ...rowData,
          ...{"invite_code": response.data["data"]["invite_code"]}
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  
  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>아이디</th>
              <td>
                <input type="text" className="row-input" name="a_user_id" value={rowData.a_user_id} onChange={handleInputChange} placeholder="아이디" />
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <input type="password" className="row-input" name="a_user_pw" value={rowData.a_user_pw} onChange={handleInputChange} placeholder="비밀번호" />
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" className="row-input" name="a_user_name" value={rowData.a_user_name} onChange={handleInputChange} placeholder="이름" />
              </td>
            </tr>
            <tr>
              <th>핸드폰</th>
              <td>
                <input type="text" className="row-input" name="a_user_phone" value={rowData.a_user_phone} onChange={handleInputChange} placeholder="000-0000-0000" />
              </td>
            </tr>
            <tr>
              <th>등급</th>
              <td>
                <select className="row-input" name="a_user_grade" value={rowData.a_user_grade} onChange={handleInputChange} >
                  <option value="teacher">선생님</option>
                  <option value="staff">직원</option>
                  <option value="master">관리자</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>로그인허용</th>
              <td>
                <select className="row-input" name="a_is_login" value={rowData.a_is_login} onChange={handleInputChange} >
                  <option value="1">허용</option>
                  <option value="">미허용</option>
                </select>
              </td>
            </tr>
            {rowData.a_seq!=""&&
            <tr>
              <th>초대코드</th>
              <td>
                {rowData["invite_code"]}
                <button className="btn btn-dark ml-2" onClick={()=>{makeInviteCode();}}>생성</button>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
