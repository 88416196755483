import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';

const MakeOptionArea = forwardRef((props:any, ref) => {
  const myProps={
    set_make_option:(inOptObj:any)=>{},
    get_todo_content_arr:(inData:any)=>{},
    make_todo_write_plan:()=>{},
    ...props
  };

  const [make_option,set_make_option]=useState<any>({
    "is_mcomp_publiic":"1",
    "is_reserve":"1",//예정여부
    "reserve_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "reserve_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "one_day_amount":10,
    "one_day_amount_time":60,
    "apply_day":"1,2,3,4,5",

    "start_h":"09",
    "start_m":"00",
    "end_h":"10",
    "end_m":"00",

    "is_auto_amount":"1",
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "make_option":[],
      ...inOptObj
    };

    if(optObj.make_option["reserve_start_date"]){
      let time_data=strFunc.timeToHourMinute(optObj.make_option["reserve_start_date"]);
      optObj.make_option["start_h"]=time_data["h"];
      optObj.make_option["start_m"]=time_data["m"];
    }
    if(optObj.make_option["reserve_end_date"]){
      let time_data=strFunc.timeToHourMinute(optObj.make_option["reserve_end_date"]);
      optObj.make_option["end_h"]=time_data["h"];
      optObj.make_option["end_m"]=time_data["m"];
    }

    set_make_option(optObj.make_option);
  };

  const get_make_option=()=>{
    return make_option;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_make_option
  }));

  useEffect(()=>{
    
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="apply_day"){
      let tmp_day_arr=make_option.apply_day.split(",");
      if(value==""){
        //제거
        let tmp_day_arr2=[];
        for(let i=0;i<tmp_day_arr.length;i++){
          if(tmp_day_arr[i]==target.value){

          }else{
            tmp_day_arr2.push(tmp_day_arr[i]);
          }
        }
        tmp_day_arr=tmp_day_arr2;
      }else{
        //추가
        if(strFunc.str_in_array(value,tmp_day_arr)==-1){
          tmp_day_arr.push(value);
        }
      }
      value=tmp_day_arr.join(",");
    }else if(name=="one_day_amount"){
      value=strFunc.comma(value);
    }

    let chage_data_json:any={
      [name]: value
    };

    if(name=="start_h"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(value,make_option.start_m);
    }else if(name=="start_m"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(make_option.start_h,value);
    }else if(name=="end_h"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(value,make_option.end_m);
    }else if(name=="end_m"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(make_option.end_h,value);
    }

    //유효성체크
    if(strFunc.str_in_array(name,["start_h","start_m","end_h","end_m"])!=-1){
      chage_data_json={
        ...make_option,
        ...chage_data_json
      };
      let start_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_start_time));
      let end_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_end_time));
      if(start_time_num>end_time_num){
        chage_data_json.a_reserve_end_time=chage_data_json.a_reserve_start_time;
        chage_data_json.end_h=chage_data_json.start_h;
        chage_data_json.end_m=chage_data_json.start_m;
      }
    }
    
    set_make_option({
      ...make_option,
      ...chage_data_json
    });
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let change_date_arr={
      ...make_option,
      [key]: date_str
    };
    if(key=="reserve_start_date"||key=="reserve_end_date"){
      let start_date=change_date_arr["reserve_start_date"];
      let end_date=change_date_arr["reserve_end_date"];
      let start_date_obj=new Date(start_date);
      let end_date_obj=new Date(end_date);
      if(end_date_obj.getTime()<start_date_obj.getTime()){
        if(key=="reserve_start_date"){
          change_date_arr["reserve_end_date"]=change_date_arr["reserve_start_date"];
        }else{
          change_date_arr["reserve_start_date"]=change_date_arr["reserve_end_date"];
        }
      }
      if(make_option.is_auto_amount=="1"){
        updateAmountAutoByPeriod(change_date_arr["reserve_start_date"],change_date_arr["reserve_end_date"]);
      }
    }else{
      set_make_option(change_date_arr);
    }
  };

  const updateAmountAutoByPeriod=(start_date:string,end_date:string)=>{
    let todo_content_arr=myProps.get_todo_content_arr();
    let diff_days=DateFunc.get_diff_days_of_date(new Date(start_date),new Date(end_date));
    diff_days=diff_days+1;
    
    //분량얻기
    let todo_amount=0;
    let todo_amount_time=0;
    for(let i=0;i<todo_content_arr.length;i++){
      let todo_content=todo_content_arr[i];
      if(todo_content.a_success_sort=="amount"){
        todo_amount+=parseInt(strFunc.uncomma(todo_content.row_view_success_amount));
      }else{
        todo_amount_time+=parseInt(strFunc.uncomma(todo_content.row_view_success_amount));
      }
    }
    
    let one_day_amount=make_option.one_day_amount;
    if(diff_days!=0&&todo_amount!=0){
      one_day_amount=Math.ceil(todo_amount/diff_days);
      //적용요일곱하기
      let apply_day_arr=make_option.apply_day.split(",");
      let apply_day_len=apply_day_arr.length;
      if(apply_day_len>0){
        one_day_amount=Math.ceil((7/apply_day_len)*one_day_amount);
      }
    }
    set_make_option({
      ...make_option,
      "reserve_start_date":start_date,
      "reserve_end_date":end_date,
      "one_day_amount":one_day_amount,
    });
  };

  let day_of_week_arr=[
    {"value":"0","text":"일"},
    {"value":"1","text":"월"},
    {"value":"2","text":"화"},
    {"value":"3","text":"수"},
    {"value":"4","text":"목"},
    {"value":"5","text":"금"},
    {"value":"6","text":"토"},
  ];
  let day_of_week_arr_checks=day_of_week_arr.map((item,idx)=>{
    let bChecked=false;
    let tmp_day_arr=make_option.apply_day.split(",");
    if(strFunc.str_in_array(item.value,tmp_day_arr)!=-1){
      bChecked=true;
    }
    return (
      <label key={idx} className="mx-1">
        <input type="checkbox" name="apply_day" value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  let time_h_arr=[];
  let time_m_arr=[];
  for(let i=0;i<24;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_h_arr.push(i_str);
  }
  for(let i=0;i<60;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_m_arr.push(i_str);
  }
  let time_h_options=time_h_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });
  let time_m_options=time_m_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  return (
    <div>
      <h5>작성옵션</h5>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="10%"></col>
            <col width="90%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>분량자동</th>
              <td>
                <select className="row-input" name="is_auto_amount" value={make_option.is_auto_amount} onChange={handleInputChange} style={{width:70}}>
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
                <span className="ml-2" style={{color:"#777"}}>※기간선택 시 분량자동산출.</span>
              </td>
            </tr>
            <tr>
              <th>기간</th>
              <td>
                <div style={{display:"inline-block",width:100}}>
                  <DatePicker 
                    selected={make_option.reserve_start_date!=""?new Date(make_option.reserve_start_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("reserve_start_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
                ~
                <div style={{display:"inline-block",width:100}}>
                  <DatePicker 
                    selected={make_option.reserve_end_date!=""?new Date(make_option.reserve_end_date):null} 
                    onChange={(date:Date) => {
                      onChangeDatePicker("reserve_end_date",date);
                    }}
                    locale={ko} 
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>시간</th>
              <td>
                <select className="row-input" name="start_h" value={make_option.start_h} onChange={handleInputChange} style={{width:60}} >
                  {time_h_options}
                </select>:
                <select className="row-input" name="start_m" value={make_option.start_m} onChange={handleInputChange} style={{width:60}} >
                  {time_m_options}
                </select>
                ~
                <select className="row-input" name="end_h" value={make_option.end_h} onChange={handleInputChange} style={{width:60}} >
                  {time_h_options}
                </select>:
                <select className="row-input" name="end_m" value={make_option.end_m} onChange={handleInputChange} style={{width:60}} >
                  {time_m_options}
                </select>
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td>
                {day_of_week_arr_checks}
              </td>
            </tr>
            <tr>
              <th>하루분량</th>
              <td>
                <input type="text" className="row-input" name="one_day_amount" value={make_option.one_day_amount}
                  onChange={handleInputChange} placeholder="분량" style={{width:40,textAlign:"right"}} />개
                <span style={{display:"none"}}>
                  <input type="text" className="row-input ml-1" name="one_day_amount_time" value={make_option.one_day_amount_time}
                    onChange={handleInputChange} placeholder="분" style={{width:40,textAlign:"right"}} />분
                </span>
                <span className="ml-1" style={{color:"#777"}}>※하루당 분배 수량.</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="text-center mt-2">
        <button className="btn-m btn-yellowish2" onClick={()=>{myProps.make_todo_write_plan();}} >플랜작성</button>
      </div>
    </div>
  );
});

export default MakeOptionArea;
