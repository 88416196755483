//import ExcelFunc from '@/lib/lyg/excel';

class ExcelFunc
{
  //엑셀날짜는 1900년1월1일 부터 지난 일수고, 소수점은 시간, 1900~1970  까지  지난 일수
  //unix는 1970 부터 밀리초다
  //엑셀타임을 유닉스타음으로 변환
  //((엑셀일수-1900~1970일수)*하루초+한국시간초)*밀리초
  //let unix_time=ExcelFunc.excel_time_to_unix_time(excel_time);
  static excel_time_to_unix_time(excel_time){
    let unix_time=0;
    excel_time=parseInt(excel_time);
    let excel_time_diff_1900_1970=25569;//1900~1970 일수
    let one_day_sec=86400;//하루초
    let kr_sec_plus_sec=32400;//한국시간으로 변경 +9시간
    unix_time=((excel_time-excel_time_diff_1900_1970)*one_day_sec+kr_sec_plus_sec)*1000;
    return unix_time;
  }
}
export default ExcelFunc;