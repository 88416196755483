import DateFunc from '@/lib/lyg/date_func';

export const default_info={
  "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
  "a_seq":"",
  "a_counsel_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
  "a_stu_seq":"",
  "a_stu_name":"",
  "a_writer_seq":"",
  "a_writer":"",
  "a_school":"",
  "a_school_grade":"",
  "a_school_class":"",
  "a_school_num":"",
  "a_gender":"",
  "a_stu_etc":"",
  "a_goal_high_school":"",
  "a_recommend_major1":"",
  "a_recommend_major2":"",
  "a_recommend_major3":"",
  "a_goal_course":"",
  "a_recommend_job1":"",
  "a_recommend_job2":"",
  "a_recommend_job3":"",
  "a_subject1":"",
  "a_subject2":"",
  "a_subject_society":"",
  "a_subject_science":"",
  "a_theme1":"",
  "a_theme2":"",
  "a_theme3":"",
  "a_theme1_content":"",
  "a_theme2_content":"",
  "a_theme3_content":"",
  "a_plan_subject":"",
  "a_plan_subject_content":"",
  "a_plan_habit":"",
  "a_plan_habit_content":"",
  "a_recommend_book":"",
};