import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import ViewFrameContent from "./area/view";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import SendPushPopupContent from "@/pages/comp/basic/push/popup/send";
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import SearchArea from "./area/search/search_area";

function ListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_mcomp_seq":user.comp_seq,
      "s_is_receive":"empty",
      "s_owner_seq":"",
      "s_addon_user":"1",
      "s_start_date":DateFunc.get_date_format(new Date(),"Y-m-01"),
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [iam_info,set_iam_info]=useState<any>({});
  const ViewFrameContentRef=useRef<any>(null);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"send",
    receive_user_seq_arr:[],
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
    //getIamInfo();
  }, []);

  const getIamInfo=()=>{
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }
    let formJsonData={
      "s_pri_arr":[user.user_seq],
      "s_addon_comp_link":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_iam_info=response.data["data"]["info_arr"][0];
          set_iam_info(tmp_iam_info);
          setTimeout(()=>{list({});},100);
        }else{
          
        }
      }else{
        
      }
    });
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let tmpPageData={...pageData};
    tmpPageData.listOpt[key]=date_str;
    setPageData({
      ...tmpPageData
    });
    list({[key]: date_str,"now_page":1});
  };

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      if(listAggridRef.current){
        inOptObj["sc"]=listAggridRef.current.getListSc();
      }
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }

    let tmp_list_opt={...pageData.listOpt};
    
    if(strFunc.is_empty(tmp_list_opt.s_mcomp_seq)){
      return false;
    }
    // if(strFunc.is_empty(iam_info.comp_link_info)){
    //   return false;
    // }
    // tmp_list_opt["s_owner_seq"]=iam_info.comp_link_info.a_main_user_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        //선택
        if(pageData.listOpt.select_cat_seq!=""){
          for(let i=0;i<response.data["data"]["info_arr"].length;i++){
            let info=response.data["data"]["info_arr"][i];
            if(info["a_seq"]==pageData.listOpt.select_cat_seq){
              ViewFrameContentRef.current.setInitData({
                row_data:info,
                isUpdate:true,
              });
            }
          }
          setTimeout(()=>{
            let listNodes=listAggridRef.current.getNodeRows();
            for(let i=0;i<listNodes.length;i++){
              let node=listNodes[i];
              if(node.data["a_seq"]==pageData.listOpt.select_cat_seq){
                listNodes[i].setSelected(true);
              }
            }
          },100);
          
        }
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        setWriteState();
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const setWriteState=()=>{
    listAggridRef.current.setSelectFalseAll();
    if(ViewFrameContentRef.current){
      ViewFrameContentRef.current.setInitData();
    }
  };

  const openSendPushPopup=()=>{
    let pop_title="푸시메세지 보내기";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "sort":"send",
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
    if(ViewFrameContentRef.current){
      ViewFrameContentRef.current.setInitData({
        row_data:params.data,
        isUpdate:true,
      });
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_receive"){
      if(params.data.a_is_receive=="1"){
        render_str="받음";
      }else{
        render_str="보냄";
      }
    }else if(key=="row_view_sender"){
      if(params.data.sender_info){
        render_str=params.data.sender_info["a_user_name"];
      }
    }else if(key=="row_view_receiver"){
      if(params.data.receiver_info){
        render_str=params.data.receiver_info["a_user_name"];
      }
    }else if(key=="a_is_read"){
      if(render_str=="1"){
        render_str="예";
      }else{
        render_str="아니오";
      }
    }
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <table style={{width:"100%"}}>
          <colgroup>
            <col width={"50%"}/>
            <col width={"*"}/>
          </colgroup>
          <tbody>
            <tr>
              <td style={{verticalAlign:"top"}}>
                <div style={{minWidth:250}}>
                  <SearchArea
                    listOpt={pageData.listOpt}
                    list={list}
                    goDelete={goDelete}
                    openSendPushPopup={openSendPushPopup}
                  ></SearchArea>
                  <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{...xColumnArr}}
                  list={list}
                  gridOpt={{
                    fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num:true,
                    is_add_checkbox:true,
                    floatingFilter:true,
                    onGridReady:()=>{
                      list({});
                    },
                    onCellClicked:cellClickedListener,
                    cellRenderer:cellRenderer,
                  }}
                  ></ListAggrid>
                  <Paging now_page={pageData.listOpt.now_page}
                    num_per_page={pageData.listOpt.num_per_page}
                    total_rec={countInfo.tot} 
                    onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
                </div>
              </td>
              <td style={{verticalAlign:"top",paddingLeft:10}}>
                <div style={{minWidth:350}}>
                  <ViewFrameContent
                    ref={ViewFrameContentRef}
                    list={(listOpt:any)=>{list(listOpt);}}
                  ></ViewFrameContent>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="send"&&
            <SendPushPopupContent callback={()=>{list({});}} 
              receive_user_seq_arr={[]}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}>
            </SendPushPopupContent>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;