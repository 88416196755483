import React, { useEffect, useRef, useState } from "react";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';

const LectureTimeTableArea = (props: any) => {
  let myProps: any = {
    lecture_info: null,
    stu_info: null,
    lecture_user_time_arr: [],
    teacherInfoArr:[],
    room_info_arr:[],
    set_lecture_user_time_arr: (inData: any) => { },
    ...props
  };

  let dark = useSelector((state: any) => state.dark);
  const default_stu_time_row_data = {
    "a_lecture_seq": myProps.lecture_info.a_seq,
    "a_stu_seq": myProps.stu_info.a_seq,
    "a_day_num": "",
    "a_seq": "",
    "a_stu_name": myProps.stu_info.a_user_name,
    "a_is_custom":"",
    "a_name":"",
    "a_start_h": "09",
    "a_start_m": "00",
    "a_end_h": "10",
    "a_end_m": "00",
    "a_room_seq": "",
    "a_teacher_seq": "",
    "a_teacher_name": "",
    "a_date": "",
  };
  const dayNumArr = [
    { value: 0, text: "일", color: "red" },
    { value: 1, text: "월", color: "black" },
    { value: 2, text: "화", color: "black" },
    { value: 3, text: "수", color: "black" },
    { value: 4, text: "목", color: "black" },
    { value: 5, text: "금", color: "black" },
    { value: 6, text: "토", color: "blue" },
  ];
  if (dark.dark == "dark") {
    for (let i = 0; i < dayNumArr.length; i++) {
      if (dayNumArr[i]["color"] == "black") {
        dayNumArr[i]["color"] = "white";
      }
    }
  }
  let teacherInfoArr=myProps.teacherInfoArr;
  let room_info_arr=myProps.room_info_arr;

  const init_time_arr_by_lecture=()=>{
    let new_lecture_user_time_arr=[];
    for(let i=0;i<myProps.lecture_user_time_arr.length;i++){
      if(myProps.lecture_user_time_arr[i]["a_lecture_seq"]==myProps.lecture_info.a_seq){

      }else{
        new_lecture_user_time_arr.push(myProps.lecture_user_time_arr[i]);
      }
    }
    myProps.set_lecture_user_time_arr(new_lecture_user_time_arr);
  };

  const getTimeRowTagsByDayNum = (dayNum: number) => {
    let timeRows: any = getTimeRowsByDayNum(dayNum);
    let timeRowTags = timeRows.map((item: any, idx: number) => {
      let first_td: any = "";
      if (idx == 0) {
        let dayNumData = getDayDataByDayNum(dayNum);
        first_td = (
          <td rowSpan={timeRows.length} style={{ color: dayNumData?.color, verticalAlign: "top" }}>
            <div style={{ height: 30, lineHeight: "30px" }}>
              {dayNumData?.text}
            </div>
          </td>
        );
      }

      let teacher_name=item.a_teacher_name;
      for(let i=0;i<teacherInfoArr.length;i++){
        if(teacherInfoArr[i]["a_seq"]==item.a_teacher_seq){
          teacher_name=teacherInfoArr[i]["a_user_name"];
        }
      }
      let room_name="";
      for(let i=0;i<room_info_arr.length;i++){
        if(room_info_arr[i]["a_seq"]==item.a_room_seq){
          room_name=room_info_arr[i]["a_name"];
        }
      }
      let is_check_stu=false;
      for(let i=0;i<myProps.lecture_user_time_arr.length;i++){
        let lecture_user_time=myProps.lecture_user_time_arr[i];
        if(lecture_user_time["a_lecture_seq"]==item["a_lecture_seq"]
          &&lecture_user_time["a_day_num"]==item["a_day_num"]
          &&lecture_user_time["a_seq"]==item["a_seq"]){
            is_check_stu=true;
        }
      }

      let active_td_style:any={};
      if(is_check_stu){
        active_td_style["color"]="#000";
        active_td_style["background"]="#bcffb6";
      }

      return (
        <tr key={idx}>
          {first_td}
          <td style={{borderLeft:0,...active_td_style}}>
            {item.a_name}
          </td>
          <td style={active_td_style}>
            {strFunc.str_pad({ "str": item.a_start_h, "pad_length": 2 })}:{strFunc.str_pad({ "str": item.a_start_m, "pad_length": 2 })}
            ~
            {strFunc.str_pad({ "str": item.a_end_h, "pad_length": 2 })}:{strFunc.str_pad({ "str": item.a_end_m, "pad_length": 2 })}
          </td>
          <td style={active_td_style}>
            <label>
              <div style={{width:"100%",height:"100%",cursor:"pointer"}}>
                <input type="checkbox" checked={is_check_stu} onChange={(e:any)=>{
                  if(e.target.checked){
                    addTimeRowByTime(item);
                  }else{
                    removeTimeRowOfIdx(item.a_lecture_seq,item.a_day_num, item.a_seq);
                  }
                }} />
              </div>
              </label>
          </td>
        </tr>
      );
    });

    return timeRowTags;
  };

  const getDayDataByDayNum = (dayNum: number) => {
    let dayNumLen = dayNumArr.length;
    let dayNumData = null;
    for (let i = 0; i < dayNumLen; i++) {
      if (dayNumArr[i].value == dayNum) {
        dayNumData = dayNumArr[i];
      }
    }
    return dayNumData;
  };

  const getTimeRowsByDayNum = (dayNum: number) => {
    let timeRows = [];
    if(myProps.lecture_info.time_arr){
      let time_len = myProps.lecture_info.time_arr.length;
      for (let i = 0; i < time_len; i++) {
        let timeRowData = myProps.lecture_info.time_arr[i];
        if (timeRowData["a_day_num"] == dayNum) {
          timeRows.push(timeRowData);
        }
      }
    }

    return timeRows;
  };

  const addTimeRowByTime=(time_row:any)=>{
    let tmpTimeInfoArr=myProps.lecture_user_time_arr;
    let add_row_data={
      ...default_stu_time_row_data,
      ...time_row,
      "a_stu_seq": myProps.stu_info.a_seq,
      "a_stu_name": myProps.stu_info.a_user_name,
    };
    let is_pre_exist=false;
    for(let i=0;i<tmpTimeInfoArr.length;i++){
      let lecture_user_time=tmpTimeInfoArr[i];
      if(lecture_user_time["a_stu_seq"]==myProps.stu_info.a_seq
        &&lecture_user_time["a_day_num"]==time_row.a_day_num
        &&lecture_user_time["a_seq"]==time_row.a_seq
        &&lecture_user_time["a_lecture_seq"]==myProps.lecture_info.a_seq){
          is_pre_exist=true;
      }
    }
    if(is_pre_exist==false){
      tmpTimeInfoArr.push(add_row_data);
      myProps.set_lecture_user_time_arr(tmpTimeInfoArr);
    }
  }

  const removeTimeRowOfIdx = (lecture_seq:number,dayNum: number, idx: number) => {
    let time_len = myProps.lecture_user_time_arr.length;
    let tmpTimeInfoArr = [];
    for (let i = 0; i < time_len; i++) {
      let timeRowData = myProps.lecture_user_time_arr[i];
      if (timeRowData["a_lecture_seq"] == lecture_seq&&timeRowData["a_day_num"] == dayNum && timeRowData["a_seq"] == idx) {

      } else {
        tmpTimeInfoArr.push(timeRowData);
      }
    }
    myProps.set_lecture_user_time_arr(tmpTimeInfoArr);
  };

  return (
    <div>
      <div className="list-table-div gray_table" >
        <table style={{ width: "auto" }} >
          <colgroup>
            <col width="50px"></col>
            <col width="80px"></col>
            <col width="100px"></col>
            <col width="50px"></col>
          </colgroup>
          <thead>
            <tr>
              <th>day</th>
              <th>교시</th>
              <th>시간</th>
              <th>chk</th>
            </tr>
          </thead>
          <tbody>
            {dayNumArr.map((item: any, idx: number) => {
              let timeRowTags = getTimeRowTagsByDayNum(item.value);
              if(timeRowTags.length==0){
                return "";
              }
              return (
                <React.Fragment key={idx} >
                  {timeRowTags.length > 0 ? timeRowTags :
                    <tr>
                      <td style={{ color: item.color }}>
                        {item.text}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  }
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="btn-box-center my-1">
        <button className="btn btn-dark" onClick={()=>{init_time_arr_by_lecture();}}>초기화</button>
      </div>
    </div>
  );
};
export default LectureTimeTableArea;