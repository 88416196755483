import Style from "../../css/style.module.css";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from "../search";
import SearchRoomArea from "../search_room";
import RoomListArea from "../room_list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudentPopup from "../../popup/find_stu_popup";
import ViewDeskPopup from "../../popup/view_desk_popup";
import SelectStuAllowDeskPopup from "../../popup/select_stu_alloc_popup";
import RoomMsgFunc from "./func/msg_func";

function RoomListWrap(props: any) {
  let myProps = {
    s_room_seq: "",
    is_show_search_room: true,
    is_show_paging: true,
    is_detal_popup: true,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    now_page: 1,
    num_per_page: 20,
    order_id: 'a_order_num, a_name',
    order_type: '',
    s_room_seq: myProps.s_room_seq,
    s_is_use: "1",
    s_addon_desk: "1",
    s_addon_use: "1",
    s_addon_lecture: "1",
    s_addon_now_time: "1",
    s_select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
  });
  const room_list_wrap_ref= useRef<any>();
  const RoomListAreaRef = useRef();
  const [search_room_list, set_search_room_list] = useState([]);
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [student_info_arr, set_student_info_arr] = useState([]);
  const pre_room_info_arr = useRef([]);
  const [now_ymdhis, set_now_ymdhis] = useState(DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"));
  const [is_now_time, set_is_now_time] = useState(true);
  const now_ymdhis_ref = useRef(now_ymdhis);
  const [is_show_only_now_lecture,set_is_show_only_now_lecture]=useState(true);
  const [is_show_msg, set_is_show_msg] = useState(strFunc.get_storage("desk_main_is_show_msg", "1") == "1" ? true : false);
  const [room_msg_arr, set_room_msg_arr] = useState<any>([]);
  const [is_show_stu_select_list, set_is_show_stu_select_list] = 
    useState(strFunc.get_storage("desk_main_is_show_stu_select_list", "") == "1" ? true : false);
  const [is_stu_filter_lecture, set_is_stu_filter_lecture] = 
    useState(strFunc.get_storage("desk_main_is_stu_filter_lecture", "") == "1" ? true : false);
  const [is_stu_filter_use_desk, set_is_stu_filter_use_desk] = 
    useState(strFunc.get_storage("desk_main_is_stu_filter_use_desk", "") == "1" ? true : false);
  const [select_stu_filter,set_select_stu_filter]=useState({
    s_stu_grade:"",
    s_user_name:"",
    s_attend_sort_detail:"no_absent",//attend,late,absent,empty,no_absent
  });
  const [select_stu_data, set_select_stu_data] = useState({
    is_select: false,
    stu_info: null,
  });
  const [is_add_attend_by_desk, set_is_add_attend_by_desk] = 
    useState(strFunc.get_storage("desk_main_is_add_attend_by_desk", "1") == "1" ? true : false);

  const [popupData, setPopupData] = useState({
    stu_info: null,
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    isOpen: false,
    sort: "find_stu",//find_stu,view_desk
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    search_room_list_by_ajax();
    list({});
    get_student_arr_by_ajax();
  }, []);

  useEffect(() => {
    const count_loop = setInterval(tick_now_ymdhis, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, [is_now_time]);

  const tick_now_ymdhis = () => {
    if(is_now_time==false){return false;}
    let tick_date_obj = DateFunc.get_change_date(new Date(now_ymdhis_ref.current), 'sec', +1);
    now_ymdhis_ref.current = DateFunc.get_date_format(tick_date_obj, "Y-m-d h:i:s");
    set_now_ymdhis(now_ymdhis_ref.current);
    if (parseInt(DateFunc.get_date_format(tick_date_obj, "s")) == 0) {
      list({});
      get_student_arr_by_ajax();
    }
  };

  //드래그 애니메이션
  // useEffect(() => {
  //   room_list_wrap_ref.current.addEventListener("mousemove", (e: any) => {
  //     if(select_stu_grag_box_ref.current){
  //       select_stu_grag_box_ref.current.style.left=(e.pageX+10)+"px";
  //       select_stu_grag_box_ref.current.style.top=(e.pageY+10)+"px";
  //     }
  //   });
  // }, []);

  const search_room_list_by_ajax = () => {
    let search_room_form_json = {
      order_id: 'a_order_num, a_name',
      order_type: '',
      s_is_use: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', search_room_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          set_search_room_list(response.data["data"]["info_arr"]);
        }
      });
  };

  const list = (inOptObj: any) => {
    let tmp_listOpt:any={
      ...listOpt,
      is_now_time:is_now_time,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', tmp_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if(tmp_listOpt.is_now_time){
            set_now_ymdhis(response.data["data"]["now_ymdhis"]);
            now_ymdhis_ref.current = response.data["data"]["now_ymdhis"];
            let change_room_msg_arr=RoomMsgFunc.get_msg_arr_by_room_list({
              room_info_arr:response.data["data"]["info_arr"],
              pre_room_info_arr:pre_room_info_arr.current,
              room_msg_arr:room_msg_arr,
              now_ymdhis:response.data["data"]["now_ymdhis"],
            });
            set_room_msg_arr(change_room_msg_arr);
            pre_room_info_arr.current=response.data["data"]["info_arr"];
          }
        } else {

        }
      });
  };

  const get_student_arr_by_ajax=()=>{
    let form_json_data={
      order_id:"a_stu_grade, a_user_name",
      s_stu_state:"now",
      s_grade: ["student"],
      s_addon_attend: "1",
      s_addon_attend_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_attend_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_lecture_time: "1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_student_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const open_find_stu_popup = (inData: any) => {
    let opt_obj: any = {
      "desk_info": null,
      "now_ymdhis": "",
      "now_lecture_data": null,
      ...inData,
    };

    setPopupData({
      ...popupData,
      "desk_info": opt_obj["desk_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "학생찾기",
      "sort": "find_stu"
    });
  };

  const open_view_desk_popup = (inData: any) => {
    let opt_obj = {
      room_info: null,
      desk_info: null,
      desku_info: null,
      now_ymdhis: "",
      now_lecture_data: null,
      ...inData
    };
    setPopupData({
      ...popupData,
      "room_info": opt_obj["room_info"],
      "desk_info": opt_obj["desk_info"],
      "desku_info": opt_obj["desku_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "책상보기",
      "sort": "view_desk"
    });
  };

  const open_select_stu_alloc_desk_popup = (inData: any) => {
    let opt_obj: any = {
      "desk_info": null,
      "now_ymdhis": "",
      "now_lecture_data": null,
      ...inData,
    };

    if(select_stu_data.is_select==false||select_stu_data.stu_info==null){
      alert("학생선택이 필요합니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      stu_info:select_stu_data.stu_info,
      "desk_info": opt_obj["desk_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "학생찾기",
      "sort": "select_stu_alloc"
    });
  };

  const callBackDataFindStu = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      "desk_info": null,
      "now_ymdhis": "",
      "select_time": {
        "start_h": 0,
        "start_m": 0,
        "end_date":"0000-00-00",
        "end_h": 0,
        "end_m": 0,
      },
      ...inData,
    };

    if (opt_obj["info_arr"].length == 0) {
      alert("학생 선택이 필요합니다.");
      return false;
    }
    if (opt_obj["desk_info"] == null) {
      alert("책상정보가 없습니다.");
      return false;
    }
    if (!confirm("등록 하시겠습니까?")) {
      return false;
    }
    let u_info = opt_obj["info_arr"][0];
    let tmp_start_date_json=DateFunc.get_date_json(new Date(opt_obj["now_ymdhis"]));
    let tmp_end_hi=strFunc.HourMinuteToTime(opt_obj["select_time"]["end_h"],opt_obj["select_time"]["end_m"]);
    let tmp_end_date_json=DateFunc.get_date_json(new Date(opt_obj["select_time"]["end_date"]+" "+tmp_end_hi+":"+tmp_start_date_json.s));
    let target_time_sec=tmp_end_date_json.date_obj.getTime()-tmp_start_date_json.date_obj.getTime();
    target_time_sec=target_time_sec/1000;

    let desk_u_row = {
      "a_ymd": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Ymd"),
      "a_seq": "",
      "a_room_seq": opt_obj["desk_info"]["a_room_seq"],
      "a_x": opt_obj["desk_info"]["a_x"],
      "a_y": opt_obj["desk_info"]["a_y"],
      "a_name": opt_obj["desk_info"]["a_name"],
      "a_date": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Y-m-d"),
      "a_end_date": opt_obj["select_time"]["end_date"],
      "a_user_seq": u_info["a_seq"],
      "a_user_name": u_info["a_user_name"],
      "a_user_grade": u_info["a_user_grade"],
      "a_start_h": opt_obj["select_time"]["start_h"],
      "a_start_m": opt_obj["select_time"]["start_m"],
      "a_end_h": opt_obj["select_time"]["end_h"],
      "a_end_m": opt_obj["select_time"]["end_m"],
      "a_target_time_sec": target_time_sec,
      "a_writer_seq": user.user_seq,
      "a_writer": user.user_name,
    };

    let is_after_write_attend="";
    if(is_add_attend_by_desk){
      //is_after_write_attend="1";
    }
    let desku_w_form_data = {
      "data_arr": [desk_u_row],
      "is_default_val": "1",
      "is_after_write_attend": is_after_write_attend,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/add_arr', desku_w_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          get_student_arr_by_ajax();
          if(select_stu_data.is_select){
            set_select_stu_data({
              is_select:false,
              stu_info:null,
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div ref={room_list_wrap_ref}>
      <div>
        <SearchArea 
          list={list} 
          listOpt={listOpt} 
          now_ymdhis={now_ymdhis}
          set_now_ymdhis={set_now_ymdhis}
          is_now_time={is_now_time}
          set_is_now_time={set_is_now_time}
          is_show_only_now_lecture={is_show_only_now_lecture}
          set_is_show_only_now_lecture={set_is_show_only_now_lecture}
          is_show_msg={is_show_msg}
          set_is_show_msg={set_is_show_msg}
          set_room_msg_arr={set_room_msg_arr}
          is_show_stu_select_list={is_show_stu_select_list}
          set_is_show_stu_select_list={set_is_show_stu_select_list}
          is_stu_filter_lecture={is_stu_filter_lecture}
          set_is_stu_filter_lecture={set_is_stu_filter_lecture}
          select_stu_filter={select_stu_filter}
          set_select_stu_filter={set_select_stu_filter}
          is_stu_filter_use_desk={is_stu_filter_use_desk}
          set_is_stu_filter_use_desk={set_is_stu_filter_use_desk}
          select_stu_data={select_stu_data}
          set_select_stu_data={set_select_stu_data}
          is_add_attend_by_desk={is_add_attend_by_desk}
          set_is_add_attend_by_desk={set_is_add_attend_by_desk}
          ></SearchArea>
        {myProps.is_show_search_room &&
          <SearchRoomArea list={list} s_room_seq={listOpt.s_room_seq} search_room_list={search_room_list}></SearchRoomArea>
        }
        <RoomListArea ref={RoomListAreaRef}
          room_info_arr={infoArr}
          now_ymdhis={now_ymdhis}
          open_find_stu_popup={open_find_stu_popup}
          open_view_desk_popup={open_view_desk_popup}
          open_select_stu_alloc_desk_popup={open_select_stu_alloc_desk_popup}
          is_detal_popup={myProps.is_detal_popup}
          is_show_only_now_lecture={is_show_only_now_lecture}
          is_show_msg={is_show_msg}
          is_show_stu_select_list={is_show_stu_select_list}
          is_stu_filter_lecture={is_stu_filter_lecture}
          is_stu_filter_use_desk={is_stu_filter_use_desk}
          select_stu_filter={select_stu_filter}
          set_select_stu_filter={set_select_stu_filter}
          room_msg_arr={room_msg_arr}
          student_info_arr={student_info_arr}
          select_stu_data={select_stu_data}
          set_select_stu_data={set_select_stu_data}
        ></RoomListArea>
        {myProps.is_show_paging &&
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "find_stu" &&
            <FindStudentPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={callBackDataFindStu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindStudentPopup>
          }
          {popupData.sort === "select_stu_alloc" &&
            <SelectStuAllowDeskPopup
              stu_info={popupData.stu_info}
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={callBackDataFindStu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></SelectStuAllowDeskPopup>
          }
          {popupData.sort == "view_desk" &&
            <ViewDeskPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              desku_info={popupData.desku_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={() => { list({ "now_page": "1" }) }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewDeskPopup>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default RoomListWrap;