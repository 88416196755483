import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"msms_sender",
    list_id:"msms_sender_list",
    x_column_list_arr:[
      {"key":"a_public_sort","name":"공개구분","width":"100","is_show":"1"},
      {"key":"row_view_mcomp_name","name":"학원","width":"110","is_show":"1"},
      {"key":"a_sender_num","name":"발신번호","width":"120","is_show":"1"},
      {"key":"a_request_date","name":"요청일","width":"100","is_show":"1"},
      {"key":"a_is_agree","name":"승인여부","width":"120","is_show":"1"},
      {"key":"a_requester","name":"요청자","width":"120","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':20,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;