import { useState } from 'react';
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from "@/pcomponents/common/content/loading";

function StuViewArea(props: any) {
  let myProps = {
    stu_info: {},
    refresh_data: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let stu_info = myProps.stu_info;
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let stu_grade_str = stu_info["a_stu_grade"];
  for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
    if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
      stu_grade_str = select_stu_grade_option_arr[i]["text"];
    }
  }
  const [loading_data,set_loading_data]=useState({
    is_display:false,
    text:"저장완료",
  });

  const stu_gold_in_out_by_ajax = (inData: any) => {
    let opt_obj = {
      in_out: "in",//in,out
      amount: 30,
      ...inData
    };
    let stu_main_user_seq = "";
    let stu_user_name = "";
    if (stu_info["comp_link_info"]
      && stu_info["comp_link_info"]["main_u_info"]
      && stu_info["comp_link_info"]["main_u_info"]["a_seq"]) {

      stu_main_user_seq = stu_info["comp_link_info"]["main_u_info"]["a_seq"];
      stu_user_name = stu_info["comp_link_info"]["main_u_info"]["a_user_name"];
    }
    let par_id = "comp_char_opt_popup";
    let msg = "양분추가";
    if (opt_obj["in_out"] == "out") {
      msg = "양분빼기";
    }

    if(strFunc.is_empty(stu_main_user_seq)){
      return false;
    }
    if(strFunc.is_empty(stu_user_name)){
      return false;
    }
    if(strFunc.is_empty(opt_obj["amount"])){
      return false;
    }
    if(strFunc.is_empty(opt_obj["in_out"])){
      return false;
    }
    let confirm_msg="양분추가를 하시겠습니까?";
    if(opt_obj["in_out"]=="out"){
      confirm_msg="양분빼기를 하시겠습니까?";
    }
    if(!confirm(confirm_msg)){
      return false;
    }

    let form_json_data = {
      "row_data": {
        a_main_user_seq: stu_main_user_seq,
        a_user_name: stu_user_name,
        a_in_out: opt_obj["in_out"],
        a_msg: msg,
        a_par_mcomp_seq: user.comp_seq,
        a_par_id: par_id,
        a_par_seq: "",
        a_amount: opt_obj["amount"],
      },
      "is_able_duplicate": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data({});
          set_loading_data({
            is_display:true,
            text:"저장완료",
          });
          setTimeout(()=>{
            set_loading_data({
              is_display:false,
              text:"저장완료",
            });
          },800);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}
      ></LoadingComponent>
      <span style={{ color: "blue", fontWeight: "bold" }}>
        {stu_info.a_user_name}
      </span>
      <span className="ml-2">학생양분 정보</span>
      <div className="list-table-div">
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"*"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>학교 학년</th>
              <td>
                {stu_info["a_school_name"]}
                <span className="ml-1">
                  {stu_grade_str}
                </span>
              </td>
            </tr>
            <tr>
              <th>양분</th>
              <td className="text-center">
                {stu_info.char_opt &&
                  <span>
                    {strFunc.comma(stu_info.char_opt["a_gold"])}
                  </span>
                }
              </td>
            </tr>
            <tr>
              <th>관리</th>
              <td className="text-center">
                <button className="btn btn-sky"
                  onClick={() => {
                    stu_gold_in_out_by_ajax({
                      in_out: "in",
                      amount: 30,
                    });
                  }}>양분주기+30</button>
                <button className="btn btn-red ml-2"
                  onClick={() => {
                    stu_gold_in_out_by_ajax({
                      in_out: "out",
                      amount: 30,
                    });
                  }}>양분빼기-30</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default StuViewArea;