import CompLayout from "@/pcomponents/comp/layout/layout";
import TopArea from "./main/area/top_area";

function Main(){
  

  return (
    <CompLayout isLeftArea={false} isConTitle={false}>
      <TopArea></TopArea>
    </CompLayout>
  );
}

export default Main;