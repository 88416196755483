import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import { useState } from 'react';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function ListBox(props: any) {
  let myProps = {
    info: {},
    row_num: 0,
    user: {},
    infoArr: [],
    setInfoArr: (inData: any) => { },
    Style: {},
    delete_by_ajax: (inData: any) => { },
    update_by_ajax: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let row_num = myProps.row_num;
  let Style = myProps.Style;

  const [is_write_mode, set_is_write_mode] = useState(false);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let tmp_infoArr = myProps.infoArr;
    tmp_infoArr[row_num][name] = value;
    myProps.setInfoArr(tmp_infoArr);
  };

  let qna_text = info["a_qna_sort"].toUpperCase();
  let qna_color = "blue";
  if (info["a_qna_sort"] == "q") {
    qna_color = "red";
  }

  let is_del_btn = false;
  let is_write_btn = false;
  if (myProps.user.user_grade == "master") {
    is_del_btn = true;
    is_write_btn = true;
  } else if (info["a_responser_seq"] == myProps.user.user_seq) {
    is_del_btn = true;
    is_write_btn = true;
  }

  return (
    <div className={Style.list_box}>
      <div className={Style.list_box_title}>
        {is_write_mode ?
          <select className="row-input" name="a_qna_sort"
            value={info["a_qna_sort"]} onChange={handleInputChange} 
            style={{width:70}}>
            <option value="q">질문</option>
            <option value="a">답변</option>
          </select>
          :
          <span className={Style.qna_span}
            style={{ color: qna_color }}>{qna_text}</span>
        }
        {info["a_responser"]}
      </div>
      <div className={Style.list_box_content}>
        {is_write_mode ?
          <TextAreaComponent
            name={"a_message"}
            value={info["a_message"]}
            height={28}
            onChange={handleInputChange}
          ></TextAreaComponent>
          :
          <MultyLineTextView
            text={info["a_message"]}
          ></MultyLineTextView>
        }
      </div>
      <div className={Style.list_box_bottom} >
        {info["a_create_date"]}
        {is_write_btn &&
          <span className="ml-1">
            |
            {is_write_mode &&
              <button className="btn-s btn-sky ml-1" onClick={() => {
                myProps.update_by_ajax({
                  data_arr: [info],
                });
              }}>저장</button>
            }
            <button className="btn-s btn-gray ml-1" onClick={() => {
              set_is_write_mode(!is_write_mode);
            }}>수정모드</button>
          </span>
        }
        {is_del_btn &&
          <span className="ml-1">
            |
            <button className="btn-s btn-red ml-1" onClick={() => {
              myProps.delete_by_ajax({
                data_arr: [info],
              });
            }}>삭제</button>
          </span>
        }
      </div>
    </div>
  );
};
export default ListBox;