import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import SupplyRecentListArea from "./area/supply_recent";

function StuEndPopup(props: any) {
  let myProps = {
    stu_info_arr: [],
    closePopup: () => { },
    callback: (inData: any) => { },
    ...props
  };
  let stu_info_arr = myProps.stu_info_arr;
  const [end_data, set_end_data] = useState({
    a_stu_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_stu_end_memo: "",
    is_del_time_table: "1",
    is_del_class_user: "1",
  });
  const SupplyRecentListAreaRef = useRef<any>(null);

  useEffect(() => {
    
    if (stu_info_arr.length == 0) {
      alert("대상 학생 정보가 없습니다.");
      myProps.closePopup();
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    set_end_data({
      ...end_data,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    set_end_data({
      ...end_data,
      ...{ [key]: date_str }
    });
  };
  //rest:휴원,end:퇴원
  const go_end_stu_by_ajax = (state_str: "rest" | "end") => {
    let state_name_str = "";
    if (state_str == "rest") {
      state_name_str = "휴원";
    } else {
      state_name_str = "퇴원";
    }
    if (!confirm("학생 " + stu_info_arr.length + "명을 " + state_name_str + "처리 하시겠습니까?")) {
      return false;
    }
    //휴퇴원 처리
    let stu_seq_arr = [];
    for (let i = 0; i < stu_info_arr.length; i++) {
      let u_info = stu_info_arr[i];
      stu_seq_arr.push(u_info["a_seq"]);
    }
    let del_supply_seq_arr: any = [];
    if (SupplyRecentListAreaRef.current) {
      let select_supply_rows = SupplyRecentListAreaRef.current.get_select_rows();
      for (let i = 0; i < select_supply_rows.length; i++) {
        let supply_info = select_supply_rows[i];
        del_supply_seq_arr.push(supply_info["a_ymd"] + "," + supply_info["a_seq"]);
      }
    }

    let formJsonData = {
      "stu_seq_arr": stu_seq_arr,
      "stu_state": state_str,
      "state_date": end_data.a_stu_end_date,
      "state_memo": end_data.a_stu_end_memo,
      "del_supply_seq_arr": del_supply_seq_arr,
      "is_del_time_table":end_data.is_del_time_table,
      "is_del_class_user":end_data.is_del_class_user,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/stu_change_state', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("완료되었습니다.");
          props.callback({});
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <p>학생 ({stu_info_arr.length})명</p>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width={"100%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                  {stu_info_arr.map((item: any, idx: number) => {
                    return (
                      <span key={idx} style={{ color: "blue" }}>
                        {item.a_user_name}
                      </span>
                    );
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="mt-2">휴/퇴원 사유</p>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"80%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>휴/퇴원일</th>
              <td>
                <DatePicker
                  selected={end_data.a_stu_end_date != "" ? new Date(end_data.a_stu_end_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_stu_end_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>
                휴/퇴원메모
              </th>
              <td>
                <input type="text" className="row-input" name="a_stu_end_memo" value={end_data.a_stu_end_memo} onChange={handleInputChange} placeholder="메모" />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <label>
                  <input type="checkbox" value="1" name="is_del_time_table" checked={end_data.is_del_time_table == "1"}
                    onChange={handleInputChange} />
                  소속시간표 제거
                </label>
                <label className="ml-2">
                  <input type="checkbox" value="1" name="is_del_class_user" checked={end_data.is_del_class_user == "1"}
                    onChange={handleInputChange} />
                  소속클래스 제거
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="mt-2">청구서</p>
      <SupplyRecentListArea
        ref={SupplyRecentListAreaRef}
        stu_info_arr={stu_info_arr}
      ></SupplyRecentListArea>
      <p>※ 선택된 청구서는 휴/퇴원 시 <span style={{ color: "red" }}>삭제</span>됩니다.</p>
      <div className="btn-box-center mt-2" >
        <button className="btn-m btn-red" onClick={() => { go_end_stu_by_ajax("rest"); }}>휴원</button>
        <button className="btn-m btn-red" onClick={() => { go_end_stu_by_ajax("end"); }}>퇴원</button>
        <button className="btn-m btn-line-gray" onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default StuEndPopup;
