//import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const FindStudentList = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => { },
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      s_addon_class_user: "1",
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    },
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll
  }));

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let tmpPageData = { ...pageData };
    tmpPageData.listOpt[name] = value;
    setPageData({
      ...tmpPageData
    });
    if (name == "s_search_text") {
      return false;
    }
    list({ [name]: value });
  };

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const setSelectFalseAll = () => {
    listAggridRef.current.setSelectFalseAll();
    myProps.list({
      "s_stu_user_seq": "",
    });
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {

    }


  }

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_manage") {

    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_stu_grade") {
      let school_name = strFunc.cut_str(params.data["a_school_name"], 2, " ");
      let stu_grade_str = params.data["a_stu_grade"];
      if (xColumnArr.select_arr["a_stu_grade"]) {
        for (let i = 0; i < xColumnArr.select_arr["a_stu_grade"].length; i++) {
          if (xColumnArr.select_arr["a_stu_grade"][i]["value"] == params.data["a_stu_grade"]) {
            stu_grade_str = xColumnArr.select_arr["a_stu_grade"][i]["text"];
          }
        }
      }
      //xColumnArr
      render_str = school_name + stu_grade_str;
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    myProps.list({
      "s_stu_user_seq": select_seq_arr,
    });
  };

  return (
    <div>
      <div className="relative" style={{ minHeight: 52 }}>
        <div className="search-box relative py-0">
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={pageData.listOpt.s_stu_lecture_seq}
            on_change_input={(inData: any) => {
              let name = inData.name;
              let value = inData.value;
              list({ now_page: 1, [name]: value });
            }}
            wrap_style={{ width: 90, display: "inline-block" }}
            select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
          ></SearchLectureSelect>
          <select className="search-input_select" name="s_stu_grade" value={pageData.listOpt.s_stu_grade} onChange={handleInputChange}>
            <option value="">학년</option>
            <option value="elementary">초</option>
            <option value="middle">중</option>
            <option value="high">고</option>
            {
              xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="search-box relative py-0 ml-0 mt-1">
          <select className="search-input_select" name="s_search_text_type" value={pageData.listOpt.s_search_text_type} onChange={handleInputChange} >
            <option value="name">이름</option>
            <option value="phone">핸드폰</option>
          </select>
          <input type="text" className="search-input" name="s_search_text"
            style={{ width: 80 }} placeholder="검색어 입력" value={pageData.listOpt.s_search_text}
            onChange={handleInputChange} onKeyUp={(e: any) => { if (e.key === 'Enter') { list({ "now_page": 1 }); } }} />
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = pageData.listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {pageData.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <button className="btn btn-gray" onClick={() => { list({ "now_page": 1 }); }} >검색</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
    </div>
  );
});

export default FindStudentList;