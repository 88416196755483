import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import CorseListArea from "./area/corse_list";
import VideoListArea from "./area/video_list";
import Style from "./css/style.module.css";

function FindVideoPopup(props:any) {
  let myProps={
    closePopup:()=>{},
    callbackData:(inData:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const [select_corse_info,set_select_corse_info]=useState(null);
  const CorseListAreaRef=useRef<any>(null);
  const VideoListAreaRef=useRef<any>(null);

  const onCorseSelect=(selected_row:any)=>{
    if(selected_row.length==1){
      VideoListAreaRef.current.setInitData({
        rowData:selected_row[0]
      });
      set_select_corse_info(selected_row[0]);
    }else{
      VideoListAreaRef.current.setInitData({
        rowData:{},
      });
      set_select_corse_info(null);
    }
  };

  const goSelectCallback=()=>{
    let select_video_list_arr=VideoListAreaRef.current.get_select_video_list();
    if(select_video_list_arr.length==0){
      alert("선택이 필요합니다.");
      return false;
    }
    //a_subject
    for(let i=0;i<select_video_list_arr.length;i++){
      select_video_list_arr[i]["a_subject"]="";
      if(select_corse_info){
        select_video_list_arr[i]["a_subject"]=select_corse_info["a_subject"];
      }
    }
    myProps.callbackData({
      "info_arr":select_video_list_arr
    });
    myProps.closePopup();
  };

  return (
    <div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{goSelectCallback();}}>선택</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
      <div className={Style.box_wrap}>
        <div className={Style.left_box}>
          <CorseListArea
            ref={CorseListAreaRef}
            onCorseSelect={onCorseSelect}
          ></CorseListArea>
        </div>
        <div className={Style.right_box}>
          <VideoListArea
            ref={VideoListAreaRef}
          ></VideoListArea>
        </div>
      </div>
    </div>
  );
}

export default FindVideoPopup;
