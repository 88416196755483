import { useSelector, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import {hmenu,smenu} from "@/pcomponents/comp_stu/layout/menu/data/menu_arr";

const SNBArea=(props:any)=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user=useSelector((state:any) => state.user);

  const movePage=(url:string)=>{
    navigate(url);
  };

  let now_h_key="";
  for(let i=0;i<smenu.length;i++){
    if(smenu[i].url==window.location.pathname){
      now_h_key=smenu[i].hmenu;
      let title_prefix="플랜아이";
      if(user.corp_name!=""){
        title_prefix=user.corp_name;
      }
      document.title =title_prefix+" "+smenu[i].name;
    }
  }
  
  const menuArrTags=smenu.map((item,idx)=>{
    if(item.hmenu!=now_h_key){
      return "";
    }
    if(!item.isShow){
      return "";
    }
    let snb_class_name="stu_snb_item";
    let compare_url=window.location.pathname;
    if(compare_url=="/comp_stu/video/my_list_view"){
      compare_url="/comp_stu/video/my_list";
    }else if(compare_url=="/comp_stu/plan/todo_list_view"){
      compare_url="/comp_stu/plan/todo_list";
    }
    if(item.url==compare_url){
      snb_class_name+=" active";
    }
    
    return (
      <div key={idx} className={snb_class_name} onClick={()=>{movePage(item.url);}} >
        {item.name}
      </div>
    );
  });

  return (
    <div className="stu_snb_area_wrap">
      <div className="stu_snb_area">
        {menuArrTags}
      </div>
    </div>
  );
};

export default SNBArea;