import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

const SearchRoomBox=(props:any)=>{
  let myProps={
    room_info:{},
    is_active:false,
    list:(inData:any)=>{},
    ...props
  };

  let room_box_class_name=Style.room_box;
  if(myProps.is_active){
    room_box_class_name+=" "+Style.active;
  }

  return (
    <div className={room_box_class_name} onClick={()=>{
      myProps.list({"now_page":"1","s_room_seq":myProps.room_info.a_seq});
    }}>
      {myProps.room_info.a_name}
    </div>
  );
};

const SearchRoomArea=(props:any)=>{
  let myProps={
    list:(inData:any)=>{},
    search_room_list:[],
    s_room_seq:"",
    ...props
  };

  const get_room_box_tags=()=>{
    let room_box_tags=myProps.search_room_list.map((room_info:any,idx:number)=>{
      let is_active=false;
      if(room_info.a_seq==myProps.s_room_seq){
        is_active=true;
      }
      return (
        <SearchRoomBox key={idx}
          room_info={room_info}
          is_active={is_active}
          list={myProps.list}
        ></SearchRoomBox>
      );
    });
    return room_box_tags;
  };

  let all_room_btn_class=Style.room_box;
  if(myProps.s_room_seq==""){
    all_room_btn_class+=" "+Style.active;
  }
  let room_box_tags=get_room_box_tags();

  return (
    <div className={Style.room_wrap}>
      <div className={all_room_btn_class} onClick={()=>{
        myProps.list({"now_page":"1","s_room_seq":""});
      }}>전체</div>
      {room_box_tags}
    </div>
  );
};
export default SearchRoomArea;