function SelectSubjectComponent(props:any){
   let myProps={
    prefix_str:"1. ",
    split_str:" / ",
    select_arr:[],//[{text:'',value:''}]
    value:[],
    name:"",
    on_change:(inData:any)=>{},
    is_view:false,
    ...props
  };

  const get_select_option_tags=()=>{
    let select_option_tags=myProps.select_arr.map((item:any,idx:number)=>{
      let row_split_str="";
      if(idx!=0){
        row_split_str=myProps.split_str;
      }
      let row_check_style:any={
        padding:3,
        borderRadius:"20px",
      };
      if(myProps.value==item.value){
        row_check_style["border"]="1px solid blue";
      }
      return (
        <span key={idx}>
          {row_split_str}
          <span style={row_check_style} onClick={()=>{
            let change_value=item.value;
            if(item.value==myProps.value){
              change_value="";
            }
            myProps.on_change({
              "name":myProps.name,
              "value":change_value,
            });
          }}>
            {item.text}
          </span>
        </span>
      );
    });
    return select_option_tags;
  };
  
  return (
  <div>
    {myProps.prefix_str}
    {get_select_option_tags()}
  </div>
  );
};
export default SelectSubjectComponent;