import CompLayout from "@/pcomponents/comp_stu/layout/layout";

function Main(){
  

  return (
    <CompLayout isLeftArea={false} isConTitle={false}>
      
    </CompLayout>
  );
}

export default Main;