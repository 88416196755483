//import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";
import strFunc from '@/lib/lyg/string';
function TimeInputComponent(props: any) {
  let myProps = {
    name: "",
    value: "",
    on_change: (inData:any) => { },
    is_his:{
      h:true,
      i:true,
      s:false,
    },
    input_style:{},
    ...props
  };

  if(strFunc.is_empty(myProps.value)){
    myProps.value="00:00:00";
  }

  let init_input_style={
    width:30,
    ...myProps.input_style
  };

  const get_his_json=()=>{
    let his_json:any={h:"00",i:"00",s:"00"};
    let his_split_arr=myProps.value.split(":");
    if(his_split_arr.length==3){
      let his_sec=strFunc.timeToSeconds(his_split_arr[0],his_split_arr[1],his_split_arr[2]);
      his_json=strFunc.secondsToTimeJson(his_sec);
    }else if(his_split_arr.length==2){
      let his_sec=strFunc.timeToSeconds(his_split_arr[0],his_split_arr[1],"00");
      his_json=strFunc.secondsToTimeJson(his_sec);
    }else if(his_split_arr.length==1){
      let his_sec=strFunc.timeToSeconds(his_split_arr[0],"00","00");
      his_json=strFunc.secondsToTimeJson(his_sec);
    }
    return his_json;
  };

  const onChangeTimeRowInput = (e: any) => {
    let value=e.target.value;
    let name=e.target.name;
    let his_key=name.replace("input_his_","");

    let his_json=get_his_json();
    value=parseInt(strFunc.uncomma(value));

    if(his_key=="h"){
      if(value>=24){
        value=23;
      }
    }else{
      if(value>=60){
        value=59;
      }
    }
    if(value<0){
      value=0;
    }

    his_json[his_key]=strFunc.str_pad({ "str":value, "pad_length": 2 });
    let change_val=his_json.h+":"+his_json.i+":"+his_json.s;
    myProps.on_change({
      name:myProps.name,
      value:change_val,
    });
  };

  const getInputTagArr=()=>{
    let his_tag_arr=[];
    let his_json:any=get_his_json();
    for(let key in myProps.is_his){
      if(myProps.is_his[key]){
        his_tag_arr.push({
          key:key,
          value:strFunc.str_pad({ "str":his_json[key], "pad_length": 2 })
        });
      }
    }
    let has_pre_tag=false;
    let his_tags=his_tag_arr.map((item:any,idx:number)=>{
      let key=item.key;
      let pre_str="";
      if(has_pre_tag){
        pre_str=":";
      }
      has_pre_tag=true;
      return (
        <span key={idx}>
          {pre_str}
          <input type="text" className="text-center" name={"input_his_"+key}
            value={item.value}
            onChange={onChangeTimeRowInput}
            onClick={(e:any)=>{e.target.select();}}
            style={init_input_style} />
        </span>
      );
    });
    return his_tags;
  }

  return (
    <>
      {getInputTagArr()}
    </>
  );
};
export default TimeInputComponent;