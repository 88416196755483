import strFunc from '@/lib/lyg/string';

function ViewPopupContent(props:any) {
  //props.rowData.a_user_name;
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="80%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>부모구분</th>
              <td>
                {props.rowData.a_par_id}
              </td>
            </tr>
            <tr>
              <th>구분컬럼</th>
              <td>
                {props.rowData.a_par_col}
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                {props.rowData.a_sort_name}
              </td>
            </tr>
            <tr>
              <th>고정</th>
              <td>
                {props.rowData.a_type}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
