import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import getXcolumnJson from "../../list/xcolumn/list";
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';

function ViewPopupContent(props:any) {
  //props.rowData.a_user_name;
  const xColumnArr=getXcolumnJson();
  const fileWriteOneRef = useRef<any>(null);
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>{props.rowData.a_stu_name}</td>
              <th>작성일</th>
              <td>{props.rowData.a_create_date}</td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>{props.rowData.a_title}</td>
            </tr>
            <tr>
              <th colSpan={4} className="text-center">내용</th>
            </tr>
            <tr>
              <td colSpan={4}>
                  {
                    props.rowData.a_content && props.rowData.a_content.split('\n').map( (line:string,idx:number) => {
                      return (<span key={idx}>{line}<br/></span>)
                    })
                  }
                </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{props.rowData.a_writer_name}</td>
              <th>성공여부</th>
              <td>{props.rowData.a_is_success}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{
            props.closePopup();
            props.openOnePopup("write",true);
          }}>수정</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
      <FileListWriteFrame
        isView={true}
        listOpt={
          {
            s_par_id:xColumnArr.table,
            s_par_seq:xColumnArr.func.getPriValByDataRow({
              xColumnArr:xColumnArr,
              rowData:props.rowData
            }),
            s_start_date:props.rowData.a_create_date
          }
        }
      ></FileListWriteFrame>
    </div>
  );
}

export default ViewPopupContent;
