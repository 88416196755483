import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"user_staff_list",
    x_column_list_arr:[
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_user_name","name":"이름","width":"100","is_show":"1"},
      {"key":"row_view_attend_in","name":"등원","width":"185","is_show":"1"},
      {"key":"row_view_attend_in_time","name":"등원시간","width":"110","is_show":"1"},
      {"key":"row_view_attend_out","name":"하원","width":"70","is_show":"1"},
      {"key":"row_view_attend_out_time","name":"하원시간","width":"110","is_show":"1"},
      {"key":"row_view_attend_memo","name":"메모","width":"110","is_show":"1"},
      {"key":"row_view_send_msg","name":"알림","width":"180","is_show":"1"},
      {"key":"row_view_par_phone","name":"학부모","width":"140","is_show":"1"},
      {"key":"row_view_class_name","name":"클래스","width":"120","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  xColumnArr.select_arr["a_sort_detail"]=[
    {"value":"attend","text":"등원"},
    {"value":"late","text":"등원(지각)"},
    {"value":"absent","text":"결석"},
    {"value":"out","text":"하원"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;