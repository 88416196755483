import strFunc from "@/lib/lyg/string";
import UserListArea from "../user_list";

function ClassListArea(props:any){
  let myProps={
    on_click_user_row:(inData:any)=>{},
    class_arr:[],
    user_info_arr:[],
    select_user_seq_arr:[],
    ...props
  };
  let user_info_arr_len=myProps.user_info_arr.length;

  const get_class_user_data_arr=()=>{
    let added_class_user_seq_arr:any=[];
    let class_user_data_arr=[];
    let class_arr_len=myProps.class_arr.length;
    for(let class_i=0;class_i<class_arr_len;class_i++){
      let class_info=myProps.class_arr[class_i];
      let row_user_arr=[];
      //클래스소속유저 얻기
      for(let user_i=0;user_i<user_info_arr_len;user_i++){
        let user_info=myProps.user_info_arr[user_i];
        if(user_info.class_arr&&user_info.class_arr.length>0){
          for(let i=0;i<user_info.class_arr.length;i++){
            let class_link=user_info.class_arr[i];
            if(class_link["a_class_seq"]==class_info["a_seq"]){
              row_user_arr.push(user_info);
              if(strFunc.str_in_array(user_info["a_seq"],added_class_user_seq_arr)==-1){
                added_class_user_seq_arr.push(user_info["a_seq"]);
              }
            }
          }
        }
      }
      class_info.row_user_arr=row_user_arr;
      if(class_info.row_user_arr.length>0){
        class_user_data_arr.push(class_info);
      }
    }
    //empty
    let empty_user_info_arr=[];
    for(let user_i=0;user_i<user_info_arr_len;user_i++){
      let user_info=myProps.user_info_arr[user_i];
      if(strFunc.str_in_array(user_info["a_seq"],added_class_user_seq_arr)==-1){
        empty_user_info_arr.push(user_info);
      }
    }
    if(empty_user_info_arr.length>0){
      class_user_data_arr.push({
        "a_seq":"empty",
        "a_name":"소속없음",
        row_user_arr:empty_user_info_arr
      });
    }

    return class_user_data_arr;
  };

  const get_user_tag_arr=()=>{
    let class_user_data_arr=get_class_user_data_arr();
    let class_tags=class_user_data_arr.map((class_info:any,idx:number)=>{
      let row_user_info_arr=class_info["row_user_arr"];
      return (
        <div key={idx}>
          <h4 className="text-center mt-2">
            {class_info.a_name}
          </h4>
          <UserListArea
            xColumnArr={myProps.xColumnArr}
            class_info={class_info}
            user_info_arr={row_user_info_arr}
            on_click_user_row={myProps.on_click_user_row}
            select_user_seq_arr={myProps.select_user_seq_arr}
          ></UserListArea>
        </div>
      );
    });
    return class_tags;
  };

  return (
    <div style={{height:"80vh",overflow:"scroll"}}>
      {get_user_tag_arr()}
    </div>
  );
};
export default ClassListArea;