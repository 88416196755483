import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';

const SectionArea = forwardRef((props:any, ref) => {
  const myProps={
    set_section_arr:(section_arr:any)=>{},
    ...props
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const init_data={
    "section_arr":[],
    "video_arr":[],
  };
  const default_row_data={
    "a_corse_seq":"",
    "a_seq":"1",
    "a_title":"",
    "a_order_num":"",
  };
  const [section_arr,set_section_arr]=useState<any>(init_data.section_arr);
  const [video_arr,set_video_arr]=useState(init_data.video_arr);
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      "section_arr":[],
      "video_arr":[],
      "isUpdate":false,
      ...inOptObj
    };
    set_section_arr(optObj.section_arr);
    set_video_arr(optObj.video_arr);
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(()=>{
    
  },[]);

  const onChangeRowData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      key:"",
      value:"",
      row_num:0,
      ...inOptObj
    };
    let value=optObj["value"];
    let key=optObj["key"];
    let row_num=optObj["row_num"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr[row_num][key]=value;

    set_section_arr(tmp_section_arr);
    myProps.set_section_arr(tmp_section_arr);
  };

  const addNewRow=()=>{
    let max_a_seq=0;
    if(section_arr.length>0){
      for(let i=0;i<section_arr.length;i++){
        let tmp_a_seq=parseInt(section_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }
      }
    }
    let addRow:any={...default_row_data};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr.push(addRow);
    set_section_arr(tmp_section_arr);
    myProps.set_section_arr(tmp_section_arr);
  };

  const removeRowData=(row_num:number)=>{
    let tmp_section_arr=[];
    for(let i=0;i<section_arr.length;i++){
      if(i!=row_num){
        tmp_section_arr.push(section_arr[i]);
      }
    }
    set_section_arr(tmp_section_arr);
    myProps.set_section_arr(tmp_section_arr);
  };

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_section_arr:any=[...section_arr];

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;

    tmp_section_arr=getReSetOrderNum(tmp_section_arr);

    set_section_arr(tmp_section_arr);
    myProps.set_section_arr(tmp_section_arr);
  };

  const getReSetOrderNum=(tmp_section_arr:any)=>{
    for(let i=0;i<tmp_section_arr.length;i++){
      tmp_section_arr[i].a_order_num=i+1;
    }
    return tmp_section_arr;
  };

  let sectionTags=section_arr.map((item:any,idx:number)=>{
    return (
      <tr key={idx}>
        <td className="text-center">
          <input type="text" 
            style={{width:"90%"}}
            value={item.a_title} onChange={(e:any)=>{
            onChangeRowData({
              key:"a_title",
              value:e.target.value,
              row_num:idx,
            });
          }} />
        </td>
        <td className="text-center">
          <button className="btn-s btn-gray" onClick={()=>{moveOrderNum(idx,"up");}}>▲</button>
          <button className="btn-s btn-gray ml-2" onClick={()=>{moveOrderNum(idx,"down");}}>▼</button>
          <button className="btn-s btn-red ml-2" onClick={()=>{removeRowData(idx);}}>X</button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="btn-box-left">
        <button className="btn btn-dark" onClick={()=>{addNewRow();}}>추가</button>
      </div>
      <div className="list-table-div mt-1">
        <table>
          <colgroup>
            <col width="*"></col>
            <col width="100px"></col>
          </colgroup>
          <thead>
            <tr>
              <th>제목</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {sectionTags}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default SectionArea;
