import { useState, useRef, useEffect } from 'react';

function TabArea(props:any){
  let myProps={
    tab_id:"student",
    set_tab_id:(inData:any)=>{},
    tab_data_arr:[
      {"value":"student","text":"학생선택","width":150},
      {"value":"content","text":"내용선택","width":150},
      {"value":"option","text":"맞춤설정","width":150},
      {"value":"write","text":"플랜확인","width":150},
    ],
    ...props
  };
  let active_tab=myProps.tab_id;


  let tab_data_arr=myProps.tab_data_arr;

  let active_pre_idx=-1;
  let active_after_idx=-1;
  let active_idx=-1;
  for(let i=0;i<tab_data_arr.length;i++){
    if(tab_data_arr[i].value==active_tab){
      active_idx=i;
      active_pre_idx=active_idx-1;
      active_after_idx=active_idx+1;
    }
  }

  let tab_tags=tab_data_arr.map((item:any,idx:number)=>{
    let item_class_name="tab_item";
    if(idx==0){
      item_class_name+=" first_tab_item";
    }
    if(item.value==active_tab){
      item_class_name+=" active";
    }
    let item_style:any={};
    if(idx==active_pre_idx){
      item_style["borderRight"]=0;
    }
    if(idx==active_after_idx){
      item_style["borderLeft"]=0;
    }
    return (
      <td key={idx}>
        <div className={item_class_name} style={item_style} onClick={()=>{
          myProps.set_tab_id(item.value);
        }}>
          STEP {idx+1}. {item.text}
        </div>
      </td>
    );
  });

  return (
    <div className="basic_tab_wrap2 mt-5">
      <table>
        <colgroup>
          {
          tab_data_arr.map((item:any,idx:number)=>{
            return (
              <col key={idx} width={item.width}></col>
            );
          })
          }
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            {tab_tags}
            <td>
              <div className="tab_item_empty">
                
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default TabArea;