//import AttendStateFunc from "@/pcomponents/common/content/edu/attend/func/state_func";
class AttendStateFunc
{
  static get_color_by_attend_info(inData:any){
    let opt_obj={
      attend_info:null,
      ...inData
    };
    let attend_info=opt_obj.attend_info;
    let state_data={"value":"","text":"미등원","background":"gray"};
    let attend_in_state_arr=[
      {"value":"attend","text":"등원","background":"#b8e9bc"},
      {"value":"late","text":"지각","background":"#FAF1E0"},
      {"value":"absent","text":"결석","background":"#FAD5CC"},
    ];
    if(attend_info["a_sort"]=="in"){
      for(let i=0;i<attend_in_state_arr.length;i++){
        let item=attend_in_state_arr[i];
        if(item["value"]==attend_info["a_sort_detail"]){
          state_data={...item};
        }
      }
    }else{
      state_data={"value":"","text":"하원","background":"#00f"};
    }
    return state_data;
  };
}
export default AttendStateFunc;