import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";
import CardListArea from "./area/list";
import WriteArea from "./area/write";
import ExcelDownComponent from "@/pcomponents/common/excel/down";
import GetEvalExcelData from "./area/excel/eval_excel_down";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import EvalFunc from "@/pcomponents/common/content/eval/func/eval_func";

function EvalCardListPage() {
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const xColumnArr = getXcolumnJson();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq:user.user_grade=="master"?"":strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
      s_addon_link_user: "1",

      s_addon_eval: "1",
      s_addon_eval_s_form_seq: "",
      s_addon_eval_s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_eval_s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_eval_detail: "1",
      s_addon_eval_form: "1",

      s_is_except_eval_user: "",
      s_except_user_seq: "",
    }
  });
  const CardListAreaRef = useRef<any>(null);
  const ExcelDownComponentRef=useRef<any>();
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [form_list, set_form_list] = useState<any>([]);
  const [refresh, set_refresh] = useState(false);

  const [write_data, set_write_data] = useState({
    is_show: false,
    is_update: false,
    row_data: {},
  });

  useEffect(() => {
    
    list({});
    get_eval_forms_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_eval_forms_by_ajax = () => {
    let s_form_json = {
      "s_addon_detail": "1",
      "s_is_use": "1",
      "order_id": "a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval_form/list', s_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_form_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const write_multy_eval = () => {
    //infoArr
    let eval_data_arr=[];
    for(let i=0;i<infoArr.length;i++){
      let info:any=infoArr[i];
      if(info.eval_arr&&info.eval_arr.length>0){
        let eval_info=info.eval_arr[0];
        if(eval_info.detail_arr){
          let tmp_total_data=EvalFunc.get_detail_total_data_by_detail_arr(eval_info.detail_arr);
          eval_info["a_average_score"]=tmp_total_data["average_score"];
          eval_info["a_item_amout"]=tmp_total_data["count"];
        }
        eval_data_arr.push(eval_info);
      }
    }

    if (eval_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let eval_w_form_json = {
      "data_arr": eval_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/add_with_detail', eval_w_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_write_eval = (inData: any) => {
    let opt_obj = {
      info: null,
      ...inData
    };

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let eval_w_form_json = {
      "data_arr": [opt_obj["info"]]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/write', eval_w_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          write_detail_by_ajax(response.data["data"][0], opt_obj["info"]["detail_arr"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const write_detail_by_ajax = (eval_info: any, detail_arr: any) => {
    let detail_form_json = {
      "detail_arr": detail_arr,
      "eval_seq": eval_info["a_ymd"] + "," + eval_info["a_seq"],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/evald/add_of_eval', detail_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_open_write_page = (is_update: boolean, selected_row: any[]) => {
    let w_opeon_data_json = {
      ...write_data,
      is_show: true,
      row_data: {},
      is_update: false,
    };
    if (is_update) {
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      } else if (selected_row.length != 1) {
        alert("한개만 선택해 주세요.");
        return false;
      }
      w_opeon_data_json.row_data = selected_row[0];
      w_opeon_data_json.is_update = true;
    }

    set_write_data(w_opeon_data_json);
  };

  const go_delete = (inData: any) => {
    let opt_obj = {
      "info_arr": [],
      ...inData
    };
    if (opt_obj["info_arr"].length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let del_form_json = {
      "data_arr": opt_obj["info_arr"]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제되었습니다.");
          list({ "now_page": "1" });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goExcelDown=()=>{
    if(ExcelDownComponentRef.current){
      let tmp_info_arr:any=[...infoArr];
      let excel_down_data_opt=GetEvalExcelData({
        infoArr:tmp_info_arr
      });
      ExcelDownComponentRef.current.setInitData(excel_down_data_opt);
      set_refresh(!refresh);
      setTimeout(()=>{
        ExcelDownComponentRef.current.goDownExcel();
      },100);
    }
  };

  return (
    <CompLayout isConTitle={false}>
      <div style={{ display: (write_data.is_show ? "none" : "block") }} >
        <SearchArea
          listOpt={pageData.listOpt}
          xColumnArr={xColumnArr}
          list={list}
        ></SearchArea>
        <div className="btn-box-right mt-2">
          <button className="btn-m btn-sky" onClick={()=>{navigate("/comp/edu/eval_from/list");}}>평가폼작성</button>
          <button className="btn-m btn-sky" onClick={()=>{write_multy_eval();}}>모두저장</button>
          <button className="btn-m btn-sky" onClick={()=>{goExcelDown();}} >엑셀다운</button>
        </div>
        <CardListArea
          ref={CardListAreaRef}
          info_arr={infoArr}
          s_form_seq={pageData.listOpt.s_addon_eval_s_form_seq}
          form_list={form_list}
          go_write_eval={go_write_eval}
          go_delete={(inData: any) => { go_delete(inData); }}
          setInfoArr={(inData: any) => {
            setInfoArr(inData);
            set_refresh(!refresh);
          }}
        ></CardListArea>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
      <ExcelDownComponent
        ref={ExcelDownComponentRef}
        xColumnArr={xColumnArr}
      ></ExcelDownComponent>
      {write_data.is_show &&
        <WriteArea
          list={list}
          is_update={write_data.is_update}
          row_data={write_data.row_data}
          close={() => { set_write_data({ ...write_data, is_show: false }); }}
        ></WriteArea>
      }
    </CompLayout>
  );
}

export default EvalCardListPage;