import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import CategoryListArea from "./area/cate_list";

function FindPrescribePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    row_num: 0,
    key_str: "",
    pri_val: "",
    callBackData: (inData: any) => {
      let opt_obj = {
        cate_info:null,
        info_arr: [],
        select_check_idx:0,
        select_cate_idx:0,
        row_num: 0,
        key_str: "",
        pri_val: "",
        ...inData,
      };
    },
    ...props
  };

  const [list_opt, set_list_opt] = useState({
    "s_addon_check": "1",
    "order_id": "a_order_num",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [select_cate_seq_arr,set_select_cate_seq_arr]=useState([]);

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let opt_obj={
      ...list_opt,
      ...inOptObj
    };
    set_list_opt(opt_obj);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/prescribe/prescribe_check_cate/list', opt_obj, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_select_check=(inData:any)=>{
    let opt_obj={
      cate_info:null,
      check_info_arr:[],
      select_check_idx:0,
      select_cate_idx:0,
      ...inData
    };
    myProps.callBackData({
      cate_info:opt_obj["cate_info"],
      info_arr: opt_obj["check_info_arr"],
      select_check_idx:opt_obj["select_check_idx"],
      select_cate_idx:opt_obj["select_cate_idx"],
      row_num: myProps.row_num,
      key_str: myProps.key_str,
      pri_val: myProps.pri_val,
    });
    myProps.closePopup();
  };


  return (
    <div>
      <CategoryListArea 
        infoArr={infoArr}
        select_cate_seq_arr={select_cate_seq_arr}
        set_select_cate_seq_arr={set_select_cate_seq_arr}
        on_select_check={on_select_check}></CategoryListArea>
    </div>
  );
};
export default FindPrescribePopup;