import strFunc from "@/lib/lyg/string";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function DetailRow(props: any) {
  let myProps = {
    row_num: 0,
    detail_info: {},
    set_detail_info: (inData: any) => { },
    remove_row: () => { },
    is_view_mode: false,
    ...props
  };
  let detail_info = myProps.detail_info;
  let is_view_mode = myProps.is_view_mode;

  let score_select_arr = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    if(name=="a_amount"||name=="a_score"){
      value=strFunc.uncomma(value);
    }

    myProps.set_detail_info({
      ...detail_info,
      ...{ [name]: value }
    });
  };

  let time_his_json = strFunc.secondsToTimeJson(detail_info.a_time_sec);
  let time_his_str = time_his_json.h + ":" + time_his_json.i + ":" + time_his_json.s;

  return (
    <tr>
      <td className="text-center">
        {myProps.row_num + 1}
      </td>
      <td className="text-center">
        {is_view_mode ?
          detail_info.a_study_title :
          <input type="text" className="row-input" name="a_study_title"
            value={detail_info.a_study_title} onChange={handleInputChange} placeholder="교재" />
        }
      </td>
      <td className="text-center">
        {is_view_mode ?
          detail_info.a_study_present :
          <input type="text" className="row-input" name="a_study_present"
            value={detail_info.a_study_present} onChange={handleInputChange} placeholder="현재" />
        }
      </td>
      <td className="text-center">
        {is_view_mode ?
          detail_info.a_amount + "" + detail_info.a_amount_unit :
          <div style={{ display: "flex" }}>
            <span style={{ flexGrow: "1" }}>
              <input type="text" className="row-input" name="a_amount"
                value={detail_info.a_amount} onChange={handleInputChange} placeholder="학습량" 
                style={{textAlign:"right"}} />
            </span>
            <span style={{ flexShrink: 1 }}>
              <input type="text" className="row-input" name="a_amount_unit"
                value={detail_info.a_amount_unit} onChange={handleInputChange} placeholder=""
                style={{ maxWidth: "40px", minWidth: 30 }} />
            </span>
          </div>
        }
      </td>
      <td className="text-center">
        {is_view_mode ? time_his_str :
          <TimeInputComponent
            name="a_time_sec"
            value={time_his_str}
            is_his={{ h: true, i: true, s: true }}
            on_change={(inData: any) => {
              let sec_value = strFunc.hisToSeconds(inData.value);
              handleInputChange({
                target: {
                  name: "a_time_sec",
                  value: sec_value,
                  type: "text",
                }
              });
            }}
            input_style={{ width: 20 }}
          ></TimeInputComponent>
        }
      </td>
      <td className="text-center">
        <SelectBoxBar
          valueTextArr={score_select_arr}
          value={detail_info.a_estimate_num}
          name={"a_estimate_num"}
          style_type={"small"}
          box_style={{minWidth:"12px"}}
          on_change={(inData: any) => {
            if (is_view_mode == false) {
              handleInputChange({
                target: {
                  name: "a_estimate_num",
                  value: inData.value,
                  type: "text",
                }
              });
            }
          }}
        ></SelectBoxBar>
      </td>
      <td className="text-center">
        {is_view_mode ?
          detail_info.a_score :
          <input type="text" className="row-input" name="a_score"
            value={detail_info.a_score} onChange={handleInputChange} placeholder="점수" 
            onClick={(e:any)=>{e.target.select();}}
            style={{textAlign:"right",width:35}} />
        }
        점
      </td>
      <td className="text-center">
        <button className="btn-s btn-red" onClick={myProps.remove_row}>X</button>
      </td>
    </tr>
  );
};
export default DetailRow;