import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';

function ViewTodoDayPopup(props:any) {
  let myProps={
    list_opt:{
      "s_stu_user_name":"",
      "s_stu_user_seq":"",
      "s_start_date":"",
      "s_end_date":"",
    },
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_stu_user_name":"",
      "s_stu_user_seq":"",
      "s_start_date":"",
      "s_end_date":"",
      "s_addon_user":"1",
      "s_is_mcomp_public":"1",
      "s_search_text_type":"name",
      "s_search_text":"",
      ...myProps.list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    let tmpPageData={...pageData};
    tmpPageData.listOpt[name]=value;
    setPageData({
      ...tmpPageData
    });
    if(name=="s_search_text"){
      return false;
    }
    list({[name]: value,"now_page":1});
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let tmpPageData={...pageData};
    tmpPageData.listOpt[key]=date_str;
    setPageData({
      ...tmpPageData
    });
    list({[key]: date_str,"now_page":1});
  };

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let list_form_json=pageData.listOpt;
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    list_form_json.s_stu_mcomp_seq=user.comp_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_stu_name"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_success_sort"){
      let sort_str=params.data.a_success_sort;
      if(xColumnArr.select_arr["a_success_sort"]){
        for(let i=0;i<xColumnArr.select_arr["a_success_sort"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_success_sort"][i];
          if(tmp_option_row["value"]==sort_str){
            sort_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {sort_str}
        </div>
      );
    }else if(key=="row_view_state"){
      let state_str=params.data.a_state;
      if(xColumnArr.select_arr["a_state"]){
        for(let i=0;i<xColumnArr.select_arr["a_state"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_state"][i];
          if(tmp_option_row["value"]==state_str){
            state_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {state_str}
        </div>
      );
    }else if(key=="row_view_per"){
      let per_str=0;
      if(params.data.a_success_sort=="time"){
        let a_time_sec=parseInt(params.data.a_time_sec);
        let a_success_time_sec=parseInt(params.data.a_success_time_sec);
        if(a_time_sec!=0&&a_success_time_sec!=0){
          per_str=Math.round((a_time_sec/a_success_time_sec)*100);
        }
      }else{
        let a_amount_num=parseInt(params.data.a_amount_num);
        let a_success_amount=parseInt(params.data.a_success_amount);
        if(a_amount_num!=0&&a_success_amount!=0){
          per_str=Math.round((a_amount_num/a_success_amount)*100);
        }
      }
      if(params.data.a_is_success=="1"){
        per_str=100;
      }
      let per_div_style:any={};
      if(per_str>0){
        per_div_style["color"]="#979705";
      }
      if(per_str>=100){
        per_div_style["color"]="blue";
      }
      render_str=(
        <div style={per_div_style}>
          {per_str} %
        </div>
      );
    }else if(key=="a_reserve_start_date"){
      render_str=render_str.substring(5,10);
    }
    return render_str;
  }, []);

  return (
    <div>
      <div className="search-box relative py-0" style={{lineHeight:"20px"}}>
        <select className="search-input_select" name="s_date_type" value={pageData.listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_create_date">작성일</option>
          <option value="a_reserve_start_date">예정일</option>
        </select>
        <div className="search_date_picker_wrap">
          <DatePicker 
            wrapperClassName="pl-1"
            selected={pageData.listOpt.s_start_date!=""?new Date(pageData.listOpt.s_start_date):null} 
            onChange={(date:Date) => {
              onChangeDatePicker("s_start_date",date);
            }}
            locale={ko} 
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
          ~
        <div className="search_date_picker_wrap">
          <DatePicker 
            wrapperClassName="pl-1"
            selected={pageData.listOpt.s_end_date!=""?new Date(pageData.listOpt.s_end_date):null} 
            onChange={(date:Date) => {
              onChangeDatePicker("s_end_date",date);
            }}
            locale={ko} 
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
      </div>
      <div className="search-box relative py-0 ml-0 mt-1">
        <select className="search-input_select" name="s_search_text_type" value={pageData.listOpt.s_search_text_type} onChange={handleInputChange} >
            <option value="name">이름</option>
            <option value="writer">작성자</option>
            <option value="title">제목</option>
          </select>
          <input type="text" className="search-input" name="s_search_text" 
            style={{width:120}} placeholder="검색어 입력" value={pageData.listOpt.s_search_text}
            onChange={handleInputChange} onKeyUp={(e:any)=>{if(e.key === 'Enter'){list({"now_page":1});}}} />
          <button className="btn btn-gray" onClick={()=>{list({"now_page":1});}} >검색</button>
          <span className="ml-2">
            {pageData.listOpt.s_stu_user_name}
            {!strFunc.is_empty(pageData.listOpt.s_stu_user_seq)&&
              <button className="btn btn-red" onClick={()=>{
                list({
                  "now_page":"1",
                  s_stu_user_name:"",
                  s_stu_user_seq:"",
                });
              }}>X</button>
            }
          </span>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:false,
          is_add_checkbox:false,
          floatingFilter:true,
          height:(window.innerHeight/6)*3,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot} 
        onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
    </div>
  );
}
export default ViewTodoDayPopup;