import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import TopDateArea from "./area/top_date";
import StuArea from "./area/stu_area";
import TodoArea from "./area/todo_area";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteTodoPopup from "@/pages/comp/plan/stu_todo_history/popup/write";

function ViewTodoDaySortPopup(props: any) {
  let myProps = {
    list_opt: {
      "s_stu_user_name": "",
      "s_stu_user_seq": "",
      "s_start_date": "",
      "s_end_date": "",
    },
    list: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [infoArr, setInfoArr] = useState<any>([]); // Set rowData to Array of Objects, one Object per Row
  const [stu_info, set_stu_info] = useState<any>({
    "a_seq": "",
    "a_user_name": myProps.list_opt["s_stu_user_name"],
    "a_school_name": "",
    "a_stu_grade": "",
  });
  const [list_opt, set_list_opt] = useState({
    "s_stu_user_name": "",
    "s_stu_user_seq": "",
    "s_start_date": "",
    "s_end_date": "",
    ...myProps.list_opt
  });
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    
    list({});
  }, []);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "todo_day_sort",
    rowData: {},
    isUpdate: false,
    select_stu_name: "",
    select_stu_seq: "",
    select_date: "",
    receive_arr: [],
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    user_info_arr: [],
    todo_info: {},
    todo_sort: "study",
    title: "맞춤플랜 보기",
    width: "500px",
    height: "100%",
  });

  const openTodoWriteOnePopup = (inData: any) => {
    let opt_obj = {
      select_date: list_opt.s_start_date,
      select_stu_name: "",
      select_stu_seq: "",
      todo_info: {},
      todo_sort: "study",
      isUpdate: true,
      ...inData
    };
    if (opt_obj["isUpdate"]) {
      if (strFunc.is_empty(opt_obj["todo_info"]["a_seq"])) {
        alert("내용 없음.");
        return false;
      }
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "todo_write_one",
      select_stu_name: opt_obj["select_stu_name"],
      select_stu_seq: opt_obj["select_stu_seq"],
      select_date: opt_obj["select_date"],
      todo_sort: opt_obj["todo_sort"],
      todo_info: opt_obj["todo_info"],
      isUpdate: opt_obj["isUpdate"],
      title: "맞춤플랜",
      ...opt_obj
    });
  };

  const list = (inData: any) => {
    let tmp_list_opt = {
      ...list_opt,
      ...inData,
    };
    set_list_opt(tmp_list_opt);
    let list_form_json: any = {
      "s_addon_user": "1",
      "s_is_mcomp_public": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "order_id": "a_stu_name",
      "s_reserve_start_date": tmp_list_opt["s_start_date"],
      "s_reserve_end_date": tmp_list_opt["s_start_date"],
      "s_stu_user_seq": tmp_list_opt.s_stu_user_seq,
      "s_addon_par_content": "1",
      "s_addon_elapse_time": "1",
      ...tmp_list_opt,
    };

    list_form_json.s_stu_mcomp_seq = user.comp_seq;

    if (strFunc.is_empty(list_form_json.s_stu_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(list_form_json.s_start_date)) {
      return false;
    }
    if (strFunc.is_empty(list_form_json.s_stu_mcomp_seq)) {
      return false;
    }
    get_stu_info_by_ajax(tmp_list_opt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {
          alert("검색 중 오류.");
          props.closePopup();
        }
      });
  };

  const get_stu_info_by_ajax = (tmp_list_opt: any) => {
    let list_form_json = {
      "s_pri_arr": [tmp_list_opt["s_stu_user_seq"]],
      "s_addon_home_work_file": "1",
      "s_addon_home_work_file_start_date": tmp_list_opt["s_start_date"],
      "s_addon_home_work_file_end_date": tmp_list_opt["s_start_date"],
      s_addon_plan_study_file: "1",
      s_addon_plan_study_file_start_date: tmp_list_opt["s_start_date"],
      s_addon_plan_study_file_end_date: tmp_list_opt["s_start_date"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(tmp_list_opt["s_stu_user_seq"])) {
      return false;
    }
    if (strFunc.is_empty(list_form_json["s_pri_arr"])) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_stu_info(response.data["data"]["info_arr"][0]);
          } else {
            alert("학생 검색결과 없음.");
            props.closePopup();
          }
        } else {
          alert("학생 검색 중 오류.");
          props.closePopup();
        }
      });
  };

  const on_change_todo = (inData: any) => {
    let opt_obj = {
      "value": "",
      "name": "",
      "todo_pri_val": "",
      ...inData
    };
    let name = opt_obj["name"];
    let value = opt_obj["value"];

    let tmp_info_arr = [...infoArr];
    let info_arr_len = tmp_info_arr.length;
    for (let i = 0; i < info_arr_len; i++) {
      let info = tmp_info_arr[i];
      let row_pri_val = info["a_ymd"] + "," + info["a_seq"];
      if (row_pri_val == opt_obj["todo_pri_val"]) {
        if (name == "a_time_sec" || name == "a_amount_num") {
          value = strFunc.uncomma(value);
        }

        tmp_info_arr[i][name] = value;

        //상태검사
        let row_per: any = TodoStateFunc.get_percent_by_todo_info(info);
        //a_state 상태(ready:준비,ing:하는중,stop:중지,complete:성공)
        let change_state = "";
        let change_is_success = "";
        if (row_per == 0) {
          change_state = "ready";
        } else if (row_per > 0) {
          change_state = "stop";
        }
        if (tmp_info_arr[i]["a_state"] == "stop") {
          change_state = "stop";
        }
        if (row_per >= 100) {
          change_state = "complete";
          change_is_success = "1";
        }

        tmp_info_arr[i]["a_state"] = change_state;
        tmp_info_arr[i]["a_is_success"] = change_is_success;
      }
    }
    setInfoArr(tmp_info_arr);
  };

  const on_delete_todo = (inData: any) => {
    let opt_obj = {
      todo_info: null,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["todo_info"])) {
      alert("데이터가 없습니다.");
      return false;
    }
    if (!confirm("해당 플랜을 삭제 하시겠습니까?")) {
      return false;
    }

    let del_form_json = {
      "data_arr": [opt_obj["todo_info"]]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.list({});
          list({});
        } else {
          alert(response.data["msg"]);
          props.closePopup();
        }
      });

  };

  const save_todo_arr_by_ajax = () => {
    if (infoArr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let save_form_json = {
      "is_update": "1",
      "data_arr": infoArr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', save_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          list({});
          myProps.list({});
          // props.closePopup();
        } else {
          alert("저장 중 오류." + response.data["msg"]);
        }
      });
  };

  const get_td_sort_data_arr = () => {
    let td_sort_data_json: any = {
      "study": {
        "sort": "study",
        "title": "학습",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "homework": {
        "sort": "homework",
        "title": "숙제",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "video": {
        "sort": "video",
        "title": "강의",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "etc": {
        "sort": "etc",
        "title": "기타",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      }
    };

    let todo_info_arr_len = infoArr.length;
    for (let i = 0; i < todo_info_arr_len; i++) {
      let todo_info = infoArr[i];
      for (let key in td_sort_data_json) {
        if (todo_info["a_sort"] == key) {
          td_sort_data_json[key]["count"]++;
          let row_per: any = TodoStateFunc.get_percent_by_todo_info(todo_info);
          if (todo_info["a_is_success"] == "1") {
            row_per = 100;
          }
          td_sort_data_json[key]["per_arr"].push(row_per);
          todo_info["percent"] = row_per;
          td_sort_data_json[key]["todo_info_arr"].push(todo_info);
        }
      }
    }
    //평균얻기
    for (let key in td_sort_data_json) {
      let row_sum_per: any = 0;
      let per_arr_len: any = td_sort_data_json[key]["per_arr"].length;
      for (let i = 0; i < per_arr_len; i++) {
        row_sum_per += td_sort_data_json[key]["per_arr"][i];
      }
      if (row_sum_per != 0 && per_arr_len != 0) {
        td_sort_data_json[key]["percent"] = Math.floor(row_sum_per / per_arr_len);
      }
    }

    return td_sort_data_json;
  };

  let td_sort_data_json = get_td_sort_data_arr();
  let td_sort_data_arr: any = [];
  for (let key in td_sort_data_json) {
    if (key == "homework" || key == "study") {
      td_sort_data_arr.push(td_sort_data_json[key]);
    } else {
      if (td_sort_data_json[key]["count"] > 0) {
        td_sort_data_arr.push(td_sort_data_json[key]);
      }
    }

  }

  return (
    <div style={{ marginBottom: 70 }}>
      <TopDateArea
        Style={Style}
        list_opt={list_opt}
        list={list}
      ></TopDateArea>
      <StuArea
        style={Style}
        stu_info={stu_info}
        td_sort_data_arr={td_sort_data_arr}
      ></StuArea>
      <TodoArea
        style={Style}
        stu_info={stu_info}
        select_date={list_opt.s_start_date}
        callback={() => {
          list({});
          myProps.list({});
        }}
        td_sort_data_arr={td_sort_data_arr}
        on_change_todo={on_change_todo}
        on_delete_todo={on_delete_todo}
        openTodoWriteOnePopup={(inData: any) => {
          let opt_obj = {
            todo_info: {},
            isUpdate: true,
            todo_sort: "study",
            ...inData
          };
          let popup_opt_data = {
            select_date: list_opt.s_start_date,
            select_stu_name: stu_info.a_user_name,
            select_stu_seq: stu_info.a_seq,
            todo_info: opt_obj.todo_info,
            todo_sort: opt_obj["todo_sort"],
            isUpdate: opt_obj.isUpdate,
          };
          openTodoWriteOnePopup(popup_opt_data);
        }}
      ></TodoArea>
      <div className={Style.bottom_btn_area} >
        <button className="btn-l2 btn-yellowish2"
          style={{ width: 130, fontSize: "14px" }}
          onClick={() => { save_todo_arr_by_ajax(); }}>저장</button>
        <button className="btn-l2 btn-line-gray ml-4"
          style={{ width: 130, fontSize: "14px" }}
          onClick={() => { props.closePopup(); }}>닫기</button>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={"0"}>
          {popupData.sort == "todo_write_one" &&
            <WriteTodoPopup
              stu_seq={popupData.select_stu_seq}
              stu_name={popupData.select_stu_name}
              select_date={popupData.select_date}
              rowData={popupData.todo_info}
              todo_sort={popupData.todo_sort}
              isUpdate={popupData.isUpdate}
              callback={(inData: any) => {
                list({});
                myProps.list({});
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteTodoPopup>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default ViewTodoDaySortPopup;
