import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

function ModifyClassOfStuOnePopup(props:any){
   let myProps={
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_info:{},
    class_arr:[],
    closePopup: () => {},
    callback:(inData:any)=>{},
    ...props
  };
  let select_mcomp_data=myProps.select_mcomp_data;
  const [stu_info,set_stu_info]=useState<any>(myProps.stu_info);
  const [class_info_arr,set_class_info_arr]=useState<any>([]);
  const [class_user_arr,set_class_user_arr]=useState<any>([]);
  const [refresh,set_refresh]=useState(false);
  let default_class_user_row={
    a_class_seq:"",
    a_user_seq:"",
    a_user_name:"",
    a_type:"student",
    a_is_main:"",
    a_supply_discount:"",
    a_supply_discount_memo:"",
    class_name:"",
    class_info:{},
  };

  useEffect(()=>{
    get_stu_info_by_ajax();
    getClassList();
  },[select_mcomp_data.mcomp_seq]);

  const get_stu_info_by_ajax=()=>{
    if(strFunc.is_empty(stu_info.a_seq)){
      set_class_user_arr(myProps.class_arr);
      return false;
    }

    let user_list_opt:any={
      "s_pri_arr":[stu_info.a_seq],
      s_addon_class_user: "1",
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        user_list_opt["api_key"]=select_mcomp_data.api_key;
        user_list_opt["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',user_list_opt,get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        if(response.data["data"]["info_arr"].length>0){
          let tmp_stu_info=response.data["data"]["info_arr"][0];
          set_stu_info(tmp_stu_info);
          if(tmp_stu_info.class_arr){
            set_class_user_arr(tmp_stu_info.class_arr);
          }else{
            set_class_user_arr([]);
          }
        }else{
          alert("학생 정보가 없습니다.");
          myProps.closePopup();
        }
      } else {
        alert(response.data["msg"]);
        myProps.closePopup();
      }
    });
  };

  const getClassList=()=>{
    let uListFormData:any={
      "order_id":"a_name",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        uListFormData["api_key"]=select_mcomp_data.api_key;
        uListFormData["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/list',uListFormData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_class_info_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goWriteClassUser=()=>{
    if(strFunc.is_empty(stu_info["a_seq"])){
      myProps.callback({
        class_arr:class_user_arr
      });
      myProps.closePopup();
      return false;
    }
    if(!confirm("저장 하시겠습니까?")){
      return false;
    }

    //클래스키 넣기
    let tmp_class_user_arr=class_user_arr;
    for(let i=0;i<class_user_arr.length;i++){
      tmp_class_user_arr[i].a_user_seq=stu_info["a_seq"];
      tmp_class_user_arr[i].a_user_name=stu_info["a_user_name"];
    }
    let formJsonData:any={
      "user_seq":stu_info["a_seq"],
      "class_user_arr":tmp_class_user_arr,
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        formJsonData["api_key"]=select_mcomp_data.api_key;
        formJsonData["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/classu/write_of_user',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.callback({
          class_arr:tmp_class_user_arr
        });
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const on_row_select=(inData:any)=>{
    let opt_obj={
      "is_add":false,
      "class_info":{},
      ...inData
    };
    
    let class_info=opt_obj["class_info"];
    //추가
    if(opt_obj["is_add"]){
      let tmp_class_user_arr=class_user_arr;
      tmp_class_user_arr.push({
        ...default_class_user_row,
        a_class_seq:class_info["a_seq"],
        a_user_seq:stu_info["a_seq"],
        a_user_name:stu_info["a_user_name"],
        class_name:class_info["a_name"],
        class_info:class_info,
      });
      set_class_user_arr(tmp_class_user_arr);
    }else{
      //제거
      let tmp_class_user_arr=[];
      for(let i=0;i<class_user_arr.length;i++){
        if(class_user_arr[i]["a_class_seq"]==class_info["a_seq"]){

        }else{
          tmp_class_user_arr.push(class_user_arr[i]);
        }
      }
      set_class_user_arr(tmp_class_user_arr);
    }
    set_refresh(!refresh);
  };

  const get_class_check_data_arr=()=>{
    let class_check_data_arr=[];
    for(let i=0;i<class_info_arr.length;i++){
      let class_info=class_info_arr[i];
      let is_check=false;
      let class_u_info=null;
      for(let j=0;j<class_user_arr.length;j++){
        if(class_user_arr[j]["a_class_seq"]==class_info["a_seq"]){
          is_check=true;
          class_u_info=class_user_arr[j];
        }
      }
      class_check_data_arr.push({
        "title":class_info["a_name"],
        "is_check":is_check,
        "class_info":class_info,
        "class_u_info":class_u_info,
      });
    }
    return class_check_data_arr;
  };

  const get_class_check_tags=()=>{
    let class_check_data_arr=get_class_check_data_arr();
    let class_check_tags=class_check_data_arr.map((item:any,idx:number)=>{
      let item_class_name=Style.check_item;
      let is_add=true;
      if(item.is_check){
        item_class_name+=" "+Style.active;
        is_add=false;
      }
      return (
        <div key={idx} className={item_class_name} onClick={()=>{
          on_row_select({
            "is_add":is_add,
            "class_info":item.class_info
          });
        }}>
          <input type="checkbox" checked={item.is_check} onChange={()=>{}} />
          <span className={Style.item_title_span} >
            {item.title}
          </span>
        </div>
      );
    });
    return class_check_tags;
  };
  
  return (
  <div>
    <div className={Style.title_wrap}>
      <span style={{color:"#00f",fontSize:18}}>{stu_info.a_user_name}</span> 님의 클래스 관리
    </div>
    <div className={Style.check_list_wrap}>
      {get_class_check_tags()}
    </div>
    <div className="btn-box-center mt-2">
      <button className="btn-m btn-dark" onClick={()=>{goWriteClassUser();}}>저장</button>
      <button className="btn-m btn-dark" onClick={()=>{myProps.closePopup();}}>닫기</button>
    </div>
  </div>
  );
};
export default ModifyClassOfStuOnePopup;