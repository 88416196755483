import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Style from "./css/style.module.css";
import StuListArea from "./area/stu_list";
import TodoContetnArea from "./area/todo_content";
import MakeOptionArea from  "./area/make_option";
import TodoWriteListArea from  "./area/todo_write_list";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import MakeTodoWriteFunc from "./func/make_todo_write";

function PlanMakeTodo(){
  let user=useSelector((state:any) => state.user);
  const StuListAreaRef=useRef<any>(null);
  const TodoContetnAreaRef=useRef<any>(null);
  const MakeOptionAreaRef=useRef<any>(null);
  const TodoWriteListAreaRef=useRef<any>(null);
  useEffect(()=>{
    
  },[]);

  const make_todo_write_plan=()=>{
    let todo_content_arr=TodoContetnAreaRef.current.get_todo_content_arr();
    let make_option=MakeOptionAreaRef.current.get_make_option();

    if(todo_content_arr.length==0){
      alert("내용 선택이 필요합니다.");
      return false;
    }
    if(strFunc.is_empty(make_option.one_day_amount)||strFunc.is_empty(make_option.one_day_amount_time)){
      alert("하루분량이 필요합니다.");
      return false;
    }

    let todo_write_arr=MakeTodoWriteFunc.get_make_todo_write({
      todo_content_arr:todo_content_arr,
      make_option:make_option
    });
    
    TodoWriteListAreaRef.current.setInitData({
      todo_write_arr:todo_write_arr
    });

  };

  const make_todo_by_pan_write=()=>{
    let stu_info_arr=StuListAreaRef.current.get_stu_arr();
    let todo_write_arr=TodoWriteListAreaRef.current.get_todo_write_arr();

    if(strFunc.is_empty(user.comp_seq)){
      alert("로그인이 필요합니다.");
      return false;
    }
    if(stu_info_arr.length==0){
      alert("학생 선택이 필요합니다.");
      return false;
    }
    if(todo_write_arr.length==0){
      alert("스케줄 작성내용이 없습니다.");
      return false;
    }

    if(!confirm("스케줄을 등록 하시겠습니까?")){
      return false;
    }
    let stu_seq_arr=[];
    for(let i=0;i<stu_info_arr.length;i++){
      stu_seq_arr.push(stu_info_arr[i]["a_seq"]);
    }

    let formJsonData={
      "m_comp_seq":user.comp_seq,
      "stu_seq_arr":stu_seq_arr,
      "todo_write_arr":todo_write_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/make_by_custom_and_user',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("등록되었습니다.");
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <div className={Style.wrap}>
        <div className={Style.box}>
          <StuListArea
            ref={StuListAreaRef}
          ></StuListArea>
        </div>
        <div className={Style.box}>
          <TodoContetnArea
            ref={TodoContetnAreaRef}
          ></TodoContetnArea>
        </div>
        <div className={Style.box}>
          <MakeOptionArea
            ref={MakeOptionAreaRef}
            get_todo_content_arr={()=>{return TodoContetnAreaRef.current.get_todo_content_arr();}}
            make_todo_write_plan={make_todo_write_plan}
          ></MakeOptionArea>
        </div>
        <div className={Style.box}>
          <TodoWriteListArea
            ref={TodoWriteListAreaRef}
            make_todo_by_pan_write={make_todo_by_pan_write}
          ></TodoWriteListArea>
        </div>
      </div>
    </CompLayout>
  );
}

export default PlanMakeTodo;