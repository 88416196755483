import strFunc from "@/lib/lyg/string";
import DefaultTableData from "../data/default_data";
import CollisionFunc from "../collision/collision_func";

class TdDataFunc {
  static get_table_option_by_lecture_info = (inData: any) => {
    let opt_obj = {
      lecture_info: null,
      table_option: {},
      ...inData
    };
    let lecture_info = opt_obj["lecture_info"];
    let table_option = opt_obj["table_option"];

    //시간표로 기본 추가
    if (lecture_info.time_arr && lecture_info.time_arr.length > 0) {
      for (let i = 0; i < lecture_info.time_arr.length; i++) {
        let time_info = lecture_info.time_arr[i];

        //최소시간, 최대시간 구하기
        let row_start_time_m = parseInt(strFunc.uncomma(time_info["a_start_h"])) * 60;
        row_start_time_m += parseInt(strFunc.uncomma(time_info["a_start_m"]));

        let row_end_time_m = parseInt(strFunc.uncomma(time_info["a_end_h"])) * 60;
        row_end_time_m += parseInt(strFunc.uncomma(time_info["a_end_m"]));

        if (row_start_time_m < table_option["start_time_m"]) {
          table_option["start_time_m"] = row_start_time_m;
        }
        if (row_end_time_m > table_option["end_time_m"]) {
          table_option["end_time_m"] = row_end_time_m;
        }

        //hm얻기
        let row_start_hm = strFunc.HourMinuteToTime(time_info["a_start_h"], time_info["a_start_m"]);
        let row_end_hm = strFunc.HourMinuteToTime(time_info["a_end_h"], time_info["a_end_m"]);
        let row_time_str = row_start_hm + "~" + row_end_hm;

        let tmp_default_td_data_row = DefaultTableData.default_td_data_row;
        table_option["td_data_arr"].push({
          ...tmp_default_td_data_row,
          lecture_seq: lecture_info["a_seq"],
          name: time_info["a_name"],
          time_str: row_time_str,
          start_time_m: row_start_time_m,
          end_time_m: row_end_time_m,
          start_h: time_info["a_start_h"],
          start_m: time_info["a_start_m"],
          end_h: time_info["a_end_h"],
          end_m: time_info["a_end_m"],
          room_seq: time_info["a_room_seq"],
          teacher_seq: time_info["a_teacher_seq"],
          day_num: time_info["a_day_num"] + "",
          row_span: 1,
          col_span: 1,
          stu_union_time_arr: [],
        });
      }
    }

    //시간표 학생으로 추가
    if (lecture_info.stu_union_time_arr && lecture_info.stu_union_time_arr.length > 0) {
      for (let i = 0; i < lecture_info.stu_union_time_arr.length; i++) {
        let stu_union_time = lecture_info.stu_union_time_arr[i];

        //최소시간, 최대시간 구하기
        let row_start_time_m = parseInt(strFunc.uncomma(stu_union_time["a_start_h"])) * 60;
        row_start_time_m += parseInt(strFunc.uncomma(stu_union_time["a_start_m"]));

        let row_end_time_m = parseInt(strFunc.uncomma(stu_union_time["a_end_h"])) * 60;
        row_end_time_m += parseInt(strFunc.uncomma(stu_union_time["a_end_m"]));

        if (row_start_time_m < table_option["start_time_m"]) {
          table_option["start_time_m"] = row_start_time_m;
        }
        if (row_end_time_m > table_option["end_time_m"]) {
          table_option["end_time_m"] = row_end_time_m;
        }

        //hm얻기
        let row_start_hm = strFunc.HourMinuteToTime(stu_union_time["a_start_h"], stu_union_time["a_start_m"]);
        let row_end_hm = strFunc.HourMinuteToTime(stu_union_time["a_end_h"], stu_union_time["a_end_m"]);
        let row_time_str = row_start_hm + "~" + row_end_hm;

        //td 데이터 얻기
        let is_exist_td_data = false;
        for (let j = 0; j < table_option["td_data_arr"].length; j++) {
          let td_data_info = table_option["td_data_arr"][j];
          let stu_a_day_num = stu_union_time["a_day_num"] + "";
          if (td_data_info["lecture_seq"] == stu_union_time["a_lecture_seq"]
            && td_data_info["time_str"] == row_time_str
            && td_data_info["day_num"] == stu_a_day_num
            && td_data_info["room_seq"] == stu_union_time["a_room_seq"]
            && td_data_info["teacher_seq"] == stu_union_time["a_teacher_seq"]) {
            table_option["td_data_arr"][j]["stu_union_time_arr"].push(stu_union_time);
            is_exist_td_data = true;
          }
        }

        let tmp_default_td_data_row = DefaultTableData.default_td_data_row;
        if (is_exist_td_data == false) {
          table_option["td_data_arr"].push({
            ...tmp_default_td_data_row,
            lecture_seq: stu_union_time["a_lecture_seq"],
            name: stu_union_time["a_name"],
            time_str: row_time_str,
            start_time_m: row_start_time_m,
            end_time_m: row_end_time_m,
            start_h: stu_union_time["a_start_h"],
            start_m: stu_union_time["a_start_m"],
            end_h: stu_union_time["a_end_h"],
            end_m: stu_union_time["a_end_m"],
            room_seq: stu_union_time["a_room_seq"],
            teacher_seq: stu_union_time["a_teacher_seq"],
            day_num: stu_union_time["a_day_num"] + "",
            row_span: 1,
            col_span: 1,
            stu_union_time_arr: [stu_union_time],
          });
        }
      }
    }

    //범위정보 얻기
    let range_row_json_arr: any = {};//{start_end:{"start":0,"end":0}}
    for (let td_i = 0; td_i < table_option["td_data_arr"].length; td_i++) {
      let row_td_data = table_option["td_data_arr"][td_i];
      if (row_td_data["lecture_seq"] != lecture_info["a_seq"]) {
        continue;
      }
      let range_row: any = {
        start: row_td_data["start_time_m"],
        end: row_td_data["end_time_m"],
      };

      //있는지검사
      let is_exist_range = false;
      for (let key in range_row_json_arr) {
        let ab_line_data = {
          a_start: range_row_json_arr[key]["start"],
          a_end: range_row_json_arr[key]["end"],
          b_start: range_row["start"],
          b_end: range_row["end"],
        };
        let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
        //붙어있으면 무효
        if (row_is_exist_range) {
          is_exist_range = row_is_exist_range;
        }
      }

      if (is_exist_range == false) {
        //다른td돌리면서 범위추가
        for (let i = 0; i < table_option["td_data_arr"].length; i++) {
          let row_td_data2 = table_option["td_data_arr"][i];
          if (row_td_data2["lecture_seq"] == lecture_info["a_seq"]) {
            let ab_line_data = {
              a_start: row_td_data2["start_time_m"],
              a_end: row_td_data2["end_time_m"],
              b_start: range_row["start"],
              b_end: range_row["end"],
            };
            let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
            if (row_is_exist_range) {
              if (range_row["start"] > row_td_data2["start_time_m"]) {
                range_row["start"] = row_td_data2["start_time_m"];
              }
              if (range_row["end"] < row_td_data2["end_time_m"]) {
                range_row["end"] = row_td_data2["end_time_m"];
              }
            }
          }
        }

        //등록
        let range_key = range_row["start"] + "~" + range_row["end"];
        range_row_json_arr[range_key] = range_row;
      }
    }
    //범위정보 추가
    for (let td_i = 0; td_i < table_option["td_data_arr"].length; td_i++) {
      let row_td_data = table_option["td_data_arr"][td_i];
      if (row_td_data["lecture_seq"] != lecture_info["a_seq"]) {
        continue;
      }
      for (let key in range_row_json_arr) {
        let range_row = range_row_json_arr[key];
        let ab_line_data = {
          a_start: range_row["start"],
          a_end: range_row["end"],
          b_start: row_td_data["start_time_m"],
          b_end: row_td_data["end_time_m"],
        };
        let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
        if (row_is_exist_range) {
          table_option["td_data_arr"][td_i]["range_start_time_m"] = range_row["start"];
          table_option["td_data_arr"][td_i]["range_end_time_m"] = range_row["end"];
          table_option["td_data_arr"][td_i]["range_time_str"] = key;
        }
      }
    }
    // for(let key in range_row_json_arr){
    //   let range_row=range_row_json_arr[key];
    //   let start_json=strFunc.secondsToTimeJson(range_row["start"]*60);
    //   let end_json=strFunc.secondsToTimeJson(range_row["end"]*60);
    //   console.log(start_json.h+":"+start_json.i+"~"+end_json.h+":"+end_json.i);
    // }

    return table_option;
  };
  static get_day_of_week_group_of_td_data_arr = (td_data_arr: any[]) => {
    let group_day_td_data_arr: any = {};
    //요일별 그룹화
    for (let td_i = 0; td_i < td_data_arr.length; td_i++) {
      let td_data = td_data_arr[td_i];

      let stu_seq_arr = [];
      for (let i = 0; i < td_data["stu_union_time_arr"].length; i++) {
        stu_seq_arr.push(td_data["stu_union_time_arr"][i]["a_stu_seq"]);
      }
      stu_seq_arr.sort();
      let stu_seq_arr_str = stu_seq_arr.join(",");

      let a_day_num = td_data["day_num"] + "";
      let tmp_group_key_arr = [
        td_data["lecture_seq"],
        td_data["time_str"],
        //a_day_num,
        td_data["room_seq"],
        td_data["teacher_seq"],
        stu_seq_arr_str
      ];
      let tmp_group_key = tmp_group_key_arr.join(",");
      if (group_day_td_data_arr[tmp_group_key] == undefined) {
        group_day_td_data_arr[tmp_group_key] = {
          td_data_arr: [],
          day_num_arr: [],
        };
      }
      group_day_td_data_arr[tmp_group_key]["td_data_arr"].push(td_data);
      //day_num 없으면 넣기
      if (strFunc.str_in_array(a_day_num, group_day_td_data_arr[tmp_group_key]["day_num_arr"]) == -1) {
        group_day_td_data_arr[tmp_group_key]["day_num_arr"].push(a_day_num);
      }
    }

    //그룹된 요일별로 놓기
    for (let key in group_day_td_data_arr) {
      if (group_day_td_data_arr[key]["day_num_arr"]) {
        group_day_td_data_arr[key]["day_num_arr"].sort();
      }
    }

    let new_td_data_arr: any = [];
    let day_group_td_data_arr: any = {};
    for (let key in group_day_td_data_arr) {
      let group_day_td_data = group_day_td_data_arr[key];

      let row_day_num_arr = group_day_td_data["day_num_arr"];
      let row_day_num_arr_str = row_day_num_arr.join(",");

      for (let i = 0; i < group_day_td_data["td_data_arr"].length; i++) {
        group_day_td_data["td_data_arr"][i]["day_num_arr"] = row_day_num_arr;
        group_day_td_data["td_data_arr"][i]["day_num_arr_str"] = row_day_num_arr_str;
      }

      if (day_group_td_data_arr[row_day_num_arr_str] == undefined) {
        day_group_td_data_arr[row_day_num_arr_str] = [];
      }

      let added_key_arr: any = [];
      for (let i = 0; i < group_day_td_data["td_data_arr"].length; i++) {
        let td_data = group_day_td_data["td_data_arr"][i];
        if (strFunc.str_in_array(td_data["day_num_arr_str"], added_key_arr) == -1) {
          day_group_td_data_arr[row_day_num_arr_str].push(td_data);
          added_key_arr.push(td_data["day_num_arr_str"]);
        }
      }
    }

    //새로넣기
    for (let key in day_group_td_data_arr) {
      for (let i = 0; i < day_group_td_data_arr[key].length; i++) {
        new_td_data_arr.push(day_group_td_data_arr[key][i]);
      }
    }

    return new_td_data_arr;
  };
  static get_ordered_stu_of_td_data_arr = (inData: any) => {
    let opt_obj = {
      td_data_arr: [],
      ...inData
    };
    let td_data_arr = opt_obj.td_data_arr;

    for (let td_i = 0; td_i < td_data_arr.length; td_i++) {
      let td_data = td_data_arr[td_i];
      if (td_data.stu_union_time_arr) {
        let tmp_grade_name_key_arr = [];
        for (let i = 0; i < td_data.stu_union_time_arr.length; i++) {
          let stu_union_time = td_data.stu_union_time_arr[i];
          let name_key = stu_union_time["a_stu_grade"] + "," + stu_union_time["a_stu_name"];
          tmp_grade_name_key_arr.push(name_key);
        }
        tmp_grade_name_key_arr.sort();
        //정렬 후 다시 등록
        let new_stu_union_time_arr = [];
        for (let name_i = 0; name_i < tmp_grade_name_key_arr.length; name_i++) {
          let tmp_key = tmp_grade_name_key_arr[name_i];
          for (let i = 0; i < td_data.stu_union_time_arr.length; i++) {
            let stu_union_time = td_data.stu_union_time_arr[i];
            let name_key = stu_union_time["a_stu_grade"] + "," + stu_union_time["a_stu_name"];
            if (name_key == tmp_key) {
              new_stu_union_time_arr.push(stu_union_time);
            }
          }
        }
        td_data_arr[td_i].stu_union_time_arr = new_stu_union_time_arr;
      }
    }

    return td_data_arr;
  };
}
export default TdDataFunc;