import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function ViewOneWeekOfTeacher() {

  const [listOpt, setListOpt] = useState({
    "order_id": "a_order_num",
    "s_lecture_seq": "",
    "s_state": "ing",
    "s_teacher_like": "",
    "s_teacher_seq": "",
    "s_room_seq": "",
    "s_name_like": "",
    "s_addon_time": "1",
    "s_addon_user": "1",
    "s_addon_user_time": "1",
    "s_addon_user_s_state": "ing",
    "s_addon_time_union": "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [infoArr, setInfoArr] = useState([]);

  useEffect(() => {

    //list({});
  }, []);

  const list = (inOptObj: any) => {
    let change_list_opt={
      ...listOpt,
      ...inOptObj,
    };
    setListOpt(change_list_opt);
    let list_form_json_data = change_list_opt;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list_time_table_data',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          console.log(response.data["data"]);
        } else {

        }
      });
  };

  return (
    <CompLayout isConTitle={false}>
      준비중..
    </CompLayout>
  );
}

export default ViewOneWeekOfTeacher;