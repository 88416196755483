import Style from "../../css/style.module.css";

const TopSearchTabArea=(props:any)=>{
  let myProps={
    select_tab:"",
    set_select_tab:(inData:any)=>{},
    ...props
  };

  let tab_data_arr=[
    {"name":"클래스","key":"class"},
    {"name":"원생","key":"stu"},
  ];

  const get_tab_tags=()=>{
    return tab_data_arr.map((item:any,idx:number)=>{
      let tab_box_class_name=Style.tab_box;
      if(item.key==myProps.select_tab){
        tab_box_class_name+=" "+Style.active;
      }
      return (
        <div key={idx} className={tab_box_class_name} onClick={()=>{myProps.set_select_tab(item.key);}} >
          {item.name}
        </div>
      );
    });
  };

  return (
    <div className={Style.left_tab_wrap}>
       {get_tab_tags()}
    </div>
  );
};
export default TopSearchTabArea;