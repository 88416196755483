//import axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import axios from 'axios';

export const setAxiosDefaultHeader=()=>{
  //axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Authorization'] = localStorage.intoyou_token;
  axios.defaults.withCredentials=true;
};

export const get_axios_data=()=>{
  return {
    headers:{
      Authorization:localStorage.intoyou_token
    },
    withCredentials:true,
  };
};

export default axios;