import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"month_prescribe",
    list_id:"month_prescribe_excel_list",
    x_column_list_arr:[
      {"key":"a_subject_name","name":"과목","width":"70","is_show":"1"},
      {"key":"a_stu_name","name":"원생명","width":"100","is_show":"1"},
      {"key":"a_writer_name","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_reserve_date","name":"날짜","width":"120","is_show":"1"},
      {"key":"a_is_success","name":"처방","width":"120","is_show":"1"},
      {"key":"a_success_writer_name","name":"처방자","width":"100","is_show":"1"},
      {"key":"a_is_success_check","name":"처방확인","width":"100","is_show":"1"},
      {"key":"a_is_request_counsel","name":"상담요청","width":"100","is_show":"1"},
      {"key":"a_request_counselor","name":"상담사","width":"100","is_show":"1"},
      {"key":"a_progress_reason","name":"진도전략","width":"150","is_show":"1"},
      {"key":"a_progress","name":"현재진도","width":"150","is_show":"1"},
      {"key":"a_grow","name":"성장한부분","width":"150","is_show":"1"},
      {"key":"a_homework_degree","name":"숙제수행도","width":"100","is_show":"1"},
      {"key":"a_understand_degree","name":"이해점수","width":"100","is_show":"1"},
      {"key":"a_study_amount","name":"하루학습량","width":"100","is_show":"1"},
      {"key":"a_lesson_attitude","name":"수업태도","width":"100","is_show":"1"},
      {"key":"a_study_emotion","name":"학습정서(감정)","width":"200","is_show":"1"},
      {"key":"a_lack","name":"새로운 발견(상황중심기술)","width":"250","is_show":"1"},
      {"key":"a_content","name":"학습 처방","width":"250","is_show":"1"},
      {"key":"a_success_check_msg","name":"처방 확인","width":"250","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_is_success, a_reserve_date DESC, a_create_date DESC',
      'order_type':'',
      's_date_type':'a_reserve_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_addon_user":"1",
      "s_stu_seq":"",
      "s_stu_name_like":"",
      "s_stu_phone_like":"",
      "s_stu_school_name_like":"",
      "s_title_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;