import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';

const ReceiverUserArea = forwardRef((props:any, ref) => {
  let myPorps:any={
    receive_user_arr:[],
    set_receive_user_arr:(inData:any)=>{},
    push_list:(inData:any)=>{},
    ...props
  };

  const [receive_user_arr,set_receive_user_arr]=useState<any>(myPorps.receive_user_arr);
  const [able_user_cnt,set_able_user_cnt]=useState(0);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));
  
  const setInitData=(inData:any)=>{
    let optObj:any={
      "receive_user_arr":[],
      ...inData
    };
    set_receive_user_arr(optObj["receive_user_arr"]);
    let tmp_able_user_cnt=0;
    for(let i=0;i<optObj["receive_user_arr"].length;i++){
      let user_info=optObj["receive_user_arr"][i];
      if(!strFunc.is_empty(user_info.comp_link_info)){
        if(user_info.comp_link_info.main_u_info){
          if(user_info.comp_link_info.main_u_info.a_push_token){
            tmp_able_user_cnt++;
          }
        }
      }
    }
    set_able_user_cnt(tmp_able_user_cnt);
  };

  const removeUserOne=(idx:number)=>{
    let tmp_receive_user_arr=[];
    for(let i=0;i<receive_user_arr.length;i++){
      if(i!=idx){
        tmp_receive_user_arr.push(receive_user_arr[i]);
      }
    }
    set_receive_user_arr(tmp_receive_user_arr);
    myPorps.set_receive_user_arr(tmp_receive_user_arr);
    myPorps.push_list({});
  };

  const getUserTags=()=>{
    let user_tag_obj=receive_user_arr.map((user_info:any,user_i:number)=>{
      let u_title_msg="";
      if(user_info.a_user_grade=="student"){
        u_title_msg="학생";
      }else if(user_info.a_user_grade=="parent"){
        u_title_msg="학부모";
      }else if(user_info.a_user_grade=="teacher"){
        u_title_msg="선생님";
      }

      let user_name_text=user_info.a_user_name;
      let u_name_style:any={
        "color":"#785656"
      };
      if(!strFunc.is_empty(user_info.comp_link_info)){
        user_name_text=(
          <>
          <span>{user_name_text}</span>
          <span style={{"color":"gray","marginLeft":2,"fontSize":12}}>○</span>
          </>
        );
        if(user_info.comp_link_info.main_u_info){
          if(user_info.comp_link_info.main_u_info.a_push_token){
            u_name_style["color"]="blue";
            user_name_text=user_info.a_user_name;
          }
        }
      }

      return (
        <span key={user_i} className={Style.receiver_item} title={u_title_msg} >
          <span className={Style.receiver_item_idx}>{user_i+1}.</span>
          <span style={u_name_style}>{user_name_text}</span>
          <button className="btn-s btn-gray px-1 ml-2" onClick={()=>{removeUserOne(user_i);}} >X</button>
        </span>
      );
    });

    return user_tag_obj;
  };

  let userTags=getUserTags();
  if(userTags==""){
    userTags=(
      <h5>받는 유저 선택 필요.</h5>
    );
  }

  return (
    <>
    <div className={Style.receiver_wrap}>
      {userTags}
    </div>
    <div style={{fontSize:12}}>
      <p>■ 전송가능 유저수 : <span style={{color:"blue"}}>{able_user_cnt}</span> 명</p>
      <p>※ <span style={{"color":"#785656","marginLeft":2,"fontSize":12}}>갈색</span> : 어플가입 없음.</p>
      <p>※ <span style={{"color":"gray","marginLeft":2,"fontSize":12}}>○</span> : 로그아웃 상태</p>
      <p>※ <span style={{color:"blue",fontWeight:"bold"}}>파란색</span> 이름은 어플에 로그인 유저입니다.</p>
    </div>
    </>
  );
});

export default ReceiverUserArea;