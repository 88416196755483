import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import CalendarTable from "./area/calendar_table";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import { useNavigate } from 'react-router';

const PlanCalendarArea = forwardRef((props:any, ref) => {
  const myProps={
    onSearchDateChange:(inData:any)=>{},
    list:(inOptObj:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    openTodoWritePopup:(inData:any)=>{},
    removeSelectStu:(stu_seq:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const CalendarTableRef=useRef<any>(null);
  const [listOpt,setListOpt]=useState({
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
    "order_id":"a_stu_name",
    "s_reserve_start_date":"",
    "s_reserve_end_date":"",
    "s_addon_user":"1",
    "s_is_mcomp_public":"1",
    "s_todo_sort":"",
    "s_addon_par_content":"1",
    "s_stu_user_seq":[],
    "s_stu_info_arr":[],
    "s_class_seq_arr":[],
    "s_date_str":DateFunc.get_date_format(new Date(),"Y-m-d"),

    "is_select_stu_search":"select",
  });
  const [todoInfoArr, setTodoInfoArr] = useState([]);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      ...inOptObj
    };
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,list
  }));

  useEffect(()=>{
    
    list({});
  },[]);

  const list = (inOptObj:any)=>{
    let list_form_json:any={
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);
    
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    if(strFunc.is_empty(list_form_json.s_stu_user_seq)&&strFunc.is_empty(list_form_json.s_class_seq_arr)){
      if(list_form_json.is_select_stu_search=="select"){
        setTodoInfoArr([]);
        CalendarTableRef.current.set_todo_arr([]);
        CalendarTableRef.current.set_stu_info_arr([]);
        return false;
      }
    }
    list_form_json.s_stu_mcomp_seq=user.comp_seq;

    if(!strFunc.is_empty(list_form_json.s_date_str)){
      let pre_month_obj=DateFunc.get_change_date(new Date(list_form_json.s_date_str),'month',-1);
      let after_month_obj=DateFunc.get_change_date(new Date(list_form_json.s_date_str),'month',+1);
      list_form_json.s_start_date=DateFunc.get_date_format(pre_month_obj,"Y-01-01");
      list_form_json.s_reserve_start_date=DateFunc.get_date_format(pre_month_obj,"Y-m-01");
      list_form_json.s_reserve_end_date=DateFunc.get_date_format(after_month_obj,"Y-m-t");
    }

    let tmp_list_form_json=list_form_json;
    if(tmp_list_form_json.s_stu_user_seq.length>0&&tmp_list_form_json.s_stu_user_seq.length!=1){
      tmp_list_form_json.s_stu_user_seq=[tmp_list_form_json.s_stu_user_seq[0]];
    }

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',tmp_list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setTodoInfoArr(response.data["data"]["info_arr"]);
        CalendarTableRef.current.set_todo_arr(response.data["data"]["info_arr"]);
        CalendarTableRef.current.set_stu_info_arr(list_form_json.s_stu_info_arr);
      }else{
        
      }
    });
  };

  const moveToWriteTodoPage=(select_date_str:string)=>{
    //myProps.s_stu_user_seq
    navigate('/comp/plan/todo/make_todo', { state:{
      select_date_str:select_date_str,
      stu_seq_arr:listOpt.s_stu_user_seq
    }});
  };

  return (
    <div>
      <CalendarTable
      ref={CalendarTableRef}
      listOpt={listOpt}
      select_date={listOpt.s_date_str}
      list={list}
      onSearchDateChange={(inData:any)=>{
        myProps.onSearchDateChange(inData);
        list({"s_date_str":inData});
      }}
      openTodoDaySort={myProps.openTodoDaySort}
      openTodoWritePopup={myProps.openTodoWritePopup}
      removeSelectStu={myProps.removeSelectStu}
      moveToWriteTodoPage={moveToWriteTodoPage}
      ></CalendarTable>
    </div>
  );
});

export default PlanCalendarArea;
