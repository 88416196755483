import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import TimeTableArea from "./area/time_table";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import go_down_excel from "./area/excel/down_time_table";

function LectureListOneDay(){
  let today_date_json=DateFunc.get_date_json(new Date());
  const [pageData, setPageData] = useState({
    listOpt:{
      "s_state":"ing",
      "s_teacher_like":"",
      "s_name_like":"",
      "s_day_num":[today_date_json["day"]],
      "s_addon_time":"1",
      "s_addon_user":"1",
      "s_addon_user_time":"1",
      "s_addon_user_s_state":"ing",
      //"s_addon_time_union":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData:any=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let list_form_json_data=pageData.listOpt;
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/lecture/list',list_form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        response.data["data"]["info_arr"]=get_setting_data_of_lecture_arr({"info_arr":response.data["data"]["info_arr"]});
        setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_setting_data_of_lecture_arr=(inData:any)=>{
    let opt_obj={
      "info_arr":[],
      ...inData
    };
    let info_arr_len=opt_obj["info_arr"].length;
    for(let info_i=0;info_i<info_arr_len;info_i++){
      let info:any=opt_obj["info_arr"][info_i];

      //시간표 + 유저시간표 merge_time_arr
      if(info.time_arr&&info.user_time_arr){
        opt_obj["info_arr"][info_i]["time_arr"]=[
          ...info.time_arr,
          ...info.user_time_arr
        ];
      }

      //유저분리 (student_arr_base , user_time_arr 에 student_info 넣기 )
      opt_obj["info_arr"][info_i]["student_arr_base"]=[];
      if(info.student_arr){
        let student_arr_base=[];
        for(let i=0;i<info.student_arr.length;i++){
          let tmp_stu_info=info.student_arr[i];
          let tmp_has_custom=false;
          for(let j=0;j<info.user_time_arr.length;j++){
            if(info.user_time_arr[j]["a_stu_seq"]==tmp_stu_info["a_user_seq"]){
              tmp_has_custom=true;
              opt_obj["info_arr"][info_i]["user_time_arr"][j]["student_info"]=tmp_stu_info;
            }
          }
          if(tmp_has_custom==false){
            student_arr_base.push(tmp_stu_info);
          }
        }
        opt_obj["info_arr"][info_i]["student_arr_base"]=student_arr_base;
      }

    }

    return opt_obj["info_arr"];
  };
  
  const get_start_end_time_min=()=>{
    let start_time=22*60;
    let end_time=9*60;
    let infoArr_len=infoArr.length;
    for(let i=0;i<infoArr_len;i++){
      let info:any=infoArr[i];
      if(info.time_arr){
        for(let j=0;j<info.time_arr.length;j++){
          let time_info=info.time_arr[j];

          let tmp_start_time=strFunc.timeToSeconds(time_info["a_start_h"],time_info["a_start_m"],0);
          tmp_start_time=tmp_start_time/60;

          if(tmp_start_time<start_time){
            start_time=tmp_start_time;
          }
          
          let tmp_end_time=strFunc.timeToSeconds(time_info["a_end_h"],time_info["a_end_m"],0);
          tmp_end_time=tmp_end_time/60;
          
          if(tmp_end_time>end_time){
            end_time=tmp_end_time;
          }
        }
      }
    }
    
    return {
      "start_time":start_time,
      "end_time":end_time
    };
  };

  let start_end_time_json=get_start_end_time_min();

  return (
    <CompLayout isConTitle={false}>
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
        go_down_excel={()=>{
          go_down_excel({
            start_time:start_end_time_json.start_time,
            end_time:start_end_time_json.end_time,
            lecture_arr:infoArr,
          });
        }}
      ></SearchArea>
      <TimeTableArea
        start_time={start_end_time_json.start_time}
        end_time={start_end_time_json.end_time}
        lecture_arr={infoArr}
      ></TimeTableArea>
    </CompLayout>
  );
}

export default LectureListOneDay;