import { useState,forwardRef,useImperativeHandle } from 'react';

const SearchArea = forwardRef((props:any, ref) => {
  let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    goDelete:(inData:any)=>{},
    setWriteState:(inData:any)=>{},
    ...props
  };
  const [listOpt,setListOpt] = useState(props.listOpt);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({[name]: value});
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  return (
    <div className="search-box relative">
      제목:
      <input type="text" className="search-input" name="s_title_like" value={listOpt.s_title_like} onChange={handleInputChange} style={{width:100}} />
      <button className="btn btn-dark" onClick={()=>{goSearch();}} >검색</button>
      <div className="absolute right-0 top-3 btn-box-right">
        <button className="btn btn-dark" onClick={()=>{myProps.setWriteState();}}>신규</button>
        <button className="btn btn-dark" onClick={myProps.goDelete}>삭제</button>
      </div>
    </div>
  );
});

export default SearchArea;