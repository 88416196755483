import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindStudyPopup from "@/pages/comp/plan/todo/popup/find_study";
import FindVideoPopup from "@/pages/comp/plan/todo/popup/find_video";
import strFunc from '@/lib/lyg/string';

const TodoContetnArea = forwardRef((props:any, ref) => {
  const myProps={
    set_todo_content_arr:(inOptObj:any)=>{},
    ...props
  };
  
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  let user=useSelector((state:any) => state.user);

  let default_todo_content={
    "a_seq":1,
    "a_sort":"homework",//homework 숙제, study 학습, video 강의
    "a_subject":"",
    "a_title":"",
    "a_content":"",
    "a_success_sort":"amount",//amount,time
    "a_par_id":"",
    "a_par_seq":"",
    "a_success_amount":"0",
    "a_success_time_sec":"0",
    "a_amount_sort":"개",
    "row_view_success_amount":"1",
  };
  const [todo_content_arr,set_todo_content_arr]=useState<any>([]);

  const [popupData,setPopupData] = useState<any>({
    isOpen:false,
    sort:"find_study",//find_study,find_video
    title:"학습찾기",
    width:"800px",
    height:"100%",
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "todo_content_arr":[],
      ...inOptObj
    };
    set_todo_content_arr(optObj.todo_content_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_todo_content_arr
  }));

  const get_todo_content_arr=()=>{
    let all_row=ListComponentRef.current.getRows();
    set_todo_content_arr(all_row);
    return all_row;
  };

  const openFindStudyPopup=()=>{
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find_study",
      title:"학습찾기",
    });
  };
  const openFindVideoPopup=()=>{
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find_video",
      title:"동영상강의 찾기",
    });
  };

  const add_home_work=()=>{
    let all_row=ListComponentRef.current.getRows();
    let last_seq=get_last_seq();
    last_seq++;
    //숙제넘버링
    let homework_num=1;
    for(let i=0;i<all_row.length;i++){
      if(all_row[i]["a_title"].indexOf("숙제")!=-1){
        homework_num++;
      }
    }
    let add_row_data={
      ...default_todo_content,
      "a_seq":last_seq,
      "a_title":"숙제"+homework_num,
      "a_success_sort":"amount",
    };
    all_row.push(add_row_data);
    ListComponentRef.current.setInfoArr(all_row);
  };

  const add_study_list_by_callback=(inData:any)=>{
    let opt_obj={
      "info_arr":[],
      ...inData
    };

    let all_row=ListComponentRef.current.getRows();
    let last_seq=get_last_seq();
    last_seq++;

    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let study_info=opt_obj["info_arr"][i];
      let add_row_data={
        ...default_todo_content,
        "a_seq":last_seq,
        "a_sort":"study",
        "a_subject":study_info["a_subject_sort"],
        "a_title":study_info["a_title"],
        "a_content":study_info["a_content"],
        "a_success_sort":"amount",
        "a_par_id":"study_list",
        "a_par_seq":study_info["a_title_seq"]+","+study_info["a_seq"],
        "a_success_amount":study_info["a_amount_num"],
        "a_amount_sort":study_info["a_amount_sort"],
        "row_view_success_amount":study_info["a_amount_num"]
      };
      
      all_row.push(add_row_data);
    }
    ListComponentRef.current.setInfoArr(all_row);
  };

  const add_video_list_by_callback=(inData:any)=>{
    let opt_obj={
      "info_arr":[],
      ...inData
    };

    let all_row=ListComponentRef.current.getRows();
    let last_seq=get_last_seq();
    last_seq++;

    for(let i=0;i<opt_obj["info_arr"].length;i++){
      let video_info=opt_obj["info_arr"][i];
      let a_total_sec=strFunc.uncomma(video_info["a_total_sec"]);
      let add_row_data={
        ...default_todo_content,
        "a_seq":last_seq,
        "a_sort":"study",
        "a_subject":video_info["a_subject"],
        "a_title":video_info["a_title"],
        "a_content":video_info["a_content"],
        "a_success_sort":"amount",
        "a_par_id":"video_list",
        "a_par_seq":video_info["a_corse_seq"]+","+video_info["a_seq"],
        "a_success_time_sec":a_total_sec,
        "a_success_amount":"1",
        "a_amount_sort":"개",
        "row_view_success_amount":"1"
      };
      all_row.push(add_row_data);
    }
    ListComponentRef.current.setInfoArr(all_row);
  };

  const get_last_seq=()=>{
    let last_seq=0;
    let all_row=ListComponentRef.current.getRows();
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_seq=parseInt(tmp_row.a_seq);
      if(tmp_seq>last_seq){
        last_seq=tmp_seq;
      }
    }
    return last_seq;
  };

  const removeSelect=()=>{
    let all_row=ListComponentRef.current.getRows();
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      return false;
    }
    let remain_row_arr=[];
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_is_exist=false;
      for(let j=0;j<selected_row.length;j++){
        let tmp_sel_row=selected_row[j];
        if(tmp_row.a_seq==tmp_sel_row.a_seq){
          tmp_is_exist=true;
        }
      }
      if(tmp_is_exist==false){
        remain_row_arr.push(tmp_row);
      }
    }
    ListComponentRef.current.setInfoArr(remain_row_arr);
  };

  const removeOneRow=(idx:number)=>{
    let new_info_arr=[];
    let all_row=ListComponentRef.current.getRows();
    for(let i=0;i<all_row.length;i++){
      if(i==idx){

      }else{
        new_info_arr.push(all_row[i]);
      }
    }
    ListComponentRef.current.setInfoArr(new_info_arr);
  };

  const customCellRenderer=(params:any)=>{
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    let key=params.key;
    let val_str=params.value;

    if(key=="a_sort"){
      if(xColumnArr.select_arr[key]){
        for(let i=0;i<xColumnArr.select_arr[key].length;i++){
          let sel_opt_row=xColumnArr.select_arr[key][i];
          if(sel_opt_row["value"]==val_str){
            val_str=sel_opt_row["text"];
          }
        }
      }
    }else if(key=="a_success_sort"){
      if(xColumnArr.select_arr[key]){
        for(let i=0;i<xColumnArr.select_arr[key].length;i++){
          let sel_opt_row=xColumnArr.select_arr[key][i];
          if(sel_opt_row["value"]==val_str){
            val_str=sel_opt_row["text"];
          }
        }
      }
    }else if(key=="row_view_manage"){
      val_str=(
        <div className="text-center">
          <button className="btn btn-red" onClick={()=>{
            removeOneRow(params.idx);
          }}>제외</button>
        </div>
      );
    }

    return val_str;
  };
  const customGridCellRenderer=(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "input_tag":input_tag,
        "onChangeInput":onChangeInput,
      }
      */
     let key=params.key;
     if(key=="row_view_success_amount"){

     }else if(key=="a_amount_sort"){
      if(params.rowData.a_success_sort=="time"){
        params.input_tag="분";
      }
     }
      return params.input_tag;
  };

  return (
    <div>
      <div className="my-1 text-right">
        <button className="btn-m btn-yellowish3" onClick={()=>{add_home_work();}} >숙제추가</button>
        <button className="btn-m btn-yellowish3 ml-1" onClick={()=>{openFindStudyPopup();}} >학습찾기</button>
        <button className="btn-m btn-yellowish3 ml-1" onClick={()=>{openFindVideoPopup();}} >강의찾기</button>
        <button className="btn-m btn-red ml-1" onClick={()=>{removeSelect();}} >제외</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        infoArr={todo_content_arr}
        gridOpt={{
          isGrid:true,
          is_add_idx_num:false,
          is_add_checkbox:true,
          width:"100%",
          height:"45vh",
          is_auto_max_width:false,
          td_align:"left",//center,right
          table_align:"left",//center,right
          onGridReady:()=>{},
          rowOnClick:(rowData:any,idx:number,e:any)=>{

          },
          customCellRenderer:customCellRenderer,
          customGridCellRenderer:customGridCellRenderer,
          class_name_json:{
            table_div:"basic_list_div",
            td_div:"row-col-div",
            input_check:"chk_row",
            row_input:"row-input",
            data_row_tr:"data_row_tr",
            active_row:"active",
            empty_con_div:"list_empty_content_div",
          },
        }}
      ></ListComponent>

      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} y={"0"} >
          {popupData.sort==="find_study"&&
           <FindStudyPopup
            callbackData={add_study_list_by_callback}
            closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
           ></FindStudyPopup>
          }
          {popupData.sort==="find_video"&&
            <FindVideoPopup
              callbackData={add_video_list_by_callback}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
            ></FindVideoPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default TodoContetnArea;
