import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import PreWriteArea from "./pre_area";
import DetailListArea from "./detail_list";
import strFunc from "@/lib/lyg/string";

function WriteArea(props: any) {
  let myProps = {
    rowData: {},
    isUpdate: false,
    openFIndStudentPopup: () => { },
    setRowData: (inData: any) => { },
    comment_list: [],
    refresh_comment: (inData: any) => { },
    list: (inData: any) => { },
    is_find_stu: true,
    is_view_mode: false,
    set_refresh: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let rowData = myProps.rowData;
  let isUpdate = myProps.isUpdate;
  let is_view_mode = myProps.is_view_mode;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let row_pri_val = "";
  if (isUpdate) {
    row_pri_val = rowData.a_ymd + "," + rowData.a_seq;
  }
  let comment_init_data = {
    writer_seq: rowData.a_writer_seq,
    writer_name: rowData.a_writer,
    par_id: "study_diary",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(rowData["a_study_date"]), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };
  if (isUpdate) {
    comment_init_data["s_start_date"] = rowData["a_study_date"];
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_row_data:any={};
    change_row_data[name]=value;
    if(name=="a_is_success_prescribe"){
      if(value=="1"&&rowData.a_is_success_prescribe==""){
        change_row_data["a_prescribe_success_date"]=DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }else if(name=="a_subject_name"){
      if(!strFunc.is_empty(value)){
        update_subject_name_of_writer_by_ajax({
          subject_name:value,
        });
      }
    }

    myProps.setRowData({
      ...rowData,
      ...change_row_data
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const update_subject_name_of_writer_by_ajax=(inData:any)=>{
    let opt_obj={
      subject_name:"",
      ...inData
    };
    if(strFunc.is_empty(opt_obj["subject_name"])){
      return false;
    }
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }
    let up_row_data={
      a_seq:user.user_seq,
      a_main_subject:opt_obj["subject_name"],
    };
    let formJsonData = {
      "data_arr": [up_row_data],
      "is_update":"1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          
        } else {

        }
      });
  };

  let is_pre_row_data = false;
  if (isUpdate) {
    let tmp_pre_time = new Date("2024-03-31").getTime();
    let tmp_create_time = new Date(rowData["a_create_date"]).getTime();
    if (tmp_create_time < tmp_pre_time) {
      is_pre_row_data = true;
    }
  }

  return (
    <div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            <col width="10%"></col>
            <col width="42%"></col>
            <col width="10%"></col>
            <col width="38%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={4} className="text-center">
                <span>
                  <span style={{ color: "#6faa12", fontWeight: "bold" }}>
                    {rowData.a_stu_name}
                  </span>
                  {(isUpdate == false && myProps.is_find_stu && myProps.is_view_mode == false) &&
                    <button className="btn-s btn-dark ml-2 py-1" onClick={() => { myProps.openFIndStudentPopup(); }} >학생찾기</button>
                  }
                </span>
                <span className="ml-2">
                  과목:
                  {
                    is_view_mode ?
                      rowData.a_subject_name :
                      <>
                        <input type="text" className="row-input" name="a_subject_name"
                          value={rowData.a_subject_name} onChange={handleInputChange} placeholder="과목"
                          style={{ width: 60 }} />
                        <select className="row-input ml-1" name="a_subject_name" onChange={handleInputChange}
                          style={{ width: 70 }} >
                          <option value="">과목</option>
                          {select_subject_option_arr.map((item: any, idx: number) => {
                            return (
                              <option key={idx} value={item.value}>{item.text}</option>
                            );
                          })}
                        </select>
                      </>
                  }
                </span>
                <span className="ml-2">
                  작성일:
                  {is_view_mode ?
                    rowData.a_study_date :
                    <span style={{ display: "inline-block", width: 100 }}>
                      <DatePicker
                        selected={rowData.a_study_date != "" ? new Date(rowData.a_study_date) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_study_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite ></CustomInputWrite>}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </span>
                  }
                </span>
                <span className="ml-2">
                  작성자:
                  {is_view_mode ?
                    rowData.a_writer :
                    <input type="text" className="row-input" name="a_writer"
                      value={rowData.a_writer} onChange={handleInputChange} placeholder="작성자"
                      style={{ width: 75 }} />
                  }
                </span>
              </td>
            </tr>
            <DetailListArea
              sdiary_info={rowData}
              set_sdiary_info={myProps.setRowData}
              list={myProps.list}
              sort={"progress"}
              set_refresh={myProps.set_refresh}
              is_view_mode={is_view_mode}
            ></DetailListArea>
            <DetailListArea
              sdiary_info={rowData}
              set_sdiary_info={myProps.setRowData}
              list={myProps.list}
              sort={"homework"}
              set_refresh={myProps.set_refresh}
              is_view_mode={is_view_mode}
            ></DetailListArea>
            <tr>
              <th style={{ fontSize: "11px" }}>학습목표</th>
              <td colSpan={3}>
                {is_view_mode ?
                  <MultyLineTextView
                    text={rowData["a_study_goal"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    class_name="row-input"
                    name="a_study_goal"
                    value={rowData.a_study_goal}
                    onChange={handleInputChange}
                    placeholder="학습목표"
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <th style={{ fontSize: "11px" }}>관찰기록</th>
              <td colSpan={3}>
                {is_view_mode ?
                  <MultyLineTextView
                    text={rowData["a_observe"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    class_name="row-input"
                    name="a_observe"
                    value={rowData.a_observe}
                    onChange={handleInputChange}
                    placeholder="관찰기록"
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            {is_pre_row_data &&
              <PreWriteArea
                rowData={rowData}
                isUpdate={isUpdate}
                comment_init_data={comment_init_data}
                handleInputChange={handleInputChange}
                comment_list={myProps.comment_list}
                refresh_comment={myProps.refresh_comment}
              ></PreWriteArea>
            }
            <tr>
              <th rowSpan={2} style={{ color: "#fff", background: "#406173", fontSize: "11px" }}>학습처방</th>
              <td colSpan={3}>
                {is_view_mode ?
                  <MultyLineTextView
                    text={rowData["a_study_prescribe"]}
                    style={{ color: "blue" }}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    class_name="row-input"
                    name="a_study_prescribe"
                    value={rowData.a_study_prescribe}
                    onChange={handleInputChange}
                    placeholder="학습처방"
                    style={{ color: "blue" }}
                  ></TextAreaComponent>
                }
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <label>
                  <input type="checkbox" className="row-input" name="a_is_success_prescribe"
                    checked={rowData.a_is_success_prescribe == "1"}
                    value="1"
                    style={{ width: 15, height: 15 }}
                    onChange={handleInputChange} />
                  <span className="ml-1">처방완료</span>

                  {rowData.a_is_success_prescribe == "1" &&
                    <span className="ml-2" style={{ color: "gray" }}>
                      {rowData.a_prescribe_success_date}
                    </span>
                  }
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isUpdate &&
        <CommentPartArea
          info_arr={myProps.comment_list}
          list={myProps.refresh_comment}
          is_show_toggle={false}
          is_show_detail={true}
          title_str={"추가분석요청"}
          wrap_style={{ width: "100%" }}
          init_data={{
            ...comment_init_data,
            par_locate: "",
            par_locate_name: "추가분석요청",
          }}
        ></CommentPartArea>
      }
    </div>
  );
};
export default WriteArea;
