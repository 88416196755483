import { useState, useRef, useEffect } from 'react';
import Style from "../../../../css/style.module.css";
import CircleArea from "./circle";

const CardBoxTopTitleArea = (props:any) => {
  let myProps:any={
    user_info:{},
    todo_stat_data:{},
    todo_color:"green",
    openViewTodoDayPopup:(stu_info:any)=>{},
    ...props
  };

  let edu_class_name="클래스없음.";
  let class_info=null;
  if(myProps.user_info.class_arr&&myProps.user_info.class_arr.length>0){
    class_info=myProps.user_info.class_arr[0];
    edu_class_name=class_info["class_name"];
  }

  return (
    <div className={Style.card_box_top}>
      <div className={Style.card_box_todo_per} onClick={()=>{
        myProps.openViewTodoDayPopup(myProps.user_info);
      }}>
        <CircleArea 
          per_num={myProps.todo_stat_data.total.percent}
          color={myProps.todo_color}
          back_color={"#ddd"}
        ></CircleArea>
        <div className={Style.card_box_todo_per_text}>
          <p style={{fontSize:10}}>학습</p>
          <p>{myProps.todo_stat_data.total.percent}%</p>
        </div>
      </div>
      <div className={Style.card_box_title_wrap}>
        <p className={Style.card_box_title}>{myProps.user_info.a_user_name}</p>
        <p className={Style.card_box_sub_title}>{edu_class_name}</p>
      </div>
    </div>
  );
};

export default CardBoxTopTitleArea;