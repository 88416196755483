import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";

const StuListArea = forwardRef((props: any, ref) => {
  let myProps = {
    list: (inData: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: "",//["student"]
      s_stu_state_and_stu: "now",
      s_is_login:"1",
      s_en_class_seq: "",
      s_addon_data: "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [en_class_arr, set_en_class_arr] = useState([]);
  const [change_en_class_seq, set_change_en_class_seq] = useState("");

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find_student",
    title: "학생찾기",
    s_except_user_seq: [],
    width: "800px",
    height: "85%",
  });

  useEffect(() => {
    
    get_en_class_by_ajax();
  }, []);

  const set_init_data = (inData: any) => {
    let opt_obj = {
      s_en_class_seq: "",
      ...inData
    };
    get_en_class_by_ajax();
    list({
      now_page: "1",
      s_en_class_seq: opt_obj.s_en_class_seq
    });
  };
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_init_data
  }));

  const get_en_class_by_ajax = () => {
    let list_json_form_data = {
      "order_id": "a_order_num",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/list', list_json_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_en_class_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const list = (inOptObj: any) => {
    let tmpPageData = pageData;
    if (inOptObj != undefined) {

      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let tmp_list_opt = tmpPageData.listOpt;
    if (strFunc.is_empty(tmp_list_opt.s_en_class_seq)) {
      setInfoArr([]);
      listAggridRef.current.setInfoArr([]);
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"]=get_ordered_stu_info_arr(response.data["data"]["info_arr"]);
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_ordered_stu_info_arr=(in_info_arr:[])=>{
    let new_info_arr:any=[];
    let state_group_arr:any={
      "1":[],
      "2":[],
    };
    for(let i=0;i<in_info_arr.length;i++){
      let stu_info=in_info_arr[i];
      if(stu_info["a_stu_state"]=="now"){
        state_group_arr["1"].push(stu_info);
      }else{
        state_group_arr["2"].push(stu_info);
      }
    }
    for(let key in state_group_arr){
      let row_info_arr=state_group_arr[key];
      for(let i=0;i<row_info_arr.length;i++){
        new_info_arr.push(row_info_arr[i]);
      }
    }
    return new_info_arr;
  };

  const openFindStuPopup = () => {
    let all_rows = listAggridRef.current.getRows();
    let s_except_user_seq: any = [];
    for (let i = 0; i < all_rows.length; i++) {
      let row_data = all_rows[i];
      s_except_user_seq.push(row_data["a_seq"]);
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "s_except_user_seq": s_except_user_seq,
      "sort": "find_student",
      width: "800px",
      height: "85%",
    });
  };

  const call_back_invite_stu_by_popup = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData
    };
    if(opt_obj.info_arr.length==0){
      alert("초대 할 학생 선택이 없습니다.");
      return false;
    }

    if(strFunc.is_empty(pageData.listOpt.s_en_class_seq)){
      alert("이동할 클래스 키가 없습니다.");
      return false;
    }

    if(!confirm(opt_obj.info_arr.length+"명을 초대 하시겠습니까?")){
      return false;
    }

    let move_user_seq_arr=[];
    for(let i=0;i<opt_obj.info_arr.length;i++){
      move_user_seq_arr.push(opt_obj.info_arr[i]["a_seq"]);
    }

    let move_form_json_data = {
      "to_seq": pageData.listOpt.s_en_class_seq,
      "user_seq_arr": move_user_seq_arr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/move_user', move_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.list({ "select_seq": pageData.listOpt.s_en_class_seq });
          alert("성공");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_move_class = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("학생 선택이 없습니다.");
      return false;
    }
    if (strFunc.is_empty(change_en_class_seq)) {
      alert("클래스 선택이 없습니다.");
      return false;
    }
    let move_user_seq_arr = [];
    for (let i = 0; i < selected_row.length; i++) {
      move_user_seq_arr.push(selected_row[i]["a_seq"]);
    }

    if (move_user_seq_arr.length == 0) {
      alert("학생 선택이 없습니다.2");
      return false;
    }
    if (!confirm("클래스이동 하시겠습니까?")) {
      return false;
    }

    let move_form_json_data = {
      "to_seq": change_en_class_seq,
      "user_seq_arr": move_user_seq_arr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/move_user', move_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          myProps.list({ "select_seq": change_en_class_seq });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_coolen_link_user=(inData:any)=>{
    let opt_obj={
      confirm_msg:"연동 하시겠습니까?",
      is_allow_coolen:"empty",
      "coolen_sort":"inside",//inside,bank
      ...inData,
    };
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("학생 선택이 없습니다.");
      return false;
    }
    if (selected_row.length != 1) {
      alert("한명만 선택해 주세요.");
      return false;
    }
    if (!confirm(selected_row[0]["a_user_name"]+"을 "+opt_obj["confirm_msg"])) {
      return false;
    }

    let link_form_json_data = {
      "user_seq": selected_row[0]["a_seq"],
      "is_allow_coolen":opt_obj["is_allow_coolen"],
      "coolen_sort":opt_obj["coolen_sort"]
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/coolen/coolen/write_user', link_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          myProps.list({ "select_seq": pageData.listOpt.s_en_class_seq });
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_coolen_link_user_multy=(inData:any)=>{
    let opt_obj={
      confirm_msg:"연동 하시겠습니까?",
      is_allow_coolen:"empty",
      ...inData
    };
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("학생 선택이 없습니다.");
      return false;
    }
    if (!confirm(opt_obj["confirm_msg"])) {
      return false;
    }
    let user_seq_arr=[];
    for(let i=0;i<selected_row.length;i++){
      user_seq_arr.push(selected_row[i]["a_seq"]);
    }
    let link_form_json_data = {
      "user_seq_arr": user_seq_arr,
      "is_allow_coolen":opt_obj["is_allow_coolen"],
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/coolen/coolen/write_user_multy', 
      link_form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          myProps.list({ "select_seq": pageData.listOpt.s_en_class_seq });
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_coolen") {
      render_str = "미연동";
      if (params.data["addon_data_info"]
        && !strFunc.is_empty(params.data["addon_data_info"]["a_coolen_user_seq"])) {
        render_str = (<span style={{ color: "blue" }}>연동</span>);
      }
      render_str=(
        <button onClick={()=>{
          setTimeout(()=>{go_coolen_link_user({});},100);
        }}>
          {render_str}
        </button>
      );
    }else if(key=="row_view_coolen_allow"){
      render_str=(<span style={{ color: "red" }}>미승인</span>);
      let change_is_allow="1";
      let tmp_confirm_msg="승인 하시겠습니까?";
      if (params.data["addon_data_info"]
        && params.data["addon_data_info"]["a_is_allow_coolen"]=="1") {
        render_str = (<span style={{ color: "blue" }}>승인</span>);
        change_is_allow="";
        tmp_confirm_msg="미승인 하시겠습니까?";
      }

      render_str=(
        <button onClick={()=>{
          setTimeout(()=>{go_coolen_link_user({
            confirm_msg:tmp_confirm_msg,
            is_allow_coolen:change_is_allow,
          });},100);
        }}>
          {render_str}
        </button>
      );
    }else if (key == "row_view_coolenb") {
      render_str = "미연동";
      if (params.data["addon_data_info"]
        && !strFunc.is_empty(params.data["addon_data_info"]["a_coolenb_user_seq"])) {
        render_str = (<span style={{ color: "blue" }}>연동</span>);
      }
      render_str=(
        <button onClick={()=>{
          setTimeout(()=>{go_coolen_link_user({coolen_sort:"bank"});},100);
        }}>
          {render_str}
        </button>
      );
    }else if(key=="row_view_coolenb_allow"){
      render_str=(<span style={{ color: "red" }}>미승인</span>);
      let change_is_allow="1";
      let tmp_confirm_msg="승인 하시겠습니까?";
      if (params.data["addon_data_info"]
        && params.data["addon_data_info"]["a_is_allow_coolenb"]=="1") {
        render_str = (<span style={{ color: "blue" }}>승인</span>);
        change_is_allow="";
        tmp_confirm_msg="미승인 하시겠습니까?";
      }

      render_str=(
        <button onClick={()=>{
          setTimeout(()=>{go_coolen_link_user({
            confirm_msg:tmp_confirm_msg,
            is_allow_coolen:change_is_allow,
            coolen_sort:"bank"
          });},100);
        }}>
          {render_str}
        </button>
      );
    }
    return render_str;
  };

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
  };

  return (
    <div>
      <SearchArea
        xColumnArr={xColumnArr}
        list_opt={pageData.listOpt}
        list={list}
        change_en_class_seq={change_en_class_seq}
        set_change_en_class_seq={set_change_en_class_seq}
        en_class_arr={en_class_arr}
        go_move_class={go_move_class}
        open_find_stu={openFindStuPopup}
        go_coolen_link_user_multy={go_coolen_link_user_multy}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: false,
          height: (window.innerHeight / 5) * 3 - 50,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <div className="mt-1">
        <p style={{fontSize:"13px"}}>
          ※ <span style={{color:"red"}}>휴,퇴원</span> 학생은 미승인으로 변경해주셔야 보이지 않습니다.
        </p>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "find_student" &&
            <FindMultyStudentPopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={call_back_invite_stu_by_popup}
              listOpt={{
                s_except_user_seq: popupData.s_except_user_seq,
              }}
              is_show_en_class={true}
            ></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
});
export default StuListArea;