import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import getXcolumnJson from "../../../xcolumn/list";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const WriteArea = forwardRef((props:any, ref) => {
  const myProps={
    setRowData:(inOptObj:any)=>{},
    open_manage_category_popup:()=>{},
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  const [categoryArr,setCategoryArr]=useState<any[]>([]);
  const init_data={
    "a_seq":"",
    "a_subject":"",
    "a_category_seq":"",
    "a_category":"",
    "a_title":"",
    "a_content":"",
    "a_state":"ing",
    "a_agree_sort":"manual",
    "a_period_sort":"continue",
    "a_start_date":"",
    "a_end_date":"",
    "a_level":"",
    "a_teacher_name":"",
    "a_open_group":"",
  };
  const [rowData,setRowData]=useState({
   ...init_data
  });
  let select_subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData,getCategoryList
  }));

  useEffect(()=>{
    
    getCategoryList();
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    let chage_data_json:any={
      [name]: value
    };

    if(name=="a_category_seq"){
      let category_name="";
      for(let i=0;i<categoryArr.length;i++){
        if(categoryArr[i]["a_seq"]==value){
          category_name=categoryArr[i]["a_title"];
        }
      }
      chage_data_json["a_category"]=category_name;
    }
    
    setRowData({
      ...rowData,
      ...chage_data_json
    });
    myProps.setRowData({
      ...rowData,
      ...chage_data_json
    });
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
    myProps.setRowData({
      ...rowData,
      ...{[key]: date_str}
    });
  };

  const getCategoryList=()=>{
    let get_category_form={
      "order_id":"a_order_num",
      "is_need_count":"",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',get_category_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setCategoryArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  return (
    <div className="write-table-div">
      <table>
        <colgroup>
          <col width="20%"></col>
          <col width="30%"></col>
          <col width="20%"></col>
          <col width="30%"></col>
        </colgroup>
        <tbody>
          <tr>
            <th>카테고리</th>
            <td>
              <select className="row-input" name="a_category_seq" value={rowData.a_category_seq} 
                onChange={handleInputChange}
                style={{width:90}}>
                <option value="">선택없음</option>
                {
                  categoryArr.map((item:any,idx:number)=>{
                    return (
                      <option value={item.a_seq} key={idx} >{item.a_title}</option>
                    );
                  })
                }
              </select>
              <button className="btn btn-gray ml-1" onClick={()=>{myProps.open_manage_category_popup();}}>관리</button>
            </td>
            <th>그룹공개</th>
            <td>
              <select className="row-input" name="a_open_group" value={rowData.a_open_group} onChange={handleInputChange}>
                {
                  xColumnArr.select_arr["a_open_group"]&&
                  xColumnArr.select_arr["a_open_group"].map((item:any,idx:number)=>{
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <th>과목</th>
            <td colSpan={3}>
              <input type="text" className="row-input" name="a_subject" value={rowData.a_subject} 
                onChange={handleInputChange} placeholder="과목" style={{width:100}} />
              <select className="row-input ml-1" name="a_subject" value={rowData.a_subject} 
                onChange={handleInputChange} style={{width:90}}>
                <option value="">선택</option>
                {
                  select_subject_option_arr.map((item:any,idx:number)=>{
                    return (
                      <option key={idx} value={item.value}>{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan={3}>
              <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={handleInputChange} placeholder="제목" />
            </td>
          </tr>
          <tr>
            <th>상태</th>
            <td>
              <select className="row-input" name="a_state" value={rowData.a_state} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr["a_state"]!=undefined&&
                  xColumnArr.select_arr["a_state"].map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
            <th>승인구분</th>
            <td>
              <select className="row-input" name="a_agree_sort" value={rowData.a_agree_sort} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr["a_agree_sort"]!=undefined&&
                  xColumnArr.select_arr["a_agree_sort"].map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <th>기간종류</th>
            <td>
              <select className="row-input" name="a_period_sort" value={rowData.a_period_sort} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr["a_period_sort"]!=undefined&&
                  xColumnArr.select_arr["a_period_sort"].map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
            <th>난이도</th>
            <td>
              <select className="row-input" name="a_level" value={rowData.a_level} onChange={handleInputChange}>
                <option value="">선택없음</option>
                {
                  xColumnArr.select_arr["a_level"]!=undefined&&
                  xColumnArr.select_arr["a_level"].map((item:any,idx:number)=>{
                    return (
                      <option value={item.value} key={idx} >{item.text}</option>
                    );
                  })
                }
              </select>
            </td>
          </tr>
          {rowData.a_period_sort=="period"&&
          <tr>
            <th>시작일</th>
            <td>
              <DatePicker 
                selected={rowData.a_start_date!=""?new Date(rowData.a_start_date):null} 
                onChange={(date:Date) => {
                  onChangeDatePicker("a_start_date",date);
                }}
                locale={ko} 
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputWrite />}
                renderCustomHeader={customHeaderFunc}
              />
            </td>
            <th>종료일</th>
            <td>
              <DatePicker 
                selected={rowData.a_end_date!=""?new Date(rowData.a_end_date):null} 
                onChange={(date:Date) => {
                  onChangeDatePicker("a_end_date",date);
                }}
                locale={ko} 
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputWrite />}
                renderCustomHeader={customHeaderFunc}
              />
            </td>
          </tr>
          }
          <tr>
            <th>강사명</th>
            <td colSpan={3}>
              <input type="text" className="row-input" name="a_teacher_name" value={rowData.a_teacher_name} onChange={handleInputChange} placeholder="강사명" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default WriteArea;
