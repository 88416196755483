import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import search_ico from "@/img/ico/search3.png";

const SearchArea = forwardRef((props:any, ref) => {
  let myProps={
    list_opt:{
      s_title:"",
      s_subject:"",
    },
    list:(inData:any)=>{},
    ...props
  };


  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="s_search_text"){
      return false;
    }
    myProps.list({now_page:1,[name]: value});
  };

  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        평가서검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_title"
            value={myProps.list_opt.s_title} onChange={handleInputChange} placeholder="제목" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_subject"
            value={myProps.list_opt.s_subject} onChange={handleInputChange} placeholder="과목" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_is_use"
            value={myProps.list_opt.s_is_use} onChange={handleInputChange} >
              <option value="">사용여부</option>
              <option value="1">사용</option>
              <option value="empty">미사용</option>
          </select>
        </div>
        
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-yellowish2" onClick={()=>{myProps.list({now_page:1});}} style={{padding:"9px 10px"}} >검색</button>
        </div>
      </div>
    </div>
  );
});

export default SearchArea;