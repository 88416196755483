import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";

const RequestList = forwardRef((props:any, ref) => {
  const myProps={
    "corse_info":null,
    "infoArr":[],
    "list":(inOptObj:any)=>{},
    ...props
  };
  const XColumnArr=getXcolumnJson();
  const [corse_info,set_corse_info]=useState(myProps.corse_info);
  const [infoArr,setInfoArr]=useState(myProps.infoArr);
  const ListComponentRef=useRef<any>(null);
  
  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "corse_info":null,
      "infoArr":[],
      ...inOptObj
    };
    //idx_num
    for(let i=0;i<optObj["infoArr"].length;i++){
      optObj["infoArr"][i]["idx_num"]=i+1;
    }
    setInfoArr(optObj["infoArr"]);
    set_corse_info(optObj["corse_info"]);
    if(ListComponentRef.current){
      ListComponentRef.current.setInfoArr(optObj["infoArr"]);
    }
  };

  useEffect(()=>{
    
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const goAgreeUse=()=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("승인 하시겠습니까?")){
      return false;
    }
    let request_seq_arr=[];
    for(let i=0;i<selected_row.length;i++){
      let row_data=selected_row[i];
      request_seq_arr.push(row_data["a_ymd"]+","+row_data["a_seq"]);
    }

    let agree_form_data={
      "request_seq_arr":request_seq_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/request/agree',agree_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.list({select_cat_seq:corse_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDelete = () => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/request/delete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.list({select_cat_seq:corse_info.a_seq});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const customCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val
    }
    */
   let render_str=params.value;
   if(params.key=="a_is_agree"){
    if(params.value=="1"){
      render_str="예";
    }else{
      render_str="아니오";
    }
   }
    return render_str;
  };

  return (
    <div>
      <div className="btn-box-right mb-1">
        <button className="btn btn-dark" onClick={()=>{goAgreeUse();}}>승인</button>
        <button className="btn btn-dark" onClick={()=>{goDelete();}}>삭제</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        infoArr={infoArr}
        xColumnArr={XColumnArr}
        gridOpt={
          {
            is_add_idx_num:true,
            is_add_checkbox:true,
            width:"100%",
            max_width:1000,
            td_align:"left",//center,right
            table_align:"left",//center,right
            onGridReady:()=>{},
            rowOnClick:(rowData:any,idx:number,e:any)=>{

            },
            customCellRenderer:customCellRenderer
          }
        }
      ></ListComponent>
    </div>
  );
});

export default RequestList;
