import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import VideoUseList from "./area/video_use_list/index";
import DateFunc from '@/lib/lyg/date_func';

function VideoUseDetailPopup(props:any) {
  let myProps:any={
    closePopup:()=>{},
    "stu_seq":"",
    "corse_info":null,
    "use_info":null,
    "refresh":()=>{},
    ...props
  };

  const VideoUseListRef=useRef<any>(null);
  const [detail_arr,set_detail_arr]=useState<any>(myProps.detail_arr);

  useEffect(()=>{
    
    getUseDetailArr();
  },[]);

  const getUseDetailArr=()=>{
    if(myProps.corse_info&&myProps.stu_seq){}else{
      return false;
    }
    let get_form_json={
      "s_corse_seq":myProps.corse_info.a_seq,
      "s_stu_seq":myProps.stu_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/list',get_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_detail_arr(response.data["data"]["info_arr"]);
        if(VideoUseListRef.current){
          VideoUseListRef.current.setInitData({
            "corse_info":myProps.corse_info,
            "detail_arr":response.data["data"]["info_arr"],
          });
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const selectCompleteWatch=()=>{
    let select_video_seq_arr=[];
    if(VideoUseListRef.current){
      select_video_seq_arr=VideoUseListRef.current.get_select_video_seq_arr();
    }
    if(select_video_seq_arr.length==0){
      alert("선택 없음.");
      return false;
    }
    if(myProps.use_info){}else{
      alert("사용정보 없음.");
      return false;
    }
    if(!confirm(select_video_seq_arr+"개를 선택 완료 처리 하시겠습니까?")){
      return false;
    }

    let add_detail_arr:any=[];
    let video_arr=[];
    if(myProps.corse_info.video_arr){
      video_arr=myProps.corse_info.video_arr;
    }
    for(let seq_i=0;seq_i<select_video_seq_arr.length;seq_i++) {
      let video_seq=select_video_seq_arr[seq_i];
      let vidoe_info=null;
      for(let i=0;i<video_arr.length;i++){
        if(video_arr[i].a_seq==video_seq){
          vidoe_info=video_arr[i];
        }
      }
      if(vidoe_info==null){
        continue;
      }
      let detail_row={
        "a_corse_seq":myProps.corse_info.a_seq,
        "a_stu_seq":myProps.use_info.a_stu_seq,
        "a_video_seq":video_seq,
        "a_video_is_complete":"1",
        "a_watch_sec":vidoe_info["a_total_sec"],
        "a_total_sec":vidoe_info["a_total_sec"],
        "a_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
        "a_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      };
      add_detail_arr.push(detail_row);
    }

    if(add_detail_arr.length==0){
      alert("영상매칭이 맞지 않습니다.");
      return false;
    }

    let up_form_json={
      "detail_arr":add_detail_arr,
      "corse_seq":myProps.corse_info.a_seq,
      "stu_seq":myProps.use_info.a_stu_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/save_video_detail',up_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.refresh();
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  const initUseDetail=()=>{
    let select_video_seq_arr=[];
    if(VideoUseListRef.current){
      select_video_seq_arr=VideoUseListRef.current.get_select_video_seq_arr();
    }
    if(select_video_seq_arr.length==0){
      alert("선택 없음.");
      return false;
    }
    if(myProps.use_info){}else{
      alert("사용정보 없음.");
      return false;
    }
    if(!confirm(select_video_seq_arr+"개를 선택 완료 처리 하시겠습니까?")){
      return false;
    }

    let del_detail_arr:any=[];
    let video_arr=[];
    if(myProps.corse_info.video_arr){
      video_arr=myProps.corse_info.video_arr;
    }
    for(let seq_i=0;seq_i<select_video_seq_arr.length;seq_i++) {
      let video_seq=select_video_seq_arr[seq_i];
      let vidoe_info=null;
      for(let i=0;i<video_arr.length;i++){
        if(video_arr[i].a_seq==video_seq){
          vidoe_info=video_arr[i];
        }
      }
      if(vidoe_info==null){
        continue;
      }
      
      for(let i=0;i<detail_arr.length;i++){
        let detail_info=detail_arr[i];
        if(detail_info["a_video_seq"]==video_seq){
          del_detail_arr.push(detail_info);
        }
      }
    }

    if(del_detail_arr.length==0){
      alert("초기화 데이터 없음.");
      return false;
    }
    let detail_pri_arr=[];
    for(let i=0;i<del_detail_arr.length;i++){
      let detail_info=del_detail_arr[i];
      detail_pri_arr.push(
        detail_info["a_corse_seq"]+","+detail_info["a_stu_seq"]+","+detail_info["a_video_seq"]
      );
    }

    let up_form_json={
      "detail_pri_arr":detail_pri_arr,
      "corse_seq":myProps.corse_info.a_seq,
      "stu_seq":myProps.use_info.a_stu_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/del_video_detail',up_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        myProps.refresh();
        myProps.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <div className="view-table-div">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>강의명</th>
              <td colSpan={3}>
                {myProps.corse_info&&myProps.corse_info.a_title}
              </td>
            </tr>
            <tr>
              <th>진행도</th>
              <td>
                {myProps.use_info&&myProps.use_info.a_study_per+" %"}
              </td>
              <th>사용자</th>
              <td>
                {myProps.use_info&&myProps.use_info.a_stu_name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-2">
        <VideoUseList
          ref={VideoUseListRef}
          corse_info={myProps.corse_info}
        ></VideoUseList>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-dark" onClick={()=>{selectCompleteWatch();}}>선택수강완료</button>
        <button className="btn btn-dark" onClick={()=>{initUseDetail();}}>선택수강초기화</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default VideoUseDetailPopup;
