import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from "./area/search";

const RightSmsList = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_date_type":"a_send_date",
      "s_start_date":"",
      "s_end_date":"",
      "s_is_success":"",
      "s_mcomp_seq":user.comp_seq,
      "s_receiver_seq":[],
    },
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list
  }));

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    if(pageData.listOpt.s_mcomp_seq==""){return false;}

    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( () => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const cellClickedListener = (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      
    }
  }

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="a_is_success"){
      if(params.data.a_is_success=="1"){
        render_str=<span style={{color:"blue"}}>예</span>;
      }else{
        render_str=<span style={{color:"red"}}>아니오</span>;
      }
    }
    return render_str;
  }, []);

  return (
    <div>
      <div className="mt-11">
        <SearchArea
          listOpt={pageData.listOpt}
          goDelete={()=>{goDelete();}}
          list={(inData:any)=>{list(inData);}}
        ></SearchArea>
      </div>
      <ListAggrid
      ref={listAggridRef}
      infoArr={infoArr}
      xColumnArr={{...xColumnArr}}
      list={list}
      gridOpt={{
        fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
        is_idx_num:true,
        is_add_checkbox:true,
        floatingFilter:true,
        onGridReady:()=>{
          list({});
        },
        onCellClicked:cellClickedListener,
        cellRenderer:cellRenderer
      }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot} 
        onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
    </div>
  );
});

export default RightSmsList;