import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import DatePicker from "react-datepicker";
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';

function SearchArea(props:any){
  let myProps:any={
    listOpt:{
      s_date_type:"a_date",
      s_start_date:"",
      s_end_date:"",
      s_state:"",
      s_sort:"",
    },
    goDelete:()=>{},
    saveSelectedRow:()=>{},
    list:(listOpt:any)=>{},
    ...props
  };

  const [listOpt,setListOpt]=useState(myProps.listOpt);
  let is_under_width=MyScreenFunc.get_is_under();

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    setListOpt({
      ...listOpt,
      ...{[key]:date_str}
    });
    myProps.list({
      now_page:"1",
      ...{[key]:date_str}
    });
  };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    setListOpt({
      ...listOpt,
      ...{[name]:value}
    });
    myProps.list({
      now_page:"1",
      ...{[name]:value}
    });
  };

  const goDateMonthChange=(sort:any)=>{
    //next,pre
    let selected_date=listOpt.s_start_date;
    if(selected_date==""){
      selected_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let change_date_obj=new Date(selected_date);
    if(sort=="pre"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'month',-1);
    }else if(sort=="next"){
      change_date_obj=DateFunc.get_change_date(new Date(selected_date),'month',+1);
    }
    
    let start_date=DateFunc.get_date_format(change_date_obj,"Y-m-01");
    let end_date=DateFunc.get_date_format(change_date_obj,"Y-m-t");
    setListOpt({
      ...listOpt,
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
    myProps.list({
      now_page:"1",
      ...{
        s_start_date:start_date,
        s_end_date:end_date,
      }
    });
  };

  let right_box_class_name="absolute right-0 top-0 btn-box-left";
  if(is_under_width){
    right_box_class_name="";
  }

  return (
    <div>
      <div className="btn-box-left relative">
        <span className="text-blue-700">
          {listOpt.s_start_date.substring(5,7)} 월
        </span>
        <select className="search-input_select" name="s_date_type" value={listOpt.s_date_type} onChange={handleInputChange} >
          <option value="a_date">청구일</option>
          <option value="a_pay_date">수납일</option>
        </select>
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("pre");}} >◀</button>
        <button className="btn-s btn-dark" onClick={()=>{goDateMonthChange("next");}} >▶</button>
        <span style={{width:100}}>
          <DatePicker 
              selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
              onChange={(date:Date) => {
                onChangeDatePicker("s_start_date",date);
              }}
              locale={ko} 
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputSearch />}
              renderCustomHeader={customHeaderFunc}
            />
          </span>~
          <span style={{width:100}}>
            <DatePicker 
              selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
              onChange={(date:Date) => {
                onChangeDatePicker("s_end_date",date);
              }}
              locale={ko} 
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputSearch />}
              renderCustomHeader={customHeaderFunc}
            />
          </span>
          <select className="search-input_select" name="s_state" value={listOpt.s_state} onChange={handleInputChange} >
            <option value="">수납상태</option>
            <option value="complete">완납</option>
            <option value="unpay">미납</option>
          </select>
          <select className="search-input_select" name="s_sort" value={listOpt.s_sort} onChange={handleInputChange} >
            <option value="">수납구분</option>
            <option value="class">수업</option>
            <option value="etc">기타</option>
          </select>
        <div className={right_box_class_name}>
          <button className="btn btn-dark" onClick={myProps.saveSelectedRow}>저장</button>
          <button className="btn btn-dark ml-1" onClick={myProps.goDelete}>삭제</button>
        </div>
      </div>
      <div className="btn-box-left relative mt-1">
       
      </div>
    </div>
  );
}
export default SearchArea;