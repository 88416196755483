import DateFunc from '@/lib/lyg/date_func';

let default_row_info={
  a_ymd:DateFunc.get_date_format(new Date(),"Ymd"),
  a_seq:"",
  a_stu_seq:"",
  a_stu_name:"",
  a_writer_seq:"",
  a_writer:"",
  a_checker_seq:"",
  a_checker:"",
  a_is_check:"",
  a_check_date:"",
  a_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
  a_subject:"",
  a_progress_main:"",
  a_progress_sub:"",
  a_homework:"",
  a_exam_main_num:"",
  a_exam_sub_num:"",
  a_is_success:"",
  a_success_date:"",
  a_comment:"",
};
export default default_row_info;