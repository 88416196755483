import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SortDRight,{ISortDRight} from '@/pages/comp/basic/sortd/right/index';
import Style from "./css/style.module.css";

function ListPage(){
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const rightRef = useRef<ISortDRight>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_type":"custom",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/sort/sort/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };


  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(rightRef.current){
      let s_par_id=params.data.a_par_id;
      let s_par_col=params.data.a_par_col;
      rightRef.current.list({
        "now_page":1,
        "s_par_id":s_par_id,
        "s_par_col":s_par_col,
      });
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className={Style.left_right_wrap}>
          <div>
            <div className="btn-box-left relative h-8">
              <div className="absolute right-0 top-0 btn-box-right">
                <button className="btn btn-dark" >상세</button>
              </div>
            </div>
            <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num:false,
              is_add_checkbox:false,
              floatingFilter:false,
              onGridReady:()=>{
                list({});
              },
              onCellClicked:cellClickedListener,
              cellRenderer:cellRenderer
            }}
            ></ListAggrid>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
          </div>
          <div>
            <SortDRight ref={rightRef}></SortDRight>
          </div>
        </div>
      </div>
    </CompLayout>
  );
}

export default ListPage;