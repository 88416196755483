import React, { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import { useReactToPrint } from 'react-to-print';
import StuTimeTableArea from "./area/time_table";
import go_down_excel from "./area/excel/down_time_table";

const ViewTimeTableOfStu = forwardRef((props: any, ref) => {
  let myProps = {
    ...props
  };

  const [listOpt, setListOpt] = useState({
    "order_id": "a_order_num",
    "s_lecture_seq": "",
    "s_state": "ing",
    "s_teacher_like": "",
    "s_teacher_seq": "",
    "s_room_seq": "",
    "s_name_like": "",
    "s_addon_time": "1",
    "s_addon_user": "1",
    "s_addon_user_time": "1",
    "s_addon_user_s_state": "ing",
    "s_addon_user_s_user_seq": "empty",
    "s_addon_time_union": "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });

  const [stu_info, set_stu_info] = useState<any>({
    a_seq: "",
    a_user_name: "",
  });
  const [lecture_user_arr, set_lecture_user_arr] = useState([]);
  const [lecture_arr, set_lecture_arr] = useState([]);
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [room_arr, set_room_arr] = useState([]);
  const [is_teacher_nickname, set_is_teacher_nickname] = useState(true);
  const [is_print_land, set_is_print_land] = useState(true);

  const time_table_wrap_ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => time_table_wrap_ref.current,
  });

  useEffect(() => {
    get_teacher_by_ajax();
    get_room_by_ajax();
  }, []);

  const setInitData = (inData: any) => {
    let opt_obj = {
      "stu_info": null,
      ...inData
    };
    set_stu_info(opt_obj["stu_info"]);
    if (!strFunc.is_empty(opt_obj["stu_info"]["a_seq"])) {
      get_user_and_time_by_ajax({ s_addon_user_s_user_seq: opt_obj["stu_info"]["a_seq"] });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const get_user_and_time_by_ajax = (inOptObj: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inOptObj,
    };
    setListOpt(change_list_opt);
    let list_form_json_data: any = {
      "s_stu_seq": change_list_opt["s_addon_user_s_user_seq"]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/get_user_and_time',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_user_arr(response.data["data"]["lecture_user_arr"]);
          let lecture_seq_arr: any = [];
          for (let i = 0; i < response.data["data"]["lecture_user_arr"].length; i++) {
            let row_lecture_user_info = response.data["data"]["lecture_user_arr"][i];
            if (strFunc.str_in_array(row_lecture_user_info["a_lecture_seq"], lecture_seq_arr) == -1) {
              lecture_seq_arr.push(row_lecture_user_info["a_lecture_seq"]);
            }
          }
          if (lecture_seq_arr.length > 0) {
            change_list_opt["s_pri_arr"] = lecture_seq_arr;
            get_lecture_arr_by_ajax(change_list_opt);
          } else {
            set_lecture_arr([]);
          }
        } else {

        }
      });

  };
  const get_lecture_arr_by_ajax = (inOptObj: any) => {
    let list_form_json_data = inOptObj;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_room_by_ajax = () => {
    let list_form_json_data = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onClickPrint = () => {
    if (time_table_wrap_ref.current) {
      handlePrint();
    }
  };

  const go_excel_down_action = () => {
    if (!confirm("엑셀로 받겠습니까?")) {
      return false;
    }
    go_down_excel({
      lecture_arr: lecture_arr,
      teacher_arr: teacher_arr,
      room_arr: room_arr,
      is_teacher_nickname: is_teacher_nickname,
    });
  };

  return (
    <div>
      <div className="my-1 text-center">
        <select value={is_print_land ? "1" : "2"}
          onChange={(e: any) => {
            if (e.target.value == "1") {
              set_is_print_land(true);
            } else {
              set_is_print_land(false);
            }
          }}
          style={{ border: "1px solid #ddd" }}>
          <option value="1">가로</option>
          <option value="2">세로</option>
        </select>
        <button className="btn btn-sky ml-2" onClick={() => { onClickPrint(); }}>프린트</button>
        <button className="btn btn-sky ml-2" onClick={() => { go_excel_down_action(); }}>엑셀다운</button>
      </div>
      <div ref={time_table_wrap_ref} >
        <style type="text/css" media="print">
          {is_print_land ? " @page { size: landscape; }@media print {html,body {width: 290mm;max-height:201mm; }} " : ""}
        </style>
        <div className="ml-2" style={{fontSize:"14px"}}>
          <span style={{color:"#0040ff",fontWeight:"bold"}}>{stu_info["a_user_name"]}</span> 학생 시간표
        </div>
        <div className="mt-1">
          <StuTimeTableArea
            lecture_arr={lecture_arr}
            teacher_arr={teacher_arr}
            room_arr={room_arr}
            is_teacher_nickname={is_teacher_nickname}
          ></StuTimeTableArea>
        </div>
      </div>
    </div>
  );
});
export default ViewTimeTableOfStu;