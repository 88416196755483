import strFunc from "@/lib/lyg/string";
import TableCss from "./css/data_table.module.css";

const DataTableArea = (props:any)=>{
  let myProps:any={
    title:"학습현황",
    chart_data:[
      { name: '완료', value: 0, color: '#3FAC96', state: "complete" },
      { name: '미완료', value: 0, color: '#89D3C4', state: "ready" },
      { name: '하는중', value: 0, color: '#505050', state: "ing" },
      { name: '중지', value: 0, color: '#505050', state: "stop" },
    ],
    ...props
  };

  let getCntOfState=(s_state:string)=>{
    let show_cnt=0;
    let data_len=myProps.chart_data.length;
    for(let i=0;i<data_len;i++){
      if(myProps.chart_data[i]["state"]==s_state){
        show_cnt=strFunc.comma(myProps.chart_data[i]["value"]);
      }
    }
    return show_cnt;
  };
  
  return (
    <div className="px-7">
      <h4 className={TableCss.title_wrap} >
        {myProps.title+" >"}
      </h4>
      <div className="basic_list_div">
        <table>
          <colgroup>
            <col width="33%"></col>
            <col width="33%"></col>
            <col width="33%"></col>
          </colgroup>
          <thead>
            <tr>
              <td className="text-center p-2">완료</td>
              <td className="text-center">미완료</td>
              <td className="text-center">하는중</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center p-2">
                <span className={TableCss.cnt_span} style={{color:"#3FAC96"}}>
                  {getCntOfState("complete")}
                </span>
              </td>
              <td className="text-center">
                <span className={TableCss.cnt_span} style={{color:"#89D3C4"}}>
                  {getCntOfState("ready")}
                </span>
              </td>
              <td className="text-center">
                <span className={TableCss.cnt_span} style={{color:"#505050"}}>
                  {getCntOfState("ing")}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DataTableArea;