import FileSlideArea from "@/pages/comp/basic/file/common/file_slide";

function ViewFileSlidePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    file_info_arr: [],
    select_file_idx:0,
    ...props
  };

  return (
    <div>
      <FileSlideArea
        file_info_arr={myProps.file_info_arr}
        select_file_idx={myProps.select_file_idx}
      ></FileSlideArea>
      <div className="mt-1 text-center">
        <button className="btn btn-dark" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default ViewFileSlidePopup;