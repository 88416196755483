import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import search_ico from "@/img/ico/search.png";

const SearchArea = forwardRef((props:any, ref) => {
  const [listOpt,setListOpt] = useState(props.listOpt);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({now_page:1,[name]: value});
  };
  
  const goSearch=()=>{
    props.list({...listOpt});
  };


  let oder_id_select_arr=[
    {"text":"등록순","value":"a_create_date"},
    {"text":"등록역순","value":"a_create_date DESC"},
    {"text":"이름순","value":"a_name"},
    {"text":"이름역순","value":"a_name DESC"},
  ];

  props.xColumnArr.select_arr.a_stu_grade=[
    {text: '1세', value: '1'},
    {text: '2세', value: '2'},
    {text: '3세', value: '3'},
    {text: '4세', value: '4'},
    {text: '5세', value: '5'},
    {text: '6세', value: '6'},
    {text: '7세', value: '7'},
    {text: '초1', value: '8'},
    {text: '초2', value: '9'},
    {text: '초3', value: '10'},
    {text: '초4', value: '11'},
    {text: '초5', value: '12'},
    {text: '초6', value: '13'},
    {text: '중1', value: '14'},
    {text: '중2', value: '15'},
    {text: '중3', value: '16'},
    {text: '고1', value: '17'},
    {text: '고2', value: '18'},
    {text: '고3', value: '19'},
    {text: '성인', value: '20'}
  ];

  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        클래스검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap">
          <input type="text" className="search_item_input" name="s_stu_name_like"
            value={listOpt.s_stu_name_like} onChange={handleInputChange} placeholder="이름" />
        </div>
        <div className="basic_search_item_input_wrap">
          <input type="text" className="search_item_input" name="s_stu_school_name_like"
            value={listOpt.s_stu_school_name_like} onChange={handleInputChange} placeholder="학교" />
        </div>
        <div className="basic_search_item_input_wrap">
          <input type="text" className="search_item_input" name="s_class_name_like"
            value={listOpt.s_class_name_like} onChange={handleInputChange} placeholder="클래스명" />
        </div>
        <div className="basic_search_item_input_wrap">
          <select className="search_item_input_select" name="s_stu_stu_grade" value={listOpt.s_stu_stu_grade} onChange={handleInputChange}>
          <option value="">학년</option>
          {
            props.xColumnArr.select_arr.a_stu_grade.map((item:any,idx:number)=>{
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
          </select>
        </div>
        <div className="basic_search_item_input_wrap">
          <select className="search_item_input_select" name="s_is_supply_money" value={listOpt.s_is_supply_money} onChange={handleInputChange}>
            <option value="">청구여부</option>
            <option value="1">예</option>
            <option value="empty">아니오</option>
          </select>
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-dark" onClick={()=>{props.list({now_page:1});}} style={{padding:"9px 10px"}} >검색</button>
        </div>
      </div>
    </div>
  );
});

export default SearchArea;