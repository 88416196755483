import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import CardBox from "./card_box";
import { useState } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import CounselWritePopup from "@/pages/comp/basic/counsel/popup/write";

function ListCardArea(props:any){
   let myProps={
    info_arr:[],
    show_detail_view:(inData:any)=>{},
    xColumnArr:{},
    list:(inData:any)=>{},
    ...props
  };

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"counsel_write",
    isUpdate:false,
    rowData:{},
    stu_seq:"",
    stu_name:"",
    select_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    title:"팝업",
    width:"800px",
    height:"100%",
  });

  const openCounselWritePopup=(inData:any)=>{
    let opt_obj={
      "stu_info":{},
      "select_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      "isUpdate":false,
      "counsel_todo_pri_val":"",
      ...inData,
    };
    
    let pop_title="상담등록";
    if(opt_obj["isUpdate"]){
      pop_title="상담수정";
    }
    let row_data:any={};
    if(!strFunc.is_empty(opt_obj["counsel_todo_pri_val"])){
      let row_pri_val_arr=opt_obj["counsel_todo_pri_val"].split(",");
      if(row_pri_val_arr.length==2){
        row_data["a_ymd"]=row_pri_val_arr[0];
        row_data["a_seq"]=row_pri_val_arr[1];
      }
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":opt_obj["isUpdate"],
      rowData:row_data,
      stu_seq:opt_obj["stu_info"]["a_seq"],
      stu_name:opt_obj["stu_info"]["a_user_name"],
      select_date:opt_obj["select_date"],
      "title":pop_title,
      "sort":"counsel_write",
    });
  };

  const get_card_box_tags=()=>{
    let card_box_tags=myProps.info_arr.map((item:any,idx:number)=>{
      return (
        <CardBox
          key={idx}
          row_num={idx}
          info={item}
          Style={Style}
          xColumnArr={myProps.xColumnArr}
          show_detail_view={myProps.show_detail_view}
          openCounselWritePopup={openCounselWritePopup}
        ></CardBox>
      );
    });
    if(strFunc.is_empty(card_box_tags)){
      card_box_tags=(
        <div className={Style.empty_box}>
          내용이 없습니다.
        </div>
      );
    }
    return card_box_tags;
  };
  
  return (
  <div>
    <div className={Style.card_list_wrap}>
      {get_card_box_tags()}
    </div>
    {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} y={"0px"}>
        {popupData.sort=="counsel_write"&&
        <CounselWritePopup
          isUpdate={popupData.isUpdate}
          rowData={popupData.rowData}
          stu_seq={popupData.stu_seq}
          stu_name={popupData.stu_name}
          select_date={popupData.select_date}
          closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
          list={myProps.list}
        ></CounselWritePopup>
        }
      </LayerPopup>
      }
  </div>
  );
};
export default ListCardArea;