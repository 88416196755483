import * as XLSX from "xlsx-js-style";
import style_data from "./style/index";
import LectureTimeFunc from "@/pages/comp/edu/lecture/view_time_table/list_one_day/area/lecture_time_table/func/lecture_time_func";
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const go_down_excel = (inData: any) => {
  let opt_obj = {
    lecture_arr: [],
    teacher_arr: [],
    room_arr: [],
    is_teacher_nickname: false,
    is_show_th_stu_len: false,
    stu_grade_sort: "",
    split_height:35,
    ...inData,
  };
  const wb = XLSX.utils.book_new();

  let table_option = LectureTimeFunc.get_table_option_by_lecture_arr({
    lecture_arr: opt_obj.lecture_arr,
    teacher_arr: opt_obj.teacher_arr,
    room_arr: opt_obj.room_arr,
    is_teacher_nickname: opt_obj.is_teacher_nickname,
    is_show_th_stu_len: opt_obj.is_show_th_stu_len,
  });

  let data: any = [];

  //넓이
  var wscols = [
    { wpx: 100 },
  ];
  //높이
  var wsrows = [{ hpx: 30 }];

  //헤더
  let header_row_data: any = ["시간"];
  let merge_cell_arr: any = [];//{x:0,y:0,col_span:1,row_span:1}
  for (let i = 0; i < table_option.table_th_arr.length; i++) {
    let th_info = table_option.table_th_arr[i];
    if (th_info.is_view == false) {
      wscols.push({ wpx: 200 });
      header_row_data.push("");
      continue;
    }
    wscols.push({ wpx: 200 });
    header_row_data.push(th_info.title);
    if (th_info.col_span >= 2) {
      merge_cell_arr.push({ x: header_row_data.length-1, y: 0, col_span: th_info.col_span,row_span:1 });
    }
  }
  let abc_arr = strFunc.get_abc_arr(header_row_data.length);
  data.push(header_row_data);

  //시간 + 데이터
  let split_time_arr = table_option.split_time_m_arr;
  let split_time_arr_len = split_time_arr.length;
  for (let i = 0; i < split_time_arr_len; i++) {
    let split_time = split_time_arr[i];
    let rowt_start_json = strFunc.secondsToTimeJson(split_time * 60);
    let rowt_end_json = strFunc.secondsToTimeJson((split_time + table_option.split_term) * 60);
    let row_data = [
      {
        v: rowt_start_json["h"] + ":" + rowt_start_json["i"] + "~" + rowt_end_json["h"] + ":" + rowt_end_json["i"],
        t: "s",
        s: { alignment: { vertical: "center", horizontal: "center" } },
      }
    ];
    let td_data_json_rs: any = get_td_data_arr({
      ...opt_obj,
      table_option: table_option,
      row_time_min: split_time,
      row_num: i,
      pre_row_num:row_data.length,
    });
    let td_data_arr = td_data_json_rs.td_data_arr;

    data.push([
      ...row_data,
      ...td_data_arr
    ]);
    wsrows.push({ hpx: opt_obj["split_height"] });

    merge_cell_arr=[
      ...merge_cell_arr,
      ...td_data_json_rs.merge_cell_arr
    ];
  }

  // STEP 3: Create worksheet with rows; Add worksheet to workbook
  const ws = XLSX.utils.aoa_to_sheet(data);//[row,row2]
  XLSX.utils.book_append_sheet(wb, ws, "time_table");

  //넓이
  ws['!cols'] = wscols;
  //높이
  ws['!rows'] = wsrows;

  //합치기
  let merge_cell_data_arr = [];
  for (let i = 0; i < merge_cell_arr.length; i++) {
    let merge_cell = merge_cell_arr[i];
    if (merge_cell["row_span"] >= 2||merge_cell["col_span"] >= 2) {
      let plus_row_num = merge_cell.row_span - 1;
      let plus_x_num = merge_cell.col_span - 1;
      merge_cell_data_arr.push({
        s: { r: merge_cell.y, c: merge_cell.x },
        e: { r: merge_cell.y + plus_row_num, c: merge_cell.x+plus_x_num },
      });
    }
  }
  if (merge_cell_data_arr.length > 0) {
    ws["!merges"] = merge_cell_data_arr;
  }

  //스타일
  let all_row_i = data.length;
  for (let row_i = 0; row_i < all_row_i; row_i++) {
    for (let i = 0; i < abc_arr.length; i++) {
      let cell_style: any = {
        border: style_data.border_1,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true
        },
      };
      if (row_i == 0 || i == 0) {
        cell_style["font"] = {
          "bold": true
        };
      } else {
        cell_style["fill"] = { fgColor: { rgb: "EFEFEF" } };
        for (let j = 0; j < merge_cell_arr.length; j++) {
          let merge_cell = merge_cell_arr[j];
          if (merge_cell.x == i && merge_cell.y == row_i) {
            cell_style["fill"] = { fgColor: { rgb: "FFFFFF" } };
          }
        }
      }
      if (ws[abc_arr[i] + (row_i + 1)]) {
        ws[abc_arr[i] + (row_i + 1)].s = cell_style;
      }
    }
  }

  // STEP 4: Write Excel file to browser
  XLSX.writeFile(wb, "time_table_excel.xlsx");
};

const get_td_data_arr = (inData: any) => {
  let opt_obj = {
    row_num: 0,
    pre_row_num:0,
    row_time_min: 0,
    table_option: {},
    lecture_arr: [],
    teacher_arr: [],
    room_arr: [],
    stu_grade_sort: "",
    ...inData
  };

  let td_data_arr: any = [];
  let merge_cell_arr: any = [];
  if (opt_obj.table_option.table_tr_td_arr[opt_obj.row_num] == undefined) {
    return {
      td_data_arr: td_data_arr,
      merge_cell_arr: merge_cell_arr
    };
  }
  let tr_td_arr = opt_obj.table_option.table_tr_td_arr[opt_obj.row_num];
  for (let tr_td_idx = 0; tr_td_idx < tr_td_arr.length; tr_td_idx++) {
    let tr_td_row = tr_td_arr[tr_td_idx];
    if (tr_td_row["is_view"] == false) {
      td_data_arr.push("");
      continue;
    }
    let lecture_info = null;
    if (tr_td_row["td_data"]) {
      for (let i = 0; i < opt_obj.lecture_arr.length; i++) {
        if (tr_td_row["td_data"]["lecture_seq"] == opt_obj.lecture_arr[i]["a_seq"]) {
          lecture_info = opt_obj.lecture_arr[i];
          let content_str=get_td_content_str({
            row_num:opt_obj.row_num,
            lecture_info: lecture_info,
            tr_td_row: tr_td_row,
            stu_grade_sort: opt_obj.stu_grade_sort,
          });
          td_data_arr.push({v:content_str,t:"s",s: { alignment: { wrapText: true } } });
          
          if(tr_td_row["col_span"]>=2||tr_td_row["row_span"]>=2){
            merge_cell_arr.push({
              x: td_data_arr.length,
              y: opt_obj.row_num+opt_obj.pre_row_num,
              col_span:tr_td_row["col_span"],
              row_span:tr_td_row["row_span"],
            });
          }
        }
      }
    }else{
      td_data_arr.push("");
    }
  }

  return {
    td_data_arr: td_data_arr,
    merge_cell_arr: merge_cell_arr
  };
};

const get_td_content_str=(inData:any)=>{
  let opt_obj={
    row_num:0,
    lecture_info: {},
    tr_td_row: {},
    stu_grade_sort: "",
    ...inData
  };
  let content_str="";

  let lecture_info = opt_obj.lecture_info;
  let tr_td_row = opt_obj.tr_td_row;
  let td_data = null;
  let stu_union_time_arr: any = [];
  if (tr_td_row.td_data) {
    td_data = tr_td_row.td_data;
    stu_union_time_arr = td_data.stu_union_time_arr;
  }

  let select_option_arr: any = [];
  if (opt_obj.stu_grade_sort != "") {
    select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': opt_obj.stu_grade_sort });
  }

  let stu_name_arr=[];
  for(let i=0;i<stu_union_time_arr.length;i++){
    let item=stu_union_time_arr[i];
    let grade_str = item.a_stu_grade;
    for (let i = 0; i < select_option_arr.length; i++) {
      if (select_option_arr[i]["value"] == grade_str) {
        grade_str = select_option_arr[i]["text"];
      }
    }
    let stu_name=item.a_stu_name;
    if(opt_obj.stu_grade_sort!=""){
      stu_name+=" ("+grade_str+")";
    }
    stu_name_arr.push(stu_name);
  }

  //요일정보
  let day_num_arr = [];
  if (td_data) {
    day_num_arr = td_data.day_num_arr;
  }
  let week_name_arr = ["일", "월", "화", "수", "목", "금", "토"];
  let row_week_name_arr = [];
  day_num_arr.sort();
  for (let i = 0; i < day_num_arr.length; i++) {
    row_week_name_arr.push(
      week_name_arr[day_num_arr[i]]
    );
  }
  let row_week_name_str = row_week_name_arr.join(",");

  let td_title_str = "";
  if (td_data) {
    td_title_str = td_data.name;
    if (lecture_info) {
      if (lecture_info["a_main_teacher"] != td_data.teacher_name) {
        td_title_str += " " + td_data.teacher_name;
      }
      if (lecture_info["a_room_name"] != td_data.room_name) {
        td_title_str += " " + td_data.room_name;
      }
    }

    td_title_str += "(" + row_week_name_str + ")";
    if (stu_union_time_arr.length > 0) {
      td_title_str += " " + stu_union_time_arr.length + "명";
    }
  }

  content_str=td_title_str;
  content_str+="\n";
  content_str+=stu_name_arr.join(" ");

  return content_str;
};

export default go_down_excel;