import { useEffect,useState } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function SearchEval(props: any) {
  let myProps = {
    listOpt: {
      s_addon_eval_s_form_seq: "",
      s_addon_eval_s_start_date: "",
      s_addon_eval_s_end_date: "",
      s_is_except_eval_user: "",
    },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  const [form_list, set_form_list] = useState<any>([]);

  useEffect(() => {
    
    get_eval_forms_by_ajax();
  }, []);

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ now_page: 1, [key]: date_str });
  };

  const get_eval_forms_by_ajax = () => {
    let s_form_json = {
      "order_id": "a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval_form/list', s_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_form_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      개별검사:
      <div style={{ display: "inline-block", width: 90 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={listOpt.s_addon_eval_s_start_date != "" ? new Date(listOpt.s_addon_eval_s_start_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_addon_eval_s_start_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
      ~
      <div style={{ display: "inline-block", width: 90 }}>
        <DatePicker
          wrapperClassName="pl-1"
          selected={listOpt.s_addon_eval_s_end_date != "" ? new Date(listOpt.s_addon_eval_s_end_date) : null}
          onChange={(date: Date) => {
            onChangeDatePicker("s_addon_eval_s_end_date", date);
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>

      <span className="ml-2">
        <label>
          <input type="checkbox" checked={(myProps.listOpt.s_is_except_eval_user=="1"?true:false)} onChange={(e:any)=>{
            let s_is_except_eval_user=(e.target.checked?"1":"");
            myProps.list({ now_page: 1, s_is_except_eval_user: s_is_except_eval_user});
          }} />
          기존작성유저제외
        </label>
      </span>

      <span className="ml-2">
        <select className="search_item_input_select" value={myProps.listOpt.s_addon_eval_s_form_seq} onChange={(e:any)=>{
          myProps.list({ now_page: 1, s_addon_eval_s_form_seq: e.target.value});
        }}  style={{border:"1px solid #ddd"}}>
          <option value="">폼선택</option>
          {
            form_list.map((item:any,idx:number)=>{
              return (
                <option value={item["a_seq"]} key={idx}>{item["a_title"]}</option>
              );
            })
          }
        </select>
      </span>
    </div>
  );
}
export default SearchEval;