import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import getXcolumnJson from "./xcolumn/list";
import search_ico_img from "@/img/ico/search2.png";
import strFunc from '@/lib/lyg/string';
import ClassListArea from "./area/class_list";

const ClassStuList = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => { },
    ...props
  };
  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      ...inOptObj
    };
  };

  const xColumnArr = getXcolumnJson();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      "s_stu_state": "now",
      s_addon_class_user: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
      s_addon_counsel_todo_tot: "1",
      s_addon_counsel_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
      s_addon_counsel_end_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', +1), "Y-m-t"),
    },
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [select_user_seq_arr, set_select_user_seq_arr] = useState<any>([]);

  const [classArr, setClassArr] = useState<any>([]);

  useEffect(() => {
    
    getClassFrontArr();
    list({});
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let tmpPageData = { ...pageData };
    tmpPageData.listOpt[name] = value;
    setPageData({
      ...tmpPageData
    });
    if (name == "s_search_text") {
      return false;
    }
    list({ [name]: value });
  };

  const getClassFrontArr = () => {
    let class_list_form = {
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/list', class_list_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setClassArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const setSelectFalseAll = () => {
    set_select_user_seq_arr([]);
    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": "",
      "s_stu_info_arr": [],
    });
  };

  const removeSelectStu = (stu_seq: any) => {
    set_select_user_seq_arr([]);
    setTimeout(() => {
      onSelectionChanged({
        select_user_seq_arr: []
      });
    }, 100);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll, removeSelectStu
  }));


  const on_click_user_row = (inData: any) => {
    let opt_obj = {
      "user_info": null,
      ...inData
    };

    set_select_user_seq_arr([opt_obj["user_info"]["a_seq"]]);
    onSelectionChanged({
      select_user_seq_arr: [opt_obj["user_info"]["a_seq"]]
    });
  };

  const onSelectionChanged = (inData: any) => {
    let opt_obj = {
      select_user_seq_arr: select_user_seq_arr,
      ...inData
    };
    let selected_row: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      if (strFunc.str_in_array(info["a_seq"], opt_obj.select_user_seq_arr) != -1) {
        selected_row.push(info);
      }
    }
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    myProps.list({
      "s_class_seq_arr": [],
      "s_stu_user_seq": select_seq_arr,
      "s_stu_info_arr": selected_row,
    });
  };

  return (
    <div>
      <div className="relative" >
        <div className="relative p-3 text-center" style={{ lineHeight: "20px" }}>
          <input type="text" className="search-input" name="s_search_text"
            style={{ width: 80, padding: 4 }} placeholder="이름" value={pageData.listOpt.s_search_text}
            onChange={handleInputChange} onKeyUp={(e: any) => { list({ "now_page": 1 }); }} />
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = pageData.listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {pageData.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <button onClick={() => { list({ "now_page": 1 }); }} style={{ background: "#3FAC96", padding: 4, borderTop: "1px solid #3FAC96" }} >
            <img src={search_ico_img} style={{ height: 20 }} />
          </button>
        </div>
      </div>
      <ClassListArea
        on_click_user_row={on_click_user_row}
        class_arr={classArr}
        user_info_arr={infoArr}
        select_user_seq_arr={select_user_seq_arr}
      ></ClassListArea>
    </div>
  );
});

export default ClassStuList;
