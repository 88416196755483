export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"h_video",name:"내강의",url:"/comp_stu/video/my_list",msg:"",isShow:true},
  // {key:"h_sche",name:"스케줄",url:"/comp_stu/sche/my_sche",msg:"",isShow:true},
  {key:"h_setting",name:"내정보",url:"/comp_stu/setting/mypage",msg:"",isShow:true},

  {key:"h_subs",name:"서브",url:"/comp_stu/video/my_list",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"basic_main",name:"메인",url:"/comp_stu",msg:"",hmenu:"h_subs",isShow:false},

  {key:"video_my_list",name:"내강의",url:"/comp_stu/video/my_list",msg:"",hmenu:"h_subs",isShow:true},
  {key:"video_my_list_view",name:"내강의보기",url:"/comp_stu/video/my_list_view",msg:"",hmenu:"h_subs",isShow:false},
  {key:"coolen_move",name:"브릿지영어",url:"/comp_stu/coolen/move",msg:"",hmenu:"h_subs",isShow:true},
  {key:"video_corse_list",name:"모든과정",url:"/comp_stu/video/corse_list",msg:"",hmenu:"h_subs",isShow:false},
  {key:"todo_list",name:"맞춤플랜",url:"/comp_stu/plan/todo_list",msg:"",hmenu:"h_subs",isShow:true},
  {key:"todo_list_view",name:"맞춤플랜view",url:"/comp_stu/plan/todo_list_view",msg:"",hmenu:"h_subs",isShow:false},
  {key:"todo_list_view_video",name:"맞춤플랜video",url:"/comp_stu/plan/todo_list_view_video",msg:"",hmenu:"h_subs",isShow:false},
  
  {key:"video_my_list",name:"내스케줄",url:"/comp_stu/sche/my_sche",msg:"",hmenu:"h_subs",isShow:false},
  {key:"mypage",name:"마이페이지",url:"/comp_stu/setting/mypage",msg:"",hmenu:"h_subs",isShow:false},
];