import CompLayout from "@/pcomponents/comp/layout/layout";
import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";

function VideoUseDetailListPage() {
  let user = useSelector((state: any) => state.user);
  const findStudentListRef = useRef<any>(null);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
    "s_stu_user_seq": [],
    "s_is_success": "",
    "s_addon_user": "1",
    "s_addon_corse": "1",
    "s_addon_video":"1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  const list = (inData: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inData
    };
    setListOpt(change_list_opt);
    let list_form_json = change_list_opt;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/use_det/list', list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          console.log(response.data["data"]["info_arr"]);
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/video/use_det/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if(key=="row_view_video_core_name"){
      render_str="";
      if(params.data.corse_info){
        render_str=params.data.corse_info["a_title"];
      }
    }else if(key=="row_view_video_name"){
      render_str="";
      if(params.data.video_info){
        render_str=params.data.video_info["a_title"];
      }
    }else if(key=="row_view_stu_name"){
      render_str="";
      if(params.data.stu_info){
        render_str=params.data.stu_info["a_user_name"];
      }
    }else if(key=="row_view_complete"){
      if(params.data.a_video_is_complete=="1"){
        render_str=(<span style={{color:"blue"}}>예</span>);
      }else{
        render_str="아니오";
      }
    }else if(key=="row_view_per"){
      let total_sec=parseInt(params.data.a_total_sec);
      let watch_sec=parseInt(params.data.a_watch_sec);
      let per_num=0;
      if(total_sec!=0&&watch_sec!=0){
        per_num=strFunc.comma(Math.round((watch_sec/total_sec)*100));
      }
      if(params.data.a_video_is_complete=="1"){
        per_num=100;
      }
      render_str=per_num+"%";
    }

    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{ minWidth: 220 }}>
            <FindStudentList
              ref={findStudentListRef}
              list={(inData: any) => {
                list(inData);
              }}
            ></FindStudentList>
          </div>
          <div className="grow" style={{ minWidth: 400 }}>
            <SearchArea
              xColumnArr={xColumnArr}
              listOpt={listOpt}
              list={list}
            ></SearchArea>
            <ListAggrid
              ref={listAggridRef}
              infoArr={infoArr}
              xColumnArr={{ ...xColumnArr }}
              list={list}
              gridOpt={{
                fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                is_idx_num: false,
                is_add_checkbox: true,
                floatingFilter: true,
                onGridReady: () => {
                  list({});
                },
                onCellClicked: cellClickedListener,
                cellRenderer: cellRenderer
              }}
            ></ListAggrid>
            <Paging now_page={listOpt.now_page}
              num_per_page={listOpt.num_per_page}
              total_rec={countInfo.tot}
              onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
              onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
          </div>
        </div>
      </div>
    </CompLayout >
  );
};
export default VideoUseDetailListPage;