import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";

const CorseListArea = forwardRef((props:any, ref) => {
  const myProps={
    onCorseSelect:(inOptObj:any)=>{},
    ...props
  };
  
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_title_like":"",
      "s_category_seq":"",
      "s_addon_category":"1",
      "s_addon_section":"1",
      "s_addon_video":"1",
    }
  });

  const [info_arr,set_info_arr]=useState<any>([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [categoryInfoArr, setCategoryInfoArr] = useState([]);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "info_arr":[],
      "countInfo":{"tot":0},
      ...inOptObj
    };
    set_info_arr(optObj.info_arr);
    setCountInfo(optObj.countInfo);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,
  }));

  useEffect(()=>{
    
    list_category();
  },[]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_info_arr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const list_category = ()=>{
    let category_form_json={
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',category_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setCategoryInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_name"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    myProps.onCorseSelect(selected_row);
  };

  return (
    <div>
      <SearchArea
        list_opt={pageData.listOpt}
        list={list}
        categoryInfoArr={categoryInfoArr}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={info_arr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:false,
          floatingFilter:false,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer,
          onSelectionChanged:onSelectionChanged
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
            num_per_page={pageData.listOpt.num_per_page}
            total_rec={countInfo.tot} 
            onChangePage={(now_page:number)=>{list({now_page:now_page});}}
            onChangeNumPerPage={(num_per_page:number)=>{list({num_per_page:num_per_page,now_page:1});}}></Paging>
    </div>
  );
});

export default CorseListArea;
