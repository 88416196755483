import SelectTab from "@/pcomponents/common/crud/write/select_tab";

function SearchArea(props:any){
   let myProps={
    listOpt:{
      "s_teacher_like":"",
      "s_name_like":"",
      "s_day_num":[],
    },
    list:(inData:any)=>{},
    go_down_excel:()=>{},
    ...props
  };

  const on_change_search_input=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;

    let search_list_opt={
      [name]:value
    };
    myProps.list(search_list_opt);
  };

  let select_arr_week_days=[
    {"value":"","text":"전체"},
    {"value":"0","text":"일"},
    {"value":"1","text":"월"},
    {"value":"2","text":"화"},
    {"value":"3","text":"수"},
    {"value":"4","text":"목"},
    {"value":"5","text":"금"},
    {"value":"6","text":"토"},
  ];
  
  return (
  <div>
    <div className="search-box">
      <input type="text" className="search-input" name="s_name_like" 
        onChange={on_change_search_input} placeholder="제목" style={{width:80}} />
      <input type="text" className="search-input" name="s_teacher_like" 
        onChange={on_change_search_input} placeholder="선생님" style={{width:80}} />
      <SelectTab
        select_arr={select_arr_week_days}
        value={myProps.listOpt.s_day_num.length==0?"":myProps.listOpt.s_day_num[0]}
        on_select={(value:any)=>{
          if(value==""){
            myProps.list({
              s_day_num:[]
            });
            return false;
          }
          myProps.list({
            s_day_num:[value]
          });
        }}
      ></SelectTab>
      <button className="btn btn-dark" onClick={()=>{myProps.list({});}} >검색</button>
    </div>
    <div className="btn-box-center mb-2">
      <button className="btn-m btn-sky" onClick={()=>{myProps.go_down_excel();}} >엑셀다운</button>
    </div>
  </div>
  );
};
export default SearchArea;