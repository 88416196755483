//import YouTubeArea from "@/pcomponents/common/content/video/player/youtube";
import YouTube from 'react-youtube';
import { useState } from 'react';
import strFunc from '@/lib/lyg/string';

function YouTubeArea(props:any){
   let myProps={
    video_url:"",
    init_seek_data:{start:0,end:1},
    set_is_play:(inData:any)=>{},
    ...props
  };

  const [player,setPlayer]=useState<any>(null);

  const onReadyVideo=(event:any)=>{
    //console.log("Ready");
    setPlayer(event.target);
    if(event.target){
      if(!strFunc.is_empty(myProps.init_seek_data.start)){
        event.target.seekTo(parseInt(strFunc.uncomma(myProps.init_seek_data.start)));
        event.target.pauseVideo();
      }
    }
  };
  const onPlay=(event:any)=>{
    //console.log("onPlay");
    myProps.set_is_play(true);
    //다른동영상 재상 방지
    if(player){
      let play_video_code=player.getVideoUrl();
      let now_video_id:any=strFunc.get_youtube_id_by_url(play_video_code);
      let this_video_id:any="";
      this_video_id=strFunc.get_youtube_id_by_url(myProps.video_url);
      if(now_video_id!=this_video_id){
        player.loadVideoById(this_video_id,0, "large");
        player.pauseVideo();
      }
    }
  };
  const onPause=(event:any)=>{
    //console.log("onPause");
    myProps.set_is_play(false);
  };
  const onEnd=(event:any)=>{
    //console.log("onEnd");
    if(player){
      let current_time=player.getCurrentTime();
      player.seekTo(current_time-2);
      player.pauseVideo();
    }
  };
  
  let video_id:any="";
  if(!strFunc.is_empty(myProps.video_url)){
    video_id=strFunc.get_youtube_id_by_url(myProps.video_url);
  }
  const video_opts = {
    height: '500',
    width: '100%',
    playerVars: {
      autoplay: 0,
      //start:myProps.init_seek_data.start,
    },
  };

  return (
  <div>
    {video_id!=""&&
      <YouTube videoId={video_id} 
        opts={video_opts} 
        onReady={onReadyVideo}
        onPlay={onPlay} 
        onPause={onPause} 
        onEnd={onEnd} 
      />
    }
  </div>
  );
};
export default YouTubeArea;