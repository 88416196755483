import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"en_class_right_user_list",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"100","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"100","is_show":"1"},
      {"key":"a_school_name","name":"학교","width":"100","is_show":"1"},
      {"key":"a_user_id","name":"아이디","width":"100","is_show":"1"},
      {"key":"a_stu_state","name":"상태","width":"70","is_show":"1"},
      {"key":"row_view_coolen","name":"페어링","width":"80","is_show":"1"},
      {"key":"row_view_coolen_allow","name":"승인","width":"80","is_show":"1"},
      {"key":"row_view_coolenb","name":"뱅크","width":"80","is_show":"1"},
      {"key":"row_view_coolenb_allow","name":"승인","width":"80","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'a_stu_state, a_stu_grade, a_user_name',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_name_like":"",
      "s_stu_name_like":"",
      "s_stu_phone_like":"",
      "s_stu_school_name_like":"",
      "s_stu_stu_grade":"",
      "s_teacher_like":"",
      "s_is_supply_money":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;