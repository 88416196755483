import TStuWriteMultyArea from "@/pages/comp/tea/tstu_todo/common/write_multy";

function TStuTodoWriteMultyPopup(props: any) {
  let myProps = {
    infoArr:[],
    closePopup: () => { },
    callBackData: (data: any) => { },
    ...props
  };

  return (
    <div>
      <TStuWriteMultyArea
        infoArr={myProps.infoArr}
        close={myProps.closePopup}
        callBackData={myProps.callBackData}
      ></TStuWriteMultyArea>
    </div>
  );
};
export default TStuTodoWriteMultyPopup;