import {
  createBrowserRouter,
} from "react-router-dom";

import mainRouterArr from "./main/index";
import compRouterArr from "./comp/index";
import compStuRouterArr from "./comp_stu/index";
import compParRouterArr from "./comp_par/index";
import homeRouterArr from "./home/index";
import testRouterArr from "./test/index";

const router = createBrowserRouter([
  ...mainRouterArr,
  ...compRouterArr,
  ...compStuRouterArr,
  ...compParRouterArr,
  ...testRouterArr,
  ...homeRouterArr,
  {
    path: "*",
    element: <div>404 Not Found!!</div>,
  },
]);

export default router;