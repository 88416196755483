import { useState,forwardRef,useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const ViewFrameContent = forwardRef((props:any, ref) => {
  const myProps={
    list:(inOptObj:any)=>{},
    isUpdate:false,
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const pageData={
    "write_btn_text":"등록",
    "confirm_msg":"등록 하시겠습니까?",
  };
  const [isUpdate,setIsUpdate]=useState(myProps.isUpdate);
  if(isUpdate){
    pageData["write_btn_text"]="수정";
    pageData["confirm_msg"]="수정 하시겠습니까?";
  }
  const init_data={
    "a_seq":"",
    "a_is_receive":"",
    "a_sort":"",
    "a_send_date":"",
    "a_read_date":"",
    "a_is_read":"",
    "a_is_send_success":"",
    "a_title":"",
    "a_content":"",

    "sender_info":null,
    "receiver_info":null,
  };
  const [rowData,setRowData]=useState<any>({
   ...init_data
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "row_data":{},
      "isUpdate":false,
      ...inOptObj
    };
    setRowData({
      ...init_data,
      ...optObj.row_data
    });
    setIsUpdate(optObj.isUpdate);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowData,setIsUpdate,setInitData
  }));


  return (
    <div>
      <h4 style={{height:38,lineHeight:"38px"}}>내용보기</h4>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>구분</th>
              <td>
                {rowData.a_is_receive=="1"?"받음":"보냄"}
              </td>
              <th>전송일</th>
              <td>
                {rowData.a_send_date}
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                {rowData.a_send_date}
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan={3}>
                {
                  rowData.a_content && rowData.a_content.split('\n').map( (line:string,idx:number) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>보낸이</th>
              <td>
                {rowData.sender_info&&
                  <span>{rowData.sender_info.a_user_name}</span>
                }
              </td>
              <th>받는이</th>
              <td>
                {rowData.receiver_info&&
                  <span>{rowData.receiver_info.a_user_name}</span>
                }
              </td>
            </tr>
            <tr>
              <th>확인여부</th>
              <td>
                {rowData.a_is_read=="1"?"확인":"미확인"}
              </td>
              <th>확인일</th>
              <td>
                {rowData.a_read_date}
              </td>
            </tr>
            <tr>
              <th>전송성공</th>
              <td>
                {rowData.a_is_send_success=="1"?"확인":"미확인"}
              </td>
              <th>에러메세지</th>
              <td>
                {rowData.a_error_msg}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">

      </div>
    </div>
  );
});

export default ViewFrameContent;
