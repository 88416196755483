import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import ListArea from "./area/list_area";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import WriteArea from "./area/write";
import SearchArea from "./area/search";
import CounselListArea from "./area/counsel_list";

const StudyDiary = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_data:{
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_info: {},
    is_view_mode: true,
    s_select_date: "",//DateFunc.get_date_format(new Date(), "Y-m-d")
    s_start_date: "",
    s_end_date: "",
    refresh_data: (inData: any) => { },
    ...props
  };

  let select_mcomp_data= myProps.select_mcomp_data;
  let init_s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
  let init_s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
  if (myProps.s_select_date != "") {
    init_s_start_date = myProps.s_select_date;
    init_s_end_date = myProps.s_select_date;
  }
  if (!strFunc.is_empty(myProps.s_start_date)) {
    init_s_start_date = myProps.s_start_date;
  }
  if (!strFunc.is_empty(myProps.s_end_date)) {
    init_s_end_date = myProps.s_end_date;
  }

  const [listOpt, setListOpt] = useState({
    "is_need_count": "1",
    "is_need_info_arr": "1",
    "is_no_limit": "",
    "now_page": "1",
    "num_per_page": 30,
    "s_date_type": "a_study_date",
    "order_id": "a_study_date DESC, a_create_date DESC",
    "s_start_date": init_s_start_date,
    "s_end_date": init_s_end_date,
    "s_stu_seq": myProps.stu_info["a_seq"],
    "s_writer_seq": "",
    "s_is_success_prescribe": "",
    "s_addon_detail": "1",
    "s_addon_user": "1",
    "s_addon_file": "1",
    // "s_addon_counsel_todo": "1",
    // "s_addon_counsel_todo_addon_file": "1",
    ...myProps.list_opt
  });
  const [counselListOpt, setCounselListOpt] = useState({
    "is_need_count": "1",
    "is_need_info_arr": "1",
    "is_no_limit": "",
    "now_page": "1",
    "num_per_page": 30,
    "order_id": "a_reserve_date  DESC, a_create_date DESC",
    "s_date_type": "a_reserve_date",
    "s_start_date": init_s_start_date,
    "s_end_date": init_s_end_date,
    "s_stu_seq": myProps.stu_info["a_seq"],
    "s_addon_file": "1",
  });
  const [stu_info, set_stu_info] = useState(myProps.stu_info);
  const [info_arr, set_info_arr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [comment_info_arr, set_comment_info_arr] = useState([]);
  const [counsel_info_arr, set_counsel_info_arr] = useState([]);
  const [counselCountInfo, setCounselCountInfo] = useState({ "tot": 0 });
  const [is_view_write, set_is_view_write] = useState(false);
  const [refresh, set_refresh] = useState(false);
  let is_view_mode = myProps.is_view_mode;

  useEffect(() => {
    list({});
  }, [select_mcomp_data.mcomp_seq]);

  const setInitData = (inData: any) => {
    let opt_obj = {
      stu_info: {},
      s_select_date: "",
      s_start_date: "",
      s_end_date: "",
      ...inData
    };
    set_stu_info(opt_obj.stu_info);
    let tmp_s_list_opt: any = {
      "s_stu_seq": opt_obj["stu_info"]["a_seq"],
      "now_page": "1"
    };
    if (!strFunc.is_empty(opt_obj["s_select_date"])) {
      tmp_s_list_opt["s_start_date"] = opt_obj["s_select_date"];
      tmp_s_list_opt["s_end_date"] = opt_obj["s_select_date"];
    }
    if (!strFunc.is_empty(opt_obj["s_start_date"])) {
      tmp_s_list_opt["s_start_date"] = opt_obj["s_start_date"];
    }
    if (!strFunc.is_empty(opt_obj["s_end_date"])) {
      tmp_s_list_opt["s_start_date"] = opt_obj["s_end_date"];
    }
    list(tmp_s_list_opt);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    let tmp_list_form_json = list_form_json;
    if (strFunc.is_empty(tmp_list_form_json["s_stu_seq"])) {
      return false;
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        tmp_list_form_json["api_key"]=select_mcomp_data.api_key;
        tmp_list_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_info_arr(response.data["data"]["info_arr"]);
          get_comment_list({
            info_arr: response.data["data"]["info_arr"]
          });
          get_counsel_list({
            now_page: 1,
            s_start_date: list_form_json.s_start_date,
            s_end_date: list_form_json.s_end_date,
            s_stu_seq: list_form_json.s_stu_seq
          });
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };
  const get_comment_list = (inData: any) => {
    let opt_obj = {
      s_start_date: listOpt.s_start_date,
      s_end_date: listOpt.s_end_date,
      info_arr: info_arr,
      ...inData
    };
    let tmp_pri_val_arr: any = [];
    for (let i = 0; i < opt_obj["info_arr"].length; i++) {
      let info = opt_obj["info_arr"][i];
      tmp_pri_val_arr.push(info["a_ymd"] + "," + info["a_seq"]);
    }
    if (tmp_pri_val_arr.length == 0) {
      return false;
    }
    let comment_list_from_json:any = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "max_limit_num": "10000",
      "order_id": "a_date, a_create_date",
      "s_start_date": opt_obj.s_start_date,
      "s_end_date": opt_obj.s_end_date,
      "s_par_id": "study_diary",
      "s_par_seq": tmp_pri_val_arr,
    };
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        comment_list_from_json["api_key"]=select_mcomp_data.api_key;
        comment_list_from_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', comment_list_from_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_counsel_list = (inOptObj: any) => {
    let list_form_json: any = {
      ...counselListOpt,
      ...inOptObj
    };
    setCounselCountInfo(list_form_json);

    let tmp_list_form_json:any = list_form_json;
    if (strFunc.is_empty(tmp_list_form_json["s_stu_seq"])) {
      return false;
    }
    if(!strFunc.is_empty(select_mcomp_data.api_key)
      &&!strFunc.is_empty(select_mcomp_data.api_user_seq)){
        tmp_list_form_json["api_key"]=select_mcomp_data.api_key;
        tmp_list_form_json["api_user_seq"]=select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_counsel_info_arr(response.data["data"]["info_arr"]);
          setCounselCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  return (
    <div className={Style.diary_wrap}>
      <div className={Style.box}>
        <div className={Style.box_left}>
          <div>
            디스커버리 ({info_arr.length})
            <button className="btn-m2 btn-sky ml-1" onClick={() => { set_is_view_write(!is_view_write) }} >신규</button>
          </div>
          <div className="mt-2">
            <SearchArea
              listOpt={listOpt}
              list={list}
              stu_info={stu_info}
            ></SearchArea>
          </div>
          <div>
            {is_view_write &&
              <WriteArea
                stu_info={stu_info}
                Style={Style}
                list={(inData: any) => { list(inData); set_is_view_write(false); myProps.refresh_data({}); }}
                set_is_view_write={set_is_view_write}
              ></WriteArea>
            }
          </div>
          <ListArea
            select_mcomp_data={{...select_mcomp_data}}
            stu_info={stu_info}
            info_arr={info_arr}
            set_info_arr={set_info_arr}
            list={(inData: any) => { list(inData); myProps.refresh_data({}); }}
            set_refresh={() => { set_refresh(!refresh); }}
            Style={Style}
            is_view_mode={is_view_mode}
            comment_list={comment_info_arr}
            refresh_comment={get_comment_list}
          ></ListArea>
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            is_view_num_per_page={true}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
        </div>
        <div className={Style.box_right}>
          <CounselListArea
            select_mcomp_data={{...select_mcomp_data}}
            is_view_mode={is_view_mode}
            Style={Style}
            counsel_info_arr={counsel_info_arr}
            set_counsel_info_arr={set_counsel_info_arr}
            list={(inData: any) => { list(inData); myProps.refresh_data({}); }}
            stu_info={stu_info}
            select_date_str={""}
            get_counsel_list={get_counsel_list}
            counselListOpt={counselListOpt}
            counselCountInfo={counselCountInfo}
            setCounselCountInfo={setCounselCountInfo}
          ></CounselListArea>
        </div>
      </div>
    </div>
  );
});
export default StudyDiary;