import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function CounselBox(props:any){
   let myProps={
    Style:{},
    info:{},
    openCounselWritePopup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  //1차: 당일,  2차: 일주일, 3차: 한달.
  let counsel_state_arr:any=[
    {
      "text":"1차",
      "sort":1,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"2차",
      "sort":2,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
    {
      "text":"3차",
      "sort":3,
      "counsel_todo_pri_val":"",
      "select_date":"",
    },
  ];
  const set_counsel_info_data=()=>{
    for(let i=0;i<counsel_state_arr.length;i++){
      let counsel_state=counsel_state_arr[i];

      if(counsel_state.sort==1){
        counsel_state_arr[i]["select_date"]=info["a_join_date"];
      }else if(counsel_state.sort==2){
        counsel_state_arr[i]["select_date"]=DateFunc.get_date_format(DateFunc.get_change_date(new Date(info["a_join_date"]),'day',+7),"Y-m-d");
      }else if(counsel_state.sort==3){
        counsel_state_arr[i]["select_date"]=DateFunc.get_date_format(DateFunc.get_change_date(new Date(info["a_join_date"]),'month',+1),"Y-m-d");
      }
      if(info.add_memo_info){
        if(counsel_state.sort==1){
          if(!strFunc.is_empty(info.add_memo_info["a_init_counsel_date1"])&&info.add_memo_info["a_init_counsel_date1"]!="0000-00-00"){
            counsel_state_arr[i]["select_date"]=info.add_memo_info["a_init_counsel_date1"];
            counsel_state_arr[i]["counsel_todo_pri_val"]=info.add_memo_info["a_init_counsel_seq1"];
          }
        }else if(counsel_state.sort==2){
          if(!strFunc.is_empty(info.add_memo_info["a_init_counsel_date2"])&&info.add_memo_info["a_init_counsel_date2"]!="0000-00-00"){
            counsel_state_arr[i]["select_date"]=info.add_memo_info["a_init_counsel_date2"];
            counsel_state_arr[i]["counsel_todo_pri_val"]=info.add_memo_info["a_init_counsel_seq2"];
          }
        }else if(counsel_state.sort==3){
          if(!strFunc.is_empty(info.add_memo_info["a_init_counsel_date3"])&&info.add_memo_info["a_init_counsel_date3"]!="0000-00-00"){
            counsel_state_arr[i]["select_date"]=info.add_memo_info["a_init_counsel_date3"];
            counsel_state_arr[i]["counsel_todo_pri_val"]=info.add_memo_info["a_init_counsel_seq3"];
          }
        }
      }
    }
  };
  set_counsel_info_data();

  const get_counsel_state_tags=()=>{
    let counsel_state_tags=counsel_state_arr.map((item:any,idx:number)=>{
      let is_active=false;
      let row_class_name=Style.cousel_box_state;
      let state_str=item["text"];
      if(!strFunc.is_empty(item.select_date)){
        state_str=item.select_date.substring(5,10);
      }
      if(!strFunc.is_empty(item.counsel_todo_pri_val)){
        is_active=true;
        row_class_name+=" "+Style.active;
      }
      return (
        <div key={idx} className={row_class_name} onClick={()=>{
          if(strFunc.is_empty(item.counsel_todo_pri_val)){
            myProps.openCounselWritePopup({
              stu_info:info,
              select_date:item.select_date,
            });
          }else{
            myProps.openCounselWritePopup({
              isUpdate:true,
              counsel_todo_pri_val:item.counsel_todo_pri_val,
            });
          }
        }}>
          {state_str}
        </div>
      );
    });

    return counsel_state_tags;
  };
  
  return (
  <div className={Style.cousel_box_wrap}>
    <div className={Style.cousel_box_state_wrap}>
      {get_counsel_state_tags()}
    </div>
  </div>
  );
};
export default CounselBox;