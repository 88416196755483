import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';
import TdTodoSumArea from "./area/td_todo_sum";
import TD_TodoContent from "./area/td_todo_centent";
import TopYearMonthArea from "./area/top_year_month";
import TopSearchSort from "./area/top_search_sort";
import TopStuInfoArea from "./area/top_stu_info";

interface TdData {
  is_this_month: boolean;
  year:string,
  month:string,
  day:number,
  day_of_week:number,
  content?:any,
  todo_arr?:any[],
};
const CalendarTable = forwardRef((props:any, ref) => {
  const myProps={
    "listOpt":{},
    "select_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "onSearchDateChange":(inData:any)=>{},
    openTodoDaySort:(inData:any)=>{},
    openTodoWritePopup:(inData:any)=>{},
    removeSelectStu:(stu_seq:any)=>{},
    moveToWriteTodoPage:(select_date_str:string)=>{},
    list:(inData:any)=>{},
    ...props
  };
  const [select_date,set_select_date]=useState(myProps.select_date);
  const [todo_arr,set_todo_arr]=useState<any>([]);
  const [stu_info_arr,set_stu_info_arr]=useState<any>([]);
  let stu_color_arr:any={};
  let stu_color_idx=0;
  for(let i=0;i<todo_arr.length;i++){
    let tmp_stu_seq=todo_arr[i]["a_stu_mcomp_user_seq"];
    if(stu_color_arr[tmp_stu_seq]==undefined){
      stu_color_arr[tmp_stu_seq]="#d1d1d1";
    }
  }
  
  const now_date_json=DateFunc.get_date_json(new Date());

  let day_num_list=[0,1,2,3,4,5,6];
  let day_name_list=["일","월","화","수","목","금","토"];
  let select_month_obj=new Date(select_date);
  let pre_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',-1);
  let after_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',+1);
  let pre_month_date=DateFunc.get_date_format(pre_month_obj,"Y-m-01");
  let after_month_date=DateFunc.get_date_format(after_month_obj,"Y-m-01");
  let cal_data:any={
    select_month_json:DateFunc.get_date_json(select_month_obj),
    pre_month_json:DateFunc.get_date_json(pre_month_obj),
    after_month_json:DateFunc.get_date_json(after_month_obj),
    select_month_week_len:5,
  };
  cal_data.select_month_week_len=Math.ceil((cal_data.select_month_json.first_day_of_week+cal_data.select_month_json.last_day)/7);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      select_date:myProps.select_date,
      todo_arr:[],
      stu_info_arr:[],
      ...inOptObj
    };
    set_select_date(optObj.select_date);
    set_todo_arr(optObj.todo_arr);
    set_stu_info_arr(optObj.stu_info_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,set_todo_arr,set_stu_info_arr
  }));

  const handleDateChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    
    let change_searchDate=select_date;
    let tmp_year=select_date.substring(0,4);
    let tmp_month=select_date.substring(5,7);
    let tmp_day=select_date.substring(8,10);
    let tmp_date_json:any=DateFunc.get_date_json(new Date(select_date));
    if(name=="s_month"){
      tmp_month=value;
    }else if(name=="s_year"){
      tmp_year=value;
    }
    if(parseInt(tmp_day)>tmp_date_json.last_day){
      tmp_day=strFunc.str_pad({"str":tmp_date_json.last_day,"pad_length":2});
    }
    change_searchDate=tmp_year+"-"+tmp_month+"-"+tmp_day;

    set_select_date(change_searchDate);
    if(myProps.onSearchDateChange){
      myProps.onSearchDateChange(change_searchDate);
    }
  };

  const get_col_tags=()=>{
    let col_tags=day_num_list.map((item:any,idx:number)=>{
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };
  
  const get_th_tags=()=>{
    let th_tags=day_num_list.map((item:number,idx:number)=>{
      let th_title=day_name_list[item];
      let th_text_class_name=Style.th_text;
      if(idx==0){
        th_text_class_name+=" "+Style.th_text_sun;
      }else if(idx==6){
        th_text_class_name+=" "+Style.th_text_sat;
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  const get_td_data_arr=()=>{
    let td_data_arr:TdData[]=[];
    let day_num=0;
    let pre_day_num=cal_data.pre_month_json.t-cal_data.select_month_json.first_day_of_week+1;
    let after_day_num=1;
    for(let week_i=0;week_i<cal_data.select_month_week_len;week_i++){
      for(let td_i=0;td_i<7;td_i++){
        if(day_num==0){
          if(cal_data.select_month_json.first_day_of_week==td_i){
            day_num=1;
          }
        }
        //전달
        if(day_num<=0){
          td_data_arr.push({
            "is_this_month":false,
            "year":cal_data.pre_month_json.Y,
            "month":cal_data.pre_month_json.m,
            "day":pre_day_num,
            "day_of_week":td_i,
          });
          pre_day_num++;
        }
        //이번달
        if(day_num>0&&day_num<=cal_data.select_month_json.t){
          td_data_arr.push({
            "is_this_month":true,
            "year":cal_data.select_month_json.Y,
            "month":cal_data.select_month_json.m,
            "day":day_num,
            "day_of_week":td_i,
          });
          day_num++;
        }
        //다음달
        if(day_num>cal_data.select_month_json.t){
          td_data_arr.push({
            "is_this_month":false,
            "year":cal_data.after_month_json.Y,
            "month":cal_data.after_month_json.m,
            "day":after_day_num,
            "day_of_week":td_i,
          });
          after_day_num++;
        }
      }
    }
    
    return td_data_arr;
  };
  
  const setting_todo_arr_at_td_data_arr=(td_data_arr:any)=>{

    let td_data_len=td_data_arr.length;
    for(let todo_i=0;todo_i<todo_arr.length;todo_i++){
      let todo_info=todo_arr[todo_i];
      let todo_res_start=new Date(todo_info["a_reserve_start_date"].substring(0,10)).getTime();
      let todo_res_end=new Date(todo_info["a_reserve_end_date"].substring(0,10)+" 23:59:59").getTime();
      for(let i=0;i<td_data_len;i++){
        let td_data=td_data_arr[i];

        let td_date_time=new Date(td_data["year"]+"-"+DateFunc.get_digit_str(td_data["month"])+"-"+DateFunc.get_digit_str(td_data["day"])).getTime();
        if(todo_res_start<=td_date_time&&td_date_time<=todo_res_end){
          if(strFunc.is_empty(td_data_arr[i]["todo_arr"])){
            td_data_arr[i]["todo_arr"]=[];
          }
          td_data_arr[i]["todo_arr"]?.push(todo_info);
        }
      }
    }
    for(let i=0;i<td_data_len;i++){
      let td_data=td_data_arr[i];
      let select_date_str=td_data["year"]+"-"+DateFunc.get_digit_str(td_data["month"])+"-"+DateFunc.get_digit_str(td_data["day"]);
      let tmp_select_date_time=new Date(select_date_str).getTime();
      let now_date_time=new Date(now_date_json.Y+"-"+now_date_json.m+"-"+now_date_json.d).getTime();
      let td_user_data_arr:any={};
      let tmp_todo_arr=td_data_arr[i]["todo_arr"];
      if(tmp_todo_arr){
        for(let i=0;i<tmp_todo_arr.length;i++){
          let stu_key=tmp_todo_arr[i]["a_stu_mcomp_user_seq"];
          if(td_user_data_arr[stu_key]==undefined){
            td_user_data_arr[stu_key]=[];
          }
          td_user_data_arr[stu_key].push(tmp_todo_arr[i]);
        }
        let tmp_todo_data_arr_user=[];
        for(let key in td_user_data_arr){
          tmp_todo_data_arr_user.push(td_user_data_arr[key]);
        }
        
        if(!strFunc.is_empty(tmp_todo_data_arr_user)){
          td_data_arr[i]["content"]=(
            <div style={{position:"relative"}}>
              {
                tmp_todo_data_arr_user.map((item:any,idx:number)=>{
                  let stu_name=item[0]["a_stu_name"];
                  let stu_seq=item[0]["a_stu_mcomp_user_seq"];
                  return (
                    <div key={idx}>
                      <div className={Style.td_todo_con_box}>
                        <TdTodoSumArea
                          todo_info_arr={item}
                          openTodoDaySort={myProps.openTodoDaySort}
                          select_date={select_date_str}
                          select_stu_name={stu_name}
                          select_stu_seq={stu_seq}
                        ></TdTodoSumArea>
                      </div>
                    </div>
                  );
                })
              }
              {tmp_select_date_time>=now_date_time&&
              <button className="btn btn-dark" 
                style={{
                  position:"absolute",
                  top:-27,
                  right:0,
                  background:"#508B87"
                }}
                onClick={()=>{
                  //myProps.moveToWriteTodoPage(select_date_str);
                  myProps.openTodoWritePopup({
                    select_stu_seq:myProps.listOpt.s_stu_user_seq,
                    select_date:select_date_str,
                  });
                }}>+</button>
              }
            </div>
          );
        }
      }else{
        td_data_arr[i]["content"]="";
        if(tmp_select_date_time>=now_date_time){
          td_data_arr[i]["content"]=(
            <div className="text-center" style={{lineHeight:"90px"}}>
              <button className="btn btn-dark"
                style={{padding:"0 12px",lineHeight:"28px",background:"#707070"}}
                onClick={()=>{
                  //myProps.moveToWriteTodoPage(select_date_str);
                  myProps.openTodoWritePopup({
                    select_stu_seq:myProps.listOpt.s_stu_user_seq,
                    select_date:select_date_str,
                  });
                }}
                >+PLAN</button>
            </div>
          );
        }
      }
    }

    return td_data_arr;
  };

  const get_td_tags=(inData:any)=>{
    let opt_obj={
      "td_week_data_arr":[],
      ...inData
    };

    let td_tags=opt_obj["td_week_data_arr"].map((item:any,idx:number)=>{
      let td_tag_class_name="";
      let td_class_name=Style.td_title;
      if(item.is_this_month){
        td_class_name+=" "+Style.td_title_select_month;
      }
      if(item.day_of_week==0){
        td_class_name+=" "+Style.td_title_sun;
      }else if(item.day_of_week==6){
        td_class_name+=" "+Style.td_title_sat;
      }
      if(item.is_this_month
          &&cal_data.select_month_json.m==now_date_json.m
          &&cal_data.select_month_json.Y==now_date_json.Y
          &&item.day==parseInt(now_date_json.d)){
          
        td_tag_class_name=Style.td_title_now_date;
      }

      let select_date_str=item["year"]+"-"+DateFunc.get_digit_str(item["month"])+"-"+DateFunc.get_digit_str(item["day"]);

      return (
        <td key={idx} className={td_tag_class_name}>
          <div className={td_class_name} >
            {item.day}
          </div>
          <div className={Style.td_content_wrap}>
            {item.content}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags=()=>{
    let td_data_arr=get_td_data_arr();
    td_data_arr=setting_todo_arr_at_td_data_arr(td_data_arr);
    let tmp_td_week_arr=[];
    let td_week_arr=[];
    for(let i=0;i<td_data_arr.length;i++){
      if(i%7==0&&i!=0){
        td_week_arr.push([
          ...tmp_td_week_arr
        ]);
        tmp_td_week_arr=[];
      }
      tmp_td_week_arr.push(td_data_arr[i]);
    }
    let tr_tags=td_week_arr.map((item:any,idx:number)=>{
      let td_tags=get_td_tags({
        "td_week_data_arr":item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      {/* <WriteStuPage
        info_arr={[{a_seq:myProps.listOpt.s_stu_user_seq}]}
        select_idx={0}
        is_update={true}
        is_view_mode={true}
      ></WriteStuPage> */}
      <TopStuInfoArea
        stu_seq={myProps.listOpt.s_stu_user_seq.length>0?myProps.listOpt.s_stu_user_seq[0]:""}
        callback_refresh={()=>{myProps.onSearchDateChange(select_date);}}
      ></TopStuInfoArea>
      <TopYearMonthArea
        select_date={select_date}
        set_select_date={set_select_date}
        onSearchDateChange={myProps.onSearchDateChange}
        handleDateChange={handleDateChange}
        pre_month_date={pre_month_date}
        after_month_date={after_month_date}
      ></TopYearMonthArea>
      <div className={Style.plan_cal_middle_wrap}>
        <div className={Style.plan_cal_middle_stu} >
          {/* {stu_info_arr.map((item:any,idx:number)=>{
            let stu_span_style:any={
              //background:stu_color_arr[item.a_seq]
            };

            return (
              <span key={idx} className={Style.plan_cal_middle_stu_span} style={stu_span_style}>
                {item.a_user_name}
                <a className="ml-1" onClick={()=>{myProps.removeSelectStu(item.a_seq);}}>X</a>
              </span>
            );
          })} */}
        </div>
        <TopSearchSort
          listOpt={myProps.listOpt}
          list={myProps.list}
        ></TopSearchSort>
        
        <div className={Style.plan_cal_middle_right}>
          {/* <select value={myProps.listOpt.is_select_stu_search} onChange={(e:any)=>{
            myProps.list({"is_select_stu_search":e.target.value});
          }} style={{border:"1px solid #ddd"}}>
            <option value="select">선택보기</option>
            <option value="">전체보기</option>
          </select> */}

          <span className={Style.plan_cal_middle_right_state_span+" ml-2"}>
            완료 x
          </span>
          <span className={Style.plan_cal_middle_right_state_span+" ml-2"} style={{"background":"#EAB358"}}>
            진행중 x
          </span>
          <span className={Style.plan_cal_middle_right_state_span+" ml-2"} style={{"background":"#DB6C58"}}>
            미완료 x
          </span>
        </div>
      </div>
      <div className={Style.plan_cal_wrap}>
        <table>
          <colgroup>
            {get_col_tags()}
          </colgroup>
          <thead>
            <tr>
              {get_th_tags()}
            </tr>
          </thead>
          <tbody>
            {get_tr_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default CalendarTable;
