import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import StudyTitleListArea from "./area/study_title";
import StudyListArea from "./area/study_list";
import Style from "./css/style.module.css";

function FindStudyPopup(props:any) {
  let myProps={
    closePopup:()=>{},
    callbackData:(inData:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const [select_title_info,set_select_title_info]=useState(null);
  const StudyTitleListAreaRef=useRef<any>(null);
  const StudyListAreaRef=useRef<any>(null);

  const onStudyTitleSelect=(selected_row:any)=>{
    if(selected_row.length==1){
      StudyListAreaRef.current.setInitData({
        rowData:selected_row[0]
      });
      set_select_title_info(selected_row[0]);
    }else{
      StudyListAreaRef.current.setInitData({
        rowData:{},
      });
      set_select_title_info(null);
    }
  };

  const goSelectCallback=()=>{
    let select_study_list_arr=StudyListAreaRef.current.get_select_study_list();
    if(select_study_list_arr.length==0){
      alert("선택이 필요합니다.");
      return false;
    }
    //a_subject_sort
    if(select_title_info){
      for(let i=0;i<select_study_list_arr.length;i++){
        select_study_list_arr[i]["a_subject_sort"]=select_title_info["a_subject_sort"];
      }
    }

    myProps.callbackData({
      "info_arr":select_study_list_arr
    });
    myProps.closePopup();
  };

  return (
    <div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{goSelectCallback();}}>선택</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
      <div className={Style.box_wrap}>
        <div className={Style.left_box}>
          <StudyTitleListArea
            ref={StudyTitleListAreaRef}
            onStudyTitleSelect={onStudyTitleSelect}
          ></StudyTitleListArea>
        </div>
        <div className={Style.right_box}>
          <StudyListArea
            ref={StudyListAreaRef}
          ></StudyListArea>
        </div>
      </div>
    </div>
  );
}

export default FindStudyPopup;
