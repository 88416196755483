import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import default_row_info from "./data/data";
import ReportWriteComponent from "../component/write";

function ReportWriteArea(props: any) {
  let myProps = {
    stu_info: {},
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    set_is_show_write: (inData: any) => { },
    list: (inData: any) => { },
    set_write_info: (inData: any) => { },
    Style: {},
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let tmp_default_row_info={
    ...default_row_info,
    a_ymd: strFunc.str_replace("-", "", myProps.select_date),
    a_date: myProps.select_date,
    a_stu_seq: myProps["stu_info"]["a_seq"],
    a_stu_name: myProps["stu_info"]["a_user_name"],
    a_writer_seq: user.user_seq,
    a_writer: user.user_name,
  };
  if(myProps.stu_info.last_prescribe_arr&&myProps.stu_info.last_prescribe_arr.length>0){
    let last_study_prescribe=myProps.stu_info.last_prescribe_arr[0];
    tmp_default_row_info["a_subject"]=last_study_prescribe["a_subject"];
    tmp_default_row_info["a_progress_main"]=last_study_prescribe["a_progress_main"];
    tmp_default_row_info["a_progress_sub"]=last_study_prescribe["a_progress_sub"];
    tmp_default_row_info["a_exam_main_num"]=last_study_prescribe["a_exam_main_num"];
    tmp_default_row_info["a_homework"]=last_study_prescribe["a_homework"];
    tmp_default_row_info["a_comment"]=last_study_prescribe["a_comment"];
  }
  const [row_data, set_row_data] = useState(tmp_default_row_info);
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    let init_row_data={
      ...row_data,
      a_date:myProps.select_date,
    };
    set_row_data(init_row_data);
    myProps.set_write_info(init_row_data);
  }, [myProps.select_date]);
  useEffect(() => {
    
  }, []);

  const goWrite = () => {
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }
    let tmp_row_data = row_data;

    if(strFunc.is_empty(tmp_row_data["a_seq"])){
      tmp_row_data["a_ymd"]=strFunc.str_replace("-","",tmp_row_data["a_date"]);
    }
    let w_report_form_json = {
      "data_arr": [tmp_row_data],
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/write', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.set_is_show_write(false);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="mt-1">
        <span style={{color:"orange"}}>신규등록</span>
      </div>
      <ReportWriteComponent
        row_data={row_data}
        set_row_data={(inData: any) => {
          set_row_data(inData);
          myProps.set_write_info(inData);
          set_refresh(!refresh);
        }}
      ></ReportWriteComponent>
      <div className="text-center mt-1">
        <button className="btn-s btn-sky" onClick={() => { goWrite(); }} >등록</button>
        <button className="btn-s btn-gray ml-1" onClick={() => { myProps.set_is_show_write(false); }} >
          닫기
        </button>
      </div>
    </div>
  );
};
export default ReportWriteArea;