import { useState,useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';

function ManageArea(props: any) {
  let myProps = {
    goAddRow: (inData: any) => { },
    goWriteArr: (inData: any) => { },
    goDelete: (inData: any) => { },
    ListComponentRef: null,
    infoArr: [],
    setInfoArr: (inData: any) => { },
    teacher_arr: [],
    iam_info: {},
    xColumnArr: {},
    ...props
  };

  const [add_row_data, set_add_row_data] = useState<any>({
    a_public_sort: "all",
    a_title: "",
    a_memo: "",
    a_owner_mcomp_user_seq: "",
    a_owner_name: "",
  });
  const [key_val, set_key_val] = useState<any>({ "key": "", "value": "" });

  useEffect(() => {
    if(myProps.iam_info){
      set_add_row_data({
        ...add_row_data,
        a_owner_mcomp_user_seq:myProps.iam_info.a_seq,
        a_owner_name:myProps.iam_info.a_user_name,
      });
    }
  }, [myProps.iam_info]);

  const onChangeMulty = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      ...inData
    };
    let selected_row = myProps.ListComponentRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let key = opt_obj["key"];
    let value = opt_obj["value"];
    let select_teacher_info = null;
    if (key == "a_owner_mcomp_user_seq") {
      for (let i = 0; i < myProps.teacher_arr.length; i++) {
        if (myProps.teacher_arr[i]["a_seq"] == value) {
          select_teacher_info = myProps.teacher_arr[i];
        }
      }
    }
    for (let i = 0; i < selected_row.length; i++) {
      if (key == "a_owner_mcomp_user_seq" && select_teacher_info != null) {
        selected_row[i]["a_owner_mcomp_user_seq"] = select_teacher_info["a_seq"];
        selected_row[i]["a_owner_name"] = select_teacher_info["a_user_name"];
      } else {
        selected_row[i][key] = value;
      }
    }
    let all_rows = myProps.ListComponentRef.current.getRows();
    for (let i = 0; i < all_rows.length; i++) {
      let tmp_row = all_rows[i];
      for (let j = 0; j < selected_row.length; j++) {
        let tmp_select_row = selected_row[j];
        if (tmp_row["idx_num"] == tmp_select_row["idx_num"]) {
          all_rows[i] = tmp_select_row;
        }
      }
    }
    myProps.setInfoArr(all_rows);
    myProps.ListComponentRef.current.setInfoArr(all_rows);
  };

  const onChangeAddrowInput = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_add_row_data = { ...add_row_data };
    change_add_row_data[name] = value;
    if(name=="a_owner_mcomp_user_seq"){
      for(let i=0;i<myProps.teacher_arr.length;i++){
        let tmp_teacher = myProps.teacher_arr[i];
        if(tmp_teacher["a_seq"]==value){
          change_add_row_data["a_owner_name"] = tmp_teacher["a_user_name"];
        }
      }
    }
    set_add_row_data(change_add_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    set_key_val({ key: key, value: date_str });
  };

  const getChangeInputTag = () => {
    let key = key_val["key"];
    let on_change_val = (e: any) => {
      set_key_val({ ...key_val, "value": e.target.value });
    };
    let input_tag = <input type="text" className="search-input ml-1" value={key_val.value}
      onChange={on_change_val}
      style={{ width: 100 }}
      placeholder='입력' />;
    if (key == "a_owner_mcomp_user_seq") {
      input_tag = (
        <select className="search-input_select ml-1" value={key_val.value} onChange={on_change_val} >
          <option value="">선택없음</option>
          {
            myProps.teacher_arr.map((item: any, idx: number) => {
              return (
                <option value={item.a_seq} key={idx}>{item.a_user_name}</option>
              );
            })
          }
        </select>
      );
    } else if (key == "a_reserve_date") {
      let tmp_date_str = key_val.value;
      if (tmp_date_str.length != 10) {
        tmp_date_str = "";
      }
      input_tag = (
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={tmp_date_str != "" ? new Date(tmp_date_str) : null}
            onChange={(date: Date) => {
              onChangeDatePicker(key, date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
      );
    } else if (myProps.xColumnArr.select_arr[key]) {
      input_tag = (
        <select className="search-input_select ml-1" value={key_val.value} onChange={on_change_val} >
          <option value="">선택없음</option>
          {
            myProps.xColumnArr.select_arr[key].map((item: any, idx: number) => {
              let val_str = item.value;
              if (item.value == "") {
                val_str = "empty";
              }
              return (
                <option value={val_str} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
      );
    }

    return input_tag;
  };
  let input_tag = getChangeInputTag();

  return (
    <div>
      <div className="text-right">
        <button className="btn btn-blue ml-1" onClick={() => { myProps.goWriteArr(); }}>저장</button>
        <button className="btn btn-red ml-1" onClick={() => { myProps.goDelete(); }}>삭제</button>
      </div>
      <div className="text-right mt-1">
        내용
        <input type="text" className="row-input ml-1" name="a_title" value={add_row_data["a_title"]}
          onChange={onChangeAddrowInput} placeholder="내용" style={{ width: 150 }} />
        <span className="ml-1">
          메모
          <input type="text" className="row-input ml-1" name="a_memo" value={add_row_data["a_memo"]}
            onChange={onChangeAddrowInput} placeholder="메모" style={{ width: 100 }} />
        </span>
        <span className="ml-1">
          공개
          <select className="search-input ml-1" name="a_public_sort" 
            value={add_row_data["a_public_sort"]} onChange={onChangeAddrowInput}>
            <option value="all">전체</option>
            <option value="manage">나,관리자</option>
            <option value="iam">나만</option>
          </select>
        </span>
        <span className="ml-1">
          선생님
          <select className="search-input ml-1" name="a_owner_mcomp_user_seq" 
            value={add_row_data["a_owner_mcomp_user_seq"]} onChange={onChangeAddrowInput} >
            <option value="">선택없음</option>
            {
              myProps.teacher_arr.map((item: any, idx: number) => {
                return (
                  <option value={item.a_seq} key={idx}>{item.a_user_name}</option>
                );
              })
            }
          </select>
        </span>
        <button className="btn btn-yellowish2 ml-1" onClick={() => {
          myProps.goAddRow({
            row_data: {
              ...add_row_data
            },
          });
        }}>추가</button>
      </div>
      <div className="mt-1">
        일괄변경
        <select className="search-input_select ml-1"
          value={key_val.key}
          onChange={(e: any) => {
            set_key_val({
              "key": e.target.value,
              "value": ""
            });
          }}
          style={{ width: 100 }}>
          <option value="">선택없음</option>
          {
            myProps.xColumnArr.x_column_list_arr.map((col_data: any, idx: number) => {
              if (col_data["is_show"] != "1") {
                return "";
              }
              if (col_data["key"] == "a_stu_name") {
                return "";
              }
              return (
                <option value={col_data["key"]} key={idx}>{col_data["name"]}</option>
              );
            })
          }
        </select>
        {input_tag}
        <button className="btn btn-dark ml-1" onClick={() => { onChangeMulty(key_val); }} >변경</button>
      </div>
    </div>
  );
};
export default ManageArea;