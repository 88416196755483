import Style from "../css/style.module.css";
import strFunc from "@/lib/lyg/string";

const ContentDivBox=(props:any)=>{
  let myProps={
    lecture_info:null,
    row_time_arr:[],
    time_split_term:30,
    split_height:50,
    ...props
  };

  const get_merge_student_arr=(origin_arr:any[],add_arr:any[],a_day_num:any)=>{
    let tmp_student_arr:any=origin_arr;
    let add_etc_arr=[];
    let add_arr_len=add_arr.length;
    for(let i=0;i<add_arr_len;i++){
      let is_exist_data=false;
      let add_info:any=add_arr[i];
      for(let j=0;j<origin_arr.length;j++){
        let origin_info:any=origin_arr[j];
        
        if(add_info.a_user_seq==origin_info.a_user_seq){
          is_exist_data=true;
          if(strFunc.str_in_array(a_day_num,tmp_student_arr[j]["day_num_arr"])==-1){
            tmp_student_arr[j]["day_num_arr"].push(a_day_num);
          }
        }
      }
      if(is_exist_data==false){
        add_info.day_num_arr=[a_day_num];
        add_etc_arr.push(add_info);
      }
    }

    tmp_student_arr=[
      ...tmp_student_arr,
      ...add_etc_arr
    ];
  
    return tmp_student_arr;
  };

  let row_start_sec=strFunc.timeToSeconds(myProps.row_time_arr[0]["a_start_h"],myProps.row_time_arr[0]["a_start_m"],0);
  let row_end_sec=strFunc.timeToSeconds(myProps.row_time_arr[0]["a_end_h"],myProps.row_time_arr[0]["a_end_m"],0);
  let day_num_arr:any=[];
  let student_arr=[];
  for(let i=0;i<myProps.row_time_arr.length;i++){
    let time_info=myProps.row_time_arr[i];
    let tmp_start_time_sec=strFunc.timeToSeconds(time_info["a_start_h"],time_info["a_start_m"],0);
    let tmp_end_time_sec=strFunc.timeToSeconds(time_info["a_end_h"],time_info["a_end_m"],0);
    if(tmp_start_time_sec<row_start_sec){
      row_start_sec=tmp_start_time_sec;
    }
    if(tmp_end_time_sec>row_end_sec){
      row_end_sec=tmp_end_time_sec;
    }

    //요일등록
    if(strFunc.str_in_array(time_info["a_day_num"],day_num_arr)==-1){
      day_num_arr.push(time_info["a_day_num"]);
    }

    //유저정보
    if(time_info.a_stu_seq==undefined){
      //student_arr=get_merge_student_arr(student_arr,myProps.lecture_info.student_arr_base,time_info["a_day_num"]);
    }else{
      if(time_info.student_info){
        student_arr=get_merge_student_arr(student_arr,[time_info.student_info],time_info["a_day_num"]);
      }
    }
  }

  let row_diff_sec=row_end_sec-row_start_sec;
  let row_diff_min=row_diff_sec/60;
  let height_multi_num=Math.ceil(row_diff_min/myProps.time_split_term);
  let row_height=myProps.split_height*(height_multi_num-1);

  //요일정보
  let week_name_arr=["일","월","화","수","목","금","토"];
  let row_week_name_arr=[];
  day_num_arr.sort();
  for(let i=0;i<day_num_arr.length;i++){
    row_week_name_arr.push(
      week_name_arr[day_num_arr[i]]
    );
  }
  let row_week_name_str=row_week_name_arr.join(",");

  return (
    <div className={Style.time_table_lecture_box} style={{bottom:-row_height}}>
      <div className={Style.time_table_lecture_title} >
        수업
        {row_week_name_arr.length>0&&
          <span className="ml-1" style={{fontWeight:400}}>
            ({row_week_name_str})
          </span>
        }
      </div>
      <div className={Style.time_table_lecture_stu_wrap}>
        {
          student_arr.map((item:any,idx:number)=>{
            //요일 다르면 표시
            let stu_row_week_name_str="";
            let tmp_row_week_name_arr=[];
            item["day_num_arr"].sort();
            for(let i=0;i<item["day_num_arr"].length;i++){
              tmp_row_week_name_arr.push(
                week_name_arr[item["day_num_arr"][i]]
              );
            }
            let tmp_row_week_name_str=tmp_row_week_name_arr.join(",");
            if(row_week_name_str!=tmp_row_week_name_str){
              stu_row_week_name_str="("+tmp_row_week_name_str+")";
            }
            
            return (
              <div key={idx} className={Style.time_table_lecture_stu_row}>
                {item.a_user_name}
                {stu_row_week_name_str}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
export default ContentDivBox;