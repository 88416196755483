export const default_info={
  "a_ymd":"",
  "a_seq":"",
  "a_reserve_date":"",
  "a_is_write":"",
  "a_write_date":"",
  "a_stu_seq":"",
  "a_stu_name":"",
  "a_writer_seq":"",
  "a_writer_name":"",
  "a_subject_name":"",
  "a_title":"",
  "a_progress_reason":"",
  "a_progress":"",
  "a_grow":"",
  "a_lack":"",
  "a_homework_degree":"",
  "a_understand_degree":"",
  "a_study_amount":"",
  "a_lesson_attitude":"",
  "a_study_emotion":"",
  "a_content":"",
  "a_is_success":"",
  "a_success_date":"",
  "a_success_writer_seq":"",
  "a_success_writer_name":"",
  "a_is_success_check":"",
  "a_success_check_date":"",
  "a_success_check_msg":"",
  "a_is_request_counsel":"",
  "a_request_counsel_date":"",
  "a_request_counsel_msg":"",
  "a_request_counselor_seq":"",
  "a_request_counselor":"",
  "a_request_counsel_writer_seq":"",
  "a_request_counsel_writer":"",
  "a_request_counsel_seq":"",
};
export const month_prescribe_select_arr={
  a_homework_degree:[
    { "value": "100", "text": "A" },
    { "value": "90", "text": "B" },
    { "value": "80", "text": "C" },
    { "value": "70", "text": "D" },
    { "value": "50", "text": "E" },
  ],
  a_understand_degree:[
    { "value": "100", "text": "100" },
    { "value": "90", "text": "90" },
    { "value": "80", "text": "80" },
    { "value": "70", "text": "70" },
    { "value": "50", "text": "50" },
  ],
  a_lesson_attitude:[
    { "value": "100", "text": "A" },
    { "value": "90", "text": "B" },
    { "value": "80", "text": "C" },
    { "value": "70", "text": "D" },
    { "value": "50", "text": "E" },
  ],
};