import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import getXcolumnJson from "../../xcolumn/list";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import Style from "../list/css/style.module.css";
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from '../../../popup/find_stu';
import DetailListArea from "./detail";

//평가틀선택(local저장)
//학생선택(이미 작성했는지 표시)(팝업,그냥 둘다있도록 하기)
//점수작성(ABC선택)
//저장
function WriteArea(props: any){
  let myProps = {
    list: (inData: any) => { },
    close: () => { },
    row_data: {},
    is_update: false,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const [form_list, set_form_list] = useState<any>([]);

  let last_edu_form_seq="";
  if(localStorage.last_edu_form_seq){
    last_edu_form_seq=localStorage.last_edu_form_seq;
  }
  
  let default_row_data = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_form_seq": last_edu_form_seq,
    "a_target_id": "study_title",
    "a_target_seq": "",
    "a_target_name": "",
    "a_target_detail": "",
    "a_stu_seq": "",
    "a_stu_name": "",
    "a_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_subject_name": "",
    "a_title": "",
    "a_average_score": 0,
    "a_item_amout": 0,
    "a_comment": "",
    "a_writer_seq": user.user_seq,
    "a_writer_name": user.user_name,
    "form_detail_arr": [],
    "detail_arr": [],
  };
  let default_detail_row_data = {
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_detail_seq: "",
    a_title: "",
    a_score: 0,
    a_max_score: 100,
    a_score_letter: "",
    a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_order_num: "",
  };
  const [row_data, set_row_data] = useState({
    ...default_row_data,
    ...myProps.row_data,
  });
  
  const [study_arr, set_study_arr] = useState([]);
  const [lecture_arr, set_lecture_arr] = useState([]);
  const [refresh, set_refresh] = useState(false);

  const [is_except_eval_user,set_is_except_eval_user]=useState(true);
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find_stu",//find_stu
    title:"학생찾기",
    width:"800px",
    height:"85%",
  });

  useEffect(() => {
    
    get_eval_forms_by_ajax();
    get_study_arr_by_ajax();
    get_lecture_arr_by_ajax();
  }, []);

  let target_seq_select_arr = [];
  if (row_data.a_target_id == "study_title") {
    for (let i = 0; i < study_arr.length; i++) {
      let study_info = study_arr[i];
      target_seq_select_arr.push({
        "value": study_info["a_seq"],
        "text": study_info["a_title"],
      });
    }
  } else if (row_data.a_target_id == "lecture") {
    for (let i = 0; i < lecture_arr.length; i++) {
      let lecture_info = lecture_arr[i];
      target_seq_select_arr.push({
        "value": lecture_info["a_seq"],
        "text": lecture_info["a_name"],
      });
    }
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_row_data = { ...row_data };
    change_row_data[name] = value;

    if (name == "a_form_seq") {
      change_row_data=get_row_data_by_form_arr_data({
        row_data:change_row_data,
        form_list:form_list,
      });
    }

    set_row_data(change_row_data);
  };

  const get_row_data_by_form_arr_data=(inData:any)=>{
    let opt_obj:any={
      row_data:{},
      form_list:[],
      ...inData,
    };

    let change_row_data=opt_obj.row_data;
    let form_info = null;
    for (let i = 0; i < opt_obj.form_list.length; i++) {
      if (opt_obj.form_list[i].a_seq == opt_obj.row_data.a_form_seq) {
        form_info = opt_obj.form_list[i];
      }
    }
    let detail_arr = [];
    if (form_info != null) {
      change_row_data["a_title"] = form_info["a_title"];
      change_row_data["a_subject_name"] = form_info["a_subject"];
      change_row_data["a_target_id"] = form_info["a_target_id"];
      change_row_data["a_target_seq"] = form_info["a_target_seq"];
      change_row_data["a_target_name"] = form_info["a_target_name"];
      change_row_data["a_target_detail"] = form_info["a_target_detail"];
    }
    if (form_info != null && !strFunc.is_empty(form_info.detail_arr)) {
      for (let i = 0; i < form_info.detail_arr.length; i++) {
        let form_d_info = form_info.detail_arr[i];
        let detail_idx_num = i + 1;
        detail_arr.push({
          ...default_detail_row_data,
          "a_title": form_d_info["a_title"],
          "a_detail_seq": detail_idx_num,
          "a_order_num": detail_idx_num,
          "a_max_score": form_d_info["a_max_score"],
        });
      }
    }
    change_row_data.detail_arr = detail_arr;
    if(form_info!=null){
      change_row_data.form_detail_arr = form_info.detail_arr;
    }

    return change_row_data;
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    set_row_data({
      ...row_data,
      ...{ [key]: date_str }
    });
  };

  const open_find_stu_popup=()=>{
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find_stu",
    });
  };

  const call_back_stu_data=(inData:any)=>{
    let optObj={
      info_arr:[],
      row_num:0,
      key:"",
      ...inData,
    };
    if(optObj.info_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }else if(optObj.info_arr.length!=1){
      alert("한개만 선택해 주세요.");
      return false;
    }
    let stu_info=optObj.info_arr[0];
    set_row_data({
      ...row_data,
      a_stu_seq:stu_info["a_seq"],
      a_stu_name:stu_info["a_user_name"],
    });
  };

  const get_eval_forms_by_ajax = () => {
    let s_form_json = {
      "order_id": "a_title",
      "s_is_use": "1",
      "s_addon_detail": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval_form/list', s_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_form_list(response.data["data"]["info_arr"]);
          if(myProps.is_update==false){
            let change_row_data=get_row_data_by_form_arr_data({
              row_data:row_data,
              form_list:response.data["data"]["info_arr"],
            });
            set_row_data(change_row_data);
          }
        } else {

        }
      });
  };

  const get_study_arr_by_ajax = () => {
    let form_data_json = {
      "order_id": "a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', form_data_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_lecture_arr_by_ajax = () => {
    let form_data_json = {
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', form_data_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_detail_total_data=()=>{
    let total_data:any={
      "count":0,
      "average_score":0,
    };

    if(row_data.detail_arr&&row_data.detail_arr.length>0){
      let total=0;
      let sum_per_num=0;
      let detail_arr_len=row_data.detail_arr.length;
      for(let i=0;i<detail_arr_len;i++){
        let detail_info=row_data.detail_arr[i];
        let a_score=parseInt(strFunc.uncomma(detail_info["a_score"]));
        let a_max_score=parseInt(strFunc.uncomma(detail_info["a_max_score"]));
        let row_per_num:any=0;
        if(a_score!=0&&a_max_score!=0){
          row_per_num=(a_score/a_max_score)*100;
        }
        sum_per_num+=row_per_num;
        total++;
      }
      if(total!=0&&sum_per_num!=0){
        total_data["average_score"]=(sum_per_num/total);
        total_data["average_score"]=parseInt(total_data["average_score"]);
        total_data["count"]=total;
      }
    }

    return total_data;
  };

  const go_write=()=>{
    if(row_data.a_title==""){
      alert("제목을 입력해 주세요.");
      return false;
    }else if(strFunc.is_empty(row_data.a_stu_seq)){
      alert("학생을 선택해 주세요.");
      return false;
    }

    let confirm_msg="등록 하시겠습니까?";
    if(myProps.is_update){
      confirm_msg="수정 하시겠습니까?";
    }
    if(!confirm(confirm_msg)){
      return false;
    }

    let w_row_data=row_data;
    let detail_total_data=get_detail_total_data();
    w_row_data["a_average_score"]=detail_total_data["average_score"];
    w_row_data["a_item_amout"]=detail_total_data["count"];

    let write_data_form:any={
      "data_arr":[w_row_data],
    };
    if(myProps.is_update){
      write_data_form["is_update"]="1";
    }else{
      write_data_form["is_default_val"]="1";
    }

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/eval/write',write_data_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.setItem('last_edu_form_seq',w_row_data["a_form_seq"]);
        write_detail_by_ajax(response.data["data"][0]);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const write_detail_by_ajax=(eval_info:any)=>{
    let detail_form_json={
      "detail_arr":row_data.detail_arr,
      "eval_seq":eval_info["a_ymd"]+","+eval_info["a_seq"],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/evald/add_of_eval',detail_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("성공");
        myProps.list({"now_page":"1"});
        myProps.close();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  let form_test_val_arr: any = [];
  for (let i = 0; i < form_list.length; i++) {
    form_test_val_arr.push({
      "text": form_list[i].a_title,
      "value": form_list[i].a_seq
    });
  }

  return (
    <div>
      <div>
        <p>평가틀선택</p>
        <div>
          <RadioListArea
            valueTextArr={form_test_val_arr}
            value={row_data.a_form_seq}
            name={"a_form_seq"}
            handleInputChange={handleInputChange}
          ></RadioListArea>
        </div>
      </div>
      <div>
        <p>내용</p>
        <div className="write-table-div">
          <table>
            <colgroup>
              <col width="10%"></col>
              <col width="90%"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>학생</th>
                <td>
                  {row_data.a_stu_name}
                  <button className="btn-m btn-sky ml-2" onClick={()=>{open_find_stu_popup();}}>찾기</button>
                  <p className="mt-2">
                    <label>
                      <input type="checkbox" className="row-input" checked={is_except_eval_user} 
                        onChange={()=>{set_is_except_eval_user(!is_except_eval_user)}} style={{width:12,height:12}} />
                      기존작성자 검색 제외
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <th>과목</th>
                <td>
                  <input type="text" className="row-input" name="a_subject_name" value={row_data.a_subject_name}
                    onChange={handleInputChange} placeholder="과목" style={{ width: 200 }} />
                </td>
              </tr>
              <tr>
                <th>제목</th>
                <td>
                  <input type="text" className="row-input" name="a_title" value={row_data.a_title}
                    onChange={handleInputChange} placeholder="제목" />
                </td>
              </tr>
              <tr>
                <th>구분</th>
                <td colSpan={3}>
                  <select className="row-input" name="a_target_id" value={row_data.a_target_id}
                    onChange={handleInputChange} style={{ width: 100 }}>
                    <option value="study_title" >교재</option>
                    <option value="lecture" >수업</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>평가대상</th>
                <td colSpan={3}>
                  <select className="row-input" name="a_target_seq" value={row_data.a_target_seq}
                    onChange={handleInputChange} style={{ width: 100 }}>
                    <option value="" >선택없음</option>
                    {target_seq_select_arr.map((item: any, idx: number) => {
                      return (
                        <option value={item.value} key={idx} >{item.text}</option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>평가상세</th>
                <td colSpan={3}>
                  <input type="text" className="row-input" name="a_target_detail" value={row_data.a_target_detail}
                    onChange={handleInputChange} placeholder="평가상세" style={{ width: 200 }} />
                </td>
              </tr>
              <tr>
                <th>평가일</th>
                <td colSpan={3}>
                  <DatePicker
                    selected={row_data.a_date != "" ? new Date(row_data.a_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </td>
              </tr>
              <tr>
                <th>커멘트</th>
                <td>
                  <textarea className="row-input" name="a_comment" value={row_data.a_comment}
                    onChange={handleInputChange} placeholder="커멘트" style={{ height: 50 }}></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <p>평가</p>
        <div>
          <DetailListArea
            form_detail_arr={row_data.form_detail_arr}
            detail_arr={row_data.detail_arr}
            onChangeDetail={(inData: any) => {
              let opt_obj = {
                "row_num": 0,
                "detail_info": {},
                ...inData,
              };
              let change_row_data = row_data;
              change_row_data.detail_arr[opt_obj.row_num] = opt_obj.detail_info;
              set_row_data(change_row_data);
              set_refresh(!refresh);
            }}
          ></DetailListArea>
        </div>
      </div>
      <div className="btn-box-center mt-4" style={{marginBottom:200}}>
        <button className="btn-m btn-sky" onClick={()=>{go_write();}} >
          {myProps.is_update ? "수정" : "등록"}
        </button>
        <button className="btn-m btn-gray" onClick={() => { myProps.close(); }}>뒤로</button>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find_stu"&&
            <FindMultyStudentPopup
              callBackData={call_back_stu_data} 
              listOpt={{
                s_addon_eval_s_form_seq:row_data.a_form_seq,
                s_addon_eval_s_start_date:row_data.a_date,
                s_addon_eval_s_end_date:row_data.a_date,
                s_is_except_eval_user:(is_except_eval_user?"1":""),
              }}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindMultyStudentPopup>
          }
      </LayerPopup>
      }
    </div>
  );
};

export default WriteArea;