import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import BaseWriteArea from "./area/write";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import StuListArea from "./area/stu_list";
import strFunc from '@/lib/lyg/string';

const WriteArea = forwardRef((props: any, ref) => {
  let myProps = {
    list: (inData: any) => { },
    ...props
  };

  let default_row_data = {
    a_seq: "",
    a_name: "",
    a_coolen_class_seq: "",
    a_is_use: "1",
    a_is_main: "1",
    a_order_num: 0,
  };
  const [row_data, set_row_data] = useState(default_row_data);
  const [is_update, set_is_update] = useState(false);
  const StuListAreaRef = useRef<any>(null);

  useEffect(() => {
    
  }, []);

  const set_init_data = (inData: any) => {
    let opt_obj = {
      row_data: default_row_data,
      is_update: false,
      ...inData
    };
    set_row_data(opt_obj.row_data);
    set_is_update(opt_obj.is_update);
    if (StuListAreaRef.current && opt_obj.row_data["a_seq"]) {
      StuListAreaRef.current.set_init_data({
        s_en_class_seq: opt_obj.row_data["a_seq"],
        now_page: 1,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_init_data
  }));

  const go_write = () => {
    let add_row_data = row_data;

    if (add_row_data["a_name"] == "") {
      alert("클래스명 입력이 필요합니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let form_json_data = {
      "data_arr": [add_row_data],
      "is_coolen_write":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.list({"select_seq":response.data["data"][0]["a_seq"]});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_add_coolen = (inData:any) => {
    let opt_obj={
      coolen_sort:"inside",//inside,bank
      ...inData
    };
    if (is_update == false) {
      alert("수정상태에서만 됩니다..");
      return false;
    }
    if (strFunc.is_empty(row_data["a_seq"])) {
      alert("키가 없습니다.");
      return false;
    }
    let confrim_msg_title="페어링잉글리쉬";
    if(opt_obj["coolen_sort"]=="bank"){
      confrim_msg_title="잉글리쉬뱅크";
    }
    if (!confirm(confrim_msg_title+"에 연동 하시겠습니까?")) {
      return false;
    }

    let form_json_data = {
      en_class_seq: row_data["a_seq"],
      coolen_sort:opt_obj["coolen_sort"],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/coolen/coolen/write_class', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.list({"select_seq":row_data["a_seq"]});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div style={{ lineHeight: "28px" }}>내용</div>
      <BaseWriteArea
        row_data={row_data}
        set_row_data={set_row_data}
        go_write={go_write}
        go_add_coolen={go_add_coolen}
        is_update={is_update}
      ></BaseWriteArea>

      <div style={{ display: is_update ? "" : "none" }}>
        <div style={{ lineHeight: "28px" }}>소속학생</div>
        <StuListArea
          ref={StuListAreaRef}
          list={myProps.list}
        ></StuListArea>
      </div>
    </div>
  );
});
export default WriteArea;