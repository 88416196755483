import CompLayout from "@/pcomponents/comp/layout/layout";

function AttendStatMonth(){

  return (
    <CompLayout isLeftArea={true}>

    </CompLayout>
  );
}

export default AttendStatMonth;