import strFunc from "@/lib/lyg/string";

function DailyLastTds(props:any){
   let myProps={
    info:{},
    lecture_info:null,
    last_daily_info:{},
    ...props
  };
  let info=myProps.info;
  let lecture_info=myProps.lecture_info;
  let last_daily_info=myProps.last_daily_info;
  
  return (
  <>
    <td rowSpan={2} title={last_daily_info["a_textbook"]}>
      {strFunc.cut_str(last_daily_info["a_textbook"],10,"..")}
    </td>
    <td rowSpan={2} title={last_daily_info["a_subtextbook"]}>
      {strFunc.cut_str(last_daily_info["a_subtextbook"],10,"..")}
    </td>
    <td rowSpan={2} title={last_daily_info["a_homework"]}>
      {strFunc.cut_str(last_daily_info["a_homework"],10,"..")}
    </td>
  </>
  );
};
export default DailyLastTds;