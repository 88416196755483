import strFunc from "@/lib/lyg/string";
import getXcolumnJson from "../../xcolumn/list";

function UserListArea(props:any){
  let myProps={
    on_click_user_row:(inData:any)=>{
      //{user_info:null}
    },
    class_info:{},
    user_info_arr:[],
    select_user_seq_arr:[],
    ...props
  };
  const xColumnArr=getXcolumnJson();

  const get_user_tag_arr=()=>{
    let user_tags=myProps.user_info_arr.map((user_info:any,idx:number)=>{
      let tr_class_name="";
      if(strFunc.str_in_array(user_info["a_seq"],myProps.select_user_seq_arr)!=-1){
        tr_class_name="active";
      }
      return (
        <tr key={idx} className={tr_class_name} style={{cursor:"pointer"}} onClick={(e:any)=>{
          myProps.on_click_user_row({
            "user_info":user_info,
          });
        }}>
          {xColumnArr.x_column_list_arr.map((col_data:any,idx:number)=>{
            let key=col_data["key"];
            let render_str="";
            if(user_info[key]){
              render_str=user_info[key];
            }
            render_str=strFunc.cut_str(render_str,4,"..");
            if(xColumnArr.select_arr[key]){
              for(let i=0;i<xColumnArr.select_arr[key].length;i++){
                if(xColumnArr.select_arr[key][i]["value"]==render_str){
                  render_str=xColumnArr.select_arr[key][i]["text"];
                }
              }
            }
            if(key=="row_view_class_name"){
              render_str=myProps.class_info["a_name"];
            }

            return (
              <td key={idx}>
                <div className="row-col-div text-center">{render_str}</div>
              </td>
            );
          })}
        </tr>
      );
    });
    if(strFunc.is_empty(user_tags)){
      user_tags=(
        <tr>
          <td colSpan={99}>
            <div className="text-center" style={{lineHeight:"40px"}}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }
    return user_tags;
  };

  return (
    <div>
      <div className="basic_list_div">
        <table>
          <thead>
            <tr>
              {xColumnArr.x_column_list_arr.map((col_data:any,idx:number)=>{
                return (
                  <th key={idx}>
                    <div className="row-col-div">{col_data.name}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {get_user_tag_arr()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UserListArea;