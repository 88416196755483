import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"sort",
    list_id:"sort_list",
    x_column_list_arr:[
      {"key":"a_par_id","name":"구분","width":"120","is_show":"1"},
      // {"key":"a_par_col","name":"컬럼","width":"170","is_show":"1"},
      {"key":"a_sort_name","name":"구분명","width":"110","is_show":"1"},
      // {"key":"a_type","name":"타입","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_par_id","a_par_col"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;