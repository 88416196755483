import CompLayout from "@/pcomponents/comp/layout/layout";
import TodoStuCardListArea from "./area/card_list";

function TodoStuCardListPage() {

  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap">
        <TodoStuCardListArea></TodoStuCardListArea>
      </div>
    </CompLayout>
  );
}

export default TodoStuCardListPage;