import Style from "../../css/style.module.css";

function TopSearchSort(props:any){
  let myProps={
    listOpt:{
      "s_todo_sort":"",
    },
    list:(inData:any)=>{},
    ...props
  };

  let todo_sort_arr=[
    {"text":"전체","value":""},
    {"text":"학습","value":"study"},
    {"text":"과제","value":"homework"},
  ];
  const tab_btn_tags=todo_sort_arr.map((item:any,idx:number)=>{
    let sort_class_name=Style.plan_cal_middle_tab_btn;
    if(item.value==myProps.listOpt.s_todo_sort){
      sort_class_name+=" "+Style.active;
    }
    if(idx!=0){
      sort_class_name+=" ml-2";
    }
    return (
      <button className={sort_class_name}
        key={idx}
        onClick={()=>{myProps.list({"s_todo_sort":item.value});}}>{item.text}</button>
    );
  });

  return (
    <div className={Style.plan_cal_middle_tab_wrap} >
      {tab_btn_tags}
    </div>
  );
}
export default TopSearchSort;