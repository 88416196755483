import strFunc from "@/lib/lyg/string";

class ListFunc
{
  static get_list_info_arr(inData:any){
    let opt_obj={
      info_arr:[],
      ...inData
    };
    let info_arr=opt_obj.info_arr;
    let info_arr_len=info_arr.length;
    for(let i=0;i<info_arr_len;i++){
      let info=info_arr[i];
      info["row_view_school_grade"]=strFunc.cut_str(info["a_school_name"],"3","")+" "+info["a_stu_grade"];
    }

    return info_arr;
  };
}
export default ListFunc;