import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import CardItem from "./item";
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';

const CardListArea = forwardRef((props:any, ref) => {
  let myProps={
    info_arr:[],
    s_form_seq:"",
    form_list:[],
    go_write_eval:(inData:any)=>{},
    go_delete:(inData:any)=>{},
    setInfoArr:(inData:any)=>{},
    ...props
  };
  let info_arr=myProps.info_arr;

  const get_card_tags=()=>{
    let card_tags=info_arr.map((item:any,idx:number)=>{
      let eval_info:any={};
      let is_update=false;
      if(item.eval_arr&&item.eval_arr.length>0){
        eval_info=item.eval_arr[0];
        if(!strFunc.is_empty(eval_info.a_seq)){
          is_update=true;
        }
      }
      return (
        <CardItem
          key={idx}
          info={eval_info}
          user_info={item}
          row_num={idx}
          is_update={is_update}
          s_form_seq={myProps.s_form_seq}
          form_list={myProps.form_list}
          go_write_eval={myProps.go_write_eval}
          go_delete={()=>{myProps.go_delete({"info_arr":[eval_info]});}}
          set_eval_data={(inData:any)=>{
            let opt_obj={
              "eval_info":{},
              "user_row_num":0,
              ...inData
            };
            
            let change_info_arr=myProps.info_arr;
            if(change_info_arr[opt_obj["user_row_num"]].eval_arr==undefined){
              change_info_arr[opt_obj["user_row_num"]].eval_arr=[];
            }
            if(change_info_arr[opt_obj["user_row_num"]].eval_arr.length==0){
              change_info_arr[opt_obj["user_row_num"]].eval_arr.push(opt_obj["eval_info"]);
            }else{
              change_info_arr[opt_obj["user_row_num"]].eval_arr[0]=opt_obj["eval_info"];
            }
            myProps.setInfoArr(change_info_arr);
          }}
        ></CardItem>
      );
    });

    if(strFunc.is_empty(card_tags)){
      card_tags=(
        <div className="text-center" style={{lineHeight:"60px"}}>
          내용이 없습니다.
        </div>
      );
    }

    return card_tags;
  };

  return (
    <div>
      <div className={Style.card_group}>
        <div className={Style.card_group_con}>
          {get_card_tags()}
        </div>
      </div>
    </div>
  );
});
export default CardListArea;