import { useEffect, useRef } from "react";

function RoomMessageBox(props: any) {
  let myProps = {
    room_info: {},
    room_msg_arr: [],
    Style: {},
    ...props
  };
  let Style = myProps.Style;
  let room_info = myProps.room_info;
  let room_msg_arr: any = [];
  for (let i = 0; i < myProps.room_msg_arr.length; i++) {
    if (myProps.room_msg_arr[i]["room_seq"] == room_info["a_seq"]) {
      room_msg_arr.push(myProps.room_msg_arr[i]);
    }
  }

  const room_box_msg_row_wrap_ref = useRef<any>();
  useEffect(() => {
    if (room_box_msg_row_wrap_ref.current) {
      room_box_msg_row_wrap_ref.current.scrollTop = room_box_msg_row_wrap_ref.current.scrollHeight;
    }
  }, [myProps.room_msg_arr.length]);
  useEffect(() => {
    setTimeout(() => {
      if (room_box_msg_row_wrap_ref.current) {
        let max_height = room_box_msg_row_wrap_ref.current.parentElement.parentElement.offsetHeight - 30;
        room_box_msg_row_wrap_ref.current.style.maxHeight=max_height+"px";
      }
    }, 200);
  }, []);

  const get_room_msg_tags = () => {
    let room_msg_tags = room_msg_arr.map((msg_info: any, idx: number) => {
      let msg_style: any = {};
      if (msg_info["state"] == "start") {
        msg_style["color"] = "#00f";
      } else if (msg_info["state"] == "end") {
        msg_style["color"] = "#f00";
      }
      return (
        <div key={idx} className={Style.room_box_msg_row}>
          <span style={msg_style}>{msg_info.msg}</span>
        </div>
      );
    });
    return room_msg_tags;
  };

  return (
    <div className={Style.room_box_msg_wrap}>
      <div className={Style.room_box_msg_title}>
        메세지
      </div>
      <div className={Style.room_box_msg_row_wrap} ref={room_box_msg_row_wrap_ref} >
        {get_room_msg_tags()}
      </div>
    </div>
  );
};
export default RoomMessageBox;