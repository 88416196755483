import DateFunc from '@/lib/lyg/date_func';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";

const getXcolumnFunc=async (inOptObj)=>{
  if(inOptObj==undefined){inOptObj= {};}
  let optObj={
    "user_seq":"",
  };
  for(let key in inOptObj){
    optObj[key]=inOptObj[key];
  }
  if(optObj["user_seq"]==""){
    return false;
  }
  
  if(localStorage.xcolumn_create_date==DateFunc.get_date_format(new Date(),"Y-m-d")){
    return false;
  }
  const formJsonData={
    "s_user_seq":optObj["user_seq"],
    "s_xcolumn_obj":"1",
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"",
  };
  my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/list_opt/list_opt/list',formJsonData,get_axios_data())
  .then((response) => {
    if(response.data["result"]=="true"){
      localStorage.xcolumn_data_arr=JSON.stringify(response.data.data["info_arr"]);
      localStorage.xcolumn_create_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
  });
  return true;
};
export default getXcolumnFunc;