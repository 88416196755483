import strFunc from '@/lib/lyg/string';

function BaseWriteArea(props: any) {
  let myProps = {
    row_data: {},
    set_row_data: (inData: any) => { },
    go_write: (inData: any) => { },
    go_add_coolen:(inData: any)=>{},
    is_update:false,
    ...props
  };
  let row_data = myProps.row_data;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_row_data({
      ...row_data,
      ...{ [name]: value }
    });
  };

  return (
    <div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col width={"10%"}></col>
            <col width={"30%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"10%"}></col>
            <col width={"5%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                클래스명
              </th>
              <td>
                <input type="text" className="row-input" value={row_data.a_name}
                  onChange={(e: any) => { handleInputChange({ name: "a_name", value: e.target.value }); }}
                  placeholder="클래스명" />
              </td>
              <td>
                <select className="row-input" value={row_data.a_is_use}
                  onChange={(e: any) => { handleInputChange({ name: "a_is_use", value: e.target.value }); }} >
                  <option value="1">사용</option>
                  <option value="">미사용</option>
                </select>
              </td>
              <td className="text-center">
                <label>
                  <input type="checkbox" value={"1"} className="mr-1" checked={row_data.a_is_main=="1"}
                    onChange={(e: any) => { handleInputChange({ 
                      name: "a_is_main", 
                      value: e.target.checked?e.target.value:""
                    }); }} />
                  메인
                </label>
              </td>
              <td className="text-center">
                {(myProps.is_update&&!strFunc.is_empty(row_data.a_coolen_class_seq))&&
                  <span style={{color:"blue"}} onClick={()=>{
                    myProps.go_add_coolen({"coolen_sort":"inside"});
                  }} >페어링연동</span>
                }
                {(myProps.is_update&&strFunc.is_empty(row_data.a_coolen_class_seq))&&
                  <button className="btn-s btn-gray" 
                    onClick={()=>{myProps.go_add_coolen({"coolen_sort":"inside"});}} >페어링연동</button>
                }
              </td>
              <td className="text-center">
                {(myProps.is_update&&!strFunc.is_empty(row_data.a_coolenb_class_seq))&&
                  <span style={{color:"blue"}} onClick={()=>{
                    myProps.go_add_coolen({"coolen_sort":"bank"});
                  }} >뱅크연동</span>
                }
                {(myProps.is_update&&strFunc.is_empty(row_data.a_coolenb_class_seq))&&
                  <button className="btn-s btn-gray" 
                    onClick={()=>{myProps.go_add_coolen({"coolen_sort":"bank"});}} >뱅크연동</button>
                }
              </td>
              <td>
                <button className="btn btn-sky" onClick={myProps.go_write} >
                  {myProps.is_update?"수정":"등록"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default BaseWriteArea;