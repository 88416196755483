import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import SearchArea from  "./area/search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';

function ParNoticeListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_mcomp_seq":"",
      s_date_type:"a_date DESC",
      s_start_date:"",
      s_end_date:"",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    s_id:pageData.listOpt.s_id,
    isUpdate:false,
    rowData:{},
    title:"팝업",
    width:"800px",
    height:"90%",
  });

  useEffect(() => {
    
  }, [user]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_listOpt=pageData.listOpt;

    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    tmp_listOpt["s_mcomp_seq"]=user.comp_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/parnotice/parnotice/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/parnotice/parnotice/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_title"||key=="a_public_sort"||key=="a_date"){
      openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="a_title"){
      let title_sort="";
      if(params.data.a_sort!=""){
        title_sort="["+params.data.a_sort+"] ";
      }
      render_str=(
        <div style={{color:params.data.a_color}}>
          {title_sort}{render_str}
        </div>
      );
    }
    return render_str;
  }, []);

  return (
  <CompLayout>
    <div className="con_wrap">
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
      ></SearchArea>
      <div className="btn-box-left relative h-8">
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={()=>{openOnePopup("write",false);}}>등록</button>
          <button className="btn btn-dark" onClick={()=>{openOnePopup("write",true);}}>수정</button>
          <button className="btn btn-dark" onClick={goDelete}>삭제</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:true,
          floatingFilter:true,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
    </div>
    {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             s_id={popupData.s_id}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} 
             openOnePopup={(sort:string,isUpdate:boolean)=>{openOnePopup(sort,isUpdate);}} ></ViewPopupContent>
          }
      </LayerPopup>
      }
  </CompLayout>
  );
};
export default ParNoticeListPage;