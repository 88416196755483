export const default_info={
  "a_seq":"",
  "a_corp_seq":"1",
  "a_user_id":"",
  "a_user_pw":"",
  "a_user_grade":"student",
  "a_is_login":"1",
  "a_user_name":"",
  "a_user_phone":"",

  "a_stu_state":"now",
  "a_stu_num":"",
  "a_class_seq":"",
  "a_gender":"",
  "a_school_name":"",
  "a_stu_grade":"",
  a_stu_total_code:"",
  a_stu_think_type:"",
  "a_join_date":"",
  "a_join_counsel_date":"",
  "a_stu_birth_date":"",
  "a_stu_supply_day":"",
  "a_stu_supply_discount":"0",
  "a_memo":"",
  "a_stu_end_date":"",
  "a_stu_end_memo":"",
  "invite_code":"",
  "a_makeedu_num":"",
  "a_makeedu_class":"",
  "a_cash_receipt_sort":"",
  "a_cash_receipt_num":"",

  "class_arr":[],
  "link_user_arr":[],
  "profile_arr":[],

  "person_thum_img":"",
  "person_img":"",

  "comp_link_info":undefined
};
export const default_add_memo_info={
  a_user_seq:"",
  a_user_name:"",
  a_parent_sort:"",
  a_parent_edu_type:"",
  a_attitude:"",
  a_ability:"",
  a_wish_student:"",
  a_wish_parent:"",
  a_pre_exam_name:"",
  a_pre_en_exam_score:"",
  a_pre_language_exam_score:"",
  a_pre_science_exam_score:"",
  a_pre_math_exam_score:"",
  a_pre_society_exam_score:"",
  a_pre_edu_history:"",
  a_matter_condition:"",
  a_counsel_content:"",
  a_english_test_nelt:"",
  a_english_test_nelt_etc:"",
  a_math_test_score:"",
  a_math_test_score_etc:"",
  a_en_issue:"",
  a_en_plan:"",
  a_en_action:"",
  a_en_attitude:"",
  a_en_ability:"",
  a_math_issue:"",
  a_math_plan:"",
  a_math_action:"",
  a_math_attitude:"",
  a_math_ability:"",
};
export const add_memo_select_arr={
  a_stu_think_type:[
    { "value": "R", "text": "R타입" },
    { "value": "U", "text": "U타입" },
    { "value": "T", "text": "T타입" },
    { "value": "A", "text": "A타입" },
  ],
  a_parent_edu_type:[
    { "value": "민주", "text": "민주적" },
    { "value": "독재", "text": "독재적" },
    { "value": "과잉", "text": "과잉적" },
    { "value": "방임", "text": "방임적" },
  ],
  a_attitude:[
    { "value": "100", "text": "A" },
    { "value": "90", "text": "B" },
    { "value": "80", "text": "C" },
    { "value": "70", "text": "D" },
    { "value": "60", "text": "E" },
  ],
  a_ability:[
    { "value": "100", "text": "A" },
    { "value": "90", "text": "B" },
    { "value": "80", "text": "C" },
    { "value": "70", "text": "D" },
    { "value": "60", "text": "E" },
  ],
}