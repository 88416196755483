import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import { ko } from "date-fns/esm/locale";
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import Style from "./css/style.module.css";
import ReceiverListComponent from "./area/receiver_list";

function SendSMSPopup(props:any) {
  let myProps = {
    callBackRefresh:()=>{},
    closePopup:()=>{},
    receive_arr:[],//[{phone_num:"",user_name:"",user_seq:""}]
    ...props
  };
  let user=useSelector((state:any) => state.user);

  const [msg_row,set_msg_row]=useState({
    "a_owner_mcomp_seq":user.comp_seq,
    "a_sender_mcomp_seq":user.comp_seq,
    "a_sender":user.user_name,
    "a_sender_user_seq":user.user_seq,
    "a_sender_phone_num":"16888764",
    "a_receiver_mcomp_seq":user.comp_seq,
    "a_title":"알림",
    "a_content":"",
    "a_is_mms":"",
  });
  const [mcomp_point,set_mcomp_point] = useState<any>(0);
  const [sender_tel_arr,set_sender_tel_arr]=useState([]);
  const ReceiverListComponentRef=useRef<any>(null);

  useEffect(()=>{
    
    getSenderPhoneNumberByAjax();
    getPointNumByAjax();
  },[]);

  const onChangeInputData=(key:string,value:string)=>{
    set_msg_row({
      ...msg_row,
      [key]:value
    });
  };

  const getPointNumByAjax=()=>{
    if(user.comp_seq){

    }else{
      return false;
    }
    let point_form_json={
      "s_pri_arr":[user.comp_seq],
      "is_addon_point_tot":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/mcomp/mcomp/list',point_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let corp_info=response.data["data"]["info_arr"][0];
          if(corp_info.point_tot_info){
            set_mcomp_point(corp_info.point_tot_info.tot);
          }
        }
      }else{
        
      }
    });
  };

  const getSenderPhoneNumberByAjax=()=>{
    let from_json_data={};
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms_sender/get_front_list',from_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_sender_tel_arr(response.data["data"]["select_row_arr"]);
        if(response.data["data"]["select_row_arr"].length>0){
          set_msg_row({
            ...msg_row,
            "a_sender_phone_num":response.data["data"]["select_row_arr"][0].value
          });
        }
      }
    });
  };

  const sendSmsMessage=()=>{
    if(msg_row.a_content==""){
      alert("내용 입력 필요.");
      return false;
    }
    let receiver_user_arr=[];
    if(ReceiverListComponentRef.current){
      receiver_user_arr=ReceiverListComponentRef.current.getReceiveArr();
    }
    if(receiver_user_arr.length==0){
      alert("받는사람이 없습니다.");
      return false;
    }
    for(let i=0;i<receiver_user_arr.length;i++){
      if(receiver_user_arr[i]["phone_num"].length<10){
        alert("전화번호가 올바르지 않습니다."+receiver_user_arr[i]["phone_num"]);
        return false;
      }
    }

    if(!confirm("메세지를 보내시겠습니까?")){
      return false;
    }
    
    let send_sms_form_json={
      "msg_row":msg_row,
      "receive_arr":receiver_user_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/sms/send_sms',send_sms_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.callBackRefresh();
        alert(response.data["msg"]);
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  return (
    <div>
      <div  className={Style.sms_box_wrap}>
        <div className={Style.left_user_box}>
          <div className="text-right">
            남은 포인트:<span className="ml-2" style={{color:"red"}}>{strFunc.comma(mcomp_point)}</span>
          </div>
          <div className="write-table-div mt-1">
            <table>
              <colgroup>
                <col width="20%"></col>
                <col width="80%"></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>발신자</th>
                  <td>
                    <input type="text" className="row-input" name="a_sender" value={msg_row.a_sender} 
                      onChange={(e:any)=>{onChangeInputData(e.target.name,e.target.value);}}
                      placeholder="발신자"
                      style={{width:"98%"}} />
                  </td>
                </tr>
                <tr>
                  <th>발신번호</th>
                  <td>
                    <select className="row-input" name="a_sender_phone_num" value={msg_row.a_sender_phone_num} 
                      onChange={(e:any)=>{onChangeInputData(e.target.name,e.target.value);}} >
                        <option value="">선택없음</option>
                        {
                          sender_tel_arr.map((item:any,idx:number)=>{
                            return (
                              <option key={idx} value={item.value} >{item.text}</option>
                            );
                          })
                        }
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ReceiverListComponent
            ref={ReceiverListComponentRef}
            receive_arr={myProps.receive_arr}
          ></ReceiverListComponent>
        </div>
        <div className={Style.right_sms_box}>
          <div className={Style.sms_content_title}>
            문자내용미리보기
          </div>
          <textarea className={Style.sms_content_input} 
            placeholder='문자 내용 입력'
            name="a_content"
            value={msg_row.a_content}
            onChange={(e:any)=>{onChangeInputData(e.target.name,e.target.value);}}></textarea>
        </div>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{sendSmsMessage();}}>전송</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default SendSMSPopup;
