function SearchArea(props:any){
   let myProps={
    list_opt:{},
    list:(inData:any)=>{},
    categoryInfoArr:[],
    ...props
  };
  
  const go_search=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;
    myProps.list({
      "now_page":"1",
      [name]:value
    });
  };

  return (
  <div>
    <select className="search-input_select" value={myProps.list_opt.s_category_seq} 
      name="s_category_seq"
      onChange={go_search}
      style={{width:80}}>
      <option value="">선택없음</option>
      {
        myProps.categoryInfoArr.map((item:any,idx:number)=>{
          return (
            <option key={idx} value={item.a_seq}>{item.a_title}</option>
          );
        })
      }
    </select>
    <input type="text" className="search-input" value={myProps.list_opt.s_title_like} name="s_title_like"
      onChange={go_search}
      placeholder="제목"
      style={{width:60}} />
    <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({});}}>검색</button>
  </div>
  );
};
export default SearchArea;