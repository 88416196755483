import Style from "../../../../../css/style.module.css";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

const DeskBoxArea = (props: any) => {
  let myProps: any = {
    x: 0,
    y: 0,
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    select_stu_data: {},
    open_find_stu_popup: (inData: any) => { },
    open_view_desk_popup: (inData: any) => { },
    open_select_stu_alloc_desk_popup: (inData: any) => { },
    ...props
  };
  if (myProps.now_ymdhis == "") {
    myProps.now_ymdhis = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
  }
  let now_date_json = DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec=now_date_json.date_obj.getTime()/1000;
  let desk_info = myProps.desk_info;
  let desku_info = myProps.desku_info;
  let desk_box_class_name = Style.desk_box;
  let desk_num_str = "";
  let desk_per_height = "0%";
  if (desk_info != null) {
    desk_num_str = desk_info["a_name"];
  } else {
    desk_box_class_name += " " + Style.desk_empty_area;
  }

  let user_name = "";//홍길동
  let per_num = 0;//35
  let remain_minute_num = 0;
  let per_target_num: any = "";// /90
  let time_period = "";//21:00 ~ 22:30
  let percent_num = 0;
  let desk_back_class_name = Style.desk_back_per;
  if (desku_info != null) {
    per_num = 0;
    if (myProps.now_ymdhis.substring(0, 10) == desku_info["a_date"]) {
      let start_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_start_h"],desku_info["a_start_m"])+":00";
      let start_date_json=DateFunc.get_date_json(new Date(start_date_str));
      let end_date_str=desku_info["a_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      if(!strFunc.is_empty(desku_info["a_end_date"])){
        end_date_str=desku_info["a_end_date"]+" "+strFunc.HourMinuteToTime(desku_info["a_end_h"],desku_info["a_end_m"])+":00";
      }
      let end_date_json=DateFunc.get_date_json(new Date(end_date_str));

      let start_sec = start_date_json.date_obj.getTime() / 1000;
      let end_sec = end_date_json.date_obj.getTime() / 1000;

      let target_time_sec = end_sec - start_sec;

      if (target_time_sec != 0) {
        per_target_num = target_time_sec;
      }
      if (now_sec >= start_sec && now_sec < end_sec) {
        per_num = now_sec - start_sec;
        remain_minute_num = Math.round(per_target_num - per_num);
        if (remain_minute_num != 0) {
          remain_minute_num = Math.ceil(remain_minute_num / 60);
        }
      } else if (now_sec >= end_sec) {
        per_num = per_target_num;
        remain_minute_num = 0;
      }
      per_num = Math.round(per_num);
    }

    user_name = desku_info["a_user_name"];
    if (per_num != 0 && per_target_num != 0) {
      percent_num = (per_num / per_target_num) * 100;
      percent_num = Math.round(strFunc.uncomma(percent_num));
    }
    if (percent_num > 80) {
      desk_back_class_name += " " + Style.is_ending;
    }
    desk_per_height = percent_num + "%";
    time_period = strFunc.HourMinuteToTime(desku_info["a_start_h"], desku_info["a_start_m"]);
    time_period += " ~ " + strFunc.HourMinuteToTime(desku_info["a_end_h"], desku_info["a_end_m"]);
  }

  let content_box_class_name = Style.desk_content_box;
  if (desku_info != null) {
    content_box_class_name += " " + Style.is_use_desk;
  }

  const open_view_desk_popup = () => {
    if (desku_info == null) {
      return false;
    }
    myProps.open_view_desk_popup({
      room_info: myProps.room_info,
      desk_info: desk_info,
      desku_info: desku_info,
      now_ymdhis: myProps.now_ymdhis,
      now_lecture_data: myProps.now_lecture_data,
    });
  };

  const get_find_stu_desk_box_button = () => {
    let find_btn_str: any = "";
    if (desk_info != null) {
      if (myProps.select_stu_data.is_select) {
        find_btn_str = (
          <div 
            className={Style.desk_user_find_wrap2}
            onClick={() => {
              myProps.open_select_stu_alloc_desk_popup({
                "room_info": myProps.room_info,
                "desk_info": desk_info,
                "now_ymdhis": myProps.now_ymdhis,
                "now_lecture_data": myProps.now_lecture_data,
              });
            }}>
            {desk_num_str} 번
            <div>
              선택
            </div>
          </div>
        );
      } else {
        find_btn_str = (
          <div className={Style.desk_user_find_wrap}>
            <button className="btn-s btn-dark" onClick={() => {
              myProps.open_find_stu_popup({
                "room_info": myProps.room_info,
                "desk_info": desk_info,
                "now_ymdhis": myProps.now_ymdhis,
                "now_lecture_data": myProps.now_lecture_data,
              });
            }}>학생찾기</button>
          </div>
        );
      }

    }
    return find_btn_str;
  };

  return (
    <div className={desk_box_class_name}>
      <div className={desk_back_class_name} style={{ height: desk_per_height }}></div>
      <div className={content_box_class_name} onClick={() => { open_view_desk_popup(); }}>
        <div className={Style.desk_content_box_con}>
          <span className={Style.desk_num_span}>{desk_num_str}</span>
          {desku_info != null ?
            <>
              <div className={Style.desk_per_div}>
                <span className={Style.desk_per_div_num}>
                  {remain_minute_num}
                </span>
                분
              </div>
              <span className={Style.desk_user_name}>{user_name}</span>
              <div className={Style.desk_time_period}>
                {time_period}
              </div>
            </>
            :
            get_find_stu_desk_box_button()
          }
        </div>
      </div>
    </div>
  );
};
export default DeskBoxArea;