import React,{ useState, useRef, useEffect,forwardRef,useImperativeHandle } from 'react';
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import MyScreenFunc from "@/pcomponents/common/screen/func/screen_func";

const StudentArea = forwardRef((props:any, ref) => {
  let myProps={
    rowData:{},
    setRowData:(inData:any)=>{},
    handleInputChange:(e:any)=>{},
    onChangeDatePicker:(key:string,date:Date)=>{},
    xColumnArr:{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const [stu_num_msg_data,set_stu_num_msg_data]=useState({
    is_show:false,
    msg:"사용가능",
    color:"green",
  });

  useImperativeHandle(ref, () => ({
    checkStuNumOfChildUser
  }));

  useEffect(()=>{
    
  },[]);

  const checkStuNumOfChildUser=(stu_num:string)=>{
    if(stu_num==""||stu_num=="0"){
      return false;
    }
    stu_num=strFunc.uncomma(stu_num);

    //이미 있는지 체크
    let checkFormData={
      "s_stu_num_now":stu_num,
      "s_except_user_seq":myProps.rowData.a_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',checkFormData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length!=0){
          let u_info=response.data["data"]["info_arr"][0];
          set_stu_num_msg_data({
            is_show:true,
            msg:"해당 출결번호는 이미있는 있습니다. "+u_info["a_user_name"]+"",
            color:"#ff5757",
          });
          // myProps.setRowData({
          //   ...myProps.rowData,
          //   "a_stu_num":""
          // });
        }else{
          set_stu_num_msg_data({
            is_show:true,
            msg:"사용가능",
            color:"green",
          });
        }
      }
    });
  };

  const makeInviteCode=()=>{
    if(myProps.rowData.a_seq==""){
      alert("유저키 없음.");
      return false;
    }
    if(!confirm("초대코드를 생성 하시겠습니까?")){
      return false;
    }
    let inivte_make_code_from_data={
      "mcomp_seq":"",
      "user_seq":myProps.rowData.a_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/invite_code/make_code',
    inivte_make_code_from_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.setRowData({
          ...myProps.rowData,
          ...{"invite_code": response.data["data"]["invite_code"]}
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const supply_d_arr:number[]=[];
  for(let i=1;i<=30;i++){
    supply_d_arr.push(i);
  }
  const supply_d_options=supply_d_arr.map((item:any,idx:number)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  let is_under=MyScreenFunc.get_is_under();
  let split_num=2;
  if(is_under){
    split_num=1;
  }
  let col_tag_num_arr=[];
  for(let i=0;i<split_num;i++){
    col_tag_num_arr.push(i);
  }

  let th_td_tags=[
    <>
      <th>출결번호</th>
      <td>
        <input type="text" className="row-input" name="a_stu_num" value={strFunc.str_pad({"str":strFunc.uncomma(myProps.rowData.a_stu_num),"pad_length":user.stu_num_ciphers})} 
          onChange={(e:any)=>{
            myProps.handleInputChange(e);
            if(e.target.value!=""){
              checkStuNumOfChildUser(e.target.value);
            }
          }}
          onClick={(e:any)=>{
            e.target.select();
          }}
          placeholder="출결번호" style={{width:120}}/>
        <button className="btn-m3 btn-line-yellow-green ml-1" onClick={()=>{checkStuNumOfChildUser(myProps.rowData.a_stu_num);}} >중복체크</button>
        
        {stu_num_msg_data.is_show&&
          <div>
            <span style={{color:stu_num_msg_data.color}}>{stu_num_msg_data.msg}</span>
          </div>
        }
      </td>
    </>,
    <>
      <th>성별</th>
      <td>
        <RadioListArea
          valueTextArr={props.xColumnArr.select_arr.a_gender}
          value={myProps.rowData.a_gender}
          name="a_gender"
          handleInputChange={myProps.handleInputChange}
        ></RadioListArea>
      </td>
    </>,
    <>
      <th>학년</th>
      <td>
        <select className="row-input" name="a_stu_grade" value={myProps.rowData.a_stu_grade} onChange={myProps.handleInputChange}>
          <option value="">선택없음</option>
          {
            props.xColumnArr.select_arr.a_stu_grade.map((item:any,idx:number)=>{
              return (
                <option key={idx} value={item.value}>{item.text}</option>
              );
            })
          }
        </select>
      </td>
    </>,
    <>
      <th>학교</th>
      <td>
        <input type="text" className="row-input" name="a_school_name" value={myProps.rowData.a_school_name} onChange={myProps.handleInputChange} placeholder="학교" />
      </td>
    </>,
    <>
      <th>청구일</th>
      <td>
        <select className="row-input" name="a_stu_supply_day" value={myProps.rowData.a_stu_supply_day} onChange={myProps.handleInputChange}>
          <option value="">선택없음</option>
          {supply_d_options}
        </select>
      </td>
    </>,
    <>
      <th>수납기본할인</th>
      <td>
        <input type="text" className="row-input" name="a_stu_supply_discount" 
          value={myProps.rowData.a_stu_supply_discount} onChange={myProps.handleInputChange} placeholder="할인가"
          style={{textAlign:"center",width:130}} /> 원
      </td>
    </>,
    <>
      <th>입학일</th>
      <td>
        <DatePicker 
          selected={myProps.rowData.a_join_date!=""?new Date(myProps.rowData.a_join_date):null} 
          onChange={(date:Date) => {
            myProps.onChangeDatePicker("a_join_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputWrite />}
          renderCustomHeader={customHeaderFunc}
        />
      </td>
    </>,
    <>
    <th>생년월일</th>
    <td>
      <DatePicker 
        selected={myProps.rowData.a_stu_birth_date!=""?new Date(myProps.rowData.a_stu_birth_date):null} 
        onChange={(date:Date) => {
          myProps.onChangeDatePicker("a_stu_birth_date",date);
        }}
        locale={ko} 
        dateFormat="yyyy-MM-dd"
        customInput={<CustomInputWrite />}
        renderCustomHeader={customHeaderFunc}
      />
    </td>
  </>,
    <>
      <th style={{fontSize:"12px"}}>현금영수증구분</th>
      <td>
        <input type="text" className="row-input" name="a_cash_receipt_sort" 
          value={myProps.rowData.a_cash_receipt_sort} onChange={myProps.handleInputChange} placeholder="현금영수증구분"
           /> 
      </td>
    </>,
    <>
      <th style={{fontSize:"11px"}}>현금영수증발급번호</th>
      <td>
        <input type="text" className="row-input" name="a_cash_receipt_num" 
          value={myProps.rowData.a_cash_receipt_num} onChange={myProps.handleInputChange} placeholder="현금영수증발급번호"
          /> 
      </td>
    </>,
    <>
      <th>메모</th>
      <td colSpan={3}>
        <textarea className="row-input" name="a_memo" value={myProps.rowData.a_memo} 
          onChange={myProps.handleInputChange} placeholder="메모" 
          style={{height:100}}></textarea>
      </td>
    </>,
  ];

  const getTrTags=()=>{
    let tr_len=0;
    if(th_td_tags.length>0){
      tr_len=Math.round(th_td_tags.length/split_num);
    }
    let  tr_num_arr=[];
    for(let i=0;i<tr_len;i++){
      tr_num_arr.push(i);
    }
    let tr_tags=tr_num_arr.map((item:any,idx:number)=>{
      let start_th_td_num=idx*split_num;
      let max_th_td_num=start_th_td_num+split_num;
      let row_th_td_tags=th_td_tags.map((td_th:any,td_th_idx:number)=>{
        if(start_th_td_num<=td_th_idx&&td_th_idx<max_th_td_num){

        }else{
          return "";
        }
        return (
          <React.Fragment key={td_th_idx}>
            {td_th}
          </React.Fragment>
        );
      });
      
      return (
        <tr key={idx}>
          {row_th_td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div className="write-table-div mt-1">
      <table>
        <colgroup>
          {col_tag_num_arr.map((item:any,idx:number)=>{
            return (
              <React.Fragment key={idx}>
                <col width="130px"></col>
                <col width="*"></col>
              </React.Fragment>
            );
          })}
        </colgroup>
        <tbody>
          {getTrTags()}
          {myProps.rowData.a_seq!=""&&
          <tr>
            <th>초대코드</th>
            <td colSpan={3}>
              {myProps.rowData["comp_link_info"]==undefined?
              <div>
                {myProps.rowData["invite_code"]}
                <button className="btn btn-dark ml-2" onClick={()=>{makeInviteCode();}}>생성</button>
              </div>
              :
              <div style={{color:"blue"}}>
                어플가입됨 ({myProps.rowData["comp_link_info"]["a_main_user_name"]})
              </div>
              }
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  );
});
export default StudentArea;