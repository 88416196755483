import strFunc from "@/lib/lyg/string";
import CardBox from "./card_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DateFunc from '@/lib/lyg/date_func';
import UserGroupDataFunc from "@/pcomponents/common/content/list/user/search/tab_area/func/group_data";

function CardListArea(props: any) {
  let myProps = {
    infoArr: [],
    tab_id: "",
    Style: {},
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    base_date_str: "",
    openTodoDaySort: (inData: any) => { },
    xColumnArr: {},
    deleteAttend: (inData: any) => { },
    openAttendPopup: (inData: any) => { },
    openTodoWriteOnePopup:(inData: any) => { },
    show_detail_user_by_seq: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let base_date_str = myProps.base_date_str;
  let tmp_start_date_json = DateFunc.get_date_json(new Date(base_date_str));
  let pre_date_change_num = -1;
  if (tmp_start_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(base_date_str), 'day', pre_date_change_num), "Y-m-d");

  const on_change_group_checkbox = (inData: any) => {
    let opt_obj = {
      checked: false,
      info_arr: [],
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["info_arr"])) {
      return false;
    }

    if (opt_obj["checked"]) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) != -1) {

        } else {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const get_cardbox_tags = (infoArr: []) => {
    let cardbox_tags: any = infoArr.map((item: any, idx: number) => {
      return (
        <CardBox key={idx}
          info={item}
          Style={Style}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          openTodoDaySort={myProps.openTodoDaySort}
          base_date_str={base_date_str}
          pre_date_str={pre_date_str}
          xColumnArr={myProps.xColumnArr}
          deleteAttend={myProps.deleteAttend}
          openAttendPopup={myProps.openAttendPopup}
          openTodoWriteOnePopup={myProps.openTodoWriteOnePopup}
          show_detail_user_by_seq={myProps.show_detail_user_by_seq}
        ></CardBox>
      );
    });

    if (strFunc.is_empty(cardbox_tags)) {
      cardbox_tags = (
        <div className="text-center" style={{ lineHeight: "50px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return cardbox_tags;
  };

  const get_cardbox_group_tags = () => {
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date:myProps.base_date_str,
      info_arr:myProps.infoArr,
      tab_id:myProps.tab_id,
    });

    let cardbox_group_tags = group_data_arr.map((group_info: any, idx: number) => {
      let group_title_str = group_info["title"];
      if (myProps.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_option_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      return (
        <div key={idx}>
          <div>
            <input type="checkbox" className={Style.card_group_checkbox} onChange={(e: any) => {
              on_change_group_checkbox({
                checked: e.target.checked,
                info_arr: group_info["info_arr"]
              });
            }} />
            {group_title_str}
            <span className="ml-1" style={{ color: "#777" }}>
              ({group_info["info_arr"].length})
            </span>
          </div>
          <div className={Style.card_list_wrap + " mt-5"}>
            {get_cardbox_tags(group_info["info_arr"])}
          </div>
        </div>
      );
    });

    if (strFunc.is_empty(cardbox_group_tags)) {
      cardbox_group_tags = (
        <div className="text-center" style={{ lineHeight: "50px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return cardbox_group_tags;
  };

  return (
    <div className={Style.card_list_wrap + " mt-2"}>
      {get_cardbox_group_tags()}
    </div>
  );
}
export default CardListArea;