import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"video_list",
    list_id:"video_frame_list",
    x_column_list_arr:[
      {"key":"a_section_seq","name":"대단원","width":"100","is_show":"1"},
      // {"key":"a_is_url","name":"URL여부","width":"80","is_show":"1"},
      // {"key":"a_url_sort","name":"URL종류","width":"100","is_show":"1"},
      {"key":"a_title","name":"제목","width":"140","is_show":"1"},
      {"key":"a_url","name":"주소","width":"350","is_show":"1"},
      {"key":"row_view_preview","name":"미리보기","width":"70","is_show":"1"},
      {"key":"a_is_essential","name":"필수여부","width":"70","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"140","is_show":"1"},
      {"key":"a_total_sec","name":"총시간","width":"100","is_show":"1"},
      {"key":"a_complete_sec","name":"수료시간","width":"100","is_show":"1"},
      {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_title',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;