import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import { useLocation,useNavigate } from "react-router";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import Style from "./css/style.module.css";
import TitleArea from "./area/title";
import ClockeArea from "./area/clock";
import ContentArea from "./area/content";
import strFunc from '@/lib/lyg/string';
import FileArea from "./area/file_area";
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ProgressAmountPopup from "../popup/progress_amount";

function TodoListView(){
  const navigate = useNavigate();
  let user=useSelector((state:any) => state.user);
  const { state } = useLocation();
  const [row_data,set_row_data]= useState(state.row_data);
  const TitleAreaRef=useRef<any>(null);
  const ClockeAreaRef=useRef<any>(null);
  const ContentAreaRef=useRef<any>(null);
  const FileAreaRef=useRef<any>(null);
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const [is_first_action,set_first_action]=useState(false);
  
  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"progress",//view
    isUpdate:false,
    rowData:{},
    title:"진행도",
    width:"400px",
    height:"50%",
  });

  useEffect(() => {
    
    if(row_data.file_arr){
      if(FileAreaRef.current){
        FileAreaRef.current.setInitData({
          "file_arr":row_data.file_arr
        });
      }
    }
    if(is_first_action==false){
      let a_time_sec=parseInt(strFunc.uncomma(row_data.a_time_sec));
      if(row_data.a_state=="ing"&&row_data.a_start_date_last){
        //진행시간 업데이트
        let last_date_sec=(new Date(row_data.a_start_date_last).getTime())/1000;
        let now_date_sec=(new Date().getTime())/1000;
        let plus_sec=now_date_sec-last_date_sec;
        if(localStorage.server_time_gap){
          plus_sec-=parseInt(localStorage.server_time_gap);
        }
        a_time_sec=a_time_sec+plus_sec;
        a_time_sec=Math.round(a_time_sec);

        set_row_data({
          ...row_data,
          ...{
            a_time_sec:a_time_sec
          }
        });
        set_first_action(true);
        if(TitleAreaRef.current){
          TitleAreaRef.current.setInitData({"row_data":{
            ...row_data,
            ...{
              a_time_sec:a_time_sec
            }
          }});
        }
        if(ClockeAreaRef.current){
          ClockeAreaRef.current.setInitData({"row_data":{
            ...row_data,
            ...{
              a_time_sec:a_time_sec
            }
          }});
        }
      }
    }
  }, [is_first_action,row_data]);

  const onStart=()=>{
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let up_form_data={
      "todo_seq_arr":[row_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/start',up_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const onStop=()=>{
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let up_form_data={
      "todo_seq_arr":[row_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/end',up_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const completeOne = ()=>{
    if(!confirm("완료처리 하시겠습니까?")){
      return false;
    }
    if(user.user_seq==""){
      return false;
    }
    if(strFunc.is_empty(row_data.a_seq)){
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let del_form_data={
      "todo_seq_arr":[row_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/complete',del_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        navigate("/comp_stu/plan/todo_list");
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWritefile=()=>{
    if(fileInput.current){
      if((fileInput.current as HTMLInputElement).value==""){
        alert("파일 선택이 없습니다.");
        return false;
      }
    }
    if(!confirm("파일을 등록 하시겠습니까?")){
      return false;
    }
    if(writeFileForm.current==null){
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data= new FormData(form);
    let file_data_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_comp_seq":0,
      "a_par_id":"todo",
      "a_par_seq":row_data.a_ymd+","+row_data.a_seq,
      "a_sort1":"result",
      "a_sort2":"",
      "a_sort3":"",
      "a_writer":user.user_name,
      "a_create_seq":user.user_seq,
    };
    form_json_data.append("data_arr",JSON.stringify([file_data_row]));
    form_json_data.append("authorization",localStorage.intoyou_token);

    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current){
          (fileInput.current as HTMLInputElement).value="";
        }
        alert(response.data["msg"]);
        goRefresh();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goRefresh=()=>{
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let form_json_data={
      "s_pri_arr":[row_pri_val],
      "s_addon_file_arr":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          set_row_data(response.data["data"]["info_arr"][0]);
          if(TitleAreaRef.current){
            TitleAreaRef.current.setInitData({"row_data":response.data["data"]["info_arr"][0]});
          }
          if(ClockeAreaRef.current){
            ClockeAreaRef.current.setInitData({"row_data":response.data["data"]["info_arr"][0]});
          }
          if(ContentAreaRef.current){
            ContentAreaRef.current.setInitData({
              "row_data":response.data["data"]["info_arr"][0]
            });
          }
        }else{
          alert("조회 내용이 없습니다.");
          navigate("/comp_stu/plan/todo_list");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const openProgressPopup=()=>{
    let pop_title="진행도";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "rowData":row_data,
      "title":pop_title,
      "sort":"progress"
    });
  };

  return (
    <CompLayout isLeftArea={false} isConTitle={false}>
      <div className={Style.view_wrap} >
        <TitleArea
          ref={TitleAreaRef}
          row_data={row_data}
        ></TitleArea>
        <ClockeArea
          ref={ClockeAreaRef}
          row_data={row_data}
          set_row_data={set_row_data}
          onStart={onStart}
          onStop={onStop}
        ></ClockeArea>
      </div>
      <ContentArea
        ref={ContentAreaRef}
        row_data={row_data}
      ></ContentArea>
      <FileArea
        ref={FileAreaRef}
      ></FileArea>
      <div className="p-2">
        <p>결과파일첨부</p>
        <table style={{width:"100%"}}>
          <colgroup>
            <col width="200px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <form id="write_form" method="post" ref={writeFileForm} >
                  <input type="file" id="files" className="row-input mt-1" name="input_file[]" ref={fileInput} style={{width:200}} />
                </form>
              </td>
              <td>
                <button className="btn btn-dark" onClick={()=>{goWritefile();}}>저장</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-dark" onClick={()=>{navigate("/comp_stu/plan/todo_list");}}>뒤로</button>
        <button className="btn btn-dark" onClick={()=>{openProgressPopup();}}>진행도</button>
        {row_data.a_is_success!="1"&&
          <button className="btn btn-dark" onClick={()=>{completeOne();}}>완료</button>
        }
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="progress"&&
            <ProgressAmountPopup rowData={popupData.rowData} 
              callback={()=>{goRefresh();}} 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></ProgressAmountPopup>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default TodoListView;