import Style from "./css/style.module.css";
import { useState, useRef, useEffect } from 'react';
import { default_info, default_add_memo_info } from "./data/default_data";
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import ViewInfoArea from "./area/view_info";
import CounselDetailArea from "./area/counsel_detail";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ModifyClassOfStuOnePopup from "@/pages/comp/basic/student/popup/modify_class_of_stu_one";

function TopStuInfoArea(props: any) {
  let myProps = {
    stu_seq: "",
    callback_refresh:()=>{},
    ...props
  };
  const [info, set_info] = useState(default_info);
  const [add_memo_info, set_add_memo_info] = useState(default_add_memo_info);
  const [is_view_detail, set_is_view_detail] = useState(true);
  const [is_view_mode, set_is_view_mode] = useState(true);

  useEffect(() => {
    
    get_user_info_by_ajax();
  }, [myProps.stu_seq]);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "modify_class",
    stu_seq: "",
    title: "팝업",
    width: "800px",
    height: "85%",
  });

  const get_user_info_by_ajax = () => {
    if (strFunc.is_empty(myProps.stu_seq)) {
      set_info(default_info);
      set_add_memo_info(default_add_memo_info);
      return false;
    }
    let user_info_list_opt = {
      "s_pri_arr": [myProps.stu_seq],
      s_addon_class_user: "1",
      s_addon_link_user: "1",
      s_addon_profile: "1",
      s_addon_counsel_file:"1",
      s_addon_invite_code: "1",
      s_addon_comp_link: "1",
      s_addon_add_memo: "1",
      "is_need_count": "1",
      "is_need_info_arr": "1",
      "is_no_limit": "",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', user_info_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let row_user_info = response.data["data"]["info_arr"][0];
            set_info(row_user_info);
            set_add_memo_info({
              ...default_add_memo_info,
              ...(row_user_info.add_memo_info ? row_user_info.add_memo_info : {})
            });
          } else {
            set_info(default_info);
            set_add_memo_info(default_add_memo_info);
          }
        } else {

        }
      });
  };

  const go_write_user_data=()=>{
    if(!confirm("저장 하시겠습니까?")){
      return false;
    }

    let user_write_form_json={
      "data_arr":[info],
      "is_update":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', user_write_form_json,get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        let row_user_info=response.data["data"][0];
        go_write_add_memo_info({
          a_user_seq:row_user_info["a_seq"],
        });
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const go_write_add_memo_info=(inData:any)=>{
    let row_add_memo_info={
      ...add_memo_info,
      a_user_seq:info.a_seq,
      a_user_name:info.a_user_name,
      ...inData,
    };
    let user_write_form_json={
      "data_arr":[row_add_memo_info],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user_add_memo/write', user_write_form_json,get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        alert("저장 되었습니다.");
        myProps.callback_refresh();
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const openModifyClassPopup = () => {
    let pop_title = "클래스관리";

    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "modify_class",
      title: pop_title,
      stu_seq: info.a_seq
    });
  };

  return (
    <div>
      <div className="text-right" style={{display:strFunc.is_empty(info["a_seq"])?"none":""}}>
        {is_view_mode==false&&
        <button className="btn btn-sky" onClick={()=>{go_write_user_data();}}>수정</button>
        }
        <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
          {is_view_mode?"수정":"보기"}모드
        </button>
      </div>
      <ViewInfoArea
        info={info}
        set_info={set_info}
        add_memo_info={add_memo_info}
        set_add_memo_info={set_add_memo_info}
        refresh_data={get_user_info_by_ajax}
        openModifyClassPopup={openModifyClassPopup}
        is_view_mode={is_view_mode}
        Style={Style}
      ></ViewInfoArea>
      {is_view_detail &&
        <CounselDetailArea
          info={info}
          set_info={set_info}
          add_memo_info={add_memo_info}
          set_add_memo_info={set_add_memo_info}
          is_view_mode={is_view_mode}
          refresh_data={get_user_info_by_ajax}
        ></CounselDetailArea>
      }
      <div className="text-right">
        <button className="btn-s btn-gray" style={{ width: "50px" }}
          onClick={() => { set_is_view_detail(!is_view_detail); }}>
          {is_view_detail ? "▲" : "▼"}
        </button>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "modify_class" &&
            <ModifyClassOfStuOnePopup
              stu_seq={popupData.stu_seq}
              callback={() => { get_user_info_by_ajax(); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ModifyClassOfStuOnePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default TopStuInfoArea;