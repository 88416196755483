import ReportWriteComponent from "../../component/write";

function ReportItemArea(props:any){
   let myProps={
    info:null,
    row_num:0,
    set_info:(info:any)=>{},
    is_view_mode:true,
    Style:{},
    goUpdate:(info:any)=>{},
    goDelete:(info:any)=>{},
    ...props
  };
  
  return (
  <div className="mt-1">
    <div style={{position:"relative"}}>
      No. {myProps.row_num+1}
      <span style={{position:"absolute",right:0,top:0}}>
        {myProps.info["a_writer"]}
      </span>
    </div>
    <ReportWriteComponent
      row_data={myProps.info}
      set_row_data={(inData:any)=>{myProps.set_info(inData);}}
      is_view_mode={myProps.is_view_mode}
    ></ReportWriteComponent>
    {myProps.is_view_mode==false&&
    <div className="text-center mt-1">
      <button className="btn-s btn-sky" onClick={()=>{myProps.goUpdate([myProps.info]);}} >저장</button>
      <button className="btn-s btn-red ml-1" onClick={()=>{myProps.goDelete([myProps.info]);}} >삭제</button>
    </div>
    }
  </div>
  );
};
export default ReportItemArea;