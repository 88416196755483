import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from "@/pages/comp/edu/class/popup/write";
import StudentList from './area/stu_list';

function ListPage(){
  const StudentListRef=useRef<any>(null);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_addon_class_user":"1",
      "s_is_supply_money":"1"
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    stu_seq:"",
    stu_name:"",
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    if(sort=="write"){
      if(isUpdate==false){
        if(pageData.listOpt.s_stu_seq==""){
          alert("원생 선택이 필요합니다.");
          return false;
        }
      }
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      "title":pop_title,
      "sort":sort
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_manage"){
      render_str=(
        <div>
          <button className="btn btn-dark" onClick={()=>{setTimeout(()=>{openOnePopup("write",true);},100);}}>보기</button>
        </div>
      );
    }else if(key=="row_view_stu_cnt"){
      if(params.data.student_arr){
        render_str=params.data.student_arr.length+" 명";
      }
    }else if(key=="row_view_teacher"){
      if(params.data.teacher_arr){
        let teacher_name_arr=[];
        for(let i=0;i<params.data.teacher_arr.length;i++){
          teacher_name_arr.push(params.data.teacher_arr[i]["a_user_name"]);
        }
        render_str=teacher_name_arr.join(",");
      }
    }else if(key=="a_is_supply_money"){
      if(render_str=="1"){
        render_str="예";
      }else{
        render_str="아니오";
      }
    }
    return render_str;
  }, []);

  const onSelectionChanged=(event:any)=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    let select_seq_arr=[];
    if(selected_row){
      for(let i=0;i<selected_row.length;i++){
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    let class_seq_arr=[];
    for(let i=0;i<select_seq_arr.length;i++){
      class_seq_arr.push(select_seq_arr[i]);
    }
    if(StudentListRef.current){
      StudentListRef.current.list({
        "s_class_seq":class_seq_arr,
      });
    }
  };

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{width:450}}>
            <div className="relative" style={{minHeight:23}}>
              <div className="search-box relative py-0 ml-0 mt-1">
                <select className="search-input_select" name="s_is_supply_money" onChange={(e:any)=>{
                  list({
                    "now_page":"1",
                    "s_is_supply_money":e.target.value
                  });
                }}>
                  <option value="" >청구여부</option>
                  <option value="1">예</option>
                  <option value="empty">아니오</option>
                </select>
                <button className="btn btn-gray" onClick={()=>{list({"now_page":1});}} >검색</button>
              </div>
            </div>
            <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num:false,
              is_add_checkbox:true,
              floatingFilter:false,
              onGridReady:()=>{
                list({});
              },
              onCellClicked:cellClickedListener,
              cellRenderer:cellRenderer,
              onSelectionChanged:onSelectionChanged
            }}
            ></ListAggrid>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
          </div>
          <div className="grow">
            <StudentList
              ref={StudentListRef}
              list={(inOptObj:any)=>{list(inOptObj);}}
            ></StudentList>
          </div>
        </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
        {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }

      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;