function CheckListArea(props: any) {
  let myProps = {
    check_arr: [],
    select_cate_idx:0,
    cate_info:null,
    on_select_check: (inData: any) => { },
    Style: {},
    ...props
  };
  let Style = myProps.Style;

  const get_check_tags=()=>{
    let check_tags=myProps.check_arr.map((item:any,idx:number)=>{
      return (
        <tr key={idx}>
          <td className={Style.check_title_wrap} 
            onClick={()=>{
              myProps.on_select_check({
                cate_info:myProps.cate_info,
                check_info_arr:[item],
                select_cate_idx:myProps.select_cate_idx,
                select_check_idx:idx,
              });
            }}>
            {idx+1}{") "}
            {item.a_title}
          </td>
          <td className="text-center">
            <button className="btn btn-sky"
              onClick={()=>{
                myProps.on_select_check({
                  cate_info:myProps.cate_info,
                  check_info_arr:[item],
                  select_cate_idx:myProps.select_cate_idx,
                  select_check_idx:idx,
                });
              }}
              style={{width:"90%"}}
            >선택</button>
          </td>
        </tr>
      );
    });
    return check_tags;
  };

  return (
    <div className="p-2">
      <table>
        <colgroup>
          <col width={"*"}></col>
          <col width={70}></col>
        </colgroup>
        <tbody>
          {get_check_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default CheckListArea;