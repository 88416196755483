import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import SelectSubjectComponent from "./select_subject";

function ContentArea2(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  return (
    <div className={Style.content_wrap}>
      <div className={Style.title_wrap}>
        <span className={Style.title_num}>2</span>
        <span className={Style.title_text}>학생부 교과/비교과 설계</span>
      </div>
      <div>
        <table className={Style.goal_table} style={{ marginTop: 5 }}>
          <colgroup>
            <col width="10%"></col>
            <col width="25%"></col>
            <col width="45%"></col>
            <col width="15%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div className={Style.goal_title_box}>
                  교과
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  <div style={{ lineHeight: "20px", paddingTop: 7 }}>
                    <SelectSubjectComponent
                      prefix_str={"1. "}
                      select_arr={[
                        { text: "국어", value: "국어" },
                        { text: "영어", value: "영어" },
                        { text: "수학", value: "수학" },
                      ]}
                      value={info.a_subject1}
                      name={"a_subject1"}
                      is_view={is_view_mode}
                      on_change={(inData: any) => {
                        if (is_view_mode == false) {
                          handleInputChange({ name: "a_subject1", value: inData.value });
                        }
                      }}
                    ></SelectSubjectComponent>
                    <div className="mt-1">
                      <SelectSubjectComponent
                        prefix_str={"2. "}
                        select_arr={[
                          { text: "국어", value: "국어" },
                          { text: "영어", value: "영어" },
                          { text: "수학", value: "수학" },
                        ]}
                        value={info.a_subject2}
                        name={"a_subject2"}
                        is_view={is_view_mode}
                        on_change={(inData: any) => {
                          if (is_view_mode == false) {
                            handleInputChange({ name: "a_subject2", value: inData.value });
                          }
                        }}
                      ></SelectSubjectComponent>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <div style={{ paddingLeft: 10, paddingTop: 7, textAlign: "left" }}>
                      <div>사회: {info.a_subject_society}</div>
                      <div>과학: {info.a_subject_science}</div>
                    </div>
                    :
                    <div style={{ paddingLeft: 10, paddingTop: 7, textAlign: "left" }}>
                      <div>
                        사회: <input type="text" className="row-input" value={info.a_subject_society}
                          onChange={(e: any) => { handleInputChange({ name: "a_subject_society", value: e.target.value }); }}
                          placeholder="사회" style={{ width: "80%", textAlign: "left" }} />
                      </div>
                      <div>
                        과학: <input type="text" className="row-input" value={info.a_subject_science}
                          onChange={(e: any) => { handleInputChange({ name: "a_subject_science", value: e.target.value }); }}
                          placeholder="과학" style={{ width: "80%", textAlign: "left" }} />
                      </div>
                    </div>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    강점과목 우선
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    상승과목 차선
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    전공적합성 고려
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={Style.goal_title_box}>
                  비교과
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme1}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme1}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme1", value: e.target.value }); }}
                      placeholder="Thema A"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme1_content}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme1_content}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme1_content", value: e.target.value }); }}
                      placeholder="Thema A"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    전공관련 활동
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    동아리/자율활동
                  </div>
                  <div className={Style.goal_box_explan_row}>중심</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>

              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme2}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme2}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme2", value: e.target.value }); }}
                      placeholder="Thema B"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme2_content}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme2_content}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme2_content", value: e.target.value }); }}
                      placeholder="Thema B"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    목표 직업 고려
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    필요능력 중심
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    동일전공 차별성
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>

              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme3}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme3}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme3", value: e.target.value }); }}
                      placeholder="Thema C"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky}>
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_theme3_content}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_theme3_content}
                      onChange={(e: any) => { handleInputChange({ name: "a_theme3_content", value: e.target.value }); }}
                      placeholder="Thema C"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    목표 직업 고려
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    필요능력 중심
                  </div>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    동일전공 차별성
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ContentArea2;