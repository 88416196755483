import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function FolderManageList(props: any) {
  let myProps = {
    list: (inData:any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_addon_lecture":"1",
    "s_addon_stu":"1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [backup_folder_name,set_backup_folder_name]=useState("백업"+DateFunc.get_date_format(new Date(),"Ymd_his"));

  useEffect(() => {

  }, []);

  const list = (inData: any) => {
    inData["sc"] = listAggridRef.current.getListSc();
    let form_json_data = {
      ...listOpt,
      ...inData,
    };
    setListOpt(form_json_data);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_backup/folder/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let del_form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_backup/folder/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goBackup=()=>{
    if(!confirm("백업을 생성 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "backup_folder_name":backup_folder_name
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_backup/folder/backup', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("백업완료 되었습니다.");
          set_backup_folder_name("백업"+DateFunc.get_date_format(new Date(),"Ymd_his"));
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goRestore=()=>{
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (selected_row.length !== 1) {
      alert("한개만 선택해 주세요.");
      return false;
    }
    if(!confirm("시간표를 복구 하시겠습니까?")){
      return false;
    }
    let folder_info=selected_row[0];
    let del_form_data = {
      "folder_seq": folder_info["a_seq"]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture_backup/folder/restore_from_folder', 
      del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("복구 되었습니다.");
          list({});
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if(key=="row_view_lecture_cnt"){
      render_str="0";
      if(params.data.lecture_arr&&params.data.lecture_arr.length>0){
        render_str=params.data.lecture_arr.length;
      }
      render_str=(
        <div className="text-center">
          {render_str}
        </div>
      );
    }else if(key=="row_view_stu_cnt"){
      render_str="0";
      if(params.data.stu_arr&&params.data.stu_arr.length>0){
        render_str=params.data.stu_arr.length;
      }
      render_str=(
        <div className="text-center">
          {render_str}
        </div>
      );
    }

    return render_str;
  }, []);

  return (
    <div>
      <div className="text-right">
        <input type="text" value={backup_folder_name} 
          onChange={(e:any)=>{
            set_backup_folder_name(e.target.value);
          }} 
          style={{width:150,padding:"0px 3px"}}/>
        <button className="btn btn-dark ml-1" onClick={goBackup}>백업생성</button>
        <button className="btn btn-dark ml-1" onClick={goRestore} >불러오기</button>
        <button className="btn btn-red ml-1" onClick={goDelete}>삭제</button>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: true,
          floatingFilter: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        is_view_num_per_page={true}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
};
export default FolderManageList;