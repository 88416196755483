import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayOfWeekPick from "./area/day_of_week_pick";
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import WriteListArea from "./area/write_list";
import MakeCounselTodoFunc from "./func/make_counsel_todo_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStuListComponet from "@/pages/comp/basic/student/popup/find_multy";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";

const WriteCounselTodoPopup = forwardRef((props: any, ref) => {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    stu_info_arr: [],
    start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const WriteListAreaRef = useRef<any>(null);
  const WriteListAreaAfterRef = useRef<any>(null);
  const [stu_info_arr, set_stu_info_arr] = useState(myProps.stu_info_arr);
  const [teacher_info_arr, set_teacher_info_arr] = useState<any>([]);
  const [write_data, set_write_data] = useState({
    start_date: myProps.start_date,
    end_date: myProps.end_date,
    apply_day: "1,2,3,4,5",
    one_day_amount: 5,
    a_counseler_seq: user.user_seq,
    a_counseler: user.user_name,
    a_kind: "학습",
    a_reserve_time: "00:00:00",
  });
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_stu",//view_ctodo
    list_opt: {},
    title: "찾기",
    width: "550px",
    height: "60%",
  });

  const default_ctodo = {
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_is_public: "1",
    a_writer_seq: user.user_seq,
    a_writer: user.user_name,
    a_par_id: "",
    a_par_seq: "",
    a_stu_seq: "",
    a_stu_name: "",
    a_counseler_seq: user.user_seq,
    a_counseler: user.user_name,
    a_reserve_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_reserve_time: "09:00:00",
    a_counsel_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_start_date: "",
    a_end_date: "",
    a_state: "ready",
    a_is_success: "",
    a_sort1: "학생상담",
    a_sort2: "예약",
    a_title: "상담",
    a_content: "",
    a_kind:"",
  };
  let stu_grade_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let kind_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'counsel_todo', 'key': 'a_kind' });

  const setInitData = (inData: any) => {
    let opt_obj = {
      stu_info_arr: stu_info_arr,
      start_date: write_data.start_date,
      end_date: write_data.end_date,
      ...inData
    };
    set_stu_info_arr(opt_obj.stu_info_arr);
    set_write_data({
      ...write_data,
      start_date: opt_obj.start_date,
      end_date: opt_obj.end_date,
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  useEffect(() => {
    
    get_teacher_arr_by_ajax();
  }, []);

  const get_teacher_arr_by_ajax = () => {
    if (user.user_grade != "master") {
      set_teacher_info_arr([{
        "a_seq": user.user_seq,
        "a_user_name": user.user_name,
      }]);
    } else {
      let formJsonData = {
        "s_grade": ["teacher", "master", "staff"],
        "s_is_login": "1",
        "is_need_count": "1",
        "is_need_info_arr": "1",
        "is_no_limit": "",
      };
      my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData,get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            set_teacher_info_arr(response.data["data"]["info_arr"]);
          }
        });
    }
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    if (name == "one_day_amount") {
      value = strFunc.comma(value);
    }

    let chage_data_json: any = {
      [name]: value
    };

    if (name == "a_counseler_seq") {
      for (let i = 0; i < teacher_info_arr.length; i++) {
        if (teacher_info_arr[i]["a_seq"] == value) {
          chage_data_json["a_counseler"] = teacher_info_arr[i]["a_user_name"];
        }
      }
    }

    set_write_data({
      ...write_data,
      ...chage_data_json
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_date_arr = {
      ...write_data,
      [key]: date_str
    };
    if (key == "start_date" || key == "end_date") {
      let start_date = change_date_arr["start_date"];
      let end_date = change_date_arr["end_date"];
      let start_date_obj = new Date(start_date);
      let end_date_obj = new Date(end_date);
      if (end_date_obj.getTime() < start_date_obj.getTime()) {
        if (key == "start_date") {
          change_date_arr["end_date"] = change_date_arr["start_date"];
        } else {
          change_date_arr["start_date"] = change_date_arr["end_date"];
        }
      }
      set_write_data(change_date_arr);
    } else {
      set_write_data(change_date_arr);
    }
  };

  const open_find_stu_popup = () => {
    let s_except_user_seq: any = [];
    for (let i = 0; i < stu_info_arr.length; i++) {
      let stu_info = stu_info_arr[i];
      s_except_user_seq.push(stu_info["a_seq"]);
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      list_opt: {
        s_except_user_seq: s_except_user_seq,
        s_addon_counsel_todo_tot: "1",
      },
    });
  };

  const callback_find_stu_popup = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData
    };
    set_stu_info_arr([
      ...stu_info_arr,
      ...optObj.info_arr
    ]);
  };

  const except_stu_by_stu_info = (stu_info: any) => {
    let new_stu_info_arr = [];
    for (let i = 0; i < stu_info_arr.length; i++) {
      if (stu_info_arr[i]["a_seq"] != stu_info["a_seq"]) {
        new_stu_info_arr.push(stu_info_arr[i]);
      }
    }
    set_stu_info_arr(new_stu_info_arr);
  };

  const make_counsel_todo_arr = () => {
    let ctodo_data_arr = MakeCounselTodoFunc.make_counsel_todo({
      stu_info_arr: stu_info_arr,
      start_date: write_data.start_date,
      end_date: write_data.end_date,
      apply_day: write_data.apply_day,
      one_day_amount: write_data.one_day_amount,
      default_ctodo: {
        ...default_ctodo,
        a_counseler_seq: write_data.a_counseler_seq,
        a_counseler: write_data.a_counseler,
        a_reserve_time:write_data.a_reserve_time,
        a_kind:write_data.a_kind,
      }
    });
    WriteListAreaRef.current.setInitData({
      "info_arr": ctodo_data_arr,
    });
    WriteListAreaAfterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const write_counsel_todo_by_ajax = () => {
    let write_counsel_todo_arr = WriteListAreaRef.current.get_info_arr();
    if (write_counsel_todo_arr.length == 0) {
      alert("등록 할 상담 일정이 없습니다.");
      return false;
    }
    if (!confirm("등록 하시겠습니까?")) {
      return false;
    }

    let formJsonData = {
      "data_arr": write_counsel_todo_arr,
      "is_update": "",
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("등록 되었습니다.");
          myProps.callback();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="write-table-div mt-2">
        <table>
          <colgroup>
            <col width={"100px"}></col>
            <col width={"*"}></col>
            <col width={"100px"}></col>
            <col width={"*"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                학생선택
                <p style={{ fontSize: "14px" }}>
                  ({stu_info_arr.length}명)
                </p>
              </th>
              <td>
                <button className="btn-m2 btn-sky" onClick={open_find_stu_popup} >찾기</button>
                <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", marginTop: 5 }}>
                  {stu_info_arr.map((item: any, idx: number) => {
                    let stu_span_style = {
                      display: "inline-block",
                      lineHeight: "20px",
                      padding: "0 5px",
                      background: "#543b2e",
                      borderRadius: "3px",
                      color: "#fff",
                    };
                    let stu_grade_title = item.a_stu_grade;
                    let select_grade_opt = strFunc.get_obj_by_key_val_at_obj_arr("value", item.a_stu_grade, stu_grade_select_option_arr);
                    if (select_grade_opt.length > 0) {
                      stu_grade_title = select_grade_opt[0]["text"];
                    }
                    return (
                      <div key={idx} style={stu_span_style}>
                        {item.a_user_name}({stu_grade_title})
                        <button className="btn-s btn-red ml-1" onClick={() => { except_stu_by_stu_info(item); }}>X</button>
                      </div>
                    );
                  })}
                </div>
              </td>
              <th>상담선생님</th>
              <td>
                <select className="row-input" name="a_counseler_seq" value={write_data.a_counseler_seq}
                  onChange={handleInputChange} style={{ width: 100 }}>
                  <option value="">선택없음</option>
                  {teacher_info_arr.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.a_seq}>{item.a_user_name}</option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>기간</th>
              <td colSpan={3}>
                <div style={{ display: "inline-block", width: 100 }}>
                  <DatePicker
                    selected={write_data.start_date != "" ? new Date(write_data.start_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("start_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
                ~
                <div style={{ display: "inline-block", width: 100 }}>
                  <DatePicker
                    selected={write_data.end_date != "" ? new Date(write_data.end_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("end_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td colSpan={3}>
                <DayOfWeekPick
                  value={write_data.apply_day}
                  name={"apply_day"}
                  handleInputChange={handleInputChange}
                ></DayOfWeekPick>
              </td>
            </tr>
            <tr>
              <th>
                <span style={{ fontSize: "12px" }}>하루당상담량</span>
              </th>
              <td>
                <input type="text" name="one_day_amount" value={write_data.one_day_amount}
                  onChange={handleInputChange} style={{ width: 100 }} />
              </td>
              <th>예약시간</th>
              <td>
                <TimeInputComponent
                  name={"a_reserve_time"}
                  value={write_data.a_reserve_time}
                  on_change={(inData:any)=>{
                    handleInputChange({
                      target:{
                        value:inData.value,
                        name:"a_reserve_time",
                      }
                    });
                  }}
                ></TimeInputComponent>
              </td>
            </tr>
            <tr>
              <th>구분</th>
              <td colSpan={3}>
                <SelectBoxBar
                  valueTextArr={kind_select_option_arr}
                  value={write_data.a_kind}
                  name={"a_kind"}
                  on_change={(inData:any)=>{
                    handleInputChange({
                      target:{
                        value:inData.value,
                        name:"a_kind",
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn-m btn-sky" onClick={make_counsel_todo_arr}>생성</button>
        <button className="btn-m btn-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <WriteListArea
        ref={WriteListAreaRef}
        write_counsel_todo_by_ajax={write_counsel_todo_by_ajax}
        closePopup={myProps.closePopup}
      ></WriteListArea>
      <div ref={WriteListAreaAfterRef}> </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }} >
          <FindStuListComponet
            listOpt={popupData.list_opt}
            callBackData={callback_find_stu_popup}
            closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
          ></FindStuListComponet>
        </LayerPopup>
      }
    </div>
  );
});
export default WriteCounselTodoPopup;