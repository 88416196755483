import getXcolumnJson from "./xcolumn/list";
import VideoArea from "./video";
import strFunc from "@/lib/lyg/string";

function SectionArea(props:any){
  const myProps={
    section_info:{},
    section_row_num:0,
    section_arr:[],
    video_arr:[],
    set_video_arr:(video_arr:any)=>{},

    onChangeRowData:(inData:any)=>{},
    moveOrderNum:(inData:any)=>{},
    openPreviewPopup:(video_info:any)=>{},
    ...props
  };

  const videoXColumnArr=getXcolumnJson();
  const default_row_data={
    "a_corse_seq":"",
    "a_seq":"1",
    "a_section_seq":"",
    "a_is_url":"1",
    "a_url":"",
    "a_url_sort":"youtube",//vimeo
    "a_is_essential":"1",
    "a_title":"",
    "a_memo":"",
    "a_total_sec":"",
    "a_complete_sec":"",
    "a_order_num":"",
  };
  let video_arr=myProps.video_arr;

  const addNewRow=()=>{
    if(myProps.section_info.a_seq=="empty"){
      return false;
    }
    let max_a_seq=0;
    let last_section_seq=myProps.section_info.a_seq;
    let last_video_info=null;
    if(video_arr.length>0){
      for(let i=0;i<video_arr.length;i++){
        let tmp_a_seq=parseInt(video_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }

        if(myProps.section_info.a_seq==video_arr[i].a_section_seq){
          last_video_info=video_arr[i];
        }
      }
    }
    let addRow:any={...default_row_data};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    addRow["a_section_seq"]=last_section_seq;
    if(last_video_info!=null){
      addRow["a_is_url"]=last_video_info["a_is_url"];
      addRow["a_url_sort"]=last_video_info["a_url_sort"];
      addRow["a_is_essential"]=last_video_info["a_is_essential"];
      addRow["a_title"]=last_video_info["a_title"];
      addRow["a_memo"]=last_video_info["a_memo"];
      addRow["a_total_sec"]=last_video_info["a_total_sec"];
      addRow["a_complete_sec"]=last_video_info["a_complete_sec"];
    }
    let tmp_video_arr:any=[...video_arr];
    tmp_video_arr.push(addRow);
    myProps.set_video_arr(tmp_video_arr);
  };

  const get_video_tags=()=>{
    let video_tags:any="";
    if(myProps.section_info.video_arr){
      video_tags=myProps.section_info.video_arr.map((item:any,idx:number)=>{
        return (
          <VideoArea
            key={idx}
            video_info={item}
            videoXColumnArr={videoXColumnArr}
            section_arr={myProps.section_arr}
            video_arr={myProps.video_arr}
            set_video_arr={myProps.set_video_arr}
            openPreviewPopup={myProps.openPreviewPopup}
          ></VideoArea>
        );
      });
    }

    if(strFunc.is_empty(video_tags)){
      video_tags=(
        <tr>
          <td colSpan={99}>
            <div className="text-center" style={{lineHeight:"40px"}}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }
    
    return video_tags;
  };

  return  (
    <div className="mt-5" >
      <div className="text-left">
        <span>
          단원 {myProps.section_row_num+1}.
        </span>
        <input type="text" 
          style={{width:200,marginLeft:10}}
          value={myProps.section_info.a_title} onChange={(e:any)=>{
            myProps.onChangeRowData({
            key:"a_title",
            value:e.target.value,
            row_num:myProps.section_row_num,
          });
        }} />
        <span className="ml-2">
          <button className="btn-s btn-gray" onClick={()=>{myProps.moveOrderNum(myProps.section_row_num,"up");}}>▲</button>
          <button className="btn-s btn-gray ml-2" onClick={()=>{myProps.moveOrderNum(myProps.section_row_num,"down");}}>▼</button>
          <button className="btn-s btn-red ml-2" onClick={()=>{myProps.removeRowData(myProps.section_row_num);}}>X</button>
          <button className="btn-s btn-gray ml-2" onClick={()=>{addNewRow();}}>동영상 추가</button>
        </span>
      </div>

      <div className="list-table-div mt-1" >
        <table>
          <thead>
            <tr>
              {
                videoXColumnArr.x_column_list_arr.map((column:any,idx:number)=>{
                  return (
                    <th key={idx}>
                      <div style={{width:parseInt(column["width"])}}>
                        {column["name"]}
                      </div>
                    </th>
                  );
                })
              }
            </tr>
          </thead>
          <tbody>
            {get_video_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default SectionArea;