import { lazy } from 'react';

import CompDefault from "@/pages/comp/index";
const LoginPage = lazy(() => import('@/pages/comp/login/index'));
const MyPage = lazy(() => import('@/pages/comp/basic/mypage'));
const LoginLogList = lazy(() => import('@/pages/comp/basic/log_login/list'));
import PushList from "@/pages/comp/basic/push/list";
import SMSList from "@/pages/comp/basic/sms/list";
import SMSSenderList from "@/pages/comp/basic/sms_sender/list";
const SMSSenderHelp = lazy(() => import('@/pages/comp/basic/sms_sender/help'));
import KatalkListPage from "@/pages/comp/basic/katalk/list";
import ParNoticeListPage from "@/pages/comp/board/par_notice/list";

import InitTestMain from "@/pages/comp/init_test/main";
import StudentList from "@/pages/comp/basic/student/list";
import StudentListMulty from "@/pages/comp/basic/student/list_multy";
import StudentUpGrade from "@/pages/comp/basic/student/up_stu_grade";
import StudentNewListPage from "@/pages/comp/basic/student/list_new";
const ViewStuPopupPage = lazy(() => import('@/pages/comp/basic/student/popup/view'));
import ParentList from "@/pages/comp/basic/parent/list";
import UserRemovedList from "@/pages/comp/basic/user_removed";
import SortCustomList from "@/pages/comp/basic/sort/list";

import AttendCard from "@/pages/comp/edu/attend/list_card";
import AttendMain from "@/pages/comp/edu/attend/main";
import AttendStatMonth from "@/pages/comp/edu/attend/stat_month";

import SupplyMain from "@/pages/comp/supply/main";
import SupplyMultyAdd from "@/pages/comp/supply/multy_add";
import PosCardList from "@/pages/comp/supply/pos/card/list";
import PosCashReceiptList from "@/pages/comp/supply/pos/cash_receipt/list";
import SimpleTradeList from "@/pages/comp/supply/simple_trade/list";

import DeskMain from "@/pages/comp/edu/desk/main";
const ViewRoomDeskPopup = lazy(() => import('@/pages/comp/edu/desk/main/popup/view_room_popup'));
import DeskUList from "@/pages/comp/edu/desk/use_list";
import CorpMain from "@/pages/comp/basic/corp/main";
const SiteMain = lazy(() => import('@/pages/comp/edu/site/main'));
import UserStaffList from "@/pages/comp/basic/user_staff/list";
import ClassList from "@/pages/comp/edu/class/list";
import ClassStuList from "@/pages/comp/edu/class_stu/list";
const ChatPopup = lazy(() => import('@/pages/comp/chat/chat/popup/chat_popup'));
import CounselList from "@/pages/comp/basic/counsel/list";
import CounselCalendar from "@/pages/comp/basic/counsel/calendar";
import CounselCareer from "@/pages/comp/edu/counsel_career/list";
import StudyDiaryList from "@/pages/comp/edu/study_diary/list";
import MonthPrescribeList from "@/pages/comp/edu/month_prescribe/list";
import MonthPrescribeCalendarPage from "@/pages/comp/edu/month_prescribe/calendar";
import NoticeBoardList from "@/pages/comp/board/notice/list";
import DataMainPage from "@/pages/comp/board/data_main";

import EduRoomList from "@/pages/comp/edu/room/list";
import LectureList from "@/pages/comp/edu/lecture/list";
import LectureUserTimeList from "@/pages/comp/edu/lecture/stu_lecture";
import LectureListOneDay2 from '@/pages/comp/edu/lecture/list_one_day';
import LectureListOneDay from '@/pages/comp/edu/lecture/view_time_table/list_one_day';
import ViewOneWeekOfTeacher from "@/pages/comp/edu/lecture/view_time_table/one_week_by_teacher";
import ViewOneWeekOfStu from "@/pages/comp/edu/lecture/view_time_table/one_week_of_stu";

import EduEvalCardList from "@/pages/comp/edu/eval/card_list";
import EvalFromList from "@/pages/comp/edu/eval_form/list";
import EvalHistoryList from "@/pages/comp/edu/eval/list_history";
import DailyReportCardListPage from "@/pages/comp/edu/daily_report/card_list";
const DailyReportHistoryPage = lazy(() => import('@/pages/comp/edu/daily_report/history_list'));
import StudyPrescribeCardListPage from "@/pages/comp/edu/study_prescribe/card_list";
const StudyPrescribeHistoryPage = lazy(() => import('@/pages/comp/edu/study_prescribe/history_list'));

import SiteMap from "@/pages/comp/basic/site_map";

import PlanRoutArr from "./plan/index";

const routerArr:object[] = [
  {
    path: "/comp",
    element: <CompDefault></CompDefault>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/comp/mypage",
    element: <MyPage></MyPage>,
  },
  {
    path: "/comp/basic/log_login/list",
    element: <LoginLogList></LoginLogList>,
  },
  {
    path: "/comp/basic/push/list",
    element: <PushList></PushList>,
  },
  {
    path: "/comp/basic/sms/list",
    element: <SMSList></SMSList>,
  },
  {
    path: "/comp/basic/sms_sender/list",
    element: <SMSSenderList></SMSSenderList>,
  },
  {
    path: "/comp/basic/sms_sender/help",
    element: <SMSSenderHelp></SMSSenderHelp>,
  },
  {
    path: "/comp/basic/katalk/list",
    element: <KatalkListPage></KatalkListPage>,
  },
  {
    path: "/comp/board/par_notice/list",
    element: <ParNoticeListPage></ParNoticeListPage>,
  },
  {
    path: "/comp/init_test/main",
    element: <InitTestMain></InitTestMain>,
  },
  {
    path: "/comp/basic/student/list",
    element: <StudentList></StudentList>,
  },
  {
    path: "/comp/basic/parent/list",
    element: <ParentList></ParentList>,
  },
  {
    path: "/comp/basic/user_removed/list",
    element: <UserRemovedList></UserRemovedList>,
  },
  {
    path: "/comp/basic/sort_custom/list",
    element: <SortCustomList></SortCustomList>,
  },
  {
    path: "/comp/basic/student/list_multy",
    element: <StudentListMulty></StudentListMulty>,
  },
  {
    path: "/comp/basic/student/stu_up_grade",
    element: <StudentUpGrade></StudentUpGrade>,
  },
  {
    path: "/comp/basic/student/list_new",
    element: <StudentNewListPage></StudentNewListPage>,
  },
  {
    path: "/comp/basic/student/view_popup",
    element: <ViewStuPopupPage></ViewStuPopupPage>,
  },
  {
    path: "/comp/basic/counsel/list",
    element: <CounselList></CounselList>,
  },
  {
    path: "/comp/basic/counsel/calendar",
    element: <CounselCalendar></CounselCalendar>,
  },
  {
    path: "/comp/basic/counsel/career",
    element: <CounselCareer></CounselCareer>,
  },
  {
    path: "/comp/basic/study_diary/list",
    element: <StudyDiaryList></StudyDiaryList>,
  },
  {
    path: "/comp/edu/month_prescribe/list",
    element: <MonthPrescribeList></MonthPrescribeList>,
  },
  {
    path: "/comp/edu/month_prescribe/calendar",
    element: <MonthPrescribeCalendarPage></MonthPrescribeCalendarPage>,
  },
  {
    path: "/comp/board/notice/list",
    element: <NoticeBoardList></NoticeBoardList>,
  },
  {
    path: "/comp/board/data_main",
    element: <DataMainPage></DataMainPage>,
  },
  {
    path: "/comp/edu/attend/list_card",
    element: <AttendCard></AttendCard>,
  },
  {
    path: "/comp/edu/attend/main",
    element: <AttendMain></AttendMain>,
  },
  {
    path: "/comp/edu/attend/stat_month",
    element: <AttendStatMonth></AttendStatMonth>,
  },
  {
    path: "/comp/supply/main",
    element: <SupplyMain></SupplyMain>,
  },
  {
    path: "/comp/supply/multy_add",
    element: <SupplyMultyAdd></SupplyMultyAdd>,
  },
  {
    path: "/comp/supply/pos/card/list",
    element: <PosCardList></PosCardList>,
  },
  {
    path: "/comp/supply/pos/cash_receipt/list",
    element: <PosCashReceiptList></PosCashReceiptList>,
  },
  {
    path: "/comp/supply/simple_trade/list",
    element: <SimpleTradeList></SimpleTradeList>,
  },

  {
    path: "/comp/edu/desk/main",
    element: <DeskMain></DeskMain>,
  },
  {
    path: "/comp/edu/desk/popup/view_room_popup",
    element: <ViewRoomDeskPopup></ViewRoomDeskPopup>,
  },
  {
    path: "/comp/edu/desk/use_list",
    element: <DeskUList></DeskUList>,
  },
  {
    path: "/comp/edu/room/list",
    element: <EduRoomList></EduRoomList>,
  },
  {
    path: "/comp/edu/lecture/list",
    element: <LectureList></LectureList>,
  },
  {
    path: "/comp/edu/lecture/lecture_user_time",
    element: <LectureUserTimeList></LectureUserTimeList>,
  },
  {
    path: "/comp/edu/lecture/list_one_day2",
    element: <LectureListOneDay2></LectureListOneDay2>,
  },
  {
    path: "/comp/edu/lecture/list_one_day",
    element: <LectureListOneDay></LectureListOneDay>,
  },
  {
    path: "/comp/edu/lecture/view_time_table/one_week_by_teacher",
    element: <ViewOneWeekOfTeacher></ViewOneWeekOfTeacher>,
  },
  {
    path: "/comp/edu/lecture/view_time_table/one_week_by_stu",
    element: <ViewOneWeekOfStu></ViewOneWeekOfStu>,
  },
  {
    path: "/comp/edu/eval/card_list",
    element: <EduEvalCardList></EduEvalCardList>,
  },
  {
    path: "/comp/edu/eval_from/list",
    element: <EvalFromList></EvalFromList>,
  },
  {
    path: "/comp/edu/eval_history/list",
    element: <EvalHistoryList></EvalHistoryList>,
  },
  {
    path: "/comp/edu/daily_report/card_list",
    element: <DailyReportCardListPage></DailyReportCardListPage>,
  },
  {
    path: "/comp/edu/daily_report/history_list",
    element: <DailyReportHistoryPage></DailyReportHistoryPage>,
  },
  {
    path: "/comp/edu/study_prescribe/card_list",
    element: <StudyPrescribeCardListPage></StudyPrescribeCardListPage>,
  },
  {
    path: "/comp/edu/study_prescribe/history_list",
    element: <StudyPrescribeHistoryPage></StudyPrescribeHistoryPage>,
  },
  {
    path: "/comp/site_map",
    element: <SiteMap></SiteMap>,
  },
  {
    path: "/comp/basic/corp/main",
    element: <CorpMain></CorpMain>,
  },
  {
    path: "/comp/edu/site/main",
    element: <SiteMain></SiteMain>,
  },
  {
    path: "/comp/basic/user_staff/list",
    element: <UserStaffList></UserStaffList>,
  },
  {
    path: "/comp/edu/class/list",
    element: <ClassList></ClassList>,
  },
  {
    path: "/comp/edu/class_stu/list",
    element: <ClassStuList></ClassStuList>,
  },
  {
    path: "/comp/chat/chat_popup",
    element: <ChatPopup></ChatPopup>,
  },
  /* 맞춤플랜 */
  ...PlanRoutArr
];

export default routerArr;