//import ContentTitle from "@/pcomponents/comp/content/con_title";
import {smenu} from "@/pcomponents/comp/layout/menu/data/menu_arr";
import head_msg_ico from "@/img/ico/head/head_msg_ico.png";

const ContentTitle=(props:any)=>{
  let con_title=props.title;

  if(con_title==""){
    for(let i=0;i<smenu.length;i++){
      let menu=smenu[i];
      if(window.location.pathname==menu.url){
        con_title=menu.name;
      }
    }
  }

  return (
    <div className="con_wrap" style={{borderBottomWidth:1}}>
      <h5>
        <p>
          <img src={head_msg_ico} style={{marginRight:7}} />
          {con_title}
        </p>
      </h5>
    </div>
  );
};

ContentTitle.defaultProps = {
  title:"",
}

export default ContentTitle;