import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function ContentArea1(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };

  return (
    <div className={Style.content_wrap}>
      <div className={Style.title_wrap}>
        <span className={Style.title_num}>1</span>
        <span className={Style.title_text}>진로목표 설정</span>
      </div>
      <div>
        <table className={Style.goal_table} style={{ marginTop: 5 }}>
          <colgroup>
            <col width="20%"></col>
            <col width="40%"></col>
            <col width="20%"></col>
            <col width="15%"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_major1}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_major1}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_major1", value: e.target.value }); }}
                      placeholder="학과1"
                    ></textarea>
                  }
                </div>
              </td>
              <td rowSpan={3}>
                <div style={{ padding: "0px 5px" }}>
                  <div className={Style.goal_box_sky} style={{ height: 185 }} >
                    {is_view_mode ?
                      <MultyLineTextView text={info.a_goal_course}></MultyLineTextView>
                      :
                      <textarea
                        value={info.a_goal_course}
                        onChange={(e: any) => { handleInputChange({ name: "a_goal_course", value: e.target.value }); }}
                        placeholder="도달경로"
                      ></textarea>
                    }
                  </div>
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_job1}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_job1}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_job1", value: e.target.value }); }}
                      placeholder="추천직업1"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    적성을 종합하여
                  </div>
                  <div className={Style.goal_box_explan_row} >가장 적합한 직업</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_major2}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_major2}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_major2", value: e.target.value }); }}
                      placeholder="학과2"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_job2}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_job2}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_job2", value: e.target.value }); }}
                      placeholder="추천직업2"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    상위 직업달성을
                  </div>
                  <div className={Style.goal_box_explan_row}>전제로 2순위로 고</div>
                  <div className={Style.goal_box_explan_row}>려할 수 있는 직업</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_major3}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_major3}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_major3", value: e.target.value }); }}
                      placeholder="학과3"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_sky} >
                  {is_view_mode ?
                    <MultyLineTextView text={info.a_recommend_job3}></MultyLineTextView>
                    :
                    <textarea
                      value={info.a_recommend_job3}
                      onChange={(e: any) => { handleInputChange({ name: "a_recommend_job3", value: e.target.value }); }}
                      placeholder="추천직업3"
                    ></textarea>
                  }
                </div>
              </td>
              <td>
                <div className={Style.goal_box_explan}>
                  <div className={Style.goal_box_explan_row}>
                    <div className={Style.goal_box_explan_dot}></div>
                    현재 잘 모르고 있
                  </div>
                  <div className={Style.goal_box_explan_row}>으나, 향후 고려해</div>
                  <div className={Style.goal_box_explan_row}>볼 수 있는 직업</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className={Style.goal_bottom_explan_text}>
                추천학과
              </td>
              <td className={Style.goal_bottom_explan_text}>
                도달경로
              </td>
              <td className={Style.goal_bottom_explan_text}>
                추천직업
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ContentArea1;