import strFunc from '@/lib/lyg/string';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import HomeWorkFileArea from "../../home_work_file";

function TodoArrTable(props:any){
  let myProps={
    stu_info:{},
    select_date:"",
    callback:(inData:any)=>{},
    style:{},
    sum_info:{},
    todo_arr:[],
    on_change_todo:(inData:any)=>{},
    on_delete_todo:(inData:any)=>{},
    openTodoWriteOnePopup:(inData:any)=>{},
    /*
    "value":e.target.value,
    "name":amount_key,
    "todo_pri_val":todo_info["a_ymd"]+","+todo_info["a_seq"],
    */
    ...props
  };
  let Style=myProps.style;

  const get_color_style_of_sum_tag=(percent:number)=>{
    let con_wrap_style:any={};
    let con_bar_style:any={};
    let per_span_style:any={};
    if(percent==0){
      con_wrap_style["background"]="#FAD5CC";
      con_bar_style["background"]="#F48282";
      per_span_style["color"]="#E18685";
    }else if(percent>0){
      con_wrap_style["background"]="#FAF1E0";
      con_bar_style["background"]="#EBBD70";
      per_span_style["color"]="#E0BA7B";
    }
    if(percent>=100){
      con_wrap_style["background"]="#EFF3D0";
      con_bar_style["background"]="#B7C528";
      per_span_style["color"]="#B0BA3F";
    }
    return {
      wrap:con_wrap_style,
      bar:con_bar_style,
      per:per_span_style
    };
  };
  let sum_color_style=get_color_style_of_sum_tag(myProps.sum_info.percent);

  const change_up_down_ing_value=(inData:any)=>{
    let opt_obj={
      up_down:"up",//up down
      name:"",
      value:"",
      todo_pri_val:"",
      ...inData
    };
    let value=parseInt(strFunc.uncomma(opt_obj.value));
    
    if(opt_obj.up_down=="up"){
      value++;
    }else{
      value--;
    }
    if(value<0){
      value=0;
    }

    myProps.on_change_todo({
      "value":value,
      "name":opt_obj.name,
      "todo_pri_val":opt_obj.todo_pri_val,
    });
  };

  const get_todo_tags=()=>{
    let  todo_tags=myProps.todo_arr.map((todo_info:any,idx:number)=>{

      let row_per:any=TodoStateFunc.get_percent_by_todo_info(todo_info);
      let max_amout=0;
      let ing_amout=0;
      let amount_sort="개";
      let amount_key="a_amount_num";
      let todo_time_his=strFunc.secondsToTimeJson(parseInt(strFunc.uncomma(todo_info.elapse_time_sec)));
      if(todo_info.a_success_sort=="time"){
        max_amout=parseInt(strFunc.uncomma(todo_info.a_success_time_sec));
        ing_amout=parseInt(strFunc.uncomma(todo_info.a_time_sec));
        amount_sort="분";
        amount_key="a_time_sec";
      }else if(todo_info.a_success_sort=="amount"){
        max_amout=parseInt(strFunc.uncomma(todo_info.a_success_amount));
        ing_amout=parseInt(strFunc.uncomma(todo_info.a_amount_num));
        amount_sort=todo_info.a_amount_sort;
      }
      if(max_amout!=0&&ing_amout!=0){
        row_per=(ing_amout/max_amout)*100;
        row_per=parseInt(row_per);
      }
      if(todo_info["a_is_success"]=="1"){
        row_per=100;
      }

      return (
        <tr key={idx}>
          <td>
            <div className={Style.todo_arr_th_first} title={todo_info.a_title} onClick={()=>{
                myProps.openTodoWriteOnePopup({
                  todo_info:todo_info
                });
              }}>
              {strFunc.cut_str(todo_info.a_title,10,"..")}
            </div>
          </td>
          <td>
            {parseInt(todo_time_his.h+"")!=0&&
              <span>{todo_time_his.h+":"}</span>
            }
            {todo_time_his.i+":"+todo_time_his.s}
          </td>
          <td>
            {max_amout}
            {" "+amount_sort}
          </td>
          <td>{row_per}%</td>
          <td>
            <div style={{width:160}}>
              <input type="text" className={Style.todo_arr_td_input} 
                value={ing_amout}
                name="ing_amout"
                onChange={(e:any)=>{
                  myProps.on_change_todo({
                    "value":e.target.value,
                    "name":amount_key,
                    "todo_pri_val":todo_info["a_ymd"]+","+todo_info["a_seq"],
                  });
                }}
                onClick={(e:any)=>{
                  e.target.select();
                }}
                style={{width:40}} />
              {todo_info.a_amount_sort}
              <button className="btn-s2 btn-gray ml-1"
                onClick={()=>{
                  change_up_down_ing_value({
                    value:ing_amout,
                    name:amount_key,
                    "todo_pri_val":todo_info["a_ymd"]+","+todo_info["a_seq"],
                    up_down:"up"
                  });
                }}>▲</button>
              <button className="btn-s2 btn-gray ml-1"
                onClick={()=>{
                  change_up_down_ing_value({
                    value:ing_amout,
                    name:amount_key,
                    "todo_pri_val":todo_info["a_ymd"]+","+todo_info["a_seq"],
                    up_down:"down"
                  });
                }}>▼</button>
                <button className="btn-s2 btn-dark ml-1"
                  onClick={()=>{
                    myProps.on_delete_todo({
                      todo_info:todo_info
                    });
                  }}
                >X</button>
            </div>
          </td>
        </tr>
      );
    });

    return todo_tags;
  };

  return (
    <div className={Style.todo_arr_table}>
      <table>
        <colgroup>
          <col width={"32%"}></col>
          <col width={"10%"}></col>
          <col width={"15%"}></col>
          <col width={"8%"}></col>
          <col width={"35%"}></col>
        </colgroup>
        <thead>
          <tr style={sum_color_style.wrap}>
            <th>
              <div className={Style.todo_arr_th_first}>
                <div className={Style.todo_arr_th_first_bar} style={sum_color_style.bar} ></div>
                {myProps.sum_info.title}
                <button className="btn-s btn-sky ml-1" onClick={()=>{
                  myProps.openTodoWriteOnePopup({
                    isUpdate:false,
                    todo_sort:myProps.sum_info.sort,
                  });
                }}>+</button>
              </div>
            </th>
            <th>분:초</th>
            <th>목표량</th>
            <th style={sum_color_style.per} >{myProps.sum_info.percent}%</th>
            <th>달성량</th>
          </tr>
        </thead>
        <tbody>
          {get_todo_tags()}
        </tbody>
      </table>

      {myProps.sum_info.sort=="homework"&&
        <HomeWorkFileArea
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          s_par_id={"home_work_file"}
          file_arr={myProps.stu_info.home_work_file_arr?myProps.stu_info.home_work_file_arr:[]}
          callback={myProps.callback}
          is_show_audio={false}
          is_show_video={false}
        ></HomeWorkFileArea>
      }
      {myProps.sum_info.sort=="study"&&
        <HomeWorkFileArea
          stu_info={myProps.stu_info}
          select_date={myProps.select_date}
          s_par_id={"plan_study_file"}
          file_arr={myProps.stu_info.plan_study_file_arr?myProps.stu_info.plan_study_file_arr:[]}
          callback={myProps.callback}
          is_show_audio={false}
          is_show_video={false}
        ></HomeWorkFileArea>
      }
    </div>
  );
};
export default TodoArrTable;