import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"video_use_det",
    list_id:"video_use_det_history",
    x_column_list_arr:[
      {"key":"row_view_video_core_name","name":"강의","width":"120","is_show":"1"},
      {"key":"row_view_video_name","name":"제목","width":"120","is_show":"1"},
      {"key":"row_view_stu_name","name":"학생","width":"90","is_show":"1"},
      {"key":"row_view_complete","name":"완료","width":"150","is_show":"1"},
      {"key":"row_view_per","name":"퍼센트","width":"90","is_show":"1"},
      {"key":"a_start_date","name":"시작","width":"150","is_show":"1"},
      {"key":"a_end_date","name":"종료","width":"150","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_start_date DESC',
      'order_type':'',
      's_date_type':'a_start_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_corse_seq","a_stu_seq","a_video_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;