import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"mkatalk",
    list_id:"mkatalk_list",
    x_column_list_arr:[
      {"key":"a_templete_sort","name":"구분","width":"110","is_show":"1"},
      {"key":"a_title","name":"제목","width":"110","is_show":"1"},
      {"key":"a_content","name":"내용","width":"400","is_show":"1"},
      {"key":"a_sender","name":"발신자","width":"100","is_show":"1"},
      {"key":"a_receiver","name":"수신자","width":"100","is_show":"1"},
      {"key":"a_receiver_phone_num","name":"수신번호","width":"120","is_show":"1"},
      {"key":"a_sender_phone_num","name":"발신번호","width":"120","is_show":"1"},
      {"key":"a_create_date","name":"등록일","width":"180","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':20,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'a_send_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.select_arr["a_templete_sort"]=[
    {"value":"attend_in","text":"등원"},
    {"value":"attend_out","text":"하원"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;