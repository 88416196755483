import strFunc from "@/lib/lyg/string";
import DetailRow from "./row";
import {default_detail_info} from "../../../data/default_data";

function DetailListArea(props:any){
   let myProps={
    sdiary_info:{},
    set_sdiary_info:(inData:any)=>{},
    list:(inData:any)=>{},
    sort:"progress",//homework
    set_refresh:(inData:any)=>{},
    is_view_mode:false,
    ...props
  };
  let title_str="진도";
  if(myProps.sort=="homework"){
    title_str="숙제";
  }
  let detail_arr:any=[];
  if(myProps.sdiary_info["detail_arr"]&&myProps.sdiary_info["detail_arr"].length>0){
    for(let i=0;i<myProps.sdiary_info["detail_arr"].length;i++){
      let tmp_row_data=myProps.sdiary_info["detail_arr"][i];
      if(tmp_row_data["a_sort"]==myProps.sort){
        detail_arr.push(tmp_row_data);
      }
    }
  }

  const add_row=()=>{
    let new_row_data={
      ...default_detail_info,
      "a_sort":myProps.sort,
    };
    myProps.sdiary_info["detail_arr"].push(new_row_data);
    myProps.sdiary_info["detail_arr"]=get_ordered_detail_arr();
    myProps.set_sdiary_info(myProps.sdiary_info);
    myProps.set_refresh();
  };

  const set_detail_info=(inData:any)=>{
    for(let i=0;i<myProps.sdiary_info["detail_arr"].length;i++){
      let detail_info=myProps.sdiary_info["detail_arr"][i];
      if(detail_info["a_seq"]==inData["a_seq"]){
        myProps.sdiary_info["detail_arr"][i]=inData;
      }
    }
    myProps.sdiary_info["detail_arr"]=get_ordered_detail_arr();
    myProps.set_sdiary_info(myProps.sdiary_info);
    myProps.set_refresh();
  };

  const remove_row=(row_seq:string)=>{
    let new_detail_arr=[];
    for(let i=0;i<myProps.sdiary_info["detail_arr"].length;i++){
      let detail_info=myProps.sdiary_info["detail_arr"][i];
      if(detail_info["a_seq"]!=row_seq){
        new_detail_arr.push(detail_info);
      }
    }
    myProps.sdiary_info["detail_arr"]=new_detail_arr;
    myProps.sdiary_info["detail_arr"]=get_ordered_detail_arr();
    myProps.set_sdiary_info(myProps.sdiary_info);
    myProps.set_refresh();
  };

  const get_ordered_detail_arr=()=>{
    for(let i=0;i<myProps.sdiary_info["detail_arr"].length;i++){
      myProps.sdiary_info["detail_arr"][i]["a_seq"]=i+1;
      myProps.sdiary_info["detail_arr"][i]["a_order_num"]=i+1;
    }
    return myProps.sdiary_info["detail_arr"];
  };
  
  const get_detail_row_tags=()=>{
    let detail_row_tags=detail_arr.map((item:any,idx:number)=>{
      return (
        <DetailRow
          key={idx}
          row_num={idx}
          detail_info={item}
          set_detail_info={set_detail_info}
          remove_row={()=>{
            remove_row(item["a_seq"]);
          }}
          is_view_mode={myProps.is_view_mode}
        ></DetailRow>
      );
    });

    if(strFunc.is_empty(detail_row_tags)){
      detail_row_tags=(
        <tr>
          <td colSpan={8}>
            <div className="text-center">
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return detail_row_tags;
  };

  return (
  <tr>
    <th style={{verticalAlign:"top"}} >
      {title_str}
      <p>
        <button className="btn-s btn-gray" onClick={add_row}>+</button>
      </p>
    </th>
    <td colSpan={3}>
      <table>
        <colgroup>
          <col width={"3%"}></col>
          <col width={"12%"}></col>
          <col width={"18%"}></col>
          <col width={"12%"}></col>
          <col width={"17%"}></col>
          <col width={"22%"}></col>
          <col width={"13%"}></col>
          <col width={"3%"}></col>
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>교재</th>
            <th>현재</th>
            <th>학습량</th>
            <th>시:분:초</th>
            <th>평가</th>
            <th>점수</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {get_detail_row_tags()}
        </tbody>
      </table>
    </td>
  </tr>
  );
};
export default DetailListArea;