import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

class ChangeDateFunc
{
  static change_date_action(inData:any){
    let opt_obj={
      info_arr:[],
      make_option:{},
      ...inData
    };

    let default_make_option={
      "change_day":"1",
      "apply_day":"1,2,3,4,5",
    };
    let info_arr=opt_obj.info_arr;
    let make_option={
      ...default_make_option,
      ...opt_obj.make_option
    };
    let change_day=parseInt(strFunc.uncomma(make_option.change_day));
    let apply_day_arr=make_option.apply_day.split(",");
    if(apply_day_arr.length==0||change_day==0){
      return info_arr;
    }

    //날짜얻기
    let change_date_json_arr=this.get_change_date_json_arr({
      info_arr:info_arr,
      change_day:change_day,
      apply_day_arr:apply_day_arr,
    });

    //적용하기
    let change_info_arr:any=[];
    for(let i=0;i<info_arr.length;i++){
      let info=info_arr[i];
      let is_changed=false;
      for(let key in change_date_json_arr){
        let change_date=change_date_json_arr[key];
        let origin_date=info["a_reserve_start_date"].substring(0,10);
        if(key==origin_date){
          if(is_changed==false){
            info["a_reserve_start_date"]=info["a_reserve_start_date"].replace(origin_date,change_date);
            info["a_reserve_end_date"]=info["a_reserve_start_date"];
            is_changed=true;
          }
        }
      }

      change_info_arr.push(info);
    }

    return change_info_arr;
  }
  static get_change_date_json_arr(inData:any){
    let opt_obj={
      info_arr:[],
      change_day:1,
      apply_day_arr:[],
      ...inData
    };
    let change_date_json_arr:any={};
    let apply_day_arr=opt_obj.apply_day_arr;
    let info_arr=opt_obj.info_arr;
    let info_arr_len=info_arr.length;
    //날짜모으기
    for(let i=0;i<info_arr_len;i++){
      let info=info_arr[i];
      let reserve_date=info["a_reserve_start_date"].substring(0,10);
      if(change_date_json_arr[reserve_date]==undefined){
        change_date_json_arr[reserve_date]=reserve_date;
      }
    }

    //날짜이동
    let last_date_str="";
    for(let key in change_date_json_arr){
      let origin_date=key;
      if(last_date_str==""){
        last_date_str=origin_date;
        //처음날짜 이동
        last_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(last_date_str),'day',opt_obj.change_day),"Y-m-d");
      }else{
        last_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(last_date_str),'day',+1),"Y-m-d");
      }

      //요일에 맞지 않으면 더 이동.
      let is_continue_check_day=true;
      let max_loop_week_check=8;
      let loop_week_i=0;
      while(is_continue_check_day){
        //무한루프 방지
        loop_week_i++;
        if(loop_week_i>max_loop_week_check){
          is_continue_check_day=false;
        }
        //요일체크
        let last_date_json=DateFunc.get_date_json(new Date(last_date_str));
        if(strFunc.str_in_array(last_date_json.day,apply_day_arr)!=-1){
          //가능
          is_continue_check_day=false;
        }else{
          //불가 +1 day
          last_date_str=DateFunc.get_date_format(DateFunc.get_change_date(new Date(last_date_str),'day',+1),"Y-m-d");
        }
      }

      //적용
      let apply_date_str=last_date_str+"";
      change_date_json_arr[key]=apply_date_str;
    }
    return change_date_json_arr;
  };
}
export default ChangeDateFunc;