import strFunc from "@/lib/lyg/string";

class TodoArrFunc{
  static get_todo_arr_by_data(inData:any){
    let opt_obj={
      stu_info_arr:[],
      select_date_arr:[],
      todo_data:{
        a_sort:"homework",//study
        a_success_sort:"amount",
        a_amount_sort:"개",
        a_success_amount:10,
        a_subject:"",
        a_title:"할일",
        a_content:"",
      },
      user:{},
      ...inData
    };

    let todo_arr=[];
    for(let i=0;i<opt_obj.stu_info_arr.length;i++){
      let stu_info=opt_obj.stu_info_arr[i];
      for(let j=0;j<opt_obj.select_date_arr.length;j++){
        let select_date_str=opt_obj.select_date_arr[j];
        let todo_ymd=strFunc.str_replace("-","",select_date_str);
        let todo_row={
          "a_ymd":todo_ymd,
          "a_seq":"",
          "a_is_mcomp_public":"1",
          "a_par_mcomp_seq":opt_obj.user.comp_seq,
          "a_sort":opt_obj.todo_data.a_sort,
          "a_par_id":"",
          "a_par_seq":"",
          "a_writer_mcomp_seq":opt_obj.user.comp_seq,
          "a_writer_mcomp_user_seq":opt_obj.user.user_seq,
          "a_writer_seq":opt_obj.user.main_user_seq,
          "a_writer_name":opt_obj.user.user_name,
          "a_stu_mcomp_seq":opt_obj.user.comp_seq,
          "a_stu_mcomp_user_seq":stu_info["a_seq"],
          "a_stu_seq":"",
          "a_stu_name":stu_info["a_user_name"],
          "a_start_date":select_date_str,
          "a_is_reserve":"1",
          "a_reserve_start_date":select_date_str,
          "a_reserve_end_date":select_date_str,
          "a_success_sort":opt_obj.todo_data.a_success_sort,
          "a_amount_sort":opt_obj.todo_data.a_amount_sort,
          "a_success_amount":opt_obj.todo_data.a_success_amount,
          "a_is_success":"",
          "a_state":"ready",
          "a_subject":opt_obj.todo_data.a_subject,
          "a_title":opt_obj.todo_data.a_title,
          "a_content":opt_obj.todo_data.a_content,
        };
        todo_arr.push(todo_row);
      }
    }

    return todo_arr;
  }
}
export default TodoArrFunc;