import Style from "./css/style.module.css";
import StuChartArea from "./stu_chart";
import TodoChartArea from "./todo_chart";
import { Link,useNavigate } from 'react-router-dom';
import TodoListArea from "./todo_list";
import NoticeListArea from "./notice_list";

const MiddleArea=()=>{
  const navigate = useNavigate();

  return (
    <div>
      <div className={Style.box_wrap}>
        <div className={Style.box}>
          <StuChartArea></StuChartArea>
        </div>
        <div className={Style.box+" "+Style.box_todo_chart}>
          <TodoChartArea></TodoChartArea>
        </div>
      </div>
      <div className={Style.box_wrap}>
        <div className={Style.box+" "+Style.box_todo}>
          <TodoListArea></TodoListArea>
        </div>
        <div className={Style.box+" "+Style.box_notice}>
          <NoticeListArea></NoticeListArea>
        </div>
      </div>
    </div>
  );
};
export default MiddleArea;