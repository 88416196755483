import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import DayOfWeekPick from "./area/day_of_week_pick";
import MakeTodoWriteFunc from "@/pages/comp/plan/todo/make_todo/func/make_todo_write";

const MakeOptionArea = forwardRef((props:any, ref) => {
  const myProps={
    set_make_option:(inOptObj:any)=>{},
    get_todo_content_arr:(inData:any)=>{},
    make_todo_write_plan:()=>{},
    ...props
  };

  let default_make_option:any={
    "is_mcomp_publiic":"1",
    "is_reserve":"1",//예정여부
    "reserve_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "reserve_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    "one_day_amount":10,
    "one_day_amount_time":60,
    "one_day_amount_over_per":100,
    "apply_day":"1,2,3,4,5",

    "start_h":"09",
    "start_m":"00",
    "end_h":"10",
    "end_m":"00",

    "distribute_standard":"amount_period",
    "is_need_photo":"",
  };
  if(localStorage.make_option_json){
    let local_storage_make_option=JSON.parse(localStorage.make_option_json);
    let rewrite_local_storage_key_arr=[
      "one_day_amount",
      "one_day_amount_time",
      "one_day_amount_over_per",
      "apply_day",
      "start_h",
      "start_m",
      "end_h",
      "end_m",
      "distribute_standard",
      "is_need_photo",
    ];
    for(let i=0;i<rewrite_local_storage_key_arr.length;i++){
      let key=rewrite_local_storage_key_arr[i];
      if(local_storage_make_option[key]){
        default_make_option[key]=local_storage_make_option[key];
      }
    }
  }
  const [make_option,set_make_option]=useState<any>(default_make_option);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "make_option":[],
      ...inOptObj
    };

    if(optObj.make_option["reserve_start_date"]){
      let time_data=strFunc.timeToHourMinute(optObj.make_option["reserve_start_date"]);
      optObj.make_option["start_h"]=time_data["h"];
      optObj.make_option["start_m"]=time_data["m"];
    }
    if(optObj.make_option["reserve_end_date"]){
      let time_data=strFunc.timeToHourMinute(optObj.make_option["reserve_end_date"]);
      optObj.make_option["end_h"]=time_data["h"];
      optObj.make_option["end_m"]=time_data["m"];
    }

    set_make_option({
      ...default_make_option,
      ...optObj.make_option
    });
  };

  const get_make_option=()=>{
    return make_option;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_make_option
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }

    if(name=="one_day_amount"){
      value=strFunc.comma(value);
    }

    let chage_data_json:any={
      [name]: value
    };

    if(name=="start_h"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(value,make_option.start_m);
    }else if(name=="start_m"){
      chage_data_json.a_reserve_start_time=strFunc.HourMinuteToTime(make_option.start_h,value);
    }else if(name=="end_h"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(value,make_option.end_m);
    }else if(name=="end_m"){
      chage_data_json.a_reserve_end_time=strFunc.HourMinuteToTime(make_option.end_h,value);
    }

    //유효성체크
    if(strFunc.str_in_array(name,["start_h","start_m","end_h","end_m"])!=-1){
      chage_data_json={
        ...make_option,
        ...chage_data_json
      };
      let start_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_start_time));
      let end_time_num=parseInt(strFunc.uncomma(chage_data_json.a_reserve_end_time));
      if(start_time_num>end_time_num){
        chage_data_json.a_reserve_end_time=chage_data_json.a_reserve_start_time;
        chage_data_json.end_h=chage_data_json.start_h;
        chage_data_json.end_m=chage_data_json.start_m;
      }
    }
    
    if(name=="one_day_amount"){
      update_period_by_day_of_amount(value);
    }else{
      set_make_option({
        ...make_option,
        ...chage_data_json
      });
    }
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    let change_date_arr={
      ...make_option,
      [key]: date_str
    };
    if(key=="reserve_start_date"||key=="reserve_end_date"){
      let start_date=change_date_arr["reserve_start_date"];
      let end_date=change_date_arr["reserve_end_date"];
      let start_date_obj=new Date(start_date);
      let end_date_obj=new Date(end_date);
      if(end_date_obj.getTime()<start_date_obj.getTime()){
        if(key=="reserve_start_date"){
          change_date_arr["reserve_end_date"]=change_date_arr["reserve_start_date"];
        }else{
          change_date_arr["reserve_start_date"]=change_date_arr["reserve_end_date"];
        }
      }
      updateAmountAutoByPeriod(change_date_arr["reserve_start_date"],change_date_arr["reserve_end_date"]);
    }else{
      set_make_option(change_date_arr);
    }
  };

  const updateAmountAutoByPeriod=(start_date:string,end_date:string)=>{
    let todo_content_arr=myProps.get_todo_content_arr();
    let one_day_amount=MakeTodoWriteFunc.get_one_day_amount_by_period({
      start_date:start_date,
      end_date:end_date,
      todo_content_arr:todo_content_arr,
      apply_day_arr:make_option.apply_day.split(","),
      one_day_amount:make_option.one_day_amount,
    });
    set_make_option({
      ...make_option,
      "reserve_start_date":start_date,
      "reserve_end_date":end_date,
      "one_day_amount":one_day_amount,
    });
  };

  const update_period_by_day_of_amount=(one_day_amount:any)=>{
    let todo_content_arr=myProps.get_todo_content_arr();
    let tmp_todo_write_arr=MakeTodoWriteFunc.get_make_todo_write({
      todo_content_arr:todo_content_arr,
      make_option:{
        ...make_option,
        one_day_amount:one_day_amount
      },
    });
    let reserve_end_date=make_option.reserve_end_date;
    if(tmp_todo_write_arr.length>0){
      let last_todo_w_info=tmp_todo_write_arr[tmp_todo_write_arr.length-1];
      reserve_end_date=last_todo_w_info["a_reserve_start_date"].substring(0,10)
    }
    set_make_option({
      ...make_option,
      "one_day_amount":one_day_amount,
      "reserve_end_date":reserve_end_date,
    });
  };

  let time_h_arr=[];
  let time_m_arr=[];
  for(let i=0;i<24;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_h_arr.push(i_str);
  }
  for(let i=0;i<60;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2})
    time_m_arr.push(i_str);
  }
  let time_h_options=time_h_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });
  let time_m_options=time_m_arr.map((item,idx)=>{
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  return (
    <div>
      <div className="write-table-div mt-5">
        <table>
          <colgroup>
            <col width="10%"></col>
            <col width="90%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>분배기준</th>
              <td>
                <select className="row-input" name="distribute_standard" value={make_option.distribute_standard} 
                onChange={handleInputChange} style={{width:70}}>
                  <option value="amount">분량</option>
                  <option value="period">기간</option>
                  <option value="amount_period">기본</option>
                </select>
                <span className="ml-2" style={{color:"#777"}}>※기간선택 시 분량자동산출.</span>
              </td>
            </tr>
            <tr>
              <th>학습기간</th>
              <td>
                {make_option.distribute_standard!="amount"?
                <div>
                  <div style={{display:"inline-block",width:100}}>
                    <DatePicker 
                      selected={make_option.reserve_start_date!=""?new Date(make_option.reserve_start_date):null} 
                      onChange={(date:Date) => {
                        onChangeDatePicker("reserve_start_date",date);
                      }}
                      locale={ko} 
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomInputWrite />}
                      renderCustomHeader={customHeaderFunc}
                    />
                  </div>
                  ~
                  <div style={{display:"inline-block",width:100}}>
                    <DatePicker 
                      selected={make_option.reserve_end_date!=""?new Date(make_option.reserve_end_date):null} 
                      onChange={(date:Date) => {
                        onChangeDatePicker("reserve_end_date",date);
                      }}
                      locale={ko} 
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomInputWrite />}
                      renderCustomHeader={customHeaderFunc}
                    />
                  </div>
                </div>
                :
                  make_option.reserve_start_date+" ~ "+make_option.reserve_end_date
                }
              </td>
            </tr>
            <tr>
              <th>하루분량</th>
              <td>
              {make_option.distribute_standard!="period"?
              <div>
                <input type="text" className="row-input" name="one_day_amount" value={make_option.one_day_amount}
                  onChange={handleInputChange} placeholder="분량" style={{width:45,textAlign:"center"}} />개
                <span style={{display:"none"}}>
                  <input type="text" className="row-input ml-1" name="one_day_amount_time" value={make_option.one_day_amount_time}
                    onChange={handleInputChange} placeholder="분" style={{width:45,textAlign:"center"}} />분
                </span>
                <span className="ml-1" style={{color:"#777"}}>※하루당 분배 수량.</span>
                <p className="mt-1">
                  <input type="range" min="0" max="100" name="one_day_amount_over_per" value={make_option.one_day_amount_over_per}
                  onChange={handleInputChange} />
                </p>
                <p>
                  하루분량 {make_option.one_day_amount_over_per}% 채우면 다음날로 넘긴다.
                </p>
              </div>
              :
              make_option.one_day_amount+" 개"
              }
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td>
                <DayOfWeekPick
                  value={make_option.apply_day}
                  name={"apply_day"}
                  handleInputChange={handleInputChange}
                ></DayOfWeekPick>
              </td>
            </tr>
            <tr>
              <th>학습시간</th>
              <td>
                <select className="row-input" name="start_h" value={make_option.start_h} onChange={handleInputChange} style={{width:60}} >
                  {time_h_options}
                </select>:
                <select className="row-input" name="start_m" value={make_option.start_m} onChange={handleInputChange} style={{width:60}} >
                  {time_m_options}
                </select>
                ~
                <select className="row-input" name="end_h" value={make_option.end_h} onChange={handleInputChange} style={{width:60}} >
                  {time_h_options}
                </select>:
                <select className="row-input" name="end_m" value={make_option.end_m} onChange={handleInputChange} style={{width:60}} >
                  {time_m_options}
                </select>
              </td>
            </tr>
            <tr>
              <th>사진필요</th>
              <td>
                <select className="row-input" name="is_need_photo" value={make_option.is_need_photo} onChange={handleInputChange} style={{width:100}} >
                  <option value="">아니오</option>
                  <option value="1">예</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="text-center mt-2">
        <button className="btn-l btn-yellowish3"
         onClick={()=>{
          localStorage.setItem('make_option_json',JSON.stringify(make_option));
          myProps.make_todo_write_plan();
        }} >플랜작성</button>
      </div>
    </div>
  );
});

export default MakeOptionArea;
