import { useState } from 'react';

function TabArea(props:any){
  let myProps={
    isUpdate:false,
    active_tab:"all",
    onChangeTab:(tab_id:string)=>{},
    ...props
  };

  const [active_tab,set_active_tab] =useState(myProps.active_tab);
  const tabDataArr=[
    {id:"all",name:"전체"},
    {id:"section_study",name:"단원"},
  ];
  if(myProps.isUpdate){
    tabDataArr.push({id:"student",name:"이용내역"});
  }

  const onChangeTab=(tab_id:string)=>{
    set_active_tab(tab_id);
    myProps.onChangeTab(tab_id);
  };

  let tabTags=tabDataArr.map((item:any,idx:number)=>{
    let active_class_name="";
    if(item.id==active_tab){
      active_class_name=" active";
    }
    return (
      <a className={"tab_list_item_a"+active_class_name} 
        key={idx} 
        onClick={()=>{onChangeTab(item.id);}} 
        >
        {item.name}
      </a>
    );
  });

  return (
    <div className="tab_list_wrap">
      {tabTags}
    </div>
  );
}

export default TabArea;