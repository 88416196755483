import strFunc from '@/lib/lyg/string';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import DateFunc from '@/lib/lyg/date_func';

function TopYearMonthArea(props:any){
  let myProps={
    Style:{},
    select_date:"",
    set_select_date:(inData:any)=>{},
    onSearchDateChange:(inData:any)=>{},
    handleDateChange:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;

  const goPrevAfterMonth=(sort:"prev"|"after")=>{
    let select_month_obj=new Date(myProps.select_date.substring(0,7)+"-01");
    let change_date_str="";
    if(sort=="prev"){
      change_date_str=DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj,'month',-1),"Y-m-d");
    }else{
      change_date_str=DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj,'month',+1),"Y-m-d");
    }
    myProps.set_select_date(change_date_str);
    myProps.onSearchDateChange(change_date_str);
  };

  let year_num_arr=[];
  let select_year_num=parseInt(myProps.select_date.substring(0,4));
  for(let i=select_year_num-50;i<=select_year_num+10;i++){
    year_num_arr.push(i);
  }
  const year_options_tag=year_num_arr.map((item,idx)=>{
    return (
      <option value={item} key={idx}>{item}</option>
    );
  });

  let month_num_arr=[];
  for(let i=1;i<=12;i++){
    month_num_arr.push(i);
  }
  const month_options_tag=month_num_arr.map((item:any,idx:number)=>{
    let month_val=strFunc.str_pad({"str":item,"pad_length":2});
    return (
      <option value={month_val} key={idx}>{item}</option>
    );
  });

  return (
    <div className={Style.top_year_month_wrap}>
      <div className={Style.year_span_wrap}>
        <span className={Style.year_arrow_btn} onClick={()=>{goPrevAfterMonth("prev");}}>
          <img  src={left_arrow_ico} />
        </span>
        <span className={Style.center_ym_span}>
          <select name="s_year" value={myProps.select_date.substring(0,4)} onChange={myProps.handleDateChange}>
            {year_options_tag}
          </select>년
          <select className="ml-1" name="s_month" value={myProps.select_date.substring(5,7)} onChange={myProps.handleDateChange}>
            {month_options_tag}
          </select>월
        </span>
        <span className={Style.year_arrow_btn} onClick={()=>{goPrevAfterMonth("after");}}>
          <img  src={right_arrow_ico} />
        </span>
      </div>
    </div>
  );
}
export default TopYearMonthArea;