import CompLayout from "@/pcomponents/comp_stu/layout/layout";

function MySchedule(){
  

  return (
    <CompLayout >
      
    </CompLayout>
  );
}

export default MySchedule;