import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"trade_simple",
    list_id:"trade_simple_write_multy",
    x_column_list_arr:[
      // {"key":"a_par_id","name":"부모구분","width":"140","is_show":"1"},
      // {"key":"row_view_cust_name","name":"거래처명","width":"100","is_show":"1"},
      {"key":"a_date","name":"거래일","width":"100","is_show":"1"},
      {"key":"a_memo","name":"적요","width":"100","is_show":"1"},
      {"key":"a_money_type","name":"금액구분","width":"100","is_show":"1"},
      {"key":"a_inout","name":"입금/출금","width":"100","is_show":"1"},
      {"key":"a_money","name":"금액","width":"100","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.number_col_arr=["a_money"];
  xColumnArr.date_col_arr=["a_date"];

  return xColumnArr;
};

export default getXcolumnJson;