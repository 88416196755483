import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"stu_multy_list",
    x_column_list_arr:[
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_user_name","name":"이름","width":"80","is_show":"1"},
      {"key":"a_user_id","name":"아이디","width":"120","is_show":"1"},
      {"key":"a_user_phone","name":"원생휴대폰","width":"130","is_show":"1"},
      {"key":"a_school_name","name":"학교","width":"110","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"80","is_show":"1"},
      {"key":"a_stu_state","name":"현황","width":"110","is_show":"1"},
      {"key":"a_stu_num","name":"출결번호","width":"80","is_show":"1"},
      {"key":"a_join_date","name":"입학일","width":"110","is_show":"1"},
      {"key":"a_stu_end_date","name":"휴/퇴원일","width":"110","is_show":"1"},
      {"key":"a_stu_end_memo","name":"휴/퇴원메모","width":"120","is_show":"1"},
      // {"key":"a_last_login_date","name":"마지막 로그인","width":"160","is_show":"1"},
      // {"key":"a_create_date","name":"작성일","width":"180","is_show":"1"},
      // {"key":"a_update_date","name":"수정일","width":"180","is_show":"1"},
      {"key":"a_gender","name":"성별","width":"80","is_show":"1"},
      {"key":"a_email","name":"이메일","width":"110","is_show":"1"},
      {"key":"a_group_name","name":"그룹명","width":"110","is_show":"1"},
      {"key":"a_stu_birth_date","name":"생년월일","width":"110","is_show":"1"},
      {"key":"a_stu_supply_day","name":"청구일","width":"110","is_show":"1"},
      {"key":"a_stu_supply_discount","name":"수납기본할인","width":"110","is_show":"1"},
      {"key":"a_memo","name":"메모","width":"210","is_show":"1"},
      {"key":"a_post_addr","name":"주소","width":"140","is_show":"1"},
      {"key":"a_post_addr_detail","name":"주소상세","width":"120","is_show":"1"},

      {"key":"par_relation","name":"부모관계","width":"120","is_show":"1"},
      {"key":"par_phone","name":"부모핸드폰","width":"120","is_show":"1"},
      {"key":"par_user_id","name":"부모아이디","width":"120","is_show":"1"},
      {"key":"par_user_pw","name":"부모암호","width":"120","is_show":"1"},
      {"key":"par_is_attend_sms","name":"부모출석sms","width":"120","is_show":"1"},
      {"key":"par_is_attend_push","name":"부모출석푸시","width":"120","is_show":"1"},
      {"key":"par_is_attend_katalk","name":"부모출석카톡","width":"120","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':10,
      'order_id':'a_create_date DESC',
      'order_type':'',
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
      "s_user_name_like":"",
      "s_phone_like":"",
      "s_stu_num_like":"",
      "s_school_name_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  initData.date_col_arr=["a_join_date","a_stu_end_date"];
  initData.password_col_arr=["a_user_pw"];
  initData.number_col_arr=["a_stu_supply_discount"];
  initData.tel_col_arr=["a_user_phone"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.select_arr["par_relation"]=[
    {"value":"모","text":"모"},
    {"value":"부","text":"부"},
    {"value":"etc","text":"기타"},
  ];
  xColumnArr.select_arr["par_is_attend_sms"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];
  xColumnArr.select_arr["par_is_attend_push"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];
  xColumnArr.select_arr["par_is_attend_katalk"]=[
    {"value":"","text":"아니오"},
    {"value":"1","text":"예"},
  ];

  return xColumnArr;
};

export default getXcolumnJson;