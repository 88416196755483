import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect} from 'react';

const DetailItem = (props:any) => {
  let myProps:any={
    detail_info:{},
    row_num:0,
    on_change_detail_data:(inData:any)=>{},
    remove_row_by_row_num:(row_num:any)=>{},
    moveOrderNum:(row_num:number,up_down:"up"|"down")=>{},
    ...props
  };

  let detail_info=myProps.detail_info;

  let a_name_split_str=detail_info.a_name_split_str;
  let a_name_split_arr=a_name_split_str.split("@,@");
  let name_split_arr:any=[];
  if(detail_info.a_name_sort!="number"){
    let split_name_amount=0;
    let a_max_score=parseInt(strFunc.uncomma(detail_info.a_max_score));
    let a_score_split=parseInt(strFunc.uncomma(detail_info.a_score_split));
    if(a_max_score!=0&&a_score_split!=0){
      split_name_amount=Math.floor(a_max_score/a_score_split);
    }
    if(split_name_amount>0){
      let tmp_abc_arr=strFunc.get_abc_arr(split_name_amount);
      for(let i=0;i<split_name_amount;i++){
        let name_str=tmp_abc_arr[i];
        if(a_name_split_arr[i]){
          name_str=a_name_split_arr[i];
        }
        name_split_arr.push(name_str);
      }
    }
  }

  const on_change_split_name_input=(split_num:number,value:string)=>{
    name_split_arr[split_num]=value;
    a_name_split_str=name_split_arr.join("@,@");
    myProps.on_change_detail_data({
      "row_num":myProps.row_num,
      "key":"a_name_split_str",
      "value":a_name_split_str,
    });
  };

  let name_str_tags=name_split_arr.map((item:string,idx:number)=>{
    let a_max_score=parseInt(strFunc.uncomma(detail_info.a_max_score));
    let a_score_split=parseInt(strFunc.uncomma(detail_info.a_score_split));
    let start_score=a_max_score-(a_score_split*idx);
    let end_score=a_max_score-(a_score_split*(idx+1));
    let title_str=start_score+"~"+end_score;
    return (
      <input type="text" className="ml-1" value={item} key={idx}
        onChange={(e:any)=>{
          on_change_split_name_input(idx,e.target.value);
        }}
        onClick={(e:any)=>{
          e.target.select();
        }}
        style={{width:50,textAlign:"center"}} 
        title={title_str} />
    );
  });

  return (
    <>
      <tr>
        <td>
          <input type="text" className="row-input" name="a_title" value={detail_info.a_title}
            onChange={(e:any)=>{myProps.on_change_detail_data({
              "row_num":myProps.row_num,
              "key":"a_title",
              "value":e.target.value,
            });}} />
        </td>
        <td>
          <select className="row-input" name="a_name_sort" value={detail_info.a_name_sort}
            onChange={(e:any)=>{myProps.on_change_detail_data({
              "row_num":myProps.row_num,
              "key":"a_name_sort",
              "value":e.target.value,
            });}}>
            <option value="abc" >ABC</option>
            <option value="number" >숫자</option>
          </select>
        </td>
        <td>
          <input type="text" className="row-input" name="a_max_score" value={detail_info.a_max_score}
            onChange={(e:any)=>{myProps.on_change_detail_data({
              "row_num":myProps.row_num,
              "key":"a_title",
              "value":e.target.value,
            });}} style={{textAlign:"right"}}/>
        </td>
        <td>
          <input type="text" className="row-input" name="a_score_split" value={detail_info.a_score_split} 
            onChange={(e:any)=>{myProps.on_change_detail_data({
              "row_num":myProps.row_num,
              "key":"a_title",
              "value":e.target.value,
            });}} style={{textAlign:"right"}}/>
        </td>
        <td className="text-center">
          <button className="btn-m2 btn-gray" onClick={()=>{myProps.moveOrderNum(myProps.row_num,"up");}}>▲</button>
          <button className="btn-m2 btn-gray ml-1" onClick={()=>{myProps.moveOrderNum(myProps.row_num,"down");}}>▼</button>
          <button className="btn-m2 btn-red ml-1" onClick={()=>{myProps.remove_row_by_row_num(myProps.row_num);}}>X</button>
        </td>
      </tr>
      {detail_info.a_name_sort!="number"&&
      <tr>
        <td colSpan={99}>
          표시: {name_str_tags}
        </td>
      </tr>
      }
    </>
  );
};

export default DetailItem;