import { lazy } from 'react';

const TestDefault = lazy(() => import('@/pages/test'));
const TestDesign = lazy(() => import('@/pages/test/design'));
const DesignList = lazy(() => import('@/pages/test/design/list'));
const DesignButton = lazy(() => import('@/pages/test/design/button'));
const DesignContent = lazy(() => import('@/pages/test/design/content'));
const DesignTable = lazy(() => import('@/pages/test/design/table'));
const DesignPopup = lazy(() => import('@/pages/test/design/popup'));
const DesignChat = lazy(() => import('@/pages/test/design/chat'));
const ChatPopup = lazy(() => import('@/pages/test/design/chat/popup/chat_popup'));
const YouTubeFrame = lazy(() => import('@/pages/test/design/video/youtube'));
const StudentLayout = lazy(() => import('@/pages/test/design/layout/student'));

const DBExcuteMain = lazy(() => import('@/pages/test/db/execute/main'));
const DBCounselTodo = lazy(() => import('@/pages/test/db/comp/counsel_todo'));

const URLStrPage = lazy(() => import('@/pages/test/util/url_str'));
const PushTestMain = lazy(() => import('@/pages/test/util/push'));
const ScheduleTestMain = lazy(() => import('@/pages/test/util/schedule'));
const XcelDownTest = lazy(() => import('@/pages/test/util/excel/xlsx/down'));
const XcelUploadTest = lazy(() => import('@/pages/test/util/excel/xlsx/upload'));
const KakaoNoticeSend = lazy(() => import('@/pages/test/util/kakao/send_notice'));
const KaKaoCallback = lazy(() => import('@/pages/test/util/kakao/callback'));
const CoolEnMain = lazy(() => import('@/pages/test/util/coolen'));
const UtilCompMain = lazy(() => import('@/pages/test/util/comp'));

// import TestDefault from "@/pages/test";
// import TestDesign from "@/pages/test/design";
// import DesignList from "@/pages/test/design/list";
// import DesignButton from "@/pages/test/design/button";
// import DesignContent from "@/pages/test/design/content";
// import DesignTable from "@/pages/test/design/table";
// import DesignPopup from "@/pages/test/design/popup";
// import DesignChat from "@/pages/test/design/chat";
// import ChatPopup from "@/pages/test/design/chat/popup/chat_popup";
// import YouTubeFrame from "@/pages/test/design/video/youtube";
// import StudentLayout from "@/pages/test/design/layout/student";

// import DBExcuteMain from "@/pages/test/db/execute/main";

// import URLStrPage from "@/pages/test/util/url_str";
// import PushTestMain from "@/pages/test/util/push";
// import ScheduleTestMain from "@/pages/test/util/schedule";
// import XcelDownTest from "@/pages/test/util/excel/xlsx/down";
// import XcelUploadTest from "@/pages/test/util/excel/xlsx/upload";
// import KakaoNoticeSend from "@/pages/test/util/kakao/send_notice";
// import KaKaoCallback from "@/pages/test/util/kakao/callback";

const routerArr:object[] = [
  {
    path: "/test",
    element: <TestDefault></TestDefault>,
  },
  {
    path: "/test/design",
    element: <TestDesign></TestDesign>,
  },
  {
    path: "/test/design/list",
    element: <DesignList></DesignList>,
  },
  {
    path: "/test/design/btn",
    element: <DesignButton></DesignButton>,
  },
  {
    path: "/test/design/content",
    element: <DesignContent></DesignContent>,
  },
  {
    path: "/test/design/table",
    element: <DesignTable></DesignTable>,
  },
  {
    path: "/test/design/popup",
    element: <DesignPopup></DesignPopup>,
  },
  {
    path: "/test/design/chat",
    element: <DesignChat></DesignChat>,
  },
  {
    path: "/test/design/chat/popup/chat_popup",
    element: <ChatPopup></ChatPopup>,
  },
  {
    path: "/test/design/video/youtube",
    element: <YouTubeFrame></YouTubeFrame>,
  },
  {
    path: "/test/design/layout/student",
    element: <StudentLayout></StudentLayout>,
  },

  {
    path: "/test/db/excute/main",
    element: <DBExcuteMain></DBExcuteMain>,
  },
  {
    path: "/test/db/counsel_todo",
    element: <DBCounselTodo></DBCounselTodo>,
  },

  {
    path: "/test/util/url_str",
    element: <URLStrPage></URLStrPage>,
  },
  {
    path: "/test/util/push",
    element: <PushTestMain></PushTestMain>,
  },
  {
    path: "/test/util/schedule",
    element: <ScheduleTestMain></ScheduleTestMain>,
  },
  {
    path: "/test/util/excel/down",
    element: <XcelDownTest></XcelDownTest>,
  },
  {
    path: "/test/util/excel/upload",
    element: <XcelUploadTest></XcelUploadTest>,
  },
  {
    path: "/test/util/kakao/send_notice",
    element: <KakaoNoticeSend></KakaoNoticeSend>,
  },
  {
    path: "/test/util/kakao/callback",
    element: <KaKaoCallback></KaKaoCallback>,
  },
  {
    path: "/test/util/coolen/main",
    element: <CoolEnMain></CoolEnMain>,
  },
  {
    path: "/test/util/comp/main",
    element: <UtilCompMain></UtilCompMain>,
  },
];

export default routerArr;