function TodoArrHoverArea(props:any){
  let myProps:any={
    td_sort_data_arr:[],
    ...props
  };

  return (
    <div>
      {props.children}
    </div>
  );
};
export default TodoArrHoverArea;