import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';

function EtcInfoArea(props: any) {
  let myProps = {
    Style: {},
    info: {},
    set_info: (inData: any) => { },
    is_view_mode: false,
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.set_info({
      ...info,
      ...{ [key]: date_str }
    });
  };

  return (
    <div style={{ width: "21cm", margin: "0 auto", padding: "5px 0px" }}>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
          <col width={"20%"}></col>
          <col width={"30%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>상담일</th>
            <td>
              {is_view_mode ?
                info.a_counsel_date
                :
                <DatePicker
                  selected={info.a_counsel_date != "" ? new Date(info.a_counsel_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_counsel_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              }
            </td>
            <th>상담자</th>
            <td>
              {is_view_mode ?
                info.a_writer
                :
                <input type="text" value={info.a_writer}
                  onChange={(e: any) => { handleInputChange({ name: "a_writer", value: e.target.value }); }}
                  placeholder="학교명" />
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default EtcInfoArea;