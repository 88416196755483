import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup_portal';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";

const StuListArea = forwardRef((props:any, ref) => {
  const myProps={
    set_stu_arr:(inOptObj:any)=>{},
    ...props
  };
  
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  let user=useSelector((state:any) => state.user);

  const [stu_arr,set_stu_arr]=useState<any>([]);

  const [popupData,setPopupData] = useState<any>({
    isOpen:false,
    sort:"find",
    title:"학생찾기",
    row_num:0,
    key:"",
    listOpt:{
      s_grade:["student"],
      s_except_user_seq:"",
    },
    width:"800px",
    height:"80%",
  });

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "stu_arr":[],
      ...inOptObj
    };
    set_stu_arr(optObj.stu_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_stu_arr
  }));

  useEffect(()=>{
    
  },[]);

  const get_stu_arr=()=>{
    let all_row=ListComponentRef.current.getRows();
    set_stu_arr(all_row);
    return all_row;
  };

  const openFindStuPopup=()=>{
    let tmp_except_user_seq=[];
    for(let i=0;i<stu_arr.length;i++){
      tmp_except_user_seq.push(stu_arr[i].a_seq);
    }
    setPopupData({
      ...popupData,
      isOpen:true,
      sort:"find",
      title:"학생찾기",
      listOpt:{
        s_grade:["student"],
        s_except_user_seq:tmp_except_user_seq,
      },
    });
  };

  const callbackFindUser=(data:any)=>{
    let optObj={
      info_arr:[],
      row_num:0,
      key:"",
      ...data
    };
    if(ListComponentRef.current){
      ListComponentRef.current.setInfoArr(optObj["info_arr"]);
    }
  };

  const removeSelectStu=()=>{
    let all_row=ListComponentRef.current.getRows();
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      return false;
    }
    let remain_row_arr=[];
    for(let i=0;i<all_row.length;i++){
      let tmp_row=all_row[i];
      let tmp_is_exist=false;
      for(let j=0;j<selected_row.length;j++){
        let tmp_sel_row=selected_row[j];
        if(tmp_row.a_seq==tmp_sel_row.a_seq){
          tmp_is_exist=true;
        }
      }
      if(tmp_is_exist==false){
        remain_row_arr.push(tmp_row);
      }
    }
    ListComponentRef.current.setInfoArr(remain_row_arr);
  };

  const customCellRenderer=(params:any)=>{
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    let key=params.key;
    let val_str=params.value;

    if(key=="a_stu_grade"){
      if(xColumnArr.select_arr[key]){
        for(let i=0;i<xColumnArr.select_arr[key].length;i++){
          let sel_opt_row=xColumnArr.select_arr[key][i];
          if(sel_opt_row["value"]==val_str){
            val_str=sel_opt_row["text"];
          }
        }
      }
    }

    return val_str;
  };

  return (
    <div>
      <h5>학생선택</h5>
      <div className="my-1 text-right">
        <button className="btn-m btn-yellowish2" onClick={()=>{openFindStuPopup();}} >찾기</button>
        <button className="btn-m btn-dark ml-1" onClick={()=>{removeSelectStu();}} >제외</button>
      </div>
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        infoArr={stu_arr}
        gridOpt={{
          isGrid:false,
          is_add_idx_num:false,
          is_add_checkbox:true,
          width:"100%",
          height:"40vh",
          is_auto_max_width:true,
          td_align:"left",//center,right
          table_align:"left",//center,right
          onGridReady:()=>{},
          rowOnClick:(rowData:any,idx:number,e:any)=>{

          },
          customCellRenderer:customCellRenderer
        }}
      ></ListComponent>

      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find"&&
            <FindMultyStudentPopup 
              listOpt={popupData.listOpt}
              callBackData={callbackFindUser}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindMultyStudentPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default StuListArea;
