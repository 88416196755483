import { useState, useRef, useEffect } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStaffPopup from '@/pages/comp/basic/user_staff/popup/find_multy';
import AttendReceiveListArea from  "./list";
import strFunc from '@/lib/lyg/string';

function AttendReceiveArea(props:any){
  let myProps={
    ...props
  };

  const [attend_receive_arr,set_attend_receive]=useState([]);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find_user",
    s_except_user_seq:[],
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(()=>{
    
    get_attend_receive_u_arr_by_ajax();
  },[]);

  const get_attend_receive_u_arr_by_ajax=()=>{
    let formJsonData={
      "s_addon_user":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/attend_receive/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_attend_receive(response.data["data"]["info_arr"]);
      }
    });
  };

  const openFindUserPopup=()=>{
    let pop_title="유저찾기";
    let s_except_user_seq:any=[];
    for(let i=0;i<attend_receive_arr.length;i++){
      s_except_user_seq.push(attend_receive_arr[i]["a_user_seq"]);
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "s_except_user_seq":s_except_user_seq,
      "sort":"find_user"
    });
  };

  const callBackData=(inData:any)=>{
    let opt_obj={
      info_arr:[],
        row_num:0,
        key:"",
      ...inData
    };

    let add_attend_receive_arr:any=[];
    for(let i=0;i<opt_obj.info_arr.length;i++){
      let user_info=opt_obj.info_arr[i];
      if(!is_exist_attend_receive_by_user_info(user_info)){
        add_attend_receive_arr.push({
          a_user_seq:user_info["a_seq"],
          a_user_name:user_info["a_user_name"],
          a_attend_user_grade:"staff,master,teacher",
        });
      }
    }
    if(add_attend_receive_arr.length>0){
      add_attend_receive_by_ajax(add_attend_receive_arr);
    }
  };

  const add_attend_receive_by_ajax=(add_attend_receive_arr:any)=>{
    if(add_attend_receive_arr.length==0){
      alert("등록 데이터가 없습니다.");
      return false;
    }
    if(!confirm("푸시 받는 유저를 추가 하시겠습니까?")){
      return false;
    }

    let formJsonData={
      "data_arr":add_attend_receive_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/attend_receive/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("등록 되었습니다.");
        get_attend_receive_u_arr_by_ajax();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  const del_attend_receive_by_ajax=(user_seq_arr:any[])=>{
    if(strFunc.is_empty(user_seq_arr)){
      alert("선택이 없습니다.");
      return false;
    }
    if(!confirm("선택 삭제 하시겠습니까?")){
      return false;
    }
    let del_attend_receive_arr=[];
    for(let i=0;i<user_seq_arr.length;i++){
      if(strFunc.is_empty(user_seq_arr[i])){
        continue;
      }
      del_attend_receive_arr.push({
        "a_user_seq":user_seq_arr[i],
      });
    }
    if(del_attend_receive_arr.length==0){
      alert("삭제 값이 유효하지 않습니다.");
      return false;
    }
    let formJsonData={
      "data_arr":del_attend_receive_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/attend_receive/delete',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("삭제 되었습니다.");
        get_attend_receive_u_arr_by_ajax();
      }else{
        alert(response.data["msg"]);
      }
    });
  }

  const is_exist_attend_receive_by_user_info=(user_info:any)=>{
    let is_exist=false;
    for(let i=0;i<attend_receive_arr.length;i++){
      if(attend_receive_arr[i]["a_user_seq"]==user_info["a_seq"]){
        is_exist=true;
      }
    }
    return is_exist;
  };

  const on_change_data=(inData:any)=>{
    let opt_obj={
      "user_seq":"",
      "key":"",
      "value":"",
      ...inData
    };
  };
  const on_delete_data=(user_seq:any)=>{
    del_attend_receive_by_ajax([user_seq]);
  };

  return (
    <div>
      <h4 className='mt-2'>
        ※ 직원출석 알림받는 사람
        <button className="btn btn-dark ml-2" onClick={openFindUserPopup}>찾기</button>
      </h4>
      <AttendReceiveListArea
        attend_receive_arr={attend_receive_arr}
        on_change_data={on_change_data}
        on_delete_data={on_delete_data}
      ></AttendReceiveListArea>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="find_user"&&
            <FindMultyStaffPopup listOpt={{
              s_except_user_seq:popupData.s_except_user_seq
            }} callBackData={callBackData} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></FindMultyStaffPopup>
          }
      </LayerPopup>
      }
    </div>
  );
}
export default AttendReceiveArea;